import SFAuthService, { NO_USER } from '../SFAuthService'

export const createWorkgroup = ({ userId, name }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Workgroup_Create_Workgroup', {
      inputs: [
        {
          name,
          userId
        }
      ]
    })
    .then(r => r[0])
}

export const fetchWorkgroupMemberships = userId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Workgroup_Get_User_Membership', {
      inputs: [
        {
          userId
        }
      ]
    })
    .then(r => r[0])
}

export const fetchWorkgroups = (ids = []) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Workgroup_Get_Workgroup', {
    inputs: ids.map(id => ({
      workgroupId: id
    }))
  })
}

export const createMeeting = toCreate => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Workgroup_Create_Meeting', {
    inputs: [{ toCreate }]
  })
}

export const saveWorkgroupCatalogs = toSave => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Workgroup_Save_Folder', {
    inputs: toSave.map(obj => ({
      toSave: obj
    }))
  })
}

export const updateWorkgroup = toUpdate => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost(
    '/actions/custom/flow/App_Workgroup_Update_Workgroup',
    {
      inputs: [{ toUpdate }]
    }
  )
}

export const deleteCatalogs = toDelete => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost(
    '/actions/custom/flow/App_Workgroup_Delete_Catalogs',
    {
      inputs: [{ toDelete }]
    }
  )
}

export const updateMeeting = toUpdate => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Workgroup_Update_Meeting', {
    inputs: [{ toUpdate }]
  })
}

export const deleteMeeting = toDelete => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Workgroup_Delete_Meeting', {
    inputs: [{ toDelete }]
  })
}
