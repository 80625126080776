import { EgretLoadable } from 'egret'
import { authRoles } from '../../auth/authRoles'

const Forms = EgretLoadable({
  loader: () => import('../internal/Forms')
})

const Form = EgretLoadable({
  loader: () => import('./Form')
})

const FormEditor = EgretLoadable({
  loader: () => import('../forms/FormWizard')
})

const OpportunityViewWithForm = EgretLoadable({
  loader: () => import('../forms/OpportunityViewWithForm')
})

const TwoFormsDisplayTab = EgretLoadable({
  loader: () => import('../forms/TwoFormsDisplayTab')
})

const formsRoutes = [
  {
    path: '/internal/Forms',
    component: Forms,
    auth: authRoles.pm
  },
  {
    path: '/internal/FormEditor/:id?',
    component: FormEditor,
    auth: authRoles.pm
  },
  {
    path: '/elasticform/:formId/:id?/:organizationId?',
    component: Form
  },
  {
    path: '/opportunityFormView/:formId/:opportunityId/:objectId/:printConfig?',
    component: OpportunityViewWithForm
  },

  {
    path: '/forms/:formId1/:address1/:formId2/:address2',
    component: TwoFormsDisplayTab
  }
]

export default formsRoutes
