import { Tooltip, withStyles } from '@material-ui/core'
import React from 'react'

export default function TransparentBackGroundToolitp (props) {
  const StylisedTooltip = withStyles({
    tooltip: {
      backgroundColor: 'transparent'
    }
  })(Tooltip)

  return <StylisedTooltip {...props} />
}
