import SFAuthService, { NO_USER } from '../SFAuthService'
import { mapSFToForm, reverseMap } from '../sfDataService'
import { SF_API_VERSION } from './sfOpportunity'

export const scoreCardStructureBase = {
  QuestionID: '',
  'Scoring group': undefined,
  modifier: '',
  multiple: '',
  'Option 1': '',
  'Option 2': '',
  'Option 3': '',
  'Option 4': '',
  'Option 5': '',
  'Option 6': '',
  'Option 7': '',
  'Option 8': '',
  'Option 9': '',
  'Option 10': '',
  'Option 11': '',
  'Option 12': ''
}

const mapFields = {
  direction: 'in',
  Id: 'id',
  Modifier__c: 'modifier',
  Scoring_Group__c: 'scoreGroup',
  Survey_Item__c: 'surveyItem',
  Survey_Item_Ident_Id__c: 'surveyItemIdentId',
  Scoring_Details__c: 'scoringDetails'
}

export const SCORING_CATEGORIES_CONFIG = 'SCORING_CATEGORY'
const reverseMapFields = reverseMap(mapFields)

export const getScoreCardItemsParsed = params => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Score_Card_Item__c')
    .find({ ...params })
    .then(items =>
      items.map(item => {
        if (item.Scoring_Details__c) {
          item.Scoring_Details__c = JSON.parse(item.Scoring_Details__c)
        }
        return mapSFToForm(mapFields, item)
      })
    )
}

export const getScoringCategoriesConfiguration = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Configuration__c')
    .find({ Type__c: 'SCORING_CATEGORY' })
    .then(r => r[0])
}

export const saveScoringCategoriesConfiguration = ({ id, categories = [] }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Configuration__c')
    .update({ Id: id, Value__c: JSON.stringify(categories) })
}

export const checkIfScoreCardExistForTemplate = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Score_Card__c')
    .find({ Survey_Template__c: id })
    .select('Id')
    .then(result => {
      if (Array.isArray(result) && result.length > 0) {
        return true
      } else {
        return false
      }
    })
}

export const checkIfScoreCardExistForTemplates = ids => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Score_Card__c')
    .find({ Survey_Template__c: { $in: ids } })
    .then(scoreCards => {
      return scoreCards.map(obj => obj.Survey_Template__c).filter(f => f)
    })
}

export const getScoreCardFull = params => {
  const conn = SFAuthService.getConnection()
  const templateId = params.Survey_Template__c
  if (!templateId) {
    return Promise.reject('No id provided')
  }
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Score_Card__c')
    .find({ ...params })
    .then(cards => {
      if (cards.length === 0) {
        return null
      } else if (cards.length > 1) {
        return Promise.reject('Too many score cards for selected template')
      }
      const card = cards[0]
      return getScoreCardItemsParsed({
        // Survey_Template__c: templateId,
        Score_Card__c: card.Id
      }).then(items => {
        return {
          scoreCard: card,
          scoreCardItems: items
        }
      })
    })
}

export const getScoreCardItems = params => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Score_Card_Item__c').find({ ...params })
}

export const deleteScoreCard = ids => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost(`/services/data/${SF_API_VERSION}/composite/`, {
    allOrNone: true,
    compositeRequest: [
      {
        method: 'DELETE',
        url:
          `/services/data/${SF_API_VERSION}/composite/sobjects?` +
          'ids=' +
          ids.join(',') +
          '&allOrNone=true',
        referenceId: 'refDeleteObjects'
      }
    ]
  })
}

export const createScoreCard = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('SurveyTranslation__c')
    .create({})
    .then(translation => {
      const transTextFR = conn.sobject('Survey_Text__c').create({
        Survey_Translation__c: translation.id,
        Text__c: values.nameFR,
        Language__c: 'fr-ca'
      })
      const transTextEN = conn.sobject('Survey_Text__c').create({
        Survey_Translation__c: translation.id,
        Text__c: values.nameEN,
        Language__c: 'en-ca'
      })
      return Promise.all([
        transTextEN,
        transTextFR,
        conn.sobject('Score_Card__c').create({
          Title_Translation__c: translation.id,
          Name: values.nameEN,
          Survey_Template__c: values.parentCreateTemplate
        })
      ])
    })
}

export const saveScoreCards = array => {
  const toCreate = []
  const toUpdate = []
  array.forEach(obj => {
    if (obj.Id) {
      toUpdate.push(obj)
    } else {
      toCreate.push(obj)
    }
  })
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return Promise.all([
    conn.sobject('Score_Card_Item__c').create(toCreate),
    conn.sobject('Score_Card_Item__c').update(toUpdate)
  ])
}
