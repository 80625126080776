/**
 * Created by Michał Stawski on 19.08.2020.
 */

export const mapSFToForm = (map, item, onlyActiveKeys) => {
  const ret = {}
  const dir = map.direction

  for (const key in map) {
    if (key === 'direction') {
      continue
    }
    if (onlyActiveKeys && !(key in item)) {
      continue
    }
    const splitKey = key.split('.')

    if (splitKey.length > 1) {
      // handle nested values forward
      ret[map[key]] = item[splitKey[0]][splitKey[1]]
      // TODO handle deeper nesting
    } else if (map[key] && typeof map[key] === 'object') {
      if (map[key][dir] && typeof map[key][dir] === 'function') {
        const data = map[key][dir](item, ret)
        ret[map[key].key] = data
      } else {
        // TODO we don't use object nesting, keep it?
        const { fieldName, ...newMap } = map[key]
        if (
          item[key] &&
          typeof item[key] === 'object' &&
          map[key].key === undefined
        ) {
          ret[fieldName] = mapSFToForm(newMap, item[key])
        } else {
          // function key without an out function
          // console.log('types did not match', key, map[key], item[key])
        }
      }
    } else {
      let splitTarget = []
      // handle nulls in mapping
      if (map[key] !== null) {
        splitTarget = map[key].split('.')
      }
      // handle nested values reverse
      if (splitTarget.length > 1) {
        if (!ret[splitTarget[0]]) {
          ret[splitTarget[0]] = {}
        }
        ret[splitTarget[0]][splitTarget[1]] = item[key]
        // TODO handle deeper nesting
      } else {
        ret[map[key]] = item[key]
      }
    }
  }
  return ret
}

export const reverseMap = map => {
  const ret = {}

  for (const key in map) {
    if (typeof map[key] === 'object') {
      ret[map[key].key] = {
        ...map[key],
        key: key
      }
    } else {
      ret[map[key]] = key
    }
  }
  ret.direction = 'out'
  return ret
}

export const mapToFields = map => {
  const ret = new Set()
  for (const key in map) {
    if (key === 'direction') {
      continue
    }
    if (typeof map[key] === 'object' && map[key].fieldName) {
      for (const subKey in map[key]) {
        if (subKey === 'fieldName') {
          continue
        } else {
          ret.add(`${key}.${subKey}`)
        }
      }
    } else if (!map[key].nonField) {
      ret.add(key)
    }
  }
  return [...ret]
}
