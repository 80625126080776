import { Trans } from '@lingui/macro'
import { ErrorMessage, useField } from 'formik'
import _ from 'lodash'

export const FormLabel = ({ label, error }) => {
  return (
    <p
      style={{
        color: error ? '#f5543b' : 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        marginTop: 3,
        marginBottom: 3,
        marginLeft: 14,
        textAlign: 'left'
      }}
    >
      {label}
    </p>
  )
}

export function FormErrorText ({ msg }) {
  return (
    <p
      style={{
        color: '#f5543b',
        fontSize: '0.75rem',
        marginTop: 3,
        marginBottom: 3,
        marginLeft: 14,
        textAlign: 'left'
      }}
    >
      {msg}
    </p>
  )
}

export function FormErrorLabel ({ error, required, id, label }) {
  const [field, meta] = useField(id)
  const isError =
    error || (id && meta.error && meta.touched && !Array.isArray(meta.error))
  const nonErrorLabel = Boolean(label || required)
  const message =
    id && _.get(meta.error, id) && !label ? (
      <Trans id={_.get(meta.error, id)} />
    ) : (
      label
    )

  return (
    <>
      {Boolean(!isError && nonErrorLabel) && (
        <p
          style={{
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '0.75rem',
            marginTop: 3,
            marginBottom: 3,
            marginLeft: 14,
            textAlign: 'left'
          }}
        >
          {message ? (
            required ? (
              <span>
                {message}. <Trans>Required</Trans>
              </span>
            ) : (
              <span>{message}</span>
            )
          ) : (
            <Trans>Required</Trans>
          )}
        </p>
      )}
      {id && (
        <ErrorMessage name={id}>
          {msg => {
            if (Array.isArray(msg)) {
              return null
            }
            return (
              <p
                style={{
                  color: '#f5543b',
                  fontSize: '0.75rem',
                  marginTop: 3,
                  marginBottom: 3,
                  marginLeft: 14,
                  textAlign: 'left'
                }}
              >
                {msg}
              </p>
            )
          }}
        </ErrorMessage>
      )}
    </>
  )
}
