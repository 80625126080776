import { Trans, t } from '@lingui/macro'
import { I18n } from '@lingui/react'
import { i18n } from '@lingui/core'
import {
  Button,
  Card,
  Grid,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import { TrendingUpRounded } from '@material-ui/icons'
import React, { useEffect, useLayoutEffect } from 'react'
import { indexToOption, fixedQuestionAnswers } from './QuestionWidget'

function ConditionalQuestionField (props) {
  const {
    questionProps,
    values,
    index,
    setFieldValue,
    questionIndex,
    getValuesFromProps,
    updateOptionIndex
  } = props
  const { switchedOptions } = props
  const frenchVersion = values.frenchVersion
  const [menuType, selectMenuType] = React.useState(null)

  const [selectedOptionIndex, selectOptionIndex] = React.useState(
    questionProps.conditions[index].optionIndex
  )
  const [selectedQuestionId, selectQuestionId] = React.useState(
    questionProps.conditions[index].questionId
  )
  const [selectedCondition, selectCondition] = React.useState(
    questionProps.conditions[index].condition
  )
  const [selectedQuestionFromGroupId, selectQuestionFromGroupId] =
    React.useState(questionProps.conditions[index].groupQuestionId)
  const [anchorEl, setAnchorEl] = React.useState(null)

  if (getValuesFromProps) {
    if (switchedOptions && updateOptionIndex) {
      if (updateOptionIndex !== selectedOptionIndex) {
        selectOptionIndex(updateOptionIndex)
      }
    } else {
      if (questionProps.conditions[index].questionId !== selectedQuestionId) {
        selectQuestionId(questionProps.conditions[index].questionId)
      }
      if (questionProps.conditions[index].condition !== selectedCondition) {
        selectCondition(questionProps.conditions[index].condition)
      }
      if (questionProps.conditions[index].optionIndex !== selectedOptionIndex) {
        selectOptionIndex(questionProps.conditions[index].optionIndex)
      }
      if (
        questionProps.isGroup &&
        questionProps.conditions[index].groupQuestionId !==
          selectedQuestionFromGroupId
      ) {
        selectQuestionId(questionProps.conditions[index].groupQuestionId)
      }
    }
  }

  useEffect(() => {
    setFieldValue(`created.${questionIndex}.switchedOptions`, null)
    setFieldValue(`created.${questionIndex}.forceRender`, false)
  }, [getValuesFromProps])

  const questionsToDisable = []
  values.created.forEach((question, qIndex) => {
    const displayIndex = qIndex + 1
    const toPush = values.frenchVersion
      ? displayIndex + '. ' + question.questionFR
      : displayIndex + '. ' + question.questionEN
    if (toPush !== '' && qIndex !== questionIndex) {
      questionsToDisable.push({
        type: 'question',
        text: toPush,
        id: question.id
      })
    }
    if (
      question.isGroup &&
      question.groupQuestions &&
      qIndex !== questionIndex &&
      toPush !== ''
    ) {
      question.groupQuestions.forEach((item, index) => {
        const optionText = values.frenchVersion ? item.fr : item.en
        if (optionText === '') {
          return
        }
        questionsToDisable.push({
          type: 'question',
          option: index,
          parentId: question.id,
          text: (
            <Typography>
              <b>[{toPush}] </b> {optionText}
            </Typography>
          ),
          id: item.id
        })
      })
    }
  })

  const avaliableGroupQuestions = []
  if (questionProps.isGroup && questionProps.groupQuestions) {
    questionProps.groupQuestions.forEach((question, index) => {
      avaliableGroupQuestions.push({
        id: question.id,
        order: index,
        text: values.frenchVersion ? question.fr : question.en
      })
    })
  }

  values.otherSections.forEach(template => {
    questionsToDisable.push({
      type: 'template',
      id: template.id,
      text: template.name,
      templateId: template.id
    })
    template.questions.forEach((question, questionIndex) => {
      const displayIndex = questionIndex + 1
      const questionText = values.frenchVersion
        ? displayIndex + '. ' + question.textFR
        : displayIndex + '. ' + question.textEn
      if (question.id) {
        questionsToDisable.push({
          type: 'templateQuestion',
          id: question.id,
          templateId: template.id,
          order: question.order,
          joinId: question.joinId,
          text: questionText
        })
      }
      if (question.isGroup) {
        question.groupQuestions.forEach((groupQuestion, index) => {
          questionsToDisable.push({
            type: 'templateQuestion',
            id: groupQuestion.id,
            templateId: template.id,
            order: question.order,
            groupQuestionIndex: index,
            joinId: question.joinId,
            text: (
              <Typography>
                <b>[{questionText}] </b>
                {values.frenchVersion ? groupQuestion.fr : groupQuestion.en}
              </Typography>
            )
          })
        })
      }
    })
  })

  const getQuestionById = id => {
    let toRet = null
    questionsToDisable.forEach(question => {
      if (question.id === selectedQuestionId) {
        toRet = question
      }
    })
    return toRet
  }

  const handleClick = (event, menuType) => {
    selectMenuType(menuType)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setValue()
  }, [
    frenchVersion,
    selectedOptionIndex,
    selectedCondition,
    selectedQuestionId,
    selectedQuestionFromGroupId
  ])

  const setValue = () => {
    const conditionObj = {
      condition: selectedCondition,
      optionIndex: selectedOptionIndex,
      questionId: selectedQuestionId,
      groupQuestionId: selectedQuestionFromGroupId
    }
    const refQuestion = getQuestionById()
    let groupQuestionIndex = null
    if (questionProps.isGroup) {
      avaliableGroupQuestions.forEach(question => {
        if (question.id === selectedQuestionFromGroupId) {
          groupQuestionIndex = question.order
        }
      })
    }
    if (!refQuestion) {
      return
    }
    if (!refQuestion.templateId) {
      if (checkIfValid()) {
        const toCheckReference = {
          question: values.created[questionIndex].id,
          isSelected: selectedCondition === 'selected',
          option:
            questionProps.type === 'SELECT_MULTIPLE'
              ? selectedOptionIndex
              : indexToOption(selectedOptionIndex),
          disableQuestionId: selectedQuestionId,
          disableQuestionOption:
            refQuestion.option || refQuestion.option === 0
              ? refQuestion.option
              : null,
          groupQuestion: groupQuestionIndex
        }
        const newToCheck = [...values.created[questionIndex].conditionsToCheck]
        newToCheck[index] = toCheckReference
        setFieldValue(`created.${questionIndex}.conditionsToCheck`, newToCheck)
      }
      setFieldValue(
        `created.${questionIndex}.conditions.${index}`,
        conditionObj
      )
    } else {
      if (checkIfValid()) {
        const externalCondition = {
          template: refQuestion.templateId,
          disableWholeTemplate: refQuestion.type === 'template',
          isSelected: selectedCondition === 'selected',
          option:
            questionProps.type === 'SELECT_MULTIPLE'
              ? selectedOptionIndex
              : indexToOption(selectedOptionIndex),
          disableQuestion: refQuestion.id,
          groupQuestion: groupQuestionIndex
        }
        const newExternals = [
          ...values.created[questionIndex].externalConditions
        ]
        newExternals[index] = externalCondition
        setFieldValue(
          `created.${questionIndex}.externalConditions`,
          newExternals
        )
      }
      setFieldValue(
        `created.${questionIndex}.conditions.${index}`,
        conditionObj
      )
    }
  }

  let possibleOptions = []
  const fixedTypes = ['SELECT_YESNO']

  const checkIfValid = () => {
    let groupBool = true
    if (
      questionProps.isGroup &&
      groupQuestionDisplay === 'Choose question from a group!'
    ) {
      groupBool = false
    }
    return (
      selectedCondition !== 'Choose condition!' &&
      optionDisplay !== 'Choose option!' &&
      questionDisplay !== 'Choose question or template!' &&
      groupBool
    )
  }

  const checkIfMenuEmpty = () => {
    let atLeastOneViable = false
    switch (menuType) {
      case 'questions':
        questionsToDisable.forEach(question => {
          if (question !== '' && question !== questionProps.questionEN) {
            atLeastOneViable = true
          }
        })
        return atLeastOneViable
      case 'groupQuestions':
        avaliableGroupQuestions.forEach(question => {
          if (question !== '') {
            atLeastOneViable = TrendingUpRounded
          }
        })
        return atLeastOneViable
      case 'options':
        possibleOptions.forEach(option => {
          if (option !== '') {
            atLeastOneViable = true
          }
        })
        return atLeastOneViable

      default:
        return true
    }
  }

  const getMenu = () => {
    switch (menuType) {
      case 'options':
        return possibleOptions.map((item, key) => {
          return (
            <MenuItem
              key={key}
              onClick={() => {
                selectOptionIndex(key)
                handleClose()
              }}
            >
              {item.id ? i18n._(item.id) : item}
            </MenuItem>
          )
        })
      case 'bool':
        return (
          <div>
            <MenuItem
              onClick={() => {
                selectCondition('selected')
                handleClose()
              }}
            >
              <Trans>selected</Trans>
            </MenuItem>
            <MenuItem
              onClick={() => {
                selectCondition('not selected')
                handleClose()
              }}
            >
              <Trans>not selected</Trans>
            </MenuItem>
          </div>
        )
      case 'groupQuestions':
        return avaliableGroupQuestions.map((item, key) => {
          return (
            <MenuItem
              key={key}
              onClick={() => {
                selectQuestionFromGroupId(avaliableGroupQuestions[key].id)
                handleClose()
              }}
            >
              {item.text}
            </MenuItem>
          )
        })
      case 'questions':
        return questionsToDisable.map((item, key) => {
          if (item === questionProps.questionEN) {
            return
          }
          return (
            <MenuItem
              key={key}
              onClick={() => {
                const clickedOption = questionsToDisable[key]
                if (clickedOption.templateId) {
                  deleteToCheckReference()
                } else {
                  deleteExternalReference()
                }
                selectQuestionId(clickedOption.id)
                handleClose()
              }}
            >
              {item.type === 'template' ? (
                <b>[TEMPLATE] {item.text}</b>
              ) : (
                item.text
              )}
            </MenuItem>
          )
        })
    }
  }

  const deleteToCheckReference = () => {
    console.log('del ref')
    const newToCheck = [...values.created[questionIndex].conditionsToCheck]
    newToCheck[index] = {}
    while (
      newToCheck.length > values.created[questionIndex].conditions.length
    ) {
      newToCheck.splice(newToCheck.length - 1, 1)
    }
    setFieldValue(`created.${questionIndex}.conditionsToCheck`, newToCheck)
  }

  const deleteExternalReference = () => {
    console.log('del ext')
    const newToCheck = [...values.created[questionIndex].externalConditions]
    newToCheck[index] = {}
    while (
      newToCheck.length > values.created[questionIndex].conditions.length
    ) {
      newToCheck.splice(newToCheck.length - 1, 1)
    }
    setFieldValue(`created.${questionIndex}.externalConditions`, newToCheck)
  }

  if (fixedTypes.includes(questionProps.type)) {
    possibleOptions = [...fixedQuestionAnswers[questionProps.type]]
  } else if (
    (questionProps.type === 'SELECT_ONE' ||
      questionProps.type === 'SELECT_MULTIPLE') &&
    questionProps.options.length > 0
  ) {
    questionProps.options.forEach(option => {
      const toPush = values.frenchVersion ? option.fr : option.en
      if (toPush !== '') {
        possibleOptions.push(toPush)
      }
    })
  } else {
    return null
  }

  const optionDisplay =
    selectedOptionIndex === -1
      ? { id: 'Choose option!' }
      : possibleOptions[selectedOptionIndex]
  let questionDisplay = ''
  let groupQuestionDisplay = ''
  if (selectedQuestionId === -1) {
    questionDisplay = 'Choose question or template!'
  } else {
    const question = getQuestionById()
    if (question) {
      questionDisplay = question.text
    } else {
      if (!questionsToDisable[selectedQuestionId]) {
        questionDisplay = 'Choose question or template!'
        selectQuestionId(-1)
        return
      }
      questionDisplay = questionsToDisable[selectedQuestionId].text
    }
  }
  if (questionProps.isGroup) {
    if (!selectedQuestionFromGroupId) {
      groupQuestionDisplay = 'Choose question from a group!'
    } else {
      avaliableGroupQuestions.forEach(dataObj => {
        if (dataObj.id === selectedQuestionFromGroupId) {
          groupQuestionDisplay = dataObj.text
        }
      })
      if (groupQuestionDisplay === '') {
        groupQuestionDisplay = 'Choose question from a group!'
        selectQuestionFromGroupId(null)
      }
    }
  }

  return (
    <Grid container direction='column' style={{ marginBottom: 10 }}>
      <Grid container direction='row' alignItems='center'>
        <Typography style={{ margin: 10 }}>
          <b>
            <Trans>Condition</Trans> {' ' + (index + 1)}
          </b>
        </Typography>
        <IconButton
          onClick={() => {
            const toSet = [...values.created[questionIndex].conditions]
            console.log('DEL', toSet, index)
            toSet.splice(index, 1)
            setFieldValue(`created.${questionIndex}.conditions`, toSet)
            setFieldValue(`created.${questionIndex}.forceRender`, true)
            deleteToCheckReference()
            deleteExternalReference()
          }}
        >
          <Icon>delete</Icon>
        </IconButton>
      </Grid>

      <Typography style={{ marginLeft: 10 }}>
        <Trans>If option</Trans>
      </Typography>
      <Button
        onClick={event => {
          handleClick(event, 'options')
        }}
      >
        <Card style={{ width: '100%', padding: 10 }} elevation={6}>
          {optionDisplay.id ? i18n._(optionDisplay.id) : optionDisplay}
        </Card>
      </Button>
      {questionProps.isGroup ? (
        <Typography style={{ marginLeft: 10 }}>
          <Trans>from</Trans>
        </Typography>
      ) : null}
      {questionProps.isGroup ? (
        <Button
          onClick={event => {
            handleClick(event, 'groupQuestions')
          }}
        >
          <Card style={{ width: '100%', padding: 10 }} elevation={6}>
            {groupQuestionDisplay}
          </Card>
        </Button>
      ) : null}

      <Typography style={{ marginLeft: 10 }}>
        <Trans>is</Trans>
      </Typography>

      <Button
        onClick={event => {
          handleClick(event, 'bool')
        }}
      >
        <Card style={{ width: '100%', padding: 10 }} elevation={6}>
          {selectedCondition}
        </Card>
      </Button>
      <Typography style={{ marginLeft: 10 }}>
        <Trans>disable question or template:</Trans>
      </Typography>
      <Button
        onClick={event => {
          handleClick(event, 'questions')
        }}
      >
        <Card style={{ width: '100%', padding: 10 }} elevation={6}>
          {questionDisplay}
        </Card>
      </Button>

      {checkIfMenuEmpty() ? (
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onBackdropClick={handleClose}
        >
          {getMenu()}
        </Menu>
      ) : null}
    </Grid>
  )
}

export default React.memo(ConditionalQuestionField)
