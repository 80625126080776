import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  Icon,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  withStyles
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import { FieldArray } from 'formik'
import Grid from '@material-ui/core/Grid'
import { FormikSelectField, FormikTextField } from 'formik-material-fields'
import { BaseIncludedForm, withFormikIncluded } from './BaseIncludedForm'
import { I18n } from '@lingui/react'
import { t, Trans } from '@lingui/macro'
import Typography from '@material-ui/core/Typography'
import {
  CBTI_RECORD_TYPE,
  STF_LP_RECORD_TYPE,
  STF_SIP_RECORD_TYPE
} from '../../services/sfAuth/sfData/sfOpportunity'
import * as Yup from 'yup'
import { TooltipLabelIcon } from '../page-layouts/TooltipLabelIcon'
import { deleteObjective } from '../../services/sfAuth/sfData/sfObjectives'
import { withSnackbar } from 'notistack'
import { ENTITY_IS_LOCKED } from '../../services/sfAuth/sfData/jsConnectionUtils'
import moment from 'moment'
import { countHelperText } from '../page-layouts/FormElement'

// function Transition (props) {
//   return <Slide direction="up" {...props} />
// }

const styles = theme => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
    // width: 800
  },
  dataPicker: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    width: '100%',
    marginBottom: 16
  },
  tcSpan4: {
    paddingLeft: 50,
    paddingTop: 25,
    paddingBottom: 25,
    paddingRight: 35
  },
  tr: {
    borderBottom: 'solid 1px #c1c1c1',
    borderTop: 'solid 0px #ffffff'
  },
  tHeaderText: {
    textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  divCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paragraph: {
    marginBottom: '1rem'
  },
  iconButton: {
    width: '100%'
  }
})

const initialValues = {
  objectives: []
}

const requiredTrans = <Trans>This field is required</Trans>
const sizeTrans = <Trans>Must Have at least one Outcome</Trans>
const objectiveSizeTrans = <Trans>Must Have at least one Objective</Trans>
const zeroOrNow = (currentValue, originalValue) =>
  currentValue ? currentValue : moment.utc()

export const validationSchema = Yup.object().shape({
  objectives: Yup.array()
    .ensure()
    .min(1, objectiveSizeTrans)
    .label(<Trans>Objectives</Trans>)
    .of(
      Yup.object().shape({
        objective: Yup.string()
          .ensure()
          .required(requiredTrans)
          .label(<Trans>Objective</Trans>),
        needs: Yup.string()
          .ensure()
          .required(requiredTrans)
          .label(<Trans>Needs</Trans>),
        outcomes: Yup.array()
          .ensure()
          .min(1, sizeTrans)
          .of(Yup.string())
          .required(requiredTrans)
          .label(<Trans>Outcomes</Trans>)
      })
    )
})

export const objectiveChoices = [
  {
    name: t`Increase/promote access to information`,
    value: 'Increase/promote access to information',
    fund: [CBTI_RECORD_TYPE]
  },
  {
    name: t`Build capacity around housing decisions and responsibilities`,
    value: 'Build capacity around housing decisions and responsibilities',
    fund: [CBTI_RECORD_TYPE]
  },
  {
    name: t`Increase participation in housing-related decisions and projects`,
    value: 'Increase participation in housing-related decisions and projects',
    fund: [CBTI_RECORD_TYPE]
  },
  {
    name: t`Leverage existing funding`,
    value: 'Leverage existing funding',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Lead the sector to reduce its environmental footprint`,
    value: 'Lead the sector to reduce its environmental footprint',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Develop tools and make them more accessible`,
    value: 'Develop tools and make them more accessible',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Support sectoral consolidation and other partnerships`,
    value: 'Support sectoral consolidation and other partnerships',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Reinforce organizational capacity`,
    value: 'Reinforce organizational capacity',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Enhance sector resilience`,
    value: 'Enhance sector resilience',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Fill sectoral gaps in housing services`,
    value: 'Fill sectoral gaps in housing services',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Encourage housing initiative for Indigenous communities`,
    value: 'Encourage housing initiative for Indigenous communities',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Engage community`,
    value: 'Engage community',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  }
]

const maxWordCount = 100
const baseMaxLength = 800
let maxLength = 800

class ObjectivesInner extends BaseIncludedForm {
  state = {
    open: false,
    modalId: null,
    index: null
  }

  getMaxLength (input) {
    let text = input.split(' ')
    let wordCount = 0
    for (let i = 0; i < text.length; i++) {
      if (text[i] !== ' ' && text[i] !== '') {
        wordCount++
      }
    }
    if (wordCount < maxWordCount) {
      return baseMaxLength
    }
    if (
      Boolean(wordCount == maxWordCount && text[text.length - 1] === '') ||
      wordCount > maxWordCount
    ) {
      return input.length
    }
  }

  getCurrentCharacters (input) {
    if (input) {
      return input.length
    } else {
      return 0
    }
  }

  getCurrentWords (input) {
    let text = input.split(' ')
    let wordCount = 0
    for (let i = 0; i < text.length; i++) {
      if (text[i] !== ' ' && text[i] !== '') {
        wordCount++
      }
    }
    return wordCount
  }

  getOptions () {
    let toReturn = []
    let fundType = this.props.opportunity.projectDetails.fundTypeName
    for (let obj of objectiveChoices) {
      if (obj.fund.includes(fundType)) {
        toReturn.push({
          label: <Trans id={obj.name} />,
          value: obj.value
        })
      }
    }
    return toReturn
  }

  createTable = ({ values, arrayHelpers, i18n, disabled, classes }) => {
    let columns = [t`Objectives`]
    let options = this.getOptions()
    return (
      <TableContainer component={Paper}>
        <Table style={{ whiteSpace: 'pre' }}>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f5f5f5' }}>
              {columns.map((item, i) => (
                <TableCell key={i} style={{ paddingLeft: 35 }}>
                  <p
                    style={{
                      textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
                      fontWeight: 'bold'
                    }}
                  >
                    <Trans id={item} />
                  </p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {values &&
              values.objectives &&
              values.objectives
                .map((item, index) => [
                  <TableRow key={index}>
                    <TableCell style={{ paddingLeft: 20 }}>
                      <Grid
                        container
                        spacing={1}
                        style={{
                          paddingLeft: 15,
                          paddingTop: 15,
                          paddingBottom: 15
                        }}
                      >
                        <Grid item xs={12} lg={10}>
                          <FormikSelectField
                            name={`objectives[${index}].objective`}
                            disabled={disabled}
                            label={i18n._(t`Objective`)}
                            fullWidth
                            options={options}
                          />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                          <IconButton
                            variant='filled'
                            className={classes.iconButton}
                            onClick={this.handleClickOpen(item.id, index)}
                          >
                            <Icon
                              color='error'
                              className='material-icons MuiIcon-root MuiIcon-colorError'
                              variant={'filled'}
                            >
                              delete
                            </Icon>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>,
                  <TableRow key={index + values.objectives.length}>
                    <TableCell
                      style={{
                        paddingLeft: 75,
                        paddingTop: 25,
                        paddingBottom: 25,
                        paddingRight: 35
                      }}
                    >
                      <div>
                        <Trans>Needs</Trans>
                        <TooltipLabelIcon
                          tooltip={i18n._(
                            t`Questions to guide you with the identified needs are: What needs do you hope to meet? What gaps will this project fill?`
                          )}
                        />
                      </div>
                      <FormikTextField
                        name={`objectives[${index}].needs`}
                        label=''
                        multiline
                        helperText={countHelperText(
                          values.objectives[index].needs,
                          32000
                        )}
                        inputProps={{ maxLength: 32000 }}
                        rows={5}
                        disabled={disabled}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        color='primary'
                      />
                    </TableCell>
                  </TableRow>,
                  <TableRow key={index + values.objectives.length * 2}>
                    <TableCell style={{ paddingLeft: 75 }}>
                      <FieldArray
                        name={`objectives[${index}].outcomes`}
                        render={arrayHelpers2 => (
                          <div>
                            <div key={-1}>
                              <Grid container spacing={1}>
                                <Grid item style={{ paddingTop: 25 }}>
                                  {i18n._(t`Outcomes`)}

                                  <TooltipLabelIcon
                                    tooltip={i18n._(
                                      t`Questions to guide you for the outcomes are: How will you know your project is successful? What will your milestones look like along the way?`
                                    )}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  style={{ paddingTop: 18, paddingLeft: 10 }}
                                >
                                  <Button
                                    variant='contained'
                                    color='primary'
                                    type='button'
                                    size='small'
                                    disabled={disabled}
                                    onClick={() => arrayHelpers2.push('')}
                                  >
                                    <Icon color={'inherit'}>add</Icon>
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                            {item &&
                              item.outcomes &&
                              item.outcomes
                                .map((outcome, outcomeIndex) => [
                                  <Grid
                                    container
                                    spacing={2}
                                    key={outcomeIndex}
                                  >
                                    <Grid item xs={7} lg={11}>
                                      <FormikTextField
                                        name={`objectives[${index}].outcomes[${outcomeIndex}]`}
                                        // label={`outcome[${index}]`}
                                        multiline
                                        rows={2}
                                        type='text'
                                        margin='normal'
                                        variant='outlined'
                                        fullWidth
                                        color='primary'
                                        helperText={
                                          <>
                                            <div>
                                              {this.getCurrentWords(
                                                values.objectives[index]
                                                  .outcomes[outcomeIndex]
                                              )}
                                              /{maxWordCount}{' '}
                                              <Trans>words</Trans>
                                            </div>
                                            <div>
                                              {this.getCurrentCharacters(
                                                values.objectives[index]
                                                  .outcomes[outcomeIndex]
                                              )}
                                              /{baseMaxLength}{' '}
                                              <Trans>chars</Trans>
                                            </div>
                                          </>
                                        }
                                        // helperText={i18n._(
                                        //   t`No longer than 800 characters or 100 words`
                                        // )}
                                        inputProps={{
                                          maxLength: this.getMaxLength(
                                            values.objectives[index].outcomes[
                                              outcomeIndex
                                            ]
                                          )
                                        }}
                                        disabled={disabled}
                                        // onChange={event =>
                                        //   this.wordCount(event.target.value)
                                        // }
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1}
                                      lg={1}
                                      style={{ paddingTop: 40 }}
                                    >
                                      <Button
                                        variant='contained'
                                        color='secondary'
                                        type='button'
                                        size='small'
                                        disabled={disabled}
                                        onClick={() =>
                                          arrayHelpers2.remove(outcomeIndex)
                                        }
                                      >
                                        <Icon color={'error'}>delete</Icon>
                                      </Button>
                                    </Grid>
                                  </Grid>
                                ])
                                .flat()}
                          </div>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ])
                .flat()}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  handleClickOpen = (id, index) => () => {
    this.setState({ open: true, modalId: id, index })
  }

  handleClose = () => {
    this.setState({ open: false, modelId: null, index: null })
  }

  render () {
    let { classes, values, disabled, enqueueSnackbar } = this.props
    return (
      <I18n>
        {({ i18n }) => (
          <Paper className={classes.root}>
            <Typography className={classes.paragraph}>
              <Trans>
                Select the fund’s objectives that relate to your project. We
                recommend you pick only from 1 to 4 objectives.
              </Trans>
            </Typography>
            <Typography className={classes.paragraph}>
              <Trans>
                For each of them, please provide us with a description of the
                identified need linking your project to this objective.
              </Trans>
            </Typography>
            <Typography className={classes.paragraph}>
              <Trans>
                Then, provide the outcomes that you aim at achieving. You can
                put more than one outcome. Each outcome that you provide should
                be reachable within the timespan of the project. Be specific,
                have a measurable outcome, make it achievable and realistic.
                Those outcomes will be included in the funding agreement if your
                project is awarded and you will need to report on those.
              </Trans>
            </Typography>
            <FieldArray
              name='objectives'
              render={arrayHelpers => (
                <div>
                  <div style={{ paddingLeft: 15, paddingTop: 20 }}>
                    <Button
                      variant='contained'
                      color='primary'
                      type='button'
                      disabled={disabled}
                      onClick={() => {
                        arrayHelpers.push({
                          objective: '',
                          needs: '',
                          outcomes: []
                        })
                        console.log('objectives', values)
                      }}
                    >
                      <Icon color={'inherit'}>add</Icon>
                      <span>
                        <Trans>New Objective</Trans>
                      </span>
                    </Button>
                  </div>
                  <div className='w-100 overflow-auto' style={{ padding: 20 }}>
                    {this.createTable({
                      values,
                      arrayHelpers,
                      i18n,
                      disabled,
                      classes
                    })}
                  </div>
                  <Dialog
                    open={this.state.open}
                    // TransitionComponent={Transition}
                    onClose={this.handleClose}
                    aria-labelledby='alert-dialog-slide-title'
                    aria-describedby='alert-dialog-slide-description'
                  >
                    <DialogTitle id='alert-dialog-slide-title'>
                      <Trans>
                        Are you sure you want to delete this objective?
                      </Trans>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-slide-description'>
                        <Trans>This process cannot be undone.</Trans>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose} color='default'>
                        <Trans>Cancel</Trans>
                      </Button>
                      <Button
                        onClick={() => {
                          let { modalId, index } = this.state
                          if (modalId) {
                            enqueueSnackbar(<Trans>Deleting</Trans>, {
                              variant: 'info'
                            })
                            deleteObjective(modalId)
                              .then(() => {
                                enqueueSnackbar(<Trans>Deleted</Trans>, {
                                  variant: 'success'
                                })
                                arrayHelpers.remove(index)
                              })
                              .catch(error => {
                                if (
                                  error &&
                                  error.errorCode === ENTITY_IS_LOCKED
                                ) {
                                  enqueueSnackbar(
                                    <Trans>
                                      Application is Locked: Contact the
                                      Administrator
                                    </Trans>,
                                    { variant: 'error' }
                                  )
                                } else {
                                  enqueueSnackbar(<Trans>Can't Delete</Trans>, {
                                    variant: 'error'
                                  })
                                }
                              })
                          } else {
                            arrayHelpers.remove(index)
                          }
                          this.handleClose()
                        }}
                        variant={'contained'}
                        color='error'
                      >
                        <Trans>Yes, delete it forever</Trans>
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
            />
          </Paper>
        )}
      </I18n>
    )
  }
}

export default withSnackbar(
  withFormikIncluded({ initialValues })(withStyles(styles)(ObjectivesInner))
)
