import React, { useEffect } from 'react'
import { useDragLayer } from 'react-dnd'
import { draggableItemTypes } from './FormWizard'
import GroupCard, { GroupCardComponent } from './GroupCard'

import { GroupElement } from './GroupElement'

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
}

function getItemStyles (props, itemType) {
  const { currentOffset } = props
  if (!currentOffset) {
    return {
      display: 'none'
    }
  }
  const { x, y } = currentOffset
  const transform = `translate(${x - 20}px, ${y - 20}px)`
  return {
    transform: transform,
    WebkitTransform: transform
  }
}

const GroupMemoized = React.memo(props => {
  return <GroupCardComponent {...props} />
})

function DragPlaceholder ({ frenchVersion, stack = [], ...props }) {
  const { isDragging, item, currentOffset } = useDragLayer(collect)
  if (!isDragging) {
    return null
  }

  function renderItem (item, props) {
    if (item.elements) {
      const toPass = {
        item,
        french: frenchVersion,
        width: item.width || props.width,
        depth: item.depth.join('.')
      }
      return <GroupMemoized {...toPass} />
    } else {
      return <GroupElement dummy={false} {...item} {...props} handlers={{}} />
    }
  }

  if (!currentOffset) {
    return null
  }
  return (
    <div style={layerStyles}>
      {item.depth && (
        <div style={getItemStyles({ ...props, currentOffset })}>
          {renderItem(item, { ...props })}
        </div>
      )}
      {currentOffset &&
        stack.map((child, index) => {
          const mod = index + 1
          return (
            <div
              key={index}
              style={getItemStyles({
                currentOffset: {
                  x: currentOffset.x + mod * 20,
                  y: currentOffset.y - mod * 30
                }
              })}
            >
              {renderItem(child, {
                ...props,
                width: item.width
              })}
            </div>
          )
        })}
    </div>
  )
}

function collect (monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }
}

export default DragPlaceholder
