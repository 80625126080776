import SFAuthService from '..//SFAuthService'
import { NO_USER } from '../SFAuthService'

export const fetchAccountJoinOpportunityList = ({
  opportunity,
  opportunityIds,
  tag
}) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Opportunity_and_Accounts_Join_List',
      {
        inputs: [{ opportunityId: opportunity, tag, opportunityIds }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}

export const createAccountJoinOpportunity = ({
  accountId,
  tag,
  opportunityId
}) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Opportunity_and_Accounts_Join_Add',
      {
        inputs: [{ accountId, opportunityId, tag }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}

export const deleteAccountJoinOpportunity = ({
  accountId,
  id,
  tag,
  opportunityId
}) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Opportunity_and_Accounts_Join_Remove',
      {
        inputs: [{ accountId, opportunityId, tag, id }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}
