import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import {
  SurveyPdfNonApplicableCheckbox,
  pdfSurveyQuestionSubFontSize
} from '../PrintableReadyToFillSurvey'

export default function SurveyTextInputQuestionPdf ({ isGroup, ...props }) {
  if (isGroup) {
    return <GroupQuestionPdfView {...props} />
  } else {
    return <SingleQuestionPdfView {...props} />
  }
}

function GroupQuestionPdfView ({ groupQuestions, nonApplicable, ...props }) {
  return groupQuestions.map((question, index) => {
    return (
      <View key={index}>
        <Text
          style={{
            fontWeight: 'thin',
            paddingLeft: 10,
            fontSize: pdfSurveyQuestionSubFontSize,
            paddingTop: 10
          }}
        >
          {props.langFR ? question.fr : question.en}
        </Text>
        <SingleQuestionPdfView
          type={props.type}
          rows={props.rows}
          {...question}
        />
        {nonApplicable && <SurveyPdfNonApplicableCheckbox />}
      </View>
    )
  })
}

function SingleQuestionPdfView ({ type, rows, ...props }) {
  let length = rows ? Number(rows) : 3
  if (type !== 'INPUT_TEXTAREA') {
    return <PdfInputLine />
  } else {
    return [...Array(Number(length)).keys()].map(index => {
      return <PdfInputLine key={index} subsequent={index !== 0} />
    })
  }
}

export const PdfInputLine = ({ subsequent }) => {
  const marginTop = subsequent
    ? pdfSurveyQuestionSubFontSize + 12
    : pdfSurveyQuestionSubFontSize + 6
  return (
    <View
      style={{
        borderBottom: '1px solid black',
        height: 1,
        marginTop,
        flexGrow: 1
      }}
    />
  )
}
