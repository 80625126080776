import { t, Trans } from '@lingui/macro'
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { Text, View } from '@react-pdf/renderer'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import { FastField, useField, useFormikContext } from 'formik'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import { getMainConnected } from '../Form'
import {
  validFieldTypesForPicklist,
  validFieldTypesForTextField
} from '../FormWizard'
import { commitChangeToMultipleFields } from '../multiuser/grpcMultiuserEdit'
import {
  checkShouldUpdateForMultiuserField,
  ConfigureSelectActions,
  FormConnectToObject,
  handleSelectActions,
  NumberFormatDefault,
  pdfDefaultFontSize
} from './Common'
import { FormErrorLabel, FormLabel } from './FormErrorLabel'
import MUTextField from './multiuser-input/MUTextField'

export const FormPicklistPdf = ({
  title,
  id,
  typeProps,
  value,
  values = {},
  disabled,
  langFR,
  connectedFieldDetails,
  connectedObject,
  ...props
}) => {
  const { picklistType, dynamic, pdfBorderDisplay } = typeProps
  const { valueToTrans, valueToIndex, valueToSFDetails } =
    getPicklistRenderProps({ langFR, connectedFieldDetails, typeProps })

  let style = { fontFamily: 'Roboto', fontSize: pdfDefaultFontSize }
  if (pdfBorderDisplay) {
    style = {
      ...style,
      padding: 8,
      border: '1px solid rgba(0, 0, 0, 0.02)',
      borderRadius: '5px'
    }
  }

  if (picklistType === 'multiselect' || Array.isArray(value)) {
    let data = value || []
    if (connectedFieldDetails) {
      data = data.sort((a, b) => {
        const aDetails = valueToSFDetails[a]
        const bDetails = valueToSFDetails[b]
        const aIndex = aDetails?.index || 0
        const bIndex = bDetails?.index || 0
        return aIndex - bIndex
      })
    }
    return (
      <View style={style}>
        <Text style={{ fontWeight: 'bold' }}>{title}</Text>
        {Boolean(data.length === 0) && (
          <Text style={{ fontWeight: 'bold' }}>
            <Trans>This list is empty!</Trans>
          </Text>
        )}
        {data.map((item, index) => {
          const orgIndex = valueToIndex[item]
          const translatedOption = dynamic
            ? valueToSFDetails[item]?.label
            : valueToTrans[item]
          const connectedField = valueToSFDetails[item]?.connectedField

          return (
            <View
              key={index}
              style={{
                display: 'flex'
                //paddingTop: 3
                //paddingBottom: 3,
                //paddingLeft: 8
              }}
            >
              <Text>
                {translatedOption || <Trans id={item} />}
                {/* {values.other[id] && values.other[id][orgIndex] && (
                  <Text>{' (' + values.other[id][orgIndex] + ')'}</Text>
                )} */}
                {connectedField && (
                  <>
                    <Text>: </Text>
                    <Text style={{ fontStyle: 'italic' }}>
                      {connectedObject[connectedField.name]}
                    </Text>
                  </>
                )}
              </Text>
            </View>
          )
        })}
      </View>
    )
  } else {
    return (
      <View style={style}>
        <Text style={{ fontWeight: 'bold' }}>{title}</Text>
        <Text style={{ whiteSpace: 'pre-line' }}>
          {dynamic
            ? valueToSFDetails[value]?.label
            : valueToTrans[value] || <Trans id={value} />}
          {values.other[id] && <Text>{' (' + values.other[id] + ')'}</Text>}
        </Text>
      </View>
    )
  }
}

export const FormPicklistPrint = ({
  title,
  id,
  typeProps,
  value,
  disabled,
  langFR,
  connectedFieldDetails,
  connectedObject,
  ...props
}) => {
  const { values } = useFormikContext()
  const { printIcon = 'none', picklistType, options = [], dynamic } = typeProps
  const { valueToTrans, valueToIndex, valueToSFDetails } =
    getPicklistRenderProps({ langFR, connectedFieldDetails, typeProps })

  if (picklistType === 'multiselect' || Array.isArray(value)) {
    let data = value || []
    if (connectedFieldDetails) {
      data = data.sort((a, b) => {
        const aDetails = valueToSFDetails[a]
        const bDetails = valueToSFDetails[b]
        const aIndex = aDetails?.index || 0
        const bIndex = bDetails?.index || 0
        return aIndex - bIndex
      })
    }
    return (
      <div>
        <Typography className='form-print-subtitle'>{title}</Typography>
        <List>
          {Boolean(data.length === 0) && (
            <ListItem style={{ display: 'flex', fontWeight: 'bold' }}>
              <Trans>This list is empty!</Trans>
            </ListItem>
          )}
          {data.map((item, index) => {
            const translatedOption = dynamic
              ? valueToSFDetails[item]?.label
              : valueToTrans[item]
            const connectedField = valueToSFDetails[item]?.connectedField
            return (
              <ListItem
                key={index}
                style={{
                  display: 'flex',
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 8
                }}
              >
                {printIcon !== 'none' && (
                  <ListItemIcon style={{ minWidth: 35 }}>
                    <Icon>{printIcon}</Icon>
                  </ListItemIcon>
                )}
                <Typography>
                  {translatedOption || <Trans id={item} />}
                  {/* {values.other[id] && values.other[id][orgIndex] && (
                    <span>{' (' + values.other[id][orgIndex] + ')'}</span>
                  )} */}
                  {connectedField && (
                    <>
                      {': '}
                      <i>{connectedObject[connectedField.name]}</i>
                    </>
                  )}
                </Typography>
              </ListItem>
            )
          })}
        </List>
      </div>
    )
  } else {
    return (
      <div>
        <Typography className='form-print-subtitle'>{title}</Typography>
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {dynamic
            ? valueToSFDetails[value]?.label
            : valueToTrans[value] || <Trans id={value} />}
          {values.other[id] && <span>{' (' + values.other[id] + ')'}</span>}
        </Typography>
      </div>
    )
  }
}

const getPicklistRenderProps = ({
  connectedFieldDetails,
  langFR,
  typeProps
}) => {
  const { options = [] } = typeProps
  const valueToTrans = {}
  const valueToIndex = {}
  const valueToSFDetails = {}
  options.forEach((opt, index) => {
    if (opt.apiValue) {
      valueToTrans[opt.apiValue] = langFR ? opt.titleFR : opt.titleEN
    }
    valueToIndex[opt.apiValue] = index
  })
  if (connectedFieldDetails) {
    connectedFieldDetails.picklistValues.forEach((option, index) => {
      valueToSFDetails[String(option.value).replace(/\s/g, ' ')] = {
        label: option.label,
        connectedField: option.connectedFieldDetails,
        index
      }
    })
  }
  options.forEach((opt, index) => {
    if (valueToSFDetails[opt.apiValue]) {
      valueToSFDetails[opt.apiValue].connectedField = opt.connectedFieldDetails
    }
  })
  return { valueToTrans, valueToIndex, valueToSFDetails }
}

export const formPicklistValueToText = (value = [], question, object = {}) => {
  const { connectedFieldDetails } = object
  const { dynamic } = question.typeProps
  const options =
    dynamic && connectedFieldDetails
      ? connectedFieldDetails.picklistValues
          .filter(item => item.active)
          .map((item, index) => {
            return {
              titleEN: myI18n._(t`${item.label}`),
              titleFR: myI18n._(t`${item.label}`),
              apiValue: item.value
            }
          })
      : question.typeProps.options

  const selectedEn = []
  const selectedFr = []
  if (Array.isArray(value)) {
    value.forEach(v => {
      options.some((option, index) => {
        const found = v === 'option' + index || v === option.apiValue
        if (found) {
          selectedEn.push(<div key={index}>{option.titleEN}</div>)
          selectedFr.push(<div key={index}>{option.titleFR}</div>)
        }
        return found
      })
    })
    return { en: <div>{selectedEn}</div>, fr: <div>{selectedFr}</div> }
  } else {
    let singleOptionEN = ''
    let singleOptionFR = ''
    options.some((option, index) => {
      let found
      if (option.apiValue) {
        found = value === option.apiValue
      } else {
        found = value === 'option' + index
      }
      if (found) {
        singleOptionEN = option.titleEN
        singleOptionFR = option.titleFR
      }
      return found
    })
    return { en: <div>{singleOptionEN}</div>, fr: <div>{singleOptionFR}</div> }
  }
}

export const FormPicklist = ({
  id,
  useMultiuser,
  muBag,
  typeProps,
  value,
  disabled,
  langFR,
  connectedFieldDetails,
  ...props
}) => {
  const {
    options = [],
    picklistType,
    required,
    readOnly,
    isConnected,
    componentWidth,
    dynamic,
    maxOptions
  } = typeProps
  const otherId = 'other.' + id
  const hasTooltip = Boolean(props.tooltipEN || props.tooltipFR)
  const { values, setValues } = useFormikContext()
  const [field, meta] = useField(id)

  let selectableOptions =
    dynamic && connectedFieldDetails && connectedFieldDetails.picklistValues
      ? connectedFieldDetails.picklistValues
          .filter(item => item.active)
          .map((item, index) => {
            return {
              // test: myI18n._(item.label),
              titleEN: item.label,
              titleFR: item.label,
              apiValue: item.value
            }
          })
      : options.map(item => {
          return {
            ...item,
            titleEN: String(item.titleEN).replace(/\u00a0/g, ' '),
            titleFR: String(item.titleFR).replace(/\u00a0/g, ' ')
          }
        })

  if (readOnly) {
    return (
      <FormPicklistPrint
        {...props}
        title={null}
        id={id}
        typeProps={typeProps}
        value={value}
        disabled={disabled}
        langFR={langFR}
        connectedFieldDetails={connectedFieldDetails}
      />
    )
  }

  const renderTwoColumns =
    selectableOptions.length > 10 && picklistType !== 'expandable'
  const optionsMap = {}
  selectableOptions.forEach((option, index) => {
    const defaultOpt = 'option' + index
    const optionValue = isConnected ? option.apiValue || defaultOpt : defaultOpt
    selectableOptions[index].orgIndex = index
    optionsMap[optionValue] = option
  })

  if (renderTwoColumns) {
    const firstHalf = selectableOptions.slice(
      0,
      Math.ceil(selectableOptions.length / 2)
    )
    const secondHalf = selectableOptions.slice(
      Math.ceil(selectableOptions.length / 2)
    )
    const toSet = []
    firstHalf.forEach((item, index) => {
      toSet.push(item)
      if (secondHalf[index]) {
        toSet.push(secondHalf[index])
      }
    })
    selectableOptions = toSet
  }

  if (picklistType === 'multiselect') {
    const listValue = field.value || []
    const clearOptionSelected =
      listValue.length === 1 &&
      selectableOptions.some(item => {
        const index = item.orgIndex
        const defaultOpt = 'option' + index
        const optionValue = isConnected
          ? item.apiValue || defaultOpt
          : defaultOpt
        if (optionValue === listValue[0] && item.clearOnSelect) {
          return true
        } else {
          return false
        }
      })

    return (
      <PicklistMultiselectMemoized
        id={id}
        options={selectableOptions}
        langFR={langFR}
        required={required}
        disabled={disabled}
        maxOptions={maxOptions}
        isConnected={isConnected}
        clearOptionSelected={clearOptionSelected}
        muBag={muBag}
        useMultiuser={useMultiuser}
      />
    )
  } else if (picklistType === 'expandable') {
    const requireDetails = optionsMap[value]?.requireDetails
    return (
      <div>
        <MUTextField
          useMultiuser={useMultiuser}
          select
          muBag={muBag}
          id={id}
          style={{
            marginTop: !hasTooltip && 5,
            width: componentWidth
          }}
          fullWidth={!componentWidth}
          variant='outlined'
          disabled={selectableOptions.length === 0 || disabled}
          required={required}
          onChange={e => {
            const toSet = { ...values }
            const oldValue = toSet[id]
            let lastOptionActions = []
            if (oldValue) {
              selectableOptions.some(option => {
                const index = option.orgIndex
                const defaultOpt = 'option' + index
                const optionValue = isConnected
                  ? option.apiValue || defaultOpt
                  : defaultOpt
                if (oldValue === optionValue) {
                  lastOptionActions = option.selectActions
                }
                return oldValue === optionValue
              })
            }
            toSet[id] = e.target.value
            toSet[otherId] = ''
            const modified = handleSelectActions({
              toSet,
              checked: true,
              lastOptionActions,
              selectActions: optionsMap[toSet[id]]?.selectActions
            })
            setValues(toSet)
            if (useMultiuser) {
              commitChangeToMultipleFields({
                array: [...modified, id, otherId].map(id => [id, toSet[id]]),
                ...muBag
              })
            }
          }}
        >
          {selectableOptions.map(item => {
            const index = item.orgIndex
            const option = langFR ? item.titleFR : item.titleEN
            const defaultOpt = 'option' + index
            const optionValue = isConnected
              ? item.apiValue || defaultOpt
              : defaultOpt
            return (
              <MenuItem key={index} value={optionValue}>
                {option}
              </MenuItem>
            )
          })}
        </MUTextField>
        {requireDetails && (
          <div style={{ marginTop: 5 }}>
            <RequireDetailsField
              item={optionsMap[value]}
              useMultiuser={useMultiuser}
              muBag={muBag}
              id={otherId}
              disabled={disabled}
            />
          </div>
        )}
      </div>
    )
  }
  return (
    <PicklistRadiogroupMemoized
      id={id}
      options={selectableOptions}
      langFR={langFR}
      required={required}
      disabled={disabled}
      isConnected={isConnected}
      muBag={muBag}
      useMultiuser={useMultiuser}
      otherId={otherId}
    />
  )
}

const PicklistRadiogroupMemoized = ({
  id,
  options,
  langFR,
  required,
  disabled,
  isConnected,
  otherId,
  muBag,
  useMultiuser
}) => {
  const manipulatesOtherFields = options.some(
    item => item.requireDetails || Array.isArray(item.selectActions)
  )
  return (
    <FastField
      name={id}
      {...muBag}
      useMultiuser={useMultiuser}
      langFR={langFR}
      required={required}
      disabled={disabled}
      isConnected={isConnected}
      shouldUpdate={(nextProps, currentProps) => {
        let checkValuesBool = false
        if (manipulatesOtherFields) {
          checkValuesBool = !_.isEqual(
            nextProps.formik.values,
            currentProps.formik.values
          )
        }
        return (
          checkShouldUpdateForMultiuserField(nextProps, currentProps) ||
          nextProps.clearOptionSelected !== currentProps.clearOptionSelected ||
          nextProps.langFR !== currentProps.langFR ||
          checkValuesBool
        )
      }}
    >
      {({ field, meta, form }) => {
        const { setValues, setFieldValue, values } = form
        const isError = Boolean(meta.touched && meta.error)
        const renderTwoColumns = options.length > 10
        return (
          <FormControl component='fieldset'>
            <RadioGroup
              style={
                renderTwoColumns
                  ? {
                      flexDirection: 'row',
                      marginLeft: 25,
                      justifyContent: 'space-between'
                    }
                  : {}
              }
              {...field}
              value={field.value || ''}
            >
              {options.map(item => {
                const index = item.orgIndex
                const option = langFR ? item.titleFR : item.titleEN
                const tooltip = langFR ? item.tooltipFR : item.tooltipEN
                const defaultOpt = 'option' + index
                const optionValue = isConnected
                  ? item.apiValue || defaultOpt
                  : defaultOpt
                return (
                  <>
                    <FormControlLabel
                      style={{
                        width: renderTwoColumns && '45%',
                        marginBottom: 10
                      }}
                      key={index}
                      disabled={disabled}
                      value={optionValue}
                      control={
                        <Radio
                          style={{
                            paddingLeft: 9,
                            paddingRight: 9,
                            paddingTop: 0,
                            paddingBottom: 0
                          }}
                          onChange={e => {
                            const toSet = { ...values }
                            const oldValue = toSet[id]
                            if (manipulatesOtherFields) {
                              let lastOptionActions = []
                              if (oldValue) {
                                options.some(option => {
                                  const index = item.orgIndex
                                  const defaultOpt = 'option' + index
                                  const optionValue = isConnected
                                    ? item.apiValue || defaultOpt
                                    : defaultOpt
                                  if (oldValue === optionValue) {
                                    lastOptionActions = option.selectActions
                                  }
                                  return oldValue === optionValue
                                })
                              }
                              toSet[id] = e.target.value
                              toSet[otherId] = ''
                              const modified = handleSelectActions({
                                toSet,
                                checked: true,
                                selectActions: item.selectActions,
                                lastOptionActions
                              })
                              setValues(toSet)
                              if (useMultiuser) {
                                commitChangeToMultipleFields({
                                  array: [...modified, id, otherId].map(id => [
                                    id,
                                    toSet[id]
                                  ]),
                                  ...muBag
                                })
                              }
                            } else {
                              setFieldValue(id, e.target.value)
                              if (useMultiuser) {
                                commitChangeToMultipleFields({
                                  array: [[id, e.target.value]],
                                  ...muBag
                                })
                              }
                            }
                          }}
                        />
                      }
                      label={
                        <div>
                          {option}
                          {tooltip && <TooltipLabelIcon tooltip={tooltip} />}
                        </div>
                      }
                    />
                    {Boolean(
                      field.value === optionValue && item.requireDetails
                    ) && (
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          width: renderTwoColumns && '100%'
                        }}
                      >
                        <RequireDetailsField
                          item={item}
                          useMultiuser={useMultiuser}
                          muBag={muBag}
                          id={'other.' + id}
                          disabled={disabled}
                        />
                      </div>
                    )}
                  </>
                )
              })}
            </RadioGroup>
            <FormErrorLabel error={isError} id={id} required={required} />
          </FormControl>
        )
      }}
    </FastField>
  )
}

const PicklistMultiselectMemoized = ({
  id,
  options,
  langFR,
  required,
  disabled,
  maxOptions,
  isConnected,
  clearOptionSelected,
  muBag,
  useMultiuser
}) => {
  const manipulatesOtherFields = options.some(
    item => item.requireDetails || Array.isArray(item.selectActions)
  )

  return (
    <FastField
      name={id}
      {...muBag}
      useMultiuser={useMultiuser}
      langFR={langFR}
      required={required}
      disabled={disabled}
      maxOptions={maxOptions}
      isConnected={isConnected}
      clearOptionSelected={clearOptionSelected}
      options={options}
      shouldUpdate={(nextProps, currentProps) => {
        let checkValuesBool = false
        if (manipulatesOtherFields) {
          checkValuesBool = !_.isEqual(
            nextProps.formik.values,
            currentProps.formik.values
          )
        }
        return (
          checkShouldUpdateForMultiuserField(nextProps, currentProps) ||
          nextProps.clearOptionSelected !== currentProps.clearOptionSelected ||
          nextProps.langFR !== currentProps.langFR ||
          nextProps.options !== currentProps.options ||
          checkValuesBool
        )
      }}
    >
      {({ field, meta, form }) => {
        const { setValues, setFieldTouched, values, setFieldValue } = form
        const isError = Boolean(meta.touched && meta.error)
        const listValue = field.value || []
        const renderTwoColumns = options.length > 10
        return (
          <FormControl component='fieldset'>
            <FormGroup
              style={
                renderTwoColumns
                  ? {
                      flexDirection: 'row',
                      marginLeft: 25,
                      justifyContent: 'start'
                    }
                  : {}
              }
            >
              {options.map(item => {
                const { requireDetails } = item
                const index = item.orgIndex
                const option = langFR ? item.titleFR : item.titleEN
                const tooltip = langFR ? item.tooltipFR : item.tooltipEN
                const defaultOpt = 'option' + index
                const optionValue = isConnected
                  ? item.apiValue || defaultOpt
                  : defaultOpt
                let disableOption =
                  disabled ||
                  Boolean(clearOptionSelected && listValue[0] !== optionValue)
                const checked = listValue.includes(optionValue)
                if (!disableOption && maxOptions) {
                  disableOption =
                    listValue.length >= Number(maxOptions) && !checked
                }
                return (
                  <Grid
                    container
                    direction='column'
                    key={index}
                    style={{ width: renderTwoColumns && '35%' }}
                  >
                    <FormControlLabel
                      style={{
                        marginBottom: 10
                      }}
                      disabled={disableOption}
                      key={index}
                      value={optionValue}
                      onChange={e => {
                        const { value } = e.currentTarget
                        if (!meta.touched) {
                          setFieldTouched(id)
                        }
                        const newValues = { ...values }
                        const oldValue = [...listValue]
                        let newList = [...listValue]
                        const otherId = 'other.' + id + '.' + index
                        let modified = []
                        if (newList.includes(value)) {
                          newList.splice(newList.indexOf(value), 1)
                          if (requireDetails) {
                            newValues[otherId] = ''
                          }
                        } else {
                          if (item.clearOnSelect) {
                            newList = [value]
                          } else {
                            newList.push(value)
                          }
                        }

                        if (manipulatesOtherFields) {
                          newValues[id] = newList
                          options.some(option => {
                            const index = item.orgIndex
                            const defaultOpt = 'option' + index
                            const optionValue = isConnected
                              ? item.apiValue || defaultOpt
                              : defaultOpt
                            const wasSelected =
                              oldValue.includes(optionValue) &&
                              !newValues[id].includes(optionValue)
                            if (wasSelected) {
                              modified = handleSelectActions({
                                toSet: newValues,
                                checked: newValues[id].includes(value),
                                lastOptionActions: option.selectActions,
                                selectActions: item.selectActions
                              })
                            }
                            return wasSelected
                          })
                          setValues(newValues)
                          if (useMultiuser) {
                            commitChangeToMultipleFields({
                              array: [...modified, id, otherId].map(id => [
                                id,
                                newValues[id]
                              ]),
                              ...muBag
                            })
                          }
                        } else {
                          setFieldValue(id, newList)
                          if (useMultiuser) {
                            commitChangeToMultipleFields({
                              array: [[id, newList]],
                              ...muBag
                            })
                          }
                        }
                      }}
                      checked={checked}
                      control={
                        <Checkbox
                          style={{
                            paddingLeft: 9,
                            paddingRight: 9,
                            paddingTop: 0,
                            paddingBottom: 0
                          }}
                        />
                      }
                      label={
                        <div>
                          {option}
                          {tooltip && <TooltipLabelIcon tooltip={tooltip} />}
                        </div>
                      }
                    />
                    {Boolean(
                      listValue.includes(optionValue) && requireDetails
                    ) && (
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 5
                        }}
                      >
                        <RequireDetailsField
                          item={item}
                          useMultiuser={useMultiuser}
                          muBag={muBag}
                          id={'other.' + id + '.' + index}
                          disabled={disabled}
                          displayFieldHistoryIcon={true}
                        />
                      </div>
                    )}
                  </Grid>
                )
              })}
            </FormGroup>
            <FormErrorLabel error={isError} id={id} required={required} />
          </FormControl>
        )
      }}
    </FastField>
  )
}

const RequireDetailsField = ({ item, ...props }) => {
  let specifyLimit
  if (item && item.connectedFieldDetails) {
    specifyLimit = item.connectedFieldDetails.length
  }
  return (
    <>
      <Trans>Please, specify: </Trans>
      <MUTextField
        {...props}
        // inputProps={{
        //   maxLength: specifyLimit && Number(specifyLimit)
        // }}
        limit={specifyLimit}
      />
    </>
  )
}

export const FormEditorPicklist = ({
  typeProps = {},
  elementType,
  id,
  french,
  editMode,
  depth,
  showPrintProps,
  showPdfProps,
  injectable,
  ...props
}) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()

  const {
    options = [],
    printIcon,
    required,
    requiredAll,
    forceRequired,
    picklistType,
    isConnected,
    dynamic,
    componentWidth,
    maxOptions,
    pdfBorderDisplay
  } = typeProps

  if (!editMode) {
    return (
      <FormPicklist
        preview
        typeProps={typeProps}
        id={id}
        {...props}
        langFR={french}
      />
    )
  }
  const { connectedField, connectedObject } = getMainConnected({
    typeProps,
    ...props
  })
  const { objects, objectsConnected } = values
  let fieldsToConnect = []
  const avaliableObjectsMap = {}
  objectsConnected.forEach(obj => {
    avaliableObjectsMap[obj.identId] = obj
  })
  connectedObject &&
    objects.some(obj => {
      if (!avaliableObjectsMap[connectedObject]) {
        return false
      }
      const bool = obj.name === avaliableObjectsMap[connectedObject].type
      if (bool) {
        fieldsToConnect = obj.fields
      }
      return bool
    })

  const fieldsMap = {}
  fieldsToConnect.forEach(field => {
    fieldsMap[field.name] = field
  })
  fieldsToConnect = fieldsToConnect.filter(item =>
    validFieldTypesForTextField.includes(item.type)
  )
  const forceReadOnly =
    (connectedField && connectedField.readOnly) || showPrintProps
  const readOnly = typeProps.readOnly || forceReadOnly

  return (
    <Grid container style={{ padding: 10 }} direction='column'>
      {!showPdfProps && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(readOnly && connectedField) || forceReadOnly}
              disabled={!connectedField || forceReadOnly}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.readOnly = e.target.checked
                delete toSet.required
                delete toSet.requiredAll
                delete toSet.componentWidth
                delete toSet.maxOptions
                // delete toSet.picklistType
                toSet.options = []
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Read only</Trans>}
        />
      )}

      {readOnly && !showPdfProps ? (
        <>
          <TextField
            select
            variant='outlined'
            label={<Trans>Print list icon</Trans>}
            fullWidth
            value={printIcon || 'none'}
            style={{ marginTop: 10, marginBottom: 10 }}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.printIcon = e.target.value
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          >
            <MenuItem value='none' key='none'>
              <Trans>None</Trans>
            </MenuItem>
            {[
              'supervised_user_circle',
              'accessibility_new',
              'public',
              'fiber_manual_record',
              'payments',
              'location_city',
              'paid',
              'work'
            ].map(item => (
              <MenuItem value={item} key={item}>
                <Icon>{item}</Icon>
              </MenuItem>
            ))}
          </TextField>
          <Alert severity='info' variant='outlined'>
            <Trans>
              You can't change this element to an input. Either connected field
              or whole form is marked as read only
            </Trans>
          </Alert>
        </>
      ) : (
        !showPdfProps && (
          <>
            <FormControlLabel
              disabled={readOnly || forceRequired}
              style={{ marginBottom: 5 }}
              control={
                <Checkbox
                  checked={Boolean(required || forceRequired)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.required = e.target.checked
                    if (e.target.checked) {
                      delete toSet.requiredAll
                    }
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Required</Trans>}
            />

            {picklistType === 'multiselect' && (
              <FormControlLabel
                disabled={readOnly || forceRequired}
                style={{ marginBottom: 5 }}
                control={
                  <Checkbox
                    checked={Boolean(requiredAll)}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      toSet.requiredAll = e.target.checked
                      if (e.target.checked) {
                        delete toSet.required
                      }
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: { ...toSet }
                      })
                    }}
                  />
                }
                label={<Trans>Required (All)</Trans>}
              />
            )}

            {picklistType === 'expandable' && (
              <TextField
                variant='outlined'
                label={<Trans>Component width</Trans>}
                fullWidth
                value={componentWidth}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.componentWidth = e.currentTarget.value
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            )}
          </>
        )
      )}

      <FormConnectToObject
        injectable={injectable}
        typeProps={typeProps}
        depth={depth}
        validTypes={validFieldTypesForPicklist}
        allowReadOnlyFields
        clearOnReadOnlyField={[
          'required',
          'requiredAll',
          'componentWidth',
          'maxOptions'
        ]}
        handleDisconnect={toSet => {
          delete toSet.dynamic
          if (toSet.options) {
            toSet.options.forEach(option => {
              delete option.apiValue
            })
          }
        }}
      />

      {!showPdfProps && (
        <FormControl>
          <FormLabel component='legend'>
            <Trans>List type</Trans>
          </FormLabel>
          <RadioGroup
            defaultValue='singleselect'
            value={picklistType || 'singleselect'}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.picklistType = e.currentTarget.value
              // delete toSet.connectedField
              delete toSet.requiredAll
              delete toSet.componentWidth
              if (e.currentTarget.value !== 'multiselect') {
                if (toSet.options) {
                  toSet.options.forEach(option => {
                    delete option.clearOnSelect
                  })
                }
                delete toSet.maxOptions
              }
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          >
            <FormControlLabel
              disabled={
                Boolean(
                  connectedField && connectedField.type !== 'multipicklist'
                ) || forceReadOnly
              }
              value='multiselect'
              control={<Radio />}
              label={<Trans>Multiselect</Trans>}
            />
            {
              <FormControlLabel
                disabled={
                  Boolean(
                    connectedField && connectedField.type === 'multipicklist'
                  ) || forceReadOnly
                }
                value='singleselect'
                control={<Radio />}
                label={<Trans>Single select (Radio group)</Trans>}
              />
            }
            {
              <FormControlLabel
                disabled={
                  Boolean(
                    connectedField && connectedField.type === 'multipicklist'
                  ) || forceReadOnly
                }
                value='expandable'
                control={<Radio />}
                label={<Trans>Single select (Dropdown)</Trans>}
              />
            }
          </RadioGroup>
        </FormControl>
      )}

      <FormControlLabel
        disabled={!connectedField}
        style={{ marginBottom: 5 }}
        control={
          <Checkbox
            checked={Boolean(dynamic)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.dynamic = e.target.checked
              if (e.target.checked) {
                toSet.options = []
              }
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        }
        label={<Trans>Dynamic picklist</Trans>}
      />
      {picklistType === 'multiselect' && !readOnly && !showPdfProps && (
        <TextField
          variant='outlined'
          label={<Trans>Max options to select</Trans>}
          fullWidth
          value={maxOptions || ''}
          InputProps={{ inputComponent: NumberFormatDefault }}
          onChange={e => {
            const toSet = { ...typeProps }
            toSet.maxOptions = e.target.value
            dispatch({
              type: 'FIELD',
              injectable,
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        />
      )}

      {(readOnly || showPdfProps) && (
        <Alert severity='info' variant='outlined'>
          <Trans>
            For read only view all options selected by user will be displayed
            regardless of configuration. If element is marked as Dynamic
            Picklist it will use labels from Selesforce. If it is not it will
            use labels configured below
          </Trans>
        </Alert>
      )}

      {!dynamic && (
        <div style={{ marginTop: 5 }}>
          <Grid container direction='row' alignItems='center'>
            <Typography>
              <Trans>Picklist options</Trans>
            </Typography>
            <IconButton
              onClick={() => {
                options.push({ titleEN: '', titleFR: '', apiValue: '' })
                typeProps.options = options
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...typeProps }
                })
              }}
            >
              <Icon>add</Icon>
            </IconButton>
            <Button
              disabled={!connectedField}
              style={{ marginLeft: 10 }}
              color='primary'
              variant='contained'
              onClick={() => {
                const fieldInfo = fieldsMap[connectedField.name]
                const toSet = fieldInfo?.picklistValues
                  .map(sfObj => {
                    if (sfObj.active) {
                      const french = sfObj.labelLang !== 'en_US'
                      const toRet = {
                        titleEN: french ? '' : sfObj.label,
                        titleFR: french ? sfObj.label : '',
                        apiValue: sfObj.value
                      }

                      return toRet
                    }
                  })
                  .filter(item => item)
                typeProps.options = toSet || []
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...typeProps }
                })
              }}
            >
              <Trans>Use values from object definition</Trans>
            </Button>
          </Grid>
          <Grid container direction='column'>
            {options.map((item, index) => {
              const selectActions = item.selectActions || []
              return (
                <div key={index}>
                  <Grid
                    item
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    wrap='nowrap'
                  >
                    {index + 1 + '. '}
                    <Grid item style={{ flex: 1, padding: 10 }}>
                      <Grid container direction='row'>
                        <Grid item xs={!isConnected ? 6 : 4}>
                          <TextField
                            variant='outlined'
                            label={<Trans>Option label - english</Trans>}
                            fullWidth
                            value={item.titleEN}
                            onChange={e => {
                              const toSet = { ...typeProps }
                              toSet.options[index].titleEN =
                                e.currentTarget.value
                              dispatch({
                                type: 'FIELD',
                                injectable,
                                depth: depth.split('.'),
                                fieldName: 'typeProps',
                                fieldValue: toSet
                              })
                              // setOptionEN(e.currentTarget.value)
                            }}
                          />
                        </Grid>
                        <Grid item xs={!isConnected ? 6 : 4}>
                          <TextField
                            variant='outlined'
                            label={<Trans>Option label - french</Trans>}
                            fullWidth
                            value={item.titleFR}
                            onChange={e => {
                              const toSet = { ...typeProps }
                              toSet.options[index].titleFR =
                                e.currentTarget.value
                              dispatch({
                                type: 'FIELD',
                                injectable,
                                depth: depth.split('.'),
                                fieldName: 'typeProps',
                                fieldValue: toSet
                              })
                              // setOptionFR(e.currentTarget.value)
                            }}
                          />
                        </Grid>
                        {isConnected && (
                          <Grid item xs={4}>
                            <TextField
                              variant='outlined'
                              label={<Trans>API value</Trans>}
                              fullWidth
                              value={item.apiValue}
                              onChange={e => {
                                const toSet = { ...typeProps }
                                toSet.options[index].apiValue =
                                  e.currentTarget.value
                                dispatch({
                                  type: 'FIELD',
                                  injectable,
                                  depth: depth.split('.'),
                                  fieldName: 'typeProps',
                                  fieldValue: toSet
                                })
                                // setOptionFR(e.currentTarget.value)
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <div style={{ width: 50 }}>
                      <IconButton
                        disabled={index === 0}
                        onClick={() => {
                          const toSet = { ...typeProps }
                          const toMove = toSet.options[index]
                          const toReplace = toSet.options[index - 1]
                          toSet.options[index - 1] = toMove
                          toSet.options[index] = toReplace
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                      >
                        <Icon>arrow_upward</Icon>
                      </IconButton>
                    </div>

                    <div style={{ width: 50 }}>
                      <IconButton
                        disabled={index === typeProps.options.length - 1}
                        onClick={() => {
                          const toSet = { ...typeProps }
                          const toMove = toSet.options[index]
                          const toReplace = toSet.options[index + 1]
                          toSet.options[index + 1] = toMove
                          toSet.options[index] = toReplace
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                      >
                        <Icon>arrow_downward</Icon>
                      </IconButton>
                    </div>

                    <div style={{ width: 50 }}>
                      <IconButton
                        onClick={() => {
                          const toSet = { ...typeProps }
                          toSet.options.splice(index, 1)
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </div>
                  </Grid>
                  {!(forceReadOnly || readOnly) && !showPdfProps && (
                    <>
                      <Grid container direction='row'>
                        <FormControlLabel
                          style={{ marginLeft: 15 }}
                          control={
                            <Checkbox
                              checked={Boolean(item.requireDetails)}
                              onChange={e => {
                                const toSet = { ...typeProps }
                                toSet.options[index].requireDetails =
                                  e.target.checked
                                dispatch({
                                  type: 'FIELD',
                                  injectable,
                                  depth: depth.split('.'),
                                  fieldName: 'typeProps',
                                  fieldValue: { ...toSet }
                                })
                              }}
                            />
                          }
                          label={<Trans>Require details</Trans>}
                        />
                        {picklistType === 'multiselect' && (
                          <FormControlLabel
                            style={{ marginLeft: 15 }}
                            control={
                              <Checkbox
                                checked={Boolean(item.clearOnSelect)}
                                onChange={e => {
                                  const toSet = { ...typeProps }
                                  toSet.options[index].clearOnSelect =
                                    e.target.checked
                                  dispatch({
                                    type: 'FIELD',
                                    injectable,
                                    depth: depth.split('.'),
                                    fieldName: 'typeProps',
                                    fieldValue: { ...toSet }
                                  })
                                }}
                              />
                            }
                            label={
                              <Trans>
                                Clear and disable other options on select
                              </Trans>
                            }
                          />
                        )}
                        <FormControlLabel
                          style={{ marginLeft: 15 }}
                          control={
                            <Checkbox
                              checked={Boolean(item.hasTooltip)}
                              onChange={e => {
                                const toSet = { ...typeProps }
                                toSet.options[index].hasTooltip =
                                  e.target.checked
                                delete toSet.options[index].tooltipEN
                                delete toSet.options[index].tooltipFR
                                dispatch({
                                  type: 'FIELD',
                                  injectable,
                                  depth: depth.split('.'),
                                  fieldName: 'typeProps',
                                  fieldValue: { ...toSet }
                                })
                              }}
                            />
                          }
                          label={<Trans>Has tooltip?</Trans>}
                        />
                        {Boolean(item.hasTooltip) && (
                          <Grid
                            container
                            direction='row'
                            style={{ padding: 20, marginRight: 35 }}
                          >
                            <Grid xs={6}>
                              <TextField
                                variant='outlined'
                                label={<Trans>Option tooltip - english</Trans>}
                                fullWidth
                                value={item.tooltipEN}
                                onChange={e => {
                                  const toSet = { ...typeProps }
                                  toSet.options[index].tooltipEN =
                                    e.currentTarget.value
                                  dispatch({
                                    type: 'FIELD',
                                    injectable,
                                    depth: depth.split('.'),
                                    fieldName: 'typeProps',
                                    fieldValue: toSet
                                  })
                                }}
                              />
                            </Grid>
                            <Grid xs={6}>
                              <TextField
                                variant='outlined'
                                label={<Trans>Option tooltip - french</Trans>}
                                fullWidth
                                value={item.tooltipFR}
                                onChange={e => {
                                  const toSet = { ...typeProps }
                                  toSet.options[index].tooltipFR =
                                    e.currentTarget.value
                                  dispatch({
                                    type: 'FIELD',
                                    injectable,
                                    depth: depth.split('.'),
                                    fieldName: 'typeProps',
                                    fieldValue: toSet
                                  })
                                }}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      {!showPdfProps && (
                        <ConfigureSelectActions
                          depth={depth}
                          typeProps={typeProps}
                          id={id}
                          index={index}
                          french={french}
                          selectActions={selectActions}
                        />
                      )}
                    </>
                  )}
                  {connectedObject && (
                    <div
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingBottom: 10
                      }}
                    >
                      <Autocomplete
                        freeSolo={false}
                        value={
                          options[index].connectedField
                            ? options[index].connectedField.label
                            : null
                        }
                        onChange={(e, value) => {
                          const toSet = { ...typeProps }
                          let obj
                          fieldsToConnect.some(item => {
                            const bool = item.label === value
                            if (bool) {
                              obj = item
                            }
                            return bool
                          })
                          toSet.options[index].connectedField = obj
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                        style={{ marginTop: 15 }}
                        fullWidth
                        options={fieldsToConnect.map(item => item.label)}
                        renderInput={params => (
                          <TextField
                            variant='outlined'
                            {...params}
                            label={<Trans>Connected field</Trans>}
                          />
                        )}
                      />
                    </div>
                  )}
                  <Divider style={{ marginBottom: 5 }} />
                </div>
              )
            })}
          </Grid>
        </div>
      )}

      {showPdfProps && (
        <div style={{ marginTop: 10 }}>
          <Typography variant='h6'>
            <Trans>Pdf props</Trans>
          </Typography>
          <div style={{ padding: 5 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(pdfBorderDisplay)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.pdfBorderDisplay = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              }
              label={<Trans>Display with border</Trans>}
            />
          </div>
        </div>
      )}
    </Grid>
  )
}
