import { Trans, t } from '@lingui/macro'
import {
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import { deleteDocument, uploadFile } from 'app/services/sfAuth/sfData/sfFiles'
import { getNetwork } from 'app/services/sfAuth/sfData/sfUser'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { myI18n } from 'translation/I18nConnectedProvider'

const styles = theme => ({
  dropbox: {
    margin: '15px 0',
    border: '2px dashed #0085B8',
    padding: 35,
    backgroundColor: 'rgba(0, 133, 184, 0.05)',
    '&:hover': {
      backgroundColor: 'rgba(0, 133, 184, 0.15)',
      border: '3px dashed #0085B8',
      textWeight: 'bold'
    }
  },
  disabled: {
    margin: '20px 0',
    border: '2px dashed rgba(158, 158, 158)',
    padding: 35,
    backgroundColor: 'rgba(245, 245, 245, 0.05)'
  }
})
export const dropboxStyles = makeStyles(styles)

export const FileDropzone = ({
  disabled,
  handleUpload = () => {},
  ...props
}) => {
  const classes = dropboxStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const handleOnDrop = (files, e) => {
    const file = files[0]
    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = e => {
      console.log('file reading has failed', e)
      enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
        variant: 'error'
      })
    }
    reader.onload = () => {
      const binaryStr = reader.result
      var data = reader.result
      handleUpload({ ...file, data })
      const uploadingKey = enqueueSnackbar(<Trans>FILE_UPLOAD_SUCCESS</Trans>, {
        variant: 'info'
      })
      const handleError = () => {
        closeSnackbar(uploadingKey)
      }
      if (binaryStr.byteLength > 52428800) {
        enqueueSnackbar(<Trans>Maximum file size is 52 MB</Trans>, {
          variant: 'error'
        })
        handleError()
      }
    }
    reader.readAsArrayBuffer(file)
  }

  return (
    <Dropzone
      disabled={disabled}
      multiple={false}
      maxFiles={1}
      onDrop={handleOnDrop}
      {...props}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={disabled ? classes.disabled : classes.dropbox}
        >
          <section>
            <input {...getInputProps()} />
            <div
              style={{
                textAlign: 'center'
              }}
            >
              <Grid
                container
                direction='row'
                alignItems='center'
                justify='center'
              >
                <Icon style={{ marginRight: 10 }}>upload</Icon>
                <Trans>Drag 'n' drop file here, or click to select file</Trans>
              </Grid>
            </div>

            <div />
          </section>
        </div>
      )}
    </Dropzone>
  )
}

export const UploadFilesField = ({
  files = [],
  parentId,
  handleChange,
  onRemove,
  disabled
}) => {
  const classes = dropboxStyles()
  const [network, setNetwork] = useState()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    getNetwork().then(network => {
      setNetwork(network)
    })
  }, [])

  const handleOnDrop = ({ files, id }) => {
    const listCount = files.length
    const uploadId = listCount + 1
    const file = files[0]
    let uploadedName = file.name

    const newValue = [...files]
    const fakeId = crypto.randomBytes(8).toString('hex')
    newValue.push({
      uploadId,
      name: uploadedName,
      progress: true,
      actionId: fakeId
    })

    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = e => {
      console.log('file reading has failed', e)
      enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
        variant: 'error'
      })
      const newValue = [...files]
      newValue.some((file, index) => {
        if (file.actionId === fakeId) {
          newValue.splice(index, 1)
          return true
        }
        return false
      })
    }
    reader.onload = () => {
      const binaryStr = reader.result
      console.log('binary', binaryStr, reader)
      const uploadingKey = enqueueSnackbar(<Trans>Uploading File</Trans>, {
        variant: 'info'
      })
      const handleError = () => {
        const newValue = [...files]
        newValue.some((file, index) => {
          if (file.actionId === fakeId) {
            newValue.splice(index, 1)
            return true
          }
          return false
        })
        closeSnackbar(uploadingKey)
      }
      if (binaryStr.byteLength > 52428800) {
        enqueueSnackbar(<Trans>Maximum file size is 52 MB</Trans>, {
          variant: 'error'
        })
        handleError()
      }
      let tagsString = id

      if (parentId) {
        uploadFile(
          {
            name: uploadedName,
            tags: tagsString,
            uploadId: parentId
          },
          binaryStr,
          network.Id,
          true
        )
          .then(uploadedFile => {
            enqueueSnackbar(<Trans>Uploaded File</Trans>, {
              variant: 'success'
            })
            const newValue = [...files]
            newValue.some((file, index) => {
              if (file.actionId === fakeId) {
                newValue[index] = {
                  id: uploadedFile.Id,
                  tags: tagsString,
                  uploadId,
                  name: uploadedName
                }
                return true
              }
              return false
            })
          })
          .catch(error => {
            console.warn('cant upload file', error)
            enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
              variant: 'error'
            })
            handleError()
          })
      }
    }
    reader.readAsArrayBuffer(file)
  }

  const onRemoveItem = ({ id, files, index }) => {
    enqueueSnackbar(<Trans>Deleting File</Trans>, {
      variant: 'info'
    })
    const fakeId = crypto.randomBytes(8).toString('hex')
    // const newValue = [...formikRef.current.values[fieldId]]
    // newValue[index].deleting = true
    // newValue[index].actionId = fakeId
    // setFieldValue(fieldId, newValue)

    deleteDocument(id).then(
      result => {
        enqueueSnackbar(<Trans>Deleted File</Trans>, {
          variant: 'success'
        })
        const newValue = [...files]
        newValue.some((file, index) => {
          if (file.actionId === fakeId) {
            newValue.splice(index, 1)
            return true
          }
          return false
        })
        //handleChange(newValue)
      },
      reject => {
        enqueueSnackbar(<Trans>Error ocurred while deleting file!</Trans>, {
          variant: 'error'
        })
        const newFiles = [...files]
        newFiles.some((file, index) => {
          if (file.actionId === fakeId) {
            delete newFiles[index].actionId
            delete newFiles[index].deleting
            return true
          }
          return false
        })
      }
    )
  }

  return (
    <div>
      <TableContainer>
        <Table style={{ whiteSpace: 'pre' }}>
          <colgroup>
            <col width='85%' />
            <col width='15%' />
          </colgroup>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f5f5f5' }}>
              {[t`Name`, t`Remove`].map((item, i) => (
                <TableCell key={i}>
                  <p
                    style={{
                      textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      paddingRight: i === 1 && 20
                    }}
                  >
                    {myI18n._(item)}
                  </p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file, index) => {
              const iconDisabled = file.progress || file.deleting
              return [
                <TableRow key={index}>
                  <TableCell>
                    <div style={{ paddingLeft: 35 }}>
                      <Link color='primary' href={file.url}>
                        {file.name}
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingRight: 20
                      }}
                    >
                      <Tooltip title={myI18n._(t`Remove file`)}>
                        <IconButton
                          variant='filled'
                          disabled={iconDisabled}
                          onClick={() => {
                            onRemoveItem({
                              id: file.id,
                              files,
                              index
                            })
                          }}
                        >
                          <Icon
                            className={
                              iconDisabled
                                ? ''
                                : 'material-icons MuiIcon-root MuiIcon-colorError'
                            }
                            variant='filled'
                          >
                            delete
                          </Icon>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>,
                file.progress && (
                  <TableRow key={files.length + 1}>
                    <TableCell colSpan={3}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )
              ]
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dropzone
        disabled={disabled}
        multiple={false}
        maxFiles={1}
        onDrop={handleOnDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={disabled ? classes.disabled : classes.dropbox}
          >
            <section>
              <div>
                <input {...getInputProps()} />
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='center'
                  >
                    <Icon style={{ marginRight: 10 }}>upload</Icon>
                    <Trans>
                      Drag 'n' drop file here, or click to select file
                    </Trans>
                  </Grid>
                </div>
              </div>
              <div />
            </section>
          </div>
        )}
      </Dropzone>
    </div>
  )
}
