import React from 'react'
import { Redirect } from 'react-router-dom'

import utilitiesRoutes from './views/utilities/UtilitiesRoutes'
import sessionRoutes from './views/sessions/SessionRoutes'
import formsRoutes from './views/forms/FormsRoutes'
import surveyRoutes from './views/surveys/SurveyRoutes'
import grantsRoutes from './views/grants/GrantsRoutes'
import internalRoutes from './views/internal/InternalRoutes'
import vivreEnVilleRoutes from './views/vivre-en-ville/VivreEnVilleRoutes'
import sfOauthConfig from './services/sfAuth/sfAuthConfig'

const redirectRoute = [
  {
    path: '/',
    exact: true,
    // component: () => <Redirect to="/dashboard/learning-management" />
    // TODO: handle redirect from login and default to home
    // component: () => <Redirect to='/grants/ApplicationList' />
    component: () => <Redirect to='/grants/GrantsHome' />
  }
]

const errorRoute = [
  {
    component: () => <Redirect to='/session/404' />
  }
]

const routes = [
  ...surveyRoutes,
  ...internalRoutes,
  ...grantsRoutes,
  ...sessionRoutes,
  ...utilitiesRoutes,
  ...formsRoutes,
  ...redirectRoute,
  ...errorRoute,
  !sfOauthConfig.isIcce && [...vivreEnVilleRoutes]
]

export default routes
