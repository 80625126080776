/**
 * Created by Michał Stawski on 10.03.2021.
 */

export const satScoreCardCoop = [
  {
    'QuestionID': '3a571deca452ffd90626398dde7c8fbc083e1954.0',
    'Parent Text': 'To what degree do you agree with the following statements?',
    'Text EN': 'To function well, a housing co-op must have competent, democratic and scrupulous governance and management.',
    'Created date': 'undefined',
    'Section': 'SECTION A: General information',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '3a571deca452ffd90626398dde7c8fbc083e1954.1',
    'Parent Text': 'To what degree do you agree with the following statements?',
    'Text EN': 'To provide long-term quality housing, it is important to plan for maintenance and renovations.',
    'Created date': 'undefined',
    'Section': 'SECTION A: General information',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '3a571deca452ffd90626398dde7c8fbc083e1954.2',
    'Parent Text': 'To what degree do you agree with the following statements?',
    'Text EN': 'Transparent financial management that anticipates future expenses, ensures appropriate housing charge collection, and clear and up-to-date accounting is necessary to maintain the best possible housing charge structure and to avoid sudden housing charge increases.',
    'Created date': 'undefined',
    'Section': 'SECTION A: General information',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '3a571deca452ffd90626398dde7c8fbc083e1954.3',
    'Parent Text': 'To what degree do you agree with the following statements?',
    'Text EN': 'The co-op model requires active participation of members in decision-making and co-op operations.',
    'Created date': 'undefined',
    'Section': 'SECTION A: General information',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '3a571deca452ffd90626398dde7c8fbc083e1954.4',
    'Parent Text': 'To what degree do you agree with the following statements?',
    'Text EN': 'The co-op model values social inclusion and engagement in the community.',
    'Created date': 'undefined',
    'Section': 'SECTION A: General information',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '209f2c71610c236b7e4d9d1f6995a4279b33fe3e',
    'Parent Text': '',
    'Text EN': 'How important is governance for your co-op?',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b8f282026212bf5facfae07ee9a642037d529531.0',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'The board meets regularly (e.g., monthly or quarterly).',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b8f282026212bf5facfae07ee9a642037d529531.1',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'Board meeting minutes are kept, filed and accessible.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b8f282026212bf5facfae07ee9a642037d529531.2',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'The board has written policies and procedures.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b8f282026212bf5facfae07ee9a642037d529531.3',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'The board reviews its policies and procedures regularly.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b8f282026212bf5facfae07ee9a642037d529531.4',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'Board members have fixed-term mandates (e.g., 3 years).',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b8f282026212bf5facfae07ee9a642037d529531.5',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'The board ensures membership renewal (it has a succession plan).',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b8f282026212bf5facfae07ee9a642037d529531.6',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'The training or integration of new board members is ensured.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b8f282026212bf5facfae07ee9a642037d529531.7',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'Where appropriate, the board is made up of members from different properties owned by the co-op.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b8f282026212bf5facfae07ee9a642037d529531.8',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'Board members are also involved with other community groups or community organizations.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b8f282026212bf5facfae07ee9a642037d529531.9',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'Board members have complementary skills (management, accounting, law, social work, etc.).',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '21d57d6f9919d48438b72e2933d3cea80d654b77.0',
    'Parent Text': 'To what extent do each of the following statements describe the action and functioning of your board?',
    'Text EN': 'The board reviews the monthly/ quarterly financial statements, meets with the auditor to audit the annual financial statements, and reviews other corporate reports.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': 'false',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '21d57d6f9919d48438b72e2933d3cea80d654b77.1',
    'Parent Text': 'To what extent do each of the following statements describe the action and functioning of your board?',
    'Text EN': 'The board ensures the cooperative meets all of its legal and compliance obligations.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': 'false',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '21d57d6f9919d48438b72e2933d3cea80d654b77.2',
    'Parent Text': 'To what extent do each of the following statements describe the action and functioning of your board?',
    'Text EN': 'The board has a strategic plan with clear objectives and assesses progress toward meeting them. It weighs its decisions carefully to ensure they are consistent with the plan.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': 'false',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '21d57d6f9919d48438b72e2933d3cea80d654b77.3',
    'Parent Text': 'To what extent do each of the following statements describe the action and functioning of your board?',
    'Text EN': 'Board members represent the co-op in the surrounding environment, in committees or in consultations.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': 'false',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '4f5a0c36079b2c595de21a121e2fc0bba0129816.0',
    'Parent Text': 'Is your co-op a member of a federation/ alliance/ association of housing co-ops? Does it participate in federation activities and use its services?',
    'Text EN': 'The co-op is a member of a federation housing co-ops.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '4f5a0c36079b2c595de21a121e2fc0bba0129816.1',
    'Parent Text': 'Is your co-op a member of a federation/ alliance/ association of housing co-ops? Does it participate in federation activities and use its services?',
    'Text EN': 'Board members and management attend meetings or participate in federation events.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '4f5a0c36079b2c595de21a121e2fc0bba0129816.2',
    'Parent Text': 'Is your co-op a member of a federation/ alliance/ association of housing co-ops? Does it participate in federation activities and use its services?',
    'Text EN': 'The co-op uses the training and support services provided by the federation.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'fcf04a51e4ce3b30f58037ee5c0f9304c4682f16',
    'Parent Text': '',
    'Text EN': 'Does your co-op have any paid staff?',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b884b263d73f7e051b99787cde720565b48b910d',
    'Parent Text': '',
    'Text EN': 'Do you contract out work to a property management firm?',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '41e4c36d454a51dee5aaa9c7dd45795c52a12700.0',
    'Parent Text': 'How many employees (full time and part time) are employed by your co-op?',
    'Text EN': 'Number of manager(s):',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '41e4c36d454a51dee5aaa9c7dd45795c52a12700.1',
    'Parent Text': 'How many employees (full time and part time) are employed by your co-op?',
    'Text EN': 'Number of other employees:',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '142079068e0617142d18e799590c1d5333811e27.0',
    'Parent Text': 'To what extent do each of the following statements describe the management of your co-op and its employees?',
    'Text EN': 'The manager(s), our employees or contracted staff have the training and experience to properly manage our property(ies), operations and planning.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '142079068e0617142d18e799590c1d5333811e27.1',
    'Parent Text': 'To what extent do each of the following statements describe the management of your co-op and its employees?',
    'Text EN': 'Our co-op has implemented controls and processes tailored to its needs to eliminate the risk of error and/ or fraud.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '142079068e0617142d18e799590c1d5333811e27.2',
    'Parent Text': 'To what extent do each of the following statements describe the management of your co-op and its employees?',
    'Text EN': 'The co-op has updated its computer hardware, software and security processes and ensures staff are properly trained.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '142079068e0617142d18e799590c1d5333811e27.3',
    'Parent Text': 'To what extent do each of the following statements describe the management of your co-op and its employees?',
    'Text EN': 'Human resources policies have been put in place to recruit and manage employees, and ensure they receive all the required training.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '142079068e0617142d18e799590c1d5333811e27.4',
    'Parent Text': 'To what extent do each of the following statements describe the management of your co-op and its employees?',
    'Text EN': 'Our management prepares regular follow-up reports to the board and ensures that all reports are prepared respecting both best practice standards and established deadlines.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c2404c3ceedb80e949744310cc72a752b9e8e0aa',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the day-to-day management of your co-op by the management staff?',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': 'false',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '36f0abc63b887b49c5a06e48720b29a355a7d0ee',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the day-to-day management of your co-op by the board?',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'GOVERNANCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '59bd57821c37f713736b5b296895e2c28f868fb1',
    'Parent Text': '',
    'Text EN': 'General information',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e557e6c13eb02ca23e9647c3a694d61e1c7ea4ee',
    'Parent Text': '',
    'Text EN': 'Some co-ops have several buildings. Often, these assets have been acquired through different funding programs or phases of programs over time. The management of these assets is subject to different agreements and contracts with funders. Has your co-opâ€™s development been carried out in different projects or in different phases?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0cb55d257555a96d4e8f5f9d25190bc56295a980',
    'Parent Text': '',
    'Text EN': 'If you have multiple properties in various locations, please select one that, to the extent possible, is representative of your other properties in terms of size, age and condition, and profile of members to answer the questions in this section.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'fc25534bb818353ed992107148d35b10bcf3787e.0',
    'Parent Text': 'What is the property address?',
    'Text EN': 'Street address:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'fc25534bb818353ed992107148d35b10bcf3787e.1',
    'Parent Text': 'What is the property address?',
    'Text EN': 'Municipality:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'fc25534bb818353ed992107148d35b10bcf3787e.2',
    'Parent Text': 'What is the property address?',
    'Text EN': 'Province/territory:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'fc25534bb818353ed992107148d35b10bcf3787e.3',
    'Parent Text': 'What is the property address?',
    'Text EN': 'Postal code:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '35b7a09cede6bbf9de7b921b31eef57a034a6b52',
    'Parent Text': '',
    'Text EN': 'Please indicate the number of units on this property:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '509811544c9206804cdcc257ed9a278fc961806c.0',
    'Parent Text': 'Breakdown (in percentage) of the housing charges listed below in this property:',
    'Text EN': 'Rent-geared-to-income (RGI)* (%):',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '509811544c9206804cdcc257ed9a278fc961806c.1',
    'Parent Text': 'Breakdown (in percentage) of the housing charges listed below in this property:',
    'Text EN': 'Housing charges below market (%):',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '509811544c9206804cdcc257ed9a278fc961806c.2',
    'Parent Text': 'Breakdown (in percentage) of the housing charges listed below in this property:',
    'Text EN': 'Market rate housing charges (%):',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'a4eb920d4af185a02ed8ff9eea47271b0807a09e',
    'Parent Text': '',
    'Text EN': 'Does your co-op have an operating agreement* or subsidy agreement** for this property with the CMHC, the province, territory or city?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5781a8582e1b4053761d8c80b598847392e0d30c',
    'Parent Text': '',
    'Text EN': 'How much time is left before the end of this agreement?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'a7198becd12e6896abce985073f37a3e4cc130ae',
    'Parent Text': '',
    'Text EN': 'Does your co-op have an agreement with a government entity* to reduce housing charges for certain units on this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5c7ac9e7e09ace1d29d8154dfa38fe08cd476398',
    'Parent Text': '',
    'Text EN': 'How much time is left before the end of this agreement?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '92b0c5a9399b46920e9e4b22ef54f713b5da832e',
    'Parent Text': '',
    'Text EN': 'Does your co-op have a service-funding agreement with the province or municipality for this propertyâ€™s members who need support services*?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '00521077b485f1f2fe0050afccb7b90402732be8',
    'Parent Text': '',
    'Text EN': 'How much time is left before the end of this agreement?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '83f1ba246cd80494ed0a2d2bf00d92a58d58acdb',
    'Parent Text': '',
    'Text EN': 'How is the overall condition of the building(s) on this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'PROPERTY ',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '327c5faa0e2912e96097961c73c9df73d1398c4b',
    'Parent Text': '',
    'Text EN': 'Property Management',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '24284141b85c3ecb13e0e003662be627ca7fbcb2',
    'Parent Text': '',
    'Text EN': 'How important is property management for your co-op?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'PROPERTY ',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c6f8f6e09dd05692f6ccdb313fc7ea6683b14d1f',
    'Parent Text': '',
    'Text EN': 'Overall, the property management of this property is:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'PROPERTY ',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '1078dcf1282625868ac26355854f75fd2d2387d2',
    'Parent Text': '',
    'Text EN': 'When was this propertyâ€™s last full building condition assessment (BCA)?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'PROPERTY ',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8b9d35a8e409688861a7b20f98fe8de9a32404e5',
    'Parent Text': '',
    'Text EN': 'Have you completed the work recommended in the most recent assessment?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'PROPERTY ',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '60cafce6cf10f4e5466f028325b095a67261099f',
    'Parent Text': '',
    'Text EN': 'What is your approach to preventative maintenance and proactive repairs on this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'PROPERTY ',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '4aefa79542a1f85ca1baaaef28745c23c44b1339',
    'Parent Text': '',
    'Text EN': 'What is your approach to member feedback?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'PROPERTY ',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '81a8f22eedab9fe28001a6175a8652b4d2030591',
    'Parent Text': '',
    'Text EN': 'What is your approach to member requests?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'PROPERTY ',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e30eb1837a44375720acf1edffc5d980fe446153',
    'Parent Text': '',
    'Text EN': 'Financial management',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0e948b2f4505a1fb9c41dccd2a8b0a719e2c9006',
    'Parent Text': '',
    'Text EN': 'How important is financial management for your co-op?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'FINANCIAL',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '12351de13f94f584f8f84af2c383050e47c5d81d',
    'Parent Text': '',
    'Text EN': 'Overall, the financial health of this property is:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'fc6eca9ac1dd929f4e3d614a298cab5a9453b93b',
    'Parent Text': '',
    'Text EN': 'In the past five years, have you made annual contributions to a Capital Replacement Reserve Fund for this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '9215a86a4169c8e7cd9190991c2b53a972878793.0',
    'Parent Text': 'How well do the following statements describe this property?',
    'Text EN': 'The property is financially viable (e.g., revenues cover all expenses, including routine repairs and contributions to the capital reserve).',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '9215a86a4169c8e7cd9190991c2b53a972878793.1',
    'Parent Text': 'How well do the following statements describe this property?',
    'Text EN': 'Vacancy losses are minimal and/ or have declined over the past 5 years.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '9215a86a4169c8e7cd9190991c2b53a972878793.2',
    'Parent Text': 'How well do the following statements describe this property?',
    'Text EN': 'Housing charge arrears are close to zero or have been declining over the past five years.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '9215a86a4169c8e7cd9190991c2b53a972878793.3',
    'Parent Text': 'How well do the following statements describe this property?',
    'Text EN': 'The capital reserve is enough to complete the planned renovations.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '2fc1d52359b6836241e01c6e8d31df325d5e4c4e',
    'Parent Text': '',
    'Text EN': 'Do you have an Eviction Prevention Policy? (Including, for example, repayment schedules, access to a rent bank, mediation, help to apply for income support, etc.)',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0,6',
    'Option 3': '1,3',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '15c3124c397afd5204e76161f40e578624b3e8f3.0',
    'Parent Text': 'To what extent is the current housing charge structure financially sustainable in the immediate and longer term?',
    'Text EN': 'The current mix of rent-geared-to-income (RGI)* and non-subsidized housing charges is sustainable to the extent that we have subsidy agreements in place.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '15c3124c397afd5204e76161f40e578624b3e8f3.1',
    'Parent Text': 'To what extent is the current housing charge structure financially sustainable in the immediate and longer term?',
    'Text EN': 'When units become available, we set housing charges for new members to ensure long-term financially sustainability even if subsidies end.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '15c3124c397afd5204e76161f40e578624b3e8f3.2',
    'Parent Text': 'To what extent is the current housing charge structure financially sustainable in the immediate and longer term?',
    'Text EN': 'We plan to assess the housing charge structure at the end of the operating agreements**.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '15c3124c397afd5204e76161f40e578624b3e8f3.3',
    'Parent Text': 'To what extent is the current housing charge structure financially sustainable in the immediate and longer term?',
    'Text EN': 'Our housing charge structure allows us to capitalize for further development and/ or subsidize lower-income members.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8b363e446ffbe80a80ead3e26d48d79cef57f49a',
    'Parent Text': '',
    'Text EN': 'Has your co-op developed a plan to ensure that this property remains financially viable once the operating agreement has expired?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b48bceebd96af0fc13c1024488dd8f10e84368e4',
    'Parent Text': '',
    'Text EN': 'Member involvement and community relations',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '4ccb04d699302f97c59292bd8fbceca3a8912c09',
    'Parent Text': '',
    'Text EN': 'How important is it for you that members be involved in the co-op?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': '1',
    'Option 2': '0,5',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '4354cac94cfda897951233336a1570735cd24e1d',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the involvement of members in this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e348482a4518bf3a7e8fb2427e36decfde10683b.0',
    'Parent Text': 'What is the level of involvement of this propertyâ€™s members in the following co-op activities?',
    'Text EN': 'Participation in the board and/or committees',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e348482a4518bf3a7e8fb2427e36decfde10683b.1',
    'Parent Text': 'What is the level of involvement of this propertyâ€™s members in the following co-op activities?',
    'Text EN': 'Organization of regular events/activities',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e348482a4518bf3a7e8fb2427e36decfde10683b.2',
    'Parent Text': 'What is the level of involvement of this propertyâ€™s members in the following co-op activities?',
    'Text EN': 'Preparation of newsletters, print, digital or internet publications',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e348482a4518bf3a7e8fb2427e36decfde10683b.3',
    'Parent Text': 'What is the level of involvement of this propertyâ€™s members in the following co-op activities?',
    'Text EN': 'Reglar use of common space for events and activities',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e348482a4518bf3a7e8fb2427e36decfde10683b.4',
    'Parent Text': 'What is the level of involvement of this propertyâ€™s members in the following co-op activities?',
    'Text EN': 'Participation in events and social activities.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6bc30b36a251578e8df47df660941983e828dd1d',
    'Parent Text': '',
    'Text EN': 'How important is it for your co-op that members, board members and staff be involved in the surrounding community?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': '1',
    'Option 2': '0,5',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b7f65eb44c4027fcfd931a89ee40c5e893a5a41c',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the involvement of members, board members and staff in the surrounding community?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'ec7b3945d9bcbecbe45ea927bdc009489847f225.0',
    'Parent Text': 'What is the level of member, board member and staff involvement in the surrounding community for this property?',
    'Text EN': 'Participation in community events',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'ec7b3945d9bcbecbe45ea927bdc009489847f225.1',
    'Parent Text': 'What is the level of member, board member and staff involvement in the surrounding community for this property?',
    'Text EN': 'Use of common space for activities with community groups',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'ec7b3945d9bcbecbe45ea927bdc009489847f225.2',
    'Parent Text': 'What is the level of member, board member and staff involvement in the surrounding community for this property?',
    'Text EN': 'Shared common services with other community groups',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'ec7b3945d9bcbecbe45ea927bdc009489847f225.3',
    'Parent Text': 'What is the level of member, board member and staff involvement in the surrounding community for this property?',
    'Text EN': 'Membership in other community groups',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'ec7b3945d9bcbecbe45ea927bdc009489847f225.4',
    'Parent Text': 'What is the level of member, board member and staff involvement in the surrounding community for this property?',
    'Text EN': 'Participation in community initiatives to improve surrounding community',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5be32dc2903315361c4a446051eb719ae3a02804.0',
    'Parent Text': 'How would you describe diversity among the members who live in this property?',
    'Text EN': 'Economically',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5be32dc2903315361c4a446051eb719ae3a02804.1',
    'Parent Text': 'How would you describe diversity among the members who live in this property?',
    'Text EN': 'Linguistically',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5be32dc2903315361c4a446051eb719ae3a02804.2',
    'Parent Text': 'How would you describe diversity among the members who live in this property?',
    'Text EN': 'Culturally',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5be32dc2903315361c4a446051eb719ae3a02804.3',
    'Parent Text': 'How would you describe diversity among the members who live in this property?',
    'Text EN': 'Racially',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5be32dc2903315361c4a446051eb719ae3a02804.4',
    'Parent Text': 'How would you describe diversity among the members who live in this property?',
    'Text EN': 'At the age level',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5be32dc2903315361c4a446051eb719ae3a02804.5',
    'Parent Text': 'How would you describe diversity among the members who live in this property?',
    'Text EN': 'At the household type level',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'dff01c8fbcab2c47a5694cc2f4b9d57b3cfe1ad0',
    'Parent Text': '',
    'Text EN': 'Which of the following best describes accessibility in this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'bede4c7eaff10276e85bc595903c0db10709a3b9',
    'Parent Text': '',
    'Text EN': 'Has your co-op ever assessed the feasibility of improving or expanding the accessibility of this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for co-ops with more than one property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'df12af92ab9e1f822eda94a61b63c6d3777b5cd4',
    'Parent Text': '',
    'Text EN': 'General Information',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'dff2c733eef5c982fdf2778c344c690a166c6383',
    'Parent Text': '',
    'Text EN': 'Please indicate the number of units in the co-op:',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '40aaeedf78144d9407b88af6f2a38833126cea00.0',
    'Parent Text': 'Breakdown (in percentage) of the rents listed below in the co-op on this property:',
    'Text EN': 'Rent-geared-to-income (RGI)* (%):',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '40aaeedf78144d9407b88af6f2a38833126cea00.1',
    'Parent Text': 'Breakdown (in percentage) of the rents listed below in the co-op on this property:',
    'Text EN': 'Rents below market (%):',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '40aaeedf78144d9407b88af6f2a38833126cea00.2',
    'Parent Text': 'Breakdown (in percentage) of the rents listed below in the co-op on this property:',
    'Text EN': 'Market rents (%):',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '9797ddf8a779928efb559f7aa3d4c193024bc828',
    'Parent Text': '',
    'Text EN': 'Does your co-op have an operating agreement* or subsidy agreement** with the CMHC, the province, territory or city?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Yes',
    'Option 2': 'No',
    'Option 3': 'Unsure',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'f8fa6fc6ccbc1ee5188cf230f968fce763ca85dd',
    'Parent Text': '',
    'Text EN': 'If so, how much time is left before the end of this agreement?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Less than 2 years',
    'Option 2': 'Between 2 and 5 years',
    'Option 3': 'Between 5 and 10 years',
    'Option 4': 'More than 10 years',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e33d5f4356570704456a302f9a444c0023941ea6',
    'Parent Text': '',
    'Text EN': 'Does your co-op have a subsidy agreement* with a government entity to reduce rents for certain units?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Yes',
    'Option 2': 'No',
    'Option 3': 'Unsure',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e9b7a6ad6b126cc4fead07d083d605f5b76d46b3',
    'Parent Text': '',
    'Text EN': 'Does your co-op have a service funding agreement with the province or municipality for members in need of support services*?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '[object Object]',
    'Option 2': '[object Object]',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7225a4b2b5463bd64e13bfdbe7af31e2d5ab9829',
    'Parent Text': '',
    'Text EN': 'How long is this agreement?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'One year',
    'Option 2': '2-5 years',
    'Option 3': '5 to 10 years',
    'Option 4': '10 to 25 years',
    'Option 5': 'Over 25 years',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c3debb762cdf331276d6e7d03b959b126e4fd906',
    'Parent Text': '',
    'Text EN': 'How would you rate the overall condition of the building(s)?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'PROPERTY',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Excellent',
    'Option 2': 'Good',
    'Option 3': 'Acceptable',
    'Option 4': 'Bad',
    'Option 5': 'Very bad',
    'Option 6': 'Unsure',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '3efc929ea3a1f2a7c1ccee604f5e0f3802f96698',
    'Parent Text': '',
    'Text EN': 'Property management',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b1c9e142b84caed13e1d0303abd2d717a17d42a4',
    'Parent Text': '',
    'Text EN': 'What is the level of importance assigned by your co-operative to property management issues?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'PROPERTY',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': 'Very important',
    'Option 2': 'Somewhat important',
    'Option 3': 'Not important',
    'Option 4': 'Unsure',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '9f5df7b9d58982fe4e517d10da98fb13d53542aa',
    'Parent Text': '',
    'Text EN': 'Overall, you consider property management to be:',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'PROPERTY',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Excellent',
    'Option 2': 'Good',
    'Option 3': 'Acceptable',
    'Option 4': 'Bad',
    'Option 5': 'Very bad',
    'Option 6': 'Unsure',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '886eb63ab7ec095cbed0595895cf5b5d5f7a5ad9',
    'Parent Text': '',
    'Text EN': 'When did your co-op last have a full building condition assessment done?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'PROPERTY',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Less than 1 year ago',
    'Option 2': 'Between 1 and 2 years',
    'Option 3': 'Between 2 and 3 years',
    'Option 4': 'Between 3 and 5 years',
    'Option 5': 'More than 5 years',
    'Option 6': 'Unsure',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b40b085cefccbf7b138a4eefb4f0588d990cb316',
    'Parent Text': '',
    'Text EN': 'Have you completed the work recommended in the most recent evaluation?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'PROPERTY',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Most of the recommended work has been completed or is planned and, barring the unforeseen, should be completed according to the plan.',
    'Option 2': 'Only the most urgent work has been done.',
    'Option 3': 'The work is planned but not yet completed.',
    'Option 4': 'The work is not planned.',
    'Option 5': 'We have lost track of what the evaluation recommended.',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'f8aa3e28cfe68fb94a589ff7e93d883d620a4e2c',
    'Parent Text': '',
    'Text EN': 'Which approach best describes the execution of regular maintenance and repairs?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'PROPERTY',
    'modifier': '',
    'multiple': '',
    'Option 1': 'The work is completed according to the maintenance plan schedule and current repair budget. In addition, membersâ€™ requests are generally processed as quickly as possible.',
    'Option 2': 'While there is no set schedule for the maintenance plan, member requests are generally processed as soon as possible within available resources.',
    'Option 3': 'Whether or not there is a maintenance plan, member requests take time to process.',
    'Option 4': 'Most repairs are deferred due to budget reasons and the scope of the work.',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '112d8b854c89f7d76abe2a93e7864b19bfddd37d',
    'Parent Text': '',
    'Text EN': 'Financial Management',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '077ef1ece57726622a2cdab9aeede069cff49e72',
    'Parent Text': '',
    'Text EN': 'What is the level of importance assigned by your co-operative to financial management issues?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'FINANCIAL',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': 'Very important',
    'Option 2': 'Somewhat important',
    'Option 3': 'Not important',
    'Option 4': 'Unsure',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e37f80bc19f9703897b7ffbc28c22a0a65acbb87',
    'Parent Text': '',
    'Text EN': 'Overall, you consider the financial health of your co-op to be:',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Excellent',
    'Option 2': 'Good',
    'Option 3': 'Acceptable',
    'Option 4': 'Bad',
    'Option 5': 'Very bad',
    'Option 6': 'Unsure',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7f0db541f9f541f4fb5b00d66c84f98e80c53e5b',
    'Parent Text': '',
    'Text EN': 'Over the past five years, have you made annual contributions to a Capital Replacement Reserve Fund?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Yes, we have made at least one contribution every year.',
    'Option 2': 'We have contributed variable amounts to the reserve fund each year.',
    'Option 3': 'Our contributions to reserve funds have decreased year over year.',
    'Option 4': 'No, we did not contribute to a reserve fund.',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '72a51fef413d788aed4e570ff905810e1c65e379.0',
    'Parent Text': 'How well does the following describe your co-op?',
    'Text EN': 'It is financially viable (e.g., revenues cover all expenses, including routine repairs and contributions to the capital reserve).',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very well',
    'Option 2': 'Well',
    'Option 3': 'To some extent',
    'Option 4': 'Not at all',
    'Option 5': 'Donâ€™t know',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '72a51fef413d788aed4e570ff905810e1c65e379.1',
    'Parent Text': 'How well does the following describe your co-op?',
    'Text EN': 'Vacancy losses are minimal and/or have declined over the past 5 years.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very well',
    'Option 2': 'Well',
    'Option 3': 'To some extent',
    'Option 4': 'Not at all',
    'Option 5': 'Donâ€™t know',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '72a51fef413d788aed4e570ff905810e1c65e379.2',
    'Parent Text': 'How well does the following describe your co-op?',
    'Text EN': 'Rent arrears are close to zero or have been declining over the past five years.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very well',
    'Option 2': 'Well',
    'Option 3': 'To some extent',
    'Option 4': 'Not at all',
    'Option 5': 'Donâ€™t know',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '72a51fef413d788aed4e570ff905810e1c65e379.3',
    'Parent Text': 'How well does the following describe your co-op?',
    'Text EN': 'There is sufficient capital reserve to complete planned renovations.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very well',
    'Option 2': 'Well',
    'Option 3': 'To some extent',
    'Option 4': 'Not at all',
    'Option 5': 'Donâ€™t know',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6cc33186b707026b2b9b430b276c251ed2c84264.0',
    'Parent Text': 'To what extent is the current rent structure financially sustainable in the immediate and longer term?',
    'Text EN': 'The current mix of rent-geared-to-income (RGI)* and non-subsidized rent is sustainable to the extent that we have subsidy agreements in place.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'To a large extent',
    'Option 2': 'To some extent',
    'Option 3': 'Not at all',
    'Option 4': 'Donâ€™t know',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6cc33186b707026b2b9b430b276c251ed2c84264.1',
    'Parent Text': 'To what extent is the current rent structure financially sustainable in the immediate and longer term?',
    'Text EN': 'When units become available, we set rents for new members to ensure long-term financially sustainability even if subsidies end.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'To a large extent',
    'Option 2': 'To some extent',
    'Option 3': 'Not at all',
    'Option 4': 'Donâ€™t know',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6cc33186b707026b2b9b430b276c251ed2c84264.2',
    'Parent Text': 'To what extent is the current rent structure financially sustainable in the immediate and longer term?',
    'Text EN': 'We plan to assess the rent structure at the end of the operating agreements**.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'To a large extent',
    'Option 2': 'To some extent',
    'Option 3': 'Not at all',
    'Option 4': 'Donâ€™t know',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6cc33186b707026b2b9b430b276c251ed2c84264.3',
    'Parent Text': 'To what extent is the current rent structure financially sustainable in the immediate and longer term?',
    'Text EN': 'Our rent structure allows us to capitalize for further development and/or subsidize lower-income members.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'To a large extent',
    'Option 2': 'To some extent',
    'Option 3': 'Not at all',
    'Option 4': 'Donâ€™t know',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '873a6a670b1bc35f55db4ba2f0f4799311867f52',
    'Parent Text': '',
    'Text EN': 'If the property has a government operating agreement (CMHC, province, territory or city), has your co-op done a financial analysis and evaluated the options once the operating agreement has expired?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'FINANCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'We have an approved plan for the post-agreement.',
    'Option 2': 'A detailed plan is being developed but not yet approved.',
    'Option 3': 'Initial assessments identified options.',
    'Option 4': 'This work has been postponed.',
    'Option 5': 'Uncertain',
    'Option 6': 'Not applicable (the agreement for this project has already expired or there has never been an agreement).',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'cb03d60e29ecfb33be0fcd82699ce2b4b24039a2',
    'Parent Text': '',
    'Text EN': 'Member involvement and community relations',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b84a0e4d980aeb2024df1a726aae906a778bc15d',
    'Parent Text': '',
    'Text EN': 'What is the level of importance assigned by your organization for members to get involved in the co-op and play a role in the surrounding community?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': 'Very important',
    'Option 2': 'Somewhat important',
    'Option 3': 'Not important',
    'Option 4': 'Unsure',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c8e667ac935b0da030fe4d749bfa887aae88db0f',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the involvement of members?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Excellent',
    'Option 2': 'Good',
    'Option 3': 'Acceptable',
    'Option 4': 'Bad',
    'Option 5': 'Very bad',
    'Option 6': 'Unsure',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '00e83abd5c5800253d4146577bacb76e5ca985fc',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the involvement of members in the surrounding community?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Excellent',
    'Option 2': 'Good',
    'Option 3': 'Acceptable',
    'Option 4': 'Bad',
    'Option 5': 'Very bad',
    'Option 6': 'Unsure',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '77d8817ab554711ddee690bf971b589aa2ff367d.0',
    'Parent Text': 'What is the level of member involvement in co-op activities?',
    'Text EN': 'Participation in the board and/ or committees.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'High',
    'Option 2': 'Medium',
    'Option 3': 'Low',
    'Option 4': 'None',
    'Option 5': 'Unsure',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '77d8817ab554711ddee690bf971b589aa2ff367d.1',
    'Parent Text': 'What is the level of member involvement in co-op activities?',
    'Text EN': 'Organization of regular events/ activities.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'High',
    'Option 2': 'Medium',
    'Option 3': 'Low',
    'Option 4': 'None',
    'Option 5': 'Unsure',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '77d8817ab554711ddee690bf971b589aa2ff367d.2',
    'Parent Text': 'What is the level of member involvement in co-op activities?',
    'Text EN': 'Preparation of newsletters, print, digital or internet publications.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'High',
    'Option 2': 'Medium',
    'Option 3': 'Low',
    'Option 4': 'None',
    'Option 5': 'Unsure',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '77d8817ab554711ddee690bf971b589aa2ff367d.3',
    'Parent Text': 'What is the level of member involvement in co-op activities?',
    'Text EN': 'Active use of common space for events and activities.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'High',
    'Option 2': 'Medium',
    'Option 3': 'Low',
    'Option 4': 'None',
    'Option 5': 'Unsure',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '77d8817ab554711ddee690bf971b589aa2ff367d.4',
    'Parent Text': 'What is the level of member involvement in co-op activities?',
    'Text EN': 'Participation in events and social activities.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'High',
    'Option 2': 'Medium',
    'Option 3': 'Low',
    'Option 4': 'None',
    'Option 5': 'Unsure',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '78b488e3a942ff51caccedfc880ee8d2aac2675a.0',
    'Parent Text': 'How involved are members in the surrounding community?',
    'Text EN': 'Participation in community events.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'High',
    'Option 2': 'Medium',
    'Option 3': 'Low',
    'Option 4': 'None',
    'Option 5': 'Unsure',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '78b488e3a942ff51caccedfc880ee8d2aac2675a.1',
    'Parent Text': 'How involved are members in the surrounding community?',
    'Text EN': 'Use of common space for activities with community groups.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'High',
    'Option 2': 'Medium',
    'Option 3': 'Low',
    'Option 4': 'None',
    'Option 5': 'Unsure',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '78b488e3a942ff51caccedfc880ee8d2aac2675a.2',
    'Parent Text': 'How involved are members in the surrounding community?',
    'Text EN': 'Shared common services with other community groups.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'High',
    'Option 2': 'Medium',
    'Option 3': 'Low',
    'Option 4': 'None',
    'Option 5': 'Unsure',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '78b488e3a942ff51caccedfc880ee8d2aac2675a.3',
    'Parent Text': 'How involved are members in the surrounding community?',
    'Text EN': 'Membership in other community groups.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'High',
    'Option 2': 'Medium',
    'Option 3': 'Low',
    'Option 4': 'None',
    'Option 5': 'Unsure',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '78b488e3a942ff51caccedfc880ee8d2aac2675a.4',
    'Parent Text': 'How involved are members in the surrounding community?',
    'Text EN': 'Participation in community initiatives to improve the environment.',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'High',
    'Option 2': 'Medium',
    'Option 3': 'Low',
    'Option 4': 'None',
    'Option 5': 'Unsure',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '11691d80c89e6a23c0a13ee18a605bcb51511eae.0',
    'Parent Text': 'Within your target clientele, how would you describe diversity among your members?',
    'Text EN': 'At the economic level',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very diverse',
    'Option 2': 'Somewhat diverse',
    'Option 3': 'Not diversified',
    'Option 4': 'N/A',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '11691d80c89e6a23c0a13ee18a605bcb51511eae.1',
    'Parent Text': 'Within your target clientele, how would you describe diversity among your members?',
    'Text EN': 'At the ethnic and cultural level',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very diverse',
    'Option 2': 'Somewhat diverse',
    'Option 3': 'Not diversified',
    'Option 4': 'N/A',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '11691d80c89e6a23c0a13ee18a605bcb51511eae.2',
    'Parent Text': 'Within your target clientele, how would you describe diversity among your members?',
    'Text EN': 'At the age level',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very diverse',
    'Option 2': 'Somewhat diverse',
    'Option 3': 'Not diversified',
    'Option 4': 'N/A',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '11691d80c89e6a23c0a13ee18a605bcb51511eae.3',
    'Parent Text': 'Within your target clientele, how would you describe diversity among your members?',
    'Text EN': 'At the household type level',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very diverse',
    'Option 2': 'Somewhat diverse',
    'Option 3': 'Not diversified',
    'Option 4': 'N/A',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '11691d80c89e6a23c0a13ee18a605bcb51511eae.4',
    'Parent Text': 'Within your target clientele, how would you describe diversity among your members?',
    'Text EN': 'At the linguistic level',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very diverse',
    'Option 2': 'Somewhat diverse',
    'Option 3': 'Not diversified',
    'Option 4': 'N/A',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8f41c61dc8d07ee799dcee9762b6579a9638f8c9.0',
    'Parent Text': 'Is this situation in terms of diversity similar to that of the surrounding environment?',
    'Text EN': 'At the economic level',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very similar',
    'Option 2': 'Somewhat similar',
    'Option 3': 'Not at all similar',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8f41c61dc8d07ee799dcee9762b6579a9638f8c9.1',
    'Parent Text': 'Is this situation in terms of diversity similar to that of the surrounding environment?',
    'Text EN': 'At the ethnic and cultural level',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very similar',
    'Option 2': 'Somewhat similar',
    'Option 3': 'Not at all similar',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8f41c61dc8d07ee799dcee9762b6579a9638f8c9.2',
    'Parent Text': 'Is this situation in terms of diversity similar to that of the surrounding environment?',
    'Text EN': 'At the age level',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very similar',
    'Option 2': 'Somewhat similar',
    'Option 3': 'Not at all similar',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8f41c61dc8d07ee799dcee9762b6579a9638f8c9.3',
    'Parent Text': 'Is this situation in terms of diversity similar to that of the surrounding environment?',
    'Text EN': 'At the household type level',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very similar',
    'Option 2': 'Somewhat similar',
    'Option 3': 'Not at all similar',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8f41c61dc8d07ee799dcee9762b6579a9638f8c9.4',
    'Parent Text': 'Is this situation in terms of diversity similar to that of the surrounding environment?',
    'Text EN': 'At the linguistic level',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very similar',
    'Option 2': 'Somewhat similar',
    'Option 3': 'Not at all similar',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '74a6f7feb929f915cb51eaf4a6f57bf64a76e51c',
    'Parent Text': '',
    'Text EN': 'Which of the following best describes accessibility in the co-op?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'This property is relatively accessible to persons with disabilities or limitations physical.',
    'Option 2': 'Some of our members need better access.',
    'Option 3': 'There is no accessibility.',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b5df9bd98c35f198bd2a03affa1e45b27501c3a1',
    'Parent Text': '',
    'Text EN': 'Has your co-op ever assessed the feasibility of improving or expanding its accessibility?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Yes, and it is possible if funds are available.',
    'Option 2': 'Yes, we have assessed it and it is not possible.',
    'Option 3': 'No, we havenâ€™t considered it yet.',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '165ac09ee98c28bb8d974ac17d8c2014165db8e3',
    'Parent Text': '',
    'Text EN': 'Do you have any comments to share about member engagement in community relations?',
    'Created date': 'undefined',
    'Section': 'SECTION D: Version for co-ops with a single property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6cbe2f5a7078dec7c2081637dd5c19becfb16997.0',
    'Parent Text': 'Considering all your buildings and properties, how satisfied are you with each of the following aspects of your co-op?',
    'Text EN': 'Governance (board and employee)',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': 'GOVERNANCE',
    'modifier': 'SATISFACTION',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6cbe2f5a7078dec7c2081637dd5c19becfb16997.1',
    'Parent Text': 'Considering all your buildings and properties, how satisfied are you with each of the following aspects of your co-op?',
    'Text EN': 'Property management',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': 'PROPERTY',
    'modifier': 'SATISFACTION',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6cbe2f5a7078dec7c2081637dd5c19becfb16997.2',
    'Parent Text': 'Considering all your buildings and properties, how satisfied are you with each of the following aspects of your co-op?',
    'Text EN': 'Financial management',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': 'FINANCIAL',
    'modifier': 'SATISFACTION',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6cbe2f5a7078dec7c2081637dd5c19becfb16997.3',
    'Parent Text': 'Considering all your buildings and properties, how satisfied are you with each of the following aspects of your co-op?',
    'Text EN': 'Member involvement',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': 'SOCIAL',
    'modifier': 'SATISFACTION',
    'multiple': '',
    'Option 1': '1',
    'Option 2': '0,75',
    'Option 3': '0,5',
    'Option 4': '0,25',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6cbe2f5a7078dec7c2081637dd5c19becfb16997.4',
    'Parent Text': 'Considering all your buildings and properties, how satisfied are you with each of the following aspects of your co-op?',
    'Text EN': 'Community relations',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': 'SOCIAL',
    'modifier': 'SATISFACTION',
    'multiple': '',
    'Option 1': '1',
    'Option 2': '0,75',
    'Option 3': '0,5',
    'Option 4': '0,25',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c714cc86ccbb72f6f24b751940901db7742440a4.0',
    'Parent Text': 'Overall, how accurately do these statements describe your co-op?',
    'Text EN': 'Our co-op is able to maintain its operations at the same level for a long time to come without additional external support or significant housing charge increases (i.e., we have sufficient reserves for annual maintenance and renovations).',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': 'VIABILITY',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c714cc86ccbb72f6f24b751940901db7742440a4.1',
    'Parent Text': 'Overall, how accurately do these statements describe your co-op?',
    'Text EN': 'Our co-op has the flexibility to adapt and meet more or less predictable challenges (e.g., end of subsidies, disaster, resignation by a manager, sudden increase in energy prices).',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': 'RESILIENCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c714cc86ccbb72f6f24b751940901db7742440a4.2',
    'Parent Text': 'Overall, how accurately do these statements describe your co-op?',
    'Text EN': 'Our co-op is strong enough to consider growing, improving its services or supporting the community (e.g., purchasing or building a new phase, accepting members facing serious health issues, installing public equipment in the yard).',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': 'VITALITY',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '73d20873b5dc75e63fcedc26d77f59e50cda4ac3.0',
    'Parent Text': 'To what extent does your cooperative have programs or policies in place for the following?',
    'Text EN': 'Minimize operating costs for heating, A/C, lighting, and water.',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': 'PROPERTY',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '73d20873b5dc75e63fcedc26d77f59e50cda4ac3.1',
    'Parent Text': 'To what extent does your cooperative have programs or policies in place for the following?',
    'Text EN': 'Minimize the impacts of climate change on our members (e.g., heat, more severe winter, etc.).',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': 'PROPERTY',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '73d20873b5dc75e63fcedc26d77f59e50cda4ac3.2',
    'Parent Text': 'To what extent does your cooperative have programs or policies in place for the following?',
    'Text EN': 'Protect the health of our members and the surrounding community.',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '73d20873b5dc75e63fcedc26d77f59e50cda4ac3.3',
    'Parent Text': 'To what extent does your cooperative have programs or policies in place for the following?',
    'Text EN': 'Create more socially accessible and inclusive housing.',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': 'SOCIAL',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8b701bac33d5a0245c5edf34fbd2c4978140617f.0',
    'Parent Text': 'How easy is it for your co-op to obtain the following services?',
    'Text EN': 'Financial Services',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8b701bac33d5a0245c5edf34fbd2c4978140617f.1',
    'Parent Text': 'How easy is it for your co-op to obtain the following services?',
    'Text EN': 'Aid for development proposals',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8b701bac33d5a0245c5edf34fbd2c4978140617f.2',
    'Parent Text': 'How easy is it for your co-op to obtain the following services?',
    'Text EN': 'Aid for renovation proposals',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8b701bac33d5a0245c5edf34fbd2c4978140617f.3',
    'Parent Text': 'How easy is it for your co-op to obtain the following services?',
    'Text EN': 'Architectural and technical expertise',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8b701bac33d5a0245c5edf34fbd2c4978140617f.4',
    'Parent Text': 'How easy is it for your co-op to obtain the following services?',
    'Text EN': 'Services for members needing support services',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0e7a7abb5688d78ca6b07f59c6c8227421085826',
    'Parent Text': '',
    'Text EN': 'What could the Centre do to help your co-op?',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'd13884f89b64c0fd1f0f469d8919ad70d24ac8e6',
    'Parent Text': '',
    'Text EN': 'Do you have any other comments about your co-op and its housing?',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR CO-OP AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5d92f237b7d44064351ac6bad5f0b27486cb5d36',
    'Parent Text': '',
    'Text EN': 'When was your business continuity plan last updated?',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0,9',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
]
