import {
  formConditions,
  sfFieldToConditionType,
  specialFormConditions
} from './ConditionalElementEditor'

const isDisabled = ({ item, ...props }) => {
  if (!item.conditions || item.conditions.length === 0) {
    return false
  }
  return item.conditions.some(condition => {
    const { conditionMet, state } = isConditionMet({
      condition,
      ...props
    })
    return (
      (conditionMet && state === 'hide') || (!conditionMet && state === 'show')
    )
  })
}

export const checkAltLabel = ({
  item,
  conditionType = 'altLabel',
  ...props
}) => {
  let label
  item.conditions.some(condition => {
    const { conditionMet, state, altLabel } = isConditionMet({
      condition,
      ...props
    })
    if (conditionMet && state === conditionType) {
      label = altLabel
    } else {
      return false
    }
  })
  return label
}

export const getValidCurrentStepIndex = ({ realIndex, disabledIds = [] }) => {
  let base = realIndex
  for (let index = realIndex; index >= 0; index--) {
    if (disabledIds.includes(sectionConditionId + index)) {
      base--
    }
  }
  return base
}

export const sectionConditionId = 'section##'
export const getDisabledIds = ({ sections, errors, ...props }) => {
  if (!props.values || !errors) {
    return []
  }

  const getIdsToDisable = errors => {
    const toRet = []
    sections.forEach((section, sectionIndex) => {
      const checkConditionsInItem = item => {
        if (item.elements) {
          if (isDisabled({ item, ...props, errors })) {
            toRet.push(item.id)
          }
          item.elements.forEach(item => {
            checkConditionsInItem(item)
          })
        } else if (isDisabled({ item, ...props, errors })) {
          toRet.push(item.id)
        }
      }

      const disableAllChildElements = item => {
        toRet.push(item.id)
        if (item.elements) {
          item.elements.forEach(item => {
            disableAllChildElements(item)
          })
        }
      }

      if (isDisabled({ item: section, ...props, errors })) {
        toRet.push(sectionConditionId + sectionIndex)
        section.elements.forEach(item => {
          disableAllChildElements(item)
        })
      }

      section.elements.forEach(item => {
        checkConditionsInItem(item)
      })
    })
    return toRet
  }

  const preDisabledIds = getIdsToDisable(errors)
  const validErrors = { ...errors }
  preDisabledIds.forEach(id => {
    delete validErrors[id]
  })
  return getIdsToDisable(validErrors)
}

export const isConditionMet = ({
  condition,
  elementsMap,
  values,
  langFR,
  describeMap = {},
  connectedMap = {},
  errors = {}
}) => {
  let bool = false
  let testRule, testValue
  if (condition.conditions) {
    const logic = condition.logic || 'all'
    if (logic === 'any') {
      bool = condition.conditions.some(condition => {
        const { conditionMet } = isConditionMet({
          condition,
          elementsMap,
          values,
          langFR,
          describeMap,
          connectedMap
        })
        return conditionMet
      })
    } else {
      bool = !condition.conditions.some(condition => {
        const { conditionMet } = isConditionMet({
          condition,
          elementsMap,
          values,
          langFR,
          describeMap,
          connectedMap
        })
        return !conditionMet
      })
    }
  } else {
    const toRet = {
      conditionMet: false,
      state: condition.state,
      altLabel: langFR ? condition.altLabelFR : condition.altLabelEN
    }
    if (
      condition.conditionTarget &&
      specialFormConditions[condition.conditionTarget]
    ) {
      if (condition.conditionTarget === 'noErrors') {
        toRet.conditionMet = Object.keys(errors).length === 0
      } else if (condition.conditionTarget === 'errorsPresent') {
        toRet.conditionMet = Object.keys(errors).length > 0
      }
      return toRet
    } else if (!condition.condition) {
      return toRet
    }
    if (
      !['isTrue', 'isFalse', 'isEmpty', 'isNotEmpty'].includes(
        condition.condition
      ) &&
      !condition.parameter
    ) {
      return toRet
    }
    if (condition.sfField) {
      const objData = connectedMap[condition.sfObject]
      if (objData) {
        let ruleType
        //Handle nested conditions target
        if (String(condition.sfField).includes('.')) {
          const objField = condition.sfField.split('.')[0]
          const nestedField = condition.sfField.split('.')[1]
          testValue = objData.sfObject[objField][nestedField]

          const nonRelationshipFieldName = objField.replace('__r', '__c')
          const nestedObjectInfo = objData.fieldsMap[nonRelationshipFieldName]
          const nestedObjectType = nestedObjectInfo.referenceTo[0]

          const nestedFieldInfo = describeMap[nestedObjectType].fields.find(
            field => field.name === nestedField
          )
          ruleType = sfFieldToConditionType[nestedFieldInfo.type]
          if (!ruleType) {
            return toRet
          }
          testRule =
            formConditions[ruleType].conditions[condition.condition].rule
        } else {
          testValue = objData.sfObject[condition.sfField]
          ruleType =
            sfFieldToConditionType[objData.fieldsMap[condition.sfField]?.type]
          if (condition.sfField === 'RecordTypeId') {
            ruleType = 'picklist'
          }
        }
        if (!ruleType) {
          return toRet
        }
        testRule = formConditions[ruleType].conditions[condition.condition].rule
      }
    } else {
      const targetElement = elementsMap[condition.conditionTarget]
      if (!targetElement) {
        console.warn(
          'Question targeted by this condition does not exist!',
          condition
        )
        return toRet
      } else if (
        !Object.keys(formConditions).includes(targetElement.elementType)
      ) {
        console.warn(
          'Question targeted by this condition has invalid type!',
          condition
        )
        return toRet
      }
      testValue = values[condition.conditionTarget]
      testRule =
        formConditions[targetElement.elementType].conditions[
          condition.condition
        ]?.rule
    }
    if (testRule) {
      bool = testRule(condition.parameter, testValue)
    }
  }

  return {
    conditionMet: bool,
    state: condition.state,
    altLabel: langFR ? condition.altLabelFR : condition.altLabelEN
  }
}
