import { t, Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Link,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@material-ui/core'
import Save from '@material-ui/icons/Save'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Font, PDFViewer, Text, View } from '@react-pdf/renderer'
import { dateFormat, datetimeFormat, defaultDocTitle } from 'app/appSettings'
import robotoBold from 'app/assets/fonts/Roboto-Bold.ttf'
import robotoBoldItalic from 'app/assets/fonts/Roboto-BoldItalic.ttf'
import robotoItalic from 'app/assets/fonts/Roboto-Italic.ttf'
import robotoRegular from 'app/assets/fonts/Roboto-Regular.ttf'
import { authRoles, checkAuth, hasRole } from 'app/auth/authRoles'
import {
  resetEditingUsers,
  setEditingUsers
} from 'app/redux/actions/MultiuserActions'
import SFAuthService from 'app/services/sfAuth/SFAuthService'
import { getAccountsMap } from 'app/services/sfAuth/sfData/sfAccount'
import { createCaseByFlow } from 'app/services/sfAuth/sfData/sfCase'
import { getContactsMap } from 'app/services/sfAuth/sfData/sfContact'
import {
  fetchFormPage,
  fetchFormPages,
  fetchReusableFormPage
} from 'app/services/sfAuth/sfData/sfForms'
import {
  createOpportunity,
  opportunitieStages,
  submitOpportunity
} from 'app/services/sfAuth/sfData/sfOpportunity'
import { submitTechnicalAdvisory } from 'app/services/sfAuth/sfData/sfTechnicalAdvisories'
import { getNetwork, saveUser } from 'app/services/sfAuth/sfData/sfUser'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Formik, useFormikContext, validateYupSchema } from 'formik'
import _, { isError, isNaN } from 'lodash'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import ReactCursorPosition, { INTERACTIONS } from 'react-cursor-position'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import rehypeStringify from 'rehype-stringify'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import { myI18n } from 'translation/I18nConnectedProvider'
import { unified } from 'unified'
import * as Yup from 'yup'
import { parseExtensionPhoneToSF } from '../common/Formats'
import SaveWillOverrideWarningDialog from '../common/SaveWillOverrideWarningDialog'
import SavingFailedWarningDialog from '../common/SavingFailedWarningDialog'
import { FormTitle } from '../grants/FormTitle'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import RedirectWarning from '../page-layouts/RedirectWarning'
import {
  constructFormAddressString,
  parseDisplayedText,
  pdfDefaultFontSize
} from './components/Common'
import MUAvatar from './components/multiuser-input/MUAvatar'
import {
  getFormVersionDifference,
  VersionsDifferences
} from './form-page/FormVersionDifferences'
import {
  checkAltLabel,
  getDisabledIds,
  getValidCurrentStepIndex,
  isConditionMet,
  sectionConditionId
} from './FormHelpersConditions'
import {
  constructValidationSchema,
  errorsToRender,
  isTrueDirty
} from './FormHelpersValidation'
import FormPdfDocument from './FormPdf'
import { connectedObjectQuery } from './FormsHelpersQueries'
import FormTabSession from './FormTabSession'
import { formObjectsToConnect } from './FormWizard'
import {
  formElementsWithoutCardInPrintView,
  formElementsWithoutInput,
  FormElementTitle,
  formElementTypes,
  formUseStyles,
  initialTouchedFormElements
} from './GroupElement'
import { cloneInjectableElement } from './InjectablesElementsPanel'
import Chat from './multiuser/Chat'
import CursorIcon from './multiuser/CursorIcon'
import { grpcListenForFieldCommentChangedEvent } from './multiuser/grpcFieldComment'
import {
  commitChangeToMultipleFields,
  commitFormCache,
  formCacheTypeToLabel,
  formRealmId,
  getCurrentFormState,
  grpcEndEditingForm,
  grpcFetchAllUsersInfo,
  grpcFetchChatMessages,
  grpcGetFormBackups,
  grpcGetFormCache,
  grpcListenForChatMessageSent,
  grpcListenForEndEditingFormEvent,
  grpcListenForFieldLockEvent,
  grpcListenForMouseCursorEvent,
  grpcListenForSFSaveRequest,
  grpcListenForSFSaveResult,
  grpcListenForStarEditingFormEvent,
  grpcListenForUserInfoUpdated,
  grpcReportSFSaveResult,
  grpcRequestSFSave,
  grpcStartSession,
  grpcUpdateUserInfo,
  grpGetLockedFieldsForForm,
  moveMouseCursor,
  pingServer,
  tryInitiatingForm,
  unlockFieldWithoutChanges
} from './multiuser/grpcMultiuserEdit'
import {
  DocumentCacheType,
  LockOperation
} from './multiuser/proto/generated/Multiuser_pb'
import { RequestStatus } from './multiuser/proto/generated/MultiuserSF_grpc_web_pb'
import UnsavedDataDetectedDialog from './multiuser/UnsavedDataDetectedDialog'

export const formItemPadding = 12
const printViewSpacing = 15
const DEFAULT_FORM_SAVE_REJECT = 'DEFAULT_FORM_SAVE_REJECT'

Font.register({
  family: 'Roboto',
  fonts: [
    { src: robotoBoldItalic, fontWeight: 700, fontStyle: 'italic' },
    { src: robotoRegular }, // font-style: normal, font-weight: normal
    { src: robotoBold, fontWeight: 700 },
    { src: robotoItalic, fontStyle: 'italic' }
  ]
})

const getInitialTouched = data => {
  const toRet = {}
  if (!data.sections) {
    return toRet
  }
  data.sections.forEach(section => {
    section.elements.forEach(element => {
      initialTouchedForElement(element, toRet)
    })
  })
  return toRet
}

const initialTouchedForElement = (item, initObj) => {
  if (item.elements) {
    item.elements.forEach(element => {
      initialTouchedForElement(element, initObj)
    })
  } else {
    const type = item.elementType
    if (initialTouchedFormElements.includes(type)) {
      initObj[item.id] = true
    }
    if (formElementTypes[type].initialTouchedIds) {
      const ids = formElementTypes[type].initialTouchedIds(item)
      ids.forEach(id => {
        _.set(initObj, id, true)
      })
    }
  }
}

const baseMuInfo = { chat: [], comments: {}, sessionUsers: [] }
export const getInitialValues = ({ data, files, ...props }) => {
  const toRet = { muInfo: { ...baseMuInfo }, other: {} }
  if (!data.sections) {
    return toRet
  }
  data.sections.forEach(section => {
    section.elements.forEach(element => {
      initialValuesForElement({
        item: element,
        initObj: toRet,
        files,
        ...props
      })
    })
  })
  return toRet
}

const initialValuesForElement = ({
  item,
  initObj,
  connectedMap = {},
  contactsMap = {},
  accountsMap = {}
}) => {
  if (item.elements) {
    item.elements.forEach(element => {
      initialValuesForElement({
        item: element,
        initObj,
        connectedMap,
        contactsMap,
        accountsMap
      })
    })
  } else {
    const type = item.elementType
    if (type && !formElementsWithoutInput.includes(type)) {
      const { typeProps = {} } = item
      const { options, picklistType, displayAllFiles } = typeProps
      let sfObject = {}
      let additionalSFInfo
      const { connectedObject, connectedField } = getMainConnected(item)
      if (connectedObject && connectedMap[connectedObject]) {
        sfObject = connectedMap[connectedObject].sfObject
        const files = connectedMap[connectedObject].files
        additionalSFInfo = connectedMap[connectedObject].additionalInfo
        if (type === 'uploadFiles') {
          initObj[item.id] = displayAllFiles
            ? [...files]
            : [
                ...files.filter(
                  file => file.tags && file.tags.includes(item.id)
                )
              ]
          return
        }
        if (options) {
          options.forEach((option, index) => {
            const { requireDetails, connectedField } = option
            if (requireDetails && connectedField) {
              if (picklistType === 'multiselect') {
                if (!initObj.other[item.id]) {
                  initObj.other[item.id] = []
                }
                initObj.other[item.id][index] = sfObject[connectedField.name]
              } else {
                initObj.other[item.id] = sfObject[connectedField.name]
              }
            }
          })
        }
        const sfValue = getSFObjectFieldValue(sfObject, connectedField)
        if (connectedField && (sfValue || sfValue === 0)) {
          if (type === 'picklist') {
            if (picklistType === 'multiselect' || sfValue.includes(';')) {
              initObj[item.id] = sfValue
                .split(';')
                .map(string => String(string).replace(/\s/g, ' ').trim())
            } else {
              initObj[item.id] = sfValue.replace(/\s/g, ' ')
            }
            return
          } else if (
            typeof formElementTypes[type].defaultValue === 'function'
          ) {
            initObj[item.id] = formElementTypes[type].defaultValue(
              sfObject,
              { ...additionalSFInfo, contactsMap, accountsMap, initObj },
              item
            )
            return
          } else {
            initObj[item.id] = sfValue
            return
          }
        }
      }
      if (type === 'picklist') {
        initObj[item.id] = picklistType === 'multiselect' ? [] : ''
        return
      }
      if (!formElementTypes[type]) {
        return
      }
      if (typeof formElementTypes[type].defaultValue === 'function') {
        initObj[item.id] = formElementTypes[type].defaultValue(
          sfObject,
          { ...additionalSFInfo, contactsMap, accountsMap, initObj },
          item
        )
      } else {
        initObj[item.id] = formElementTypes[type].defaultValue
      }
    }
  }
}

export const insertValueToSFObject = ({
  saveMap,
  value,
  fieldProps,
  sfObject,
  subObjectsMap,
  connectedObjectId,
  customConnectedField = false
}) => {
  if (!fieldProps) {
    return null
  }
  const { name, subObject } = fieldProps
  if (subObject && name.indexOf('.') !== '-1') {
    const subObjectName = name.split('.')[0]
    const subFieldName = name.split('.')[1]
    if (subObjectName && subFieldName) {
      if (customConnectedField) {
        sfObject = sfObject[subObjectName]
      }
      subObjectsMap[sfObject.Id] = { type: subObject }
      if (!saveMap[sfObject.Id]) {
        saveMap[sfObject.Id] = {
          Id: sfObject.Id
        }
      } else {
        saveMap[sfObject.Id][subFieldName] = value
      }
    }
  } else {
    saveMap[connectedObjectId][name] = value
  }
}

export const getSFObjectFieldValue = (sfObject, props) => {
  if (!props) {
    return null
  }
  let sfValue = _.get(sfObject, props.name)
  if (props.subObject && sfValue) {
    const mainField = props.name.split('.')[0]
    const subField = props.name.split('.')[1]
    sfValue = sfObject[mainField][subField]
  }
  return sfValue
}

export const getMainConnected = item => {
  const connected = item.typeProps.connectedTo || []
  let toRet = {}
  connected.some((obj, index) => {
    const noField = formElementTypes[item.elementType]?.noFieldConnect
    if (obj.connectedObject && (obj.connectedField || noField)) {
      toRet = obj
      return true
    }
    if (index === 0) {
      // console.error('Main connected object was empty!', item)
    } else {
      console.warn('Empty connection configured detected for:', item)
    }
    return false
  })
  return toRet
}

export const mapFormElements = (data, langFR, mapGroups = false) => {
  const returnObj = {}
  if (!data || !data.sections) {
    return returnObj
  }
  data.sections.forEach((section, sectionIndex) =>
    section.elements.forEach(item =>
      mapItem({ item, returnObj, section, langFR, sectionIndex, mapGroups })
    )
  )
  return returnObj
}

const mapItem = ({
  item,
  returnObj,
  section,
  langFR,
  sectionIndex,
  mapGroups
}) => {
  if (item.elements) {
    item.elements.forEach(element =>
      mapItem({
        item: element,
        returnObj,
        section,
        langFR,
        sectionIndex,
        mapGroups
      })
    )
    if (mapGroups) {
      returnObj[item.id] = {
        ...item,
        title: langFR ? item.titleFR : item.titleEN,
        sectionIndex,
        sectionName: langFR ? section.titleFR : section.titleEN
      }
    }
  } else {
    returnObj[item.id] = {
      ...item,
      title: langFR ? item.titleFR : item.titleEN,
      sectionIndex,
      sectionName: langFR ? section.titleFR : section.titleEN
    }
  }
}

export const correctableErrors = {
  ZIP_CODE: {
    text: <Trans>Provided zip code has incorrect format!</Trans>,
    logic: error => error.includes('X1X 1X1')
  },
  DUPLICATE_NAME: {
    text: <Trans>Organization with this name already exists!</Trans>,
    logic: error =>
      error.includes('Organization with this name already exists!')
  },
  REQUIRED_NAME: {
    text: <Trans>Empty name is invalid!</Trans>,
    logic: error => error.includes('[Name]')
  }
}

export const handleFormSave = ({
  values,
  appConfigurations,
  reduxBag,
  extraInfo,
  utilityBag = {},
  elementsMap,
  connectedMap,
  baseToSave = {}
}) => {
  const conn = SFAuthService.getConnection()
  const { enqueueSnackbar, reloadLastModifiedDates } = utilityBag
  let promises = []
  const subObjectsMap = {}
  if (!connectedMap || Object.keys(connectedMap).length === 0) {
    return Promise.resolve()
  }
  console.log('form save values', values)
  const saveMap = { ...baseToSave }
  Object.keys(connectedMap).forEach(key => {
    if (connectedMap[key].sfObject) {
      saveMap[key] = {
        ...saveMap[key],
        Id: connectedMap[key].sfObject.Id
      }
    }
  })
  Object.keys(values).forEach((key, index) => {
    const item = elementsMap[key]
    if (item) {
      const elementProps = formElementTypes[item.elementType]
      const typeProps = item.typeProps
      const {
        isConnected,
        connectedTo = [],
        options,
        requiresRequest,
        isPhone,
        readOnly
      } = typeProps
      let sfObject, additionalSFInfo
      if (!readOnly) {
        connectedTo.forEach((obj, index) => {
          let value = values[key]
          let { connectedObject, connectedField } = obj
          if (isConnected && connectedObject && connectedMap[connectedObject]) {
            sfObject = connectedMap[connectedObject].sfObject
            additionalSFInfo = connectedMap[connectedObject].additionalInfo
            let fieldName = connectedField?.name
            if (connectedField && fieldName.indexOf('.') !== '-1') {
              const subObjectName = fieldName.split('.')[0]
              const subFieldName = fieldName.split('.')[1]
              if (subObjectName && subFieldName) {
                fieldName = subFieldName
                sfObject = sfObject[subObjectName]
                connectedObject = sfObject.Id
                subObjectsMap[sfObject.Id] = { type: connectedField.subObject }
                if (!saveMap[sfObject.Id]) {
                  saveMap[sfObject.Id] = {
                    Id: sfObject.Id
                  }
                }
              }
            }
            if (options) {
              options.forEach((option, index) => {
                let isSelected = false
                const defaultOpt = 'option' + index
                const optionValue = isConnected
                  ? option.apiValue || defaultOpt
                  : defaultOpt
                if (Array.isArray(value)) {
                  isSelected = value.includes(optionValue)
                } else {
                  isSelected = value === optionValue
                }
                if (isSelected && option.requireDetails) {
                  if (option.connectedField) {
                    let fieldValue = values.other && values.other[key]
                    if (Array.isArray(fieldValue)) {
                      fieldValue = fieldValue[index]
                    }
                    saveMap[connectedObject][option.connectedField.name] =
                      fieldValue
                  }
                }
              })
            }
            if (typeProps.picklistType === 'multiselect' && value) {
              value = value.join(';')
            }
            // If empty string is sent to SF instead of null  the field will be set to 0 instead of null
            if (
              item.elementType === 'textInputNumeric' ||
              item.elementType === 'numericSlider'
            ) {
              if (!value && value !== 0) {
                value = null
              } else if (isPhone) {
                value = parseExtensionPhoneToSF(value, values.other[key])
              }
            }
            let saveHandled = false
            if (elementProps.savePromise) {
              saveHandled = true
              promises.push(
                elementProps.savePromise({
                  value,
                  item,
                  connectedObject: sfObject,
                  additionalSFInfo,
                  appConfigurations
                })
              )
            }
            if (elementProps.extractSaveKey) {
              saveHandled = true
              elementProps.extractSaveKey({
                saveMap,
                subObjectsMap,
                additionalSFInfo,
                value,
                values,
                item,
                connectedProps: obj,
                connectedObjectId: connectedObject,
                sfObject,
                appConfigurations
              })
            }
            if (!saveHandled && !requiresRequest && fieldName) {
              saveMap[connectedObject][fieldName] = value
            }
          }
        })
      }
    }
  })

  Object.keys(saveMap).forEach(key => {
    const objData = connectedMap[key]?.sfObject
    const type = objData ? objData.attributes.type : subObjectsMap[key].type
    const sfObjectData = formObjectsToConnect[type]
    console.log({ key, objData, type, sfObjectData, saveMap })
    if (saveMap[key].Id && Object.keys(saveMap[key]).length > 1) {
      if (sfObjectData && sfObjectData.saveFunction) {
        promises.push(
          sfObjectData.saveFunction(saveMap[key], reduxBag, utilityBag)
        )
      } else {
        promises.push(conn.sobject(type).update(saveMap[key]))
      }
    } else if (!saveMap[key].Id) {
      if (type === 'Opportunity') {
        promises.push(createOpportunity(conn, saveMap[key], extraInfo))
      } else {
        if (Object.keys(saveMap[key]).length > 0) {
          promises.push(conn.sobject(type).create(saveMap[key]))
        }
      }
    }
  })

  const hasError = result => {
    if (Array.isArray(result)) {
      return result.some(sub => hasError(sub))
    } else {
      if (!result) {
        console.error(
          'No promise resolution configured or an unexpected error ocurred!'
        )
        return true
      }
      if (result.compositeResponse) {
        return result.compositeResponse.some(comp => {
          if (!comp.body) {
            return false
          } else {
            return comp.body.some(sub => !sub.success)
          }
        })
      }
      if ('isSuccess' in result) {
        return !result.isSuccess
      }
      if ('success' in result) {
        return !result.success
      }
      if ('hasErrors' in result) {
        return result.hasErrors
      }
      return false
    }
  }

  promises = promises.filter(f => f)

  const handleReject = reject => {
    let correctableError

    if (Array.isArray(reject)) {
      if (reject.some(result => !hasError(result))) {
        reloadLastModifiedDates()
      }
    }

    const checkErrorGravity = error => {
      if (Array.isArray(error)) {
        error.forEach(error => checkErrorGravity(error))
      } else if (error.compositeResponse) {
        error.compositeResponse.forEach(result => {
          if (result.body) {
            result.body.forEach(result => {
              checkErrorGravity(result)
            })
          }
        })
      } else if (error.errors) {
        error.errors.forEach(error => {
          checkErrorGravity(error)
        })
      } else {
        let parsedError = error
        if (isError(error)) {
          parsedError = reject.toString()
        } else if (error.errorCode && error.message) {
          parsedError = error.message
        }
        if (typeof parsedError === 'string') {
          Object.keys(correctableErrors).forEach(key => {
            const errorData = correctableErrors[key]
            if (errorData.logic(parsedError)) {
              correctableError = key
              if (errorData.text) {
                enqueueSnackbar(errorData.text, {
                  variant: 'error'
                })
              }
            }
          })
        }
      }
    }
    checkErrorGravity(reject)
    return Promise.reject({
      reject: correctableError || DEFAULT_FORM_SAVE_REJECT,
      errorStringified: reject
    })
  }

  return Promise.allSettled(promises).then(
    result => {
      let errorOcurred
      console.log('form saving result', result)
      result.forEach(promise => {
        if (promise.status === 'rejected') {
          errorOcurred = true
        } else if (!errorOcurred) {
          errorOcurred =
            Array.isArray(promise.value) &&
            promise.value.some(res => hasError(res))
        }
      })
      if (errorOcurred) {
        const rejectArray = result.map(promise =>
          promise.status === 'rejected' ? promise.reason : promise.value
        )
        return handleReject(rejectArray)
      } else {
        return result.map(promise =>
          promise.status === 'rejected' ? promise.reason : promise.value
        )
      }
    },
    reject => {
      console.error('form error saving', reject)
      return handleReject(reject)
    }
  )
}

export const checkFormValidity = ({ id, formId }) => {
  return fetchFormPage(formId)
    .then(result => {
      console.log('form loaded', result)
      if (result.objectsConnected) {
        return connectedObjectQuery(result, {
          id
        }).then(
          ({ connectedMap, describeMap }) => {
            const initialValues = getInitialValues({
              data: result,
              connectedMap
            })
            const elementsMap = mapFormElements(result)

            const getValidFormSchema = (errors = {}) => {
              const validationSchemaRaw = constructValidationSchema({
                data: result,
                returnRaw: true,
                english: true
              })

              const disabledIds = getDisabledIds({
                sections: result.sections,
                elementsMap,
                values: initialValues,
                connectedMap,
                describeMap,
                errors
              })
              disabledIds.forEach(id => {
                delete validationSchemaRaw[id]
              })
              return Yup.object().shape(validationSchemaRaw)
            }

            return validateYupSchema(initialValues, getValidFormSchema()).then(
              result => {
                return validateYupSchema(
                  initialValues,
                  getValidFormSchema({})
                ).then(
                  result => true,
                  reject => false
                )
              },
              reject => {
                const errors = {}
                reject.inner.forEach(error => {
                  errors[error.path] = error
                })
                return validateYupSchema(
                  initialValues,
                  getValidFormSchema(errors)
                ).then(
                  result => true,
                  reject => false
                )
              }
            )
          },
          reject => {
            console.error('no object found', reject)
            return false
          }
        )
      } else {
        return true
      }
    })
    .catch(error => {
      console.error('error loading form', error)
      return false
    })
}

const getObjectsFieldsMap = ({ data, connectedMap = {}, describeMap }) => {
  const toReturn = {}
  if (!data) {
    return toReturn
  }
  data.objectsConnected.forEach(object => {
    const connData = connectedMap[object.identId]
    const toSet = {}
    if (connData) {
      const objName = connData.sfObject.attributes.type
      Object.keys(connData.fieldsMap).forEach(key => {
        toSet[key] = {
          value: connData.sfObject[key],
          type: connData.fieldsMap[key].type
        }
      })
      Object.keys(connData.sfObject).forEach(key => {
        const value = connData.sfObject[key]
        if (!toSet[key]) {
          toSet[key] = {
            value,
            type: 'object'
          }
        }
      })
      if (formObjectsToConnect[objName].additionalObjects) {
        formObjectsToConnect[objName].additionalObjects.forEach(obj => {
          const { sfObject, field } = obj
          const subObject = connData.sfObject[field]
          if (subObject) {
            const fields = describeMap[subObject.attributes.type].fields
            const subObjectFieldsMap = {}
            if (fields) {
              fields.forEach(obj => {
                subObjectFieldsMap[obj.name] = {
                  value: subObject[obj.name],
                  type: obj.type
                }
              })
            }
            toSet[field] = {
              value: subObjectFieldsMap,
              type: 'object'
            }
          }
        })
      }
      toReturn[object.name] = toSet
    }
  })
  return toReturn
}

export const multiuserColors = [
  '#fca737',
  '#42c9c0',
  '#810bbc',
  '#a01313',
  '#232efc',
  '#f43f9a',
  '#1b9121',
  '#e03134',
  '#8e6d5e',
  '#5e595a',
  '#FFADBC'
]

const FormWrapped = props => {
  const editingUsers = useSelector(state => state.multiuser.editingUsers)
  const user = useSelector(state => state.user)
  const multiuserEdit = editingUsers && Object.values(editingUsers).length > 0
  const mouseDetectRef = useRef()
  const [currentStep, setStep] = useState(0)

  return (
    <>
      {multiuserEdit && (
        <div style={{ height: 1 }}>
          {Object.values(editingUsers)
            .filter(
              userObj =>
                userObj.id !== user.userId && userObj.step === currentStep
            )
            .map((userObj, index) => {
              const { coordinates, color, name, step } = userObj
              return (
                coordinates && (
                  <CursorIcon
                    coordinates={coordinates}
                    dimensions={
                      mouseDetectRef?.current?.state?.elementDimensions
                    }
                    index={index}
                    color={color}
                    name={name}
                  />
                )
              )
            })}
        </div>
      )}
      <FormTabSession
        {...props}
        mouseDetectRef={mouseDetectRef}
        currentStep={currentStep}
        setStep={setStep}
      />
    </>
  )
}

export const Form = React.memo(
  ({
    match = {},
    saveCallback,
    fetchString,
    formId,
    fixedDisplay,
    scrollbarContentRef,
    disableTitle = false,
    pdfTitle,
    returnPdf,
    displayView,
    forceDisabled,
    disablePDF,
    inDialog,
    onDialogClose,
    mouseDetectRef,
    currentStep,
    setStep,
    renderData,
    tabId,
    ...props
  }) => {
    const { params = {} } = match
    const isPreview = props.preview //|| params.preview
    const id = fetchString || params.id
    formId = formId || params.formId
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(true)
    const [initialValues, setInitialValues] = useState()
    const [saving, setSaving] = useState(false)
    const [data, setData] = useState()
    const [network, setNetwork] = useState()
    const [validationSchema, setValidationSchema] = useState()
    const [overrideWarningData, setOverrideWarningData] = useState()
    const [saveFailedData, setSaveFailedData] = useState()
    const [connectedMap, setConnectedMap] = useState({})
    const [describeMap, setDescribeMap] = useState({})
    const [configuration, setConfiguration] = useState()
    const [readOnly, setReadOnly] = useState(false)
    const [pdfDisplay, setPdfDisplay] = useState(false)
    const [insufficientAccess, setInsufficientAccess] = useState(false)
    const [wrongAccountRole, setWrongAccountRole] = useState(null)
    const [useMultiuser, setUseMultiuser] = useState(false)
    const [multiuserSessionToken, setMultiuserSessionToken] = useState(null)
    const [unsavedDataDetected, setUnsavedDataDetected] = useState(null)
    const settings = useSelector(state => state.layout.settings)
    const theme = settings.themes[settings.activeTheme]
    const successColor = theme?.palette.success.main
    const errorColor = theme?.palette.error.main
    const printRef = useRef()
    const user = useSelector(state => state.user)
    const appConfigurations = useSelector(state => state.configuration || {})
    const organization = useSelector(state => state.organization || {})
    const avaliableOrganizations = useSelector(
      state => state.avaliableOrganizations
    )
    const organizationId = organization.id || params.organizationId
    const { language, multiuserAuthenticated, multiuserLoginToken } = user
    const langFR = language !== 'en_US'
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const history = useHistory()
    const dispatch = useDispatch()
    const formikRef = useRef()
    const noConnectedObjects =
      connectedMap && Object.keys(connectedMap).length === 0
    const classes = formUseStyles()
    useBeforeunload(event => {
      muEndEditingForm()
      dispatch(resetEditingUsers())
    })

    const { sections = [], showPrintButton } = data || {}
    const displayPrintButton = readOnly || showPrintButton || forceDisabled
    const objectsFieldsMap = getObjectsFieldsMap({
      data,
      connectedMap,
      describeMap
    })
    const formTitlePdf = parseDisplayedText({
      text: langFR ? data?.titleFR : data?.titleEN,
      french: langFR,
      objectsFieldsMap,
      describeMap,
      returnString: true
    })

    useEffect(() => {
      return () => {
        if (multiuserSessionToken) {
          muEndEditingForm()
          dispatch(resetEditingUsers())
        }
      }
    }, [multiuserSessionToken])

    const muEndEditingForm = () => {
      if (formikRef && formikRef.current) {
        const { kickedOutOfForm } = formikRef.current.values
        if (useMultiuser && !kickedOutOfForm) {
          const { values } = formikRef.current
          if (
            values.muUsers &&
            Object.keys(values.muUsers).length === 1 &&
            isTrueDirty(formikRef)
          ) {
            commitFormCache({
              values,
              userId: user.userId,
              formId: formRealmId(organizationId, id),
              token: multiuserSessionToken,
              userIds: values.muInfo.sessionUsers,
              type: DocumentCacheType.CHANGES_NOT_SAVED
            })
          }
          grpcEndEditingForm({
            userId: user.userId,
            token: multiuserSessionToken,
            userIds: values.muInfo.sessionUsers,
            type: DocumentCacheType.CHANGES_NOT_SAVED
          })
        }
      }
    }

    useEffect(() => {
      if (useMultiuser && multiuserAuthenticated && multiuserSessionToken) {
        const streamFieldLocked = grpcListenForFieldLockEvent({
          userId: user.userId,
          token: multiuserSessionToken,
          onEventRecieved: ({ userId, changes = [], operation }) => {
            const { values, setValues, setFieldValue } = formikRef.current
            console.log('lock change event', operation, changes)
            if (
              operation === LockOperation.LOCK ||
              operation === LockOperation.RECLAIM
            ) {
              const toSet = { ...values.muInfo }
              changes.forEach(obj => {
                const { lockId, fieldId } = obj
                console.log('user locked field', user, fieldId, lockId)
                if (!toSet[fieldId]) {
                  if (userId === user.userId) {
                    toSet.lockId = lockId
                  }
                  toSet[fieldId] = {
                    locked: true,
                    user: userId
                  }
                }
              })
              setFieldValue(`muInfo`, toSet, false)
            } else if (operation === LockOperation.COMMIT) {
              const toSet = { ...values }
              changes.forEach(obj => {
                const { lockId, fieldId, fieldValue } = obj
                let newValue = fieldValue
                console.log('user unlocked field', fieldId, newValue)
                if (newValue) {
                  newValue = JSON.parse(newValue)
                  const testNum = Number(String(newValue).replace(/,|\$/g, ''))
                  if (
                    !isNaN(testNum) &&
                    !moment.utc(newValue).isValid() &&
                    (newValue || newValue === 0)
                  ) {
                    newValue = testNum
                  }
                } else {
                  newValue = null
                }
                delete toSet.muInfo[fieldId]
                _.set(toSet, fieldId, newValue)
              })
              setValues(toSet, userId !== user.userId)
            } else if (operation === LockOperation.UPDATE) {
              if (userId !== user.userId) {
                const toSet = { ...values }
                changes.forEach(obj => {
                  const { lockId, fieldId, fieldValue } = obj
                  let newValue = fieldValue
                  if (newValue) {
                    newValue = JSON.parse(newValue)
                    const testNum = Number(
                      String(newValue).replace(/,|\$/g, '')
                    )
                    if (
                      !isNaN(testNum) &&
                      !moment.utc(newValue).isValid() &&
                      (newValue || newValue === 0)
                    ) {
                      newValue = testNum
                    }
                  } else {
                    newValue = null
                  }
                  _.set(toSet, fieldId, newValue)
                })
                setValues(toSet)
              }
            } else if (operation === LockOperation.CANCEL) {
              const toSet = { ...values }
              changes.forEach(obj => {
                const { lockId, fieldId } = obj
                delete toSet.muInfo[fieldId]
              })
              setValues(toSet, false)
            }
          }
        })
        const streamMouseMoved = grpcListenForMouseCursorEvent({
          userId: user.userId,
          token: multiuserSessionToken,
          onEventRecieved: ({ coordinates, userId }) => {
            const { values } = formikRef.current
            const toSet = { ...values }
            const newMuUsers = { ...toSet.muUsers }
            if (newMuUsers[userId]) {
              newMuUsers[userId].coordinates = coordinates
              dispatch(setEditingUsers(newMuUsers))
            }
          }
        })
        const streamStartEditing = grpcListenForStarEditingFormEvent({
          userId: user.userId,
          token: multiuserSessionToken,
          onEventRecieved: info => {
            const { values, setValues } = formikRef.current
            const toSet = { ...values }
            console.log('user started editing', info)
            if (
              info.id === user.userId &&
              toSet.muUsers[info.id] &&
              info.tabId !== tabId
            ) {
              toSet.kickedOutOfForm = true
              setValues(toSet, false)
              history.push('/grants/GrantsHome')
              if (values.muInfo.lockId) {
                unlockFieldWithoutChanges({
                  fieldId: values.muInfo.lockId,
                  token: multiuserSessionToken,
                  userId: user.userId
                })
              }
              enqueueSnackbar(
                <Trans>
                  You logged to this form in different tab or browser
                </Trans>,
                {
                  variant: 'info'
                }
              )
            } else {
              toSet.muUsers[info.id] = info
              if (
                toSet.muInfo.sessionUsers &&
                !toSet.muInfo.sessionUsers.includes(info.id)
              ) {
                toSet.muInfo.sessionUsers.push(info.id)
              }
              setValues(toSet, false)
              dispatch(setEditingUsers(toSet.muUsers))
            }
          }
        })
        const streamChatMessage = grpcListenForChatMessageSent({
          userId: user.userId,
          token: multiuserSessionToken,
          onEventRecieved: ({ user, message }) => {
            const { values, setFieldValue } = formikRef.current
            const toSet = { ...values.muInfo }
            console.log('got message', user, message)
            if (values.muUsers[user]) {
              const newChat = toSet.chat ? [...toSet.chat] : []
              newChat.push({
                userId: user,
                text: message,
                color: values.muUsers[user].color,
                name: values.muUsers[user].name,
                recieved: moment.utc()
              })
              toSet.chat = newChat
              setFieldValue(`muInfo`, toSet, false)
            }
          }
        })
        const streamEndEditing = grpcListenForEndEditingFormEvent({
          userId: user.userId,
          token: multiuserSessionToken,
          onEventRecieved: id => {
            const { values, setValues } = formikRef.current
            console.log('user logout of form', id)
            const toSet = { ...values }
            const newMuUsers = { ...toSet.muUsers }
            if (newMuUsers[id]) {
              delete newMuUsers[id]
              toSet.muUsers = newMuUsers
            }
            // unlock fields for editor
            Object.keys(toSet.muInfo).forEach(key => {
              const value = toSet.muInfo[key]
              if (typeof value === 'object' && value && value.user === id) {
                delete toSet.muInfo[key]
                unlockFieldWithoutChanges({
                  token: multiuserSessionToken,
                  fieldId: key,
                  userId: user.userId
                })
              }
            })
            setValues(toSet, false)
            dispatch(setEditingUsers(toSet.muUsers))
          }
        })
        const streamSFSaveRequested = grpcListenForSFSaveRequest({
          userId: user.userId,
          token: multiuserSessionToken,
          onEventRecieved: ({ canSave, requestType, userRequesting }) => {
            const { values, setFieldValue } = formikRef.current
            const userInfo = values.muUsers[userRequesting]
            console.log(
              'save request recieved',
              canSave,
              requestType,
              userRequesting
            )
            if (userInfo && userRequesting !== user.userId) {
              if (requestType === 'Autosave') {
                enqueueSnackbar(<Trans>Autosaving</Trans>, { variant: 'info' })
              } else {
                enqueueSnackbar(
                  <Trans>{userInfo.name} requested form save</Trans>,
                  { variant: 'info' }
                )
              }
            }
            if (!canSave) {
              setSaving(false)
              enqueueSnackbar(
                <Trans>
                  All fields must be unlocked for saving to commence!
                </Trans>,
                { variant: 'error' }
              )
            } else {
              setSaving(true)
              if (userRequesting === user.userId) {
                handleSave({ values }).then(
                  result => {
                    grpcReportSFSaveResult({
                      type: requestType,
                      token: multiuserSessionToken,
                      userId: user.userId,
                      result: RequestStatus.ALLOWED
                    })
                    commitFormCache({
                      values,
                      token: multiuserSessionToken,
                      userId: user.userId,
                      formId: formRealmId(organizationId, id),
                      userIds: values.muInfo.sessionUsers,
                      type: DocumentCacheType.MANUAL_SAVE
                    })
                  },
                  reject => {
                    grpcReportSFSaveResult({
                      type: requestType,
                      token: multiuserSessionToken,
                      userId: user.userId,
                      result: RequestStatus.BLOCKED
                    })
                  }
                )
              } else {
                const muSnackbar = enqueueSnackbar(null, {
                  persist: true,
                  content: key =>
                    ProgressSnackbar(<Trans>{userInfo.name} is saving</Trans>)
                })
                setFieldValue(`muInfo.muSnackbar`, muSnackbar, false)
              }
            }
          }
        })
        const streamSFSaveResult = grpcListenForSFSaveResult({
          userId: user.userId,
          token: multiuserSessionToken,
          onEventRecieved: ({ success, requestType, userSaving }) => {
            console.log(
              'save request recieved',
              success,
              requestType,
              userSaving
            )
            if (userSaving !== user.userId) {
              const { values, setFieldValue } = formikRef.current
              if (success) {
                if (requestType === 'Save' || requestType === 'Autosave') {
                  fetchData({ multiuserReload: true }).then(result => {
                    if (values.muInfo.muSnackbar) {
                      closeSnackbar(values.muInfo.muSnackbar)
                      setFieldValue(`muInfo.muSnackbar`, null, false)
                    }
                    enqueueSnackbar(<Trans>Successfully saved!</Trans>, {
                      variant: 'success'
                    })
                    setSaving(false)
                  })
                } else {
                  if (values.muInfo.muSnackbar) {
                    closeSnackbar(values.muInfo.muSnackbar)
                    setFieldValue(`muInfo.muSnackbar`, null, false)
                  }
                  enqueueSnackbar(<Trans>Successfully submitted!</Trans>, {
                    variant: 'success'
                  })
                }
              } else {
                if (values.muInfo.muSnackbar) {
                  closeSnackbar(values.muInfo.muSnackbar)
                  setFieldValue(`muInfo.muSnackbar`, null, false)
                }
                setSaving(false)
                if (requestType === 'Save') {
                  enqueueSnackbar(
                    <Trans>
                      Error ocurred while saving! Some fields were not saved!
                    </Trans>,
                    {
                      variant: 'error'
                    }
                  )
                } else {
                  enqueueSnackbar(<Trans>Error Submitting</Trans>, {
                    variant: 'error'
                  })
                }
              }
            }
          }
        })

        const streamUserInfoUpdated = grpcListenForUserInfoUpdated({
          userId: user.userId,
          token: multiuserSessionToken,
          onEventRecieved: ({ userId, info }) => {
            const { values, setFieldValue } = formikRef.current
            const toSet = { ...values.muUsers }
            toSet[userId] = info
            setFieldValue(`muUsers`, toSet, false)
          }
        })

        const streamFieldCommentChanged = grpcListenForFieldCommentChangedEvent(
          {
            id,
            userId: user.userId,
            token: multiuserSessionToken,
            onEventRecieved: ({ comment, userId, fieldId, operation }) => {
              console.log('field comment changed', comment, fieldId, operation)
              const { setFieldValue, values } = formikRef.current
              const toSet = { ...values.muInfo }
              if (operation === LockOperation.LOCK) {
                const current = _.get(values.muInfo, `comments.${fieldId}`)
                _.set(toSet, `comments.${fieldId}`, {
                  ...current,
                  locked: true,
                  user: userId
                })
                setFieldValue(`muInfo`, toSet, false)
              } else if (
                operation === LockOperation.COMMIT ||
                operation === LockOperation.CANCEL ||
                operation === LockOperation.UPDATE
              ) {
                _.set(toSet, `comments.${fieldId}`, {
                  locked: operation === LockOperation.UPDATE,
                  comment,
                  user: userId
                })
                setFieldValue(`muInfo`, toSet, false)
              }
            }
          }
        )

        const sendCursorEvent = () => {
          const ref = mouseDetectRef && mouseDetectRef.current
          if (ref) {
            const muUsers = formikRef.current.values.muUsers
            const { elementDimensions, isPositionOutside, position } = ref.state
            if (!isPositionOutside) {
              const { x, y } = position
              if (muUsers && muUsers[user.userId]) {
                const oldX = muUsers[user.userId].coordinates?.x
                const oldY = muUsers[user.userId].coordinates?.y
                if (Math.abs(oldX - x) < 10 && Math.abs(oldY - y) < 10) {
                  return
                }
              }
              const xPercent = Number(
                Number(x / elementDimensions.width).toFixed(2)
              )
              const yPercent = Number(
                Number(y / elementDimensions.height).toFixed(2)
              )
              moveMouseCursor({
                userId: user.userId,
                token: multiuserSessionToken,

                x,
                y,
                xPercent,
                yPercent
              })
            }
          }
        }

        const resetCursorDetect = () => {
          if (mouseDetectRef && mouseDetectRef.current) {
            setTimeout(() => {
              mouseDetectRef.current.reset()
              sendCursorEvent()
            }, 400)
          }
        }
        window.addEventListener('wheel', resetCursorDetect)
        const mouseMoveInterval = setInterval(() => {
          sendCursorEvent()
        }, 500)

        return () => {
          streamFieldLocked.close()
          streamChatMessage.close()
          streamMouseMoved.close()
          streamEndEditing.close()
          streamStartEditing.close()
          streamSFSaveRequested.close()
          streamSFSaveResult.close()
          streamUserInfoUpdated.close()
          streamFieldCommentChanged.close()
          clearInterval(mouseMoveInterval)
          window.removeEventListener('wheel', resetCursorDetect)
        }
      }
    }, [useMultiuser, multiuserAuthenticated, multiuserSessionToken])

    const checkFormLocksForCommit = ({ handle, muSessionId }) => {
      const ref = formikRef.current
      if (!ref && handle) {
        clearInterval(handle)
      } else if (ref) {
        const { values, setFieldValue } = ref
        let isFieldLocked
        Object.values(values.muInfo).forEach(obj => {
          if (obj && obj.locked) {
            isFieldLocked = true
          }
        })
        if (!isFieldLocked) {
          if (handle) {
            clearInterval(handle)
          }
          setFieldValue(`muInfo.waitingForCommit`, false, false)
          commitFormCache({
            values,
            userId: user.userId,
            formId: formRealmId(organizationId, id),
            token: muSessionId,
            userIds: values.muInfo.sessionUsers,
            type: DocumentCacheType.AUTO_SAVE
          })
          // commit revision
        } else if (!handle) {
          setFieldValue(`muInfo.waitingForCommit`, true, false)
          const handle = setInterval(() => {
            checkFormLocksForCommit({ handle, muSessionId })
          }, 1000)
        }
      }
    }

    const handleMultiuserReconnect = ({
      sessionToken,
      colorIndex,
      multiuserLoginToken
    }) => {
      const token = sessionToken || multiuserSessionToken
      console.log('reconnecting...', token)
      getCurrentFormState({
        userId: user.userId,
        token,
        onSuccess: formState => {
          grpcFetchAllUsersInfo({
            id,
            userId: user.userId,
            token,
            multiuserLoginToken,
            onFail: e => {
              enqueueSnackbar(
                <Trans>
                  Could not connect to server for multiuser editing
                </Trans>,
                {
                  variant: 'error'
                }
              )
            },
            onSuccess: ({ users }) => {
              console.log('got current users', users)
              grpGetLockedFieldsForForm({
                userId: user.userId,
                token,
                onSuccess: locksList => {
                  const { values, setValues } = formikRef.current
                  const muInfo = {
                    ...baseMuInfo,
                    sessionStartTime: values.muInfo.sessionStartTime
                  }
                  if (values.muInfo.chat) {
                    muInfo.chat = [...values.muInfo.chat]
                  }
                  if (values.muInfo.sessionUsers) {
                    muInfo.sessionUsers = [...values.muInfo.sessionUsers]
                  }
                  const myLock = values.muInfo.lockId
                  locksList.forEach(lock => {
                    muInfo[lock.fieldId] = {
                      locked: true,
                      user: lock.lockedBy
                    }
                    if (myLock && myLock === lock.lockId) {
                      muInfo.lockId = lock.lockId
                    }
                  })
                  if (colorIndex || colorIndex === 0) {
                    let color = multiuserColors[colorIndex]
                    users[user.userId] = {
                      ...users[user.userId],
                      color
                    }
                  }
                  dispatch(setEditingUsers(users))
                  grpcFetchChatMessages({
                    userId: user.userId,
                    token,
                    sessionStartTime: muInfo.sessionStartTime,
                    onSuccess: messeges => {
                      muInfo.chat = [...muInfo.chat, ...messeges]
                      muInfo.disconnected = false
                      setMultiuserSessionToken(token)
                      setValues({
                        ...values,
                        ...formState,
                        muInfo,
                        muUsers: users
                      })
                    }
                  })
                }
              })
            }
          })
        }
      })
    }

    useEffect(() => {
      if (formikRef.current) {
        const { values } = formikRef.current
        if (
          multiuserAuthenticated &&
          (values.muInfo.disconnected || user.fake)
        ) {
          //&& values.muInfo.disconnected) {
          //console.log('start session access token', user.access_token)
          console.log('start session login token', multiuserLoginToken)
          window.location.reload()
          return
          grpcStartSession({
            userId: user.userId,
            token: user.access_token,
            multiuserLoginToken,
            formId: formRealmId(organizationId, id),
            onFail: e => {
              enqueueSnackbar(
                <Trans>
                  Could not connect to server for multiuser editing
                </Trans>,
                {
                  variant: 'error'
                }
              )
            },
            username: user.displayName,
            userInfo: {
              id: user.userId,
              step: currentStep,
              startEditingTime: moment.utc(),
              tabId,
              name: user.displayName
            },
            onSuccess: ({ sessionToken, colorIndex }) => {
              handleMultiuserReconnect({
                sessionToken,
                multiuserLoginToken,
                colorIndex
              })
            }
          })
        }
      }
    }, [multiuserLoginToken])

    useEffect(() => {
      if (useMultiuser && multiuserSessionToken) {
        const handle = setInterval(() => {
          pingServer({
            userId: user.userId,
            token: multiuserSessionToken,
            onReject: err => {
              console.log('form ping fail', err)
              const { setFieldValue, values } = formikRef.current
              if (!values.muInfo.disconnected) {
                setFieldValue(`muInfo.disconnected`, true, false)
              }
            },
            onSuccess: () => {
              const { values } = formikRef.current
              if (values.muInfo.disconnected) {
                console.log('connection restored')
                //handleMultiuserReconnect({})
              }
            }
          })
        }, 5000)
        return () => {
          clearInterval(handle)
        }
      }
    }, [useMultiuser, multiuserSessionToken])

    useEffect(() => {
      if (useMultiuser && multiuserSessionToken) {
        const minutes = 3
        const handle = setInterval(() => {
          const { values } = formikRef.current
          if (!values.muInfo.waitingForCommit && !values.muInfo.disconnected) {
            checkFormLocksForCommit({ muSessionId: multiuserSessionToken })
          }
        }, 60000 * minutes)
        return () => {
          clearInterval(handle)
        }
      }
    }, [useMultiuser, multiuserSessionToken])

    const elementsMap = mapFormElements(data, langFR)

    const scrollToTop = () => {
      console.log('scrollbarContentRef ref', scrollbarContentRef)
      if (scrollbarContentRef && scrollbarContentRef.current) {
        setTimeout(() => {
          scrollbarContentRef.current.scrollToTop()
          scrollbarContentRef.current.handleWindowResize()
          console.log('scrollbarContentRef update Scrolls', scrollbarContentRef)
        }, 500)
      }
    }

    const scrollToY = y => {
      if (scrollbarContentRef && scrollbarContentRef.current) {
        setTimeout(() => {
          scrollbarContentRef.current.scrollTop(y)
        }, 600)
      }
    }

    const navigateToError = ({ section, id }) => {
      setStep(section)
      if (scrollbarContentRef && scrollbarContentRef.current) {
        setTimeout(() => {
          const domElement = document.getElementById(id)
          scrollbarContentRef.current.scrollTop(domElement.offsetTop)
        }, 300)
      }
    }

    const handleObjectMissing = () => {
      setInsufficientAccess(true)
      enqueueSnackbar(<Trans>Not all objects could be loaded!</Trans>, {
        variant: 'error'
      })
    }

    const fetchData = ({ multiuserReload = false }) => {
      return Promise.all([
        renderData ? Promise.resolve(renderData) : fetchFormPage(formId),
        getNetwork(),
        fetchReusableFormPage()
      ])
        .then(([result, network, reusableFormPage]) => {
          console.log('form loaded', result, network)

          const reusableComponents =
            reusableFormPage?.config.injectableComponents || []
          const reusableComponentsMap = {}
          reusableComponents.forEach(component => {
            const addToMap = item => {
              reusableComponentsMap[item.id] = item
              if (item.elements) {
                item.elements.forEach(child => {
                  addToMap(child)
                })
              }
            }
            addToMap(component)
          })

          const updateInjectedItem = item => {
            if (item.elements) {
              item.elements.forEach((element, index) => {
                if (element.injectableId) {
                  if (reusableComponentsMap[element.injectableId]) {
                    const updated = cloneInjectableElement({
                      item: element,
                      componentsMap: reusableComponentsMap
                    })
                    item.elements[index] = updated
                  } else {
                    delete element.injectableId
                    delete element.injectableName
                  }
                }
                updateInjectedItem(element)
              })
            }
          }
          result.sections.forEach(section => {
            updateInjectedItem(section)
          })
          setNetwork(network)
          setValidationSchema(
            constructValidationSchema({
              data: result,
              english: user.language === 'en_US'
            })
          )
          if (result.objectsConnected && result.objectsConnected.length > 0) {
            if (id) {
              let noId
              const objArray = id.split(';')
              objArray.forEach((string, index) => {
                if (string.includes('NO_OBJECT_ID')) {
                  noId = true
                }
                const ident = string.split('=')[0]
                let objInfo
                result.objectsConnected.some(obj => {
                  if (obj.identId === ident) {
                    objInfo = obj
                  }
                  return obj.identId === ident
                })
                if (objInfo && objInfo.type === 'User') {
                  objArray[index] = ident + '=' + user.userId
                }
              })
              if (noId) {
                enqueueSnackbar(
                  <Trans>
                    Ids were not provided for all objects used in this form.
                    Contact your administrator
                  </Trans>,
                  { variant: 'error' }
                )
                return Promise.reject()
              }
              if (objArray.join(';') !== id) {
                history.push(
                  '/elasticform/' + formId + '/' + objArray.join(';')
                )
                return Promise.reject()
              }
            }
            return connectedObjectQuery(result, {
              handleObjectMissing,
              langFR,
              id,
              enqueueSnackbar
            }).then(
              ({ connectedMap, describeMap }) => {
                let preventAccess
                let shouldDisable = Boolean(forceDisabled)

                const programManagerSession =
                  params.organizationId && checkAuth(authRoles.pm, user.role)
                if (programManagerSession) {
                  shouldDisable = true
                }

                Object.values(connectedMap).forEach(data => {
                  const obj = data.sfObject
                  const additionalInfo = data.additionalInfo
                  const objName = obj.attributes.type
                  Object.values(appConfigurations)
                    .filter(config => config && typeof config === 'object')
                    .forEach(config => {
                      const sameForm = config.form && config.form === formId
                      const sameRecordType =
                        obj.RecordType &&
                        obj.RecordType.Id === config.recordType
                      if (sameForm && sameRecordType) {
                        setConfiguration(config)
                      }
                    })

                  if (
                    obj.UserRecordAccess &&
                    !obj.UserRecordAccess.HasEditAccess &&
                    !isPreview
                  ) {
                    shouldDisable = true
                  }

                  if (result.readOnly || returnPdf || displayView) {
                    shouldDisable = true
                  }
                  if (!isPreview) {
                    if (objName === 'Opportunity') {
                      if (
                        obj.StageName !== opportunitieStages.IN_PROGRESS &&
                        obj.StageName !== opportunitieStages.MORE_INFO_REQUIERED
                      ) {
                        shouldDisable = true
                      }
                      if (
                        obj.ProcessInstances &&
                        obj.ProcessInstances.records
                      ) {
                        if (
                          obj.ProcessInstances.records.some(
                            process => process.Status === 'Pending'
                          )
                        ) {
                          enqueueSnackbar(
                            <Trans>
                              Application is Locked: Contact the Administrator
                            </Trans>,
                            { variant: 'error' }
                          )
                          shouldDisable = true
                        }
                      }
                    } else if (objName === 'TechnicalAdvisoryAssignment__c') {
                      if (
                        obj.Status__c === 'Submitted' ||
                        obj.Status__c === 'Completed'
                      ) {
                        shouldDisable = true
                      }
                    } else if (
                      objName === 'Account' &&
                      result.restrictAccessForRoles
                    ) {
                      let userRole
                      additionalInfo.accountMembers.some(member => {
                        if (member.UserId === user.userId) {
                          userRole = member.TeamMemberRole
                          return true
                        }
                        return false
                      })
                      if (
                        result.restrictAccessForRoles[userRole] ||
                        !userRole
                      ) {
                        const restricType =
                          result.restrictAccessForRoles[userRole]

                        if (restricType === 'preventAccessBlock') {
                          enqueueSnackbar(
                            <Trans>
                              Your member role has insufficient access to view
                              this page!
                            </Trans>,
                            {
                              variant: 'error'
                            }
                          )
                          history.push('/grants/GrantsHome')
                          preventAccess = true
                        } else if (restricType === 'disable') {
                          shouldDisable = true
                          setWrongAccountRole('warning')
                        } else if (restricType === 'preventAccessMessage') {
                          setWrongAccountRole('prevent')
                        }
                      }
                    }
                  }
                })
                setDisabled(shouldDisable)
                const fakeInitialValues = getInitialValues({
                  data: result,
                  connectedMap
                })
                const map = mapFormElements(result, langFR)
                const contacts = []
                const accounts = []
                Object.keys(map).forEach(key => {
                  const question = map[key]
                  const value = fakeInitialValues[key]
                  if (question.elementType === 'connectContact') {
                    if (!contacts.includes(value) && value) {
                      contacts.push(value)
                    }
                  } else if (question.elementType === 'connectAccount') {
                    if (!accounts.includes(value) && value) {
                      accounts.push(value)
                    }
                  }
                })
                const mapPromise =
                  contacts.length > 0 || accounts.length > 0
                    ? Promise.all([
                        getContactsMap(contacts),
                        getAccountsMap(accounts)
                      ])
                    : Promise.resolve().then(r => [{}, {}])

                return mapPromise.then(([contactsMap, accountsMap]) => {
                  let formType = result.formType
                  if (!formType) {
                    if (result.readOnly) {
                      formType = 'printable'
                    } else if (result.showPdfDownload) {
                      formType = 'pdf'
                    } else {
                      formType = 'editable'
                    }
                  }
                  setPdfDisplay(formType === 'pdf')
                  setReadOnly(formType === 'printable')
                  setConnectedMap(connectedMap)
                  setDescribeMap(describeMap)
                  setData(result)
                  const initialValues = getInitialValues({
                    data: result,
                    connectedMap,
                    contactsMap,
                    accountsMap
                  })

                  const multiuser =
                    Boolean(result.enableMultiuser && id) &&
                    (checkAuth(authRoles.tester, user.role) ||
                      organization.additionalFeatures.includes(
                        'multiuser_testing'
                      )) &&
                    !Boolean(result.readOnly || displayView) &&
                    (!shouldDisable || programManagerSession)

                  setUseMultiuser(multiuser)
                  if (multiuser && !multiuserReload) {
                    setInitialValues(initialValues)
                    const userInfo = {
                      id: user.userId,
                      step: 0,
                      startEditingTime: moment.utc(),
                      tabId,
                      name: user.displayName
                    }
                    tryInitiatingForm({
                      formId: formRealmId(organizationId, id),
                      metadata: {
                        sessionName: parseDisplayedText({
                          returnString: true,
                          text: result.titleEN,
                          objectsFieldsMap: getObjectsFieldsMap({
                            data: result,
                            connectedMap,
                            describeMap
                          }),
                          describeMap,
                          renderProps: {
                            connectedMap
                          }
                        }),
                        organizationName: organization.organisationsName,
                        organizationId,
                        formId,
                        url: id
                      },
                      userId: user.userId,
                      multiuserLoginToken,
                      accessToken: user.access_token,
                      initialValues,
                      mode: programManagerSession
                        ? 'ProgramManager'
                        : 'Testing',
                      userInfo,
                      onFail: e => {
                        console.log('failed while initiating form', e)
                        setLoading(preventAccess)
                        setUseMultiuser(false)
                        enqueueSnackbar(
                          <Trans>
                            Could not connect to server for multiuser editing
                          </Trans>,
                          {
                            variant: 'error'
                          }
                        )
                      },
                      onSuccess: ({
                        formState,
                        unsavedData,
                        sessionToken,
                        colorIndex,
                        users,
                        isAnotherUser,
                        sessionStartTime
                      }) => {
                        //setHaveConnectionToServer(true)
                        setMultiuserSessionToken(sessionToken)
                        console.log(
                          'session started succesfully',
                          sessionToken,
                          colorIndex,
                          formState,
                          unsavedData,
                          users,
                          sessionStartTime
                        )
                        console.log(
                          'all users',
                          users,
                          isAnotherUser,
                          sessionStartTime
                        )
                        if (
                          unsavedData &&
                          !shouldDisable &&
                          getFormVersionDifference({
                            cache: unsavedData,
                            current: initialValues,
                            elementsMap: map
                          }).length > 0
                        ) {
                          setUnsavedDataDetected(unsavedData)
                        }
                        grpGetLockedFieldsForForm({
                          userId: user.userId,
                          token: sessionToken,
                          onSuccess: locksList => {
                            const toSet = isAnotherUser
                              ? { ...initialValues, ...formState }
                              : initialValues
                            let muInfo = toSet.muInfo || {}
                            muInfo.sessionStartTime = sessionStartTime
                            muInfo.sessionUsers = Object.keys(users)
                            locksList.forEach(lock => {
                              const isUserInForm =
                                users[lock.lockedBy] &&
                                lock.lockedBy !== user.userId
                              if (isUserInForm) {
                                muInfo[lock.fieldId] = {
                                  locked: true,
                                  user: lock.lockedBy
                                }
                              } else {
                                unlockFieldWithoutChanges({
                                  token: sessionToken,
                                  lockId: lock.lockId,
                                  fieldId: lock.fieldId,

                                  userId: user.userId
                                })
                              }
                            })
                            let color = multiuserColors[colorIndex]
                            users[user.userId] = {
                              ...userInfo,
                              color
                            }
                            dispatch(setEditingUsers(users))
                            if (isAnotherUser) {
                              grpcFetchChatMessages({
                                userId: user.userId,

                                sessionStartTime,
                                token: sessionToken,
                                onSuccess: messeges => {
                                  muInfo.chat = messeges
                                  let usersToSet = { ...users }
                                  if (formikRef.current) {
                                    const currentUsers =
                                      formikRef.current.values.muUsers
                                    usersToSet = {
                                      ...usersToSet,
                                      ...currentUsers
                                    }
                                  }
                                  setInitialValues({
                                    ...toSet,
                                    muInfo,
                                    muUsers: usersToSet
                                  })
                                  setLoading(preventAccess)
                                }
                              })
                            } else {
                              setInitialValues({
                                ...toSet,
                                muInfo,
                                muUsers: users
                              })
                              setLoading(preventAccess)
                            }
                            //}
                          }
                        })
                      }
                    })
                  } else if (multiuser && multiuserReload) {
                    const { values } = formikRef.current
                    setInitialValues({
                      ...initialValues,
                      muUsers: values.muUsers,
                      muInfo: values.muInfo
                    })
                    setLoading(preventAccess)
                  } else {
                    setInitialValues(initialValues)
                    setLoading(preventAccess)
                  }
                  // if (injectableComponents) {
                  //   const toSet = {}
                  //   injectableComponents.forEach(component => {
                  //     toSet[component.injectableId] = component
                  //   })
                  //   setInjectablesMap(toSet)
                  // }
                })
              },
              reject => {
                console.error('no object found', reject)
                enqueueSnackbar(<Trans>No object found!</Trans>, {
                  variant: 'error'
                })
              }
            )
          } else {
            setInitialValues(
              getInitialValues({
                data: result,
                connectedMap: {}
              })
            )
            setData(result)
            setLoading(false)
          }
        })
        .catch(error => {
          console.error('error loading form', error)
        })
    }

    const trySaving = useCallback(
      ({ values, type = 'Save' }) => {
        if (
          overrideWarningData ||
          !SFAuthService.user ||
          readOnly ||
          saving ||
          saveFailedData
        ) {
          return
        }
        setSaving(true)

        if (useMultiuser) {
          return grpGetLockedFieldsForForm({
            token: multiuserSessionToken,
            userId: user.userId,
            onSuccess: locks => {
              if (locks.length === 0) {
                grpcRequestSFSave({
                  token: multiuserSessionToken,
                  userId: user.userId,
                  type
                })
              } else {
                enqueueSnackbar(
                  <Trans>
                    You cannot save the form, some fields are currently edited
                    by other users!
                  </Trans>,
                  {
                    variant: 'error'
                  }
                )
                setSaving(false)
              }
            }
          })
        } else {
          const savingSnackbar = enqueueSnackbar(null, {
            persist: true,
            content: key => ProgressSnackbar(<Trans>Saving</Trans>)
          })

          const handleError = err => {
            console.error('error saving form', err)
            closeSnackbar(savingSnackbar)
            setSaving(false)
            enqueueSnackbar(
              <Trans>
                Error ocurred while saving! Some fields were not saved!
              </Trans>,
              {
                variant: 'error'
              }
            )
          }

          if (!data.displayesSavedInMeantimeWarning || useMultiuser) {
            return handleSave({ values, snackbar: savingSnackbar }).catch(
              err => {
                handleError(err)
              }
            )
          }

          return connectedObjectQuery(data, {
            id,
            langFR,
            enqueueSnackbar,
            handleObjectMissing
          })
            .then(result => {
              const currentConnectedMap = result.connectedMap
              if (Object.keys(currentConnectedMap).length === 0) {
                enqueueSnackbar(<Trans>You lost connection!</Trans>, {
                  variant: 'error'
                })
                return Promise.reject()
              }
              const wasSavedInMeantime = Object.keys(currentConnectedMap).some(
                key => {
                  const objectSaved = currentConnectedMap[key].sfObject
                  const objectNow = connectedMap[key].sfObject
                  const savedDate = moment.utc(objectSaved.LastModifiedDate)
                  const currentDate = moment.utc(objectNow.LastModifiedDate)
                  return savedDate.isAfter(currentDate)
                }
              )
              if (wasSavedInMeantime) {
                const dataToPass = {
                  type: 'form'
                }
                const savedValues = getInitialValues({
                  data,
                  connectedMap: currentConnectedMap
                })

                const current = {}
                const saved = {}
                const map = mapFormElements(data, langFR)
                const contacts = []
                const accounts = []
                Object.keys(map).forEach(key => {
                  const question = map[key]
                  if (question.elementType === 'connectContact') {
                    if (
                      savedValues[key] &&
                      savedValues[key] !== values[key]?.id
                    ) {
                      contacts.push(savedValues[key])
                    }
                  } else if (question.elementType === 'connectAccount') {
                    if (
                      savedValues[key] &&
                      savedValues[key] !== values[key]?.id
                    ) {
                      accounts.push(savedValues[key])
                    }
                  }
                })
                const mapPromise =
                  contacts.length > 0 || accounts.length > 0
                    ? Promise.all([
                        getContactsMap(contacts),
                        getAccountsMap(accounts)
                      ])
                    : Promise.resolve().then(r => [{}, {}])
                return mapPromise.then(([contactsMap, accountsMap]) => {
                  closeSnackbar(savingSnackbar)
                  Object.keys(values).forEach(key => {
                    const question = map[key]
                    if (question) {
                      dataToPass[key] = question
                      const toText =
                        formElementTypes[question.elementType].valueToText
                      const parseValue =
                        formElementTypes[question.elementType]
                          .parseValueToCompare
                      const { isConnected } = question.typeProps
                      let connectedFieldDetails
                      const { connectedField, connectedObject } =
                        getMainConnected(question)
                      if (isConnected && connectedField) {
                        connectedFieldDetails = extractFieldDetails({
                          connectedField,
                          connectedMap,
                          describeMap,
                          connectedObject
                        })
                      }
                      if (toText) {
                        current[key] = {
                          value: parseValue
                            ? parseValue(values[key])
                            : values[key],
                          ...toText(values[key], question, {
                            contactsMap,
                            accountsMap,
                            connectedFieldDetails
                          })
                        }
                        saved[key] = {
                          value: parseValue
                            ? parseValue(savedValues[key])
                            : savedValues[key],
                          ...toText(savedValues[key], question, {
                            contactsMap,
                            accountsMap,
                            connectedFieldDetails
                          })
                        }
                      } else {
                        console.warn(
                          'No value to text function configured for: ',
                          question.elementType
                        )
                      }
                    }
                  })
                  setOverrideWarningData({
                    current,
                    saved,
                    formData: dataToPass
                  })
                  return Promise.resolve()
                })
              } else {
                handleSave({ values, snackbar: savingSnackbar })
              }
            })
            .catch(err => {
              handleError(err)
            })
        }
      },
      [
        data,
        connectedMap,
        saving,
        saveFailedData,
        overrideWarningData,
        useMultiuser,
        multiuserSessionToken
      ]
    )

    useEffect(() => {
      if ((formId && id) || !id) {
        setLoading(true)
        fetchData({})
      }
    }, [id, formId])

    useEffect(() => {
      if (id && Object.keys(connectedMap).length > 0 && !isPreview) {
        const objArray = id.split(';')
        objArray.forEach((string, index) => {
          const ident = string.split('=')[0]
          const obj = connectedMap[ident]
          const connectedObject = obj.sfObject
          if (
            connectedObject &&
            connectedObject.attributes.type === 'Account'
          ) {
            objArray[index] = ident + '=' + organization.id
          }
        })
        if (objArray.join(';') !== id) {
          history.push('/elasticform/' + formId + '/' + objArray.join(';'))
        }
      }
    }, [organization.id])

    useEffect(() => {
      // This fixes issues with bold font not being used in first render of pdf
      Font.load({ fontFamily: 'Roboto' })
      Font.load({ fontFamily: 'Roboto', fontStyle: 'italic' })
      Font.load({ fontFamily: 'Roboto', fontWeight: 700 })
      Font.load({ fontFamily: 'Roboto', fontWeight: 700, fontStyle: 'italic' })
    }, [])

    useEffect(() => {
      document.title = formTitlePdf
      return () => {
        document.title = defaultDocTitle
      }
    }, [formTitlePdf])

    useEffect(() => {
      //This checks if first section is disabled and sets the initial selected section to first non-disabled section
      if (initialValues) {
        const initialDisabledIds = getDisabledIds({
          sections: sections,
          elementsMap,
          values: initialValues,
          langFR,
          connectedMap,
          describeMap,
          errors: {}
        })
        if (currentStep === 0) {
          let valid = currentStep
          while (initialDisabledIds.includes(sectionConditionId + valid)) {
            valid++
            if (valid >= sections.length) {
              valid = 0
              break
            }
          }
          if (valid > 0) {
            setStep(valid)
          }
        }
      }
    }, [initialValues])

    useEffect(() => {
      // Handle autosave
      const minutes = data && Number(data.autosave)
      if (loading || !minutes || isPreview || disabled || readOnly) {
        return
      }
      if (minutes !== 0) {
        const handle = setInterval(() => {
          if (formikRef && isTrueDirty(formikRef)) {
            const { values } = formikRef.current
            if (useMultiuser) {
              //only first user should commence autosave
              grpcFetchAllUsersInfo({
                id,
                userId: user.userId,
                onSuccess: ({ users }) => {
                  let myUserInfo
                  Object.values(users).some(obj => {
                    if (obj.id === user.userId) {
                      myUserInfo = obj
                      return true
                    }
                    return false
                  })
                  if (myUserInfo && myUserInfo.logOrder === 0) {
                    trySaving({ values, type: 'Autosave' })
                  }
                }
              })
            } else {
              trySaving({ values })
            }
          }
        }, 60000 * minutes)
        return () => {
          clearInterval(handle)
        }
      }
    }, [
      currentStep,
      loading,
      trySaving,
      initialValues,
      useMultiuser,
      id,
      user.userId,
      disabled,
      readOnly
    ])

    useEffect(() => {
      // Update SF fields language info on user language change
      if (data) {
        setValidationSchema(
          constructValidationSchema({
            data,
            english: user.language === 'en_US'
          })
        )
        connectedObjectQuery(data, {
          id,
          returnOnlyDescribe: true,
          langFR: user.language !== 'en_US',
          enqueueSnackbar,
          handleObjectMissing
        }).then(({ describeResult }) => {
          const newMap = { ...connectedMap }
          Object.values(newMap).forEach(obj => {
            const { objectType } = obj
            const sfData = describeResult[objectType]
            obj.fieldsMap = sfData.fieldsMap
          })
          setConnectedMap(newMap)
          setDescribeMap(describeResult)
        })
      }
    }, [user.language])

    useEffect(() => {
      if (formikRef.current) {
        formikRef.current.validateForm()
      }
    }, [validationSchema])

    const addMethodToValidationSchema = (method, id) => {
      const yupObj = constructValidationSchema({
        data,
        english: user.language === 'en_US',
        returnRaw: true
      })
      if (!yupObj[id]) {
        yupObj[id] = method
      } else {
        yupObj[id].concat(method)
      }
      setValidationSchema(Yup.object().shape(yupObj))
    }

    const muHandleChangeStep = index => {
      const toSet = formikRef.current.values
      const newMuUsers = { ...toSet.muUsers }
      if (newMuUsers[user.userId]) {
        grpcUpdateUserInfo({
          formId: formRealmId(organizationId, id),
          token: multiuserSessionToken,
          userId: user.userId,
          userInfo: {
            ...newMuUsers[user.userId],
            step: index
          }
        })
      }
    }

    const handleSave = ({ values, snackbar }) => {
      const savingSnackbar =
        snackbar ||
        enqueueSnackbar(null, {
          persist: true,
          content: key => ProgressSnackbar(<Trans>Saving</Trans>)
        })

      const isValid = Object.keys(formikRef.current.errors).length === 0
      return handleFormSave({
        values,
        elementsMap,
        connectedMap,
        appConfigurations: appConfigurations.AVAILABLE_APPLICATIONS,
        reduxBag: {
          dispatch,
          user,
          organization,
          avaliableOrganizations,
          appConfigurations
        },
        utilityBag: { closeSnackbar, enqueueSnackbar, reloadLastModifiedDates }
      }).then(
        result => {
          fetchData({ multiuserReload: useMultiuser }).then(r => {
            console.log('form saved', result)
            closeSnackbar(savingSnackbar)
            setSaving(false)
            enqueueSnackbar(<Trans>Successfully saved!</Trans>, {
              variant: 'success'
            })

            if (saveCallback) {
              const savedMap = {}
              Object.entries(elementsMap).forEach(([key, element]) => {
                const fieldValue = values[key]
                const { connectedField, connectedObject } =
                  getMainConnected(element)
                if (connectedField && connectedObject) {
                  const { objectType } = connectedMap[connectedObject]
                  if (!savedMap[objectType]) {
                    savedMap[objectType] = {}
                  }
                  savedMap[objectType][connectedField.name] = fieldValue
                }
              })
              saveCallback({
                isValid,
                savedMap
              })
            }
            if (
              formId === appConfigurations.FORM_USER_PROFILE &&
              !organization.id &&
              hasRole(user.role, authRoles.grantee) &&
              !user.userObject.redirectedToJoinOrganization
            ) {
              history.push('/grants/JoinOrganization')
              saveUser({
                Id: user.userId,
                Redirected_To_Join_Organization__c: true
              })
            }
          })
        },
        rejectObj => {
          const { reject, errorStringified } = rejectObj
          console.error(reject)
          closeSnackbar(savingSnackbar)
          setSaving(false)
          const current = {}
          const map = mapFormElements(data, langFR)
          const dataToPass = {
            type: 'form'
          }
          Object.keys(values).forEach(key => {
            const question = map[key]
            if (question) {
              dataToPass[key] = question
              const toText = formElementTypes[question.elementType].valueToText
              const parseValue =
                formElementTypes[question.elementType].parseValueToCompare
              const { isConnected } = question.typeProps
              let connectedFieldDetails
              const { connectedField, connectedObject } =
                getMainConnected(question)
              if (isConnected && connectedField) {
                connectedFieldDetails = extractFieldDetails({
                  connectedField,
                  connectedMap,
                  describeMap,
                  connectedObject
                })
              }
              if (toText) {
                current[key] = {
                  title: question.title,
                  value: parseValue
                    ? parseValue(values[key], { saveFailed: true })
                    : values[key],
                  ...toText(values[key], question, {
                    connectedFieldDetails,
                    saveFailed: true
                  })
                }
              } else {
                console.warn(
                  'No value to text function configured for: ',
                  question.elementType
                )
              }
            }
          })
          let connectedAccount, connectedOpportunity
          Object.values(connectedMap).forEach(obj => {
            if (obj.objectType === 'Opportunity') {
              connectedOpportunity = obj.sfObject.Id
            }
            if (obj.objectType === 'Account') {
              connectedAccount = obj.sfObject.Id
            }
          })
          const toPass = _.cloneDeep(values)
          delete toPass.muInfo
          delete toPass.muUsers

          if (
            data.displaySaveFailedDialog &&
            reject === DEFAULT_FORM_SAVE_REJECT
          ) {
            //Don't create for my user
            if (user.userId !== '0055X000000KQ81QAG') {
              createCaseByFlow({
                title: 'Saving failed',
                type: 'Saving Failed',
                description: JSON.stringify({
                  values: toPass,
                  reject,
                  errorStringified,
                  path: history.location.pathname
                }),
                contact: user.userObject.contactId,
                opportunityId: connectedOpportunity,
                organization: connectedAccount || organization.id,
                skipAssigment: false,
                owner: '005Am000000Kas0IAC'
              })
            }
            setSaveFailedData({
              current,
              formData: dataToPass
            })
          } else {
            //Don't create for my user
            if (user.userId !== '0055X000000KQ81QAG') {
              createCaseByFlow({
                title: 'Saving failed',
                type: 'Saving Failed',
                description: JSON.stringify({
                  values: toPass,
                  reject,
                  errorStringified,
                  path: history.location.pathname
                }),
                contact: user.userObject.contactId,
                opportunityId: connectedOpportunity,
                organization: connectedAccount || organization.id,
                skipAssigment: false,
                owner: '005Am000000Kas0IAC'
              })
            }
            enqueueSnackbar(
              <Trans>
                Error ocurred while saving! Some fields were not saved!
              </Trans>,
              {
                variant: 'error'
              }
            )
          }
          return reject
        }
      )
    }

    const reloadLastModifiedDates = () => {
      return connectedObjectQuery(data, {
        enqueueSnackbar,
        langFR,
        id,
        handleObjectMissing
      }).then(queryResult => {
        const toSet = { ...connectedMap }
        Object.keys(toSet).forEach(key => {
          const setObj = toSet[key].sfObject
          const nowObj = queryResult.connectedMap[key]?.sfObject
          if (nowObj && setObj && setObj.LastModifiedDate) {
            setObj.LastModifiedDate = nowObj.LastModifiedDate
          }
        })
        setConnectedMap(toSet)
      })
    }

    const handleSubmit = ({ values }) => {
      setSaving(true)
      const submitText = <Trans>Submitting</Trans>

      const snackKey = enqueueSnackbar(null, {
        variant: 'info',
        persist: true,
        content: key => ProgressSnackbar(submitText)
      })

      const baseHandleError = (error, snackbarText) => {
        console.log('error submitting', error)
        setSaving(false)
        closeSnackbar(snackKey)
        enqueueSnackbar(snackbarText || <Trans>Error Submitting</Trans>, {
          variant: 'error'
        })
      }

      const baseHandleSuccess = ({ result, successText }) => {
        console.log('submitted and got ', result)
        return fetchData({ multiuserReload: useMultiuser }).then(() => {
          closeSnackbar(snackKey)
          setSaving(false)
          enqueueSnackbar(successText, {
            variant: 'success'
          })
        })
      }

      return Promise.all([
        handleFormSave({
          values,
          elementsMap,
          connectedMap,
          appConfigurations: appConfigurations.AVAILABLE_APPLICATIONS,
          reduxBag: {
            dispatch,
            user,
            organization,
            avaliableOrganizations
          },
          utilityBag: {
            closeSnackbar,
            enqueueSnackbar,
            reloadLastModifiedDates
          }
        }),
        fetchFormPages()
      ]).then(([result, formPages]) => {
        if (noConnectedObjects) {
          enqueueSnackbar(<Trans>There is no object to submit!</Trans>, {
            variant: 'error'
          })
        } else {
          const promises = []
          Object.values(connectedMap).forEach(data => {
            const connectedObject = data.sfObject
            const type = connectedObject.attributes.type
            if (type === 'Opportunity') {
              const organizationDetailsFormId =
                appConfigurations.FORM_ORGANIZATION_DETAILS
              let organizationDetailsForm
              organizationDetailsFormId &&
                formPages.some(form => {
                  if (organizationDetailsFormId === form.id) {
                    organizationDetailsForm = form.config
                  }
                  return organizationDetailsFormId === form.id
                })
              let checkOrganizationValidity = Promise.resolve(true)
              if (organizationDetailsForm) {
                checkOrganizationValidity = checkFormValidity({
                  formId: organizationDetailsFormId,
                  id: constructFormAddressString({
                    user,
                    organization,
                    configuration,
                    objectsConnected: organizationDetailsForm.objectsConnected
                  })
                })
              }
              promises.push(
                checkOrganizationValidity.then(isValid => {
                  if (!isValid) {
                    baseHandleError(
                      'organization details form is not valid',
                      <Trans>
                        You cannot submit the application until you fill out all
                        field marked as required in the Organisation Details
                      </Trans>
                    )
                    return Promise.reject(
                      new Error('organization details form is not valid')
                    )
                  } else {
                    return submitOpportunity(connectedObject.Id)
                      .then(res => {
                        return baseHandleSuccess({
                          result,
                          successText: <Trans>Submitted Application</Trans>
                        })
                        // .then(result => {
                        //   history.push('/grants/ApplicationsList')
                        // })
                      })
                      .catch(error => {
                        baseHandleError(error)
                      })
                  }
                })
              )
            }
            if (type === 'TechnicalAdvisoryAssignment__c') {
              promises.push(
                submitTechnicalAdvisory(connectedObject.Id).then(
                  result => {
                    return baseHandleSuccess({
                      result,
                      successText: (
                        <Trans>Submitted Technical Advisory Assigment</Trans>
                      )
                    })
                  },
                  reject => {
                    baseHandleError(reject)
                  }
                )
              )
            }
          })
          return Promise.all(promises)
        }
      })
    }

    const returnInDialog = ({ component, loading, disableSave }) => {
      if (loading) {
        return (
          <Dialog open maxWidth='lg' fullWidth>
            <DialogTitle>
              <Grid
                container
                wrap='nowrap'
                alignItems='flex-end'
                justifyContent='flex-end'
              >
                <Grid item>
                  <IconButton
                    onClick={() => {
                      onDialogClose()
                    }}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ width: '500px', height: '500px' }}>
              <Loading />
            </DialogContent>
          </Dialog>
        )
      }
      const formTitle = parseDisplayedText({
        text: langFR ? data.titleFR : data.titleEN,
        french: langFR,
        objectsFieldsMap,
        describeMap,
        returnString: true
      })
      return (
        <Dialog
          open
          maxWidth='lg'
          fullWidth
          scroll='paper'
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <DialogTitle>
            <Grid
              container
              wrap='nowrap'
              alignItems='center'
              justifyContent='center'
            >
              <Grid item xs>
                <Typography
                  variant='h6'
                  style={{
                    textAlign: 'center'
                  }}
                >
                  {formTitle}
                </Typography>
              </Grid>

              {displayPrintButton && (
                <Grid item>
                  <ReactToPrint
                    // onAfterPrint={() => (document.title = defaultDocTitle)}
                    // onBeforePrint={() => (document.title = formTitle)}
                    trigger={() => (
                      <IconButton
                        disabled={disableSave}
                        aria-label={<Trans>Print</Trans>}
                        className=''
                      >
                        <Icon>print</Icon>
                      </IconButton>
                    )}
                    content={() => printRef.current}
                  />
                </Grid>
              )}
              <Grid item>
                <IconButton
                  onClick={() => {
                    onDialogClose()
                  }}
                >
                  <Icon>close</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>{component}</DialogContent>
        </Dialog>
      )
    }

    const checkIfFormValidationShouldRebuild = ({ sections, values }) => {
      let shouldRebuild = false
      const requiredFromConditions = []
      const nonRequiredFromConditions = []
      const validationInfoFromConditions = {}
      const errors =
        formikRef && formikRef.current ? formikRef.current.errors : {}
      const checkRequiredConditions = item => {
        if (item.elements) {
          item.elements.forEach(element => {
            checkRequiredConditions(element)
          })
        }
        if (item.conditions) {
          item.conditions
            .filter(condition =>
              ['required', 'notRequired', 'minFiles'].includes(condition.state)
            )
            .forEach(condition => {
              const { conditionMet, state } = isConditionMet({
                condition,
                elementsMap,
                values,
                langFR,
                connectedMap,
                errors
              })
              const yupField = validationSchema.fields[item.id]
              if (conditionMet) {
                if (state === 'notRequired') {
                  nonRequiredFromConditions.push(item.id)
                } else {
                  requiredFromConditions.push(item.id)
                }
                if (yupField && state === 'notRequired') {
                  shouldRebuild = true
                } else if (!yupField && state !== 'notRequired') {
                  shouldRebuild = true
                }
              } else {
                if (
                  yupField &&
                  state !== 'notRequired' &&
                  !item.typeProps.required
                ) {
                  shouldRebuild = true
                } else if (!yupField && state === 'notRequired') {
                  shouldRebuild = true
                } else if (item.typeProps.required && !yupField) {
                  shouldRebuild = true
                }
              }

              if (state === 'minFiles') {
                if (conditionMet) {
                  validationInfoFromConditions[item.id] = {
                    minFiles: +condition.minFiles
                  }
                }
                if (yupField) {
                  const current = +yupField._meta.current
                  const parameter = +condition.minFiles
                  if (
                    current &&
                    parameter &&
                    current !== parameter &&
                    conditionMet
                  ) {
                    shouldRebuild = true
                  }
                  if (
                    current &&
                    parameter &&
                    current === parameter &&
                    !conditionMet
                  ) {
                    shouldRebuild = true
                  }
                }
              }
            })
        }
      }
      sections.forEach(section => checkRequiredConditions(section))
      if (shouldRebuild) {
        console.log(
          'validation change from conditions detected. Form will rebuild validation schema'
        )
        setValidationSchema(
          constructValidationSchema({
            data,
            requiredFromConditions,
            nonRequiredFromConditions,
            validationInfoFromConditions,
            english: user.language === 'en_US'
          })
        )
      }
    }

    if (loading || !initialValues) {
      if (inDialog) {
        return returnInDialog({
          loading: true,
          disableSave: true
        })
      }
      return fixedDisplay || returnPdf ? (
        <Grid container alignContent='center' alignItems='center'>
          <Loading isNotFixed />
        </Grid>
      ) : (
        <Loading />
      )
    }

    if (insufficientAccess) {
      return (
        <div style={{ padding: 15 }}>
          <Alert severity='error'>
            <AlertTitle>
              <Typography variant='h6'>
                <Trans>
                  You don't have access to all objects used in this form!
                </Trans>
              </Typography>
            </AlertTitle>
            <div style={{ marginTop: 5 }}>
              <Trans>You cannot view or edit this page</Trans>
            </div>
          </Alert>
        </div>
      )
    }

    if (wrongAccountRole === 'prevent') {
      return (
        <div style={{ padding: 15 }}>
          <Alert severity='error'>
            <AlertTitle>
              <Typography variant='h6'>
                <Trans>You cannot view or edit this page</Trans>
              </Typography>
            </AlertTitle>
            <div style={{ marginTop: 5 }}>
              {/* <Trans>You cannot view or edit this page</Trans>
              {'. '} */}
              <Trans>You can request higher organization access</Trans>{' '}
              <Link href='/grants/Organizations'>
                <Trans>here</Trans>
              </Link>
            </div>
          </Alert>
        </div>
      )
    }

    const noStepper = sections.length < 2 || pdfDisplay
    let style = {
      padding: 20,
      flexGrow: 1,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      flexDirection: 'column',
      //minHeight: '100%',
      userSelect: 'text'
    }
    try {
      if (data.style) {
        const parsed = JSON.parse(data.style)
        if (typeof parsed === 'object') {
          style = Object.assign(style, JSON.parse(data.style))
        }
      }
    } catch (e) {}

    const toReturn = (
      <Formik
        innerRef={formikRef}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
        initialValues={initialValues}
        initialTouched={getInitialTouched(data)}
      >
        {({ values, validateForm, setValues, setFieldValue, errors }) => {
          console.log('values', values, connectedMap)
          const dirty = isTrueDirty(formikRef)
          const formTitle = parseDisplayedText({
            text: langFR ? data.titleFR : data.titleEN,
            french: langFR,
            objectsFieldsMap,
            describeMap,
            renderProps: {
              connectedMap
            }
          })

          const disabledIds = getDisabledIds({
            sections,
            elementsMap,
            values,
            langFR,
            connectedMap,
            describeMap,
            errors
          })

          const sectionTitle = parseDisplayedText({
            text: langFR
              ? sections[currentStep].titleFR
              : sections[currentStep].titleEN,
            french: langFR,
            objectsFieldsMap,
            describeMap,
            renderProps: {
              connectedMap
            }
          })

          checkIfFormValidationShouldRebuild({ sections, values })

          const handleNext = () => {
            let toSet = currentStep + 1
            while (disabledIds.includes(sectionConditionId + toSet)) {
              toSet++
              if (toSet >= sections.length) {
                toSet = currentStep
                break
              }
            }
            scrollToTop()
            setStep(toSet)
            if (useMultiuser) {
              muHandleChangeStep(toSet)
            }
          }

          const handleBack = () => {
            let toSet = currentStep - 1
            while (disabledIds.includes(sectionConditionId + toSet)) {
              toSet--
              if (toSet <= 0) {
                toSet = currentStep
                break
              }
            }
            scrollToTop()
            setStep(toSet)
            if (useMultiuser) {
              muHandleChangeStep(toSet)
            }
          }

          const pdfDocument = (
            <FormPdfDocument
              sections={sections}
              title={formTitlePdf}
              data={data}
              describeMap={describeMap}
              objectsFieldsMap={objectsFieldsMap}
              langFR={langFR}
              renderFunc={(item, section, index) => {
                return renderItem({
                  item: {
                    ...item,
                    value: values[item.id],
                    title: langFR ? item.titleFR : item.titleEN
                  },
                  lastInSection: section.elements.length === index + 1,
                  disabledIds,
                  pdfView: true,
                  baseErrors: {},
                  values,
                  objectsFieldsMap,
                  connectedMap,
                  elementsMap,
                  describeMap,
                  formikRef,
                  langFR,
                  index
                })
              }}
            />
          )
          if (returnPdf) {
            return returnPdf(pdfDocument, formTitlePdf, fetchData)
          }

          const noSaveButton = readOnly || pdfDisplay || forceDisabled
          const saveDisabled =
            disabled || isPreview || noConnectedObjects || !dirty

          const StepperButtonsElement = (
            <StepperButtons
              formTitle={formTitlePdf}
              printRef={displayPrintButton && printRef}
              pdfFileName={formTitlePdf}
              hideSaveButton={noSaveButton}
              pdf={Boolean(!disablePDF && pdfDisplay) && pdfDocument}
              noStepper={noStepper}
              saving={saving}
              disableSave={saveDisabled}
              elementsMap={elementsMap}
              muBag={{
                formId: formRealmId(organizationId, id),
                token: multiuserSessionToken,
                userId: user.userId
              }}
              handleSave={() => trySaving({ values })}
              handleNext={handleNext}
              handleBack={handleBack}
              steps={sections.filter(
                (section, index) =>
                  !disabledIds.includes(sectionConditionId + index)
              )}
              activeStep={getValidCurrentStepIndex({
                realIndex: currentStep,
                disabledIds
              })}
              useMultiuser={useMultiuser}
            />
          )
          const parsedErrors = errorsToRender({
            errors,
            objectsFieldsMap,
            describeMap,
            disabledIds,
            elementsMap,
            french: langFR
          })

          return (
            <ReactCursorPosition
              isEnabled={useMultiuser}
              activationInteractionMouse={INTERACTIONS.HOVER}
              ref={mouseDetectRef}
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%'
              }}
            >
              <Paper style={style}>
                {useMultiuser && (
                  <Chat formId={id} token={multiuserSessionToken} />
                )}
                {useMultiuser && unsavedDataDetected && (
                  <UnsavedDataDetectedDialog
                    data={unsavedDataDetected}
                    elementsMap={elementsMap}
                  />
                )}
                {useMultiuser && values.muInfo?.disconnected && (
                  <Dialog open fullWidth maxWidth='sm'>
                    <DialogContent>
                      <Typography variant='h6' style={{ marginBottom: 10 }}>
                        <Trans>Connection lost. Reconnecting...</Trans>
                      </Typography>
                      <div style={{ paddingBottom: 20 }}>
                        <Loading isNotFixed />
                      </div>
                    </DialogContent>
                  </Dialog>
                )}
                {wrongAccountRole === 'warning' && (
                  <Alert severity='warning' style={{ marginBottom: 10 }}>
                    <AlertTitle>
                      <Typography variant='h6'>
                        <Trans>You cannot edit this page</Trans>
                      </Typography>
                    </AlertTitle>
                    <div style={{ marginTop: 5 }}>
                      {/* <Trans>You cannot edit this page</Trans>
                      {'. '} */}
                      <Trans>You can request higher organization access</Trans>{' '}
                      <Link href='/grants/Organizations'>
                        <Trans>here</Trans>
                      </Link>
                    </div>
                  </Alert>
                )}
                {!noStepper && !inDialog && (
                  <Typography
                    variant='h6'
                    style={{
                      textAlign: 'center',
                      marginBottom: 20,
                      margintTop: 20
                    }}
                  >
                    {formTitle}
                  </Typography>
                )}
                {data.displaySaveFailedDialog && (
                  <SavingFailedWarningDialog
                    open={Boolean(saveFailedData)}
                    data={saveFailedData}
                    handleClose={() => {
                      setSaveFailedData(null)
                    }}
                    fileName={
                      myI18n._(t`Extracted data`) +
                      ' - ' +
                      moment.utc().format(dateFormat)
                    }
                  />
                )}
                {data.displayUnsavedWarning && !noSaveButton && !saveDisabled && (
                  <RedirectWarning
                    open={dirty}
                    handleSave={() => {
                      handleSave({ values })
                    }}
                  />
                )}
                <SaveWillOverrideWarningDialog
                  handleSave={() => {
                    handleSave({ values })
                  }}
                  handleClose={() => {
                    setOverrideWarningData(null)
                    setSaving(false)
                  }}
                  open={Boolean(overrideWarningData)}
                  data={overrideWarningData}
                />
                {!noStepper && (
                  <Stepper
                    nonLinear
                    activeStep={getValidCurrentStepIndex({
                      realIndex: currentStep,
                      disabledIds
                    })}
                    orientation='horizontal'
                    alternativeLabel
                  >
                    {sections.map((section, index) => {
                      if (disabledIds.includes(sectionConditionId + index)) {
                        return null
                      }
                      const hasErrors =
                        parsedErrors.filter(obj => index === obj.sectionIndex)
                          .length > 0
                      return (
                        <Step
                          itemType='step'
                          id='step'
                          key={index}
                          style={{ cursor: 'pointer' }}
                          onClick={e => {
                            scrollToTop()
                            setStep(index)
                            if (useMultiuser) {
                              muHandleChangeStep(index)
                            }
                          }}
                        >
                          <StepLabel id='label'>
                            <Grid
                              container
                              justifyContent='center'
                              alignItems='center'
                            >
                              {parseDisplayedText({
                                text: langFR
                                  ? section.titleFR
                                  : section.titleEN,
                                french: langFR,
                                objectsFieldsMap,
                                describeMap,
                                renderProps: {
                                  connectedMap
                                }
                              })}
                              {!readOnly && !disabled && (
                                <>
                                  {hasErrors ? (
                                    <Icon
                                      style={{
                                        color: errorColor,
                                        paddingLeft: 6,
                                        fontSize: 16
                                      }}
                                    >
                                      close
                                    </Icon>
                                  ) : (
                                    <Icon
                                      style={{
                                        color: successColor,
                                        paddingLeft: 6,
                                        fontSize: 16
                                      }}
                                    >
                                      done
                                    </Icon>
                                  )}
                                </>
                              )}
                            </Grid>

                            {useMultiuser && values.muUsers && (
                              <Grid container justifyContent='center'>
                                {Object.entries(values.muUsers)
                                  .filter(
                                    ([key, value]) =>
                                      value.step === index &&
                                      key !== user.userId
                                  )
                                  .map(([key, value]) => {
                                    return (
                                      <Grid item key={key}>
                                        <MUAvatar
                                          {...value}
                                          handleClick={e => {
                                            if (value.coordinates) {
                                              const { y, yPercent } =
                                                value.coordinates
                                              scrollToY(y)
                                            }
                                          }}
                                        />
                                      </Grid>
                                    )
                                  })}
                              </Grid>
                            )}
                          </StepLabel>
                        </Step>
                      )
                    })}
                  </Stepper>
                )}
                {!disableTitle && (
                  <FormTitle title={noStepper ? formTitle : sectionTitle}>
                    {StepperButtonsElement}
                  </FormTitle>
                )}
                {displayPrintButton && (
                  <div
                    ref={printRef}
                    style={{ width: '100%' }}
                    className='show-in-print'
                  >
                    {sections.map((section, index) => {
                      if (disabledIds.includes(sectionConditionId + index)) {
                        return null
                      }
                      const sectionTitle = parseDisplayedText({
                        text: langFR ? section.titleFR : section.titleEN,
                        french: langFR,
                        objectsFieldsMap,
                        describeMap,
                        renderProps: {
                          connectedMap
                        }
                      })
                      return (
                        <>
                          <div
                            style={{
                              pageBreakBefore: 'always'
                            }}
                          >
                            <FormTitle title={sectionTitle} />
                          </div>
                          {section.elements.map((item, index) => {
                            return renderItem({
                              item: {
                                ...item,
                                value: values[item.id],
                                title: langFR ? item.titleFR : item.titleEN
                              },
                              navigateToError,
                              lastInSection:
                                section.elements.length === index + 1,
                              objectsFieldsMap,
                              values,
                              setFieldValue,
                              printView: true,
                              renderPrint: true,
                              disabled: Boolean(disabled || saving || readOnly),
                              saveDisabled: Boolean(disabled || isPreview),
                              configuration,
                              addMethodToValidationSchema,
                              connectedMap,
                              elementsMap,
                              describeMap,
                              saving,
                              handleSubmit,
                              saveButtonClicked: trySaving,
                              disabledIds,
                              classes,
                              reloadLastModifiedDates,
                              sectionIndex: currentStep,
                              preview: isPreview,
                              formikRef,
                              baseErrors: errors,
                              errors: parsedErrors,
                              muBag: {
                                formId: formRealmId(organizationId, id),
                                token: multiuserSessionToken,
                                userId: user.userId
                              },
                              useMultiuser,
                              network,
                              langFR,
                              index
                            })
                          })}
                        </>
                      )
                    })}
                  </div>
                )}

                {pdfDisplay ? (
                  <PDFViewer style={{ flexGrow: 1 }}>{pdfDocument}</PDFViewer>
                ) : (
                  sections[currentStep].elements.map((item, index) => {
                    return (
                      <div key={index}>
                        {renderItem({
                          item: {
                            ...item,
                            value: values[item.id],
                            title: langFR ? item.titleFR : item.titleEN
                          },
                          navigateToError,
                          lastInSection:
                            sections[currentStep].elements.length === index + 1,
                          objectsFieldsMap,
                          values,
                          setFieldValue,
                          renderPrint: readOnly,
                          printRef,
                          disabled: Boolean(disabled || saving || readOnly),
                          saveDisabled: Boolean(disabled || isPreview),
                          configuration,
                          addMethodToValidationSchema,
                          connectedMap,
                          elementsMap,
                          describeMap,
                          saving,
                          handleSubmit,
                          saveButtonClicked: trySaving,
                          disabledIds,
                          classes,
                          reloadLastModifiedDates,
                          sectionIndex: currentStep,
                          preview: isPreview,
                          baseErrors: errors,
                          errors: parsedErrors,
                          formikRef,
                          muBag: {
                            formId: formRealmId(organizationId, id),
                            token: multiuserSessionToken,
                            userId: user.userId
                          },
                          useMultiuser,
                          network,
                          langFR,
                          index
                        })}
                      </div>
                    )
                  })
                )}

                {Boolean(!noStepper) && StepperButtonsElement}
              </Paper>
            </ReactCursorPosition>
          )
        }}
      </Formik>
    )

    return inDialog
      ? returnInDialog({
          component: toReturn
        })
      : toReturn
  }
)

const extractFieldDetails = ({
  connectedField,
  connectedMap,
  describeMap,
  connectedObject
}) => {
  const { subObject, name } = connectedField
  if (!subObject) {
    if (!connectedMap[connectedObject]) {
      return {}
    }
    return connectedMap[connectedObject].fieldsMap[name]
  } else {
    const subFieldName = name.split('.')[1]
    const subObjectName = name.split('.')[0]
    if (!subFieldName || !subObjectName) {
      console.error('Could not find field details for: ', name)
      return null
    }
    const fields = describeMap[subObjectName]?.fields || []
    let details
    fields.some(field => {
      if (field.name === subFieldName) {
        details = field
      }
      return field.name === subFieldName
    })
    return details
  }
}

const renderItem = ({
  item,
  langFR,
  index,
  columns = 1,
  renderPrint,
  skipCard,
  pdfView,
  ...props
}) => {
  const {
    elements,
    padding = {},
    headerFontSize,
    style,
    titleFR,
    titleEN,
    headerStyle,
    bold,
    italics,
    pdfBorderDisplay
  } = item
  const { connectedMap } = props
  const paddingStyles = {}
  const paddingKeys = [
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom'
  ]
  paddingKeys.forEach(key => {
    let toSet = 0
    if (padding[key]) {
      toSet = Number(padding[key])
    }
    paddingStyles[key] = toSet
  })

  if (elements) {
    if (props.disabledIds.includes(item.id)) {
      return null
    }
    const title = langFR ? titleFR : titleEN
    let styleToPass = {
      width: '100%',
      ...paddingStyles
    }
    let headerStyleToPass = {}
    try {
      if (style) {
        const parsed = JSON.parse(style)
        if (typeof parsed === 'object') {
          styleToPass = Object.assign(styleToPass, JSON.parse(style))
        }
      }
    } catch (e) {}
    try {
      const parsed = JSON.parse(headerStyle)
      if (typeof parsed === 'object') {
        headerStyleToPass = Object.assign(headerStyleToPass, parsed)
      }
    } catch (e) {}
    if (headerFontSize) {
      headerStyleToPass.fontSize = +headerFontSize
    }
    if (bold) {
      headerStyleToPass.fontWeight = 'bold'
    }
    if (italics) {
      headerStyleToPass.fontStyle = 'italic'
    }

    if (pdfView) {
      styleToPass.width = props.printWidth || '100%'
      delete props.printWidth
      if (pdfBorderDisplay) {
        styleToPass = {
          ...styleToPass,
          padding: 8,
          border: '1px solid rgba(0, 0, 0, 0.02)',
          borderRadius: '5px'
        }
      }
      return (
        <View key={index} style={styleToPass} wrap={false}>
          {title ? (
            <Text
              style={{
                fontWeight: 'bold',
                paddingTop: 12,
                paddingBottom: 6,
                fontSize: pdfDefaultFontSize,
                ...headerStyleToPass
              }}
            >
              {parseDisplayedText({
                text: title,
                french: langFR,
                pdf: true,
                describeMap: props.describeMap,

                objectsFieldsMap: props.objectsFieldsMap,
                renderProps: {
                  connectedMap
                }
              })}
            </Text>
          ) : (
            <Text />
          )}

          <View
            style={{
              fontSize: pdfDefaultFontSize,
              width: '100%',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            {item.elements.map((element, eIndex) => {
              const columns = item.columns ? +item.columns : 1
              return renderItem({
                item: {
                  ...element,
                  value: !formElementsWithoutInput.includes(element.elementType)
                    ? props.values[element.id]
                    : null
                },
                langFR,
                eIndex,
                printWidth: String(100 / columns) + '%',
                pdfView: true,
                ...props
              })
            })}
          </View>
        </View>
      )
    }

    const toRender = (
      <Grid key={index} style={styleToPass} item xs={12 / columns}>
        {title && (
          <div
            style={{
              // fontWeight: 'bold',
              marginBottom: formItemPadding,
              marginLeft: formItemPadding,
              fontSize: 28,
              ...headerStyleToPass
            }}
          >
            {parseDisplayedText({
              text: title,
              french: langFR,
              objectsFieldsMap: props.objectsFieldsMap,

              describeMap: props.describeMap,
              renderProps: {
                connectedMap
              }
            })}
          </div>
        )}
        <Grid container direction='row' className='break-in-print'>
          {item.elements.map((element, eIndex) => {
            const labelsWidth = element.labelsWidth || item.labelsWidth
            const itemsSpacing = element.itemsSpacing || item.itemsSpacing
            return renderItem({
              item: {
                ...element,
                value: !formElementsWithoutInput.includes(element.elementType)
                  ? props.values[element.id]
                  : null,
                itemsSpacing: itemsSpacing,
                labelsWidth
              },
              langFR,
              eIndex,
              columns: item.columns,
              skipCard: true,
              renderPrint,
              pdfView,
              ...props
            })
          })}
        </Grid>
      </Grid>
    )

    if (renderPrint && !skipCard) {
      const containerStyle = {
        padding: printViewSpacing
      }
      if (props.lastInSection && props.printView) {
        containerStyle.paddingBottom = 0
      }
      return (
        <div style={containerStyle}>
          <Paper
            elevation={3}
            style={{
              width: '100%',
              display: 'flex'
            }}
          >
            {toRender}
          </Paper>
        </div>
      )
    } else {
      return toRender
    }
  } else {
    return (
      <RenderElement
        item={item}
        langFR={langFR}
        index={index}
        pdfView={pdfView}
        skipCard={skipCard}
        renderPrint={renderPrint}
        xs={12 / columns}
        {...props}
      />
    )
  }
}

const RenderElement = ({
  xs = 12,
  item,
  langFR,
  connectedMap,
  describeMap,
  muBag,
  index,
  network,
  pdfView,
  disabledIds,
  classes,
  skipCard,
  printView,
  renderPrint,
  lastInSection,
  ...props
}) => {
  const type = item.elementType
  const elementData = formElementTypes[type]
  const {
    labelsWidth,
    padding = {},
    itemsSpacing,
    titleFR,
    titleEN,
    conditions,
    labelAsMarkdown,
    typeProps,
    elementType,
    helpTextEN,
    helpTextFR
  } = item

  const { printAvoidBreak, options } = typeProps
  const paddingStyles = {}
  const paddingKeys = [
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom'
  ]
  paddingKeys.forEach(key => {
    let toSet = formItemPadding
    if (pdfView) {
      if (key === 'paddingTop' || key === 'paddingBottom') {
        toSet = 6
      } else {
        toSet = 0
      }
    }
    if (padding[key]) {
      toSet = Number(padding[key])
    }
    if (itemsSpacing || itemsSpacing === 0) {
      toSet = Number(itemsSpacing)
    }
    paddingStyles[key] = toSet
  })

  if (type && item.id && elementData) {
    const renderComponent = pdfView
      ? elementData.formComponentPdf
      : elementData.formComponent
    if (!renderComponent || disabledIds.includes(item.id)) {
      return null
    }
    const tooltip = langFR ? item.tooltipFR : item.tooltipEN
    let helpText = langFR ? helpTextFR : helpTextEN
    let sfObject, connectedFieldDetails
    const { isConnected, showFieldLabel } = typeProps
    const { connectedField, connectedObject } = getMainConnected(item)
    if (connectedObject && connectedMap[connectedObject]) {
      sfObject = connectedMap[connectedObject].sfObject
    }
    if (isConnected && connectedField) {
      connectedFieldDetails = extractFieldDetails({
        connectedField,
        connectedMap,
        describeMap,
        connectedObject
      })
    }
    if (options) {
      options.forEach(option => {
        if (option.connectedField) {
          option.connectedFieldDetails = extractFieldDetails({
            connectedField: option.connectedField,
            connectedMap,
            describeMap,
            connectedObject
          })
        }
      })
    }
    const baseLabel = langFR ? titleFR : titleEN
    let label = baseLabel && String(baseLabel).replace(/\n/g, '')
    if (conditions && conditions.length > 0) {
      const altLabel = checkAltLabel({
        item,
        describeMap,
        connectedMap,
        values: props.values,
        errors: props.baseErrors,
        langFR,
        elementsMap: props.elementsMap
      })
      if (typeof altLabel === 'string') {
        label = altLabel
      }
      const altHelpText = checkAltLabel({
        item,
        connectedMap,
        describeMap,
        values: props.values,
        errors: props.baseErrors,
        langFR,
        elementsMap: props.elementsMap,
        conditionType: 'altHelpText'
      })
      if (typeof altHelpText === 'string') {
        helpText = altHelpText
      }
    }
    label = parseDisplayedText({
      text: label,
      french: langFR,
      objectsFieldsMap: props.objectsFieldsMap,
      describeMap,
      connectedMap,
      pdf: pdfView,
      returnString: !pdfView,
      renderProps: {
        connectedMap
      }
    })
    if (labelAsMarkdown) {
      label = unified()
        .use(remarkParse)
        .use(remarkRehype, { allowDangerousHtml: true })
        .use(rehypeStringify, { allowDangerousHtml: true })
        .processSync(label)
        .toString()
    }
    if (helpText) {
      helpText = unified()
        .use(remarkParse)
        .use(remarkRehype, { allowDangerousHtml: true })
        .use(rehypeStringify, { allowDangerousHtml: true })
        .processSync(helpText)
        .toString()
    }
    const alt = item.altLabelPlacement
    if (renderPrint) {
      if (elementData.printComponent) {
        let printRender = elementData.printComponent({
          ...item,
          describeMap,
          title: label,
          connectedMap,
          editMode: false,
          connectedObject: sfObject,
          connectedFieldDetails,
          muBag,
          langFR,
          tooltip,
          ...props
        })
        if (formElementsWithoutCardInPrintView.includes(elementType)) {
          if (!skipCard) {
            printRender = (
              <div style={{ padding: printViewSpacing }}> {printRender}</div>
            )
          }
        } else {
          if (!skipCard) {
            const containerStyle = {
              padding: printViewSpacing
            }
            if (lastInSection && printView) {
              containerStyle.paddingBottom = 0
            }
            printRender = (
              <div style={containerStyle}>
                <Paper
                  elevation={3}
                  style={{
                    ...paddingStyles,
                    width: '100%',
                    display: 'flex'
                  }}
                >
                  {printRender}
                </Paper>
              </div>
            )
          } else {
            printRender = (
              <div style={{ ...paddingStyles, width: '100%', display: 'flex' }}>
                {printRender}
              </div>
            )
          }
        }
        const printBreakBehaviourClass = printAvoidBreak
          ? 'avoid-print-break'
          : 'allow-print-break'
        return (
          <Grid
            key={index}
            id={item.id}
            item
            container
            xs={xs}
            style={{
              width: '100%',
              display: 'flex'
            }}
            direction={alt ? 'row' : 'column'}
            wrap='nowrap'
            className={
              typeProps.printPageBreakBefore
                ? 'page-break-before'
                : printBreakBehaviourClass
            }
          >
            {printRender}
          </Grid>
        )
      } else {
        return null
      }
    }
    let baseElement = renderComponent({
      ...item,
      describeMap,
      connectedMap,
      editMode: false,
      connectedObject: sfObject,
      connectedFieldDetails,
      network,
      muBag,
      langFR,
      tooltip,
      title: label,
      helpText,
      ...props
    })
    if (pdfView) {
      return (
        <View style={{ width: props.printWidth || '100%', ...paddingStyles }}>
          {baseElement}
        </View>
      )
    }

    return (
      <Grid
        key={index}
        id={item.id}
        item
        container
        xs={xs}
        style={{
          width: '100%',
          display: 'flex',
          ...paddingStyles
        }}
        direction={alt ? 'row' : 'column'}
        className='break-in-print'
        wrap='nowrap'
      >
        <FormElementTitle
          tooltip={tooltip}
          labelsWidth={labelsWidth}
          id={item.id}
          altLabelPlacement={alt}
          helpText={!elementData.customHelptext && helpText}
          labelAsMarkdown={labelAsMarkdown}
          title={label}
          type={type}
          showFieldLabel={showFieldLabel}
          useMultiuser={props.useMultiuser}
          muBag={muBag}
          disabled={props.disabled}
          connectedObject={sfObject}
          item={item}
        />
        <Grid item xs>
          {baseElement}
        </Grid>
      </Grid>
    )
  }
}

const StepperButtons = ({
  activeStep,
  noStepper,
  handleBack,
  handleSave,
  handleNext,
  printRef,
  pdf,
  pdfFileName,
  saving,
  disableSave,
  muBag,
  hideSaveButton,
  steps,
  elementsMap,
  useMultiuser
}) => (
  <Grid
    container
    style={{ marginLeft: 'auto' }}
    wrap='nowrap'
    justifyContent='flex-end'
  >
    {!noStepper && (
      <Button
        variant='contained'
        color='secondary'
        disabled={activeStep === 0}
        onClick={handleBack}
      >
        <Trans>Previous</Trans>
      </Button>
    )}
    {!hideSaveButton && (
      <Button
        className='ml-4'
        variant='outlined'
        color='primary'
        disabled={saving || disableSave}
        onClick={handleSave}
      >
        <Trans>Save</Trans>
        <Save style={{ marginLeft: 5 }} />
      </Button>
    )}
    {printRef && !pdf && (
      <ReactToPrint
        trigger={() => (
          <Button
            className='ml-4'
            variant='outlined'
            color='primary'
            onClick={handleSave}
          >
            {' '}
            <Trans>Print</Trans>
            <Icon style={{ marginLeft: 5 }}>print</Icon>
          </Button>
        )}
        // onAfterPrint={() => (document.title = defaultDocTitle)}
        // onBeforePrint={() => (document.title = formTitle)}
        content={() => printRef.current}
      />
    )}
    {/* {pdf && (
      <PDFDownloadLink fileName={pdfFileName} document={pdf}>
        {({ blob, url, loading, error }) => {
          return (
            <Button
              disabled={loading}
              className='ml-4'
              variant='outlined'
              color='primary'
            >
              <Icon>download</Icon>
            </Button>
          )
        }}
      </PDFDownloadLink>
    )} */}

    {useMultiuser && (
      <BackupsPanel elementsMap={elementsMap} saving={saving} muBag={muBag} />
    )}

    {!noStepper && (
      <Button
        className='ml-4'
        variant='contained'
        color='primary'
        disabled={activeStep === steps.length - 1}
        onClick={handleNext}
      >
        <Trans>Next</Trans>
      </Button>
    )}
  </Grid>
)

const BackupsPanel = ({ saving, muBag, elementsMap = {} }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [backups, setBackups] = useState(null)
  const [versions, setVersions] = useState(null)
  const [restoring, setRestoring] = useState(false)
  const { values, setValues } = useFormikContext()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (dialogOpen) {
      grpcGetFormBackups({
        ...muBag,
        onSuccess: backups => {
          setBackups(
            backups.sort((a, b) =>
              moment(b.date).diff(moment(a.date), 'minutes')
            )
          )
        }
      })
    }
  }, [dialogOpen])

  return (
    <>
      <Dialog open={Boolean(versions)} fullWidth maxWidth='md'>
        <DialogTitle>
          <Grid container direction='row'>
            <Grid
              item
              xs
              style={{
                textAlign: 'center',
                fontWeight: 400,
                fontSize: 17,
                paddingBottom: 15
              }}
            >
              <Trans>Current version</Trans>

              <Grid></Grid>
            </Grid>
            <Grid
              item
              xs
              style={{
                textAlign: 'center',
                fontWeight: 400,
                fontSize: 17,
                paddingBottom: 15
              }}
            >
              <Trans>Backup</Trans>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {versions && (
            <VersionsDifferences {...versions} elementsMap={elementsMap} />
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent='space-evenly'
            style={{ paddingTop: 15, paddingBottom: 15 }}
          >
            <Button
              color='primary'
              variant='contained'
              disabled={restoring}
              onClick={e => {
                setRestoring(true)
                grpGetLockedFieldsForForm({
                  ...muBag,
                  onSuccess: locks => {
                    if (locks.length === 0) {
                      const newValues = { ...values, ...versions.cache }
                      setRestoring(false)
                      setDialogOpen(false)
                      setVersions(null)
                      enqueueSnackbar(<Trans>Backup restored!</Trans>, {
                        variant: 'success'
                      })
                      setValues(newValues)
                      commitChangeToMultipleFields({
                        ...muBag,
                        array: Object.entries(newValues).filter(
                          ([key, values]) =>
                            !['muUsers', 'muInfo'].includes(key)
                        )
                      })
                    } else {
                      setRestoring(false)
                      enqueueSnackbar(
                        <Trans>
                          You cannot restore the backup, some fields are
                          currently edited by other users!
                        </Trans>,
                        {
                          variant: 'error'
                        }
                      )
                    }
                  }
                })
              }}
            >
              <Trans>Restore backup</Trans>
            </Button>
            <Button
              color='primary'
              variant='contained'
              disabled={restoring}
              onClick={e => {
                setVersions(null)
                setRestoring(false)
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen} fullWidth maxWidth='sm'>
        <DialogTitle>
          <Grid container justifyContent='space-between'>
            <Trans>Form backups</Trans>
            <IconButton
              disabled={restoring || !backups}
              onClick={e => {
                setDialogOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
          <div style={{ fontSize: 13, fontWeight: 400 }}>
            <Trans>
              Be careful! Restoring the backup will overrite your current
              changes
            </Trans>
          </div>
        </DialogTitle>
        <DialogContent>
          {Array.isArray(backups) ? (
            backups.length === 0 ? (
              <Alert severity='info'>
                <AlertTitle>
                  <Trans>There are no backups yet for this form!</Trans>
                </AlertTitle>
              </Alert>
            ) : (
              backups.map((backup, index) => (
                <Grid
                  container
                  key={index}
                  alignItems='center'
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs>
                    <div style={{ fontWeight: 400, fontSize: 11 }}>
                      <Trans>Date</Trans>
                    </div>
                    <div>
                      {moment.utc(backup.date).local().format(datetimeFormat)}
                    </div>
                  </Grid>

                  <Grid item xs>
                    <div style={{ fontWeight: 400, fontSize: 11 }}>
                      <Trans>Backup Type</Trans>
                    </div>
                    <div>{formCacheTypeToLabel[backup.type]}</div>
                  </Grid>

                  <Grid item xs>
                    <div style={{ fontWeight: 400, fontSize: 11 }}>
                      <Trans>Editing users</Trans>
                    </div>
                    <div>{backup.users?.join(', ')}</div>
                  </Grid>

                  <Grid item>
                    <IconButton
                      disabled={restoring || saving}
                      onClick={e => {
                        setRestoring(true)
                        grpcGetFormCache({
                          ...muBag,
                          id: backup.id,
                          onSuccess: cache => {
                            setRestoring(false)
                            setVersions({
                              cache,
                              current: values
                            })
                          }
                        })
                      }}
                    >
                      <Icon>settings_backup_restore</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              ))
            )
          ) : (
            <Loading isNotFixed style={{ marginBottom: 15 }} />
          )}
        </DialogContent>
      </Dialog>

      <Button
        style={{ marginLeft: 4 }}
        //disabled={!backups}
        variant='contained'
        color='primary'
        onClick={e => {
          setDialogOpen(true)
        }}
      >
        <Trans>Form backups</Trans>
        <Icon style={{ marginLeft: 5 }}>backup</Icon>
      </Button>
    </>
  )
}

export const FormPage = ({
  label,
  langFR,
  errors,
  handleSubmit,
  connectedMap = {},
  values,
  elements,
  sections,
  formId
}) => {
  const elementsMap = mapFormElements({ sections: sections }, langFR)
  const disabledIds = getDisabledIds({ sections, elementsMap, values })

  return (
    <Paper style={{ padding: 20 }}>
      <Typography variant='h3' style={{ textAlign: 'center' }}>
        {label}
      </Typography>
      <div>
        {elements.map((item, index) => {
          if (disabledIds.includes(item.id)) {
            return null
          }
          return renderItem({
            item: {
              ...item,
              value: values[item.id],
              title: langFR ? item.titleFR : item.titleEN
            },
            lastInSection: elements.length === index + 1,
            disabledIds,
            connectedMap,
            saving: false,
            handleSubmit,
            sectionIndex: 0,
            errors: errorsToRender({
              errors,
              objectsFieldsMap: {},
              disabledIds,
              elementsMap,
              french: langFR
            }),
            formId,
            values,
            network: {},
            langFR,
            index
          })
        })}
      </div>
    </Paper>
  )
}

export default FormWrapped
