import { combineReducers } from 'redux'
import LoginReducer from './LoginReducer'
import UserReducer from './UserReducer'
import LayoutReducer from './LayoutReducer'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import ConfigurationReducer from './ConfigurationReducer'
import ActionPlanReducer from './ActionPlanReducer'
import OrganizationReducer from './OrganizationReducer'
import formEditorReducer from './FormEditorReducer'
import AvaliableOrganizationsReducer from './AvaliableOrganizationsReducer'
import CaseDataReducer from './CaseDataRecuder'
import i18nReducer from './i18nReducer'
import tutorialsReducer from './TutorialsReducer'
import multiuserReducer from './MultiuserReducer'

const RootReducer = combineReducers({
  organization: OrganizationReducer,
  caseTypes: CaseDataReducer,
  login: LoginReducer,
  actionPlan: ActionPlanReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  tutorials: tutorialsReducer,
  ecommerce: EcommerceReducer,
  configuration: ConfigurationReducer,
  avaliableOrganizations: AvaliableOrganizationsReducer,
  i18n: i18nReducer,
  formEditorTree: formEditorReducer,
  multiuser: multiuserReducer
})

export default RootReducer
