import { surveyConfigurationVariables } from '../services/sfAuth/sfData/sfSurvey'
import { applicationConfigurationVariables } from '../views/internal/ConfigureApplicationTypes'

export const CONFIGURATION_JSON = 'CONFIGURATION_JSON'
export const CONFIGURATION_NUMBER = 'CONFIGURATION_NUMBER'
export const CONFIGURATION_STRING = 'CONFIGURATION_STRING'
export const CONFIGURATION_SALESFORCE_ID = 'CONFIGURATION_SALESFORCE_ID'
export const CONFIGURATION_APPLICATION_TYPES = 'CONFIGURATION_APPLICATION_TYPES'

export const configurationMetadata = {
  ...surveyConfigurationVariables,
  ...applicationConfigurationVariables,
}