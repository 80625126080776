import { NO_USER } from '../SFAuthService'
import SFAuthService from '..//SFAuthService'

export const createJoinRequest = ({
  accountId,
  userId,
  email,
  role,
  isModifyRequest
}) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn
      .requestPost('/actions/custom/flow/App_Create_Join_Request', {
        inputs: [
          {
            toCreate: {
              AccountId__c: accountId,
              UserId__c: userId,
              Email__c: email,
              Team_role__c: role,
              Is_Modify_Request__c: isModifyRequest
            }
          }
        ]
      })
      .then(result => {
        return result[0].outputValues
      })
  } else {
    return Promise.reject(NO_USER)
  }
}

export const denyRequest = id => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost('/actions/custom/flow/App_Delete_Join_Request', {
      inputs: [
        {
          toDelete: {
            Id: id
          }
        }
      ]
    })
  } else {
    return Promise.reject(NO_USER)
  }
}

export const denyRequests = ids => {
  const conn = SFAuthService.getConnection()

  if (conn) {
    return conn.requestPost('/actions/custom/flow/App_Delete_Join_Request', {
      inputs: ids.map(id => ({
        toDelete: {
          Id: id
        }
      }))
    })
  } else {
    return Promise.reject(NO_USER)
  }
}

export const getJoinRequests = (idsList, onyJoin = false) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn
      .sobject('Join_Request__c')
      .find({
        AccountId__c: { $in: idsList }
      })
      .then(result => {
        if (onyJoin) {
          result = result.filter(v => v.UserId__c)
        }
        return result.filter(v => Boolean(v))
      })
  } else {
    return Promise.reject(NO_USER)
  }
}

export const getJoinRequestCount = () => {
  const conn = SFAuthService.getConnection()
  return conn
    .requestPost('/actions/custom/flow/Portal_Get_Join_Request_Count', {
      inputs: [{}]
    })
    .then(result => {
      console.log('getJoinRequestCount result', result)
      return result[0].outputValues
    })
}
