import { t } from '@lingui/macro'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'

export const numberFormat = v => {
  return <NumberFormat displayType='text' thousandSeparator=' ' value={v} />
}

export const parseExtensionPhoneFromSF = value => {
  let countryCode, phoneNumber
  if (value && value.indexOf('+(') !== -1) {
    countryCode = value.substring(2, value.indexOf(')'))
    phoneNumber = value.substring(value.indexOf(')'))
    phoneNumber = phoneNumber.replace(/[^0-9]/g, '')
  } else {
    phoneNumber = value
  }
  return { countryCode, phoneNumber }
}

export const parseExtensionPhoneToSF = (value, countryCode) => {
  if (!value) {
    return ''
  }
  const phone = String(value).substring(0, 10)
  const extenstion = String(value).substring(10)
  const str1 = phone.substring(0, 3)
  const str2 = phone.substring(3, 6)
  const str3 = phone.substring(6, 10)
  let toRet = str1
  if (str2) {
    toRet += '-' + str2
  }
  if (str3) {
    toRet += '-' + str3
  }
  if (extenstion) {
    toRet += ' x' + extenstion
  }
  if (countryCode) {
    toRet = '+(' + countryCode + ') ' + toRet
  } else {
    toRet = '+(1) ' + toRet
  }
  return toRet
}

export function PhoneFormatWithExtension (props) {
  const { inputRef, onChange, ...other } = props
  const inputValue = other.value || ''
  let format = `###-###-####, ${myI18n._(t`Ext.`)}`
  // if (inputValue.length === 10) {
  //   format = '###-###-#####'
  // } else
  if (inputValue.length >= 10) {
    format = `###-###-####, ${myI18n._(t`Ext.`)} ####`
  }

  return (
    <NumberFormat
      {...other}
      format={format}
      mask={inputValue.length >= 10 ? ' ' : '_'}
      isNumericString
      type='tel'
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}

export function PhoneCountryCodeFormat (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      placeholder='+(1)'
      prefix='+('
      suffix=')'
      mask=' '
      isNumericString
      type='tel'
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}

export function PhoneFormat (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      format='###-###-####'
      mask=' '
      isNumericString
      type='tel'
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}

export function DefaultNumericFormat (props) {
  const { inputRef, onChange, id, ...other } = props
  const language = useSelector(state => state.user.language)

  return (
    <NumberFormat
      {...other}
      value={typeof props.value === 'object' ? '' : props.value}
      defaultValue=''
      isNumericString
      getInputRef={inputRef}
      allowNegative={false}
      thousandSeparator={language === 'en_US' ? ',' : ' '}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}

export function CurrencyFormat (props) {
  const { inputRef, onChange, ...other } = props
  const language = useSelector(state => state.user.language)
  return language === 'en_US' ? (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator=','
      prefix='$'
      allowNegative={false}
      isNumericString={false}
    />
  ) : (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator=' '
      suffix=' $'
      allowNegative={false}
      isNumericString={false}
    />
  )
}

export const bilingualDateFormat = (value, french) => {
  const date = moment.utc(value)
  let dateString = date.format('MMMM D, YYYY')
  if (french) {
    const month =
      date.format('MMMM')[0].toLowerCase() + date.format('MMMM').slice(1)
    dateString = date.format('D') + ' ' + month + ' ' + date.format('YYYY')
  }
  return dateString
}

export const bilingualNumberFormat = (value, french) => {
  if (isNaN(value) || Boolean(!value && value !== 0)) {
    return ''
  }
  return String(
    !french
      ? new Intl.NumberFormat('en-CA').format(value)
      : new Intl.NumberFormat('fr-CA').format(value)
  )
}

export const currencyFormatedString = (value, french) => {
  if (isNaN(value) || Boolean(!value && value !== 0)) {
    return ''
  }
  return String(
    !french
      ? new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value)
      : new Intl.NumberFormat('fr-CA', {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
        .format(value)
        .split('CA')[0]
  )
}

export const CurrencyFormated = ({ value }) => {
  const language = useSelector(state => state.user.language)
  if (isNaN(value) || Boolean(!value && value !== 0)) {
    return ''
  }
  return String(
    language === 'en_US'
      ? new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value)
      : new Intl.NumberFormat('fr-CA', {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
        .format(value)
        .split('CA')[0]
  )
}

export const percentFormattedString = (value, french) => {
  if (isNaN(value) || Boolean(!value && value !== 0)) {
    return ''
  }
  return new Intl.NumberFormat(!french ? 'en-CA' : 'fr-CA', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value / 100)
}
