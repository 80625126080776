import React from 'react'
import {
  Image,
  Page,
  PDFViewer,
  Text,
  View,
  Document,
  PDFDownloadLink,
  Font
} from '@react-pdf/renderer'
import { parseDisplayedText, pdfDefaultFontSize } from './components/Common'
import { useFormikContext } from 'formik'
import { Html } from 'react-pdf-html'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import reactImageSize from 'react-image-size'
import { useState } from 'react'

const baseLogoSize = 100

const getFlexPlacmentForLogo = (
  textPlacement = 'left',
  logoPlacement = 'right'
) => {
  if (textPlacement === 'left' && logoPlacement === 'left') {
    return { flexPlacement: 'flex-start', order: ['text', 'logo'] }
  } else if (textPlacement === 'right' && logoPlacement === 'right') {
    return { flexPlacement: 'flex-end', order: ['text', 'logo'] }
  } else if (textPlacement === 'center' && logoPlacement === 'center') {
    return { flexPlacement: 'center', order: ['text', 'logo'] }
  } else if (textPlacement === 'left' && logoPlacement === 'center') {
    return { flexPlacement: 'space-between', order: ['text', 'logo', 'empty'] }
  } else if (textPlacement === 'center' && logoPlacement === 'left') {
    return { flexPlacement: 'space-between', order: ['logo', 'text', 'empty'] }
  } else if (textPlacement === 'right' && logoPlacement === 'center') {
    return { flexPlacement: 'space-between', order: ['empty', 'logo', 'text'] }
  } else if (textPlacement === 'center' && logoPlacement === 'right') {
    return { flexPlacement: 'space-between', order: ['empty', 'text', 'logo'] }
  } else if (textPlacement === 'left' && logoPlacement === 'right') {
    return { flexPlacement: 'space-between', order: ['text', 'logo'] }
  } else if (textPlacement === 'center' && logoPlacement === 'right') {
    return { flexPlacement: 'space-between', order: ['logo', 'text'] }
  } else if (textPlacement === 'right' && logoPlacement === 'left') {
    return { flexPlacement: 'space-between', order: ['logo', 'text'] }
  } else {
    return { flexPlacement: 'flex-start', order: ['text', 'logo'] }
  }
}

export default function FormPdfDocument ({
  title,
  sections,
  data,
  renderFunc,
  describeMap,
  objectsFieldsMap,
  langFR
}) {
  const { pdfProps } = data
  return (
    <Document style={{ fontFamily: 'Roboto' }} title={title}>
      {sections.map((section, sIndex) => {
        let header, footer, footerHeight, headerHeight
        let pageMargin = 2.54
        if (pdfProps) {
          const keys = ['footer', 'header']
          const { pagePadding } = pdfProps
          if (pagePadding) {
            pageMargin = +pagePadding
          }
          keys.forEach(key => {
            const { component, logoHeight } = FormPdfFixedSection({
              ...data.pdfProps[key],
              langFR,
              type: key,
              pageMargin,
              parseText: text =>
                parseDisplayedText({
                  text,
                  french: langFR,
                  describeMap,
                  objectsFieldsMap,
                  returnString: true
                })
            })

            if (key === 'footer') {
              footer = component
              footerHeight = logoHeight
            } else {
              header = component
              headerHeight = logoHeight
            }
          })
        }
        const basePageStyle = {
          padding: pageMargin + 'cm',
          fontFamily: 'Roboto'
        }
        if (footer && pageMargin < 1) {
          basePageStyle.paddingBottom = '1cm'
        }
        if (header && pageMargin < 1) {
          basePageStyle.paddingTop = '1cm'
        }

        if (headerHeight) {
          basePageStyle.paddingTop = headerHeight + 45
        }
        if (footerHeight) {
          basePageStyle.paddingBottom = footerHeight + 45
        }

        return (
          <Page style={basePageStyle} key={sIndex}>
            {section.elements.map((item, index) => {
              if (!section.elements || section.elements.length === 0) {
                return null
              }
              return renderFunc(item, section, index)
            })}
            {header}
            {footer}
          </Page>
        )
      })}
    </Document>
  )
}

function FormPdfFixedSection ({
  textFR,
  textEN,
  placement,
  fontSize,
  isPageNumber,
  textProps = [],
  logoUrl,
  logoPlacement,
  langFR,
  parseText,
  pageMargin,
  logoSize = baseLogoSize,
  type
}) {
  const [calcSize, setCalcSize] = useState(null)

  useEffect(() => {
    if (logoUrl) {
      reactImageSize(logoUrl).then(({ width, height }) => {
        if (width >= height) {
          const wRel = width / logoSize
          setCalcSize({
            width: width / wRel,
            height: height / wRel
          })
        } else if (height > width) {
          const hRel = height / logoSize
          setCalcSize({
            width: width / hRel,
            height: height / hRel
          })
        }
      })
    }
  }, [logoUrl])

  const label = isPageNumber ? '5' : parseText(langFR ? textFR : textEN)
  const style = {
    fontFamily: 'Roboto',
    left: pageMargin + 'cm',
    width: '100%',
    position: 'absolute'
  }
  if (label || logoUrl) {
    if (type === 'header') {
      style.top = '1.27cm'
    } else {
      style.bottom = '1.27cm'
    }

    const textStyle = {
      fontFamily: 'Roboto',
      fontWeight: textProps.includes('bold') ? 700 : 400,
      fontStyle: textProps.includes('italics') ? 'italic' : 'normal',
      textDecoration: textProps.includes('underline') && 'underline',
      fontSize: +fontSize || pdfDefaultFontSize
    }

    const text = isPageNumber ? (
      <View
        render={({ pageNumber, totalPages }) => (
          <Text style={textStyle}>{pageNumber + '/' + totalPages}</Text>
        )}
      />
    ) : textProps.includes('html') ? (
      <Html
        style={{
          fontSize: +fontSize || pdfDefaultFontSize
        }}
      >
        {label}
      </Html>
    ) : (
      <Text style={textStyle}>{label}</Text>
    )

    if (logoUrl && logoPlacement) {
      const { flexPlacement, order } = getFlexPlacmentForLogo(
        placement,
        logoPlacement
      )

      const logo = calcSize && (
        <View style={{ ...calcSize }}>
          <Image src={logoUrl} />
        </View>
      )

      return {
        logoHeight: calcSize && calcSize.height,
        component: (
          <View
            fixed
            style={{
              ...style,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: flexPlacement,
              alignItems: type === 'footer' ? 'flex-end' : 'flex-start'
            }}
          >
            {order.map(id => {
              if (id === 'text') {
                return text
              } else if (id === 'logo') {
                return logo || <View />
              } else {
                return <View />
              }
            })}
          </View>
        )
      }
    } else {
      let alignItems = 'center'
      if (placement === 'right') {
        alignItems = 'flex-end'
      } else if (placement === 'center') {
        alignItems = 'center'
      } else if (placement === 'left') {
        alignItems = 'flex-start'
      }
      return {
        component: (
          <View fixed style={{ ...style, alignItems, textAlign: placement }}>
            {text}
          </View>
        )
      }
    }
  } else {
    return {
      component: <View fixed style={style} />
    }
  }
}
