import React from 'react'
import { fromLonLat } from 'ol/proj'
import { boundingExtent } from 'ol/extent'
import 'ol/ol.css'
import { RMap, RLayerVector, RLayerStamen, RFeature } from 'rlayers'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  useTheme,
  FormControl,
  FormLabel
} from '@material-ui/core'
import { RCircle, RStyle, RFill, RStroke, RText } from 'rlayers/style'
import {
  canadaCenter,
  geoData,
  provinceAbbrevationToProvince
} from './FormCensusDivision'
import { canadaProvincesData } from 'app/views/common/SharedData'
import { Trans } from '@lingui/macro'
import { useFormikContext } from 'formik'

export const CenzusDivisionSelectProvince = ({
  selected,
  id,
  avaliableProvinces = [],
  disabled,
  scopeType,
  setView,
  isPilotPhase,
  ...props
}) => {
  const { setFieldValue } = useFormikContext()

  if (avaliableProvinces.length === 1) {
    return null
  }

  return (
    <FormControl component='fieldset'>
      <FormLabel style={{ paddingBottom: 10 }}>
        {isPilotPhase ? (
          <Trans>CENZUS_DIVISION_ELEMENT_PROVINCES_PILOT_PHASE</Trans>
        ) : (
          <Trans>CENZUS_DIVISION_ELEMENT_PROVINCES</Trans>
        )}
      </FormLabel>
      <FormGroup
        style={{
          flexDirection: 'row',
          marginLeft: 25,
          justifyContent: 'start'
        }}
        onChange={(e, v) => {
          const provinceId = e.target.value + '_province'
          let toSet = [...selected]
          if (toSet.includes(provinceId)) {
            toSet.splice(toSet.indexOf(provinceId), 1)
          } else {
            toSet.push(provinceId)
            let zoom = 5
            if (
              provinceId === 'Nunavut_province' ||
              provinceId === 'Northwest Territories_province'
            ) {
              zoom = 4.2
            } else if (
              provinceId === 'Nova Scotia_province' ||
              provinceId === 'Prince Edward Island_province'
            ) {
              zoom = 6
            }
            setView({
              zoom,
              center: fromLonLat(geoData[provinceId].location)
            })
          }
          if (scopeType === 'Regional') {
            toSet = toSet.filter(value => {
              if (String(value).includes('_cenzus')) {
                const id =
                  provinceAbbrevationToProvince[geoData[value].province] +
                  '_province'
                return toSet.includes(id)
              } else {
                return true
              }
            })
          }
          setFieldValue(`${id}.selectedScope`, toSet)
        }}
      >
        {canadaProvincesData
          .filter(item => {
            if (!avaliableProvinces || avaliableProvinces.length === 0) {
              return true
            }
            return avaliableProvinces.includes(item.value + '_province')
          })
          .map((item, index) => {
            const { value, label } = item
            const checked = selected.includes(value + '_province')
            return (
              <Grid
                container
                direction='column'
                key={index}
                style={{ width: '50%' }}
              >
                <FormControlLabel
                  style={{
                    marginBottom: 10
                  }}
                  key={index}
                  value={value}
                  checked={checked}
                  disabled={disabled}
                  control={
                    <Checkbox
                      style={{
                        paddingLeft: 9,
                        paddingRight: 9,
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                    />
                  }
                  label={label}
                />
              </Grid>
            )
          })}
      </FormGroup>
    </FormControl>
  )
}

export const RegionalMapComponent = React.memo(
  ({ value, addValue, getAvaliableCities }) => {
    return (
      <>
        <RMap
          width={'100%'}
          height={'700px'}
          initial={{ center: canadaCenter, zoom: 4 }}
          extent={boundingExtent([
            fromLonLat([-143, 90]),
            fromLonLat([-50, 40])
          ])}
        >
          <RLayerStamen layer='toner' properties={{ label: 'toner' }} />
          <ShowSelectedCitiesLayer
            value={value}
            addValue={addValue}
            getAvaliableCities={getAvaliableCities}
          />
        </RMap>
      </>
    )
  }
)

export const ShowSelectedCitiesLayer = React.memo(
  ({ value, getAvaliableCities }) => {
    const theme = useTheme()
    const { main, dark } = theme.palette.primary

    const features = getAvaliableCities().filter(feature =>
      value.includes(feature.values_.name + '_city')
    )

    return (
      <RLayerVector zIndex={5}>
        {features.map(feature => {
          return (
            <RFeature geometry={feature.values_.geometry}>
              <RStyle>
                <RCircle radius={8}>
                  <RFill color={dark} />
                </RCircle>
                <RText text={feature.values_.name}>
                  <RFill color='#fff' />
                  <RStroke color='rgba(0, 0, 0, 0.6)' width={3} />
                </RText>
              </RStyle>
            </RFeature>
          )
        })}
      </RLayerVector>
    )
  }
)
