import React, { createRef, useEffect, useRef } from 'react'
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  useLoadScript
} from '@react-google-maps/api'
import Geocode from 'react-geocode'
import { Field, useFormikContext } from 'formik'
import { Trans } from '@lingui/macro'
import Loading from 'egret/components/EgretLoadable/Loading'
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  TextField
} from '@material-ui/core'
import { Autocomplete as AutocompleteMaterial } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { validFieldTypesForTextField } from '../../FormWizard'
import { FormConnectToObject } from '../Common'
import {
  getMainConnected,
  getSFObjectFieldValue,
  insertValueToSFObject
} from '../../Form'
import { FormikTextField } from 'formik-material-fields'
import * as Yup from 'yup'
import { requiredTrans } from '../../formTranslations'
import MUTextField from '../multiuser-input/MUTextField'
import { endEditingField } from '../../multiuser/grpcMultiuserEdit'

const containerStyle = {
  width: '100%',
  height: '400px'
}

const center = {
  lat: 62.227,
  lng: -105.3809
}

const libraries = ['places']
const apiKey = 'AIzaSyCTJva5A5EJg3IL8gZNO6NYQ5Ve4l52pI4'
Geocode.setApiKey(apiKey)
Geocode.setLanguage('en')
Geocode.enableDebug(true)
Geocode.setRegion('ca')
Geocode.setLocationType('ROOFTOP')

export const formGoogleMapsPickerExtractSaveKey = ({
  saveMap,
  sfObject,
  value,
  connectedProps,
  subObjectsMap,
  connectedObjectId
}) => {
  const {
    latitude,
    longitude,
    street,
    city,
    zipCode,
    province
  } = connectedProps
  if (latitude && value.lat) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: value.lat,
      fieldProps: latitude,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }
  if (longitude && value.lng) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: value.lng,
      fieldProps: longitude,
      subObjectsMap,
      customConnectedField: true,
      connectedObjectId
    })
  }
  if (street && value.street) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: value.street,
      fieldProps: street,
      subObjectsMap,
      customConnectedField: true,
      connectedObjectId
    })
  }
  if (city && value.city) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: value.city,
      fieldProps: city,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }
  if (zipCode && value.zipCode) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: value.zipCode,
      fieldProps: zipCode,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }
  if (province && value.province) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: value.province,
      fieldProps: province,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }
}

export const formGoogleMapsPickerValidation = () =>
  Yup.object({
    city: Yup.string().required(requiredTrans),
    street: Yup.string().required(requiredTrans),
    zipCode: Yup.string().required(requiredTrans),
    province: Yup.string().required(requiredTrans)
  })

const keyToLabel = {
  street: <Trans>Street</Trans>,
  city: <Trans>City</Trans>,
  zipCode: <Trans>Zip Code</Trans>,
  province: <Trans>Province</Trans>
}

export const formGoogleMapsPickerError = error => {
  const toRet = {}
  Object.keys(error).forEach(key => {
    toRet[key] = {
      error: error[key],
      label: keyToLabel[key]
    }
  })
  return toRet
}

export const formGoogleMapsPickerValueToText = (value, question) => {
  let addressString = value.street || ''
  if (value.city) {
    addressString += ', ' + value.city
  }
  if (value.province) {
    addressString += ', ' + value.province
  }
  if (value.zipCode) {
    addressString += ', ' + value.zipCode
  }
  const lat = value.lat && value.lat.toFixed(3)
  const lng = value.lng && value.lng.toFixed(3)
  const toRet = (
    <div>
      {addressString && (
        <span>
          <b>
            <Trans>Address</Trans>
            {': '}
          </b>
          {addressString}
        </span>
      )}
      {lat && (
        <div>
          <b>
            <Trans>Latitude</Trans>
            {': '}
          </b>
          {lat}
        </div>
      )}
      {lng && (
        <div>
          <b>
            <Trans>Longitude</Trans>
            {': '}
          </b>
          {lng}
        </div>
      )}
    </div>
  )
  return {
    en: toRet,
    fr: toRet
  }
}

export const formGoogleMapsPickerDefaultValue = (obj, info, item) => {
  const mainConnected = getMainConnected(item)
  if (!obj) {
    return {
      street: '',
      city: '',
      zipCode: '',
      province: ''
    }
  }
  return {
    lat: getSFObjectFieldValue(obj, mainConnected.latitude) || '',
    lng: getSFObjectFieldValue(obj, mainConnected.longitude) || '',
    street: getSFObjectFieldValue(obj, mainConnected.street) || '',
    city: getSFObjectFieldValue(obj, mainConnected.city) || '',
    zipCode: getSFObjectFieldValue(obj, mainConnected.zipCode) || '',
    province: getSFObjectFieldValue(obj, mainConnected.province) || ''
  }
}

export const FormGoogleMapsPicker = ({
  id,
  useMultiuser,
  muBag,
  typeProps: { showInPopUp },
  disabled,
  ...props
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
    libraries: libraries
  })
  const user = useSelector(state => state.user)
  const { setFieldValue, values } = useFormikContext()
  const value = values[id]
  const [map, setMap] = React.useState(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [addressFromMarker, setAddressFromMarker] = React.useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const [forceRender, setForceRender] = React.useState(0)

  const onLoad = React.useCallback(function callback (map) {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  useEffect(() => {
    // Base zoom/center are not loaded properly and without this rerender are set only after interacting with element on page
    setTimeout(() => {
      setForceRender(forceRender + 1)
    }, 1000)
  }, [map])

  const onUnmount = React.useCallback(function callback (map) {
    setMap(null)
  }, [])

  if (isLoaded) {
    const baseCenter = new window.google.maps.LatLng(center.lat, center.lng)
    const markerPosition =
      Boolean(value && value.lat && value.lng) &&
      new window.google.maps.LatLng(value.lat, value.lng)

    const addressFromGoogleData = data => {
      const toRet = {
        street: '',
        city: '',
        zipCode: '',
        province: ''
      }
      let streetNumber = ''
      let streetName = ''
      data.address_components.forEach(address => {
        if (address.types.includes('postal_code')) {
          toRet.zipCode = address.long_name
        }
        if (address.types.includes('street_number')) {
          streetNumber = address.long_name
        }
        if (address.types.includes('route')) {
          streetName = address.long_name
        }
        if (address.types.includes('locality')) {
          toRet.city = address.long_name
        }
        if (address.types.includes('administrative_area_level_1')) {
          toRet.province = address.long_name
        }
      })
      toRet.street = streetNumber + ' ' + streetName
      return toRet
    }

    const searchFromAddress = address => {
      return Geocode.fromAddress(address).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          const pos = new window.google.maps.LatLng(lat, lng)
          // setMarkerPosition(pos)
          let inCanada = false
          response.results[0].address_components.forEach(component => {
            if (
              component.types.includes('country') &&
              component.long_name === 'Canada'
            ) {
              inCanada = true
            }
          })
          if (!inCanada) {
            enqueueSnackbar(
              <Trans>Provided location is not in Canada!</Trans>,
              {
                variant: 'error'
              }
            )
            setAddressFromMarker(null)
          } else {
            setFieldValue(id, {
              ...value,
              lat: pos.lat(),
              lng: pos.lng()
            })
            if (useMultiuser) {
              endEditingField({
                ...muBag,
                fieldId: id,
                fieldValue: {
                  ...value,
                  lat: pos.lat(),
                  lng: pos.lng()
                }
              })
            }
            setAddressFromMarker(addressFromGoogleData(response.results[0]))
            if (showInPopUp && !dialogOpen) {
              setDialogOpen(true)
            }
          }
        },
        error => {
          console.error(error)
          enqueueSnackbar(
            <Trans>Could not find location from provided address</Trans>,
            { variant: 'error' }
          )
        }
      )
      setAddressFromMarker(null)
    }

    const onMapClick = e => {
      // setMarkerPosition(e.latLng)
      if (disabled) {
        return
      }
      setFieldValue(id, {
        ...value,
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      })
      if (useMultiuser) {
        endEditingField({
          ...muBag,
          fieldId: id,
          fieldValue: {
            ...value,
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
          }
        })
      }
      Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
        response => {
          let inCanada = false
          response.results[0].address_components.forEach(component => {
            if (
              component.types.includes('country') &&
              component.long_name === 'Canada'
            ) {
              inCanada = true
            }
          })
          if (!inCanada) {
            enqueueSnackbar(
              <Trans>You have to select location in Canada!</Trans>,
              {
                variant: 'error'
              }
            )
            setAddressFromMarker(null)
          } else {
            setAddressFromMarker(addressFromGoogleData(response.results[0]))
          }
        },
        error => {
          setAddressFromMarker(null)
          console.error(error)
        }
      )
    }

    const googleMap = (
      <GoogleMap
        options={{
          gestureHandling: 'cooperative',
          minZoom: 4,
          restriction: {
            latLngBounds: {
              north: 83,
              south: 42,
              east: -53,
              west: -141
            }
          }
        }}
        mapContainerStyle={containerStyle}
        center={markerPosition || baseCenter}
        zoom={4}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={onMapClick}
      >
        {markerPosition && <Marker position={markerPosition} />}
        <div
          type='text'
          style={{
            boxSizing: 'border-box',
            width: '240px',
            height: '32px',
            padding: '0 12px',
            borderRadius: '3px',
            outline: 'none',
            textOverflow: 'ellipses',
            position: 'absolute',
            left: '50%',
            marginLeft: '-120px'
          }}
        >
          <Button
            disabled={!addressFromMarker || disabled}
            variant='contained'
            color='primary'
            fullWidth
            onClick={() => {
              setFieldValue(id, { ...value, ...addressFromMarker })
              if (useMultiuser) {
                endEditingField({
                  ...muBag,
                  fieldId: id,
                  fieldValue: { ...value, ...addressFromMarker }
                })
              }
              if (dialogOpen) {
                setDialogOpen(false)
              }
            }}
          >
            <Trans>Copy address from marker</Trans>
          </Button>
        </div>
      </GoogleMap>
    )

    return (
      <div>
        {showInPopUp ? (
          <div>
            <Button
              color='primary'
              variant='contained'
              fullWidth
              onClick={e => {
                setDialogOpen(true)
              }}
            >
              <Trans>Open address picker</Trans>
            </Button>
            <Dialog open={dialogOpen} fullWidth maxWidth='md'>
              <DialogTitle>
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <Trans>Address picker</Trans>
                  <IconButton
                    onClick={e => {
                      setDialogOpen(false)
                    }}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Grid>
              </DialogTitle>
              <DialogContent>{googleMap}</DialogContent>
            </Dialog>
          </div>
        ) : (
          googleMap
        )}
        <Grid style={{ marginTop: 10 }} container direction='row' wrap='nowrap'>
          <Grid container direction='row'>
            <Grid item style={{ padding: 10 }} xs={12}>
              <MUTextField
                muBag={muBag}
                useMultiuser={useMultiuser}
                id={id + '.street'}
                disabled={disabled}
                label={<Trans>Street</Trans>}
              />
            </Grid>
            <Grid item style={{ padding: 10 }} xs={5}>
              <MUTextField
                muBag={muBag}
                useMultiuser={useMultiuser}
                id={id + '.city'}
                disabled={disabled}
                label={<Trans>City</Trans>}
              />
            </Grid>
            <Grid item style={{ padding: 10 }} xs={5}>
              <MUTextField
                muBag={muBag}
                useMultiuser={useMultiuser}
                id={id + '.province'}
                disabled={disabled}
                label={<Trans>Province</Trans>}
              />
            </Grid>
            <Grid item style={{ padding: 10 }} xs={2}>
              <MUTextField
                muBag={muBag}
                useMultiuser={useMultiuser}
                id={id + '.zipCode'}
                disabled={disabled}
                label={<Trans>Zip code</Trans>}
              />
            </Grid>
          </Grid>
          <Grid item style={{ width: 250, marginTop: 10 }}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              disabled={disabled}
              onClick={e => {
                let searchString = ''
                if (value.street) {
                  searchString += value.street + ', '
                }
                if (value.city) {
                  searchString += value.city + ', '
                }
                if (value.province) {
                  searchString += value.province + ', '
                }
                if (value.zipCode) {
                  searchString += value.zipCode
                }
                searchString = searchString.trim()
                if (searchString.charAt(searchString.length - 1) === ',') {
                  searchString = searchString.slice(0, -1)
                }
                console.log('search from address: ', searchString)
                searchFromAddress(searchString)
              }}
            >
              <Trans>Move marker to address</Trans>
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  } else {
    return <Loading isNotFixed />
  }
}

export const FormEditorGoogleMapsPicker = ({
  editMode,
  typeProps = {},
  depth,
  ...props
}) => {
  const dispatch = useDispatch()
  const { showInPopUp, required, connectedTo = [] } = typeProps

  if (!editMode) {
    return <FormGoogleMapsPicker {...props} typeProps={typeProps} />
  }

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(showInPopUp)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.showInPopUp = e.target.checked
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Show map in pop up?</Trans>}
      />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(required)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.required = e.target.checked
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Required</Trans>}
        />
      </div>

      <FormConnectToObject
        depth={depth}
        typeProps={typeProps}
        fieldRender={({ fieldsToConnect, fieldsToConnectMap, index }) => {
          const item = connectedTo[index] || {}
          const { latitude, longitude, street, city, zipCode, province } = item
          const latFields = [...fieldsToConnect].filter(
            item =>
              item.type === 'double' && item.label.indexOf('Latitude') !== -1
          )
          const lngFields = [...fieldsToConnect].filter(
            item =>
              item.type === 'double' && item.label.indexOf('Longitude') !== -1
          )
          const stringFields = [...fieldsToConnect].filter(item =>
            validFieldTypesForTextField.includes(item.type)
          )
          return (
            <div>
              <AutocompleteMaterial
                freeSolo={false}
                value={latitude ? latitude.label : null}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].latitude = fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={latFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Latitude</Trans>}
                  />
                )}
              />

              <AutocompleteMaterial
                freeSolo={false}
                value={longitude ? longitude.label : null}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].longitude = fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={lngFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Longitude</Trans>}
                  />
                )}
              />

              <AutocompleteMaterial
                freeSolo={false}
                value={street ? street.label : null}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].street = fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={stringFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Street</Trans>}
                  />
                )}
              />

              <AutocompleteMaterial
                freeSolo={false}
                value={city ? city.label : null}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].city = fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={stringFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>City</Trans>}
                  />
                )}
              />

              <AutocompleteMaterial
                freeSolo={false}
                value={province ? province.label : null}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].province = fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={stringFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Province</Trans>}
                  />
                )}
              />

              <AutocompleteMaterial
                freeSolo={false}
                value={zipCode ? zipCode.label : null}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].zipCode = fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={stringFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Zip Code</Trans>}
                  />
                )}
              />
            </div>
          )
        }}
      />
    </div>
  )
}
