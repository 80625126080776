import { t, Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  Slider
} from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import _ from 'lodash'
import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import { validFieldTypesForTextFieldNumeric } from '../FormWizard'
import {
  endEditingField,
  startEditingField
} from '../multiuser/grpcMultiuserEdit'
import {
  FormConnectToObject,
  MuEditedByLabel,
  NumberFormatDefault
} from './Common'
import { FormErrorLabel } from './FormErrorLabel'

export function FormNumericSliderPrint ({ value, title, typeProps }) {
  const { max = 100, min = 0 } = typeProps
  let valueString =
    value +
    ' (' +
    myI18n._(t`min`) +
    '. ' +
    String(min) +
    ', ' +
    myI18n._(t`max`) +
    '. ' +
    String(max) +
    ')'

  return (
    <Typography>
      <Typography className='form-print-subtitle'>{title}</Typography>
      {value && valueString}
    </Typography>
  )
}

export function FormNumericSlider ({
  id,
  useMultiuser,
  muBag,
  disabled,
  typeProps = {}
}) {
  const user = useSelector(state => state.user)
  const { values } = useFormikContext()
  const [tempValue, setTempValue] = useState(null)
  const [field, meta, helpers] = useField(id)
  const { required, max, min, step = 1 } = typeProps
  const minValue = min || 0
  const maxValue = max || 100
  let isValid = field.value <= maxValue && field.value >= minValue
  const { setValue } = helpers
  const isError = Boolean(meta.touched && meta.error)
  const { muInfo, muUsers = {} } = values
  const muState = muInfo[id]
  let userColor, userName, locked
  if (muState) {
    const { user } = muState
    locked = muState.locked
    if (locked) {
      userColor = muUsers[user]?.color
      userName = muUsers[user]?.name
    }
  }

  return (
    <div style={{ paddingLeft: 15, paddingRight: 15 }}>
      <Slider
        className={!field.value && 'slider-muted'}
        style={{
          color:
            !field.value && field.value !== 0
              ? '#A5C9D3'
              : disabled
              ? 'rgba(0, 0, 0, 0.26)'
              : '#10708A'
        }}
        onChangeCommitted={(event, newValue) => {
          setValue(newValue)
          setTempValue(null)
          if (useMultiuser) {
            endEditingField({
              ...muBag,
              fieldId: id,
              fieldValue: newValue,
              lockId: values.muInfo.lockId
            })
          }
        }}
        onChange={(event, newValue) => {
          setTempValue(newValue)
          if (useMultiuser && !locked && !tempValue) {
            startEditingField({
              ...muBag,
              fieldId: id
            })
          }
        }}
        value={
          tempValue || tempValue === 0
            ? tempValue
            : isValid
            ? field.value
            : minValue
        }
        step={Number(step)}
        disabled={
          Boolean(muState && muState.locked && muState.user !== user.userId) ||
          disabled
        }
        min={Number(minValue)}
        max={Number(maxValue)}
        marks={[
          { value: minValue, label: minValue },
          { value: maxValue, label: maxValue }
        ]}
        valueLabelDisplay='auto'
      />
      <MuEditedByLabel color={userColor} userName={userName} />
      <FormErrorLabel error={isError} id={id} required={required} />
    </div>
  )
}

export function FormEditorNumericSlider ({
  typeProps = {},
  french,
  injectable,
  showPrintProps,
  depth,
  editMode,
  title,
  ...props
}) {
  const dispatch = useDispatch()
  const { required, max, min, step } = typeProps

  if (!editMode) {
    return <FormNumericSlider typeProps={typeProps} title={title} {...props} />
  }

  return (
    <Grid container direction='column' style={{ padding: 10 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(required)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.required = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Required</Trans>}
      />

      <Grid container>
        <Grid item xs style={{ padding: 5 }}>
          <TextField
            label={<Trans>Slider step</Trans>}
            value={step || '1'}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant='outlined'
            fullWidth
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.step = e.target.value
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>

        <Grid item xs style={{ padding: 5 }}>
          <TextField
            label={<Trans>Min value</Trans>}
            value={min || ''}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant='outlined'
            fullWidth
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.min = e.target.value
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ padding: 5 }}>
          <TextField
            label={<Trans>Max value</Trans>}
            value={max || ''}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant='outlined'
            fullWidth
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.max = e.target.value
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
      </Grid>

      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        validTypes={validFieldTypesForTextFieldNumeric}
        injectable={injectable}
      />
    </Grid>
  )
}
