import moment from 'moment'
import SFAuthService from '..//SFAuthService'
import { NO_USER } from '../SFAuthService'
import { mapSFToForm, reverseMap } from '../sfDataService'
import { dateFormat } from 'app/appSettings'

const mapFields = {
  direction: 'in',
  Name: 'name',
  Id: 'id'
}

export const parseFundingStream = obj => mapSFToForm(mapFields, obj)

export const fetchFundingStreams = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Funding_Stream__c').find({})
}

export const fetchFundingStreamsParsed = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Funding_Stream__c')
    .find({})
    .then(result => result.map(obj => mapSFToForm(mapFields, obj)))
}
