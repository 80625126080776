import React, { useState } from 'react'
import { useEffect } from 'react'
import { Form } from './Form'
const crypto = require('crypto')

const FormTabSession = ({ ...props }) => {
  const [tabId, setTabId] = useState()

  useEffect(() => {
    const newId = crypto.randomBytes(8).toString('hex')
    window.localStorage.setItem('tabId', newId)
    setTabId(newId)
  }, [])

  return <Form tabId={tabId} {...props} />
}

export default FormTabSession
