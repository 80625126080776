import { Trans } from '@lingui/macro'
import React from 'react'
import { FormConnectToObject, mapEditorFormElements } from './Common'
import { dateFormat } from 'app/appSettings'
import { Field } from 'formik'
import { getMainConnected, getSFObjectFieldValue } from '../Form'
import moment from 'moment'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import CustomDatePicker from 'app/views/common/CustomDatePicker'
import MUDatePicker from './multiuser-input/MUDatePicker'

export const formDatePickerDefaultValue = (obj, info, item) => {
  if (!obj) {
    return null
  }
  const { connectedField, connectedObject } = getMainConnected(item)
  if (connectedObject && connectedField) {
    const sfValue = getSFObjectFieldValue(obj, connectedField)
    return sfValue ? moment(new Date(sfValue)).utc() : null
  } else {
    return null
  }
}

export const formDatePickerValueToText = value => {
  const toDate = moment(value)
  if (toDate.isValid()) {
    return { en: toDate.format(dateFormat), fr: toDate.format(dateFormat) }
  } else {
    return { en: value || '', fr: value || '' }
  }
}

export const FormDatePicker = ({
  typeProps,
  title,
  id,
  useMultiuser,
  muBag,
  disabled,
  formikRef
}) => {
  return (
    <Field name={id}>
      {({ form: { setFieldValue, setFieldTouched, values }, field, meta }) => {
        const valueIsValid = moment.utc(field.value).isValid()
        const fieldValue = moment.utc(field.value)
        let {
          showFieldLabel,
          clearable,
          minDate,
          maxDate,
          minDateElement,
          maxDateElement,
          required
        } = typeProps
        const momentMaxDate = moment.utc(values[maxDateElement])
        const momentMinDate = moment.utc(values[minDateElement])

        if (minDateElement && values[minDateElement]) {
          minDate = momentMinDate
          if (valueIsValid && momentMinDate.isAfter(fieldValue)) {
            if (
              !(
                momentMinDate.isValid() && momentMaxDate.isBefore(momentMinDate)
              )
            ) {
              setFieldValue(id, momentMinDate)
            }
          }
        }
        if (maxDateElement && values[maxDateElement]) {
          maxDate = momentMaxDate
          if (valueIsValid && momentMaxDate.isBefore(fieldValue)) {
            if (
              !(momentMinDate.isValid() && momentMinDate.isAfter(momentMaxDate))
            ) {
              setFieldValue(id, momentMaxDate)
            }
          }
        }
        return (
          <MUDatePicker
            id={id}
            muBag={muBag}
            useMultiuser={useMultiuser}
            label={showFieldLabel && title}
            cancelLabel={<Trans>Cancel</Trans>}
            okLabel={<Trans>Ok</Trans>}
            format={dateFormat}
            disabled={disabled}
            className='text-muted'
            inputVariant='outlined'
            fullWidth
            clearable={Boolean(clearable)}
            minDate={minDate}
            maxDate={maxDate}
            formikRef={formikRef}
            helperText={required && <Trans>Required</Trans>}
          />
        )
      }}
    </Field>
  )
}

export const FormEditorDatePicker = ({
  typeProps,
  showPrintProps,
  title,
  depth,
  editMode,
  id,
  french,
  injectable,
  ...props
}) => {
  const {
    required,
    showFieldLabel,
    printLabelPlacement,
    printPageBreakBefore,
    clearable,
    minDate,
    minDateElement,
    maxDate,
    maxDateElement
  } = typeProps
  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)

  if (!editMode) {
    return (
      <FormDatePicker
        id={id}
        french={french}
        editMode
        typeProps={typeProps}
        title={title}
        {...props}
      />
    )
  }
  const avaliableElements = mapEditorFormElements({
    data: tree,
    french,
    id,
    elementTypes: ['datePicker']
  })

  return (
    <Grid container direction='column'>
      <h6 style={{ marginLeft: 10 }}>
        <Trans>Min date</Trans>
      </h6>
      <Grid container>
        <Grid item xs style={{ padding: 5 }}>
          <CustomDatePicker
            fullWidth
            clearable
            format={dateFormat}
            value={minDate || null}
            label={<Trans>Minimum input date</Trans>}
            inputVariant='outlined'
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.minDate = e
              delete toSet.minDateElement
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ padding: 5 }}>
          <TextField
            select
            fullWidth
            variant='outlined'
            label={<Trans>Minimum date based on element</Trans>}
            value={minDateElement || ''}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.minDateElement = e.target.value
              delete toSet.minDate
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          >
            {[
              <MenuItem key='none' value=''>
                <Trans>Clear</Trans>
              </MenuItem>,
              ...avaliableElements.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))
            ]}
          </TextField>
        </Grid>
      </Grid>
      <h6 style={{ marginLeft: 10 }}>
        <Trans>Max date</Trans>
      </h6>
      <Grid container>
        <Grid item xs style={{ padding: 5 }}>
          <CustomDatePicker
            fullWidth
            clearable
            format={dateFormat}
            value={maxDate || null}
            label={<Trans>Maximum input date</Trans>}
            inputVariant='outlined'
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.maxDate = e
              delete toSet.maxDateElement
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ padding: 5 }}>
          <TextField
            select
            fullWidth
            variant='outlined'
            label={<Trans>Maximum date based on element</Trans>}
            value={maxDateElement || ''}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.maxDateElement = e.target.value
              delete toSet.maxDate
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          >
            {[
              <MenuItem key='none' value=''>
                <Trans>Clear</Trans>
              </MenuItem>,
              ...avaliableElements.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))
            ]}
          </TextField>
        </Grid>
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(required)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.required = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Required</Trans>}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(clearable)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.clearable = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Input can be cleared?</Trans>}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(showFieldLabel)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.showFieldLabel = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Show label in field?</Trans>}
      />
      <FormConnectToObject
        typeProps={typeProps}
        injectable={injectable}
        depth={depth}
        validTypes={['date', 'datetime']}
      />
    </Grid>
  )
}
