import { grpc } from '@improbable-eng/grpc-web'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import _ from 'lodash'
import moment from 'moment'
import { CursorEvent } from './proto/generated/Multiuser_grpc_web_pb'
import {
  RequestSFSaveMessage,
  SaveToSFCompletedReport,
  RequestStatus
} from './proto/generated/MultiuserSF_grpc_web_pb'
import { Multiuser } from './proto/generated/Multiuser_pb_service'
import { MultiuserSF } from './proto/generated/MultiuserSF_pb_service'
import {
  ChatMessageCollectionRequest,
  ChatMessageSend,
  DocumentCacheID,
  DocumentCacheType,
  DocumentToSubmit,
  Field,
  FieldAndFieldValue,
  FieldAndLockID,
  FieldAndLockIDAndContent,
  FieldAndValue,
  FieldsAndValues,
  Realm,
  RealmInitRequest,
  RealmMetadata,
  UserSessionRequest
} from './proto/generated/Multiuser_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { Trans } from '@lingui/macro'
import { multiuserColors } from '../Form'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'

export const host = sfOauthConfig.config.multiuserHost

export const formRealmId = (orgId, id) => '+' + orgId + '+' + id

const decodeUserInfoArrayResponse = response => {
  const toRet = {}
  console.log('logged users response', response)
  response[0].forEach((array, loginOrder) => {
    if (array.length > 0) {
      const id = array[0]
      const colorIndex = array[4] || 0
      toRet[id] = {
        id,
        logOrder: loginOrder,
        ...JSON.parse(array[1]),
        color: multiuserColors[colorIndex]
      }
    }
  })
  return toRet
}

export const formCacheTypeToLabel = {
  0: <Trans>Manual save</Trans>,
  5: <Trans>Autosaved</Trans>,
  10: <Trans>Unsaved changes</Trans>
}

export const grpcGetFormBackups = ({ onFail, onSuccess, token }) => {
  grpc.unary(Multiuser.GetCachedDocumentVersions, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          console.log('get caches response', response.message.array[0])
          const caches = response.message.array[0]
            .filter(obj => obj[4] !== DocumentCacheType.INITIAL_DOCUMENT)
            .map(obj => {
              return {
                id: obj[1],
                type: obj[4] || 0,
                date: moment.unix(obj[3][0]),
                users: obj[5] && obj[5].map(arr => arr[1])
              }
            })
          onSuccess(caches)
        }
      }
    }
  })
}

export const grpcGetFormCache = ({ id, onFail, onSuccess, token }) => {
  const request = new DocumentCacheID()
  request.setUniqueid(id)
  grpc.unary(Multiuser.GetDocumentCacheByID, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(JSON.parse(response.message.array[2]))
        }
      }
    }
  })
}

export const tryInitiatingForm = ({
  onFail,
  accessToken,
  onSuccess,
  formId,
  userId,
  initialValues,
  token,
  userInfo,
  metadata,
  mode,
  multiuserLoginToken
}) => {
  const request = new Realm()
  request.setRealmpath(formId)
  grpc.unary(Multiuser.IsRealmInited, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token,
      MultiuserLoginToken: multiuserLoginToken
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        const isInited = response.message.toObject().istrue
        console.log('Is form inited?', isInited)
        if (isInited) {
          grpcStartSession({
            onFail,
            userInfo,
            formId,
            mode,
            multiuserLoginToken,
            token: accessToken,
            onSuccess: ({ colorIndex, sessionToken }) => {
              setReamlMetadata({
                sessionToken,
                multiuserLoginToken,
                metadata,
                onSuccess: () => {
                  grpcFetchAllUsersInfo({
                    id: formId,
                    multiuserLoginToken,
                    token: sessionToken,
                    userId,
                    onSuccess: ({ users, sessionStartTime, isAnotherUser }) => {
                      grpcGetUnsavedChanges({
                        multiuserLoginToken,
                        token: sessionToken,
                        onSuccess: unsavedData => {
                          console.log('Is unsaved data?', unsavedData)
                          getCurrentFormState({
                            formId,
                            token: sessionToken,
                            startEditingTime: sessionStartTime,
                            onSuccess: formState => {
                              console.log('got current form state', formState)
                              onSuccess({
                                inited: isInited,
                                formState,
                                unsavedData,
                                sessionToken,
                                colorIndex,
                                users,
                                sessionStartTime,
                                isAnotherUser
                              })
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        } else if (!isInited) {
          const realmToInit = new RealmInitRequest()
          realmToInit.setRealmpath(formId)
          realmToInit.setContent(JSON.stringify(initialValues))
          //realmToInit.setR(sessionName)
          grpc.unary(Multiuser.InitRealm, {
            request: realmToInit,
            host,
            metadata: new grpc.Metadata({
              MultiuserLoginToken: multiuserLoginToken
            }),
            onEnd: response => {
              if (response.status !== grpc.Code.OK) {
                if (onFail) {
                  onFail(response)
                }
              } else {
                grpcStartSession({
                  onFail,
                  userInfo,
                  formId,
                  mode,
                  multiuserLoginToken,
                  token: accessToken,
                  onSuccess: ({ colorIndex, sessionToken }) => {
                    setReamlMetadata({
                      sessionToken,
                      multiuserLoginToken,
                      metadata,
                      onSuccess: () => {
                        grpcGetUnsavedChanges({
                          formId,
                          multiuserLoginToken,
                          token: sessionToken,
                          onSuccess: unsavedData => {
                            console.log('Is unsaved data?', unsavedData)
                            grpcFetchAllUsersInfo({
                              id: formId,
                              userId: userId,
                              multiuserLoginToken,
                              token: sessionToken,
                              onSuccess: ({
                                users,
                                sessionStartTime,
                                isAnotherUser
                              }) => {
                                onSuccess({
                                  inited: isInited,
                                  unsavedData,
                                  sessionToken,
                                  colorIndex,
                                  users,
                                  sessionStartTime,
                                  isAnotherUser
                                })
                              }
                            })
                          }
                        })
                      }
                    })
                  }
                })
              }
            }
          })
        }
      }
    }
  })
}

const setReamlMetadata = ({
  multiuserLoginToken,
  sessionToken,
  metadata,
  onFail,
  onSuccess
}) => {
  const request = new RealmMetadata()
  const map = request.getMetadataMap()
  Object.keys(metadata).forEach(key => {
    map.set(key, metadata[key])
  })
  grpc.unary(Multiuser.SetRealmMetadata, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: sessionToken,
      MultiuserLoginToken: multiuserLoginToken
    }),
    onEnd: response => {
      console.log('set realm metadata result', response, request, sessionToken)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const updateLockedFieldValue = ({
  onFail,
  onSuccess,
  fieldId,
  lockId,
  fieldValue,
  token
}) => {
  const request = new FieldAndLockIDAndContent()
  request.setFieldvalue(JSON.stringify(fieldValue))
  request.setFieldid(fieldId)
  request.setLockid(lockId)
  grpc.unary(Multiuser.UpdateLockedFieldValue, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const commitFormCache = ({
  values,
  userIds = [],
  type,
  formId,
  onFail,
  onSuccess,
  token
}) => {
  const toSend = _.cloneDeep(values)
  delete toSend.muInfo
  delete toSend.muUsers
  const request = new DocumentToSubmit()
  request.setRealmpath(formId)
  request.setContent(JSON.stringify(toSend))
  request.setCachetype(type)
  request.setParticipatingusersidsList(userIds)
  console.log('commiting form cache', toSend, formId, userIds)
  grpc.unary(Multiuser.SubmitDocumentCache, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      console.log('commit cache response', response)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const commitChangeToMultipleFields = ({
  array,
  onSuccess,
  onFail,
  token
}) => {
  const request = new FieldsAndValues()
  request.setFieldsandvaluesarrayList(
    array.map(([id, value]) => {
      const toRet = new FieldAndValue()
      toRet.setFieldid(id)
      toRet.setFieldvalue(JSON.stringify(value))
      return toRet
    })
  )
  grpc.unary(Multiuser.MassiveCommitFieldsImmediately, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response)
        }
      }
    }
  })
}

export const endEditingField = ({
  onFail,
  onSuccess,
  fieldId,
  lockId,
  fieldValue,
  token
}) => {
  console.log('end editing field', fieldId, fieldValue)
  const request = new Field()
  request.setFieldid(fieldId)
  grpc.unary(Multiuser.IsFieldLocked, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      console.log('ended editing field for lock', lockId)
      console.log('field id', fieldId)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        const isLocked = Boolean(response.message.array[0])
        console.log('was it locked?', isLocked)
        if (isLocked) {
          const request = new FieldAndLockIDAndContent()
          request.setFieldid(fieldId)
          request.setFieldvalue(JSON.stringify(fieldValue))
          request.setLockid(lockId)
          grpc.unary(Multiuser.CommitLockedField, {
            request,
            host,
            metadata: new grpc.Metadata({
              MultiuserSessionToken: token
            }),
            onEnd: response => {
              if (response.status !== grpc.Code.OK) {
                if (onFail) {
                  onFail(response)
                }
              } else {
                if (onSuccess) {
                  onSuccess(response.message)
                }
              }
            }
          })
        } else {
          const request = new FieldAndFieldValue()
          request.setFieldid(fieldId)
          request.setFieldvalue(JSON.stringify(fieldValue))
          grpc.unary(Multiuser.CommitFieldImmediately, {
            request,
            host,
            metadata: new grpc.Metadata({
              MultiuserSessionToken: token
            }),
            onEnd: response => {
              if (response.status !== grpc.Code.OK) {
                if (onFail) {
                  onFail(response)
                }
              } else {
                if (onSuccess) {
                  onSuccess(response.message)
                }
              }
            }
          })
        }
      }
    }
  })
}

export const pingServer = ({ onReject, onSuccess, token }) => {
  grpc.unary(Multiuser.Ping, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        onReject(response)
      } else {
        onSuccess()
      }
    }
  })
}

export const unlockFieldWithoutChanges = ({
  lockId,
  fieldId,
  onFail,
  onSuccess,
  token
}) => {
  const request = new FieldAndLockID()
  request.setFieldid(fieldId)
  request.setLockid(lockId)
  if (!lockId) {
    if (onSuccess) {
      onSuccess()
    }
    return
  }
  grpc.unary(Multiuser.CancelLockedField, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const startEditingField = ({
  onFail,
  onSuccess,
  fieldId,
  userId,
  token
}) => {
  const request = new Field()
  request.setFieldid(fieldId)
  grpGetLockedFieldsForForm({
    userId,
    token,
    onFail: e => {
      if (onFail) {
        onFail()
      }
    },
    onSuccess: locks => {
      let isLocked = false,
        currentLock
      locks
        .filter(obj => obj.lockedBy === userId)
        .forEach(lockObj => {
          if (lockObj.fieldId === fieldId) {
            isLocked = true
            currentLock = lockObj.lockId
          } else {
            unlockFieldWithoutChanges({
              lockId: lockObj.lockId,
              fieldId: lockObj.fieldId,
              token,
              userId
            })
          }
        })

      if (!isLocked) {
        grpc.unary(Multiuser.LockField, {
          request,
          host,
          metadata: new grpc.Metadata({
            MultiuserSessionToken: token
          }),
          onEnd: response => {
            if (response.status !== grpc.Code.OK) {
              if (onFail) {
                onFail(response)
              }
            } else {
              if (onSuccess) {
                onSuccess(response.message)
              }
            }
          }
        })
      } else {
        console.log('field is already locked')
        const request = new FieldAndLockID()
        request.setFieldid(fieldId)
        request.setLockid(currentLock)
        grpc.unary(Multiuser.ReclaimLockedField, {
          request,
          host,
          metadata: new grpc.Metadata({
            MultiuserSessionToken: token
          }),
          onEnd: response => {
            if (response.status !== grpc.Code.OK) {
              if (onFail) {
                onFail(response)
              }
            } else {
              if (onSuccess) {
                onSuccess(response.message)
              }
            }
          }
        })
      }
    }
  })
}

export const sendChatMessage = ({ message, onFail, onSuccess, token }) => {
  const request = new ChatMessageSend()
  request.setMessagecontent(message)
  return grpc.unary(Multiuser.SendChatMessage, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess()
        }
      }
    }
  })
}

export const grpcFetchChatMessages = ({
  sessionStartTime,
  onFail,
  onSuccess,
  token
}) => {
  const request = new ChatMessageCollectionRequest()
  if (sessionStartTime) {
    const timestamp = new Timestamp()
    timestamp.setSeconds(moment.utc(sessionStartTime).unix())
    request.setFromtime(timestamp)
  }
  return grpc.unary(Multiuser.GetChatMessages, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        const { array } = response.message
        if (onSuccess) {
          onSuccess(
            array[0].map(array => {
              return {
                userId: array[0],
                recieved: array[2] && moment.unix(array[2][0]),
                text: array[1]
              }
            })
          )
        }
      }
    }
  })
}

export const grpcListenForChatMessageSent = ({ onEventRecieved, token }) => {
  return grpc.invoke(Multiuser.UserSentChatMessage, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      if (onEventRecieved) {
        onEventRecieved({
          user: response.array[0],
          message: response.array[1]
        })
      }
    }
  })
}

export const grpcListenForFieldLockEvent = ({ onEventRecieved, token }) => {
  return grpc.invoke(Multiuser.UserChangedLockFieldStatus, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      if (onEventRecieved) {
        const { array } = response
        onEventRecieved({
          userId: array[0],
          operation: array[2] || 0,
          changes: array[1].map(arr => ({
            fieldId: arr[0],
            lockId: arr[1],
            fieldValue: arr[2]
          }))
        })
      }
    }
  })
}

export const grpcListenForEndEditingFormEvent = ({
  onEventRecieved,
  token
}) => {
  return grpc.invoke(Multiuser.UserHasLoggedOutFromRealm, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        onEventRecieved(response.array[0])
      }
    }
  })
}

export const grpcFetchAllUsersInfo = ({
  onFail,
  userId,
  onSuccess,
  token,
  multiuserLoginToken
}) => {
  grpc.unary(Multiuser.GetAllConnectedUsersWithInfo, {
    request: new Empty(),
    metadata: new grpc.Metadata({
      MultiuserLoginToken: multiuserLoginToken,
      MultiuserSessionToken: token
    }),
    host,
    onEnd: response => {
      console.log('got response for all connected users', response)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          const users = decodeUserInfoArrayResponse(response.message.array)
          let isAnotherUser, sessionStartTime
          Object.values(users).forEach(obj => {
            if (obj.id !== userId) {
              isAnotherUser = true
            }
            if (
              !sessionStartTime ||
              moment.utc(obj.startEditingTime).isBefore(sessionStartTime)
            ) {
              sessionStartTime = moment.utc(obj.startEditingTime)
            }
          })
          onSuccess({ users, sessionStartTime, isAnotherUser })
        }
      }
    }
  })
}

export const grpcGetUnsavedChanges = ({
  onFail,
  onSuccess,
  multiuserLoginToken,
  token
}) => {
  grpc.unary(Multiuser.GetNewestDocumentCache, {
    request: new Empty(),
    metadata: new grpc.Metadata({
      MultiuserLoginToken: multiuserLoginToken,
      MultiuserSessionToken: token
    }),
    host,
    onEnd: response => {
      console.log('get newest cache response', response)
      if (response.status !== grpc.Code.OK) {
        if (onSuccess) {
          onSuccess(null)
        }
      } else {
        const { array } = response.message
        let unsavedData
        if (array[2]) {
          console.log('get newest form cache response', array)
          const data = JSON.parse(array[2])
          const type = array[4] || 0
          const wasNotSaved = type === DocumentCacheType.CHANGES_NOT_SAVED
          if (wasNotSaved) {
            unsavedData = data
          }
        }
        if (onSuccess) {
          onSuccess(unsavedData)
        }
      }
    }
  })
}

export const getCurrentFormState = ({
  onFail,
  onSuccess,
  startEditingTime,
  token
}) => {
  let toRet = {}
  grpc.unary(Multiuser.GetLocksNotSubmitedToCache, {
    request: new Empty(),
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    host,
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        const fieldLocks = response.message.array[0]
        console.log('got current field locks', fieldLocks, token)
        grpc.unary(Multiuser.GetNewestDocumentCache, {
          request: new Empty(),
          metadata: new grpc.Metadata({
            MultiuserSessionToken: token
          }),
          host,
          onEnd: response => {
            console.log('got current form state', response, token)
            if (response.status !== grpc.Code.OK) {
              if (onFail) {
                onFail(response)
              }
            } else {
              const { array } = response.message
              if (array[2]) {
                const data = JSON.parse(array[2])
                const submitTime = moment.unix(array[3][0])
                const wasNotSaved =
                  array[4] === DocumentCacheType.CHANGES_NOT_SAVED
                let isValid = true
                if (wasNotSaved) {
                  isValid = false
                }
                if (startEditingTime && submitTime) {
                  if (
                    moment
                      .utc(submitTime)
                      .isBefore(moment.utc(startEditingTime))
                  ) {
                    isValid = false
                  }
                }
                if (isValid) {
                  toRet = { ...toRet, ...data }
                }
              }
              fieldLocks.forEach(lock => {
                const fieldId = lock[3]
                const fieldValue = lock[4] && JSON.parse(lock[4])
                const lockCreatedTime = moment.unix(lock[8][0])
                let isValid = true

                if (startEditingTime && lockCreatedTime) {
                  if (
                    moment
                      .utc(lockCreatedTime)
                      .isBefore(moment.utc(startEditingTime))
                  ) {
                    isValid = false
                  }
                }
                if (isValid && lock[4]) {
                  toRet[fieldId] = fieldValue
                }
              })
              if (onSuccess) {
                onSuccess(toRet)
              }
            }
          }
        })
      }
    }
  })
}

export const grpcReportSFSaveResult = ({
  onFail,
  onSuccess,
  result,
  type,
  token
}) => {
  const request = new SaveToSFCompletedReport()
  request.setType(type)
  request.setResult(result)
  return grpc.unary(MultiuserSF.ReportSaveToSFCompleted, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess(response)
        }
      }
    }
  })
}

export const grpGetLockedFieldsForForm = ({ onFail, onSuccess, token }) => {
  return grpc.unary(Multiuser.GetLockedFields, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        const { array } = response.message
        if (onSuccess) {
          onSuccess(
            array[0].map(lock => ({
              fieldId: lock[3],
              lockId: lock[1],
              lockedBy: lock[2]
            }))
          )
        }
      }
    }
  })
}

export const grpcUpdateUserInfo = ({
  formId,
  userInfo,
  onFail,
  onSuccess,
  token
}) => {
  const request = new UserSessionRequest()
  request.setRealmpath(formId)
  request.setUsername(userInfo.name)
  request.setUserdata(JSON.stringify(userInfo))
  return grpc.unary(Multiuser.UpdateUserInfo, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess()
        }
      }
    }
  })
}

export const grpcListenForUserInfoUpdated = ({ onEventRecieved, token }) => {
  return grpc.invoke(Multiuser.UserUpdatedInfoAboutSelf, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        onEventRecieved({
          userId: array[0],
          info: JSON.parse(array[1])
        })
      }
    }
  })
}

export const grpcGetFieldHistory = ({ fieldId, onSuccess, onFail, token }) => {
  const request = new Field()
  request.setFieldid(fieldId)
  return grpc.unary(Multiuser.GetLocksForField, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          const savedValues = response.message.array[0]
          onSuccess(
            savedValues.map(array => ({
              date: moment.unix(array[8][0]),
              value: array[4] && JSON.parse(array[4])
            }))
          )
        }
      }
    }
  })
}

export const grpcRequestSFSave = ({ onFail, onSuccess, type, token }) => {
  const request = new RequestSFSaveMessage()
  request.setType(type)
  return grpc.unary(MultiuserSF.RequestSFSave, {
    request,
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess(response)
        }
      }
    }
  })
}

export const grpcListenForSFSaveRequest = ({ onEventRecieved, token }) => {
  return grpc.invoke(MultiuserSF.UserRequestedSFSave, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      console.log('save request message', response)
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        const userRequesting = response.array[0]
        const requestType = response.array[2]
        const status = response.array[1]
        let canSave = true
        if (status === RequestStatus.BLOCKED) {
          canSave = false
        }
        onEventRecieved({
          canSave,
          requestType,
          userRequesting
        })
      }
    }
  })
}

export const grpcListenForSFSaveResult = ({ onEventRecieved, token }) => {
  return grpc.invoke(MultiuserSF.UserCompletedSaveToSF, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      console.log('save result form server', response)
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        const userSaving = response.array[0]
        const requestType = response.array[2]
        const status = response.array[1]
        let success = true
        if (!status || status === RequestStatus.BLOCKED) {
          success = false
        }
        onEventRecieved({
          success,
          requestType,
          userSaving
        })
      }
    }
  })
}

export const getNewestCacheOfForm = ({ onFail, onSuccess, token }) => {
  grpc.unary(Multiuser.GetNewestDocumentCache, {
    request: new Empty(),
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    host,
    onEnd: response => {
      console.log('got current form state', response)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message.array)
        }
      }
    }
  })
}

export const moveMouseCursor = ({
  x,
  y,
  xPercent,
  yPercent,
  onFail,
  onSuccess,
  token
}) => {
  const cursorEvent = new CursorEvent()
  cursorEvent.setEventvalue(
    JSON.stringify({
      x,
      y,
      xPercent,
      yPercent
    })
  )
  grpc.unary(Multiuser.SendCursorEvent, {
    request: cursorEvent,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    host,
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message.array)
        }
      }
    }
  })
}

export const grpcListenForMouseCursorEvent = ({ onEventRecieved, token }) => {
  return grpc.invoke(Multiuser.UserSentCursorEvent, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        const userId = response.array[1]
        const coordinates = JSON.parse(response.array[0])
        onEventRecieved({ userId, coordinates })
      }
    }
  })
}

export const grpcListenForStarEditingFormEvent = ({
  onEventRecieved,
  token
}) => {
  return grpc.invoke(Multiuser.UserHasLoggedInToRealm, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onMessage: response => {
      const userResponse = response.array[1]
      const colorIndex = response.array[4] || 0
      if (onEventRecieved && userResponse) {
        onEventRecieved({
          ...JSON.parse(userResponse),
          color: multiuserColors[colorIndex]
        })
      }
    }
  })
}

export const grpcEndEditingForm = ({ onFail, onSuccess, token }) => {
  grpc.unary(Multiuser.LogOut, {
    request: new Empty(),
    host,
    metadata: new grpc.Metadata({
      MultiuserSessionToken: token
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const grpcStartSession = ({
  formId,
  userId,
  userInfo,
  onFail,
  onSuccess,
  mode,
  multiuserLoginToken
}) => {
  const request = new UserSessionRequest()
  request.setRealmpath(formId)
  request.setUserdata(JSON.stringify(userInfo))
  request.setUsername(userInfo.name)
  request.setUserid(userId)
  request.setToken(multiuserLoginToken)
  request.setMode(mode || 'Testing')
  console.log('start editing form', request)
  grpc.unary(Multiuser.StartSession, {
    request,
    host,
    metadata: new grpc.Metadata({}),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
          if (response.trailers.has('Exception')) {
            console.log('exception caught', response.trailers.get('Exception'))
          }
        }
      } else {
        if (onSuccess) {
          onSuccess({
            sessionToken: response.message.array[0],
            colorIndex: response.message.array[1]
              ? response.message.array[1]
              : 0
          })
        }
      }
    }
  })
}
