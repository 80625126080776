import React, { useLayoutEffect, useState } from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { Grid, Icon } from '@material-ui/core'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import { Trans } from '@lingui/macro'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important'
    }
  },
  card: {
    backgroundColor: '#fddc6c',
    width: '100%'
  },
  typography: {
    fontWeight: 'bold'
  },
  actionRoot: {
    padding: '8px 8px 8px 16px'
  },
  icons: {
    marginLeft: 'auto'
  },
  expand: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(90deg)'
  },
  collapse: {
    padding: 16
  },
  checkIcon: {
    fontSize: 20,
    color: '#b3b3b3',
    paddingRight: 4
  },
  button: {
    padding: 0,
    textTransform: 'none'
  }
}))

const AllocationComitteQuestionsCard = passedProps => {
  const {
    handleComment,
    handleSaveComment,
    handleSaveQuestion,
    toggleAll,
    forceUpdate,
    editable,
    user,
    index,
    caseId,
    question,
    comments
  } = passedProps
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)
  const [questionAdded, setQuestionAdded] = useState(!editable)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  useLayoutEffect(() => {
    if (expanded !== toggleAll) {
      setExpanded(toggleAll)
    }
  }, [forceUpdate])

  return (
    <div style={{ width: 'auto', marginLeft: 30, marginRight: 30 }}>
      <Paper square variant='outlined'>
        <CardActions
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <div
            style={{
              flexGrow: 6,
              width: '300px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              overflowWrap: 'break-word'
            }}
          >
            <Typography
              variant='subtitle2'
              align='left'
              style={{ fontSize: 10, wordBreak: 'break-word' }}
            >
              {user}
            </Typography>

            {editable ? (
              <Grid container justify='flex-start' alignItems='center'>
                <FormikTextField
                  align='left'
                  placeholder='Add new question'
                  name={`questions.${[index]}.question`}
                  variant='outlined'
                  fullWidth
                />
              </Grid>
            ) : (
              <Typography
                variant='subtitle2'
                align='left'
                style={{ overflowWrap: 'break-word' }}
              >
                {question}
              </Typography>
            )}
          </div>
          <div
            style={{
              minWidth: 215,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            {!editable ? (
              <Button
                variant='contained'
                color='primary'
                disabled={!caseId}
                onClick={() => {
                  setExpanded(true)
                  handleComment()
                }}
              >
                <Typography>
                  <Trans>Add comment</Trans>
                </Typography>
              </Button>
            ) : (
              <Button
                style={{ marginLeft: 5 }}
                variant='contained'
                color='primary'
                disabled={questionAdded}
                onClick={e => {
                  setQuestionAdded(true)
                  handleSaveQuestion(index).then(
                    result => {},
                    reject => {
                      setQuestionAdded(false)
                    }
                  )
                }}
              >
                <Trans>Save</Trans>
              </Button>
            )}
            <Grid
              style={{ width: '70px', marginLeft: 20 }}
              container
              direction='row'
              justify='center'
              alignItems='center'
            >
              <Typography style={{ color: '#5DBD94', fontSize: 24 }}>
                {comments.length}
              </Typography>
              <IconButton
                aria-label='Show more'
                edge='start'
                onClick={handleExpandClick}
                className={classnames(classes.expand, {
                  [classes.expandOpen]: expanded
                })}
              >
                <Icon style={{ fontSize: 36 }}>play_arrow</Icon>
              </IconButton>
            </Grid>
          </div>
        </CardActions>
      </Paper>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        {comments.map((commentObj, cIndex) => {
          return (
            <CommentCard
              {...commentObj}
              cIndex={cIndex}
              handleSaveComment={handleSaveComment}
              key={index}
              index={index}
            />
          )
        })}
      </Collapse>
    </div>
  )
}

const CommentCard = ({
  comment,
  user,
  editable,
  cIndex,
  handleSaveComment,
  index
}) => {
  const [added, setAdded] = useState(!editable)

  return (
    <div
      align='left'
      key={cIndex}
      style={{
        width: 'auto',
        marginLeft: 50
      }}
    >
      <Paper variant='outlined' square style={{ padding: 5 }}>
        <Typography align='left' style={{ fontSize: 10 }}>
          {user}
        </Typography>

        {!editable ? (
          <div style={{ width: '100%' }}>
            <Typography
              gutterBottom
              align='left'
              style={{ padding: 5, wordWrap: 'break-word' }}
            >
              {comment}
            </Typography>
          </div>
        ) : (
          <Grid container alignItems='center'>
            <Grid item style={{ flexGrow: 1 }}>
              <FormikTextField
                placeholder='Add new comment'
                name={`questions.${[index]}.comments.${[cIndex]}.comment`}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item style={{ width: 70 }}>
              <Button
                style={{ margin: 5 }}
                variant='contained'
                color='primary'
                disabled={added}
                onClick={e => {
                  setAdded(true)
                  handleSaveComment(index, cIndex).then(
                    result => {},
                    reject => {
                      setAdded(false)
                    }
                  )
                }}
              >
                <Trans>Save</Trans>
              </Button>
            </Grid>
          </Grid>
        )}
      </Paper>
    </div>
  )
}

export default AllocationComitteQuestionsCard
