import { Trans } from '@lingui/macro'
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Grid
} from '@material-ui/core'
import React from 'react'
import { Field } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormConnectToObject,
  handleSelectActions,
  ConfigureSelectActions
} from './Common'
import { FormErrorLabel } from './FormErrorLabel'
import { makeStyles } from '@material-ui/styles'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'
import { unified } from 'unified'
import ReactHtmlParser from 'react-html-parser'
import { commitChangeToMultipleFields } from '../multiuser/grpcMultiuserEdit'
import { PreviouslySavedValuesPanel } from '../GroupElement'

const useStyles = makeStyles(theme => ({
  root: {
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    }
  }
}))

export const formBooleanValueToText = (value, question) => {
  const toRet = value ? <Trans>Yes</Trans> : <Trans>No</Trans>
  return {
    en: toRet,
    fr: toRet
  }
}

export const FormBooleanPrint = ({
  id,
  value,
  disabled,
  typeProps,
  langFR,
  titleFR,
  titleEN,
  labelAsMarkdown,
  ...props
}) => {
  let title = langFR ? titleFR : titleEN
  const classes = useStyles()
  if (labelAsMarkdown) {
    title = unified()
      .use(remarkParse)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(rehypeStringify, { allowDangerousHtml: true })
      .processSync(title)
      .toString()
    title = <div className={classes.root}>{ReactHtmlParser(title)}</div>
  }

  return (
    <FormControlLabel
      control={<Checkbox disabled checked={Boolean(value)} />}
      label={title}
    />
  )
}

export const formBooleanParseValueToCompare = value => Boolean(value)

export const FormBoolean = ({
  id,
  useMultiuser,
  muBag,
  disabled,
  typeProps,
  title,
  labelAsMarkdown,
  ...props
}) => {
  const user = useSelector(state => state.user)
  const { fieldType, required, selectActions = [] } = typeProps
  const classes = useStyles()

  if (labelAsMarkdown) {
    title = unified()
      .use(remarkParse)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(rehypeStringify, { allowDangerousHtml: true })
      .processSync(title)
      .toString()
    title = <div className={classes.root}>{ReactHtmlParser(title)}</div>
  }

  return (
    <Field name={id}>
      {({ field, form: { setFieldValue, setValues, values }, meta }) => {
        return (
          <>
            <Grid container wrap='nowrap'>
              <FormControlLabel
                control={
                  fieldType === 'switch' ? (
                    <Switch
                      disabled={disabled}
                      {...field}
                      checked={Boolean(field.value)}
                      onChange={e => {
                        const toSet = { ...values }
                        toSet[id] = Boolean(e.target.checked)
                        const modified = handleSelectActions({
                          toSet,
                          checked: e.target.checked,
                          selectActions
                        })
                        setValues(toSet)
                        if (useMultiuser) {
                          commitChangeToMultipleFields({
                            array: [...modified, id].map(id => [id, toSet[id]]),
                            ...muBag
                          })
                        }
                      }}
                    />
                  ) : (
                    <Checkbox
                      disabled={disabled}
                      {...field}
                      checked={Boolean(field.value)}
                      onChange={e => {
                        const toSet = { ...values }
                        toSet[id] = e.target.checked
                        const modified = handleSelectActions({
                          toSet,
                          checked: e.target.checked,
                          selectActions
                        })
                        setValues(toSet)
                        if (useMultiuser) {
                          commitChangeToMultipleFields({
                            array: [...modified, id].map(id => [id, toSet[id]]),
                            ...muBag
                          })
                        }
                      }}
                    />
                  )
                }
                label={title}
              />
              <PreviouslySavedValuesPanel
                disabled={disabled}
                muBag={muBag}
                fieldId={id}
                type={props.elementType}
                useMultiuser={useMultiuser}
              />
            </Grid>

            <FormErrorLabel
              required={required}
              id={id}
              error={meta.error && meta.touched}
            />
          </>
        )
      }}
    </Field>
  )
}

export const FormEditorBoolean = ({
  editMode,
  depth,
  id,
  french,
  typeProps = {},
  injectable,
  ...props
}) => {
  const { fieldType, required, selectActions = [] } = typeProps

  const dispatch = useDispatch()
  if (!editMode) {
    return (
      <FormBoolean {...props} id={id} langFR={french} typeProps={typeProps} />
    )
  }

  return (
    <div style={{ margin: 10 }}>
      <FormControl>
        <FormLabel component='legend'>
          <Trans>List type</Trans>
        </FormLabel>
        <RadioGroup
          defaultValue='singleselect'
          value={fieldType}
          onChange={e => {
            const toSet = { ...typeProps }
            toSet.fieldType = e.currentTarget.value
            dispatch({
              type: 'FIELD',
              injectable,
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        >
          <FormControlLabel
            value='checkbox'
            control={<Radio />}
            label={<Trans>Checkbox</Trans>}
          />
          <FormControlLabel
            value='switch'
            control={<Radio />}
            label={<Trans>Switch</Trans>}
          />
        </RadioGroup>
      </FormControl>

      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(required)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.required = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Required</Trans>}
        />
      </div>

      <FormConnectToObject
        injectable={injectable}
        typeProps={typeProps}
        depth={depth}
        validTypes={['boolean']}
      />

      <div style={{ padding: 5 }}>
        <ConfigureSelectActions
          depth={depth}
          typeProps={typeProps}
          selectActions={selectActions}
          french={french}
          id={id}
        />
      </div>
    </div>
  )
}
