import { DialogTitle, Grid, Icon, IconButton } from '@material-ui/core'
import React from 'react'

export const DialogTitleWithIconClose = ({ handleClose, label, disabled }) => {
  return (
    <DialogTitle>
      <Grid
        container
        direction='row'
        alignItems='center'
        justify='space-between'
      >
        {label}
        <IconButton onClick={handleClose} disabled={disabled}>
          <Icon>close</Icon>
        </IconButton>
      </Grid>
    </DialogTitle>
  )
}
