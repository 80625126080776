/**
 * Created by Michał Stawski on 12.01.2021.
 */
import React from 'react'
import { Grid } from '@material-ui/core'
import Scrollbar from 'react-perfect-scrollbar'

export const TwoSidedScreen = ({ left, right, maxHeight, ...props }) => {
  return (
    <Grid
      container
      spacing={0}
      justify='space-evenly'
      style={{ flexGrow: 1 }}
      className='two-side-scroll-container'
    >
      <Grid item xs={6}>
        <Scrollbar
          className='scrollable-content'
          style={{ height: maxHeight, flex: 1 }}
          options={{ minScrollbarLength: 4, scrollYMarginOffset: 8 }}
        >
          {left}
        </Scrollbar>
      </Grid>
      <Grid item xs={6}>
        <Scrollbar
          className='scrollable-content '
          style={{ height: maxHeight, flex: 1 }}
          options={{ minScrollbarLength: 4, scrollYMarginOffset: 8 }}
        >
          {right}
        </Scrollbar>
      </Grid>
    </Grid>
  )
}
