/**
 * @fileoverview gRPC-Web generated client stub for Mo_multiuser
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.Mo_multiuser = require('./Multiuser_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Mo_multiuser.MultiuserClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Mo_multiuser.MultiuserPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.UserInfo>}
 */
const methodDescriptor_Multiuser_UserHasLoggedInToRealm = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserHasLoggedInToRealm',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.UserInfo,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserInfo.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserInfo>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userHasLoggedInToRealm =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserHasLoggedInToRealm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserHasLoggedInToRealm);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserInfo>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userHasLoggedInToRealm =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserHasLoggedInToRealm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserHasLoggedInToRealm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.UserInfo>}
 */
const methodDescriptor_Multiuser_UserHasLoggedOutFromRealm = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserHasLoggedOutFromRealm',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.UserInfo,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserInfo.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserInfo>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userHasLoggedOutFromRealm =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserHasLoggedOutFromRealm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserHasLoggedOutFromRealm);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserInfo>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userHasLoggedOutFromRealm =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserHasLoggedOutFromRealm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserHasLoggedOutFromRealm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.UserInfo>}
 */
const methodDescriptor_Multiuser_UserUpdatedInfoAboutSelf = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserUpdatedInfoAboutSelf',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.UserInfo,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserInfo.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserInfo>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userUpdatedInfoAboutSelf =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserUpdatedInfoAboutSelf',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserUpdatedInfoAboutSelf);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserInfo>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userUpdatedInfoAboutSelf =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserUpdatedInfoAboutSelf',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserUpdatedInfoAboutSelf);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.Realm,
 *   !proto.Mo_multiuser.RealmSessionInfoEvent>}
 */
const methodDescriptor_Multiuser_UserSessionEvent = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserSessionEvent',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.Realm,
  proto.Mo_multiuser.RealmSessionInfoEvent,
  /**
   * @param {!proto.Mo_multiuser.Realm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.RealmSessionInfoEvent.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.Realm} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.RealmSessionInfoEvent>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userSessionEvent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSessionEvent',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSessionEvent);
};


/**
 * @param {!proto.Mo_multiuser.Realm} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.RealmSessionInfoEvent>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userSessionEvent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSessionEvent',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSessionEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.FieldLockOperation>}
 */
const methodDescriptor_Multiuser_UserChangedLockFieldStatus = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserChangedLockFieldStatus',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.FieldLockOperation,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FieldLockOperation.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FieldLockOperation>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userChangedLockFieldStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserChangedLockFieldStatus',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserChangedLockFieldStatus);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FieldLockOperation>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userChangedLockFieldStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserChangedLockFieldStatus',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserChangedLockFieldStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.LockID>}
 */
const methodDescriptor_Multiuser_UserCommitedFieldAsynchronously = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserCommitedFieldAsynchronously',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.LockID,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LockID.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LockID>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userCommitedFieldAsynchronously =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserCommitedFieldAsynchronously',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserCommitedFieldAsynchronously);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LockID>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userCommitedFieldAsynchronously =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserCommitedFieldAsynchronously',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserCommitedFieldAsynchronously);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.DocumentCache>}
 */
const methodDescriptor_Multiuser_UserSubmitedDocumentCache = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserSubmitedDocumentCache',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.DocumentCache,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.DocumentCache.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.DocumentCache>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userSubmitedDocumentCache =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSubmitedDocumentCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSubmitedDocumentCache);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.DocumentCache>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userSubmitedDocumentCache =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSubmitedDocumentCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSubmitedDocumentCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.UserID>}
 */
const methodDescriptor_Multiuser_ServerRequestSubmitCache = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/ServerRequestSubmitCache',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.UserID,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserID.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserID>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.serverRequestSubmitCache =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/ServerRequestSubmitCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ServerRequestSubmitCache);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserID>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.serverRequestSubmitCache =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/ServerRequestSubmitCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ServerRequestSubmitCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.RealmMetadata>}
 */
const methodDescriptor_Multiuser_UserChangedRealmMetadata = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserChangedRealmMetadata',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.RealmMetadata,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.RealmMetadata.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.RealmMetadata>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userChangedRealmMetadata =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserChangedRealmMetadata',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserChangedRealmMetadata);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.RealmMetadata>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userChangedRealmMetadata =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserChangedRealmMetadata',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserChangedRealmMetadata);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.CursorEventReceive>}
 */
const methodDescriptor_Multiuser_UserSentCursorEvent = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserSentCursorEvent',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.CursorEventReceive,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.CursorEventReceive.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.CursorEventReceive>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userSentCursorEvent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSentCursorEvent',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSentCursorEvent);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.CursorEventReceive>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userSentCursorEvent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSentCursorEvent',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSentCursorEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.FieldCommentOperation>}
 */
const methodDescriptor_Multiuser_UserChangeFieldComment = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserChangeFieldComment',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.FieldCommentOperation,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FieldCommentOperation.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FieldCommentOperation>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userChangeFieldComment =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserChangeFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserChangeFieldComment);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FieldCommentOperation>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userChangeFieldComment =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserChangeFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserChangeFieldComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.ChatMessage>}
 */
const methodDescriptor_Multiuser_UserSentChatMessage = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserSentChatMessage',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.ChatMessage,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.ChatMessage.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.ChatMessage>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userSentChatMessage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSentChatMessage',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSentChatMessage);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.ChatMessage>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userSentChatMessage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSentChatMessage',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSentChatMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.User,
 *   !proto.Mo_multiuser.UserInfo>}
 */
const methodDescriptor_Multiuser_GetUserInfo = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetUserInfo',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.User,
  proto.Mo_multiuser.UserInfo,
  /**
   * @param {!proto.Mo_multiuser.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserInfo.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UserInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getUserInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUserInfo',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUserInfo,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.User} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UserInfo>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getUserInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUserInfo',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUserInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.UsersCount>}
 */
const methodDescriptor_Multiuser_GetUsersCount = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetUsersCount',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.UsersCount,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UsersCount.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UsersCount)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UsersCount>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getUsersCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUsersCount',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUsersCount,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UsersCount>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getUsersCount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUsersCount',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUsersCount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.UsersList>}
 */
const methodDescriptor_Multiuser_GetAllConnectedUsers = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetAllConnectedUsers',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.UsersList,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UsersList.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UsersList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UsersList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getAllConnectedUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetAllConnectedUsers',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetAllConnectedUsers,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UsersList>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getAllConnectedUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetAllConnectedUsers',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetAllConnectedUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.UserInfoList>}
 */
const methodDescriptor_Multiuser_GetAllConnectedUsersWithInfo = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetAllConnectedUsersWithInfo',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.UserInfoList,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserInfoList.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UserInfoList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserInfoList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getAllConnectedUsersWithInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetAllConnectedUsersWithInfo',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetAllConnectedUsersWithInfo,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UserInfoList>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getAllConnectedUsersWithInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetAllConnectedUsersWithInfo',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetAllConnectedUsersWithInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.Realm,
 *   !proto.Mo_multiuser.IsTrue>}
 */
const methodDescriptor_Multiuser_IsRealmInited = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/IsRealmInited',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.Realm,
  proto.Mo_multiuser.IsTrue,
  /**
   * @param {!proto.Mo_multiuser.Realm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.IsTrue.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.Realm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.IsTrue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.IsTrue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.isRealmInited =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/IsRealmInited',
      request,
      metadata || {},
      methodDescriptor_Multiuser_IsRealmInited,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.Realm} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.IsTrue>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.isRealmInited =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/IsRealmInited',
      request,
      metadata || {},
      methodDescriptor_Multiuser_IsRealmInited);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.RealmMetadata,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_SetRealmMetadata = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/SetRealmMetadata',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.RealmMetadata,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.RealmMetadata} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.RealmMetadata} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.setRealmMetadata =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SetRealmMetadata',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SetRealmMetadata,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.RealmMetadata} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.setRealmMetadata =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SetRealmMetadata',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SetRealmMetadata);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.RealmMetadata>}
 */
const methodDescriptor_Multiuser_GetRealmMetadata = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetRealmMetadata',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.RealmMetadata,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.RealmMetadata.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.RealmMetadata)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.RealmMetadata>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getRealmMetadata =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetRealmMetadata',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetRealmMetadata,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.RealmMetadata>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getRealmMetadata =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetRealmMetadata',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetRealmMetadata);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.RealmInitRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_InitRealm = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/InitRealm',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.RealmInitRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.RealmInitRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.RealmInitRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.initRealm =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/InitRealm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_InitRealm,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.RealmInitRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.initRealm =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/InitRealm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_InitRealm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.UserSessionRequest,
 *   !proto.Mo_multiuser.SessionStartResponde>}
 */
const methodDescriptor_Multiuser_LogIn = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/LogIn',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.UserSessionRequest,
  proto.Mo_multiuser.SessionStartResponde,
  /**
   * @param {!proto.Mo_multiuser.UserSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.SessionStartResponde.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.UserSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.SessionStartResponde)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.SessionStartResponde>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.logIn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/LogIn',
      request,
      metadata || {},
      methodDescriptor_Multiuser_LogIn,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.UserSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.SessionStartResponde>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.logIn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/LogIn',
      request,
      metadata || {},
      methodDescriptor_Multiuser_LogIn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_LogOut = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/LogOut',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.logOut =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/LogOut',
      request,
      metadata || {},
      methodDescriptor_Multiuser_LogOut,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.logOut =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/LogOut',
      request,
      metadata || {},
      methodDescriptor_Multiuser_LogOut);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.UserSessionRequest,
 *   !proto.Mo_multiuser.SessionStartResponde>}
 */
const methodDescriptor_Multiuser_StartSession = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/StartSession',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.UserSessionRequest,
  proto.Mo_multiuser.SessionStartResponde,
  /**
   * @param {!proto.Mo_multiuser.UserSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.SessionStartResponde.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.UserSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.SessionStartResponde)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.SessionStartResponde>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.startSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/StartSession',
      request,
      metadata || {},
      methodDescriptor_Multiuser_StartSession,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.UserSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.SessionStartResponde>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.startSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/StartSession',
      request,
      metadata || {},
      methodDescriptor_Multiuser_StartSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_EndSession = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/EndSession',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.endSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/EndSession',
      request,
      metadata || {},
      methodDescriptor_Multiuser_EndSession,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.endSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/EndSession',
      request,
      metadata || {},
      methodDescriptor_Multiuser_EndSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.UserSessionRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_UpdateUserInfo = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UpdateUserInfo',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.UserSessionRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.UserSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.UserSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.updateUserInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/UpdateUserInfo',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UpdateUserInfo,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.UserSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.updateUserInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/UpdateUserInfo',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UpdateUserInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.Time>}
 */
const methodDescriptor_Multiuser_Ping = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/Ping',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.Time,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.Time.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.Time)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.Time>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.ping =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/Ping',
      request,
      metadata || {},
      methodDescriptor_Multiuser_Ping,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.Time>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.ping =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/Ping',
      request,
      metadata || {},
      methodDescriptor_Multiuser_Ping);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.UserNameRequest,
 *   !proto.Mo_multiuser.UserName>}
 */
const methodDescriptor_Multiuser_GetUserNameByID = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetUserNameByID',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.UserNameRequest,
  proto.Mo_multiuser.UserName,
  /**
   * @param {!proto.Mo_multiuser.UserNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserName.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.UserNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UserName)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserName>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getUserNameByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUserNameByID',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUserNameByID,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.UserNameRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UserName>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getUserNameByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUserNameByID',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUserNameByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.Realm,
 *   !proto.Mo_multiuser.RealmSessionsInfos>}
 */
const methodDescriptor_Multiuser_GetCurrentSessions = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetCurrentSessions',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.Realm,
  proto.Mo_multiuser.RealmSessionsInfos,
  /**
   * @param {!proto.Mo_multiuser.Realm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.RealmSessionsInfos.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.Realm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.RealmSessionsInfos)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.RealmSessionsInfos>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getCurrentSessions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetCurrentSessions',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetCurrentSessions,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.Realm} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.RealmSessionsInfos>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getCurrentSessions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetCurrentSessions',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetCurrentSessions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.UserSessionRequest,
 *   !proto.Mo_multiuser.SessionStartResponde>}
 */
const methodDescriptor_Multiuser_StartReadonlySession = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/StartReadonlySession',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.UserSessionRequest,
  proto.Mo_multiuser.SessionStartResponde,
  /**
   * @param {!proto.Mo_multiuser.UserSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.SessionStartResponde.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.UserSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.SessionStartResponde)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.SessionStartResponde>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.startReadonlySession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/StartReadonlySession',
      request,
      metadata || {},
      methodDescriptor_Multiuser_StartReadonlySession,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.UserSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.SessionStartResponde>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.startReadonlySession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/StartReadonlySession',
      request,
      metadata || {},
      methodDescriptor_Multiuser_StartReadonlySession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.Field,
 *   !proto.Mo_multiuser.IsTrue>}
 */
const methodDescriptor_Multiuser_IsFieldLocked = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/IsFieldLocked',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.Field,
  proto.Mo_multiuser.IsTrue,
  /**
   * @param {!proto.Mo_multiuser.Field} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.IsTrue.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.Field} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.IsTrue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.IsTrue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.isFieldLocked =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/IsFieldLocked',
      request,
      metadata || {},
      methodDescriptor_Multiuser_IsFieldLocked,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.Field} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.IsTrue>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.isFieldLocked =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/IsFieldLocked',
      request,
      metadata || {},
      methodDescriptor_Multiuser_IsFieldLocked);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.LockPointer,
 *   !proto.Mo_multiuser.LockStateType>}
 */
const methodDescriptor_Multiuser_IsLockCommited = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/IsLockCommited',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.LockPointer,
  proto.Mo_multiuser.LockStateType,
  /**
   * @param {!proto.Mo_multiuser.LockPointer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LockStateType.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.LockPointer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LockStateType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LockStateType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.isLockCommited =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/IsLockCommited',
      request,
      metadata || {},
      methodDescriptor_Multiuser_IsLockCommited,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.LockPointer} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LockStateType>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.isLockCommited =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/IsLockCommited',
      request,
      metadata || {},
      methodDescriptor_Multiuser_IsLockCommited);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.Field,
 *   !proto.Mo_multiuser.UserID>}
 */
const methodDescriptor_Multiuser_GetUserLockingField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetUserLockingField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.Field,
  proto.Mo_multiuser.UserID,
  /**
   * @param {!proto.Mo_multiuser.Field} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.Field} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UserID)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserID>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getUserLockingField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUserLockingField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUserLockingField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.Field} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UserID>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getUserLockingField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUserLockingField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUserLockingField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.LocksListLite>}
 */
const methodDescriptor_Multiuser_GetIDsOfLocksNotSubmitedToCache = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetIDsOfLocksNotSubmitedToCache',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.LocksListLite,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LocksListLite.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LocksListLite)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LocksListLite>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getIDsOfLocksNotSubmitedToCache =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetIDsOfLocksNotSubmitedToCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetIDsOfLocksNotSubmitedToCache,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LocksListLite>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getIDsOfLocksNotSubmitedToCache =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetIDsOfLocksNotSubmitedToCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetIDsOfLocksNotSubmitedToCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.LocksList>}
 */
const methodDescriptor_Multiuser_GetLocksNotSubmitedToCache = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetLocksNotSubmitedToCache',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.LocksList,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LocksList.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LocksList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LocksList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getLocksNotSubmitedToCache =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetLocksNotSubmitedToCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetLocksNotSubmitedToCache,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LocksList>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getLocksNotSubmitedToCache =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetLocksNotSubmitedToCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetLocksNotSubmitedToCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.Field,
 *   !proto.Mo_multiuser.LocksListLite>}
 */
const methodDescriptor_Multiuser_GetIDsOfLocksForField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetIDsOfLocksForField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.Field,
  proto.Mo_multiuser.LocksListLite,
  /**
   * @param {!proto.Mo_multiuser.Field} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LocksListLite.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.Field} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LocksListLite)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LocksListLite>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getIDsOfLocksForField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetIDsOfLocksForField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetIDsOfLocksForField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.Field} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LocksListLite>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getIDsOfLocksForField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetIDsOfLocksForField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetIDsOfLocksForField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.Field,
 *   !proto.Mo_multiuser.LocksList>}
 */
const methodDescriptor_Multiuser_GetLocksForField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetLocksForField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.Field,
  proto.Mo_multiuser.LocksList,
  /**
   * @param {!proto.Mo_multiuser.Field} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LocksList.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.Field} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LocksList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LocksList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getLocksForField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetLocksForField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetLocksForField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.Field} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LocksList>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getLocksForField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetLocksForField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetLocksForField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.LocksListLite>}
 */
const methodDescriptor_Multiuser_GetIDsOfLockedFields = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetIDsOfLockedFields',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.LocksListLite,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LocksListLite.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LocksListLite)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LocksListLite>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getIDsOfLockedFields =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetIDsOfLockedFields',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetIDsOfLockedFields,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LocksListLite>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getIDsOfLockedFields =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetIDsOfLockedFields',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetIDsOfLockedFields);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.LocksList>}
 */
const methodDescriptor_Multiuser_GetLockedFields = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetLockedFields',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.LocksList,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LocksList.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LocksList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LocksList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getLockedFields =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetLockedFields',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetLockedFields,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LocksList>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getLockedFields =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetLockedFields',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetLockedFields);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.Field,
 *   !proto.Mo_multiuser.LockID>}
 */
const methodDescriptor_Multiuser_LockField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/LockField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.Field,
  proto.Mo_multiuser.LockID,
  /**
   * @param {!proto.Mo_multiuser.Field} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LockID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.Field} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LockID)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LockID>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.lockField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/LockField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_LockField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.Field} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LockID>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.lockField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/LockField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_LockField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldAndLockIDAndContent,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_UpdateLockedFieldValue = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UpdateLockedFieldValue',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldAndLockIDAndContent,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FieldAndLockIDAndContent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldAndLockIDAndContent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.updateLockedFieldValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/UpdateLockedFieldValue',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UpdateLockedFieldValue,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldAndLockIDAndContent} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.updateLockedFieldValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/UpdateLockedFieldValue',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UpdateLockedFieldValue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldAndLockIDAndContent,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_CommitLockedField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/CommitLockedField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldAndLockIDAndContent,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FieldAndLockIDAndContent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldAndLockIDAndContent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.commitLockedField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CommitLockedField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CommitLockedField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldAndLockIDAndContent} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.commitLockedField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CommitLockedField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CommitLockedField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldAndLockID,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_CancelLockedField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/CancelLockedField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldAndLockID,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FieldAndLockID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldAndLockID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.cancelLockedField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CancelLockedField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CancelLockedField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldAndLockID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.cancelLockedField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CancelLockedField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CancelLockedField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldAndLockID,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_ReclaimLockedField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/ReclaimLockedField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldAndLockID,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FieldAndLockID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldAndLockID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.reclaimLockedField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/ReclaimLockedField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ReclaimLockedField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldAndLockID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.reclaimLockedField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/ReclaimLockedField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ReclaimLockedField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldAndFieldValue,
 *   !proto.Mo_multiuser.LockID>}
 */
const methodDescriptor_Multiuser_CommitFieldImmediately = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/CommitFieldImmediately',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldAndFieldValue,
  proto.Mo_multiuser.LockID,
  /**
   * @param {!proto.Mo_multiuser.FieldAndFieldValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LockID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldAndFieldValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LockID)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LockID>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.commitFieldImmediately =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CommitFieldImmediately',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CommitFieldImmediately,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldAndFieldValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LockID>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.commitFieldImmediately =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CommitFieldImmediately',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CommitFieldImmediately);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.AsyncLock,
 *   !proto.Mo_multiuser.LockID>}
 */
const methodDescriptor_Multiuser_CreateAsynchronousLock = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/CreateAsynchronousLock',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.AsyncLock,
  proto.Mo_multiuser.LockID,
  /**
   * @param {!proto.Mo_multiuser.AsyncLock} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LockID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.AsyncLock} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LockID)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LockID>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.createAsynchronousLock =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CreateAsynchronousLock',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CreateAsynchronousLock,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.AsyncLock} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LockID>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.createAsynchronousLock =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CreateAsynchronousLock',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CreateAsynchronousLock);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldsAndValues,
 *   !proto.Mo_multiuser.FieldsAndLocks>}
 */
const methodDescriptor_Multiuser_MassiveCommitFieldsImmediately = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/MassiveCommitFieldsImmediately',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldsAndValues,
  proto.Mo_multiuser.FieldsAndLocks,
  /**
   * @param {!proto.Mo_multiuser.FieldsAndValues} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FieldsAndLocks.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldsAndValues} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.FieldsAndLocks)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FieldsAndLocks>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.massiveCommitFieldsImmediately =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/MassiveCommitFieldsImmediately',
      request,
      metadata || {},
      methodDescriptor_Multiuser_MassiveCommitFieldsImmediately,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldsAndValues} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.FieldsAndLocks>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.massiveCommitFieldsImmediately =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/MassiveCommitFieldsImmediately',
      request,
      metadata || {},
      methodDescriptor_Multiuser_MassiveCommitFieldsImmediately);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.DocumentCacheListLite>}
 */
const methodDescriptor_Multiuser_GetListOfCachedDocumentVersions = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetListOfCachedDocumentVersions',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.DocumentCacheListLite,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.DocumentCacheListLite.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.DocumentCacheListLite)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.DocumentCacheListLite>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getListOfCachedDocumentVersions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetListOfCachedDocumentVersions',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetListOfCachedDocumentVersions,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.DocumentCacheListLite>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getListOfCachedDocumentVersions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetListOfCachedDocumentVersions',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetListOfCachedDocumentVersions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.DocumentCacheList>}
 */
const methodDescriptor_Multiuser_GetCachedDocumentVersions = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetCachedDocumentVersions',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.DocumentCacheList,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.DocumentCacheList.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.DocumentCacheList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.DocumentCacheList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getCachedDocumentVersions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetCachedDocumentVersions',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetCachedDocumentVersions,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.DocumentCacheList>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getCachedDocumentVersions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetCachedDocumentVersions',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetCachedDocumentVersions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.DocumentCacheID,
 *   !proto.Mo_multiuser.DocumentCache>}
 */
const methodDescriptor_Multiuser_GetDocumentCacheByID = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetDocumentCacheByID',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.DocumentCacheID,
  proto.Mo_multiuser.DocumentCache,
  /**
   * @param {!proto.Mo_multiuser.DocumentCacheID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.DocumentCache.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.DocumentCacheID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.DocumentCache)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.DocumentCache>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getDocumentCacheByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetDocumentCacheByID',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetDocumentCacheByID,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.DocumentCacheID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.DocumentCache>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getDocumentCacheByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetDocumentCacheByID',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetDocumentCacheByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.Mo_multiuser.DocumentCache>}
 */
const methodDescriptor_Multiuser_GetNewestDocumentCache = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetNewestDocumentCache',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.Mo_multiuser.DocumentCache,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.DocumentCache.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.DocumentCache)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.DocumentCache>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getNewestDocumentCache =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetNewestDocumentCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetNewestDocumentCache,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.DocumentCache>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getNewestDocumentCache =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetNewestDocumentCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetNewestDocumentCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.DocumentToSubmit,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_SubmitDocumentCache = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/SubmitDocumentCache',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.DocumentToSubmit,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.DocumentToSubmit} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.DocumentToSubmit} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.submitDocumentCache =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SubmitDocumentCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SubmitDocumentCache,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.DocumentToSubmit} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.submitDocumentCache =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SubmitDocumentCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SubmitDocumentCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.CursorEvent,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_SendCursorEvent = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/SendCursorEvent',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.CursorEvent,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.CursorEvent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.CursorEvent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.sendCursorEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SendCursorEvent',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SendCursorEvent,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.CursorEvent} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.sendCursorEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SendCursorEvent',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SendCursorEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldCommentID,
 *   !proto.Mo_multiuser.FieldComment>}
 */
const methodDescriptor_Multiuser_GetFieldComment = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetFieldComment',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldCommentID,
  proto.Mo_multiuser.FieldComment,
  /**
   * @param {!proto.Mo_multiuser.FieldCommentID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FieldComment.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldCommentID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.FieldComment)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FieldComment>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getFieldComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetFieldComment,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldCommentID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.FieldComment>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getFieldComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetFieldComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldCommentID,
 *   !proto.Mo_multiuser.UserID>}
 */
const methodDescriptor_Multiuser_WhoIsLockingFieldComment = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/WhoIsLockingFieldComment',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldCommentID,
  proto.Mo_multiuser.UserID,
  /**
   * @param {!proto.Mo_multiuser.FieldCommentID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldCommentID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UserID)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserID>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.whoIsLockingFieldComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/WhoIsLockingFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_WhoIsLockingFieldComment,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldCommentID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UserID>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.whoIsLockingFieldComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/WhoIsLockingFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_WhoIsLockingFieldComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldCommentToSend,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_LockFieldComment = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/LockFieldComment',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldCommentToSend,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FieldCommentToSend} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldCommentToSend} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.lockFieldComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/LockFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_LockFieldComment,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldCommentToSend} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.lockFieldComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/LockFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_LockFieldComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldCommentToSend,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_UpdateFieldComment = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UpdateFieldComment',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldCommentToSend,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FieldCommentToSend} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldCommentToSend} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.updateFieldComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/UpdateFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UpdateFieldComment,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldCommentToSend} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.updateFieldComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/UpdateFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UpdateFieldComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldCommentToSend,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_CommitFieldComment = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/CommitFieldComment',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldCommentToSend,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FieldCommentToSend} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldCommentToSend} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.commitFieldComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CommitFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CommitFieldComment,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldCommentToSend} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.commitFieldComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CommitFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CommitFieldComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.ChatMessageCollectionRequest,
 *   !proto.Mo_multiuser.ChatMessageCollection>}
 */
const methodDescriptor_Multiuser_GetChatMessages = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetChatMessages',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.ChatMessageCollectionRequest,
  proto.Mo_multiuser.ChatMessageCollection,
  /**
   * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.ChatMessageCollection.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.ChatMessageCollection)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.ChatMessageCollection>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getChatMessages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetChatMessages',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetChatMessages,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.ChatMessageCollection>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getChatMessages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetChatMessages',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetChatMessages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.ChatMessageCollectionRequest,
 *   !proto.Mo_multiuser.MessageCount>}
 */
const methodDescriptor_Multiuser_GetChatMessagesCount = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetChatMessagesCount',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.ChatMessageCollectionRequest,
  proto.Mo_multiuser.MessageCount,
  /**
   * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.MessageCount.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.MessageCount)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.MessageCount>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getChatMessagesCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetChatMessagesCount',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetChatMessagesCount,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.MessageCount>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getChatMessagesCount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetChatMessagesCount',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetChatMessagesCount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.ChatMessageSend,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_SendChatMessage = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/SendChatMessage',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.ChatMessageSend,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.ChatMessageSend} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.ChatMessageSend} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.sendChatMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SendChatMessage',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SendChatMessage,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.ChatMessageSend} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.sendChatMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SendChatMessage',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SendChatMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_ReturnError = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/ReturnError',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.returnError =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/ReturnError',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ReturnError,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.returnError =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/ReturnError',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ReturnError);
};


module.exports = proto.Mo_multiuser;

