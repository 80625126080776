import { authRoles } from 'app/auth/authRoles'
import { EgretLoadable } from 'egret'

const Map = EgretLoadable({
  loader: () => import('./Map')
})
const Rents = EgretLoadable({
  loader: () => import('./Rents')
})
const Rent = EgretLoadable({
  loader: () => import('./Rent')
})

const vivreEnVilleRoutes = [
  {
    path: '/vivre-en-ville/map',
    component: Map,
    auth: authRoles.vivre_en_ville
  },
  {
    path: '/vivre-en-ville/rents',
    component: Rents,
    auth: authRoles.vivre_en_ville
  },
  {
    path: '/vivre-en-ville/rent/:id',
    component: Rent,
    auth: authRoles.vivre_en_ville
  }
]

export default vivreEnVilleRoutes
