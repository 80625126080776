import SFAuthService, { NO_USER } from '../SFAuthService'
import {
  createTranslation,
  getTranslation,
  getTranslations
} from './sfTranslations'
import { SF_API_VERSION } from './sfOpportunity'

export const getActionTemplates = ({
  searchParams = {},
  listPermissions = false,
  scorePermissions = false
} = {}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  let query = conn.sobject('Action_Template__c').find({ ...searchParams })
  if (listPermissions) {
    query = query.select(
      'Id, Name, Title__c, Reason__c, Expected_Outcome__c, Trigger__c'
    )
  }
  if (scorePermissions) {
    query = query.select(
      'Id, Name, Trigger__c, Title__c, Reason__c, Expected_Outcome__c, Description__c'
    )
  }
  return query.then(result => {
    const fetchIDs = []
    const matchObj = {}
    result.forEach(template => {
      if (template.Title__c) {
        fetchIDs.push(template.Title__c)
        matchObj[template.Title__c] = { template: template }
      }
    })
    return conn
      .sobject('SurveyTranslation__c')
      .find({
        Id: { $in: fetchIDs }
      })
      .select('Id')
      .include('Survey_Texts__r')
      .select(
        'Id, Extended__c, Language__c, Survey_Translation__c, Text__c, Tooltip__c'
      )
      .end()
      .then(translations => {
        translations.forEach(trans => {
          matchObj[trans.Id].translation = { ...trans }
        })
        return matchObj
      })
  })
}

export const getActionTemplatesFull = ids => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Action_Template__c')
    .find({
      Id: { $in: ids }
    })
    .then(results => {
      let ids = []
      results = results.filter(t => Boolean(t.Title__c))
      results.forEach(template => {
        ids.push(
          template.Description__c,
          template.Title__c,
          template.Reason__c,
          template.Expected_Outcome__c
        )
      })
      ids = ids.filter(id => id)
      return getTranslations(ids).then(translations => {
        const translationMap = {}
        translations.forEach(trans => {
          translationMap[trans.Id] = trans
        })
        return results.map(template => {
          return {
            ...template,
            Description__c: translationMap[template.Description__c],
            Title__c: translationMap[template.Title__c],
            Reason__c: translationMap[template.Reason__c],
            Expected_Outcome__c: translationMap[template.Expected_Outcome__c]
          }
        })
      })
    })
}

export const getActionTemplate = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Action_Template__c')
    .find({
      Id: id
    })
    .then(result => {
      const template = result[0]
      return Promise.all([
        getTranslation(template.Description__c),
        getTranslation(template.Title__c),
        getTranslation(template.Reason__c),
        getTranslation(template.Expected_Outcome__c)
      ]).then(([description, title, reason, outcome]) => {
        return {
          ...template,
          Description__c: description,
          Title__c: title,
          Reason__c: reason,
          Expected_Outcome__c: outcome
        }
      })
    })
}

export const saveActionTemplate = ({ fields, id }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Action_Template__c').update({
    Id: id,
    ...fields
  })
}

export const describeActionTemplates = records => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  conn
    .sobject('Action_Template__c')
    .describe()
    .then(result => {
      console.log('action templates describe', result)
    })
  conn
    .sobject('SurveyTranslation__c')
    .describe()
    .then(result => {
      console.log('action templates describe trans', result)
    })
  conn.sobject('Case')
    .describe()
    .then(result => {
      console.log('cases describe' , result)
    })
}

export const createSurveyTranslations = records => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost(
    `/services/data/${SF_API_VERSION}/composite/tree/SurveyTranslation__c`,
    {
      records
    }
  )
}

export const createActionTemplates = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Action_Template__c').create(values)
}

export const deleteActionTemplate = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Action_Template__c').destroy(id)
}

export const createActionTemplate = props => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return Promise.all([
    createTranslation({ en: props.name }),
    createTranslation({ en: props.description }),
    createTranslation({}),
    createTranslation({})
  ]).then(
    ([title, description, reason, outcome]) => {
      console.log(title, description, reason, outcome)
      return conn.sobject('Action_Template__c').create({
        Title__c: title,
        Description__c: description,
        Reason__c: reason,
        Expected_Outcome__c: outcome
      })
    },
    reject => {
      console.log(reject)
    }
  )
}
