/**
 * Created by Michał Stawski on 18.08.2020.
 */

import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import React from 'react'

export const FormTitle = ({ title, children }) => (
  <div
    style={{
      margin: '5px 0px',
      borderRadius: 4,
      padding: 15
    }}
  >
    <Typography
      variant='h5'
      style={{
        marginLeft: 35,
        marginTop: 0,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <DoubleArrowIcon style={{ paddingRight: 10 }} />
      <Grid container>
        <Grid item xs>
          {title}
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Typography>
  </div>
)
