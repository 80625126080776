import SFAuthService, { NO_USER } from '../SFAuthService'
import { describe } from './sfSurvey'
import { getUser, getUsersByFlow } from './sfUser'

/**
 * Created by Michał Stawski on 02.10.2020.
 */

const languageMap = {
  en_US: 'English',
  fr: 'French'
}

const mapLanguage = code => languageMap[code]

export const getComments = id => {
  const conn = SFAuthService.getConnection()
  return conn.sobject('CaseComment').find({
    ParentId: id
  })
}

export const addComment = (id, { comment }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('CaseComment').create({
    ParentId: id,
    IsPublished: true,
    CommentBody: comment
    // Type: type
  })
}

export const getCaseTypes = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.describe('Case').then(result => {
    let toRet = []
    result.fields.some(field => {
      const bool = field.name === 'Type'
      if (bool) {
        toRet = field.picklistValues
          .filter(field => field.active)
          .map(field => ({
            label: field.label,
            value: field.value
          }))
      }
      return bool
    })
    return toRet
  })
}

export const getCases = (id, props = {}) => {
  const conn = SFAuthService.getConnection()
  const { searchParams } = props
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  describe('CaseComment')
  return (
    conn
      .sobject('Case')
      .find({
        Associated_Opportunity__c: id,
        ...searchParams
      })
      // .select('*, User.Name')
      .include('CaseComments')
      .select(
        '*, CreatedBy.Id, CreatedBy.LastName, CreatedBy.FirstName, CreatedBy.Name'
      )
      .sort('CreatedDate', 'ASC')
      .end()
      .sort('CreatedDate', 'ASC')
      .autoFetch(true)
      .then(result => {
        const usersIds = []
        result.forEach(caseObj => {
          if (!usersIds.includes(caseObj.CreatedById)) {
            usersIds.push(caseObj.CreatedById)
          }
          if (caseObj.CaseComments) {
            caseObj.CaseComments.records.forEach(commentObj => {
              if (!usersIds.includes(commentObj.CreatedBy.Id)) {
                usersIds.push(commentObj.CreatedBy.Id)
              }
            })
          }
        })
        return getUsersByFlow(usersIds).then(users => {
          const usersMap = {}
          users.forEach(user => {
            usersMap[user.Id] = user
          })
          const toRet = result
          toRet.forEach((caseObj, caseIndex) => {
            if (caseObj.CaseComments) {
              caseObj.CaseComments.records.forEach((commentObj, index) => {
                toRet[caseIndex].CaseComments.records[index].CreatedBy.Name =
                  usersMap[commentObj.CreatedBy.Id]?.Name
              })
            }
          })
          return toRet
        })
      })
  )
}

export const getVote = (oppId, userId, entityType) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  console.log('getVote inside', oppId, userId)
  return conn
    .sobject('External_Approval__c')
    .find({
      Opportunity__c: oppId,
      CreatedById: userId,
      Entity_Type__c: entityType
    })
    .autoFetch(true)
}

export const saveConflictOfInterest = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  if (values.Id) {
    return conn.sobject('External_Approval__c').update(values)
  } else {
    return conn.sobject('External_Approval__c').create(values)
  }
}

export const updateVote = ({ vote, updateId, conflictOfInterest }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  console.log('updateVote', vote, updateId)
  return conn.sobject('External_Approval__c').update({
    Id: updateId,
    Result__c: vote,
    Conflict_of_interest__c: conflictOfInterest
  })
}

export const entityTypes = {
  ALLOCATION_COMMITTEE: 'Allocation Committee',
  AC: 'Allocation Committee',
  SC: 'Selection Committee',
  BOARD: 'Board',
  Board: 'Board',
  CMHC: 'CMHC',
  EXECUTIVE_DIRECTOR: 'Executive Director'
}

export const committeeCaseMapping = {
  Board: 'Approval Process - Board',
  AC: 'Approval Process - AC',
  SC: 'Approval Process - SC',
  CMHC: 'Approval Process - CMHC'
}

export const createVote = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  console.log('createVote', values)
  return conn.sobject('External_Approval__c').create({
    Result__c: values.voteValue,
    Opportunity__c: values.id,
    Approving_User__c: values.userId,
    Entity_Type__c: values.entityType
  })
}

export const HELP_CASE_TYPES = {
  HELP: 'Portal - help',
  FUNDING_UNSURE: 'Funding – Unsure/Other',
  ENVIRONMENTAL_SAT: 'Environmental self-assessment',
  REGISTRATION: 'Registration',
  JOIN_ORGANIZATION: 'Join Organization'
}

export const createCases = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  values.forEach(obj => {
    obj.Status = 'New'
    obj.Origin = 'Portal'
    obj.Language__c = mapLanguage(obj.Language__c)
  })
  return conn.sobject('Case').create(values)
}

export const createCaseByFlow = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const caseToCreate = {
    ContactId: values.contact,
    // ...(values.assignedManager ? { OwnerId: values.assignedManager } : {}),
    ...(values.skipAssigment !== undefined
      ? { Skip_Assignment__c: values.skipAssigment }
      : {}),
    AccountId: values.organization,
    SuppliedCompany: values.name,
    SuppliedName: values.userName,
    Status: 'New',
    Origin: values.origin || 'Portal',
    Type: values.type,
    Subject: values.title,
    OwnerId: values.owner,
    Comments: values.comments,
    Description: values.description,
    Region__c: values.region,
    Language__c: mapLanguage(values.language),
    Associated_Opportunity__c: values.opportunityId,
    Related_Survey__c: values.surveyId
  }
  console.log('cases', caseToCreate)
  return conn.requestPost('/actions/custom/flow/App_Create_Case', {
    inputs: [
      {
        caseToCreate,
        esatCase: values.isESAT
      }
    ]
  })
}

export const createCase = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  console.log('cases', values)
  return conn.sobject('Case').create({
    ContactId: values.contact,
    // ...(values.assignedManager ? { OwnerId: values.assignedManager } : {}),
    ...(values.skipAssigment !== undefined
      ? { Skip_Assignment__c: values.skipAssigment }
      : {}),
    AccountId: values.organization,
    SuppliedCompany: values.name,
    SuppliedName: values.userName,
    Status: 'New',
    OwnerId: values.owner,
    Origin: values.origin || 'Portal',
    Type: values.type,
    Subject: values.title,
    Comments: values.comments,
    Description: values.description,
    Region__c: values.region,
    Language__c: mapLanguage(values.language),
    Associated_Opportunity__c: values.opportunityId,
    Related_Survey__c: values.surveyId
  })
}
