import moment from 'moment'
import SFAuthService, { NO_USER } from '../SFAuthService'

export const INTERNAL_USER = 'INTERNAL_USER'
export const fetchActions = (id, orgId, permissions = {}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const findProps = {
    CreatedById: id,
    Account__c: orgId
  }
  if (id === INTERNAL_USER) {
    delete findProps.CreatedById
  }
  let query = conn.sobject('Action__c').find(findProps)
  if (permissions.SCORE) {
    query = query.select(
      'Id, From_Template__c, Status__c, From_Template__r.Category__c'
    )
  }
  return query
}

export const createAllAvaliableActions = (templates, completionTime, orgId) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Action__c')
    .find({
      Status__c: 'Selected'
    })
    .select('Order__c')
    .then(result => {
      const toCreate = []
      let order = result.length
      templates.forEach(temp => {
        toCreate.push({
          Title__c: temp.name,
          Category__c: temp.category,
          ExpectedOutcome__c: temp.outcome,
          Account__c: orgId,
          Date__c: moment.utc(completionTime).add(temp.timeline, 'days'),
          From_Template__c: temp.templateId,
          Order__c: order,
          Description__c: temp.description,
          Status__c: 'Selected',
          Reason__c: temp.reason
        })
        order++
      })
      return conn.sobject('Action__c').create(toCreate)
    })
}

export const createAction = createData => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  if (!createData.order) {
    return conn
      .sobject('Action__c')
      .find({
        Status__c: createData.column
      })
      .select('Order__c')
      .then(result => {
        return conn.sobject('Action__c').create({
          Title__c: createData.title,
          Account__c: createData.orgId,
          Category__c: createData.category,
          ExpectedOutcome__c: createData.expectedOutcome,
          Date__c: createData.dueDate,
          From_Template__c: createData.fromTemplate,
          Order__c: result.length,
          Description__c: createData.description,
          Status__c: createData.column,
          Reason__c: createData.reason
        })
      })
  }

  return conn.sobject('Action__c').create({
    Title__c: createData.title,
    Account__c: createData.orgId,
    Date__c: createData.dueDate,
    Category__c: createData.category,
    ExpectedOutcome__c: createData.expectedOutcome,
    From_Template__c: createData.fromTemplate,
    Order__c: createData.order,
    Description__c: createData.description,
    Status__c: createData.column,
    Reason__c: createData.reason
  })
}

export const updateActionBug = updateData => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Action__c').update(updateData)
}

export const updateAction = updateData => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const toUpdate = {
    Id: updateData.id,
    Title__c: updateData.title,
    Date__c: updateData.dueDate,
    Order__c: updateData.order,
    Success__c: updateData.success,
    ExpectedOutcome__c: updateData.expectedOutcome,
    ActualOutcome__c: updateData.actualOutcome,
    ReasonOfFail__c: updateData.failReason,
    Description__c: updateData.description,
    Status__c: updateData.column,
    Reason__c: updateData.reason
  }
  if (updateData.category) {
    toUpdate.Category__c = updateData.category
  }
  return conn.sobject('Action__c').update(toUpdate)
}

export const updateActionFromCalendar = updateData => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Action__c').update({
    Id: updateData.id,
    Title__c: updateData.title,
    ExpectedOutcome__c: updateData.expectedOutcome,
    ReasonOfFail__c: updateData.failReason,
    Date__c: updateData.dueDate,
    Description__c: updateData.description,
    Reason__c: updateData.reason
  })
}

export const updateActionsOrder = updateData => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Action__c').update(updateData)
}

export const deleteAction = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Action__c').delete(id)
}
