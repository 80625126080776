import SFAuthService, { NO_USER } from '../SFAuthService'
import { mapSFToForm, reverseMap } from '../sfDataService'
import { t } from '@lingui/macro'
import { SF_API_VERSION } from './sfOpportunity'
import { globalValues } from 'app/utils/GlobalValues'

const mapFields = {
  direction: 'in',
  Id: 'id',
  Type__c: 'type', // ['Final report', 'Interim', 'Phone call'] // Interim should be translate as Progress report
  FGM_Base__Due_Date__c: 'dueDate',
  Survey__c: 'surveyId',
  RecordTypeId: 'recordTypeId',
  RecordType: {
    // only flat object
    fieldName: 'recordType',
    Name: 'name',
    DeveloperName: 'developerName'
  },
  FGM_Base__Request__c: 'opportunity',
  FGM_Base__Type__c: 'scope', // ['Financial', 'Narrative and Financial', 'Narrative']
  FGM_Base__Status__c: 'status', // ['Requested', 'Received', 'Approved', 'Rejected', 'Submitted', 'Scheduled']
  FGM_Base__Submission_Date__c: 'submissionDate',
  FGM_Base__Submitted_By__c: 'submittedBy'
}

export const sanitizeReportType = type => {
  if (type === 'Interim') {
    // TODO handle this better
    return 'Progress report'
  }
  return type
}

const reverseMapFields = reverseMap(mapFields)

export const reportIsEditable = reportStatus => {
  return (
    ['Rejected', 'Requested', 'Scheduled', 'More info required'].indexOf(
      reportStatus
    ) !== -1
  )
}

export const getReports = (props = {}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const {
    searchParams = {},
    selectFields,
    permissions = {
      PAYMENTS: true
    }
  } = props
  let query = conn.sobject('FGM_Base__Grantee_Report__c').find(searchParams)
  if (selectFields) {
    query = query.select(selectFields.join(', '))
  }
  if (permissions.PAYMENTS) {
    query = query.include('FGM_Base__Payments__r').end()
  }
  return query
}

export const getReportsForOpportunity = opportunityId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('FGM_Base__Grantee_Report__c')
    .find({
      FGM_Base__Request__c: opportunityId
    })
    .select(
      'Id, Name, Type__c, RecordTypeId, FGM_Base__Status__c, FGM_Base__Is_Overdue__c, FGM_Base__Due_Date__c'
    )
}

export const getReport = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('FGM_Base__Grantee_Report__c')
    .findOne({
      Id: id
    })
    .select('*, RecordType.Name')
}

export const getReportParsed = id => {
  // console.log('getSurveyParsed', id)
  return getReport(id).then(obj => mapSFToForm(mapFields, obj))
}

export const createReports = array => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const reportRecordTypeId =
    globalValues.reportRecordTypes.Report &&
    globalValues.reportRecordTypes.Report.recordTypeId
  return conn.sobject('FGM_Base__Grantee_Report__c').create(
    array.map(ob => {
      return {
        FGM_Base__Request__c: ob.opportunityId,
        RecordTypeId: ob.recordType || reportRecordTypeId,
        Type__c: ob.reportType || 'Interim',
        FGM_Base__Due_Date__c: ob.dueDate
      }
    })
  )
}

export const createReport = ({
  opportunityId,
  recordType,
  reportType,
  dueDate
}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const reportRecordTypeId =
    globalValues.reportRecordTypes.Report &&
    globalValues.reportRecordTypes.Report.recordTypeId
  return conn.sobject('FGM_Base__Grantee_Report__c').create({
    FGM_Base__Request__c: opportunityId,
    RecordTypeId: recordType || reportRecordTypeId,
    Type__c: reportType || 'Interim',
    FGM_Base__Due_Date__c: dueDate
  })
}

export const clearSchedule = toDelete => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  let request
  if (toDelete.length < 200) {
    request = [
      {
        method: 'DELETE',
        url:
          `/services/data/${SF_API_VERSION}/composite/sobjects?` +
          'ids=' +
          toDelete.join(',') +
          '&allOrNone=true',
        referenceId: 'refDeleteObjects'
      }
    ]
  } else {
    const subArrays = new Array(Math.ceil(toDelete.length / 200))
      .fill()
      .map(_ => toDelete.splice(0, 200))
    request = subArrays.map((sub, index) => {
      return {
        method: 'DELETE',
        url:
          `/services/data/${SF_API_VERSION}/composite/sobjects?` +
          'ids=' +
          sub.join(',') +
          '&allOrNone=true',
        referenceId: 'refDeleteObjects' + index
      }
    })
  }

  return conn.requestPost(`/services/data/${SF_API_VERSION}/composite/`, {
    compositeRequest: request
  })
}

export const saveReportFromFlow = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  } else {
    return conn.requestPost('/actions/custom/flow/App_Update_Grantee_Report', {
      inputs: [
        {
          updateRecord: { ...values }
        }
      ]
    })
  }
}

export const saveReport = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('FGM_Base__Grantee_Report__c').update(values)
}

export const saveReportParsed = values => {
  // console.log('getSurveyParsed', id)(mapSFToForm(reverseMapFields, value))
  return saveReport(mapSFToForm(reverseMapFields, values))
}
