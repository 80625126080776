import { isValidJSON } from 'utils'
import SFAuthService, { NO_USER } from '../SFAuthService'
import { mapSFToForm, reverseMap } from '../sfDataService'

/**
 * Created by Michał Stawski on 19.12.2020.
 */

const mapFields = {
  Name: 'key',
  Id: 'id',
  Value__c: 'value'
}

export const ERROR_NO_CONFIGURATION_SET = 'ERROR_NO_CONFIGURATION_SET'

const reverseMapFields = reverseMap(mapFields)

export const saveConfigurations = toSave => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const toCreate = []
  const toUpdate = []
  toSave.forEach(obj => {
    if (obj.Id) {
      toUpdate.push(obj)
    } else {
      toCreate.push(obj)
    }
  })
  return Promise.all([
    conn.sobject('Configuration__c').create(toCreate),
    conn.requestPost('/actions/custom/flow/App_Update_Configuration', {
      inputs: toUpdate.map(obj => ({ toUpdate: obj }))
    })
  ])
}

export const createConfiguration = createProps => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Configuration__c').create(createProps)
}

export const getConfigurationVariables = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Configuration__c')
    .select('Id, Name, Value__c')
    .autoFetch(true)
}

export const getConfiguration = searchProps => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Configuration__c').find(searchProps)
}

export const getConfigurationVariablesParsed = () => {
  return getConfigurationVariables().then(result => {
    const dictionary = {}
    result
      .map(item => mapSFToForm(mapFields, item))
      .forEach(item => {
        let value = item.value
        if (value && isValidJSON(value)) {
          value = JSON.parse(value)
          value.id = item.id
        }

        if (!dictionary[item.key]) {
          dictionary[item.key] = value
        } else {
          if (Array.isArray(dictionary[item.key])) {
            dictionary[item.key].push(value)
          } else {
            dictionary[item.key] = [value, dictionary[item.key]]
          }
        }
      })
    return dictionary
  })
}

export const satConfigurationVariable = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Configuration__c').update(values)
}

export const getConfigurationVariableParsed = value => {
  return satConfigurationVariable(mapSFToForm(reverseMapFields, value))
}
