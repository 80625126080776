import { Trans, t } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ThemeProvider,
  createMuiTheme
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Formik } from 'formik'
import FormikTextField from 'formik-material-fields/lib/FormikTextField'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { myI18n } from 'translation/I18nConnectedProvider'
import * as yup from 'yup'
import {
  HELP_CASE_TYPES,
  createCase,
  createCaseByFlow
} from '../../services/sfAuth/sfData/sfCase'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3)
  },
  field: {
    color: 'green',
    borderColor: 'green'
  }
}))

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#A43336'
    }
  }
})

const requiredTrans = <Trans>This field is required</Trans>
const validationSchema = yup.object().shape({
  title: yup.string().required(requiredTrans).max(250),
  description: yup.string().required(requiredTrans).max(3000)
})

export default function Inquiry ({
  open,
  handleClose,
  caseType = HELP_CASE_TYPES.HELP,
  additionalValues = {},
  translations = {}
}) {
  const classes = useStyles()
  const location = useLocation()
  const user = useSelector(state => state.user)
  const organization = useSelector(state => state.organization)
  const caseTypes = useSelector(state => state.caseTypes)
  const { enqueueSnackbar } = useSnackbar()
  const {
    titleTranslation,
    topTranslations,
    subjectTranslation,
    describeTranslation
  } = {
    titleTranslation: t`How can we help you?`,
    topTranslations: t`Describe your problem here`,
    subjectTranslation: t`Type of problem`,
    describeTranslation: t`Please, fill additional info to help us address your problem`,
    ...translations
  }

  const submitData = (values, actions) => {
    enqueueSnackbar(<Trans>Submitting Case</Trans>, {
      variant: 'info'
    })
    const data = {
      ...values,
      origin: 'Portal',
      comments:
        `url: ${location.pathname} \n env: v ${process.env.REACT_APP_VERSION}-` +
        `${process.env.REACT_APP_ENVIROMENT}`,
      // type: values.type || caseType,
      type: caseType,
      language: user.language,
      organization: organization.id,
      contact: user.userObject.contactId,
      ...additionalValues
    }
    if (additionalValues.isESAT) {
      createCaseByFlow(data).then(
        flowResult => {
          if (flowResult[0].isSuccess) {
            enqueueSnackbar(<Trans>Submitted Case</Trans>, {
              variant: 'success'
            })
            handleClose()
            actions.setSubmitting(false)
            actions.resetForm({})
          } else {
            console.error('Error submitting case', flowResult[0])
            enqueueSnackbar(<Trans>Error Submitting Case</Trans>, {
              variant: 'error'
            })
            actions.setSubmitting(false)
          }
        },
        reject => {
          console.error('Error submitting case', reject)
          enqueueSnackbar(<Trans>Error Submitting Case</Trans>, {
            variant: 'error'
          })
          actions.setSubmitting(false)
        }
      )
    } else {
      createCase(data)
        .then(r => {
          enqueueSnackbar(<Trans>Submitted Case</Trans>, {
            variant: 'success'
          })
          handleClose()
          actions.setSubmitting(false)
          actions.resetForm({})
        })
        .catch(error => {
          console.error('Error submitting case', error)
          enqueueSnackbar(<Trans>Error Submitting Case</Trans>, {
            variant: 'error'
          })
          actions.setSubmitting(false)
        })
    }
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{ title: '', description: '' }}
      onSubmit={submitData}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        resetForm,
        setFieldValue,
        values
      }) => (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
          <DialogTitle>{myI18n._(titleTranslation)}</DialogTitle>
          <DialogContent>
            <ThemeProvider theme={theme}>
              <DialogContentText>{myI18n._(topTranslations)}</DialogContentText>
              <DialogContentText style={{ marginBottom: 0 }}>
                <b>{myI18n._(subjectTranslation)}</b>
              </DialogContentText>
              <FormikTextField
                margin='normal'
                // color='secondary'
                id='title'
                variant='outlined'
                fullWidth
                label={myI18n._(t`Subject`)}
                name='title'
                type='text'
              />

              <DialogContentText style={{ marginBottom: 0 }}>
                <b>{myI18n._(describeTranslation)}</b>
              </DialogContentText>
              <FormikTextField
                label={myI18n._(t`Description`)}
                id='description'
                margin='normal'
                variant='outlined'
                fullWidth
                multiline
                rows={6}
                name='description'
                type='text'
              />
              <Grid container justify='space-around' style={{ padding: 15 }}>
                <Button
                  variant='contained'
                  disabled={!isValid || isSubmitting || !dirty}
                  color='primary'
                  onClick={handleSubmit}
                >
                  <Trans>Submit</Trans>
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    handleClose()
                    resetForm({})
                  }}
                >
                  <Trans>Cancel</Trans>
                </Button>
              </Grid>
            </ThemeProvider>
          </DialogContent>
        </Dialog>
      )}
    </Formik>
  )
}
