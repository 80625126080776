import { Trans } from '@lingui/macro'
import {
  Avatar,
  Collapse,
  Divider,
  Grid,
  Icon,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { datetimeFormat, timeFormat } from 'app/appSettings'
import { useFormikContext } from 'formik'
import moment from 'moment/moment'
import React, { useState, useEffect } from 'react'
import { useRef } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import ScrollBar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import { FormErrorText } from '../components/FormErrorLabel'
import { sendChatMessage } from './grpcMultiuserEdit'

export default function Chat ({ formId, token }) {
  const user = useSelector(state => state.user)
  const { values } = useFormikContext()
  const [message, setMessage] = useState('')
  const [messagesCount, setmessagesCount] = useState(0)
  const [error, setError] = useState(false)
  const [sending, setSending] = useState(false)
  const [minimized, setMinimized] = useState(true)
  const [unread, setUnread] = useState(0)
  const theme = useTheme()
  const themeColor = theme.palette.primary.main
  const chatMesseges = values?.muInfo.chat || []
  const users = values?.muUsers || {}
  const scrollRef = useRef()

  useEffect(() => {
    if (minimized && chatMesseges.length > 0) {
      setUnread(chatMesseges.length - messagesCount)
    }
    if (!minimized && scrollRef.current) {
      scrollRef.current.scrollToBottom()
    }
    setmessagesCount(chatMesseges.length)
  }, [chatMesseges.length])

  useEffect(() => {
    if (!minimized) {
      setUnread(0)
    }
  }, [minimized])

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 20,
        width: 300,
        zIndex: 200,
        backgroundColor: 'white'
      }}
    >
      <div
        style={{ border: '1px solid rgba(0, 0, 0, 0.4)' }}
        onClick={e => {
          setMinimized(!minimized)
        }}
      >
        <Grid
          container
          style={{ backgroundColor: themeColor }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item container xs style={{ paddingLeft: 10, color: 'white' }}>
            <Typography style={{ fontWeight: '400', fontSize: 16 }}>
              <Trans>Chat</Trans>
            </Typography>
            {minimized && unread !== 0 && (
              <Avatar
                style={{
                  width: 24,
                  height: 24,
                  backgroundColor: '#f5543b',
                  marginLeft: 10,
                  fontSize: 16
                }}
              >
                {unread}
              </Avatar>
            )}
          </Grid>
          <Grid item>
            <IconButton
              onClick={e => {
                setMinimized(!minimized)
              }}
            >
              <Icon>minimize</Icon>
            </IconButton>
          </Grid>
        </Grid>
        <Collapse in={!minimized}>
          <Scrollbars
            ref={scrollRef}
            style={{
              height: 300
              //scrollBehavior: 'smooth',
              //overflowY: 'scroll'
            }}
            onWheel={e => {
              e.stopPropagation()
            }}
            onScroll={e => {
              e.stopPropagation()
            }}
          >
            <Grid container direction='column' style={{ paddingTop: 10 }}>
              {chatMesseges.map((obj, index) => {
                const otherUserMessage = obj.userId !== user.userId
                const color = users[obj.userId]?.color
                const name = users[obj.userId]?.name

                return (
                  <Grid
                    container
                    item
                    xs
                    justifyContent={
                      otherUserMessage ? 'flex-end' : 'flex-start'
                    }
                    style={{ paddingRight: 8, paddingLeft: 8 }}
                    key={index}
                  >
                    <Grid item xs={9}>
                      <Paper
                        style={{
                          padding: 8,
                          border: '1px solid ' + color,
                          whiteSpace: 'pre-wrap',
                          overflowWrap: 'break-word'
                        }}
                      >
                        {obj.text}
                      </Paper>
                      <div
                        style={{
                          fontSize: 9,
                          padding: 3,
                          textAlign: otherUserMessage ? 'right' : 'left'
                        }}
                      >
                        {name +
                          ', ' +
                          moment
                            .utc(obj.recieved)
                            .local()
                            .format(timeFormat)}
                      </div>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Scrollbars>
          <Divider />
          <Grid container wrap='nowrap' style={{ paddingBottom: 10 }}>
            <Grid item xs style={{ paddingLeft: 10, paddingTop: 10 }}>
              <TextField
                variant='filled'
                InputProps={{
                  style: {
                    paddingTop: 10
                  }
                }}
                fullWidth
                multiline
                maxRows={3}
                value={message}
                onClick={e => {
                  e.stopPropagation()
                }}
                onKeyDown={e => {
                  if (
                    e.key === 'Enter' &&
                    !e.shiftKey &&
                    !sending &&
                    message.length !== 0
                  ) {
                    e.preventDefault()
                    setSending(true)
                    sendChatMessage({
                      formId,
                      token,
                      userId: user.userId,
                      message,
                      onSuccess: () => {
                        setSending(false)
                        setError(false)
                        setMessage('')
                      },
                      onFail: () => {
                        setSending(false)
                        setError(true)
                      }
                    })
                  } else if (e.key === 'Enter' && e.shiftKey) {
                    let newMessage = e.target.value
                    setMessage(e.target.value + '\n')
                    e.preventDefault()
                  }
                }}
                onChange={e => {
                  setMessage(e.target.value)
                }}
              />
            </Grid>
            <Grid item>
              <IconButton
                disabled={sending || message.length === 0}
                onClick={e => {
                  e.stopPropagation()
                  setSending(true)
                  sendChatMessage({
                    formId,
                    token,
                    userId: user.userId,
                    message,
                    onSuccess: () => {
                      setSending(false)
                      setError(false)
                      setMessage('')
                    },
                    onFail: () => {
                      setSending(false)
                      setError(true)
                    }
                  })
                }}
              >
                <Icon>send</Icon>
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <span
              style={{
                color: '#f5543b',
                fontSize: '0.75rem',
                marginTop: 3,
                marginLeft: 14,
                textAlign: 'left'
              }}
            >
              <Trans>The message could not be send</Trans>
            </span>
          )}
        </Collapse>
      </div>
    </div>
  )
}
