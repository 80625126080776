import {
  Button,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Paper
} from '@material-ui/core'
import { Trans } from '@lingui/macro'
import moment from 'moment'
import { pmRecommendationTranslations } from '../../services/sfAuth/sfData/sfOpportunity'
import React, { useRef } from 'react'
import Loading from '../../../egret/components/EgretLoadable/Loading'
import {
  convertConfiguration,
  DATA_LIST,
  DATA_ON_BOTTOM,
  DATA_ON_TOP,
  FUND_IMAGE,
  GRID,
  SUBHEADER,
  HEADER,
  PAPER,
  TEXT_DATA,
  TEXT_LIST,
  useViewComponentStyles,
  ARRAY,
  OBJECT_TO_LIST,
  LIST_TO_OBJECTS,
  FILES_LIST,
  formatWithBreaks,
  PAGE_BREAK
} from './opportunityViewComponents'
import ReactToPrint from 'react-to-print'
import { defaultDocTitle } from 'app/appSettings'
import { printStyles } from '../grants/ApplicationsList'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import { CurrencyFormated } from '../common/Formats'

/**
 * Created by Michał Stawski on 31.12.2020.
 */

export const fieldsFromList = (list, paperClass = null, style = {}) => {
  return [
    ...list.map(
      ({
        disabled,
        field,
        gridClassName,
        paperClassName,
        subtitle,
        formatter,
        dataComponent
      }) => {
        return {
          disabled,
          type: DATA_ON_BOTTOM,
          gridClass: gridClassName,
          paperClass: paperClassName || paperClass,
          style: style,
          dataComponent,
          data: opp => {
            // console.log(opp, field)
            const splitField = field.split('.')
            let ret
            if (splitField.length === 1) {
              ret = opp[field]
            } else {
              // todo handle more then one nesting
              ret = opp[splitField[0]][splitField[1]]
            }
            if (formatter && typeof formatter === 'function') {
              ret = formatter(ret)
            }
            return ret
          },
          subtitle
        }
      }
    )
  ]
}

export const defaultConfigurationICCE = ({ classes, files = [], data }) => {
  return {
    fields: [
      {
        type: HEADER,
        text: (
          <span className={classes.mainTitle}>
            <Trans>Application Details</Trans>
          </span>
        )
      },
      {
        type: GRID,
        fields: [
          {
            type: PAPER,
            style: {
              width: '100%',
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 20,
              padding: 15,
              flexWrap: 'nowrap'
            },
            paperClass: classes.titleCardStretch,
            fields: [
              {
                type: GRID,
                justify: 'center',
                fields: [
                  {
                    type: FUND_IMAGE,
                    imageClass: classes.imageLogo,
                    fundTypeName: opp => opp.info.fundTypeName
                  },
                  {
                    type: GRID,
                    direction: 'column',
                    style: {
                      width: '300px',
                      marginLeft: '20px',
                      height: 'auto'
                    },
                    fields: [
                      {
                        type: DATA_ON_TOP,
                        gridClass: classes.nameGrid,
                        data: opp => opp.projectDetails.projectName,
                        subtitle: opp => (
                          <span className={classes.subtitle}>
                            <Trans id={opp.info.fundTypeName} />
                          </span>
                        )
                      },
                      {
                        type: DATA_ON_TOP,
                        style: { width: 150 },
                        data: opp => (
                          <CurrencyFormated
                            value={opp.projectDetails.amountRequested}
                          />
                        ),
                        subtitle: (
                          <span className={classes.subtitle}>
                            <Trans>Amount Requested</Trans>
                          </span>
                        )
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: GRID,
        gridClass: classes.gridContainerStretch,
        justify: 'center',
        fields: [
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'accessibility_new',
            subtitle: <Trans>Distinction of Community</Trans>,
            data: opp => opp.projectDetails.communityDistinction
          },
          {
            type: GRID,
            gridClass: classes.gridContainerStretch,
            justify: 'center',
            style: { paddingLeft: 20, paddingRight: 20 },
            //style: { padding: 10, backgroundColor: 'red' },
            fields: [
              {
                type: DATA_ON_BOTTOM,
                xs: 6,
                paperClass: classes.contactGridItem,
                data: opp => {
                  const contact = opp.contacts.primaryContact
                  if (!contact.FirstName && !contact.LastName) {
                    return null
                  }
                  return (
                    <div>
                      <div>{contact.FirstName + ' ' + contact.LastName}</div>
                      <div>{contact.Email}</div>
                    </div>
                  )
                },
                subtitle: <Trans>Primary contact</Trans>
              },
              {
                type: DATA_ON_BOTTOM,
                xs: 6,
                paperClass: classes.contactGridItem,
                data: opp => {
                  const contact = opp.contacts.accounting
                  if (!contact.FirstName && !contact.LastName) {
                    return null
                  }
                  return (
                    <div>
                      <div>{contact.FirstName + ' ' + contact.LastName}</div>
                      <div>{contact.Email}</div>
                    </div>
                  )
                },
                subtitle: <Trans>Accounting</Trans>
              },
              {
                type: DATA_ON_BOTTOM,
                xs: 6,
                paperClass: classes.contactGridItem,
                data: opp => {
                  const contact = opp.contacts.firstSinger
                  if (!contact || (!contact.FirstName && !contact.LastName)) {
                    return null
                  }
                  return (
                    <div>
                      <div>{contact.FirstName + ' ' + contact.LastName}</div>
                      <div>{contact.Email}</div>
                    </div>
                  )
                },
                subtitle: (
                  <Trans>
                    Authorized Signatory for Contribution Arrangements{' '}
                  </Trans>
                )
              },
              {
                type: DATA_ON_BOTTOM,
                xs: 6,
                paperClass: classes.contactGridItem,
                data: opp => {
                  const contact = opp.contacts.administrative
                  if (!contact.FirstName && !contact.LastName) {
                    return null
                  }
                  return (
                    <div>
                      <div>{contact.FirstName + ' ' + contact.LastName}</div>
                      <div>{contact.Email}</div>
                    </div>
                  )
                },
                subtitle: <Trans>Administrative Contact</Trans>
              }
            ]
          }
        ]
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        data: opp => formatWithBreaks(opp.projectDetails.executiveSummary),
        subtitle: <Trans>Executive Summary</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        data: opp => formatWithBreaks(opp.projectDetails.objectives),
        subtitle: <Trans>Objectives</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        data: opp => formatWithBreaks(opp.projectDetails.description),
        subtitle: <Trans>Project description</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        data: opp => formatWithBreaks(opp.projectDetails.deliverables),
        subtitle: <Trans>Deliverables</Trans>
      },
      {
        type: GRID,
        gridClass: classes.gridContainerStretch,
        justify: 'center',
        fields: [
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'location_city',
            subtitle: (
              <Trans>
                List of Contacts for Participating Indigenous Communities
              </Trans>
            ),
            data: opp => opp.representedOrganization,
            emptyPlaceholder: (
              <Trans>
                There are no additional contacts for Participating Indigenous
                Communities
              </Trans>
            ),
            dataComponent: opp => data => {
              return (
                <Grid container direction='column'>
                  <Grid item style={{ marign: 5 }}>
                    <b>
                      <Trans>Organization name</Trans>
                    </b>
                    {': '}
                    {data.Account_Name__c}
                  </Grid>
                  <Grid item style={{ marign: 5 }}>
                    <b>
                      <Trans>Organization e-mail</Trans>
                    </b>
                    {': '}
                    {data.Account_Email__c}
                  </Grid>
                </Grid>
              )
            }
          }
        ]
      },
      {
        type: HEADER,
        pageBreakBefore: true,
        disabled: opp => Object.keys(opp.budget).length === 0,
        style: { fontSize: 24, fonWeight: 'bold' },
        text: (
          <span className={classes.mainTitle}>
            <Trans>Budget</Trans>
          </span>
        )
      },
      // {
      //   type: GRID,
      //   gridClass: classes.gridContainerStretch,
      //   justify: 'center',
      //   fields: [
      //     {
      //       type: OBJECT_TO_LIST,
      //       obj: opp => {
      //         const toRet = { ...opp.budget }
      //         delete toRet.adminLine
      //         return toRet
      //       },
      //       conf: {
      //         fields: [
      //           {
      //             type: SUBHEADER,
      //             data: item => item.key + '/' + Number(Number(item.key) + 1),
      //             style: { textAlign: 'center' }
      //           },
      //           {
      //             type: DATA_LIST,
      //             paperClass: classes.dataList,
      //             data: item => item.data,
      //             bulletIcon: 'paid',
      //             dataComponent: opp => data => {
      //               return (
      //                 <Grid container direction='row'>
      //                   <Grid item xs={3}>
      //                     <div style={{ fontWeight: 'bold', fontSize: 9 }}>
      //                       <Trans>Category</Trans>
      //                     </div>
      //                     {data.category}
      //                   </Grid>
      //                   <Grid item xs={3}>
      //                     <div style={{ fontWeight: 'bold', fontSize: 9 }}>
      //                       <Trans>Summary</Trans>
      //                     </div>
      //                     {data.description}
      //                   </Grid>
      //                   <Grid item xs={3}>
      //                     <div style={{ fontWeight: 'bold', fontSize: 9 }}>
      //                       <Trans>Total requested to ICCE</Trans>
      //                     </div>
      //                     <CurrencyFormated value={data.totalCost} />
      //                   </Grid>
      //                   <Grid item xs={3}>
      //                     <div style={{ fontWeight: 'bold', fontSize: 9 }}>
      //                       <Trans>Other donors</Trans>
      //                     </div>
      //                     <CurrencyFormated value={data.otherDonors} />
      //                   </Grid>
      //                 </Grid>
      //               )
      //             }
      //           }
      //         ]
      //       }
      //     }
      //   ]
      // },
      {
        type: OBJECT_TO_LIST,
        obj: opp => {
          const toRet = { ...opp.budget }
          delete toRet.adminLine
          return toRet
        },
        conf: {
          fields: [
            {
              type: SUBHEADER,
              data: item => item.key + '/' + Number(Number(item.key) + 1),
              style: { textAlign: 'center' }
            },
            {
              type: DATA_LIST,
              paperClass: classes.dataList,
              data: item => item.data,
              bulletIcon: 'paid',
              dataComponent: opp => data => {
                return (
                  <Grid container direction='row'>
                    <Grid item xs={3}>
                      <div style={{ fontWeight: 'bold', fontSize: 9 }}>
                        <Trans>Category</Trans>
                      </div>
                      {data.category}
                    </Grid>
                    <Grid item xs={3}>
                      <div style={{ fontWeight: 'bold', fontSize: 9 }}>
                        <Trans>Summary</Trans>
                      </div>
                      {data.description}
                    </Grid>
                    <Grid item xs={3}>
                      <div style={{ fontWeight: 'bold', fontSize: 9 }}>
                        <Trans>Total requested to ICCE</Trans>
                      </div>
                      <CurrencyFormated value={data.totalCost} />
                    </Grid>
                    <Grid item xs={3}>
                      <div style={{ fontWeight: 'bold', fontSize: 9 }}>
                        <Trans>Other donors</Trans>
                      </div>
                      <CurrencyFormated value={data.otherDonors} />
                    </Grid>
                  </Grid>
                )
              }
            }
            // {
            //   type: GRID,
            //   gridClass: classes.gridContainerStretch,
            //   justify: 'center',
            //   fields: [
            //     {
            //       type: DATA_LIST,
            //       paperClass: classes.dataList,
            //       data: item => item.data,
            //       bulletIcon: 'paid',
            //       dataComponent: opp => data => {
            //         return (
            //           <Grid container direction='row'>
            //             <Grid item xs={3}>
            //               <div style={{ fontWeight: 'bold', fontSize: 9 }}>
            //                 <Trans>Category</Trans>
            //               </div>
            //               {data.category}
            //             </Grid>
            //             <Grid item xs={3}>
            //               <div style={{ fontWeight: 'bold', fontSize: 9 }}>
            //                 <Trans>Summary</Trans>
            //               </div>
            //               {data.description}
            //             </Grid>
            //             <Grid item xs={3}>
            //               <div style={{ fontWeight: 'bold', fontSize: 9 }}>
            //                 <Trans>Total requested to ICCE</Trans>
            //               </div>
            //               <CurrencyFormated value={data.totalCost} />
            //             </Grid>
            //             <Grid item xs={3}>
            //               <div style={{ fontWeight: 'bold', fontSize: 9 }}>
            //                 <Trans>Other donors</Trans>
            //               </div>
            //               <CurrencyFormated value={data.otherDonors} />
            //             </Grid>
            //           </Grid>
            //         )
            //       }
            //     }
            //   ]
            // }
          ]
        }
      },
      {
        type: HEADER,
        pageBreakBefore: true,
        disabled: opp => opp.workplan.length === 0,
        style: { fontSize: 24, fonWeight: 'bold' },
        text: (
          <span className={classes.mainTitle}>
            <Trans>Work Plan</Trans>
          </span>
        )
      },
      {
        type: GRID,
        gridClass: classes.gridContainerStretch,
        justify: 'center',
        fields: [
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'work',
            // subtitle: <Trans>Work Plan</Trans>,
            data: opp => opp.workplan,
            disabled: opp => opp.workplan.length === 0,
            dataComponent: opp => data => {
              return (
                <Grid container direction='column'>
                  <Grid item style={{ marign: 5 }}>
                    <b>
                      <Trans>Description of Activities</Trans>
                    </b>
                    {': '}
                    {data.description}
                  </Grid>
                  <Grid item style={{ marign: 5 }}>
                    <b>
                      <Trans>Staff Involved</Trans>
                    </b>
                    {': '}
                    {data.staff}
                  </Grid>
                  <Grid item container style={{ marign: 5 }}>
                    <Grid item xs={6}>
                      <b>
                        <Trans>Start Date</Trans>
                      </b>
                      {': '}
                      {data.startDate}
                    </Grid>
                    <Grid item xs={6}>
                      <b>
                        <Trans>End Date</Trans>
                      </b>
                      {': '}
                      {data.endDate}
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          }
        ]
      },
      {
        type: HEADER,
        pageBreakBefore: true,
        spanClassName: classes.hideInPrint,
        style: { fontSize: 24, fonWeight: 'bold' },
        disabled: opp => opp.uploadFiles.list.length === 0,
        text: item => (
          <span className={classes.mainTitle}>
            <Trans>Attached files</Trans>
          </span>
        )
      },
      {
        type: FILES_LIST,
        gridClass: classes.hideInPrint,
        files: opp => opp.uploadFiles.list || []
      }
    ]
  }
}

export const defaultConfiguration = ({ classes, ac = false }) => {
  return {
    fields: [
      {
        type: HEADER,
        text: (
          <span className={classes.mainTitle}>
            <Trans>Application Details</Trans>
          </span>
        )
      },
      {
        type: GRID,
        fields: [
          {
            type: PAPER,
            style: {
              width: '100%',
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 20,
              padding: 15,
              flexWrap: 'nowrap'
            },
            paperClass: classes.titleCardStretch,
            fields: [
              {
                type: GRID,
                // flexWrap: '',
                justify: 'center',
                // gridClass: classes.titleGrid,
                fields: [
                  {
                    type: FUND_IMAGE,
                    imageClass: classes.imageLogo,
                    fundTypeName: opp => opp.info.fundTypeName
                  },
                  {
                    type: GRID,
                    direction: 'column',
                    style: {
                      width: '300px',
                      marginLeft: '20px',
                      height: 'auto'
                    },
                    fields: [
                      {
                        type: DATA_ON_TOP,
                        gridClass: classes.nameGrid,
                        data: opp => opp.projectDetails.projectName,
                        subtitle: opp => (
                          <span className={classes.subtitle}>
                            <Trans id={opp.info.fundTypeName} />
                          </span>
                        )
                      },
                      {
                        type: GRID,
                        alignItems: 'center',
                        fields: [
                          {
                            type: DATA_ON_TOP,
                            style: { width: 150 },
                            data: opp => (
                              <CurrencyFormated
                                value={opp.projectDetails.amountRequested}
                              />
                            ),
                            subtitle: (
                              <span className={classes.subtitle}>
                                <Trans>Amount Requested</Trans>
                              </span>
                            )
                          },
                          {
                            type: DATA_ON_TOP,
                            style: { width: 150 },
                            data: opp => (
                              <CurrencyFormated
                                value={opp.recommendation.recommendedAmount}
                              />
                            ),
                            subtitle: (
                              <span className={classes.subtitle}>
                                <Trans>Recommended Amount</Trans>
                              </span>
                            )
                          },
                          {
                            type: DATA_ON_TOP,
                            style: { width: 150 },
                            data: opp => (
                              <CurrencyFormated value={opp.info.budgetTotal} />
                            ),
                            subtitle: (
                              <span className={classes.subtitle}>
                                <Trans>Overall Project Budget</Trans>
                              </span>
                            )
                          }
                          // {
                          //   type: DATA_ON_TOP,
                          //   filterKeys: {
                          //     committeeType: {
                          //       key: 'AC'
                          //     }
                          //   },
                          //   data: opp => {
                          //     let grants = 0
                          //     opp.projectDetails.grants.forEach(grant => {
                          //       grants += Number(grant.value)
                          //     })
                          //     return currencyFormat.format(grants)
                          //   },
                          //   subtitle: <Trans>Other revenue source</Trans>
                          // }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: GRID,
        gridClass: classes.gridContainer,
        justify: 'center',
        fields: [
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'payments',
            subtitle: <Trans>Other Grants</Trans>,
            data: opp => opp.projectDetails.grants,
            emptyPlaceholder: <Trans>There are no other grants</Trans>,
            dataComponent: opp => data => {
              return (
                <Grid container direction='column'>
                  <Grid item style={{ marign: 5 }}>
                    <b>
                      <Trans>Grant name</Trans>
                    </b>
                    {': '}
                    {data.name}
                  </Grid>
                  <Grid item style={{ marign: 5 }}>
                    <b>
                      <Trans>Grant status</Trans>
                    </b>
                    {': '}
                    {data.state}
                  </Grid>
                  <Grid item style={{ marign: 5 }}>
                    <b>
                      <Trans>Grant value</Trans>
                    </b>
                    {': '}
                    <CurrencyFormated value={data.value} />
                  </Grid>
                </Grid>
              )
            }
          }
        ]
      },
      {
        type: GRID,
        justify: 'space-around',
        style: { padding: 20 },
        fields: [
          {
            type: DATA_ON_BOTTOM,
            paperClass: classes.fill,
            xs: 4,
            // dataProps: {style: { fontSize: '21px' }},
            dataClass: 'largeText',
            data: opp => moment(opp.info.submitDate).format('DD/MM/YYYY'),
            subtitle: <Trans>Submission Date</Trans>
          },
          {
            type: DATA_ON_BOTTOM,
            paperClass: classes.fill,
            // paperClass: classes.dataContainer,
            xs: 4,
            // dataProps: {style: { fontSize: '21px' }},
            dataClass: 'largeText',
            data: opp =>
              moment(opp.milestones.milestones[0].startDate).format(
                'DD/MM/YYYY'
              ),
            subtitle: <Trans>Project Start Date</Trans>
          },
          {
            type: DATA_ON_BOTTOM,
            paperClass: classes.fill,
            // paperClass: classes.dataContainer,
            xs: 4,
            // dataProps: {style: { fontSize: '21px' }},
            dataClass: 'largeText',
            data: opp =>
              moment(
                opp.milestones.milestones[opp.milestones.milestones.length - 1]
                  .endDate
              ).format('DD/MM/YYYY'),
            subtitle: <Trans>Project End Date</Trans>
          }
        ]
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        // style: { padding: 20 },
        filterKeys: {
          committeeType: {
            key: 'Board'
          }
        },
        data: opp => opp.projectDetails.summary,
        subtitle: <Trans>Summary</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        // style: { padding: 20 },
        dataClass: 'largeText',
        // dataProps: {style: { fontSize: '21px' }},
        data: opp => opp.info.accountName,
        subtitle: <Trans>Applicant</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        // style: { padding: 20 },
        filterKeys: {
          committeeType: {
            key: 'CMHC'
          }
        },
        data: opp => opp.projectDetails.description,
        subtitle: <Trans>Project’s description</Trans>
      },
      {
        type: GRID,
        gridClass: classes.gridContainer,
        justify: 'center',
        fields: [
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'accessibility_new',
            subtitle: <Trans>Centre's priority</Trans>,
            filterKeys: {
              committeeType: {
                key: 'CMHC',
                negative: true
              }
            },
            data: opp => opp.projectDetails.priorityAreas
          },

          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'accessibility_new',
            subtitle: <Trans>CMHC's priority</Trans>,
            filterKeys: {
              committeeType: {
                key: 'CMHC'
              }
            },
            data: opp => opp.projectDetails.cmhcPriorityAreas
          },
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'public',
            subtitle: <Trans>Scope</Trans>,
            data: opp => opp.projectDetails.geographicalScopeType
          }
        ]
      },

      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        // style: { padding: 20 },
        dataClass: 'largeText',
        data: opp => opp.projectDetails.geographicalScopeDetails,
        subtitle: <Trans>Location</Trans>
      },
      // old demographic if filled
      {
        type: GRID,
        gridClass: classes.gridContainer,
        justify: 'center',
        fields: [
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            disabled: opp =>
              ac ||
              !opp.projectDetails.demographic ||
              opp.projectDetails.demographic.length === 0,
            bulletIcon: 'supervised_user_circle',
            subtitle: <Trans>Demographic</Trans>,
            data: opp => opp.projectDetails.demographic
          }
        ]
      },
      // new demographic if old is empty
      {
        type: GRID,
        gridClass: classes.gridContainer,
        disabled: opp =>
          !ac &&
          !(
            !opp.projectDetails.demographic ||
            opp.projectDetails.demographic.length === 0
          ),
        justify: 'center',
        fields: [
          // check hasTargetDemographic
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            // gridClass: classes.gridContainerStretch,
            bulletIcon: 'supervised_user_circle',
            style: { width: '100%' },
            subtitle: <Trans>Target demographic</Trans>,
            data: opp => opp.projectDetails.targetDemographicPM
          },
          {
            type: DATA_ON_BOTTOM,
            disabled: opp => ac || !opp.projectDetails.otherTargetDemographic,
            paperClass: classes.dataContainer,
            gridClass: classes.gridContainerStretch,
            dataClass: 'largeText',
            data: opp => opp.projectDetails.otherTargetDemographic,
            subtitle: <Trans>Other - target demographic</Trans>
          },
          {
            type: GRID,
            gridClass: classes.gridContainerStretch,
            disabled: opp => ac,
            justify: 'center',
            fields: [
              {
                type: HEADER,
                style: { fontSize: 18, fonWeight: 'bold', width: '100%' },
                text: item => <Trans>Units identified for each group:</Trans>
              },
              ...fieldsFromList(
                [
                  {
                    field: 'projectDetails.unitsWomen',
                    subtitle: <Trans>Units - women and children</Trans>
                  },
                  {
                    field: 'projectDetails.unitsFleeingDomesticViolence',
                    subtitle: <Trans>Units - fleeing domestic violence</Trans>
                  },
                  {
                    field: 'projectDetails.unitsSingleParent',
                    subtitle: <Trans>Units - single parent</Trans>
                  },
                  {
                    field: 'projectDetails.unitsIndigenous',
                    subtitle: <Trans>Units - Indigenous</Trans>
                  },
                  {
                    field: 'projectDetails.unitsRacializedPersons',
                    subtitle: <Trans>Units - racialized persons</Trans>
                  }
                ],
                classes.gridChild,
                { paddingLeft: 10 }
              )
            ]
          },

          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            disabled: opp => ac,
            bulletIcon: 'supervised_user_circle',
            // style: { width: '100%' },
            subtitle: <Trans>Supported demographics</Trans>,
            data: opp => opp.projectDetails.supportedDemographic
          },
          {
            // check hasSupportedDemographic
            type: DATA_ON_BOTTOM,
            paperClass: classes.dataContainer,
            gridClass: classes.gridContainerStretch,
            disabled: opp =>
              ac || !opp.projectDetails.otherSupportedDemographic,
            // style: { padding: 20, width: '100%' },
            dataClass: 'largeText',
            data: opp => opp.projectDetails.otherSupportedDemographic,
            subtitle: <Trans>Other - supported demographic</Trans>
          }
        ]
      },
      {
        type: DATA_ON_BOTTOM,
        paperClass: classes.dataContainer,
        gridClass: classes.gridContainerStretch,
        filterKeys: {
          committeeType: {
            key: 'AC'
          }
        },
        data: opp => opp.projectDetails.summary,
        subtitle: <Trans>Summary</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        paperClass: classes.dataContainer,
        gridClass: classes.gridContainerStretch,
        data: opp => opp.recommendation.impactSummary,
        subtitle: <Trans>Impact Summary</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        paperClass: classes.dataContainer,
        gridClass: classes.gridContainerStretch,
        // style: { padding: 20 },
        filterKeys: {
          committeeType: {
            key: 'AC'
          }
        },
        data: opp => opp.recommendation.partnersSummary,
        subtitle: <Trans>Partners</Trans>
      },
      {
        type: PAPER,
        paperClass: classes.dataContainer,
        title: <Trans>Evaluation</Trans>,
        fields: [
          {
            type: TEXT_LIST,
            fields: [
              {
                type: TEXT_DATA,
                // style: { padding: 20 },
                data: opp => opp.info.pmName,
                subtitle: (
                  <b>
                    <Trans>Program Manager</Trans>
                  </b>
                )
              },
              {
                type: TEXT_DATA,
                // style: { padding: 20 },
                data: opp => opp.info.externalReviews,
                filterKeys: {
                  committeeType: {
                    key: 'AC'
                  }
                },
                subtitle: (
                  <b>
                    <Trans>Number of external reviews</Trans>
                  </b>
                )
              },
              {
                type: TEXT_DATA,
                subtitle: '', // <Trans> Program manager’s recommendation </Trans>,
                data: opp => {
                  if (
                    pmRecommendationTranslations[
                      opp.recommendation.recommendation
                    ]
                  ) {
                    return (
                      <b>
                        <Trans
                          id={
                            pmRecommendationTranslations[
                              opp.recommendation.recommendation
                            ]
                          }
                        />
                      </b>
                    )
                  } else {
                    return <b>{opp.recommendation.recommendation}</b>
                  }
                }
              },
              {
                type: TEXT_DATA,
                subtitle: (
                  <Trans> Program manager’s recommendation comment </Trans>
                ),
                data: opp => opp.recommendation.recommendationText
              }
            ]
          }
        ]
      }
    ]
  }
}

const toPrintStyles = theme => printStyles(theme)
export const usePrintStyles = makeStyles(toPrintStyles)

export const OpportunityView = ({
  opportunity,
  pageBreakBefore,
  inlineLoading,
  fixedLoading = true,
  displayPrint = false,
  committeeType,
  defaultConfigurationOptions = {},
  configuration
}) => {
  const printClasses = usePrintStyles()
  if (!configuration) {
    if (sfOauthConfig.isIcce) {
      configuration = defaultConfigurationICCE({
        classes: printClasses,
        data: opportunity,
        ...defaultConfigurationOptions
      })
    } else {
      configuration = defaultConfiguration({
        classes: printClasses,
        ...defaultConfigurationOptions
      })
    }
  }
  const classes = useViewComponentStyles()
  const componentRef = useRef()

  if (!opportunity) {
    if (inlineLoading) {
      return (
        <Grid container>
          <Loading isNotFixed />
        </Grid>
      )
    } else if (!fixedLoading) {
      return <Loading isNotFixed />
    } else {
      return <Loading />
    }
  }

  const objects = convertConfiguration(configuration, opportunity, {
    committeeType
  })
  console.log(
    'opportunityView',
    objects,
    opportunity,
    committeeType,
    pageBreakBefore
  )

  if (!displayPrint) {
    return (
      <div ref={componentRef}>
        {pageBreakBefore && <div style={{ pageBreakBefore: 'always' }} />}
        {objects}
      </div>
    )
  } else {
    return (
      <Paper className={classes.paper}>
        <ReactToPrint
          onAfterPrint={() => (document.title = defaultDocTitle)}
          onBeforePrint={() =>
            (document.title = opportunity.projectDetails.projectName)
          }
          trigger={() => (
            <Button
              variant='contained'
              color='primary'
              style={{ marginLeft: 20, marginTop: 30 }}
            >
              <Trans>Print</Trans>
              <Icon style={{ marginLeft: 5 }}>print</Icon>
            </Button>
          )}
          content={() => componentRef.current}
        />
        <div ref={componentRef}>{objects}</div>
      </Paper>
    )
  }
}
