/**
 * Created by Michał Stawski on 23.03.2021.
 */
import React from 'react'
import { Card, Typography } from '@material-ui/core'
import { Trans } from '@lingui/macro'
import { makeStyles } from '@material-ui/core/styles'

export const useStyleSatTopCard = makeStyles(theme => ({
  headerCard: {
    background: 'rgba(0, 0, 0, 0.08)',
    maxWidth: 900,
    margin: '20px auto',
    padding: 20,
    borderRadius: 8
  },
  headerPart: {
    marginTop: 20
  },
  headerP: {
    marginTop: 10
  }
}))

export const SurveyTabSatTopCard = () => {
  const classes = useStyleSatTopCard()
  return <Card className={classes.headerCard}>

    <div className={classes.headerPart}>
      <Typography className={classes.headerP}>
        <Trans>
          <b>Instructions:</b> Please select or write in your answers. You will need to click NEXT
          in several instances to proceed to the next set of questions.
        </Trans>
      </Typography>
    </div>

    <div className={classes.headerPart}>
      <Typography className={classes.headerP}>
        <Trans>
          Remember to save your work often and before you exit the page. You can always pick back up
          where you started by selecting the pencil button to next the survey in the Self-assessment
          tool menu. When you’re done, hit "Submit" at the end of the questionnaire to view your
          results and action plan
        </Trans>
      </Typography>
    </div>
    <div className={classes.headerPart}>
      <Typography className={classes.headerP}>
        <Trans>
          In order to respond appropriately to the questions asked, <b>we ask that you to consider
          the situation of your housing organization over the past five years, before the crisis
          caused by the pandemic.</b>
        </Trans>
      </Typography>
    </div>
    <div className={classes.headerPart}>
      <Typography className={classes.headerP}>
        <Trans>
          Since this is a self-assessment tool, you will need to provide your own evaluation of the
          condition of your property(ies) and your organization as a whole. There is no point in
          trying to make the situation sound better: this tool is yours and you alone will decide
          what to do with the results.
        </Trans>
      </Typography>
      <Typography className={classes.headerP}>
        <Trans>
          *Please note: “board members” can also be members of board committees.
        </Trans>
      </Typography>
    </div>
    <div className={classes.headerPart}>
      <Typography className={classes.headerP}>
        <Trans>
          Thank you for your participation!
        </Trans>
      </Typography>
    </div>
  </Card>
}
