/**
 * Created by Michał Stawski on 05.05.2020.
 */
import React from 'react'
import {
  QuestionInputText,
  QuestionMultipleValue,
  QuestionDate,
  QuestionDateRange,
  QuestionPointsGenerator,
  QuestionSelectOne,
  QuestionUploadFiles,
  returnTooltip,
  QuestionNumericSlider
} from './surveyQustions'
import { Trans, t } from '@lingui/macro'
import { ErrorMessage } from 'formik'
import { Card, Typography } from '@material-ui/core'
import {
  NumericFieldScoreConfig,
  SelectOptionScoreConfig,
  TextFieldScoreConfig
} from './ScoreCardCreator'
import SurveyTextInputQuestionPdf from './pdfSurveyComponents/SurveyTextInputQuestionPdf'
import SurveyChoiceQuestionPdf from './pdfSurveyComponents/SurveyChoiceQuestionPdf'
import SurveyRangeQuestionPdf from './pdfSurveyComponents/SurveyRangeQuestionPdf'
import SurveyHeaderPdf from './pdfSurveyComponents/SurveyHeaderPdf'

const fixedQuestionAnswers = {
  SELECT_YESNO: [t`Yes`, t`No`]
}

// const surveyItemOptionsFields = [
//   'conditionalObject',
//   'conditionalObjectSubfield',
//   'conditionalObjectName',
//   'conditionalObjectField',
//   'conditionForObjectField',
//   'isLinked',
//   'linkedObject',
//   'linkedField',
//   'createCase',
//   'caseType',
//   'tooltipAtBottom',
//   'hasTooltip',
//   'nonApplicable',
//   'isRequired',
//   'skipInExport',
//   'skipInPrint',
//   'rows',
//   'min',
//   'max'
// ]

const questionTypes = {
  HEADER: {
    id: 'HEADER',
    text: <Trans>Header</Trans>,
    icon: 'title',
    class: () => '',
    pdfComponent: SurveyHeaderPdf
  },
  UPLOAD_FILES: {
    id: 'UPLOAD_FILES',
    text: <Trans>Question with file upload</Trans>,
    icon: 'file_upload',
    class: QuestionUploadFiles
  },
  SELECT_YESNO: {
    id: 'SELECT_YESNO',
    text: <Trans>Question with yes/no answer</Trans>,
    icon: 'thumbs_up_down',
    class: QuestionPointsGenerator(fixedQuestionAnswers.SELECT_YESNO),
    nonApplicableAsOption: true,
    pdfComponent: SurveyChoiceQuestionPdf
  },
  SELECT_ONE: {
    id: 'SELECT_ONE',
    text: <Trans>Question with custom, single answer</Trans>,
    icon: 'more_vert',
    class: QuestionSelectOne,
    scoreConfig: SelectOptionScoreConfig,
    nonApplicableAsOption: true,
    pdfComponent: SurveyChoiceQuestionPdf
  },
  SELECT_MULTIPLE: {
    id: 'SELECT_MULTIPLE',
    text: <Trans>Question with custom, multiple answers</Trans>,
    icon: 'more_vert',
    class: QuestionMultipleValue,
    scoreConfig: SelectOptionScoreConfig,
    pdfComponent: SurveyChoiceQuestionPdf
  },
  INPUT_DATE: {
    id: 'INPUT_DATE',
    text: <Trans>Question with date answer</Trans>,
    icon: 'calendar_today',
    class: QuestionDate,
    pdfComponent: SurveyTextInputQuestionPdf
  },
  INPUT_DATE_RANGE: {
    id: 'INPUT_DATE_RANGE',
    text: <Trans>Question with date range answer</Trans>,
    icon: 'date_range',
    class: QuestionDateRange,
    pdfComponent: SurveyRangeQuestionPdf
  },
  INPUT_TEXT: {
    id: 'INPUT_TEXT',
    text: <Trans>Question with short text answer</Trans>,
    icon: 'text_snippet',
    class: QuestionInputText,
    scoreConfig: TextFieldScoreConfig,
    pdfComponent: SurveyTextInputQuestionPdf
  },
  INPUT_TEXTAREA: {
    id: 'INPUT_TEXTAREA',
    text: <Trans>Question with long text answer</Trans>,
    icon: 'text_snippet',
    class: QuestionInputText,
    scoreConfig: TextFieldScoreConfig,
    pdfComponent: SurveyTextInputQuestionPdf
  },
  INPUT_NUMBER: {
    id: 'INPUT_NUMBER',
    text: <Trans>Question with numeric answer</Trans>,
    icon: 'looks_one',
    class: QuestionInputText,
    scoreConfig: NumericFieldScoreConfig,
    pdfComponent: SurveyTextInputQuestionPdf
  },
  SLIDER: {
    id: 'SLIDER',
    text: <Trans>Question with numeric slider</Trans>,
    icon: 'linear_scale',
    class: QuestionNumericSlider,
    scoreConfig: NumericFieldScoreConfig,
    pdfComponent: SurveyTextInputQuestionPdf
  }
}

const indexToFieldId = index => `field_${index}`
const indexToOption = index => `option_${index}`

const QuestionWidgetInner = props => {
  const {
    question,
    disabled,
    hideError,
    disableFields,
    index,
    errorIsArray,
    className,
    displayEN,
    questionValue,
    setFieldValue,
    surveyId,
    opportunity,
    entityFiles,
    network
  } = props

  const QuestionClass = questionTypes[question.type].class
  if (question.type === questionTypes.HEADER.id) {
    return null
  }
  const noNativeErrorClasses = [
    'SELECT_YESNO',
    'SELECT_ONE',
    'SELECT_MULTIPLE',
    'SLIDER'
  ]
  const noError = disabled || hideError
  const noNativeError = Boolean(
    noNativeErrorClasses.includes(question.type) && !question.isGroup
  )
  const mainErrorForGroup = Boolean(question.isGroup && !errorIsArray)
  return (
    <Card className={className} elevation={6}>
      {QuestionClass && (
        <>
          <QuestionClass
            hideError={noError}
            surveyId={surveyId}
            network={network}
            entityFiles={entityFiles}
            opportunity={opportunity}
            disabled={disabled}
            nonApplicableDisabled={props.nonApplicableDisabled}
            nonApplicable={props.nonApplicable}
            completedSurveys={null} // disable completed surveys and with it prefil
            displayEN={displayEN}
            disableFields={disableFields}
            questionValue={questionValue}
            setFieldValue={setFieldValue}
            question={question}
            fieldId={index}
          />
          {Boolean(noNativeError || mainErrorForGroup) && !noError && (
            <ErrorMessage name={index}>
              {msg => (
                <p
                  style={{
                    color: '#f5543b',
                    fontSize: '0.75rem',
                    marginTop: 3,
                    marginLeft: 14,
                    textAlign: 'left'
                  }}
                >
                  {msg}
                </p>
              )}
            </ErrorMessage>
          )}
        </>
      )}
    </Card>
  )
}

const QuestionWidget = React.memo(QuestionWidgetInner)

export {
  questionTypes,
  QuestionWidget,
  indexToFieldId,
  indexToOption,
  fixedQuestionAnswers
}
