import { EgretLoadable } from 'egret'
import { authRoles } from '../../auth/authRoles'
import { internalEnvs } from '../../services/sfAuth/sfAuthConfig'

const GranteeReportEditor = EgretLoadable({
  loader: () => import('./GranteeReportEditor')
})

const ProgramManagerApplicationList = EgretLoadable({
  loader: () => import('./ProjectManagerApplicationList')
})

const BudgetEmbedded = EgretLoadable({
  loader: () => import('./BudgetEmbedded')
})

const ExportSurveys = EgretLoadable({
  loader: () => import('./ExportSurveys')
})

const ExportImportObjects = EgretLoadable({
  loader: () => import('./ExportImportObjects')
})

const TranslationsEditor = EgretLoadable({
  loader: () => import('./TranslationsEditor')
})

const FormConfigurablePrintView = EgretLoadable({
  loader: () => import('./FormConfigurablePrintView')
})

const ConfigurePortalConfigurations = EgretLoadable({
  loader: () => import('./ConfigurePortalConfigurations')
})

const ConfigureApplicationTypes = EgretLoadable({
  loader: () => import('./ConfigureApplicationTypes')
})

const TechnicalAdvisoryRanking = EgretLoadable({
  loader: () => import('./technical-advisories/TechnicalAdvisoryRanking')
})

const Contract = EgretLoadable({
  loader: () => import('./Contract')
})

const InternalHelp = EgretLoadable({
  loader: () => import('./InternalHelp')
})

const ConfigureUserPermissions = EgretLoadable({
  loader: () => import('./ConfigureUserPermissions')
})

const AdminMultiuserPanel = EgretLoadable({
  loader: () => import('../forms/multiuser/admin/AdminMultiuserPanel')
})

const internalRoutes = [
  {
    path: '/internal/GranteeReportEditor/:id',
    component: GranteeReportEditor,
    auth: authRoles.pm
  },
  {
    path: '/internal/TranslationsEditor',
    component: TranslationsEditor,
    auth: authRoles.pm
  },
  {
    path: '/internal/ProgramManagerApplicationList',
    component: ProgramManagerApplicationList,
    auth: authRoles.pm
  },
  {
    path: '/internal/contract/:id',
    component: Contract,
    env: internalEnvs,
    auth: authRoles.pm
  },
  {
    path: '/internal/TechnicalAdvisoryRanking',
    component: TechnicalAdvisoryRanking,
    auth: authRoles.pm
  },
  {
    path: '/internal/widgets/budget/:id',
    component: BudgetEmbedded,
    auth: authRoles.pm
  },
  {
    path: '/internal/ExportSurveys',
    component: ExportSurveys,
    auth: authRoles.pm
  },
  {
    path: '/internal/InternalHelp',
    component: InternalHelp,
    auth: authRoles.admin
  },
  {
    path: '/internal/FormConfigurablePrintView',
    component: FormConfigurablePrintView,
    auth: authRoles.admin
  },
  {
    path: '/internal/ConfigureApplicationTypes',
    component: ConfigureApplicationTypes,
    auth: authRoles.admin
  },
  {
    path: '/internal/PortalConfigurations',
    component: ConfigurePortalConfigurations,
    auth: authRoles.admin
  },
  {
    path: '/internal/ConfigureUserPermissions',
    component: ConfigureUserPermissions,
    auth: authRoles.admin
  },
  {
    path: '/internal/ExportImportObjects',
    component: ExportImportObjects,
    auth: authRoles.admin
  },
  {
    path: '/internal/active-sessions',
    component: AdminMultiuserPanel,
    auth: authRoles.admin
  }
]

export default internalRoutes
