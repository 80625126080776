import { Trans } from '@lingui/macro'
import {
  TextField,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid
} from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import { NumberFormatDefault } from './Common'
import { stylePropTooltip } from '../GroupCard'

export const FormHeaderPrint = ({
  titleEN,
  titleFR,
  french,
  typeProps,
  ...props
}) => {
  const { style, variant, bold, italics, fontSize, centerText } = typeProps
  let styleToPass = {}
  try {
    const parsed = JSON.parse(style)
    if (typeof parsed === 'object') {
      styleToPass = Object.assign(styleToPass, parsed)
    } else {
    }
  } catch (e) {
    console.warn('could not parse style in form ', e)
  }
  if (fontSize) {
    styleToPass.fontSize = +fontSize
  }
  if (bold) {
    styleToPass.fontWeight = 'bold'
  }
  if (italics) {
    styleToPass.fontStyle = 'italic'
  }
  if (centerText) {
    styleToPass.textAlign = 'center'
  }

  return (
    <Typography
      variant={variant !== 'none' && variant}
      style={{
        marginLeft: 15,
        fontWeight: 'lighter',
        ...styleToPass
      }}
    >
      {french ? titleFR : titleEN}
    </Typography>
  )
}

export const FormHeader = ({
  titleEN,
  titleFR,
  french,
  typeProps,
  ...props
}) => {
  const { style, variant, bold, italics, fontSize, centerText } = typeProps
  let styleToPass = {}
  let isError = false
  try {
    const parsed = JSON.parse(style)
    if (typeof parsed === 'object') {
      styleToPass = Object.assign(styleToPass, parsed)
    } else {
      isError = true
    }
  } catch (e) {
    console.warn('could not parse style in form ', e)
    isError = Boolean(style)
  }
  if (fontSize) {
    styleToPass.fontSize = +fontSize
  }
  if (bold) {
    styleToPass.fontWeight = 'bold'
  }
  if (italics) {
    styleToPass.fontStyle = 'italic'
  }
  if (centerText) {
    styleToPass.textAlign = 'center'
  }
  return (
    <>
      <Typography
        variant={variant !== 'none' && variant}
        style={{
          marginLeft: 15,
          fontWeight: 'lighter',
          ...styleToPass
        }}
      >
        {french ? titleFR : titleEN}
      </Typography>
      {isError && (
        <span
          style={{
            color: '#f5543b',
            fontSize: '0.75rem',
            marginTop: 3,
            marginLeft: 14,
            textAlign: 'left'
          }}
        >
          <Trans>Style prop for this component was inncorrectly provided</Trans>
        </span>
      )}
    </>
  )
}

export const FormEditorHeader = ({
  editMode,
  depth,
  showPrintProps,
  typeProps = {},
  injectable,
  ...props
}) => {
  const dispatch = useDispatch()
  const {
    style,
    variant = 'h4',
    bold,
    italics,
    fontSize,
    centerText
  } = typeProps
  if (!editMode) {
    return <FormHeader typeProps={typeProps} {...props} />
  }
  return (
    <div>
      <TextField
        select
        style={{ marginBottom: 10 }}
        fullWidth
        variant='outlined'
        label={<Trans>Variant</Trans>}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.variant = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
        value={variant}
      >
        <MenuItem value='none'>
          <Trans>None</Trans>
        </MenuItem>
        {['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].map((v, index) => (
          <MenuItem value={v} key={index}>
            <Trans>Variant</Trans>
            {' ' + (index + 1).toString()}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label={<Trans>Font size</Trans>}
        value={fontSize || ''}
        fullWidth
        variant='outlined'
        multiline
        InputProps={{ inputComponent: NumberFormatDefault }}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.fontSize = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      <div style={{ marginLeft: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(bold)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.bold = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Bold</Trans>}
        />
      </div>
      <div style={{ marginLeft: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(italics)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.italics = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Italics</Trans>}
        />
      </div>
      <div style={{ marginLeft: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(centerText)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.centerText = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Center text</Trans>}
        />
      </div>
      <Grid container wrap='nowrap' style={{ marginTop: 5 }}>
        <TextField
          label={<Trans>Style</Trans>}
          value={style}
          fullWidth
          variant='outlined'
          multiline
          style={{ paddingRight: 5 }}
          onChange={e => {
            const toSet = { ...typeProps }
            toSet.style = e.currentTarget.value
            dispatch({
              type: 'FIELD',
              injectable,
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: { ...toSet }
            })
          }}
        />
        <TooltipLabelIcon tooltip={stylePropTooltip} />
      </Grid>
    </div>
  )
}
