import SFAuthService, { NO_USER } from '../SFAuthService'

/**
 * Created by Michał Stawski on 18.10.2020.
 */

const example = {
  compositeResponse: [
    {
      body: [
        {
          message:
            'Cet enregistrement est verrouillé. Si vous devez le modifier, contactez votre administrateur.',
          errorCode: 'ENTITY_IS_LOCKED',
          fields: []
        }
      ],
      httpHeaders: {},
      httpStatusCode: 400,
      referenceId: 'refOpportunity'
    },
    {
      body: [
        {
          errorCode: 'PROCESSING_HALTED',
          message:
            'The transaction was rolled back since another operation in the same transaction failed.'
        }
      ],
      httpHeaders: {},
      httpStatusCode: 400,
      referenceId: 'refUpdatedObjects'
    }
  ]
}

export const succesFromComposite = result => {
  let success = result.compositeResponse
    .map(item => item.httpStatusCode >= 200 && item.httpStatusCode < 300)
    .reduce((a, b) => a && b, true)
  return success
}

export const PROCESSING_HALTED = 'PROCESSING_HALTED'
export const ENTITY_IS_LOCKED = 'ENTITY_IS_LOCKED'

export const errorsFromComposite = result => {
  const ret = {
    compositeError: true,
    errors: [],
    errorDetails: []
  }
  let errors = new Set()

  for (let response of result.compositeResponse) {
    for (let error of response.body) {
      if (error.errorCode === PROCESSING_HALTED) {
        continue
      } else {
        errors.add(error.errorCode)
        ret.errorDetails.push({
          ...error
        })
      }
    }
  }
  ret.errors = [...errors.values()]
  return ret
}

export const readOnlyField = name => ({
  in: opp => opp[name]
})

export const readOlyFieledMTF = (name, key) => ({
  [name]: {
    key: key,
    in: user => user[name]
  }
})

export const multipicklistField = (name, key) => ({
  key,
  in: opp => (opp[name] ? opp[name].split(';') : []),
  out: res => {
    return res[key].join(';')
  }
})

export const cleanUpForYup = values => {
  let ret = {}
  for (let key in values) {
    if (values[key] === null) {
      ret[key] = undefined
    } else {
      ret[key] = values[key]
    }
  }
  return ret
}

export const getObjectPermissions = (userId, recordId) => {
  console.log('getAccountPermissions ', userId, recordId)
  const conn = SFAuthService.getConnection()
  return conn
    .sobject('UserRecordAccess')
    .find({
      UserID: userId,
      RecordId: recordId
    })
    .select('RecordId, HasEditAccess')
    .then(results => {
      console.log('getObjectPermissions result', results)
      if (results.length !== 1) {
        return Promise.reject(NO_USER)
      } else {
        return results[0].HasEditAccess
      }
    })
}

export const checkPromiseAllSuccess = result => {
  if (Array.isArray(result)) {
    if (result.length === 0) {
      return false
    }
    return result.some(sub => checkPromiseAllSuccess(sub))
  } else {
    if (result.compositeResponse) {
      return result.compositeResponse.some(comp => {
        if (!comp.body) {
          return false
        } else {
          return comp.body.some(sub => !sub.success)
        }
      })
    } else if (result.hasOwnProperty('isSuccess')) {
      return !result.isSuccess
    }
    return !result.success
  }
}
