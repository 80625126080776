import { Trans, t } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  TextField
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { DialogTitleWithIconClose } from '../common/CustomDialog'
import { Autocomplete } from '@material-ui/lab'
import * as Excel from 'exceljs'
import { saveAs } from 'file-saver'
import { myI18n } from 'translation/I18nConnectedProvider'
import { fetchFormPage, saveFormPage } from 'app/services/sfAuth/sfData/sfForms'
import { formTranslationsStructure } from './formTranslationsStructure'
import { getDeepFieldName } from './FormWizard'
import { FileDropzone, UploadFilesField } from '../common/UploadFilesField'
import { getNextAlphabeticalLetter } from 'utils'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import { portalLanguagesData } from 'app/appSettings'
import { mapFormElements } from './Form'

export const ExportImportFormTranslationsPanel = ({ forms = [] }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [uploadedFile, setUploadedFile] = useState('')
  const [selectedForm, setSelectedForm] = useState('')
  const [importedForm, setImportedForm] = useState(null)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const avaliableForms = []
  forms.forEach(form => {
    if (form.children) {
      form.children.forEach(form => {
        avaliableForms.push(form)
      })
    } else {
      avaliableForms.push(form)
    }
  })

  useEffect(() => {
    setSelectedForm('')
    setUploadedFile(null)
  }, [dialogOpen])

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        onClick={e => {
          setDialogOpen(true)
        }}
      >
        <Trans>FORMS_EXPORT_IMPORT_TRANSLATIONS_BUTTON</Trans>
      </Button>

      <Dialog open={Boolean(importedForm)} fullWidth maxWidth='sm'>
        <DialogTitle>
          <Trans>
            FORMS_EXPORT_IMPORT_TRANSLATIONS_DIALOG_ARE_YOU_SURE_LABEL
          </Trans>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent='space-evenly'>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={e => {
                  const savingSnackbar = enqueueSnackbar(null, {
                    persist: true,
                    content: key =>
                      ProgressSnackbar(<Trans>Saving form page</Trans>)
                  })
                  saveFormPage(importedForm).then(
                    result => {
                      setLoading(false)
                      setImportedForm(null)
                      closeSnackbar(savingSnackbar)
                      enqueueSnackbar(<Trans>Form page saved!</Trans>, {
                        variant: 'success'
                      })
                    },
                    reject => {
                      setLoading(false)
                      setImportedForm(null)
                      closeSnackbar(savingSnackbar)
                      enqueueSnackbar(
                        <Trans>Error ocurred while saving form page</Trans>,
                        {
                          variant: 'error'
                        }
                      )
                    }
                  )
                }}
              >
                <Icon style={{ marginRight: 5 }}>done</Icon>
                <Trans>Yes</Trans>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={e => {
                  setImportedForm(null)
                  setLoading(false)
                }}
              >
                <Icon style={{ marginRight: 5 }}>close</Icon>
                <Trans>No</Trans>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={dialogOpen} fullWidth maxWidth='md'>
        <DialogTitleWithIconClose
          disabled={loading}
          label={<Trans>FORMS_EXPORT_IMPORT_TRANSLATIONS_DIALOG_TITLE</Trans>}
          handleClose={e => {
            setDialogOpen(false)
          }}
        />
        <DialogContent style={{ padding: 20 }}>
          <Autocomplete
            disabled={loading}
            freeSolo={false}
            value={selectedForm}
            onChange={(e, value) => {
              setSelectedForm(value)
            }}
            style={{ marginTop: 15 }}
            fullWidth
            options={avaliableForms}
            getOptionLabel={form => {
              let name = form.name
              if (form.version) {
                name += ' [v.' + form.version + ']'
              }
              if (form.comments) {
                name += ' (' + form.comments + ')'
              }
              return name
            }}
            renderInput={params => (
              <TextField
                variant='outlined'
                {...params}
                label={
                  <Trans>FORMS_EXPORT_IMPORT_TRANSLATIONS_TARGET_FORM</Trans>
                }
              />
            )}
          />

          <div style={{ paddingTop: 15 }}>
            <FileDropzone
              disabled={Boolean(uploadedFile)}
              accept={'.xlsx'}
              handleUpload={file => {
                setUploadedFile(file)
              }}
            />
            {uploadedFile && (
              <Grid container>
                <Grid item xs style={{ padding: 8 }}>
                  {uploadedFile.path}
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={loading}
                    onClick={e => {
                      setUploadedFile(null)
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!uploadedFile || !selectedForm || loading}
            variant='contained'
            color='primary'
            onClick={e => {
              setLoading(true)
              fetchFormPage(selectedForm.id).then(form => {
                const elementsMap = mapFormElements(form, false, true)
                setImportedForm(
                  importTranslationsFromXMLS({
                    ...uploadedFile,
                    elementsMap,
                    form,
                    oldImport: true
                  })
                )
                setLoading(false)
              })
            }}
          >
            <Icon style={{ marginRight: 5 }}>upload</Icon>
            <Trans>FORMS_IMPORT_TRANSLATIONS_BUTTON</Trans>
          </Button>
          <Button
            disabled={!selectedForm || loading}
            variant='contained'
            color='primary'
            onClick={e => {
              setLoading(true)
              fetchFormPage(selectedForm.id).then(form => {
                let name = form.titleEN
                if (form.version) {
                  name += ' [v.' + form.version + ']'
                }
                if (form.comments) {
                  name += ' (' + form.comments + ')'
                }
                generateExcelFile({ ...form, fileName: name }).then(result => {
                  setLoading(false)
                })
              })
            }}
          >
            <Icon style={{ marginRight: 5 }}>download</Icon>
            <Trans>FORMS_EXPORT_TRANSLATIONS_BUTTON</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const importTranslationsFromXMLS = ({ elementsMap, data, form, oldImport }) => {
  var XLSX = require('xlsx')
  var workbook = XLSX.read(data)
  const changedMap = _.cloneDeep(elementsMap)
  const newForm = _.cloneDeep(form)

  const columnsData = {
    A: 'translationPath',
    B: 'id',
    C: 'title'
  }
  let startLetter = 'C'
  Object.entries(portalLanguagesData).forEach(([key, obj]) => {
    startLetter = getNextAlphabeticalLetter(startLetter)
    columnsData[startLetter] = obj.editKey
  })

  Object.entries(workbook.Sheets).forEach(([key, sheet], index) => {
    let rowIndex = 2
    let rowExists = true

    while (rowExists) {
      Object.keys(columnsData).forEach(key => {
        if (['A', 'B', 'C'].includes(key)) {
          return
        }
        const cellValue = sheet[key + rowIndex]?.v
        if (cellValue) {
          const langKey = columnsData[key]
          if (sheet['B' + rowIndex]) {
            const id = sheet['B' + rowIndex].v
            const translationPath = sheet['A' + rowIndex].v
            const element = changedMap[id]
            const translationSctructure =
              formTranslationsStructure[element?.elementType]
            let allUppercase = true
            let keyOverrideObject
            if (translationSctructure) {
              const occurences = translationPath.split('.')
              const typePropsKey = occurences[1]
              if (typePropsKey) {
                const { nonUppercase, keyOverride } =
                  translationSctructure[typePropsKey]
                allUppercase = !nonUppercase
                if (keyOverride) {
                  keyOverrideObject = keyOverride
                }
              }
            }
            if (oldImport) {
              let oldKey = allUppercase
                ? langKey.toUpperCase()
                : langKey.charAt(0).toUpperCase() + langKey.slice(1)
              if (keyOverrideObject) {
                oldKey = keyOverrideObject[langKey]
              }
              if (element) {
                _.set(element, translationPath + oldKey, cellValue)
              } else if (id.includes('sections')) {
                //Update section titles
                _.set(newForm, id + '.' + translationPath + oldKey, cellValue)
              } else {
                console.error(
                  'wrong element id',
                  id,
                  translationPath,
                  cellValue
                )
              }
            } else {
              // TODO
            }
          }
        }
      })

      if (!sheet['A' + (rowIndex + 1)]) {
        rowExists = false
        break
      } else {
        rowIndex++
      }
    }
  })

  const checkForUpdatedTranslations = ({ item, depth }) => {
    if (item.elements) {
      item.elements.forEach((child, index) => {
        checkForUpdatedTranslations({ item: child, depth: [...depth, index] })
      })
    }
    if (changedMap[item.id]) {
      _.set(newForm, getDeepFieldName('', depth), changedMap[item.id])
    }
  }

  newForm.sections.forEach((section, sectionIndex) => {
    section.elements.forEach((item, index) => {
      checkForUpdatedTranslations({ item, depth: [sectionIndex, index] })
    })
  })
  return newForm
}

const generateExcelFile = ({ fileName, pdfProps, sections = [] }) => {
  const workbook = new Excel.Workbook()
  const columns = [
    {
      header: myI18n._(t`Translation path`),
      width: 20,
      key: 'translationPath'
    },
    {
      header: myI18n._(t`Element id`),
      width: 20,
      key: 'id'
    },
    {
      header: myI18n._(t`Location`),
      width: 20,
      key: 'location'
    },

    ...Object.values(portalLanguagesData).map(obj => {
      return {
        header: obj.label,
        key: obj.editKey,
        width: 40
      }
    })
  ]

  sections.forEach((section, sectionIndex) => {
    const sheet = workbook.addWorksheet(section.titleEN, {
      views: [{ state: 'frozen', ySplit: 1 }]
    })
    sheet.columns = [...columns]
    const titleRow = {
      translationPath: 'title',
      id: 'sections.' + sectionIndex,
      location: 'Section title'
    }
    Object.values(portalLanguagesData).forEach(obj => {
      titleRow[obj.editKey] = section.title
        ? section.title[obj.editKey]
        : section['title' + String(obj.editKey).toUpperCase()]
    })
    sheet.addRow(titleRow)

    const addTranslations = ({ item, depth }) => {
      const {
        id,
        elementType,
        titleEN,
        titleFR,
        tooltipEN,
        tooltipFR,
        helpTextEN,
        helpTextFR,
        elements
      } = item
      const translationSctructure = formTranslationsStructure[elementType]
      const titleLabel = titleEN || ''

      if (elements || translationSctructure) {
        if (titleEN || titleFR) {
          const titleRow = {
            id,
            translationPath: 'title',
            location: titleLabel + ' (Title)'
          }
          Object.values(portalLanguagesData).forEach(obj => {
            titleRow[obj.editKey] =
              item.title && typeof item.title === 'object'
                ? item.title[obj.editKey]
                : item['title' + String(obj.editKey).toUpperCase()]
          })
          sheet.addRow(titleRow)
        }
      }
      if (translationSctructure) {
        //Handle common translations of form elmenet
        if (tooltipEN || tooltipFR) {
          const tooltipRow = {
            id,
            translationPath: 'tooltip',
            location: titleLabel + ' (Tooltip)'
          }
          Object.values(portalLanguagesData).forEach(obj => {
            tooltipRow[obj.editKey] =
              item.tooltip && typeof item.tooltip === 'object'
                ? item.tooltip[obj.editKey]
                : item['tooltip' + String(obj.editKey).toUpperCase()]
          })
          sheet.addRow(tooltipRow)
        }
        if (helpTextEN || helpTextFR) {
          const helpTextRow = {
            id,
            translationPath: 'helpText',
            location: titleLabel + ' (Help Text)'
          }
          Object.values(portalLanguagesData).forEach(obj => {
            helpTextRow[obj.editKey] =
              item.helpText && typeof item.helpText === 'object'
                ? item.helpText[obj.editKey]
                : item['helpText' + String(obj.editKey).toUpperCase()]
          })
          sheet.addRow(helpTextRow)
        }
        //Handle translations specific to form elment
        Object.keys(translationSctructure).forEach(key => {
          const { label, isArray, keys, nonUppercase, keyOverride } =
            translationSctructure[key]

          if (isArray) {
            const array = item.typeProps[key] || []
            array.forEach((child, index) => {
              keys.forEach(subKey => {
                const subLabel =
                  subKey.charAt(0).toUpperCase() + subKey.slice(1)
                const row = {
                  id,
                  translationPath:
                    'typeProps.' + key + '.' + index + '.' + subKey,
                  location:
                    titleLabel + ' (' + label + ' ' + index + ') - ' + subLabel
                }
                let valid = false
                Object.values(portalLanguagesData).forEach(obj => {
                  const oldKey = !nonUppercase
                    ? String(obj.editKey).toUpperCase()
                    : obj.editKey.charAt(0).toUpperCase() + obj.editKey.slice(1)
                  const text = child[subKey]
                    ? child[subKey][obj.editKey]
                    : child[subKey + oldKey]
                  row[obj.editKey] = text
                  if (text) {
                    valid = true
                  }
                })
                if (valid) {
                  sheet.addRow(row)
                }
              })
            })
          } else {
            const row = {
              id,
              translationPath: 'typeProps.' + key,
              location: titleLabel + ' (' + label + ')'
            }
            let valid = false
            Object.values(portalLanguagesData).forEach(obj => {
              let oldKey = !nonUppercase
                ? String(obj.editKey).toUpperCase()
                : obj.editKey.charAt(0).toUpperCase() + obj.editKey.slice(1)
              if (keyOverride) {
                oldKey = keyOverride[obj.editKey]
              }

              const text =
                item.typeProps[key] && !keyOverride
                  ? item.typeProps[key][obj.editKey]
                  : item.typeProps[key + oldKey]
              row[obj.editKey] = text
              if (text) {
                valid = true
              }
            })
            if (valid) {
              sheet.addRow(row)
            }
          }
        })
      }
    }

    const checkForTranslations = ({ item, depth }) => {
      addTranslations({ item, depth })
      if (item.elements) {
        item.elements.forEach((child, index) => {
          checkForTranslations({ item: child, depth: [...depth, index] })
        })
      }
    }

    section.elements.forEach((item, index) => {
      checkForTranslations({ item, depth: [sectionIndex, index] })
    })

    let rowIndex = 1
    for (rowIndex; rowIndex <= sheet.rowCount; rowIndex++) {
      sheet.getRow(rowIndex).alignment = {
        wrapText: true,
        vertical: 'top'
      }
    }
  })
  const buff = workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    saveAs(blob, fileName + '.xlsx')
  })
  return buff
}
