import { Card } from '@material-ui/core'
import Loading from 'egret/components/EgretLoadable/Loading'
import React from 'react'
import { snackbarClasses } from 'app/App'

export default function ProgressSnackbar (messege) {
  return (
    <Card
      style={{
        paddingLeft: 60,
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 15,
        ...snackbarClasses.variantInfo
      }}
    >
      <div style={{ width: 'auto', color: 'white' }}>
        {messege}
      </div>
      <Loading
        style={{ marginLeft: 10, marginRight: 0 }}
        link='/assets/images/chtc-logo-snackbar.svg'
      />
    </Card>
  )
}
