import React, { useState, useEffect } from 'react'
import {
  Grid,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TextField as TextField2,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
  Icon,
  IconButton
} from '@material-ui/core'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import { Field, useField, useFormikContext } from 'formik'
import { t, Trans } from '@lingui/macro'
import { NumberFormatCustom } from 'app/views/page-layouts/FormElement'
import { useDispatch, useSelector } from 'react-redux'
import { deleteBudgetLines } from 'app/services/sfAuth/sfData/sfOpportunity'
import { dateFormat } from 'app/appSettings'
import { globalValues } from 'app/utils/GlobalValues'
import { FormConnectToObject } from '../Common'
import { useSnackbar } from 'notistack'
import { myI18n } from 'translation/I18nConnectedProvider'
import {
  CurrencyFormat,
  CurrencyFormated,
  DefaultNumericFormat
} from 'app/views/common/Formats'
import * as Yup from 'yup'
import { requiredTrans } from '../../formTranslations'
import { Alert } from '@material-ui/lab'
import MUTextField from '../multiuser-input/MUTextField'
import { endEditingField } from '../../multiuser/grpcMultiuserEdit'
import { FormErrorText } from '../FormErrorLabel'
import { FormHelptext, FormHelptextWithParse } from '../../GroupElement'

const styles = theme => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
    // width: 800
  },
  labelColumn: {
    width: 250
  },
  dataColumn: {
    textAlign: 'center'
  }
})

export const formBudgetParseValueToCompare = v => {
  const toRet = { ...v }
  return toRet
}

export const formBudgetValueToText = v => ({
  en: <Trans>Data is too complex to show differences!</Trans>,
  fr: <Trans>Data is too complex to show differences!</Trans>
})

export const revenuesMap = [
  {
    key: 'Transformation Centre Grant',
    value: t`Transformation Centre Grant`
  },
  { key: 'Other Grants', value: t`Other Grants` },
  { key: 'Fundraising/Donations', value: t`Fundraising/Donations` },
  { key: 'Internal Revenue', value: t`Internal Revenue` }
]

export const expensesMap = [
  {
    key: 'Personnel - Internal Wages and Benefits',
    value: t`Personnel - Internal Wages and Benefits`
  },
  {
    key: 'Personnel - External Professional/consultant',
    value: t`Personnel - External Professional/consultant`
  },
  {
    key: 'Direct costs (travel, materials, equipment, supplies, licensing, training costs)',
    value: t`Direct costs (travel, materials, equipment, supplies, licensing, training costs)`
  },
  {
    key: 'Indirect costs (Rental space, Insurance, Maintenance, etc)',
    value: t`Indirect costs (Rental space, Insurance, Maintenance, etc...)`
  },
  {
    key: 'Other',
    value: t`Other`
  }
]
const grantKey = 'Transformation Centre Grant'

const sanitizeLineKey = key => {
  if (key === 'Indirect costs (Rental space, Insurance, Maintenance, etc...)') {
    return 'Indirect costs (Rental space, Insurance, Maintenance, etc)'
  } else {
    return key
  }
}

const parseLineKeySF = key => {
  if (key === 'Indirect costs (Rental space, Insurance, Maintenance, etc)') {
    return 'Indirect costs (Rental space, Insurance, Maintenance, etc...)'
  } else {
    return key
  }
}

Yup.addMethod(Yup.object, 'checkBudgetValidity', function () {
  return this.test('checkBudgetValidity', requiredTrans, function (object) {
    const sum = calcBudgetTotal(object)
    const { path, createError } = this
    if (sum !== 0) {
      return createError({
        path,
        message: <Trans>Budget expenses are not equal to budget revenues</Trans>
      })
    }
    return true
  })
})

Yup.addMethod(Yup.object, 'checkAmountRequestedValidity', function (id) {
  return this.test(
    'checkAmountRequestedValidity',
    requiredTrans,
    function (object) {
      if (!id) {
        return true
      }
      const { path, createError, parent } = this
      const requestedAmount = Number(parent[id])
      let sum = 0
      for (const yearKey in object.revenues) {
        if (object.revenues[yearKey][grantKey]) {
          const value = Number(object.revenues[yearKey][grantKey].value)
          sum += value
        }
      }
      if (sum !== requestedAmount) {
        return createError({
          path,
          message: (
            <Trans>
              Amount requested and centre's grant in budget do not match!
            </Trans>
          )
        })
      }
      return true
    }
  )
})

Yup.addMethod(
  Yup.object,
  'checExpensesLinesValidity',
  function (validLines = []) {
    return this.test(
      'checExpensesLinesValidity',
      requiredTrans,
      function (object) {
        const { path, createError } = this
        const errors = []
        Object.keys(object).forEach(year => {
          const budgetObj = object[year]
          Object.keys(budgetObj)
            .filter(line => validLines.includes(line))
            .forEach(line => {
              const valueObj = budgetObj[line]
              const expense = valueObj.value || 0
              if (expense && !valueObj.comment) {
                errors.push(
                  new Yup.ValidationError(
                    requiredTrans,
                    valueObj.comment,
                    path + `[${year}][${line}].comment`
                  )
                )
              }
            })
        })
        if (errors.length === 0) {
          return true
        }
        return new Yup.ValidationError(errors)
      }
    )
  }
)

export const FormBudgetError = error => {
  if (!error.expenses) {
    return error
  }
  return (
    <ul>
      {Object.keys(error.expenses)
        .filter(key => error.expenses[key])
        .map(key => {
          const lines = error.expenses[key]
          return (
            <li key={key}>
              <u>
                {key}
                {', '}
                <Trans>Expenses</Trans>
              </u>
              <ul>
                {lines &&
                  Object.keys(lines).map(line => {
                    const error = lines[line].comment
                    if (!error) {
                      return null
                    }
                    return (
                      <li key={line}>
                        <b>
                          {myI18n._(t`${line}`) + ' ['}
                          <Trans>Comments</Trans>
                          {']: '}
                        </b>

                        <Trans id={error.props.id} />
                      </li>
                    )
                  })}
              </ul>
            </li>
          )
        })}
    </ul>
  )
}

export const formBudgetValidation = item => {
  const schemaObj = {}
  const {
    expensesMustMatchRevenues,
    expensesCommentsValidation,
    requestedAmountId
  } = item.typeProps
  if (expensesCommentsValidation) {
    const validLines = item.typeProps.expensesLines || []
    schemaObj.expenses = Yup.object({}).checExpensesLinesValidity(
      validLines.map(line => sanitizeLineKey(line))
    )
  }
  let toReturn =
    Yup.object(schemaObj).checkAmountRequestedValidity(requestedAmountId)
  if (expensesMustMatchRevenues) {
    toReturn = toReturn.checkBudgetValidity()
  }
  return toReturn
}

export const FormBudgetExtractKey = ({ saveMap, value, connectedObjectId }) => {
  const inner = []
  const mapping = globalValues.budgetCategories.en_CA
  for (const year in value.revenues) {
    for (const category in value.revenues[year]) {
      inner.push({
        FGM_Portal__Amount__c: value.revenues[year][category].value || 0,
        FGM_Portal__Note__c: value.revenues[year][category].comment,
        Id: value.revenues[year][category].id,
        FGM_Portal__Category__c: mapping.revenues[category],
        FGM_Portal__Grantee_Budget__r: {
          Name: year
        }
      })
    }
    for (const category in value.expenses[year]) {
      inner.push({
        FGM_Portal__Amount__c: value.expenses[year][category].value || 0,
        FGM_Portal__Note__c: value.expenses[year][category].comment,
        Id: value.expenses[year][category].id,
        FGM_Portal__Category__c: mapping.expenses[parseLineKeySF(category)],
        FGM_Portal__Grantee_Budget__r: {
          Name: year
        }
      })
    }
  }
  saveMap[connectedObjectId].FGM_Portal__Grantee_Budget_Line_Items__r = inner
}

const calcBudgetTotal = obj => {
  let sum = 0
  for (const yearKey in obj.revenues) {
    for (const section in obj.revenues[yearKey]) {
      sum += Number(obj.revenues[yearKey][section].value)
    }
  }
  for (const yearKey in obj.expenses) {
    for (const section in obj.expenses[yearKey]) {
      sum -= Number(obj.expenses[yearKey][section].value)
    }
  }
  return sum
}

export const FormBudgetDefaultValue = obj => {
  const ret = {
    revenues: {},
    expenses: {},
    invalid: [],
    budgetTotal: 0
  }
  if (!obj || !obj.FGM_Portal__Grantee_Budget_Line_Items__r) {
    console.log('load line empty', ret)
    return ret
  }
  let year
  for (const line of obj.FGM_Portal__Grantee_Budget_Line_Items__r.records) {
    if (!line.FGM_Portal__Category__r) {
      continue
    }
    year = parseInt(line.FGM_Portal__Grantee_Budget__r.Name, 10)
    if (
      line.FGM_Portal__Category__r.FGM_Portal__Parent_Category__r.Name ===
      'Revenue'
    ) {
      if (!ret.revenues[year]) {
        ret.revenues[year] = {}
      }
      const toSet = {
        value: line.FGM_Portal__Amount__c,
        id: line.Id,
        comment: line.FGM_Portal__Note__c || '',
        modifiedDate: line.LastModifiedDate
      }
      const alreadySet = ret.revenues[year][line.FGM_Portal__Category__r.Name]
      if (alreadySet) {
        if (
          moment(toSet.modifiedDate).isAfter(moment(alreadySet.modifiedDate))
        ) {
          ret.revenues[year][line.FGM_Portal__Category__r.Name] = toSet
          ret.invalid.push(alreadySet)
        } else {
          ret.invalid.push(toSet)
        }
      } else {
        ret.revenues[year][line.FGM_Portal__Category__r.Name] = toSet
      }
    } else {
      if (!ret.expenses[year]) {
        ret.expenses[year] = {}
      }
      const toSet = {
        value: line.FGM_Portal__Amount__c,
        modifiedDate: line.LastModifiedDate,
        id: line.Id,
        comment: line.FGM_Portal__Note__c || ''
      }
      const lineKey = sanitizeLineKey(line.FGM_Portal__Category__r.Name)
      const alreadySet = ret.expenses[year][lineKey]
      if (alreadySet) {
        if (
          moment(toSet.modifiedDate).isAfter(moment(alreadySet.modifiedDate))
        ) {
          ret.expenses[year][lineKey] = toSet
          ret.invalid.push(alreadySet)
        } else {
          ret.invalid.push(toSet)
        }
      } else {
        ret.expenses[year][lineKey] = toSet
      }
    }
  }

  ret.budgetTotal = calcBudgetTotal(ret)
  return ret
}

const useStyles = makeStyles(styles)

export const FormBudgetPrint = ({ title, value, typeProps }) => {
  const { expensesLines = [], revenuesLines = [] } = typeProps

  if (Object.keys(value.revenues).length === 0) {
    return (
      <>
        <div className='form-print-title'>{title}</div>
        <Alert severity='info'>
          <Trans>There are no lines added to budget of this application</Trans>
        </Alert>
      </>
    )
  }

  return (
    <>
      <div className='form-print-title'>{title}</div>
      {Object.keys(value.revenues).map(key => {
        const revenuesData = value.revenues[key] || {}
        const revenuesRender = revenuesLines.map(key => ({
          key,
          ...revenuesData[key]
        }))

        const expensesData = value.expenses[key] || {}
        const expensesRender = expensesLines.map(key => ({
          key: sanitizeLineKey(key),
          ...expensesData[sanitizeLineKey(key)]
        }))

        return (
          <div key={key}>
            <div style={{ padding: 8, textAlign: 'center' }}>
              <Typography className='form-print-subtitle'>
                <Trans>Year</Trans>
              </Typography>
              <Typography style={{ fontSize: 21 }}>{key}</Typography>
            </div>

            {/* <div
              className='form-print-subtitle-big'
              style={{
                marginTop: 20,
                pageBreakBefore: 'always',
                breakBefore: 'always'
              }}
            >
              <Trans>Revenues</Trans>
            </div> */}
            <Paper elevation={6} style={{ marginTop: 10, padding: 10 }}>
              <Grid container>
                <Grid item style={{ padding: 4, width: 300 }}>
                  <Typography className='form-print-subtitle'>
                    <Trans>Revenue</Trans>
                  </Typography>
                </Grid>
                <Grid item style={{ padding: 4, width: 170 }}>
                  <Typography className='form-print-subtitle'>
                    <Trans>Value</Trans>
                  </Typography>
                </Grid>
                <Grid item xs style={{ padding: 4 }}>
                  <Typography className='form-print-subtitle'>
                    <Trans>Comment</Trans>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              {revenuesRender.map((obj, index) => {
                return (
                  <Grid container key={index}>
                    <Grid item style={{ padding: 4, width: 300 }}>
                      <Typography style={{ whiteSpace: 'pre-line' }}>
                        <Trans id={obj.key} />
                      </Typography>
                    </Grid>
                    <Grid item style={{ padding: 4, width: 170 }}>
                      <Typography style={{ whiteSpace: 'pre-line' }}>
                        <CurrencyFormated value={obj.value} />
                      </Typography>
                    </Grid>
                    <Grid item xs style={{ padding: 4 }}>
                      <Typography style={{ minHeight: 21 }}>
                        {obj.comment}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              })}
            </Paper>

            {/* <div
              className='form-print-subtitle-big'
              style={{
                marginTop: 20,
                pageBreakBefore: 'always',
                breakBefore: 'always'
              }}
            >
              <Trans>Expenses</Trans>
            </div> */}
            <Paper
              elevation={6}
              style={{ marginTop: 10, padding: 10 }}
              className='avoid-print-break'
            >
              <Grid container>
                <Grid item style={{ padding: 4, width: 300 }}>
                  <Typography className='form-print-subtitle'>
                    <Trans>Expense</Trans>
                  </Typography>
                </Grid>
                <Grid item style={{ padding: 4, width: 170 }}>
                  <Typography className='form-print-subtitle'>
                    <Trans>Value</Trans>
                  </Typography>
                </Grid>
                <Grid item xs style={{ padding: 4 }}>
                  <Typography className='form-print-subtitle'>
                    <Trans>Comment</Trans>
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              {expensesRender.map((obj, index) => {
                return (
                  <Grid container key={index}>
                    <Grid item style={{ padding: 4, width: 300 }}>
                      <Typography style={{ whiteSpace: 'pre-line' }}>
                        <Trans id={obj.key} />
                      </Typography>
                    </Grid>
                    <Grid item style={{ padding: 4, width: 170 }}>
                      <Typography style={{ whiteSpace: 'pre-line' }}>
                        <CurrencyFormated value={obj.value} />
                      </Typography>
                    </Grid>
                    <Grid item xs style={{ padding: 4 }}>
                      <Typography style={{ minHeight: 21 }}>
                        {obj.comment}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              })}
            </Paper>
          </div>
        )
      })}
    </>
  )
}

export const FormBudget = ({
  id,
  langFR,
  useMultiuser,
  muBag,
  title,
  connectedObject,
  editMode,
  reloadLastModifiedDates,
  typeProps,
  ...props
}) => {
  let disabled = props.disabled
  let warning
  const { values, setFieldValue, setValues } = useFormikContext()
  const [field, meta] = useField(id)
  const value = values[id] || {}
  const { revenues, expenses } = value
  const classes = useStyles()
  const {
    milestonesId,
    requestedAmountId,
    revenuesLines = [],
    expensesLines = [],
    commentsRows,
    commentsExpandable,
    revenuesHelpTextEn,
    revenuesHelpTextFr,
    expensesHelpTextEn,
    expensesHelpTextFr
  } = typeProps
  const milestones = values[milestonesId]
  const amountRequested = +values[requestedAmountId]
  let projectStartDate, projectEndDate
  try {
    projectStartDate = milestones[0].startDate
    projectStartDate =
      projectStartDate && moment.utc(projectStartDate).format(dateFormat)
  } catch (e) {
    //console.warn('no milestones', e)
  }

  try {
    projectEndDate = milestones[milestones.length - 1].endDate
    projectEndDate =
      projectEndDate && moment.utc(projectEndDate).format(dateFormat)
  } catch (e) {
    //console.warn('no milestones', e)
  }
  const startDate = moment(projectStartDate).utc().year()
  const endDate = moment(projectEndDate).utc().year()

  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== 'Opportunity'
  )

  useEffect(() => {
    if (amountRequested) {
      const toSet = { ...value.revenues }
      const baseValue = Math.floor(+amountRequested / Object.keys(toSet).length)
      Object.keys(toSet).forEach(year => {
        toSet[year][grantKey].value = baseValue
      })
      //if (!valueObj.value && valueObj.value !== 0) {
      setFieldValue(id + `.revenues`, toSet)
      //}
    }
  }, [amountRequested])

  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>
          There is no object connected in editor or connected object is not of
          "Opportunity" type!
        </Trans>
      </div>
    )
  }
  if (!milestonesId || !requestedAmountId) {
    return (
      <div style={{ color: 'red' }}>
        <b>
          <Trans>
            Either Milestones or Requested Amount field were not connected in
            form editor!
          </Trans>
        </b>
      </div>
    )
  }

  if (!projectStartDate && !projectEndDate) {
    disabled = true
    warning = (
      <div style={{ margin: 20 }}>
        <Typography style={{ marginLeft: 25, fontSize: '21px', color: 'red' }}>
          <Trans>
            You need to set project dates in Milestones to edit the project
            Budget!
          </Trans>
        </Typography>
      </div>
    )
  }

  const revenuesMap = revenuesLines.map(line => ({
    key: line,
    value: myI18n._(line)
  }))
  const expensesMap = expensesLines.map(line => {
    return {
      key: sanitizeLineKey(line),
      value: myI18n._(line)
    }
  })

  const createTable = () => {
    const columns = []
    const expensesHelpText = langFR ? expensesHelpTextFr : expensesHelpTextEn
    const revenuesHelpText = langFR ? revenuesHelpTextFr : revenuesHelpTextEn
    let y = startDate
    const sums = {
      total: {
        revenues: 0,
        expenses: 0,
        revenuesTotal: Object.fromEntries(
          revenuesMap.map(item => [item.key, 0])
        ),
        expenseTotal: Object.fromEntries(expensesMap.map(item => [item.key, 0]))
      }
    }

    const baseLines = expenses || {}
    Object.keys(baseLines).forEach((key, index) => {
      columns.push(+key)
      sums[key] = {
        revenues: 0,
        expenses: 0
      }
    })
    for (y; y <= endDate; y++) {
      if (!columns.includes(y)) {
        columns.push(y)
        sums[y] = {
          revenues: 0,
          expenses: 0
        }
      }
    }

    // fill columns with 0 if new columns are added
    for (const i of columns) {
      if (!expenses[i]) {
        expenses[i] = Object.fromEntries(
          expensesMap.map(item => [item.key, { value: 0 }])
        )
      }
      if (!revenues[i]) {
        revenues[i] = Object.fromEntries(
          revenuesMap.map(item => [item.key, { value: 0 }])
        )
      }
    }
    // count sums of fields

    columns.forEach((i, index) => {
      for (const j in revenues[i]) {
        sums[i].revenues += +revenues[i][j].value || 0
        sums.total.revenuesTotal[j] += +revenues[i][j].value || 0
      }
      for (const j in expenses[i]) {
        sums[i].expenses += +expenses[i][j].value || 0
        sums.total.expenseTotal[j] += +expenses[i][j].value || 0
      }
      sums.total.expenses += sums[i].expenses
      sums.total.revenues += sums[i].revenues
    })

    const columnsSorted = columns.sort((a, b) => a - b)

    return (
      <Table
        style={{
          whiteSpace: 'pre',
          backgroundColor: '',
          border: 'solid 1px #cccccc',
          borderTop: 'none'
        }}
      >
        <TableHead>
          <TableRow
            style={{
              backgroundColor: '#f5f5f5',
              borderTop: 'solid 1px #cccccc'
            }}
          >
            <TableCell
              style={{ textAlign: 'center' }}
              className={classes.labelColumn}
            >
              <Trans>Revenues</Trans>/<Trans>Expenses</Trans>
            </TableCell>
            <TableCell style={{ textAlign: 'center', maxWidth: 120 }}>
              <Trans>Total</Trans>
            </TableCell>
            {columnsSorted.map((year, index) => {
              const invalid = year > endDate || year < startDate
              return (
                <TableCell
                  key={index}
                  style={{
                    textAlign: 'center',
                    color: invalid && '#f5543b'
                  }}
                >
                  {' '}
                  {year}{' '}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              style={{ paddingLeft: 20 }}
              colSpan={2 + columnsSorted.length}
            >
              <b style={{ marginLeft: 10 }}>
                <Trans>Revenues</Trans>
              </b>
              <FormHelptextWithParse helpText={revenuesHelpText} />
            </TableCell>
          </TableRow>
          {revenuesMap.map((item, index) => {
            return (
              <>
                <TableRow key={index}>
                  <TableCell style={{ paddingLeft: 40 }}>
                    <Trans id={item.value} />
                  </TableCell>
                  <TableCell
                    className={classes.dataColumn}
                    style={{
                      textAlign: 'center',
                      padding: 15
                    }}
                  >
                    <CurrencyFormated
                      value={sums.total.revenuesTotal[item.key]}
                    />
                  </TableCell>
                  {columnsSorted.map((year, columnIndex) => {
                    const invalid = year > endDate || year < startDate
                    return (
                      <TableCell
                        key={columnIndex}
                        style={{ verticalAlign: 'top' }}
                      >
                        <Grid
                          container
                          direction='column'
                          style={{
                            height: '100%',
                            flexGrow: 1
                          }}
                        >
                          <MUTextField
                            id={id + `.revenues[${year}]['${item.key}'].value`}
                            useMultiuser={useMultiuser}
                            muBag={muBag}
                            displayFieldHistoryIcon={true}
                            elementType='textInputNumeric'
                            style={{ paddingRight: 15 }}
                            InputProps={{
                              inputComponent: CurrencyFormat
                            }}
                            inputProps={{
                              style: {
                                textAlign: 'center',
                                color: invalid && '#f5543b'
                              }
                            }}
                            size='small'
                            type='number'
                            placeholder={langFR ? '0 $' : '$0'}
                            disabled={disabled || invalid}
                          />

                          <MUTextField
                            id={
                              id + `.revenues[${year}]['${item.key}'].comment`
                            }
                            label={myI18n._(t`Comments`)}
                            multiline
                            minRows={commentsRows || 1}
                            maxRows={
                              commentsExpandable ? null : commentsRows || 1
                            }
                            useMultiuser={useMultiuser}
                            muBag={muBag}
                            displayFieldHistoryIcon={true}
                            style={{ paddingRight: 15, marginTop: 10 }}
                            inputProps={{
                              style: {
                                textAlign: 'center',
                                color: invalid && '#f5543b'
                              }
                            }}
                            size='small'
                            disabled={disabled || invalid}
                          />
                        </Grid>
                      </TableCell>
                    )
                  })}
                </TableRow>
                {item.key === 'Transformation Centre Grant' &&
                  meta.error &&
                  !meta.error.expenses && (
                    <div style={{ marginTop: 10 }}>
                      <FormErrorText msg={FormBudgetError(meta.error)} />
                    </div>
                  )}
              </>
            )
          })}
          <TableRow>
            <TableCell
              style={{ paddingLeft: 20 }}
              colSpan={2 + columnsSorted.length}
            >
              <b style={{ marginLeft: 10 }}>
                <Trans>Expenses</Trans>
              </b>
              <FormHelptextWithParse helpText={expensesHelpText} />
            </TableCell>
          </TableRow>
          {expensesMap.map((item, index) => (
            <TableRow key={index}>
              <TableCell style={{ paddingLeft: 40 }}>
                <Trans id={item.value} />
              </TableCell>
              <TableCell className={classes.dataColumn}>
                <CurrencyFormated value={sums.total.expenseTotal[item.key]} />
              </TableCell>
              {columnsSorted.map((year, columnIndex) => {
                const invalid = year > endDate || year < startDate
                return (
                  <TableCell key={columnIndex} style={{ verticalAlign: 'top' }}>
                    <MUTextField
                      id={id + `.expenses[${year}]['${item.key}'].value`}
                      useMultiuser={useMultiuser}
                      displayFieldHistoryIcon={true}
                      elementType='textInputNumeric'
                      muBag={muBag}
                      InputProps={{
                        inputComponent: CurrencyFormat
                      }}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          color: invalid && '#f5543b'
                        }
                      }}
                      type='number'
                      size='small'
                      placeholder={langFR ? '0 $' : '$0'}
                      style={{ paddingRight: 15 }}
                      disabled={disabled || invalid}
                    />

                    <MUTextField
                      id={id + `.expenses[${year}]['${item.key}'].comment`}
                      label={myI18n._(t`Comments`)}
                      multiline
                      showErrorWithoutTouch
                      minRows={commentsRows || 1}
                      maxRows={commentsExpandable ? null : commentsRows || 1}
                      useMultiuser={useMultiuser}
                      displayFieldHistoryIcon={true}
                      muBag={muBag}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          color: invalid && '#f5543b'
                        }
                      }}
                      size='small'
                      style={{ paddingRight: 15, marginTop: 10 }}
                      disabled={disabled || invalid}
                    />
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell style={{ paddingLeft: 20 }}>
              <b>
                <Trans>Sum of revenue</Trans>
              </b>
            </TableCell>
            <TableCell className={classes.dataColumn}>
              <CurrencyFormated value={sums.total.revenues} />
            </TableCell>
            {columnsSorted.map((year, index) => {
              const invalid = year > endDate || year < startDate
              return (
                <TableCell
                  key={index}
                  style={{
                    textAlign: 'center',
                    paddingRight: 30,
                    color: invalid && '#f5543b'
                  }}
                >
                  {sums[year] && (
                    <CurrencyFormated value={sums[year].revenues} />
                  )}
                </TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingLeft: 20 }}>
              <b>
                <Trans>Sum of expenses</Trans>
              </b>
            </TableCell>
            <TableCell className={classes.dataColumn}>
              <CurrencyFormated value={sums.total.expenses} />
            </TableCell>
            {columnsSorted.map((year, index) => {
              const invalid = year > endDate || year < startDate
              return (
                <TableCell
                  key={index}
                  style={{
                    textAlign: 'center',
                    paddingRight: 30,
                    color: invalid && '#f5543b'
                  }}
                >
                  {sums[year] && (
                    <CurrencyFormated value={sums[year].expenses} />
                  )}
                </TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingLeft: 20 }}>
              <b>
                <Trans>Total of revenue - expenses</Trans>
              </b>
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <CurrencyFormated
                value={sums.total.revenues - sums.total.expenses}
              />
            </TableCell>
            {columnsSorted.map((year, index) => {
              const invalid = year > endDate || year < startDate
              return (
                <TableCell
                  key={index}
                  style={{
                    textAlign: 'center',
                    paddingRight: 30,
                    color: invalid && '#f5543b'
                  }}
                >
                  {sums[year] && (
                    <CurrencyFormated
                      value={sums[year].revenues - sums[year].expenses}
                    />
                  )}
                </TableCell>
              )
            })}
          </TableRow>
        </TableFooter>
      </Table>
    )
  }

  return (
    <Paper className={classes.root}>
      <div style={{ padding: 20 }}>
        <h3>{title}</h3>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography variant='h6'>
              <Trans>Project Start Date:</Trans>
            </Typography>{' '}
            {projectStartDate}
            <br />
            <Typography variant='h6'>
              <Trans>Project End Date:</Trans>
            </Typography>{' '}
            {projectEndDate}
          </Grid>
        </Grid>
        <div style={{ padding: 20 }}>
          <DeleteExtraLinesButton
            setFieldValue={(id, value) => {
              if (useMultiuser) {
                endEditingField({
                  ...muBag,
                  fieldId: id,
                  fieldValue: value
                })
              }
              setFieldValue(id, value)
            }}
            disabled={disabled}
            id={id}
            value={value}
            start={projectStartDate}
            end={projectEndDate}
            reloadLastModifiedDates={reloadLastModifiedDates}
          />
        </div>
        {warning}
        <div className='w-100 overflow-auto' style={{ padding: 20 }}>
          <TableContainer component={Paper}>{createTable()}</TableContainer>
        </div>
      </div>
    </Paper>
  )
}

const DeleteExtraLinesButton = ({
  end,
  disabled,
  start,
  value,
  id,
  setFieldValue,
  reloadLastModifiedDates
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [deleting, setDeleting] = useState(false)
  const endYear = moment(end).utc().year()
  const startYear = moment(start).utc().year()
  const invalidLines = []
  Object.keys(value.expenses).forEach(year => {
    const yearObj = value.expenses[year]
    const array = Object.values(yearObj)
    const isInvalid = year > endYear || year < startYear
    array.forEach(line => {
      if (isInvalid) {
        invalidLines.push(line)
      }
    })
  })
  Object.keys(value.revenues).forEach(year => {
    const yearObj = value.revenues[year]
    const array = Object.values(yearObj)
    const isInvalid = year > endYear || year < startYear
    array.forEach(line => {
      if (isInvalid) {
        invalidLines.push(line)
      }
    })
  })
  if (value.invalid) {
    value.invalid.forEach(line => {
      invalidLines.push(line)
    })
  }

  return (
    (invalidLines.length > 0 || deleting) && (
      <div>
        <Typography style={{ marginBottom: 10, color: 'red' }}>
          <Trans>
            There are invalid (Either exceeding project date range you specified
            or created due to two users editing budget at the same time) budget
            lines added to your aplication!
          </Trans>
        </Typography>
        <Button
          disabled={deleting || disabled}
          variant='contained'
          color='primary'
          onClick={e => {
            setDeleting(true)
            const newValue = { ...value }
            newValue.invalid = []
            const toDelete = []
            const snackbar = enqueueSnackbar(
              <Trans>Deleting extra budget lines</Trans>,
              {
                variant: 'info',
                persist: true
              }
            )
            Object.keys(newValue.revenues).forEach(year => {
              const isInvalid = year > endYear || year < startYear
              if (isInvalid) {
                delete newValue.revenues[year]
              }
            })
            Object.keys(newValue.expenses).forEach(year => {
              const isInvalid = year > endYear || year < startYear
              if (isInvalid) {
                delete newValue.expenses[year]
              }
            })
            invalidLines.forEach(line => {
              if (line.id) {
                toDelete.push(line.id)
              }
            })
            deleteBudgetLines(toDelete).then(
              result => {
                reloadLastModifiedDates().then(r => {
                  setDeleting(false)
                  closeSnackbar(snackbar)
                  const isError = result.some(result => !result.success)
                  if (isError) {
                    enqueueSnackbar(
                      <Trans>
                        Error ocurred while deleting extra budget lines
                      </Trans>,
                      {
                        variant: 'error'
                      }
                    )
                  } else {
                    enqueueSnackbar(<Trans>Deleted extra budget lines</Trans>, {
                      variant: 'success'
                    })
                    setFieldValue(id, newValue)
                  }
                })
              },
              reject => {
                setDeleting(false)
                closeSnackbar(snackbar)
                enqueueSnackbar(
                  <Trans>
                    Error ocurred while deleting extra budget lines
                  </Trans>,
                  {
                    variant: 'error'
                  }
                )
              }
            )
          }}
        >
          <Trans>Delete invalid budget lines</Trans>
        </Button>
      </div>
    )
  )
}

export const FormEditorBudget = ({
  id,
  showPrintProps,
  editMode,
  depth,
  typeProps = {},
  ...props
}) => {
  const {
    milestonesId,
    expensesCommentsValidation,
    requestedAmountId,
    expensesMustMatchRevenues,
    commentsRows,
    commentsExpandable,
    revenuesHelpTextEn,
    revenuesHelpTextFr,
    expensesHelpTextEn,
    expensesHelpTextFr
  } = typeProps
  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)
  const mapping = globalValues.budgetCategories.en_CA

  if (!editMode) {
    return <FormBudget editMode id={id} typeProps={typeProps} {...props} />
  }

  const mapElements = (data, type) => {
    const returnArray = []
    data.sections.forEach(section =>
      section.elements.forEach(item =>
        mapItem({ item, returnArray, section, type })
      )
    )
    return returnArray
  }

  const mapItem = ({ item, french, returnArray, section, type }) => {
    if (item.elements) {
      item.elements.forEach(element =>
        mapItem({ item: element, returnArray, section, type })
      )
    } else {
      if (id !== item.id && item.elementType === type) {
        const title = french ? item.titleFR : item.titleEN
        const sectionName = french ? section.titleFR : section.titleEN
        returnArray.push({
          id: item.id,
          label: '[' + sectionName + '] ' + title
        })
      }
    }
  }
  const avaliableElementsMilestones = mapElements(tree, 'milestones')
  const avaliableElementsNumeric = mapElements(tree, 'textInputNumeric')

  return (
    <div>
      <TextField2
        disabled={avaliableElementsMilestones.length === 0}
        error={avaliableElementsMilestones.length === 0}
        helperText={
          avaliableElementsMilestones.length === 0 && (
            <Trans>
              You need to add at least one elements of Milestones type to link
              to this element
            </Trans>
          )
        }
        style={{ marginTop: 10 }}
        value={milestonesId || ''}
        fullWidth
        variant='outlined'
        select
        label={<Trans>Milestones element</Trans>}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.milestonesId = e.target.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
      >
        {avaliableElementsMilestones.map((item, index) => {
          return (
            <MenuItem key={index} value={item.id}>
              {item.label}
            </MenuItem>
          )
        })}
      </TextField2>

      <TextField2
        disabled={avaliableElementsNumeric.length === 0}
        error={avaliableElementsNumeric.length === 0}
        helperText={
          avaliableElementsNumeric.length === 0 && (
            <Trans>
              You need to add at least one elements of Numeric Input type to
              link to this element
            </Trans>
          )
        }
        style={{ marginTop: 10 }}
        value={requestedAmountId || ''}
        fullWidth
        variant='outlined'
        select
        label={<Trans>Requested amount element</Trans>}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.requestedAmountId = e.target.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
      >
        {avaliableElementsNumeric.map((item, index) => {
          return (
            <MenuItem key={index} value={item.id}>
              {item.label}
            </MenuItem>
          )
        })}
      </TextField2>

      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(expensesMustMatchRevenues)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.expensesMustMatchRevenues = e.target.checked
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={
            <Trans>
              Require expenses to match revenues in element validation
            </Trans>
          }
        />
      </div>

      <h6 style={{ marginTop: 10, marginBottom: 5 }}>
        <Trans>Comments field</Trans>
      </h6>
      <Grid container alignItems='center'>
        <TextField2
          label={<Trans>Rows</Trans>}
          value={commentsRows || ''}
          InputProps={{ inputComponent: DefaultNumericFormat }}
          variant='outlined'
          onChange={e => {
            const toSet = { ...typeProps }
            toSet.commentsRows = e.target.value
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: { ...toSet }
            })
          }}
        />

        <FormControlLabel
          style={{ marginLeft: 10 }}
          control={
            <Checkbox
              checked={Boolean(commentsExpandable)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.commentsExpandable = e.target.checked
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Allow expanding rows?</Trans>}
        />
      </Grid>

      <h6 style={{ marginTop: 10, marginBottom: 10 }}>
        <Trans>Help texts</Trans>
      </h6>

      <Grid container>
        <Grid item xs style={{ padding: 5 }}>
          <TextField2
            label={<Trans>Revenues help text - English</Trans>}
            value={revenuesHelpTextEn || ''}
            variant='outlined'
            fullWidth
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.revenuesHelpTextEn = e.target.value
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ padding: 5 }}>
          <TextField2
            label={<Trans>Revenues help text - French</Trans>}
            value={revenuesHelpTextFr || ''}
            variant='outlined'
            fullWidth
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.revenuesHelpTextFr = e.target.value
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs style={{ padding: 5 }}>
          <TextField2
            label={<Trans>Expenses help text - English</Trans>}
            value={expensesHelpTextEn || ''}
            variant='outlined'
            fullWidth
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.expensesHelpTextEn = e.target.value
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ padding: 5 }}>
          <TextField2
            label={<Trans>Expenses help text - French</Trans>}
            value={expensesHelpTextFr || ''}
            variant='outlined'
            fullWidth
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.expensesHelpTextFr = e.target.value
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
      </Grid>

      {[
        {
          headerLabel: <Trans>Revenues lines</Trans>,
          key: 'revenuesLines',
          mapping: mapping.revenues
        },
        {
          headerLabel: <Trans>Expenses lines</Trans>,
          key: 'expensesLines',
          mapping: mapping.expenses
        }
      ].map(obj => {
        const { headerLabel, key, mapping } = obj
        const array = typeProps[key] || []
        return (
          <>
            <Grid container alignItems='center' style={{ marginTop: 10 }}>
              <Grid item>
                <h6 style={{ marignTop: 5, marginBottom: 5 }}>{headerLabel}</h6>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  style={{ marginLeft: 10 }}
                  onClick={e => {
                    const toSet = { ...typeProps }
                    const newLines = [...array]
                    newLines.push('')
                    toSet[key] = newLines
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                >
                  <Trans>Add line</Trans>
                  <Icon style={{ marginLeft: 5 }}>add</Icon>
                </Button>
              </Grid>
              {key === 'expensesLines' && (
                <Grid item>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Checkbox
                        checked={Boolean(expensesCommentsValidation)}
                        onChange={e => {
                          const toSet = { ...typeProps }
                          toSet.expensesCommentsValidation = e.target.checked
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: { ...toSet }
                          })
                        }}
                      />
                    }
                    label={
                      <Trans>
                        Set comments as required if expense is filled?
                      </Trans>
                    }
                  />
                </Grid>
              )}
            </Grid>
            <div style={{ marginTop: 5 }}>
              {array.map((line, index) => {
                return (
                  <div key={index} style={{ padding: 5 }}>
                    <Grid container alignItems='center'>
                      <Grid item style={{ marginRight: 5 }}>
                        <b>{Number(index + 1) + '. '}</b>
                      </Grid>
                      <Grid item xs>
                        <TextField2
                          label={<Trans>Budget category</Trans>}
                          fullWidth
                          variant='outlined'
                          value={line || ''}
                          onChange={e => {
                            const toSet = { ...typeProps }
                            toSet[key][index] = e.target.value
                            dispatch({
                              type: 'FIELD',
                              depth: depth.split('.'),
                              fieldName: 'typeProps',
                              fieldValue: toSet
                            })
                          }}
                          select
                        >
                          {Object.keys(mapping).map(key => (
                            <MenuItem key={mapping[key]} value={key}>
                              {myI18n._(key)}
                            </MenuItem>
                          ))}
                        </TextField2>
                      </Grid>
                      <IconButton
                        size='small'
                        onClick={e => {
                          const toSet = { ...typeProps }
                          const toMove = toSet[key][index]
                          const toReplace = toSet[key][index + 1]
                          toSet[key][index + 1] = toMove
                          toSet[key][index] = toReplace
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: { ...toSet }
                          })
                        }}
                        disabled={index === array.length - 1}
                      >
                        <Icon>arrow_downward</Icon>
                      </IconButton>
                      <IconButton
                        size='small'
                        onClick={e => {
                          const toSet = { ...typeProps }
                          const toMove = toSet[key][index]
                          const toReplace = toSet[key][index - 1]
                          toSet[key][index - 1] = toMove
                          toSet[key][index] = toReplace
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: { ...toSet }
                          })
                        }}
                        disabled={index === 0}
                      >
                        <Icon>arrow_upward</Icon>
                      </IconButton>
                      <IconButton
                        onClick={e => {
                          const toSet = { ...typeProps }
                          toSet[key].splice(index, 1)
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: { ...toSet }
                          })
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Grid>
                  </div>
                )
              })}
            </div>
          </>
        )
      })}

      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
    </div>
  )
}
