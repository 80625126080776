/**
 * Created by Michał Stawski on 25.11.2020.
 */
import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getBudgetCategories } from '../services/sfAuth/sfData/sfBudgetCategory'
import { getRecordTypes } from '../services/sfAuth/sfData/sfOpportunity'

export const globalValues = {}

export const GlobalValues = ({ children }) => {
  const user = useSelector(state => state.user)
  useEffect(() => {
    if (user.id) {
      getBudgetCategories().then((result) => {
        globalValues.budgetCategories = result
      })
      // TODO move this to reducer
      getRecordTypes('FGM_Base__Grantee_Report__c', true).then(
        recordTypes => {
          console.log('report record types', recordTypes)
          globalValues.reportRecordTypes = recordTypes
        }
      )
      // // debug code for checking objects
      // getTasks().then(tasks => {
      //   console.log('tasks', tasks)
      // })
      //   .catch(error => console.error('tasks', error))
    }
  }, [user.id])

  return <Fragment>{children}</Fragment>
}
