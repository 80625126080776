import SFAuthService, { NO_USER } from '../SFAuthService'
import { mapSFToForm, reverseMap } from '../sfDataService'
import { t } from '@lingui/macro'
import { readOlyFieledMTF } from './jsConnectionUtils'

const mapFields = {
  direction: 'in',
  Id: 'id',
  ...readOlyFieledMTF('User__c', 'user'),
  ...readOlyFieledMTF('Opportunity__c', 'opportunity'),
  ...readOlyFieledMTF('CreatedDate', 'createdData'),
  // Opportunity__c: 'opportunity',
  // CreatedDate: 'createdData',
  ...readOlyFieledMTF('ModifiedDate', 'modifiedDate'),
  ...readOlyFieledMTF('Due_date__c', 'dueDate'),
  Status__c: 'status',
  Survey__c: 'survey',
  Snapshot_About_Me__c: 'snapshotAboutMe',
  Snapshot_Review__c: 'snapshotReview',
  Reviewer_Comments__c: 'reviewerComments',
  Date_accepted_or_declined__c: 'acceptedDate',
  Conflict_Of_Interest_Declaration__c: 'conflictOfInterestDeclaration'
  // Name: 'organisationsName',
  // Other_used_name__c: 'otherName',
}

const reverseMapFields = reverseMap(mapFields)

export const getReviewAssignements = userId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Review_Assignement__c').find({ User__c: userId })
}

export const getReviewAssignement = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Review_Assignement__c').retrieve(id)
}

export const getReviewAssignementParsed = id => {
  // console.log('getSurveyParsed', id)
  return getReviewAssignement(id).then(user => mapSFToForm(mapFields, user))
}

export const saveReviewAssignement = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Update_Review_Assignment', {
    inputs: [
      {
        obj: values
      }
    ]
  })
  //return conn.sobject('Review_Assignement__c').update(values)
}

export const saveReviewAssignementParsed = values => {
  // console.log('getSurveyParsed', id)(mapSFToForm(reverseMapFields, value))
  return saveReviewAssignement(mapSFToForm(reverseMapFields, values))
}

const mapFieldsExternalReviewer = {
  direction: 'in',
  // Id: 'id',
  Contact__c: 'contact',
  Additional_comments__c: 'additionalComments',
  Demographic_experience__c: 'additionalComments',
  Fields_of_expertise__c: 'additionalComments',
  Indigenous__c: 'additionalComments',
  Job_title__c: 'additionalComments',
  Professional_volunteer_experience__c: 'additionalComments',
  Project_types__c: 'additionalComments',
  Regional_experience__c: 'additionalComments',
  Sector_experience__c: 'additionalComments',
  Urban_rural__c: 'additionalComments'
}

export const upsertExternalReviewer = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('External_Reviewer__c')
    .upsert(values, 'Contact_External_Id__c')
}

export const getExternalReviews = searchParams => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('FGM_Base__Review__c')
    .find(searchParams)
    .select(['*', 'RecordType.Name'])
}

export const getExternalReviewer = contactId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('External_Reviewer__c').find({ Contact__c: contactId })
}
