import * as crypto from 'crypto'
import { clone, cloneDeep } from 'lodash'

const initialState = {
  sections: [],
  history: [],
  selectedElements: [],
  elementsInStack: [],
  injectableComponents: []
}

const formEditorReducer = (state = initialState, action) => {
  let newState = { ...state }
  const {
    sections,
    injectableComponents,
    nameEN,
    nameFR,
    currentStep,
    depth = [],
    hoverIndex,
    subfieldName,
    fieldName,
    fieldValue,
    fieldIndex,
    fieldArray,
    dragIndex,
    dragDepth = [],
    hoverDepth,
    select,
    sectionIndex,
    sectionMoveDirection,
    object,
    injectable = false,
    props = {}
  } = action
  const { loopAllDrop, insertIndex, emptySpace } = props
  let dragFrom,
    toDropAt,
    toChange,
    currentSection,
    targetSection,
    index,
    toReplace,
    toMove,
    toAdd
  const removeIndex = dragDepth[dragDepth.length - 1]
  const parentGroup = injectable
    ? newState.injectableComponents
    : newState.sections
  let toRemoveFrom = parentGroup
  const newSelected = []

  if (
    [
      'FIELD',
      'RESET_OBJECT_CONNECTED',
      'MOVE_SECTION',
      'MOVE_ITEM',
      'MOVE_ITEM_BETWEEN',
      'REMOVE_ITEM',
      'ADD_ELEMENT',
      'ADD_GROUP',
      'ADD_ELEMENT_INJECTABLE',
      'ADD_GROUP_INJECTABLE',
      'ADD_SECTION',
      'REMOVE_SECTION'
    ].includes(action.type)
  ) {
    if (fieldName !== 'dummy' && fieldName !== 'editMode') {
      const cloneState = cloneDeep({ ...newState })
      delete cloneState.history
      newState.history.push(cloneState)
      if (newState.history.length > 20) {
        newState.history.splice(0, 1)
      }
      newState.dirty = true
    }
  }

  switch (action.type) {
    case 'REVERT':
      if (newState.history) {
        const old = newState.history.splice(-1, 1)[0]
        if (old) {
          old.history = newState.history || []
          newState = old
        }
      }
      return newState
    case 'COPY':
      newState.copied = cloneDeep(object)
      return newState
    case 'ADD_COPIED_SECTION':
      toAdd = newState.copied
      breakReferences(toAdd)
      newState.sections.push(toAdd)
      newState.copied = null
      return newState
    case 'ADD_COPIED':
      toAdd = {
        ...object,
        id: crypto.randomBytes(16).toString('hex')
      }
      if (toAdd.elements) {
        copyElement(toAdd)
      }
      if (depth.length > 0) {
        toChange = parentGroup[depth[0]]
        depth.forEach((d, dIndex) => {
          if (dIndex > 0) {
            toChange = toChange.elements[d]
          }
        })
        toChange.elements.push(toAdd)
      } else {
        if (injectable) {
          parentGroup.push(toAdd)
        } else {
          parentGroup[currentStep].elements.push(toAdd)
        }
      }
      newState.copied = null
      return newState
    case 'RESET_DIRTY':
      delete newState.dirty
      return newState
    case 'FIELD':
      dragFrom = parentGroup[depth[0]]
      depth.forEach((d, dIndex) => {
        if (dIndex > 0) {
          dragFrom = dragFrom.elements[d]
        }
      })
      if (fieldArray) {
        fieldArray.forEach(obj => {
          if (obj.fieldValue || obj.fieldValue === 0) {
            dragFrom[obj.fieldName] = obj.fieldValue
          } else {
            delete dragFrom[obj.fieldName]
          }
        })
      } else {
        if (isNaN(fieldIndex)) {
          dragFrom[fieldName] = fieldValue
        } else {
          if (subfieldName) {
            dragFrom[fieldName][fieldIndex][subfieldName] = fieldValue
          } else {
            dragFrom[fieldName][fieldIndex] = fieldValue
          }
        }
      }
      return newState
    case 'RESET_OBJECT_CONNECTED':
      parentGroup.forEach(section => resetObjectConnected(section, object))
      if (newState.copied) {
        resetObjectConnected(newState.copied, object)
      }
      return newState
    case 'MOVE_SECTION':
      toMove = parentGroup[sectionIndex]
      index =
        sectionMoveDirection === 'up' ? sectionIndex - 1 : sectionIndex + 1
      toReplace = parentGroup[index]
      parentGroup[index] = toMove
      parentGroup[sectionIndex] = toReplace
      return newState
    case 'MOVE_GROUP_UP':
      if (injectable) {
        index = Number(depth[0])
        toMove = parentGroup[index]
        toReplace = parentGroup[index - 1]
        if (toMove && toReplace) {
          parentGroup[index - 1] = toMove
          parentGroup[index] = toReplace
        }
      } else {
        targetSection = parentGroup[depth[0]]
        index = Number(depth[1])
        toMove = targetSection.elements[index]
        toReplace = targetSection.elements[index - 1]
        if (toMove && toReplace) {
          targetSection.elements[index - 1] = toMove
          targetSection.elements[index] = toReplace
        }
      }
      return newState
    case 'MOVE_GROUP_DOWN':
      if (injectable) {
        index = Number(depth[0])
        toMove = parentGroup[index]
        toReplace = parentGroup[index + 1]
        if (toMove && toReplace) {
          parentGroup[index + 1] = toMove
          parentGroup[index] = toReplace
        }
      } else {
        targetSection = parentGroup[depth[0]]
        index = Number(depth[1])
        toMove = targetSection.elements[index]
        toReplace = targetSection.elements[index + 1]
        if (toMove && toReplace) {
          targetSection.elements[index + 1] = toMove
          targetSection.elements[index] = toReplace
        }
      }
      return newState
    case 'MOVE_ITEM':
      dragFrom = parentGroup[depth[0]]
      depth.forEach((d, dIndex) => {
        if (dIndex > 0) {
          dragFrom = dragFrom.elements[d]
        }
      })
      dragFrom = dragFrom.elements
      const deleted = dragFrom.splice(dragIndex, 1)[0]
      delete deleted.dummy
      dragFrom.splice(
        hoverIndex,
        0,
        {
          ...deleted
        },
        ...newState.elementsInStack
      )
      dragFrom.forEach(element => delete element.dummy)
      currentSection = parentGroup[depth[0]]
      for (var i = currentSection.elements.length - 1; i >= 0; i--) {
        const element = currentSection.elements[i]
        removeElementsInStack(currentSection, element)
      }
      currentSection.elements.forEach((item, index) => {
        getSelectedElementDepth(item, newSelected, [depth[0], index])
      })
      console.log('selected now', newSelected)
      newState.selectedElements = newSelected
      return newState
    case 'MOVE_ITEM_BETWEEN':
      dragFrom = parentGroup[dragDepth[0]]
      dragDepth.forEach((d, dIndex) => {
        if (dIndex < dragDepth.length - 1) {
          toRemoveFrom = toRemoveFrom[d].elements
        }
        if (dIndex > 0) {
          console.log({ ...dragFrom })
          dragFrom = dragFrom.elements[d]
        }
      })
      toDropAt = parentGroup
      hoverDepth.forEach((d, dIndex) => {
        if (dIndex < hoverDepth.length - 1 || loopAllDrop) {
          toDropAt = toDropAt[d].elements
        }
      })
      delete dragFrom.dummy
      toDropAt.splice(
        insertIndex || hoverDepth[hoverDepth.length - 1],
        0,
        {
          ...dragFrom
        },
        ...newState.elementsInStack
      )
      toDropAt.forEach(element => delete element.dummy)
      if (!emptySpace) {
        toRemoveFrom.splice(removeIndex, 1)
      } else {
        toRemoveFrom[removeIndex].emptySpace = true
      }
      currentSection = parentGroup[dragDepth[0]]
      for (var i = currentSection.elements.length - 1; i >= 0; i--) {
        const element = currentSection.elements[i]
        removeElementsInStack(currentSection, element)
      }
      currentSection.elements.forEach((item, index) => {
        getSelectedElementDepth(item, newSelected, [dragDepth[0], index])
      })
      console.log('selected now', newSelected)
      newState.selectedElements = newSelected
      return newState
    case 'START_DRAG':
      dragFrom = parentGroup[depth[0]]
      depth.forEach((d, dIndex) => {
        if (dIndex > 0) {
          dragFrom = dragFrom.elements[d]
        }
      })
      dragFrom.dummy = true
      newState.elementsInStack = []
      newState.selectedElements.forEach(id => {
        const pushDepth = id.split('.')
        let toPush = parentGroup[pushDepth[0]]
        pushDepth.forEach((d, dIndex) => {
          if (dIndex > 0) {
            toPush = toPush.elements[d]
          }
        })
        if (id !== depth.join('.')) {
          newState.elementsInStack.push({ ...toPush, depth: id.split('.') })
          toPush.movingInStack = true
        }
      })
      return newState
    case 'END_DRAG':
      currentSection = parentGroup[depth[0]]
      currentSection.elements.forEach(item => {
        resetElement(item)
      })
      return newState
    case 'REMOVE_ITEM':
      depth.forEach((d, dIndex) => {
        if (dIndex < depth.length - 1) {
          toRemoveFrom = toRemoveFrom[d].elements
        }
      })
      toRemoveFrom.splice(depth[depth.length - 1], 1)
      return newState
    case 'SELECT_ITEM':
      toChange = parentGroup[depth[0]]
      depth.forEach((d, dIndex) => {
        if (dIndex > 0) {
          toChange = toChange.elements[d]
        }
      })
      toChange.selected = select
      currentSection = parentGroup[depth[0]]
      currentSection.elements.forEach((item, index) => {
        getSelectedElementDepth(item, newSelected, [depth[0], index])
      })
      newState.selectedElements = newSelected
      return newState
    case 'ADD_ELEMENT_INJECTABLE':
      newState.injectableComponents.push({
        titleEN: '',
        titleFR: '',
        editMode: true,
        showBasicElements: true,
        elementType: 'text',
        typeProps: {},
        padding: {},
        injectable: true,
        targetFormType: 'editable',
        id: crypto.randomBytes(16).toString('hex')
      })
      return newState
    case 'ADD_GROUP_INJECTABLE':
      newState.injectableComponents.push({
        titleEN: '',
        titleFR: '',
        elements: [],
        editMode: true,
        showBasicElements: true,
        elementType: 'text',
        typeProps: {},
        padding: {},
        injectable: true,
        targetFormType: 'editable',
        id: crypto.randomBytes(16).toString('hex')
      })
      return newState
    case 'ADD_ELEMENT':
      parentGroup[currentStep].elements.push({
        titleEN: '',
        titleFR: '',
        editMode: true,
        showBasicElements: true,
        elementType: 'text',
        typeProps: {},
        padding: {},
        id: crypto.randomBytes(16).toString('hex')
      })
      return newState
    case 'ADD_GROUP':
      parentGroup[currentStep].elements.push({
        columns: '1',
        titleEN: '',
        titleFR: '',
        elements: [],
        padding: {},
        id: crypto.randomBytes(16).toString('hex')
      })
      return newState
    case 'SET_SECTIONS':
      return { ...newState, sections, injectableComponents }
    case 'ADD_SECTION':
      parentGroup.push({
        titleEN: nameEN,
        titleFR: nameFR,
        elements: []
      })
      return newState
    case 'REMOVE_SECTION':
      parentGroup.splice(action.deleteIndex, 1)
      return newState
    default:
      return state
  }
}

const breakReferences = item => {
  delete item.section
  if (item.id) {
    delete item.id
    item.id = crypto.randomBytes(16).toString('hex')
  }
  if (item.elements) {
    item.elements.forEach(element => breakReferences(element))
  }
}

const copyElement = item => {
  if (item.id) {
    delete item.id
    item.id = crypto.randomBytes(16).toString('hex')
  }
  if (item.elements) {
    const copied = []
    item.elements.forEach(item => {
      let obj = {}
      if (item.elements) {
        obj = {
          columns: item.columns,
          titleEN: item.titleEN,
          titleFR: item.titleFR,
          elements: cloneDeep(item.elements),
          style: item.style,
          labelsWidth: item.labelsWidth,
          padding: item.padding,
          bold: item.bold,
          headerFontSize: item.headerFontSize,
          headerStyle: item.headerStyle,
          italics: item.italics,
          itemsSpacing: item.itemsSpacing,
          id: crypto.randomBytes(16).toString('hex')
        }
      } else {
        obj = {
          titleEN: item.titleEN,
          titleFR: item.titleFR,
          editMode: item.editMode,
          showBasicElements: item.showBasicElements,
          elementType: item.elementType,
          typeProps: item.typeProps,
          tooltipEN: item.tooltipEN,
          tooltipFR: item.tooltipFR,
          altLabelPlacement: item.altLabelPlacement,
          conditions: item.conditions,
          id: crypto.randomBytes(16).toString('hex')
        }
      }
      if (item.injectableId) {
        obj.injectableId = item.injectableId
      }
      copied.push(obj)
    })
    item.elements = copied
    item.elements.forEach(element => copyElement(element))
  }
}

const resetElement = item => {
  delete item.dummy
  delete item.movingInStack
  if (item.elements) {
    item.elements.forEach(element => resetElement(element))
  }
}

const resetObjectConnected = (element, object) => {
  if (element.elements) {
    element.elements.forEach(item => resetObjectConnected(item, object))
  } else {
    const typeProps = { ...element.typeProps }
    const { connectedTo = [] } = typeProps
    if (object) {
      for (var i = connectedTo.length - 1; i >= 0; i--) {
        const connection = connectedTo[i] || {}
        const { connectedObject } = connection
        if (connectedObject && connectedObject === object) {
          connectedTo.splice(i, 1)
        }
      }
      if (
        typeProps.forceRequired &&
        !connectedTo.some(
          obj => obj.connectedField && obj.connectedField.required
        )
      ) {
        delete typeProps.forceRequired
        delete typeProps.required
      }
    }
    element.typeProps = typeProps
  }
}

const removeElementsInStack = (parent, item) => {
  if (item.movingInStack || item.emptySpace) {
    parent.elements.splice(parent.elements.indexOf(item), 1)
  } else if (item.elements && item.elements.length > 0) {
    for (var i = item.elements.length - 1; i >= 0; i--) {
      const element = item.elements[i]
      removeElementsInStack(item, element)
    }
  }
}

const getSelectedElementDepth = (item, array, depth) => {
  delete item.movingInStack
  if (item.selected) {
    array.push(depth.join('.'))
  } else if (item.elements && item.elements.length > 0) {
    item.elements.forEach((child, index) => {
      getSelectedElementDepth(child, array, [...depth, index])
    })
  }
}

export default formEditorReducer
