import { t, Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { dateFormat } from 'app/appSettings'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import { CurrencyFormated } from 'app/views/common/Formats'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'

import {
  commonPdfStyles,
  FormColorAutocomplete,
  FormConnectToObject,
  NumberFormatDefault,
  pdfDefaultFontSize
} from '../Common'

export const formPaymentsDefaultValue = (obj, info, item) => {
  if (!obj || !info.payments) {
    return []
  }
  return info.payments.map(payment => {
    let funding = 0
    if (sfOauthConfig.isIcce && obj.OPBase_Recommended_Amount__c) {
      funding = obj.OPBase_Recommended_Amount__c
    } else if (!sfOauthConfig.isIcce && obj.Grant_Awarded__c) {
      funding = obj.Grant_Awarded__c
    }
    let per = +((payment.FGM_Base__Amount__c / funding) * 100).toFixed(2)
    if (per === Infinity) {
      per = null
    } else if (payment.FGM_Base__Amount__c === 0) {
      per = 0
    }
    return {
      date: moment.utc(payment.FGM_Base__Scheduled_Payment_Date__c),
      percentage: per + '%',
      id: payment.Id,
      status: payment.FGM_Base__Status__c,
      report: payment.FGM_Base__Grantee_Report__c,
      amount: payment.FGM_Base__Amount__c
      // awarded: item.FGM_Base__Awarded_Amount__c
    }
  })
}

const pdfStyles = StyleSheet.create({
  headerCell: {
    textAlign: 'center',
    // fontWeight: 'bold',
    padding: 10,
    fontSize: pdfDefaultFontSize,
    flex: 1,
    fontFamily: 'Roboto',
    border: '1px solid black',
    borderCollapse: 'collapse'
  },
  cell: {
    padding: 10,
    fontSize: pdfDefaultFontSize,
    flex: 1,
    textAlign: 'center',
    fontFamily: 'Roboto',
    border: '1px solid black',
    borderCollapse: 'collapse'
  }
})

export const FormPaymentsPdf = ({
  value,
  title,
  langFR,
  typeProps,
  ...props
}) => {
  const { pdfHeaderTextProps = [], pdfBackgroundColor, pdfFontSize } = typeProps

  const headerStyle = { ...pdfStyles.headerCell }
  if (pdfHeaderTextProps.includes('bold')) {
    headerStyle.fontWeigth = 'bold'
  }
  if (pdfHeaderTextProps.includes('italics')) {
    headerStyle.fontStyle = 'italic'
  }
  if (pdfHeaderTextProps.includes('underline')) {
    headerStyle.textDecoration = 'underline'
  }
  if (pdfBackgroundColor) {
    headerStyle.backgroundColor = pdfBackgroundColor.id
  }
  if (pdfFontSize) {
    headerStyle.fontSize = +pdfFontSize
  }

  return (
    <View>
      {title && (
        <View>
          <Text style={commonPdfStyles.title}>{title}</Text>
        </View>
      )}
      <View style={commonPdfStyles.row}>
        <View style={headerStyle}>
          <Text>{myI18n._(t`Date`)}</Text>
        </View>
        <View style={headerStyle}>
          <Text>{myI18n._(t`Amount`)}</Text>
        </View>
        <View style={headerStyle}>
          <Text>{myI18n._(t`Percentage`)}</Text>
        </View>
      </View>
      {value.map((data, index) => {
        const { date, percentage, amount } = data
        return (
          <View style={commonPdfStyles.row} key={index}>
            <View style={pdfStyles.cell}>
              <Text>{date && moment.utc(date).format(dateFormat)}</Text>
            </View>
            <View style={pdfStyles.cell}>
              <Text>
                {String(
                  !langFR
                    ? new Intl.NumberFormat('en-CA', {
                        style: 'currency',
                        currency: 'CAD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }).format(amount)
                    : new Intl.NumberFormat('fr-CA', {
                        style: 'currency',
                        currency: 'CAD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                        .format(amount)
                        .split('CA')[0]
                )}
              </Text>
            </View>
            <View style={pdfStyles.cell}>
              <Text>{percentage}</Text>
            </View>
          </View>
        )
      })}
    </View>
  )
}

export const FormPayments = () => {
  return <div />
}

export const FormEditorPayments = ({
  depth,
  showPdfProps,
  typeProps,
  editMode,
  ...props
}) => {
  const dispatch = useDispatch()
  const { pdfHeaderTextProps = [], pdfBackgroundColor, pdfFontSize } = typeProps
  if (!editMode) {
    return <FormPayments editMode typeProps={typeProps} {...props} />
  }
  return (
    <div>
      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
      {showPdfProps && (
        <div style={{ marginTop: 10 }}>
          <Typography variant='h6'>
            <Trans>Pdf props</Trans>
          </Typography>
          <div style={{ padding: 5, marginTop: 10 }}>
            <Typography style={{ marginBottom: 10, fontSize: 16 }}>
              <Trans>Header</Trans>
            </Typography>
            <TextField
              label={<Trans>Font size</Trans>}
              fullWidth
              variant='outlined'
              value={pdfFontSize || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.pdfFontSize = e.target.value
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
              inputProps={{
                maxLength: 2
              }}
              InputProps={{
                inputComponent: NumberFormatDefault
              }}
            />
            <FormGroup row>
              {[
                { key: 'bold', label: <Trans>Bold</Trans> },
                { key: 'italics', label: <Trans>Italics</Trans> },
                { key: 'underline', label: <Trans>Underline</Trans> }
              ].map(item => (
                <FormControlLabel
                  key={item.key}
                  control={
                    <Checkbox
                      checked={pdfHeaderTextProps.includes(item.key)}
                      value={item.key}
                      onChange={e => {
                        const v = e.target.value
                        const toSet = { ...typeProps }
                        const newTextProps = [...pdfHeaderTextProps]
                        if (newTextProps.includes(v)) {
                          newTextProps.splice(newTextProps.indexOf(v), 1)
                        } else {
                          newTextProps.push(v)
                        }
                        toSet.pdfHeaderTextProps = newTextProps
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: { ...toSet }
                        })
                      }}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
            <FormColorAutocomplete
              depth={depth}
              label={<Trans>Cell background color</Trans>}
              name='pdfBackgroundColor'
              typeProps={typeProps}
              value={pdfBackgroundColor}
            />
          </div>
        </div>
      )}
    </div>
  )
}
