/**
 * Created by Michał Stawski on 24.08.2020.
 */
import { Trans } from '@lingui/macro'

export const dateFormat = 'YYYY-MM-DD'
export const datetimeFormat = 'YYYY-MM-DD h:mm A'
export const timeFormat = 'h:mm:ss A'
export const defaultDocTitle = 'The Centre'

export const portalLanguagesData = {
  en_US: {
    label: 'English',
    editKey: 'en',
    surveyTextKey: 'en-ca',
    portalTranslationField: 'Translated_EN__c'
  },
  fr: {
    label: 'French',
    editKey: 'fr',
    surveyTextKey: 'fr-ca',
    portalTranslationField: 'Translated_FR__c'
  },
  'iu-latn': {
    label: 'Inuinnaqtun',
    editKey: 'iu-latn',
    surveyTextKey: 'iu-latn',
    portalTranslationField: 'Translated_IU_Latn__c'
  }
  // 'iu-cans': {
  //   label: 'ᐃᓄᒃᑎᑐᑦ',
  //   editKey: 'iu-cans',
  //   surveyTextKey: 'iu-cans',
  //   portalTranslationField: 'Translated_IU_Cans__c'
  // }
}
