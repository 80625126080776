import { Trans } from '@lingui/macro'
import { Button, Grid, Icon } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GroupElementWithoutDnD } from './GroupElement'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DragPlaceholder from './DragPlaceholder'
import GroupCard from './GroupCard'
import { addInjectableId } from './FormWizard'
import _ from 'lodash'
import { fetchFormPages } from 'app/services/sfAuth/sfData/sfForms'
import Loading from 'egret/components/EgretLoadable/Loading'

const InjectablesElementsPanel = ({ filterInjectablesElements }) => {
  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)
  const { injectableComponents, elementsInStack } = tree
  const [injectableMap, setInjectableMap] = useState({})
  const [loading, setLoading] = useState(true)

  const searchInElements = (elements, formName, initialMap) => {
    if (!elements) {
      return
    }
    elements.forEach(element => {
      const elementId = element.injectableId
      const isInjectable = injectableComponents.some(
        component => component.id === elementId
      )
      if (isInjectable) {
        if (!initialMap[elementId]) {
          initialMap[elementId] = [formName]
        }
        if (!initialMap[elementId].includes(formName)) {
          initialMap[elementId].push(formName)
        }
      }
      searchInElements(element.elements, formName, initialMap)
    })
  }

  const fetchData = () => {
    const initialMap = {}
    if(injectableComponents.length > 0){
      fetchFormPages().then(result => {
        result.forEach(form => {
          form?.config?.sections?.map(section => {
            searchInElements(
              section.elements, 
              form.en, 
              initialMap
            )
          })
        })
        setInjectableMap(initialMap)
        setLoading(false)
      })
    }else{
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if(loading){
    return <Loading/>
  }

  console.log(injectableComponents)
  return (
    <div>
      <Grid container alignItems='center' style={{ padding: 10 }}>
        <h3 style={{ margin: 0 }}>
          <Trans>Reusable components</Trans>
        </h3>
        <Button
          style={{ marginLeft: 10 }}
          variant='contained'
          color='primary'
          onClick={() => {
            dispatch({
              type: 'ADD_ELEMENT_INJECTABLE'
            })
          }}
        >
          <Grid container justifyContent='center' alignItems='center'>
            <Trans>Add element</Trans>
            <Icon style={{ marginLeft: 5 }}>add</Icon>
          </Grid>
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          variant='contained'
          color='primary'
          onClick={() => {
            dispatch({
              type: 'ADD_GROUP_INJECTABLE'
            })
          }}
        >
          <Grid container justifyContent='center' alignItems='center'>
            <Trans>Add group</Trans>
            <Icon style={{ marginLeft: 5 }}>add</Icon>
          </Grid>
        </Button>
      </Grid>
      {injectableComponents
        .filter(component =>
          filterInjectablesElements.includes(component.targetFormType)
        )
        .map((component, index) => {
          const {
            titleEN,
            titleFR,
            editMode,
            isDragLayer,
            parentIsDummy,
            movingInStack,
            selectedParent,
            elementType,
            fieldType,
            width,
            columns,
            french,
            selected,
            emptySpace,
            elements,
            typeProps,
            targetFormType = 'regular',
            ...props
          } = component
          if (elements) {
            return (
              <DndProvider backend={HTML5Backend} key={index}>
                <DragPlaceholder
                  frenchVersion={french}
                  stack={elementsInStack}
                />
                <GroupCard
                  injectable
                  injectableName={props.injectableName}
                  dummy={component.dummy}
                  movingInStack={component.movingInStack}
                  item={component}
                  depth={[index]}
                  showPdfProps={targetFormType === 'pdf'}
                  showPrintProps={targetFormType === 'print'}
                  targetFormType={targetFormType}
                  currentlyUsedForms={injectableMap[props.id]}
                />
              </DndProvider>
            )
          }
          return (
            <GroupElementWithoutDnD
              injectable
              key={index}
              depth={[index].join('.')}
              elementType={elementType}
              movingInStack={movingInStack}
              width={width}
              selectedParent={selectedParent}
              french={french}
              titleEN={titleEN}
              titleFR={titleFR}
              title={french ? titleFR : titleEN}
              tooltipEN={props.tooltipEN}
              tooltipFR={props.tooltipFR}
              helpTextEN={props.helpTextEN}
              helpTextFR={props.helpTextFR}
              id={props.id}
              formId={props.formId}
              selected={selected}
              typeProps={typeProps}
              injectableId={props.injectableId}
              injectableName={props.injectableName}
              altLabelPlacement={props.altLabelPlacement}
              labelAsMarkdown={props.labelAsMarkdown}
              showBasicElements={props.showBasicElements}
              showCustomElements={props.showCustomElements}
              sectionLength={injectableComponents.length}
              editMode={editMode}
              showPdfProps={targetFormType === 'pdf'}
              showPrintProps={targetFormType === 'print'}
              targetFormType={targetFormType}
              padding={props.padding}
              currentlyUsedForms={injectableMap[props.id]}
            />
          )
        })}
    </div>
  )
}

export const cloneInjectableElement = ({ item, componentsMap, editor }) => {
  const updated = _.cloneDeep(componentsMap[item.injectableId])
  addInjectableId(updated)
  updated.elements = item.elements
  updated.conditions = item.conditions
  if (updated.typeProps.connectedTo) {
    updated.typeProps.connectedTo.forEach((connection, index) => {
      if (
        connection.connectedObjectType !==
        item.typeProps.connectedTo[index].connectedObjectType
      ) {
        console.error(
          'Connection was not updated for injectable element: ',
          item,
          updated
        )
        if (!editor) {
          updated.typeProps.connectedTo = []
        }
      } else {
        connection.connectedObject =
          item.typeProps.connectedTo[index].connectedObject
      }
    })
  }
  delete updated.editMode
  delete updated.selected
  return updated
}

export default InjectablesElementsPanel
