import { I18nProvider } from '@lingui/react'
import { setupI18n } from '@lingui/core'
import React, { useEffect } from 'react'
import catalogEn from 'locales/en/messages.js'
import catalogFr from 'locales/fr/messages.js'
import catalogIuLatn from 'locales/iu-latn/messages.js'
import catalogIuCans from 'locales/iu-cans/messages.js'
import { connect } from 'react-redux'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import 'moment/locale/fr'
import * as jsonCatalogEn from 'locales/en/messages.json'
import * as jsonCatalogFr from 'locales/fr/messages.json'
import { updateCatalog } from '../app/redux/actions/I18nActions'
import { Trans } from '@lingui/macro'

/**
 * Created by Michał Stawski on 20.09.2020.
 */

export let catalogs = {
  en: catalogEn,
  en_US: catalogEn,
  fr: catalogFr,
  fr_CA: catalogFr,
  'iu-latn': catalogIuLatn,
  'iu-cans': catalogIuCans
}

export const jsonCatalogs = {
  en: jsonCatalogEn.default,
  fr: jsonCatalogFr.default
}

export const myI18n = setupI18n()

export class UTCMomentUtils extends MomentUtils {
  parse (value, format) {
    return this.moment.utc(value, format, true)
  }

  date (value) {
    return this.moment.utc(value)
  }
}

export const userToFormat = user => {
  if (user && (user.language === 'fr' || user.language === 'fr_CA')) {
    return 'DD/MM/YYYY'
  } else {
    return 'YYYY-MM-DD'
  }
}

const mergeCatalogs = (catalogOld, catalogNew = {}) => {
  return {
    ...catalogOld,
    ...catalogNew,
    messages: {
      ...catalogOld.messages,
      ...catalogNew.messages
    }
  }
}

const I18nConnectedProvider = ({
  language,
  children,
  loadedCatalogs = {},
  updateCatalog
}) => {
  useEffect(() => {
    moment.locale(language)
  }, [language])

  useEffect(() => {
    console.log('load catalog')
    Promise.all([
      import(
        /* webpackChunkName: "messagesEn" */ '../../public/assets/js/locale/en/messages.js'
      ),
      import(
        /* webpackChunkName: "messagesFr" */ '../../public/assets/js/locale/fr/messages.js'
      )
      // import(
      //   /* webpackChunkName: "messagesFr" */ '../../public/assets/js/locale/iu-cans/messages.js'
      // ),
      // import(
      //   /* webpackChunkName: "messagesFr" */ '../../public/assets/js/locale/iu-latn/messages.js'
      // )
    ])
      .then(
        ([
          catalogEnDynamic,
          catalogFrDynamic
          //catalogIuCansDynamic,
          //catalogIuLatnDynamic
        ]) => {
          console.log('localeSplit', catalogEnDynamic, catalogFrDynamic)
          console.log(
            'localeSplit canary',
            catalogEnDynamic.default.languageData.canary
          )
          console.log(
            'localeSplit canary',
            catalogFrDynamic.default.messages['Action Plan']
          )
          let en = catalogEnDynamic.default
          en.info = { update: new Date() }
          let fr = catalogFrDynamic.default
          fr.info = { update: new Date() }
          updateCatalog('en', en)
          updateCatalog('fr', fr)
          //updateCatalog('iu-cans', catalogIuCansDynamic.default)
          //updateCatalog('iu-latn', catalogIuLatnDynamic.default)
          updateCatalog('en_US', en)
          updateCatalog('fr_CA', fr)
        }
      )
      .catch(e => {
        console.warn('localeSplit failed loading messages', e)
      })

    console.log('window', window)
    // .then(function() {
    //   console.log('Loadedcata');
    // });
  }, [])
  let mergedCatalogs = {
    en: mergeCatalogs(catalogs.en, loadedCatalogs.en),
    fr: mergeCatalogs(catalogs.fr, loadedCatalogs.fr),
    en_US: mergeCatalogs(catalogs.en, loadedCatalogs.en),
    fr_CA: mergeCatalogs(catalogs.fr, loadedCatalogs.fr),
    'iu-cans': mergeCatalogs(catalogs['iu-cans'], loadedCatalogs['iu-cans']),
    'iu-latn': mergeCatalogs(catalogs['iu-latn'], loadedCatalogs['iu-latn'])
  }
  catalogs = mergedCatalogs
  console.log('LANG', language, mergedCatalogs)

  return (
    <I18nProvider language={language} catalogs={mergedCatalogs} i18n={myI18n}>
      <MuiPickersUtilsProvider
        utils={UTCMomentUtils}
        libInstance={moment}
        // locale?
        locale={language}
      >
        {children}
      </MuiPickersUtilsProvider>
    </I18nProvider>
  )
}

const mapStateToProps = state => ({
  language: state.user.language || 'en',
  loadedCatalogs: state.i18n
})
export default connect(mapStateToProps, { updateCatalog })(
  I18nConnectedProvider
)
