import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import fOauthConfig from '../../../app/services/sfAuth/sfAuthConfig'

const useStyles = makeStyles(theme => ({
  loading: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 'calc(50% - 20px)',
    margin: 'auto',
    height: '40px',
    width: '40px',
    '& img': {
      position: 'absolute',
      height: '25px',
      width: 'auto',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto'
    }
  },
  loadingNotFixed: {
    left: 0,
    right: 0,
    margin: 'auto',
    height: '75px',
    width: '40px',
    '& img': {
      position: 'relative',
      height: '25px',
      width: 'auto',
      top: 38,
      bottom: 0,
      left: 8,
      right: 0
    }
  }
}))

const Loading = ({ style, isNotFixed, link }) => {
  const classes = useStyles()
  const defaultCircle =
    process.env.REACT_APP_ENVIROMENT &&
    process.env.REACT_APP_ENVIROMENT.indexOf('icce') !== -1
      ? '/assets/images/icce-logo-circle.svg'
      : '/assets/images/chtc-logo-circle.svg'
  return (
    <div
      className={!isNotFixed ? classes.loading : classes.loadingNotFixed}
      style={style}
    >
      <img src={link || defaultCircle} alt='' />
      <CircularProgress />
    </div>
  )
}

export default Loading
