/**
 * Created by Michał Stawski on 07.01.2022.
 */


export const normalizeNumberValue = n =>{
  if (n) {
    let u = String(n).replaceAll(',', '')
    return Number(u)
  }
  return n
}
