import { Trans } from '@lingui/macro'
import {
  createMuiTheme,
  ThemeProvider,
  Button,
  Typography,
  makeStyles,
  createTheme
} from '@material-ui/core'
import { DatePicker, Day, DateTimePicker } from '@material-ui/pickers'
import { merge } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { FormLabel } from '../forms/components/FormErrorLabel'
import { multiuserColoredInputClasses } from '../forms/components/multiuser-input/Common'

const widePopupTheme = theme =>
  createTheme(
    merge(
      { ...theme },
      {
        datePickerToolbar: {
          paddingLeft: 24,
          paddingRight: 24,
          height: 100,
          color: 'primary'
        },
        overrides: {
          MuiPickersModal: {
            dialogRoot: {
              minWidth: 550
            }
          },
          MuiButton: {
            label: {
              justifyContent: 'flex-start'
            }
          },
          MuiPickersBasePicker: {
            pickerView: {
              maxWidth: '100%'
            }
          },
          MuiPickersCalendarHeader: {
            dayLabel: {
              width: 70
            }
          },
          MuiPickersDay: {
            day: {
              width: 40,
              marginLeft: 17,
              marginRight: 17
            }
          }
        }
      }
    )
  )

const CustomDatePicker = ({
  color,
  handleClose,
  handleAccept,
  handleOpen,
  ...props
}) => {
  const userLang = useSelector(state => state.user.language)
  const settings = useSelector(state => state.layout.settings)
  const [dialogDateSelected, setDialogDateSelected] = useState()
  const theme = settings.themes[settings.activeTheme]
  const classes = multiuserColoredInputClasses(color)
  return (
    <>
      <DatePicker
        cancelLabel={<Trans>Cancel</Trans>}
        okLabel={<Trans>Ok</Trans>}
        clearLabel={<Trans>Clear</Trans>}
        {...props}
        minDate={props.minDate || undefined}
        maxDate={props.maxDate || undefined}
        onOpen={e => {
          if (handleOpen) {
            handleOpen()
          }
        }}
        onClose={e => {
          if (handleClose) {
            handleClose(dialogDateSelected)
          }
        }}
        className={color && classes.root}
        maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
        minDateMessage={<Trans>Date should not be before minimal date</Trans>}
        renderDay={(day, selectedDay, dayInCurrentMoth, dayComponent) => {
          return (
            <span
              style={{ display: 'flex' }}
              onClick={e => {
                setDialogDateSelected(day)
              }}
            >
              {dayComponent}
            </span>
          )
        }}
        ToolbarComponent={t => {
          const { date, setOpenView } = t
          let dateString = date.format('dddd, D MMMM')
          if (userLang !== 'en_US') {
            const month =
              date.format('MMMM')[0].toLowerCase() +
              date.format('MMMM').slice(1)
            const day = date.format('D')
            const weekday =
              date.format('dddd')[0].toUpperCase() +
              date.format('dddd').slice(1)
            dateString = weekday + ' ' + day + ' ' + month
          }
          return (
            <div
              style={{
                padding: 24,
                height: 100,
                backgroundColor: theme.palette.primary.main
              }}
            >
              <div>
                <Button
                  onClick={e => {
                    setOpenView('year')
                  }}
                  style={{
                    padding: 0,
                    justifyContent: 'flex-start',
                    textAlign: 'left'
                  }}
                >
                  <Typography style={{ fontSize: 16 }}>
                    {moment(date).year()}
                  </Typography>
                </Button>
              </div>

              <div>
                <Button
                  style={{ padding: 0 }}
                  onClick={e => {
                    setOpenView('date')
                  }}
                >
                  <Typography variant='h4'>{dateString}</Typography>
                </Button>
              </div>
            </div>
          )
        }}
      />
      {props.error && props.metaError && (
        <FormLabel error label={<Trans id={props.metaError?.props?.id} />} />
      )}
    </>
  )
}

export const CustomDateTimePicker = ({
  color,
  handleClose,
  handleOpen,
  ...props
}) => {
  const userLang = useSelector(state => state.user.language)
  const settings = useSelector(state => state.layout.settings)
  const theme = settings.themes[settings.activeTheme]
  const customTheme = widePopupTheme({ ...theme })

  const coloredStyles = makeStyles(theme => ({
    root: {
      '& label.Mui-focused': {
        color: color || 'rgba(0, 0, 0, 0.38)'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: color || 'rgba(0, 0, 0, 0.38)'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: color || 'rgba(0, 0, 0, 0.38)'
        },
        '&.Mui-disabled fieldset': {
          borderColor: color || 'rgba(0, 0, 0, 0.38)'
        },
        '&.Mui-focused fieldset': {
          borderColor: color || 'rgba(0, 0, 0, 0.38)'
        }
      }
    }
  }))
  const classes = coloredStyles()

  return (
    <>
      <DateTimePicker
        cancelLabel={<Trans>Cancel</Trans>}
        okLabel={<Trans>Ok</Trans>}
        clearLabel={<Trans>Clear</Trans>}
        {...props}
        minDate={props.minDate || undefined}
        maxDate={props.maxDate || undefined}
        onOpen={e => {
          if (handleOpen) {
            handleOpen()
          }
        }}
        onClose={e => {
          if (handleClose) {
            handleClose()
          }
        }}
        className={color && classes.root}
        maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
        minDateMessage={<Trans>Date should not be before minimal date</Trans>}
      />
      {props.error && props.metaError && (
        <FormLabel error label={<Trans id={props.metaError?.props?.id} />} />
      )}
    </>
  )
}

export default CustomDatePicker
