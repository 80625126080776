/**
 * Created by Michał Stawski on 09.10.2020.
 */
import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

export const TooltipLabelIcon = ({className, tooltip}) => <Tooltip
  title={tooltip}>
  <IconButton
    aria-label='delete'
    className={className}
    size='small'
  >
    <HelpOutlineIcon fontSize='inherit'/>
  </IconButton>
</Tooltip>
