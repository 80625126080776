import { Trans } from '@lingui/macro'
import {
  Button,
  Grid,
  Icon,
  Slider,
  TextField,
  Typography
} from '@material-ui/core'
import React from 'react'
import { INDEX_OF_DONE } from './ActionPlan'

const ActionDoneDialog = props => {
  const [expected, setExpected] = React.useState(
    props.action.expectedOutcome || ''
  )
  const [actual, setActual] = React.useState(props.action.actualOutcome || '')
  const [success, setSuccess] = React.useState(props.action.success || 1)
  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      style={{ display: 'flex' }}
    >
      <h4 style={{ textAlign: 'center', padding: 20, minWidth: '500px' }}>
        <Trans>Outcome</Trans>
      </h4>
      <div style={{ margin: 15, width: '470px' }}>
        <TextField
          className='text-muted'
          onChange={event => {
            setExpected(event.target.value)
          }}
          value={expected}
          label={<Trans>Expected outcome</Trans>}
          rows={3}
          name='reason'
          variant='outlined'
          fullWidth
          multiline
        />
      </div>
      <div style={{ margin: 15, width: '470px' }}>
        <TextField
          className='text-muted'
          onChange={event => {
            setActual(event.target.value)
          }}
          value={actual}
          label={<Trans>Actual outcome</Trans>}
          rows={3}
          name='reason'
          variant='outlined'
          fullWidth
          multiline
        />
      </div>

      <div style={{ margin: 15, width: '70%' }}>
        <Typography>
          <Trans>Success measure</Trans>
        </Typography>
        <Slider
          style={{ color: '#10708A' }}
          onChange={(event, newValue) => {
            setSuccess(newValue)
          }}
          defaultValue={success}
          step={1}
          min={1}
          max={5}
          marks={[
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' },
            { value: 4, label: '4' },
            { value: 5, label: '5' }
          ]}
          valueLabelDisplay='auto'
        />
      </div>

      <Grid container direction='row' justify='space-around'>
        <Button
          variant='contained'
          style={{
            marginBottom: 10,
            backgroundColor: '#10708A',
            color: 'white'
          }}
          onClick={() => {
            props.handleSave(
              {
                ...props.action,
                failReason: null,
                column: 'Done',
                success: success,
                actualOutcome: actual,
                expectedOutcome: expected
              },
              INDEX_OF_DONE,
              props.destination.index,
              props.source.sourceBoardIndex
            )
          }}
        >
          <Icon style={{ marginRight: 5 }}>check</Icon>
          <Trans>Save</Trans>
        </Button>
        <Button
          variant='contained'
          style={{
            marginBottom: 10,
            backgroundColor: '#10708A',
            color: 'white'
          }}
          onClick={() => {
            props.closeDialog()
            props.moveToColumn({
              movedFromColumn: props.destination.targetBoardIndex,
              movedToColumn: props.source.sourceBoardIndex,
              movedIndex: props.destination.index,
              moveToIndex: props.source.index
            })
          }}
        >
          <Icon style={{ marginRight: 5 }}>close</Icon>
          <Trans>Cancel</Trans>
        </Button>
      </Grid>
    </Grid>
  )
}

export default ActionDoneDialog
