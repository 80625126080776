import { t, Trans } from '@lingui/macro'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { dateFormat } from 'app/appSettings'
import { Field, useFormikContext } from 'formik'
import moment from 'moment'
import React from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import CustomDatePicker from '../common/CustomDatePicker'
import { TooltipLabelIcon } from '../page-layouts/TooltipLabelIcon'
import { formElementTypes } from './GroupElement'

export const formConditions = {
  picklist: {
    conditions: {
      isEmpty: {
        text: <Trans>Is empty</Trans>,
        rule: (n, v) => {
          if (Array.isArray(v)) {
            return v.length === 0
          } else {
            return !v
          }
        }
      },
      isNotEmpty: {
        text: <Trans>Is not empty</Trans>,
        rule: (n, v) => {
          if (Array.isArray(v)) {
            return v.length > 0
          } else {
            return v
          }
        }
      },
      inc: {
        text: item => {
          const multi =
            item.type === 'multipicklist' ||
            item.elementProps?.picklistType === 'multiselect'
          return multi ? <Trans>Includes</Trans> : <Trans>Equals</Trans>
        },
        rule: (n, v) => v && v.includes(n)
      },
      exc: {
        text: item => {
          const multi =
            item.type === 'multipicklist' ||
            item.elementProps?.picklistType === 'multiselect'
          return multi ? <Trans>Not includes</Trans> : <Trans>Not equals</Trans>
        },
        rule: (n, v) => v && !v.includes(n)
      },
      indigenous: {
        text: <Trans>Indigenous(special)</Trans>,
        deprecated: true,
        rule: (n, v) =>
          v &&
          (v.includes('First Nations') ||
            v.includes('Inuit') ||
            v.includes('Métis') ||
            v.includes('All Indigenous Peoples'))
      }
    }
  },
  datePicker: {
    conditions: {
      isAfter: {
        text: <Trans>Is after</Trans>,
        rule: (n, v) => {
          return n && v && moment(moment.utc(v)).isAfter(moment.utc(n))
        }
      },
      isBefore: {
        text: <Trans>Is before</Trans>,
        rule: (n, v) => {
          return n && v && moment(moment.utc(v)).isBefore(moment.utc(n))
        }
      }
    }
  },
  bool: {
    conditions: {
      isTrue: {
        text: <Trans>Is selected</Trans>,
        rule: (n, v) => Boolean(v)
      },
      isFalse: {
        text: <Trans>Is not selected</Trans>,
        rule: (n, v) => !v
      }
    }
  },
  textInput: {
    conditions: {
      eq: {
        text: <Trans>Is equal to</Trans>,
        rule: (n, v) => n === v
      },
      neq: {
        text: <Trans>Is not equal to</Trans>,
        rule: (n, v) => n !== v
      },
      contains: {
        text: <Trans>Contains</Trans>,
        rule: (n, v) => String(v).includes(n)
      },
      doesNotcontain: {
        text: <Trans>Does not contain</Trans>,
        rule: (n, v) => !String(v).includes(n)
      }
    }
  },
  textInputNumeric: {
    conditions: {
      eq: {
        text: <Trans>Is equal to</Trans>,
        rule: (n, v) => Number(n) === Number(v)
      },
      lt: {
        text: <Trans>Is less than</Trans>,
        rule: (n, v) => Number(v) < Number(n)
      },
      lte: {
        text: <Trans>Is less than or equal to</Trans>,
        rule: (n, v) => Number(v) <= Number(n)
      },
      gt: {
        text: <Trans>Is greater than</Trans>,
        rule: (n, v) => Number(v) > Number(n)
      },
      gte: {
        text: <Trans>Is greater than or equal to</Trans>,
        rule: (n, v) => Number(v) >= Number(n)
      }
    }
  },
  numericSlider: {
    conditions: {
      eq: {
        text: <Trans>Is equal to</Trans>,
        rule: (n, v) => Number(n) === Number(v)
      },
      lt: {
        text: <Trans>Is less than</Trans>,
        rule: (n, v) => Number(v) < Number(n)
      },
      lte: {
        text: <Trans>Is less than or equal to</Trans>,
        rule: (n, v) => Number(v) <= Number(n)
      },
      gt: {
        text: <Trans>Is greater than</Trans>,
        rule: (n, v) => Number(v) > Number(n)
      },
      gte: {
        text: <Trans>Is greater than or equal to</Trans>,
        rule: (n, v) => Number(v) >= Number(n)
      }
    }
  },
  otherGrants: {
    conditions: {
      isEmpty: {
        text: <Trans>Is empty</Trans>,
        rule: (n, v) => v.length === 0
      },
      isNotEmpty: {
        text: <Trans>Is not empty</Trans>,
        rule: (n, v) => v.length > 0
      }
    }
  }
}

export const specialFormConditions = {
  noErrors: 'If form has no errors',
  errorsPresent: 'If there are errors present in form'
}

export const sfFieldToConditionType = {
  id: 'textInput',
  double: 'textInputNumeric',
  currency: 'textInputNumeric',
  int: 'textInputNumeric',
  phone: 'textInput',
  string: 'textInput',
  textarea: 'textInput',
  picklist: 'picklist',
  multipicklist: 'picklist',
  percent: 'textInputNumeric',
  date: 'datePicker',
  datetime: 'datePicker',
  email: 'textInput',
  url: 'textInput',
  boolean: 'bool'
}

const standardConditionStates = [
  {
    value: 'hide',
    label: <Trans>Hide this element</Trans>,
    labelSection: <Trans>Hide this section</Trans>
  },
  {
    value: 'show',
    label: <Trans>Show this element</Trans>,
    labelSection: <Trans>Show this section</Trans>
  },
  {
    value: 'altLabel',
    label: <Trans>Show alternative label</Trans>
  },
  {
    value: 'altHelpText',
    label: <Trans>Show alternative help text</Trans>
  },
  {
    value: 'required',
    label: <Trans>Make element required</Trans>
  },
  {
    value: 'notRequired',
    label: <Trans>Make element not required</Trans>
  }
]

const ConditionalElementEditor = ({
  french,
  subIndex,
  id,
  depth,
  conditions = [],
  elementType,
  ...props
}) => {
  const { values } = useFormikContext()
  const mapElements = data => {
    const returnArray = []
    data.sections.forEach((section, sectionIndex) =>
      section.elements.forEach(item => {
        if (
          elementType !== 'section' ||
          (elementType === 'section' && sectionIndex !== +depth)
        ) {
          mapItem({ item, returnArray, section })
        }
      })
    )

    return returnArray
  }

  const mapItem = ({ item, returnArray, section }) => {
    if (item.elements) {
      item.elements.forEach(element =>
        mapItem({ item: element, returnArray, section })
      )
    } else {
      if (
        // id !== item.id &&
        Object.keys(formConditions).includes(item.elementType)
      ) {
        const title = french ? item.titleFR : item.titleEN
        const sectionName = french ? section.titleFR : section.titleEN
        returnArray.push({
          id: item.id,
          picklistValues: item.typeProps.options,
          elementProps: {
            picklistType: item.typeProps.picklistType
          },
          type: item.elementType,
          label: '[' + sectionName + '] ' + title
        })
      }
    }
  }

  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)
  const userLanguage = useSelector(state => state.user.language)

  const getTranslatableLabel = obj => {
    if (userLanguage === 'en_US' && obj.titleEN) {
      return obj.titleEN
    } else if (obj.titleFR) {
      return obj.titleFR
    } else {
      return myI18n._(t`[EMPTY LABEL]`)
    }
  }
  const isSub = !isNaN(subIndex)

  const { objectsConnected, objects } = values

  const avaliableObjectsMap = {}
  objects.forEach(obj => {
    avaliableObjectsMap[obj.name] = obj
  })

  const additionalFields = []
  objectsConnected.forEach(obj => {
    const objData = avaliableObjectsMap[obj.type]
    if (objData) {
      const { fields, recordTypes } = objData
      if (fields) {
        objData.fields.forEach(field => {
          if (sfFieldToConditionType[field.type]) {
            additionalFields.push({
              type: field.type,
              picklistValues: field.picklistValues || [],
              id: obj.name + '//' + field.name,
              sfField: field.name,
              sfObject: obj.identId,
              label: '[' + obj.name + '] ' + field.label
            })
          }
        })
      }
      if (recordTypes) {
        additionalFields.push({
          type: 'picklist',
          id: obj.name + 'RecordType',
          sfField: 'RecordTypeId',
          sfObject: obj.identId,
          picklistValues: Object.keys(recordTypes).map(key => {
            return {
              apiValue: recordTypes[key].recordTypeId,
              label: key
            }
          }),
          label: '[' + obj.name + '] ' + 'RecordType'
        })
      }
    }
  })

  const avaliableElements = [...mapElements(tree), ...additionalFields]
  const avaliableElementsMap = {}
  avaliableElements.forEach(element => {
    avaliableElementsMap[element.id] = element
  })

  const additionalConditionState =
    formElementTypes[elementType]?.additionalConditions

  return (
    <div style={{ padding: 15 }}>
      <Grid container direction='row' alignItems='center'>
        <Typography>
          <Trans>Conditions</Trans>
        </Typography>
        <IconButton
          onClick={() => {
            const newConditions = [...conditions]
            newConditions.push({
              state: isSub ? 'sub' : 'show'
            })
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'conditions',
              subfieldName: 'conditions',
              fieldIndex: subIndex,
              fieldValue: newConditions
            })
          }}
        >
          <Icon>add</Icon>
        </IconButton>
        {!isSub && (
          <TooltipLabelIcon
            tooltip={
              <Trans>
                In case of conflicting conditions (e.g. Show and hide at the
                same time) the hide condition will always take precedence and
                the form element will stay hidden
              </Trans>
            }
          />
        )}
      </Grid>
      <Grid container direction='column'>
        {conditions.map((item, index) => {
          const { altLabelEN, altLabelFR, state, conditionTarget, condition } =
            item
          let selectedElement = avaliableElementsMap[conditionTarget]
          if (specialFormConditions[conditionTarget]) {
            selectedElement = {
              label: conditionTarget
            }
          }
          let rules, rulesType
          if (selectedElement) {
            rulesType = selectedElement.sfField
              ? sfFieldToConditionType[selectedElement.type]
              : selectedElement.type
            if (rulesType) {
              rules = formConditions[rulesType].conditions
            }
          }

          let selectableOptions = []
          if (selectedElement && selectedElement.picklistValues) {
            selectableOptions = selectedElement.picklistValues.map(
              (option, index) => ({
                label:
                  option.label ||
                  option.apiValue ||
                  getTranslatableLabel(option),
                value: option.value || option.apiValue || 'option' + index
              })
            )
          }
          const showAltLabels = state === 'altLabel' || state === 'altHelpText'

          return (
            <>
              <Grid
                item
                container
                direction='row'
                key={index}
                justifyContent='space-between'
                alignItems='flex-start'
                wrap='nowrap'
              >
                <div style={{ marginTop: 25 }}>{index + 1 + '. '}</div>
                <Grid item style={{ flex: 1, padding: 10 }}>
                  <Grid container direction='column'>
                    {!isSub && (
                      <TextField
                        select
                        variant='outlined'
                        label={<Trans>If condition is met:</Trans>}
                        fullWidth
                        value={state || ''}
                        onChange={e => {
                          const newConditions = [...conditions]
                          delete newConditions[index][state]
                          newConditions[index].state = e.target.value
                          delete newConditions[index].altLabelEN
                          delete newConditions[index].altLabelFR
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'conditions',
                            subfieldName: 'conditions',
                            fieldIndex: subIndex,
                            fieldValue: newConditions
                          })
                        }}
                      >
                        {standardConditionStates
                          .filter(
                            obj =>
                              elementType !== 'section' ||
                              (elementType === 'section' && obj.labelSection)
                          )
                          .map(obj => {
                            return (
                              <MenuItem value={obj.value} key={obj.value}>
                                {elementType === 'section'
                                  ? obj.labelSection
                                  : obj.label}
                              </MenuItem>
                            )
                          })}
                        {additionalConditionState &&
                          Object.keys(additionalConditionState).map(key => {
                            const obj = additionalConditionState[key]
                            return (
                              <MenuItem value={key} key={key}>
                                {obj.label}
                              </MenuItem>
                            )
                          })}
                      </TextField>
                    )}

                    {additionalConditionState &&
                      additionalConditionState[state]?.component && (
                        <div style={{ marginTop: 8 }}>
                          {additionalConditionState[state]?.component({
                            value: item[state],
                            onChange: value => {
                              const newConditions = [...conditions]
                              newConditions[index][state] = value
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                fieldName: 'conditions',
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldValue: newConditions
                              })
                            }
                          })}
                        </div>
                      )}

                    {Boolean(showAltLabels) && (
                      <Grid container direction='row' style={{ marginTop: 5 }}>
                        <Grid xs={6} item style={{ padding: 5 }}>
                          <TextField
                            variant='outlined'
                            fullWidth
                            label={<Trans>Alternative label - English</Trans>}
                            value={altLabelEN || ''}
                            onChange={e => {
                              const newConditions = [...conditions]
                              newConditions[index].altLabelEN = e.target.value
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                fieldName: 'conditions',
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldValue: newConditions
                              })
                            }}
                          />
                        </Grid>
                        <Grid xs={6} item style={{ padding: 5 }}>
                          <TextField
                            variant='outlined'
                            fullWidth
                            label={<Trans>Alternative label - French</Trans>}
                            value={altLabelFR || ''}
                            onChange={e => {
                              const newConditions = [...conditions]
                              newConditions[index].altLabelFR = e.target.value
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldName: 'conditions',
                                fieldValue: newConditions
                              })
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {!isSub && (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Boolean(item.conditions)}
                              onChange={e => {
                                const newConditions = [...conditions]
                                newConditions[index].conditions = []
                                if (e.target.checked) {
                                  delete newConditions[index].sfField
                                  delete newConditions[index].sfObject
                                  delete newConditions[index].condition
                                  delete newConditions[index].parameter
                                  delete newConditions[index].conditionTarget
                                } else {
                                  delete newConditions[index].conditions
                                }
                                dispatch({
                                  type: 'FIELD',
                                  depth: depth.split('.'),
                                  subfieldName: 'conditions',
                                  fieldIndex: subIndex,
                                  fieldName: 'conditions',
                                  fieldValue: newConditions
                                })
                              }}
                            />
                          }
                          label={<Trans>Is a group condition</Trans>}
                        />
                        {item.conditions && (
                          <TextField
                            select
                            variant='outlined'
                            fullWidth
                            value={item.logic || 'all'}
                            onChange={e => {
                              const newConditions = [...conditions]
                              newConditions[index].logic = e.target.value
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldName: 'conditions',
                                fieldValue: newConditions
                              })
                            }}
                          >
                            <MenuItem value='all'>
                              <Trans>All conditions</Trans>
                            </MenuItem>
                            <MenuItem value='any'>
                              <Trans>Any condition</Trans>
                            </MenuItem>
                          </TextField>
                        )}
                      </div>
                    )}

                    {item.conditions ? (
                      <ConditionalElementEditor
                        french={french}
                        id={id}
                        depth={depth}
                        subIndex={index}
                        conditions={item.conditions}
                      />
                    ) : (
                      <Autocomplete
                        freeSolo={false}
                        value={selectedElement ? selectedElement.label : ''}
                        onChange={(e, value) => {
                          let target
                          avaliableElements.some(item => {
                            const bool = item.label === value
                            if (bool) {
                              target = item
                            }
                            return bool
                          })
                          const newConditions = [...conditions]
                          if (target) {
                            newConditions[index].conditionTarget = target.id
                            if (target.sfField) {
                              newConditions[index].sfField = target.sfField
                              newConditions[index].sfObject = target.sfObject
                            } else {
                              delete newConditions[index].sfField
                              delete newConditions[index].sfObject
                            }
                            delete newConditions[index].condition
                            delete newConditions[index].parameter
                          } else if (specialFormConditions[value]) {
                            newConditions[index].conditionTarget = value
                          } else {
                            newConditions[index] = {}
                          }
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            subfieldName: 'conditions',
                            fieldIndex: subIndex,
                            fieldName: 'conditions',
                            fieldValue: newConditions
                          })
                        }}
                        style={{ marginTop: 15 }}
                        fullWidth
                        options={[
                          ...Object.entries(specialFormConditions).map(
                            ([key, value]) => key
                          ),
                          ...avaliableElements.map((item, index) => item.label)
                        ]}
                        getOptionLabel={option =>
                          specialFormConditions[option] || option
                        }
                        renderInput={params => {
                          const { value } = params.inputProps
                          if (specialFormConditions[value]) {
                            params.inputProps.value =
                              specialFormConditions[value]
                          }
                          return (
                            <TextField
                              variant='outlined'
                              {...params}
                              label={<Trans>Condition target</Trans>}
                            />
                          )
                        }}
                      />
                    )}
                    {/* <MenuItem value='errorsPresent'>
                      <Trans>Form has errors present</Trans>
                    </MenuItem>
                    <MenuItem value='noErrors'>
                      <Trans>Form has no errors</Trans>
                    </MenuItem> */}
                    <Grid container direction='row' style={{ marginTop: 10 }}>
                      {rules && (
                        <Grid
                          xs={
                            ['bool', 'otherGrants'].includes(rulesType) ||
                            ['isEmpty', 'isNotEmpty'].includes(condition)
                              ? 12
                              : 6
                          }
                          item
                        >
                          <TextField
                            select
                            variant='outlined'
                            label={<Trans>Condition</Trans>}
                            fullWidth
                            value={item.condition || ''}
                            onChange={e => {
                              const newConditions = [...conditions]
                              newConditions[index].condition = e.target.value
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldName: 'conditions',
                                fieldValue: newConditions
                              })
                            }}
                          >
                            {Object.keys(rules)
                              .filter(key => !rules[key].deprecated)
                              .map((key, index) => {
                                const ruleObj = rules[key]
                                let text = ruleObj.text

                                if (typeof text === 'function') {
                                  text = text(selectedElement)
                                }
                                return (
                                  <MenuItem value={key} key={index}>
                                    {text}
                                  </MenuItem>
                                )
                              })}
                          </TextField>
                        </Grid>
                      )}

                      {rules &&
                        [
                          'textInputNumeric',
                          'textInput',
                          'numericSlider'
                        ].includes(rulesType) && (
                          <Grid xs={6} item>
                            <TextField
                              variant='outlined'
                              fullWidth
                              InputProps={
                                ['numericSlider', 'textInputNumeric'].includes(
                                  rulesType
                                ) && {
                                  inputComponent: NumberFormatCustom
                                }
                              }
                              value={item.parameter || ''}
                              onChange={e => {
                                const newConditions = [...conditions]
                                newConditions[index].parameter = e.target.value
                                dispatch({
                                  type: 'FIELD',
                                  subfieldName: 'conditions',
                                  fieldIndex: subIndex,
                                  depth: depth.split('.'),
                                  fieldName: 'conditions',
                                  fieldValue: newConditions
                                })
                              }}
                            />
                          </Grid>
                        )}

                      {rules &&
                        rulesType === 'picklist' &&
                        !['isEmpty', 'isNotEmpty'].includes(condition) && (
                          <Grid xs={6} item>
                            <TextField
                              disabled={
                                selectableOptions.length === 0 ||
                                !item.condition
                              }
                              variant='outlined'
                              select
                              fullWidth
                              value={item.parameter || ''}
                              onChange={e => {
                                const newConditions = [...conditions]
                                newConditions[index].parameter = e.target.value
                                dispatch({
                                  type: 'FIELD',
                                  depth: depth.split('.'),
                                  fieldName: 'conditions',
                                  subfieldName: 'conditions',
                                  fieldIndex: subIndex,
                                  fieldValue: newConditions
                                })
                              }}
                            >
                              {selectableOptions.map((option, index) => (
                                <MenuItem value={option.value} key={index}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        )}

                      {rules && rulesType === 'datePicker' && (
                        <Grid xs={6} item>
                          <CustomDatePicker
                            inputVariant='outlined'
                            fullWidth
                            format={dateFormat}
                            value={item.parameter || null}
                            onChange={e => {
                              const newConditions = [...conditions]
                              newConditions[index].parameter = e
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                fieldName: 'conditions',
                                subfieldName: 'conditions',
                                fieldIndex: subIndex,
                                fieldValue: newConditions
                              })
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <div style={{ width: 50, marginTop: 15 }}>
                  <IconButton
                    onClick={() => {
                      const newConditions = [...conditions]
                      newConditions.splice(index, 1)
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'conditions',
                        subfieldName: 'conditions',
                        fieldIndex: subIndex,
                        fieldValue: newConditions
                      })
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              </Grid>
              {!isSub && index !== conditions.length - 1 && (
                <Divider style={{ marginTop: 8, marginBottom: 12 }} />
              )}
            </>
          )
        })}
      </Grid>
    </div>
  )
}

function NumberFormatCustom (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}

export default ConditionalElementEditor
