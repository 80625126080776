import React from 'react'
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'
import { useDrag, useDrop } from 'react-dnd'
import { t, Trans } from '@lingui/macro'
import { GroupElement } from './GroupElement'
import { draggableItemTypes } from './FormWizard'
import * as crypto from 'crypto'
import { useDispatch, useSelector } from 'react-redux'
import { NumberFormatDefault } from './components/Common'
import { TooltipLabelIcon } from '../page-layouts/TooltipLabelIcon'
import { myI18n } from 'translation/I18nConnectedProvider'
import ConditionalElementEditor from './ConditionalElementEditor'
import { formItemPadding } from './Form'
import { cloneDeep } from 'lodash'
import { FormEditorElementHeaderBar } from './EditorCommonComponents'

// this breaks lingui 2.x
// export const stylePropTooltip = myI18n._(
//   t`This field allows to set all CSS properties by setting them in stringified object format. The correct input looks like this: \{"backgroundColor":"red","fontWeight":500\}`
// )
export const stylePropTooltip =
  'This field allows to set all CSS properties by setting them in stringified object format. The correct input looks like this: {"backgroundColor":"red","fontWeight":500}'

const GroupCard = ({
  item,
  type,
  key,
  dummy,
  movingInStack,
  placeholder,
  depth = [],
  ...props
}) => {
  const body = React.useRef()
  const handler = React.useRef()
  const dispatch = useDispatch()

  const [{ isHoveredOverStrict }, drop] = useDrop({
    accept: [draggableItemTypes.GROUP_CARD, draggableItemTypes.ITEM_ELEMENT],
    collect (monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isHoveredOverStrict: monitor.isOver({ shallow: true })
      }
    },
    drop (item, monitor) {
      if (!body.current) {
        return
      }
      if (!isHoveredOverStrict) {
        return
      }
      if (item.dropped) {
        return
      }
      const dragDepth = [...item.depth]
      const hoverDepth = [...depth]
      if (dragDepth.join('.') === hoverDepth.join('.')) {
        dispatch({
          type: 'FIELD',
          injectable: item.injectable,
          depth,
          fieldName: 'dummy',
          fieldValue: false
        })
        return
      }
      const dragParentPath = [...dragDepth]
      dragParentPath.splice(-1, 1)
      const hoverParentPath = [...hoverDepth]
      hoverParentPath.splice(-1, 1)
      const isInSameGroup =
        hoverParentPath.join('.') === dragParentPath.join('.')
      if (!isInSameGroup) {
        dispatch({
          type: 'MOVE_ITEM_BETWEEN',
          injectable: item.injectable,
          dragDepth,
          hoverDepth,
          endDragDepth: [...hoverDepth]
        })
      } else {
        const parentDepth = [...depth]
        const dragIndex = dragDepth[dragDepth.length - 1]
        const hoverIndex = hoverDepth[hoverDepth.length - 1]
        parentDepth.splice(-1, 1)
        dispatch({
          dragIndex,
          hoverIndex,
          depth: parentDepth,
          endDragDepth: [...hoverDepth],
          injectable: item.injectable,
          type: 'MOVE_ITEM'
        })
      }
      item.depth = [...hoverDepth]
      item.dropped = true
    }
  })

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: draggableItemTypes.GROUP_CARD,
      canDrag: () => !props.selectedParent,
      item: props => {
        // handleStartDrag(depth)
        dispatch({
          type: 'START_DRAG',
          injectable: item.injectable,
          depth
        })
        const dragRectWidth = body.current?.getBoundingClientRect().width
        return {
          ...item,
          depth: [...depth],
          type: type,
          width: dragRectWidth
        }
      },
      end (item, monitor) {
        dispatch({
          type: 'END_DRAG',
          injectable: item.injectable,
          depth: [...item.depth]
        })
      },
      collect: monitor => ({
        isDragging: !!monitor.isDragging()
      })
    }),
    [item, type, key, dummy, movingInStack, placeholder, depth, props]
  )
  drop(body)
  drag(handler)

  return (
    <>
      <div ref={preview} />
      <GroupCardComponent
        isHoveredOverMain={isHoveredOverStrict}
        isDragging={isDragging}
        item={item}
        dropRef={placeholder ? null : body}
        dragRef={placeholder ? null : handler}
        hide={dummy || movingInStack}
        placeholder={placeholder}
        depth={depth.join('.')}
        {...props}
      />
    </>
  )
}

export const GroupCardComponent = props => {
  const {
    isDragging,
    width,
    placeholder,
    hide,
    dragRef,
    dropRef,
    item,
    french,
    isDragLayer,
    selectedParent,
    isHoveredOverMain,
    showPdfProps
  } = props
  const depth = props.depth.split('.')
  const index = Number(depth[depth.length - 1])
  const {
    editMode,
    selected,
    columns,
    emptySpace,
    headerFontSize,
    bold,
    italics,
    style,
    headerStyle,
    pdfBorderDisplay,
    padding = {},
    injectable
  } = item
  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)
  const { copied } = tree

  const [{ isHoveredOver, dropped, isHoveredOverStrict }, drop] = useDrop({
    accept: hide
      ? []
      : [
          draggableItemTypes.GROUP_CARD,
          draggableItemTypes.GROUP_ELEMENT,
          draggableItemTypes.ITEM_ELEMENT
        ],
    collect (monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isHoveredOver: monitor.isOver(),
        dropped: monitor.didDrop(),
        isHoveredOverStrict: monitor.isOver({ shallow: true })
      }
    },
    drop (draggedItem, monitor) {
      if (!dropRef.current) {
        return
      }
      if (!isHoveredOverStrict) {
        return
      }
      const dragDepth = draggedItem.depth
      const hoverDepth = [...depth]
      const parentPath = [...dragDepth]
      if (dragDepth.join('.') === hoverDepth.join('.')) {
        return
      }
      if (draggedItem.dropped) {
        return
      }
      const moveWithinParent = parentPath.join('.') === hoverDepth.join('.')
      if (!moveWithinParent) {
        const targetParentPath = [...depth]
        targetParentPath.splice(-1, 1)
        const isInSameGroup =
          targetParentPath.join('.') === parentPath.join('.')
        const movingMainGroup = dragDepth.length === 2
        const dropIndex = hoverDepth[hoverDepth.length - 1]
        const dragIndex = dragDepth[dragDepth.length - 1]
        const insertIndex = item.elements.length
        dispatch({
          type: 'MOVE_ITEM_BETWEEN',
          injectable,
          dragDepth,
          hoverDepth,
          endDragDepth: [...hoverDepth, insertIndex],
          props: {
            loopAllDrop: true,
            insertIndex,
            emptySpace:
              Boolean(isInSameGroup && dropIndex > dragIndex) ||
              Boolean(movingMainGroup && dropIndex > dragIndex)
          }
        })
        draggedItem.depth = [...hoverDepth, insertIndex]
        draggedItem.dropped = true
      }
    }
  })

  let styleToPass = {}
  let headerStyleToPass = {}
  let isError = false
  try {
    const parsed = JSON.parse(style)
    if (typeof parsed === 'object') {
      styleToPass = Object.assign(styleToPass, parsed)
    } else {
      isError = true
    }
  } catch (e) {
    isError = Boolean(style)
  }
  try {
    const parsed = JSON.parse(headerStyle)
    if (typeof parsed === 'object') {
      headerStyleToPass = Object.assign(headerStyleToPass, parsed)
    } else {
      isError = true
    }
  } catch (e) {
    isError = Boolean(headerStyle)
  }
  if (headerFontSize) {
    headerStyleToPass.fontSize = +headerFontSize
  }
  if (bold) {
    headerStyleToPass.fontWeight = 'bold'
  }
  if (italics) {
    headerStyleToPass.fontStyle = 'italic'
  }
  const paddingStyles = {}
  const paddingKeys = [
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom'
  ]
  paddingKeys.forEach(key => {
    let toSet = showPdfProps ? 0 : formItemPadding
    if (padding[key]) {
      toSet = Number(padding[key])
    }
    paddingStyles[key] = toSet
  })

  return (
    <div style={{ width: '100%' }}>
      <Card
        elevation={5}
        id={item.id}
        style={{
          margin: 10,
          padding: 10,
          backgroundColor:
            Boolean(isHoveredOverMain && !hide) && 'rgba(255, 255, 180, 0.7)',
          width: width,
          zIndex: placeholder && 101,
          opacity: emptySpace ? 0 : hide ? 0.2 : 1,
          minHeight: 150,
          border: '1px solid rgba(0, 0, 0, 1)'
        }}
      >
        <div ref={dropRef || null}>
          <FormEditorElementHeaderBar
            {...item}
            dragRef={dragRef}
            isDragging={isDragging}
            {...props}
            index={index}
            depth={depth}
          />
          <div style={{ flexGrow: 1, padding: 10 }}>
            {editMode && (
              <div>
                <div style={{ padding: 5 }}>
                  <TextField
                    style={{ marginBottom: 10 }}
                    label={<Trans>Title - English</Trans>}
                    value={item.titleEN || ''}
                    onChange={e => {
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth,
                        fieldName: 'titleEN',
                        fieldValue: e.currentTarget.value
                      })
                    }}
                    fullWidth
                  />
                </div>
                <div style={{ padding: 5 }}>
                  <TextField
                    style={{ marginBottom: 10 }}
                    label={<Trans>Title - French</Trans>}
                    value={item.titleFR || ''}
                    onChange={e => {
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth,
                        fieldName: 'titleFR',
                        fieldValue: e.currentTarget.value
                      })
                    }}
                    fullWidth
                  />
                  <Grid container wrap='nowrap'>
                    <TextField
                      style={{ marginBottom: 10, paddingRight: 5 }}
                      label={<Trans>Style</Trans>}
                      value={style || ''}
                      fullWidth
                      onChange={e => {
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth,
                          fieldName: 'style',
                          fieldValue: e.currentTarget.value
                        })
                      }}
                    />
                    <TooltipLabelIcon tooltip={stylePropTooltip} />
                  </Grid>

                  <Grid container style={{ marginTop: 10 }}>
                    {[
                      {
                        key: 'paddingLeft',
                        label: <Trans>Padding left</Trans>
                      },
                      {
                        key: 'paddingRight',
                        label: <Trans>Padding right</Trans>
                      },
                      { key: 'paddingTop', label: <Trans>Padding top</Trans> },
                      {
                        key: 'paddingBottom',
                        label: <Trans>Padding bottom</Trans>
                      }
                    ].map((obj, index) => (
                      <Grid item xs key={index} style={{ padding: 5 }}>
                        <TextField
                          style={{ marginBottom: 10 }}
                          label={obj.label}
                          value={padding[obj.key] || '0'}
                          fullWidth
                          InputProps={{
                            inputComponent: NumberFormatDefault
                          }}
                          variant='outlined'
                          onChange={e => {
                            const toSet = { ...padding }
                            toSet[obj.key] = e.target.value
                            dispatch({
                              type: 'FIELD',
                              injectable,
                              depth,
                              fieldName: 'padding',
                              fieldValue: toSet
                            })
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container wrap='nowrap'>
                    <TextField
                      style={{ marginBottom: 10 }}
                      label={<Trans>Items padding</Trans>}
                      value={item.itemsSpacing || ''}
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatDefault
                      }}
                      onChange={e => {
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth,
                          fieldName: 'itemsSpacing',
                          fieldValue: e.target.value
                        })
                      }}
                    />
                    <TooltipLabelIcon
                      tooltip={
                        <Trans>
                          Setting this prop will override padding of all
                          children of this group setting it to desired value in
                          all directions
                        </Trans>
                      }
                    />
                  </Grid>

                  <TextField
                    style={{ marginBottom: 10 }}
                    label={<Trans>Labels width</Trans>}
                    value={item.labelsWidth || ''}
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatDefault
                    }}
                    onChange={e => {
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth,
                        fieldName: 'labelsWidth',
                        fieldValue: e.target.value
                      })
                    }}
                  />
                </div>

                <Grid container direction='row' alignItems='center'>
                  <FormLabel style={{ marginRight: 10 }}>
                    <Trans>Columns</Trans>
                  </FormLabel>
                  <RadioGroup
                    value={item.columns || 1}
                    row
                    onChange={e => {
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth,
                        fieldName: 'columns',
                        fieldValue: e.currentTarget.value
                      })
                    }}
                  >
                    {['1', '2', '3'].map((value, index) => (
                      <FormControlLabel
                        key={index}
                        value={value}
                        control={<Radio />}
                        label={value}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
                <div>
                  <Typography variant='h6'>
                    <Trans>Header props</Trans>
                  </Typography>
                  <TextField
                    style={{ marginBottom: 10 }}
                    label={<Trans>Header font size</Trans>}
                    value={headerFontSize || ''}
                    fullWidth
                    onChange={e => {
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth,
                        fieldName: 'headerFontSize',
                        fieldValue: e.currentTarget.value
                      })
                    }}
                  />
                  <div style={{ marginLeft: 5 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(bold)}
                          onChange={e => {
                            dispatch({
                              type: 'FIELD',
                              injectable,
                              depth,
                              fieldName: 'bold',
                              fieldValue: e.target.checked
                            })
                          }}
                        />
                      }
                      label={<Trans>Bold</Trans>}
                    />
                  </div>
                  <div style={{ marginLeft: 5 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(italics)}
                          onChange={e => {
                            dispatch({
                              type: 'FIELD',
                              injectable,
                              depth,
                              fieldName: 'italics',
                              fieldValue: e.target.checked
                            })
                          }}
                        />
                      }
                      label={<Trans>Italics</Trans>}
                    />
                  </div>
                  <Grid container wrap='nowrap'>
                    <TextField
                      style={{ marginBottom: 10, paddingRight: 5 }}
                      label={<Trans>Header style</Trans>}
                      value={headerStyle || ''}
                      fullWidth
                      onChange={e => {
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth,
                          fieldName: 'headerStyle',
                          fieldValue: e.currentTarget.value
                        })
                      }}
                    />
                    <TooltipLabelIcon tooltip={stylePropTooltip} />
                  </Grid>
                </div>
                {props.showPdfProps && (
                  <div style={{ marginTop: 10 }}>
                    <Typography variant='h6'>
                      <Trans>Pdf props</Trans>
                    </Typography>
                    <div style={{ padding: 5 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(pdfBorderDisplay)}
                            onChange={e => {
                              dispatch({
                                type: 'FIELD',
                                injectable,
                                depth,
                                fieldName: 'pdfBorderDisplay',
                                fieldValue: e.target.checked
                              })
                            }}
                          />
                        }
                        label={<Trans>Display with border</Trans>}
                      />
                    </div>
                  </div>
                )}
                {!injectable && <ConditionalElementEditor {...props} />}
                <Grid container spacing={1} style={{ marginTop: 10 }}>
                  <Grid item>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        dispatch({
                          type: 'FIELD',
                          depth,
                          injectable,
                          fieldName: 'elements',
                          fieldValue: [
                            ...item.elements,
                            {
                              titleEN: '',
                              titleFR: '',
                              editMode: true,
                              showBasicElements: true,
                              elementType: 'text',
                              typeProps: {},
                              id: crypto.randomBytes(16).toString('hex')
                            }
                          ]
                        })
                      }}
                    >
                      <Grid container direction='row' alignItems='center'>
                        <Trans>Add new element</Trans>
                        <Icon style={{ marginLeft: 5 }}>add</Icon>
                      </Grid>
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        dispatch({
                          type: 'FIELD',
                          depth,
                          injectable,
                          fieldName: 'elements',
                          fieldValue: [
                            ...item.elements,
                            {
                              titleEN: '',
                              titleFR: '',
                              editMode: true,
                              showBasicElements: true,
                              colums: '1',
                              padding: {},
                              typeProps: {},
                              elements: [],
                              id: crypto.randomBytes(16).toString('hex')
                            }
                          ]
                        })
                      }}
                    >
                      <Grid container direction='row' alignItems='center'>
                        <Trans>Add new group</Trans>
                        <Icon style={{ marginLeft: 5 }}>add</Icon>
                      </Grid>
                    </Button>
                  </Grid>

                  {copied && !copied.section && (
                    <Grid item>
                      <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          dispatch({
                            type: 'ADD_COPIED',
                            object: copied,
                            depth
                          })
                        }}
                      >
                        <Grid container justify='center' alignItems='center'>
                          <Trans>Add copied element</Trans>
                          <Icon style={{ marginLeft: 5 }}>add</Icon>
                        </Grid>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
            {!editMode && (
              <div style={{ ...styleToPass }}>
                <div style={{ ...headerStyleToPass }}>
                  {french ? item.titleFR : item.titleEN}
                </div>
                {isError && (
                  <span
                    style={{
                      color: '#f5543b',
                      fontSize: '0.75rem',
                      marginTop: 3,
                      marginLeft: 14,
                      textAlign: 'left'
                    }}
                  >
                    <Trans>
                      Style props for this component were inncorrectly provided
                    </Trans>
                  </span>
                )}
              </div>
            )}
          </div>
        </div>

        <div ref={!isDragLayer ? drop : null}>
          <Grid
            container
            direction='row'
            style={{
              minHeight: isHoveredOver ? 100 : 50,
              backgroundColor:
                Boolean(isHoveredOverStrict) && 'rgba(255, 255, 180, 0.7)',
              border: '1px dashed rgba(0, 0, 0, 1)',
              marginTop: 15,
              ...paddingStyles
            }}
          >
            {item.elements &&
              item.elements.map((element, index) => {
                if (element.elements) {
                  return (
                    <Grid
                      key={index}
                      item
                      container
                      xs={columns ? 12 / +columns : 12}
                    >
                      <GroupCard
                        french={french}
                        hide={element.dummy || element.movingInStack}
                        item={{ ...element, injectable }}
                        selectedParent={Boolean(selected || selectedParent)}
                        selectedElements={props.selectedElements}
                        index={index}
                        showPrintProps={props.showPrintProps}
                        showPdfProps={props.showPdfProps}
                        depth={[...depth, index]}
                      />
                    </Grid>
                  )
                }
                return (
                  <GroupElement
                    key={index}
                    parentIsDummy={hide}
                    {...element}
                    french={french}
                    formId={props.formId}
                    selectedElements={props.selectedElements}
                    selectedParent={selected || selectedParent}
                    columns={item.columns}
                    showPrintProps={props.showPrintProps}
                    showPdfProps={props.showPdfProps}
                    depth={[...depth, index]}
                    index={index}
                    injectable={injectable}
                  />
                )
              })}
          </Grid>
        </div>
      </Card>
    </div>
  )
}

export default GroupCard
