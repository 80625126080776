/**
 * Created by Michał Stawski on 30.11.2020.
 */
export const satScoreCard = [
  {
    'QuestionID': '595fca2f776ec5737196021bd59cf9d23c2efc62',
    'Parent Text': '',
    'Text EN': 'How would you describe your organization?  (Select one)',
    'Created date': '2020-11-28T18:05:46',
    'Section': 'SECTION A:  BACKGROUND INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '23320604ceb0c645d01f6c425462709e2d140839',
    'Parent Text': '',
    'Text EN': 'Does your organization serve a mix of all types of households?',
    'Created date': '2020-11-28T18:05:46',
    'Section': 'SECTION A:  BACKGROUND INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '3b16682b12102562c5ee4a752219996b93d762d0',
    'Parent Text': '',
    'Text EN': 'Which of the following types of households live in your housing?  (Select all that apply)',
    'Created date': '2020-11-28T18:05:46',
    'Section': 'SECTION A:  BACKGROUND INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8e4fe1ac66106af14c5c4e072cc15b1b2d5147e3.0',
    'Parent Text': 'Some organizations have several separate housing properties developed under different housing programs over time, while others have only one property.  Please indicate the number of different housing properties your organization owns and the number of housing units:',
    'Text EN': 'Number of housing properties owned at various locations:',
    'Created date': '2020-11-28T18:05:46',
    'Section': 'SECTION A:  BACKGROUND INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8e4fe1ac66106af14c5c4e072cc15b1b2d5147e3.1',
    'Parent Text': 'Some organizations have several separate housing properties developed under different housing programs over time, while others have only one property.  Please indicate the number of different housing properties your organization owns and the number of housing units:',
    'Text EN': 'Total number of housing units/beds in all your properties:',
    'Created date': '2020-11-28T18:05:46',
    'Section': 'SECTION A:  BACKGROUND INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5f705dda3ac7db1b9518ff011f5f5647ee773428',
    'Parent Text': '',
    'Text EN': 'Does your organization also manage some properties for another organization? (IF "No" SKIP TO Q.7)',
    'Created date': '2020-11-28T18:05:46',
    'Section': 'SECTION A:  BACKGROUND INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '69054e46289b5c09d5822b0083492f4b93880832',
    'Parent Text': '',
    'Text EN': 'How many added housing units do you manage for other groups?',
    'Created date': '2020-11-28T18:05:46',
    'Section': 'SECTION A:  BACKGROUND INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '4155cfa72707f40c52b115747a199169cc5f1d43',
    'Parent Text': '',
    'Text EN': 'Do you provide supportive housing with services delivered in the housing? (IF "No" SKIP TO Q.9)',
    'Created date': '2020-11-28T18:05:46',
    'Section': 'SECTION A:  BACKGROUND INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e5648fcaf5457953a323e7de7897816a9e8bf360',
    'Parent Text': '',
    'Text EN': 'Please indicate the number of spaces for people in supportive housing (may be units or shared living):',
    'Created date': '2020-11-28T18:05:46',
    'Section': 'SECTION A:  BACKGROUND INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '926ddbb7dbbff7def3f7b3d89bf1f270e69af8d1',
    'Parent Text': 'Considering all your housing, how satisfied are you with the governance and general administration of your organization?',
    'Text EN': 'Governance (Board and Employees)',
    'Created date': '2020-11-29T16:42:14',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': 'Satisfaction',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0777f364689d69118ad899790e870ce04f1539d9.0',
    'Parent Text': 'How well do each of the following statements describe your Board? \n(Select one for each of (a) to (g))',
    'Text EN': '(a)\tThe Board has regular meetings (e.g. monthly or each quarter)',
    'Created date': '2020-11-29T16:42:15',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0777f364689d69118ad899790e870ce04f1539d9.1',
    'Parent Text': 'How well do each of the following statements describe your Board? \n(Select one for each of (a) to (g))',
    'Text EN': '(b)\tMinutes of Board are maintained (by Board or staff)',
    'Created date': '2020-11-29T16:42:15',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0777f364689d69118ad899790e870ce04f1539d9.2',
    'Parent Text': 'How well do each of the following statements describe your Board? \n(Select one for each of (a) to (g))',
    'Text EN': '(c)\tThe Board has written policies and procedures',
    'Created date': '2020-11-29T16:42:15',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0777f364689d69118ad899790e870ce04f1539d9.3',
    'Parent Text': 'How well do each of the following statements describe your Board? \n(Select one for each of (a) to (g))',
    'Text EN': '(d)\tThe Board reviews its policies and procedures regularly',
    'Created date': '2020-11-29T16:42:15',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0777f364689d69118ad899790e870ce04f1539d9.4',
    'Parent Text': 'How well do each of the following statements describe your Board? \n(Select one for each of (a) to (g))',
    'Text EN': '(e)\tThe Board members have defined terms (e.g. 3 years)',
    'Created date': '2020-11-29T16:42:15',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0777f364689d69118ad899790e870ce04f1539d9.5',
    'Parent Text': 'How well do each of the following statements describe your Board? \n(Select one for each of (a) to (g))',
    'Text EN': '(f)\tThe Board takes steps to renew Board membership (succession plan)',
    'Created date': '2020-11-29T16:42:15',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0777f364689d69118ad899790e870ce04f1539d9.6',
    'Parent Text': 'How well do each of the following statements describe your Board? \n(Select one for each of (a) to (g))',
    'Text EN': '(g)\tThere is Board orientation or training for new board members',
    'Created date': '2020-11-29T16:42:15',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7e35a3d9332e6b7dd9d892d94dd54ab6f230bcd3.0',
    'Parent Text': 'How well do each of the following statements describe how the Board carries out its obligations?  Does it describe your organization very well, somewhat or not at all?  (Select one for each of (a) to (d))',
    'Text EN': '(a)\tThe Board reviews monthly/quarterly financial statements, meets  \twith Auditor to review annual financial statements, and reviews \tother corporate reports',
    'Created date': '2020-11-29T16:42:16',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7e35a3d9332e6b7dd9d892d94dd54ab6f230bcd3.1',
    'Parent Text': 'How well do each of the following statements describe how the Board carries out its obligations?  Does it describe your organization very well, somewhat or not at all?  (Select one for each of (a) to (d))',
    'Text EN': '(b) The Board ensures that legal and regulatory requirements are met according to its obligation',
    'Created date': '2020-11-29T16:42:16',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7e35a3d9332e6b7dd9d892d94dd54ab6f230bcd3.2',
    'Parent Text': 'How well do each of the following statements describe how the Board carries out its obligations?  Does it describe your organization very well, somewhat or not at all?  (Select one for each of (a) to (d))',
    'Text EN': '(c)\tThe Board has a strategic plan with defined objectives, reviews \tprogress to meet them and reviews decisions for consistency with plan.',
    'Created date': '2020-11-29T16:42:16',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7e35a3d9332e6b7dd9d892d94dd54ab6f230bcd3.3',
    'Parent Text': 'How well do each of the following statements describe how the Board carries out its obligations?  Does it describe your organization very well, somewhat or not at all?  (Select one for each of (a) to (d))',
    'Text EN': '(d)\tBoard members include people who live in the housing',
    'Created date': '2020-11-29T16:42:16',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8935cd9d6c2756cd0fd40980f1754715201198e5.0',
    'Parent Text': 'Does your organization belong to and/or make use of services from one or more associations or federations of community housing groups?  (Select one for each of (a) to (c))',
    'Text EN': '(a)\tOur organization is a member of one or more association(s)',
    'Created date': '2020-11-29T16:42:16',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8935cd9d6c2756cd0fd40980f1754715201198e5.1',
    'Parent Text': 'Does your organization belong to and/or make use of services from one or more associations or federations of community housing groups?  (Select one for each of (a) to (c))',
    'Text EN': '(b)\tBoard members and/or staff attend meetings or events',
    'Created date': '2020-11-29T16:42:16',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8935cd9d6c2756cd0fd40980f1754715201198e5.2',
    'Parent Text': 'Does your organization belong to and/or make use of services from one or more associations or federations of community housing groups?  (Select one for each of (a) to (c))',
    'Text EN': '(c)\tOur organization uses the training and support services provided by an association(s)or federation',
    'Created date': '2020-11-29T16:42:16',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0748a4a8869191db600080dcc96f6ccf66d9d8dc',
    'Parent Text': '',
    'Text EN': 'Does your organization have any paid staff?  (IF "No" SKIP TO Q.16)',
    'Created date': '2020-11-29T16:42:16',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '972ac8334816befc953436e2db536e646549b15b.0',
    'Parent Text': 'How many paid staff (full- and part-time) work for your organization?  (Please indicate the number of full-time equivalents)',
    'Text EN': '# Managers:',
    'Created date': '2020-11-29T16:42:17',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '972ac8334816befc953436e2db536e646549b15b.1',
    'Parent Text': 'How many paid staff (full- and part-time) work for your organization?  (Please indicate the number of full-time equivalents)',
    'Text EN': '# Other staff:',
    'Created date': '2020-11-29T16:42:17',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'f0019125a71f6310f77c21b7f39809c43a401cad.0',
    'Parent Text': 'How well does each of the following statements describe the management and staff of this organization?  (Select one for each of (a) to (e))',
    'Text EN': '(a)\tThe manager(s) and/or staff person(s) we employ or contract  \tservices  have the necessary professional training and expertise to carry out management, operations and planning',
    'Created date': '2020-11-29T16:42:17',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'f0019125a71f6310f77c21b7f39809c43a401cad.1',
    'Parent Text': 'How well does each of the following statements describe the management and staff of this organization?  (Select one for each of (a) to (e))',
    'Text EN': '(b)\tOur organization has put checks and processes in place suitable for its size to eliminate the risks of error and/or fraud',
    'Created date': '2020-11-29T16:42:17',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'f0019125a71f6310f77c21b7f39809c43a401cad.2',
    'Parent Text': 'How well does each of the following statements describe the management and staff of this organization?  (Select one for each of (a) to (e))',
    'Text EN': '(c)\tThe organization has updated its IT software, hardware or security and ensured staff IT training',
    'Created date': '2020-11-29T16:42:17',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'f0019125a71f6310f77c21b7f39809c43a401cad.3',
    'Parent Text': 'How well does each of the following statements describe the management and staff of this organization?  (Select one for each of (a) to (e))',
    'Text EN': '(d)\tHuman resources policies are in place for hiring and managing staff and to ensure staff take any training courses required',
    'Created date': '2020-11-29T16:42:17',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'f0019125a71f6310f77c21b7f39809c43a401cad.4',
    'Parent Text': 'How well does each of the following statements describe the management and staff of this organization?  (Select one for each of (a) to (e))',
    'Text EN': '(e)\tOur manager(s) report regularly to the Board on the \tapproved plans and ensures that all reports are prepared to standards and to meet deadlines',
    'Created date': '2020-11-29T16:42:17',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'a9911eea218e641827fedf18718197b06625cbcb',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the current management of the organization by staff?  (Select one)',
    'Created date': '2020-11-29T16:42:18',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '019d7749577522473aa50912e1fe904162f09152',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the current management of the organization by the Board?  (Select one)',
    'Created date': '2020-11-29T16:42:18',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '2dfade0394e1f588fccbbc9466db4b0569109667',
    'Parent Text': '',
    'Text EN': 'Does your organization own more than one property?  By â€śpropertyâ€ť we mean the housing covered under each funding application under a government housing loan and operating agreement (if applicable).  A property can be one building or several and can include different types of buildings and clients.  Many organizations call this a â€śprojectâ€ť.',
    'Created date': '2020-11-29T16:42:18',
    'Section': 'SECTION B:  ABOUT YOUR BOARD & MANAGEMENT (GOVERNANCE)',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'acfdbbf59e3e97309e74ca8a5d768d7bf6a94ac8',
    'Parent Text': '',
    'Text EN': 'Background - Property Profile',
    'Created date': '2020-11-29T02:01:19',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'a547b38a6e0d5fce263066321f189e40ba102477.0',
    'Parent Text': 'Please indicate the location of this selected property:',
    'Text EN': 'Street address:',
    'Created date': '2020-11-29T02:01:12',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'a547b38a6e0d5fce263066321f189e40ba102477.1',
    'Parent Text': 'Please indicate the location of this selected property:',
    'Text EN': 'City:',
    'Created date': '2020-11-29T02:01:12',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'a547b38a6e0d5fce263066321f189e40ba102477.2',
    'Parent Text': 'Please indicate the location of this selected property:',
    'Text EN': 'Province/Territory:',
    'Created date': '2020-11-29T02:01:12',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5da20c7513e3c1495c9f2a0845ed296cb5a526e1',
    'Parent Text': '',
    'Text EN': 'Type(s) of households in this property:  (Select all that apply)',
    'Created date': '2020-11-29T02:01:20',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '52b9e9d0ed03815d4a3dfc0b831c3fbeef170f92.0',
    'Parent Text': 'What is the mix (percentage) of units with the following rents (or housing charges) in this selected property?',
    'Text EN': 'RGI (rent-geared-to-income) %',
    'Created date': '2020-11-29T02:01:25',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '52b9e9d0ed03815d4a3dfc0b831c3fbeef170f92.1',
    'Parent Text': 'What is the mix (percentage) of units with the following rents (or housing charges) in this selected property?',
    'Text EN': 'Below market rent %',
    'Created date': '2020-11-29T02:01:25',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '52b9e9d0ed03815d4a3dfc0b831c3fbeef170f92.2',
    'Parent Text': 'What is the mix (percentage) of units with the following rents (or housing charges) in this selected property?',
    'Text EN': 'Market rent %',
    'Created date': '2020-11-29T02:01:25',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '32cc64577eea6ef1212dac05d59383e3a26d86f4',
    'Parent Text': '',
    'Text EN': 'Does your organization have a housing operating and subsidy agreement with CMHC or a Province/Territory for this property?',
    'Created date': '2020-11-29T02:01:25',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '00938e4d779a079b19eebc884ca71eef547d3ed4',
    'Parent Text': '',
    'Text EN': 'Does your organization have a housing subsidy agreement with a Province/Territory or municipality to reduce monthly rents for some of the units in this property?',
    'Created date': '2020-11-29T02:01:12',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '9cecfa871b1f2f8bc795e209f0eb56c2602491fe',
    'Parent Text': '',
    'Text EN': 'Does your organization have a housing subsidy agreement with a Province/Territory or municipality to reduce monthly rents for some of the units in this property?',
    'Created date': '2020-11-29T02:01:25',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '2db5b31525fa524255a45a6e7f11b30c18aa44fe',
    'Parent Text': '',
    'Text EN': 'Does your organization have funding for health or social services (or a services funding agreement) from a Province/Territory or municipality for people who live in the property?',
    'Created date': '2020-11-29T02:01:25',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '1cda8439b5d9b30c08e8d8e071715faaad2dac76',
    'Parent Text': '',
    'Text EN': 'How many years does this agreement last (e.g. is it an annual agreement, does it last 2-3 years, or is it longer term)?',
    'Created date': '2020-11-29T02:01:26',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '4ae661b9c3b85bb7a8e77409ac966cdbf93e1dc8',
    'Parent Text': '',
    'Text EN': 'Thinking about this property, how would you rate the overall condition of the building(s)?',
    'Created date': '2020-11-29T02:01:13',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '9aee88c45f2a87b72ff0d309fbc99d52003ae124',
    'Parent Text': '',
    'Text EN': 'How typical is this of all your properties?  (Select one)',
    'Created date': '2020-11-29T02:01:25',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '80bbf1858381aeb1a1285142ebaf0ad09cff286f',
    'Parent Text': '',
    'Text EN': 'Property & Asset Management for this Property',
    'Created date': '2020-11-29T02:01:26',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b9ff3e63a689b35c600606e15a510a1ec7d589ef',
    'Parent Text': 'How satisfied are you with your financial management of this property?',
    'Text EN': 'Property and asset management',
    'Created date': '2020-11-29T02:01:26',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Property',
    'modifier': 'Satisfaction',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c0be98967d3833857d39fd5f91caf5064355a01d',
    'Parent Text': '',
    'Text EN': 'Have you undertaken a comprehensive building condition assessment (BCA) within the past 5 years?',
    'Created date': '2020-11-29T02:01:13',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '0',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '30819900912265224d35238b571d181b89308743',
    'Parent Text': '',
    'Text EN': 'In what year was the last assessment carried out?',
    'Created date': '2020-11-29T02:01:26',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '192d975164996ec1c6a6461f7b2bfc545b9f1cea',
    'Parent Text': '',
    'Text EN': 'Have you completed the recommended work from the most recent assessment?',
    'Created date': '2020-11-29T02:01:26',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '37f7167970437b35307a2891e6e0645ddd208ca9',
    'Parent Text': '',
    'Text EN': 'Thinking about regular maintenance and repairs, which of the following best describes your approach in this selected property?',
    'Created date': '2020-11-29T02:01:26',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '871fad974743062aa14f80b39c2f623f68f691df',
    'Parent Text': '',
    'Text EN': 'How typical is this selected property of all your properties, as regards overall building condition and property management?',
    'Created date': '2020-11-29T02:01:26',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '2643b486b0f88370d60b6c3d80df6316ae74b4ba',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate your property and asset management for this selected property?',
    'Created date': '2020-11-29T02:01:13',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'fc4a14b2a5fdc13800310fa6c4a839a1ff469be8',
    'Parent Text': '',
    'Text EN': 'Financial Management for this Property',
    'Created date': '2020-11-29T02:01:26',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '963a6733acf9ea93d2ddad44f73d0cfc0ae85cbd',
    'Parent Text': 'How satisfied are you with your financial management of this property?',
    'Text EN': 'Financial management',
    'Created date': '2020-11-29T02:01:26',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': 'Satisfaction',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6fce15444bef335b2b2553307ef9287f3ce1863a',
    'Parent Text': '',
    'Text EN': 'Over the past five years, have Capital Reserve Fund contributions been made each year for this selected property?  (Select one)',
    'Created date': '2020-11-29T02:01:27',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '1348f8105b7aab58224fc1310da80ea4c618bd1b.0',
    'Parent Text': 'How well do each of the following statements describe this selected property?  (Select one for each of (a) to (d))',
    'Text EN': '(a)\tThe property is financially viable (e.g. revenues  \tcover expenses, including regular repairs and the contributions to capital reserves)',
    'Created date': '2020-11-29T02:01:14',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '1348f8105b7aab58224fc1310da80ea4c618bd1b.1',
    'Parent Text': 'How well do each of the following statements describe this selected property?  (Select one for each of (a) to (d))',
    'Text EN': '(b) The vacancy losses are minimal and/or have been declining over the past five years',
    'Created date': '2020-11-29T02:01:14',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '1348f8105b7aab58224fc1310da80ea4c618bd1b.2',
    'Parent Text': 'How well do each of the following statements describe this selected property?  (Select one for each of (a) to (d))',
    'Text EN': '(c) Rent arrears are close to zero or have been declining over the past five years',
    'Created date': '2020-11-29T02:01:14',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '1348f8105b7aab58224fc1310da80ea4c618bd1b.3',
    'Parent Text': 'How well do each of the following statements describe this selected property?  (Select one for each of (a) to (d))',
    'Text EN': '(d) Capital reserves are adequate to meet future upgrades in the property',
    'Created date': '2020-11-29T02:01:14',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '44529d9225451ae106b88b1169ef0fc2fd579bad.0',
    'Parent Text': 'To what extent is the current mix of rents or housing charges financially viable now and over the longer term?  (Select one for each of (a) to (d))',
    'Text EN': '(a) Current mix of RGI/below market charges is viable with subsidy agreements in place',
    'Created date': '2020-11-29T02:01:15',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '44529d9225451ae106b88b1169ef0fc2fd579bad.1',
    'Parent Text': 'To what extent is the current mix of rents or housing charges financially viable now and over the longer term?  (Select one for each of (a) to (d))',
    'Text EN': '(b) Mix of rents is transitioning on turnover of units closer to market levels',
    'Created date': '2020-11-29T02:01:15',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '44529d9225451ae106b88b1169ef0fc2fd579bad.2',
    'Parent Text': 'To what extent is the current mix of rents or housing charges financially viable now and over the longer term?  (Select one for each of (a) to (d))',
    'Text EN': '(c) Revised mix is planned at the end of agreements',
    'Created date': '2020-11-29T02:01:15',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '44529d9225451ae106b88b1169ef0fc2fd579bad.3',
    'Parent Text': 'To what extent is the current mix of rents or housing charges financially viable now and over the longer term?  (Select one for each of (a) to (d))',
    'Text EN': '(d) Moving to full market levels over time to improve viability',
    'Created date': '2020-11-29T02:01:15',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '1e7038dc7d91e7e6ac7bc0d8da457694d6ab0a94',
    'Parent Text': '',
    'Text EN': 'If your selected property is still under a government (CMHC or Provincial/Territorial) housing operating agreement, has your organization completed a financial analysis to assess options for the end of its housing operating agreement (EOA)?  (Select all that apply)',
    'Created date': '2020-11-29T02:01:15',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'true',
    'Option 1': 'false',
    'Option 2': '2',
    'Option 3': '1',
    'Option 4': '1',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '968398fd8feed938ccf0eb50ad3479d1b02ce08d',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the financial status of this selected property?',
    'Created date': '2020-11-29T02:01:28',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'f102c86a5ae8e0afaafa246e5cf33ced1f483376',
    'Parent Text': '',
    'Text EN': 'How typical is this selected property of all your properties as regards financial management?',
    'Created date': '2020-11-29T02:01:28',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8c302da8f76a378ab3a5569f91d97e4c8607b16d',
    'Parent Text': '',
    'Text EN': 'Social & Community Relations for this Property',
    'Created date': '2020-11-29T02:01:15',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '881c5937d868edf9533307b04f6a0ce46a21ffad',
    'Parent Text': 'How satisfied are you with your social and community relations for this property?',
    'Text EN': 'Social and community relations',
    'Created date': '2020-11-29T02:01:28',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': 'Satisfaction',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b83dbe80c983f271f5016e1717e70c9989aa086e.0',
    'Parent Text': 'What is the extent of involvement among people living within this selected property? (Select one for each of (a) to (f))',
    'Text EN': '(a) Have input to decision-making by Board and/or committees',
    'Created date': '2020-11-29T02:01:28',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': 'false',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b83dbe80c983f271f5016e1717e70c9989aa086e.1',
    'Parent Text': 'What is the extent of involvement among people living within this selected property? (Select one for each of (a) to (f))',
    'Text EN': '(b) Help organize regular events/activities',
    'Created date': '2020-11-29T02:01:28',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': 'false',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b83dbe80c983f271f5016e1717e70c9989aa086e.2',
    'Parent Text': 'What is the extent of involvement among people living within this selected property? (Select one for each of (a) to (f))',
    'Text EN': '(c) Help prepare newsletters',
    'Created date': '2020-11-29T02:01:28',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': 'false',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b83dbe80c983f271f5016e1717e70c9989aa086e.3',
    'Parent Text': 'What is the extent of involvement among people living within this selected property? (Select one for each of (a) to (f))',
    'Text EN': '(d) Actively use common space within the building for events and activities',
    'Created date': '2020-11-29T02:01:28',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': 'false',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b83dbe80c983f271f5016e1717e70c9989aa086e.4',
    'Parent Text': 'What is the extent of involvement among people living within this selected property? (Select one for each of (a) to (f))',
    'Text EN': '(e) Participate in social events and activities',
    'Created date': '2020-11-29T02:01:28',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': 'false',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b83dbe80c983f271f5016e1717e70c9989aa086e.5',
    'Parent Text': 'What is the extent of involvement among people living within this selected property? (Select one for each of (a) to (f))',
    'Text EN': '(f) Are active in volunteer work here',
    'Created date': '2020-11-29T02:01:28',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': 'false',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '81f6017d153b2705de045eb0e7682f6ae88b221d.0',
    'Parent Text': 'What is the extent of involvement of people living in your property and people in the surrounding community?  (Select one for each of (a) to (e))',
    'Text EN': '(a)\tTake part in joint events with neighbourhood',
    'Created date': '2020-11-29T02:01:16',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '81f6017d153b2705de045eb0e7682f6ae88b221d.1',
    'Parent Text': 'What is the extent of involvement of people living in your property and people in the surrounding community?  (Select one for each of (a) to (e))',
    'Text EN': '(b)\tCommon space used for joint activities',
    'Created date': '2020-11-29T02:01:16',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '81f6017d153b2705de045eb0e7682f6ae88b221d.2',
    'Parent Text': 'What is the extent of involvement of people living in your property and people in the surrounding community?  (Select one for each of (a) to (e))',
    'Text EN': '(c) Share common services with other groups in the area',
    'Created date': '2020-11-29T02:01:16',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '81f6017d153b2705de045eb0e7682f6ae88b221d.3',
    'Parent Text': 'What is the extent of involvement of people living in your property and people in the surrounding community?  (Select one for each of (a) to (e))',
    'Text EN': '(d) Are members of other groups in the area',
    'Created date': '2020-11-29T02:01:16',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '81f6017d153b2705de045eb0e7682f6ae88b221d.4',
    'Parent Text': 'What is the extent of involvement of people living in your property and people in the surrounding community?  (Select one for each of (a) to (e))',
    'Text EN': '(e) Join in community initiatives to improve the area',
    'Created date': '2020-11-29T02:01:16',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0e92c3a3df53c824e1369c9d61350a2b6aa9a852',
    'Parent Text': '',
    'Text EN': 'How would you describe the social diversity in the property selected?',
    'Created date': '2020-11-29T02:01:29',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'fd5247b7fcb6081f140afe0a82304801952f3877',
    'Parent Text': '',
    'Text EN': 'Is this similar to or different from the social diversity or mix in the surrounding area?',
    'Created date': '2020-11-29T02:01:29',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '706d8d3cbe27a9b7e7ec5b82271441ecb1c5fc6e',
    'Parent Text': '',
    'Text EN': 'Which of the following describes accessibility in this selected property?',
    'Created date': '2020-11-29T02:01:29',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '1',
    'Option 2': '0',
    'Option 3': '1',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '52b13582a26d638de7ceca3e0579cbf668769c40',
    'Parent Text': '',
    'Text EN': 'Has your organization considered the feasibility of improving this housing\'s accessibility?  (Select one)',
    'Created date': '2020-11-29T02:01:29',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '2cae4c167622ad55a6ebed1c7c3eaedc78a1adbc',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the social and community relations within this housing and the surrounding area?  (Select one)',
    'Created date': '2020-11-29T02:01:29',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '036b914ab16cac7ae5467a6144246cf4c64db101',
    'Parent Text': '',
    'Text EN': 'Do you have any comments about the social and community relationships in this property?',
    'Created date': '2020-11-29T02:01:29',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'bc66d076662c9567a2c1b63edf0242a074c4530e',
    'Parent Text': '',
    'Text EN': 'How typical is this property of all your housing?  (Select one)',
    'Created date': '2020-11-29T02:01:29',
    'Section': 'SECTION C:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '16c29e7dbbb67dd032c972143212e3dfb9607ed3',
    'Parent Text': '',
    'Text EN': 'Background - Property Profile',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5cbbdd3d3bae8a75491f14a17e3a770ef9829db9.0',
    'Parent Text': 'Please indicate the location of this property:',
    'Text EN': 'Street address:',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5cbbdd3d3bae8a75491f14a17e3a770ef9829db9.1',
    'Parent Text': 'Please indicate the location of this property:',
    'Text EN': 'City:',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5cbbdd3d3bae8a75491f14a17e3a770ef9829db9.2',
    'Parent Text': 'Please indicate the location of this property:',
    'Text EN': 'Province/Territory:',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '46cea488cd49b9fc5c9168f1af1c822f1e7b16d1.0',
    'Parent Text': 'What is the mix (percentage) of units with the following rents (or housing charges) in this property?',
    'Text EN': 'RGI (rent-geared-to-income) %',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '46cea488cd49b9fc5c9168f1af1c822f1e7b16d1.1',
    'Parent Text': 'What is the mix (percentage) of units with the following rents (or housing charges) in this property?',
    'Text EN': 'Below market rent %',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '46cea488cd49b9fc5c9168f1af1c822f1e7b16d1.2',
    'Parent Text': 'What is the mix (percentage) of units with the following rents (or housing charges) in this property?',
    'Text EN': 'Market rent %',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '298d1f19d5f29fcbc9d28517ff156b572fbd83a9',
    'Parent Text': '',
    'Text EN': 'Does your organization have a housing operating and subsidy agreement with CMHC or a Province/ Territory for this property?',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '4b9c30910fa2575880533753623ced3a87654a52',
    'Parent Text': '',
    'Text EN': 'Is there a housing subsidy agreement with a Province/Territory or municipality to reduce monthly rents for some of the units? (IF NO,UNSURE SKIP TO Q.54)',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'af6d5ebde4d83e478b5f151d93ffb3759a7d8300',
    'Parent Text': '',
    'Text EN': 'Does your organization have funding for health or social services (or a services funding agreement) from a Province/Territory or municipality for people who live in the property?  (IF NO SKIPT TO Q.55)',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e94c37dc90b4a563936af43463bf98c84dce5394',
    'Parent Text': '',
    'Text EN': 'How many years does this agreement last (e.g. is it an annual agreement, does it last 2-3 years, or is it longer term)?',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e33125cc4e0ab4bdf562a719038e22cf47c757f3',
    'Parent Text': '',
    'Text EN': 'How would you rate the general condition of the building(s) in your property?',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'be25aba6cb426fe845bc993853ecdb5a519eb7c4',
    'Parent Text': '',
    'Text EN': 'Property & Asset Management',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '66164ae9b557640f443571d01e76b81f1c6516bd',
    'Parent Text': '',
    'Text EN': 'How satisfied are you with your property and asset management?',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Property',
    'modifier': 'Satisfaction',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '03ee9c56831981ba5d173a7fd16f5f00d18c9ace',
    'Parent Text': '',
    'Text EN': 'Have you undertaken a comprehensive building condition assessment (BCA) within the past 5 years?',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '0',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '487bf086bc66a46928e557837f37f1047950731c',
    'Parent Text': '',
    'Text EN': 'In what year was the last assessment carried out?',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '24fc879e0f870a8ecad9f633393d487552dd15ca',
    'Parent Text': '',
    'Text EN': 'Have you completed the recommended work from the most recent assessment?',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6553b77fd56ab743018ad1e2b19dcd08773859c4',
    'Parent Text': '',
    'Text EN': 'Thinking of regular maintenance and repairs, which of the following best describes your approach?',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '9b93760f5ccb839ddc674543d4adbb88e5145ad7',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate your property and asset management?',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '33bb17d9fe508af0bb2c08d04373d971eaf253bd',
    'Parent Text': '',
    'Text EN': 'Financial Management',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6ff917381ffabb2ab2b4f4a9d46dcb6e1b3cf69e',
    'Parent Text': 'How satisfied are you with your financial management?',
    'Text EN': 'Financial management',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': 'Satisfaction',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8d0965151d7b8db9fc871019fa7d8bb5306db04c',
    'Parent Text': '',
    'Text EN': 'Over the past five years, have Capital Reserve Fund contributions been made each year for your property?  (Select one)',
    'Created date': '2020-11-29T04:44:15',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '513023727f5bef07551dae7c21f279ce876359be.0',
    'Parent Text': 'How well do each of the following statements describe your property?  (Select one for each of (a) to (d))',
    'Text EN': '(a)\tThis property is financially viable (e.g. revenues  \tcover expenses, including regular repairs and the contributions to capital reserves)',
    'Created date': '2020-11-29T04:44:16',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '513023727f5bef07551dae7c21f279ce876359be.1',
    'Parent Text': 'How well do each of the following statements describe your property?  (Select one for each of (a) to (d))',
    'Text EN': '(b) The vacancy losses are minimal and/or have been declining over the past five years',
    'Created date': '2020-11-29T04:44:16',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '513023727f5bef07551dae7c21f279ce876359be.2',
    'Parent Text': 'How well do each of the following statements describe your property?  (Select one for each of (a) to (d))',
    'Text EN': '(c) Rent arrears have been declining over the past five years (or are close to zero)',
    'Created date': '2020-11-29T04:44:16',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '513023727f5bef07551dae7c21f279ce876359be.3',
    'Parent Text': 'How well do each of the following statements describe your property?  (Select one for each of (a) to (d))',
    'Text EN': '(d) Capital reserves are adequate to meet future upgrades in the property',
    'Created date': '2020-11-29T04:44:16',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '114e9361fb36ee1c7457027b7c9ec16685262f11.0',
    'Parent Text': 'To what extent is the current mix of rents or housing charges financially viable now and over the longer term?  (Select one for each of (a) to (d))',
    'Text EN': '(a) Current mix of RGI/below market charges is viable with subsidy agreements in place',
    'Created date': '2020-11-29T04:44:16',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '114e9361fb36ee1c7457027b7c9ec16685262f11.1',
    'Parent Text': 'To what extent is the current mix of rents or housing charges financially viable now and over the longer term?  (Select one for each of (a) to (d))',
    'Text EN': '(b) Mix is of rents is transitioning on turnover of units closer to market levels',
    'Created date': '2020-11-29T04:44:16',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '114e9361fb36ee1c7457027b7c9ec16685262f11.2',
    'Parent Text': 'To what extent is the current mix of rents or housing charges financially viable now and over the longer term?  (Select one for each of (a) to (d))',
    'Text EN': '(c) Revised mix planned at end of agreements',
    'Created date': '2020-11-29T04:44:16',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '114e9361fb36ee1c7457027b7c9ec16685262f11.3',
    'Parent Text': 'To what extent is the current mix of rents or housing charges financially viable now and over the longer term?  (Select one for each of (a) to (d))',
    'Text EN': '(d) Moving to full market levels over time to improve viability',
    'Created date': '2020-11-29T04:44:16',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '146e90b69313a3577107958b27a8101a6cd9fbdd',
    'Parent Text': '',
    'Text EN': 'If your property is still under a government (CMHC or provincial) housing operating agreement, has your organization completed a financial analysis to assess options for the end of its housing operating agreement (EOA)?  (Select all that apply)',
    'Created date': '2020-11-29T04:44:16',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'false',
    'Option 1': 'false',
    'Option 2': '2',
    'Option 3': '1',
    'Option 4': '1',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'f9b6406ea79dc8ba701b05ab66230ea147199afa',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the financial condition of this selected property?',
    'Created date': '2020-11-29T04:44:16',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c14b6ebfbdc525498da99cc85bce4c8622ce8d1c',
    'Parent Text': '',
    'Text EN': 'Social & Community Relations',
    'Created date': '2020-11-29T04:44:16',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '060f17d9c15251bd24e56f5eb448baa140b859be',
    'Parent Text': 'How satisfied are you with your social and community relations?',
    'Text EN': 'Social and community relations',
    'Created date': '2020-11-29T04:44:13',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': 'Satisfaction',
    'multiple': 'false',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b739c267d633214b320d91b7fe9fb9e386cde56c.0',
    'Parent Text': 'What is the extent of involvement among people living within your property? (Select one for each of (a) to (f))',
    'Text EN': '(a) Take part in joint events with neighbourhood',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b739c267d633214b320d91b7fe9fb9e386cde56c.1',
    'Parent Text': 'What is the extent of involvement among people living within your property? (Select one for each of (a) to (f))',
    'Text EN': '(b) Help organize regular events/activities',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b739c267d633214b320d91b7fe9fb9e386cde56c.2',
    'Parent Text': 'What is the extent of involvement among people living within your property? (Select one for each of (a) to (f))',
    'Text EN': '(c) Help prepare newsletters',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b739c267d633214b320d91b7fe9fb9e386cde56c.3',
    'Parent Text': 'What is the extent of involvement among people living within your property? (Select one for each of (a) to (f))',
    'Text EN': '(d) Actively use common space within the building for events and activities',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b739c267d633214b320d91b7fe9fb9e386cde56c.4',
    'Parent Text': 'What is the extent of involvement among people living within your property? (Select one for each of (a) to (f))',
    'Text EN': '(e) Participate in social events and activities',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b739c267d633214b320d91b7fe9fb9e386cde56c.5',
    'Parent Text': 'What is the extent of involvement among people living within your property? (Select one for each of (a) to (f))',
    'Text EN': '(f) Are active in volunteer work here',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b244f714b4152eae7947eec4c216b2801863def8.0',
    'Parent Text': 'What is the extent of involvement of people living in your property and people in the surrounding community?  (Select one for each of (a) to (e))',
    'Text EN': '(a) Take part in joint events with neighbourhood',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b244f714b4152eae7947eec4c216b2801863def8.1',
    'Parent Text': 'What is the extent of involvement of people living in your property and people in the surrounding community?  (Select one for each of (a) to (e))',
    'Text EN': '(b) Common space used for joint activities',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b244f714b4152eae7947eec4c216b2801863def8.2',
    'Parent Text': 'What is the extent of involvement of people living in your property and people in the surrounding community?  (Select one for each of (a) to (e))',
    'Text EN': '(c) Share common services with other groups in the area',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b244f714b4152eae7947eec4c216b2801863def8.3',
    'Parent Text': 'What is the extent of involvement of people living in your property and people in the surrounding community?  (Select one for each of (a) to (e))',
    'Text EN': '(d) Are members of other groups in the area',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b244f714b4152eae7947eec4c216b2801863def8.4',
    'Parent Text': 'What is the extent of involvement of people living in your property and people in the surrounding community?  (Select one for each of (a) to (e))',
    'Text EN': '(e) Join in community initiatives to improve the area',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '793681dc92a36d6b4a7f7541db5040ce376fc7a4',
    'Parent Text': '',
    'Text EN': 'How would you describe the social diversity in your property?',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '090b7446418467db91eb3a75e9e5a54a635a6bda',
    'Parent Text': '',
    'Text EN': 'Is this similar to or different from the social diversity or mix in the surrounding area?',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e9db1424fc324ffd24432b7c388d15dd08eecc69',
    'Parent Text': '',
    'Text EN': 'Which of the following describes accessibility in your property?',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '1',
    'Option 2': '0',
    'Option 3': '1',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0eef6947ecf163d30a4cf84e123fe96467aaa1d8',
    'Parent Text': '',
    'Text EN': 'Has your organization considered the feasibility of improving this housing\'s accessibility?',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '1c12c883b1b10742eccf2baf9443e0bff89ef8e1',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the social and community relations of the people living in your housing and the surrounding area?',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': 'true',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '1',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0468a07f73eae98dc0d6fffe8d82f8a07da8b84d',
    'Parent Text': '',
    'Text EN': 'Do you have any comments about social and community relationships in your property?',
    'Created date': '2020-11-29T04:44:14',
    'Section': 'SECTION D:  PROPERTY MANAGEMENT, FINANCIAL MANAGEMENT AND SOCIAL RELATIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '2d2d90e65c73b14231c20fa51aaf9dedbcaa8031',
    'Parent Text': '',
    'Text EN': 'Are all four of the above aspects (governance, property & asset management, financial management, and  social/community relations) equally important to your organization?',
    'Created date': '2020-11-29T01:42:25',
    'Section': 'SECTION E: OVERALL ASSESSMENTS OF YOUR ORGANIZATION AS A WHOLE',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '54a5a9ac8cc46208711598a1f32e5b28724cc377.0',
    'Parent Text': 'Please indicate how important each of the four aspects is to your organization:  (Select one for each of (a) to (d))',
    'Text EN': 'Governance (Board & Employees)',
    'Created date': '2020-11-29T01:42:25',
    'Section': 'SECTION E: OVERALL ASSESSMENTS OF YOUR ORGANIZATION AS A WHOLE',
    'Scoring group': 'Governance',
    'modifier': 'Weighting',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '54a5a9ac8cc46208711598a1f32e5b28724cc377.1',
    'Parent Text': 'Please indicate how important each of the four aspects is to your organization:  (Select one for each of (a) to (d))',
    'Text EN': 'Property & asset management',
    'Created date': '2020-11-29T01:42:25',
    'Section': 'SECTION E: OVERALL ASSESSMENTS OF YOUR ORGANIZATION AS A WHOLE',
    'Scoring group': 'Property',
    'modifier': 'Weighting',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '54a5a9ac8cc46208711598a1f32e5b28724cc377.2',
    'Parent Text': 'Please indicate how important each of the four aspects is to your organization:  (Select one for each of (a) to (d))',
    'Text EN': 'Financial management',
    'Created date': '2020-11-29T01:42:25',
    'Section': 'SECTION E: OVERALL ASSESSMENTS OF YOUR ORGANIZATION AS A WHOLE',
    'Scoring group': 'Financial',
    'modifier': 'Weighting',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '54a5a9ac8cc46208711598a1f32e5b28724cc377.3',
    'Parent Text': 'Please indicate how important each of the four aspects is to your organization:  (Select one for each of (a) to (d))',
    'Text EN': 'Social/community relations',
    'Created date': '2020-11-29T01:42:25',
    'Section': 'SECTION E: OVERALL ASSESSMENTS OF YOUR ORGANIZATION AS A WHOLE',
    'Scoring group': 'Social',
    'modifier': 'Weighting',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'd0ed6b6ed9193fe9c1928895cf0e3741dc46cbfc.0',
    'Parent Text': 'Considering your organization as a whole, how satisfied are you with its ability to maintain, sustain, and meet future housing needs?  (Select one for each of (a) to (c))',
    'Text EN': 'Viability of your organization and its housing to maintain current service levels',
    'Created date': '2020-11-29T01:42:25',
    'Section': 'SECTION E: OVERALL ASSESSMENTS OF YOUR ORGANIZATION AS A WHOLE',
    'Scoring group': 'Viability',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'd0ed6b6ed9193fe9c1928895cf0e3741dc46cbfc.1',
    'Parent Text': 'Considering your organization as a whole, how satisfied are you with its ability to maintain, sustain, and meet future housing needs?  (Select one for each of (a) to (c))',
    'Text EN': 'Resilience of your organization to adapt to challenges and be sustainable',
    'Created date': '2020-11-29T01:42:25',
    'Section': 'SECTION E: OVERALL ASSESSMENTS OF YOUR ORGANIZATION AS A WHOLE',
    'Scoring group': 'Resilience',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'd0ed6b6ed9193fe9c1928895cf0e3741dc46cbfc.2',
    'Parent Text': 'Considering your organization as a whole, how satisfied are you with its ability to maintain, sustain, and meet future housing needs?  (Select one for each of (a) to (c))',
    'Text EN': 'Vitality of your organization for dynamic communities with strengths and energy to meet future needs',
    'Created date': '2020-11-29T01:42:25',
    'Section': 'SECTION E: OVERALL ASSESSMENTS OF YOUR ORGANIZATION AS A WHOLE',
    'Scoring group': 'Vitality',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'ff9a8de49191b405d3ec5b51e0ec7e0cac41f7e3.0',
    'Parent Text': 'How important are each of the following other factors to your organization?  (Select one for each \nof (a) to (d))',
    'Text EN': 'Minimizing operating costs due to utilities',
    'Created date': '2020-11-29T00:01:40',
    'Section': 'SECTION F:  OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'ff9a8de49191b405d3ec5b51e0ec7e0cac41f7e3.1',
    'Parent Text': 'How important are each of the following other factors to your organization?  (Select one for each \nof (a) to (d))',
    'Text EN': 'Minimizing the impacts of climate change on our residents (e.g. heat waves, harsher winters, etc.)',
    'Created date': '2020-11-29T00:01:40',
    'Section': 'SECTION F:  OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'ff9a8de49191b405d3ec5b51e0ec7e0cac41f7e3.2',
    'Parent Text': 'How important are each of the following other factors to your organization?  (Select one for each \nof (a) to (d))',
    'Text EN': 'Protecting the public health of residents and surrounding communities',
    'Created date': '2020-11-29T00:01:40',
    'Section': 'SECTION F:  OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'ff9a8de49191b405d3ec5b51e0ec7e0cac41f7e3.3',
    'Parent Text': 'How important are each of the following other factors to your organization?  (Select one for each \nof (a) to (d))',
    'Text EN': 'Creating more socially inclusive and accessible housing suited to local communities',
    'Created date': '2020-11-29T00:01:40',
    'Section': 'SECTION F:  OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c768233a856885ce4bcd1f227a27e4cfcf4689fd.0',
    'Parent Text': 'How difficult is it to obtain the following services for your organization?  (Select one for each of (a) to (e))',
    'Text EN': '(a) Financial services',
    'Created date': '2020-11-29T00:01:40',
    'Section': 'SECTION F:  OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c768233a856885ce4bcd1f227a27e4cfcf4689fd.1',
    'Parent Text': 'How difficult is it to obtain the following services for your organization?  (Select one for each of (a) to (e))',
    'Text EN': '(b) Assistance with development proposals',
    'Created date': '2020-11-29T00:01:40',
    'Section': 'SECTION F:  OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c768233a856885ce4bcd1f227a27e4cfcf4689fd.2',
    'Parent Text': 'How difficult is it to obtain the following services for your organization?  (Select one for each of (a) to (e))',
    'Text EN': '(c) Assistance with renovation proposals',
    'Created date': '2020-11-29T00:01:40',
    'Section': 'SECTION F:  OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c768233a856885ce4bcd1f227a27e4cfcf4689fd.3',
    'Parent Text': 'How difficult is it to obtain the following services for your organization?  (Select one for each of (a) to (e))',
    'Text EN': '(d) Architectural/building expertise',
    'Created date': '2020-11-29T00:01:40',
    'Section': 'SECTION F:  OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c768233a856885ce4bcd1f227a27e4cfcf4689fd.4',
    'Parent Text': 'How difficult is it to obtain the following services for your organization?  (Select one for each of (a) to (e))',
    'Text EN': '(e) Services for residents with disabilities',
    'Created date': '2020-11-29T00:01:40',
    'Section': 'SECTION F:  OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'd2d47279afdf16d8f007b47f053877c0b344905c',
    'Parent Text': '',
    'Text EN': 'Please describe how the Centre could assist your housing organization:',
    'Created date': '2020-11-29T00:01:40',
    'Section': 'SECTION F:  OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '061cedd64647b1e34693293279a6d6c78f85c6df',
    'Parent Text': '',
    'Text EN': 'Do you have any other comments about your organization and housing?',
    'Created date': '2020-11-29T00:01:40',
    'Section': 'SECTION F:  OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  }
]
