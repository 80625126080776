import { EgretLoadable } from '../../../egret'
import { authRoles } from '../../auth/authRoles'
import ExecutiveSummaries from './ExecutiveSummaries'
import Committees from './Committees'
import EditCommittee from './EditCommittee'

/**
 * Created by Michał Stawski on 02.08.2020.
 */
const OrganisationDetails = EgretLoadable({
  loader: () => import('./OrganisationDetails')
})

const JoinOrganization = EgretLoadable({
  loader: () => import('./JoinOrganization')
})

const ReviewApplications = EgretLoadable({
  loader: () => import('./ReviewApplications')
})

const Homepage = EgretLoadable({
  loader: () => import('./GrantsHome')
})

const ApplicationsList = EgretLoadable({
  loader: () => import('./ApplicationsList')
})

const Meetings = EgretLoadable({
  loader: () => import('./Meetings')
})

const AllocationCommitteeMeetings = EgretLoadable({
  loader: () => import('./AllocationCommitteeMeetings')
})

const AllocationCommitteeMeetingDetails = EgretLoadable({
  loader: () => import('./AllocationCommitteeMeetingDetails')
})

const ValidatedApplicationsList = EgretLoadable({
  loader: () => import('./ValidatedApplicationsList')
})

const ServiceInventory = EgretLoadable({
  loader: () => import('./ServiceInventory')
})

const EditService = EgretLoadable({
  loader: () => import('./EditService')
})

const ApplicationEdit = EgretLoadable({
  loader: () => import('./ApplicationEdit')
})

const ActionPlan = EgretLoadable({
  loader: () => import('./action-plan/ActionPlan')
})

const Reports = EgretLoadable({
  loader: () => import('./Reports')
})

const ExternalReviewSurvey = EgretLoadable({
  loader: () => import('./ExternalReviewSurvey')
})

const AcceptReviewRequest = EgretLoadable({
  loader: () => import('./ReviewerAccept')
})

const GranteeReport = EgretLoadable({
  loader: () => import('./GranteeReport')
})

const Calendar = EgretLoadable({
  loader: () => import('./calendar/UserCalendar')
})

const AboutMe = EgretLoadable({
  loader: () => import('./AboutMe')
})

const ApplyToExternalReview = EgretLoadable({
  loader: () => import('./ApplyToExternalReview')
})

const AssignedOpportunities = EgretLoadable({
  loader: () => import('./AssignedOpportunities')
})

const ProjectDetails = EgretLoadable({
  loader: () => import('./ProjectDetails')
})

const ApplicantInformation = EgretLoadable({
  loader: () => import('./ApplicantInformation')
})

const ActionTemplates = EgretLoadable({
  loader: () => import('./ActionTemplates')
})

const ActionTemplate = EgretLoadable({
  loader: () => import('./ActionTemplate')
})

const PrintView = EgretLoadable({
  loader: () => import('./PrintView')
})

const Organizations = EgretLoadable({
  loader: () => import('./UserOrganizations')
})

const ManageOrganizationMembers = EgretLoadable({
  loader: () => import('./ManageOrganizationMembers')
})

const Form = EgretLoadable({
  loader: () => import('../forms/Form')
})

const TechnicalAdvisories = EgretLoadable({
  loader: () => import('../grants/TechnicalAdvisories')
})

const grantsRoutes = [
  {
    path: '/grants/OrganisationDetails/:id?',
    component: OrganisationDetails
  },
  {
    path: '/grants/Committees/:initialTab?',
    component: Committees
  },
  {
    path: '/grants/Workgroups/:initialTab?',
    component: Committees
  },
  {
    path: '/grants/EditCommittee/:id',
    component: EditCommittee
  },
  {
    path: '/grants/EditWorkgroup/:id',
    component: EditCommittee
  },
  {
    path: '/grants/ApplicationsList',
    component: ApplicationsList,
    auth: authRoles.grantee
  },
  {
    path: '/grants/TechnicalAdvisories',
    component: TechnicalAdvisories,
    auth: authRoles.grantee
  },
  {
    path: '/grants/ActionTemplates',
    component: ActionTemplates,
    auth: authRoles.editor
  },
  {
    path: '/grants/ActionTemplate/:id',
    component: ActionTemplate,
    auth: authRoles.editor
  },
  {
    path: '/grants/GrantsHome',
    component: Homepage
  },
  {
    path: '/grants/ExecutiveSummaries/:committee',
    component: ExecutiveSummaries
  },
  {
    path: '/grants/Meetings/:committee',
    component: Meetings
  },
  {
    path: '/grants/AllocationCommitteeMeetings',
    component: AllocationCommitteeMeetings
  },
  {
    path: '/grants/AllocationCommitteeMeetingDetails/:id',
    component: AllocationCommitteeMeetingDetails
  },
  {
    path: '/grants/ValidatedApplicationsList_AC',
    component: ValidatedApplicationsList,
    auth: authRoles.allocationCommittee
  },
  {
    path: '/grants/ValidatedApplicationsList_Board',
    component: ValidatedApplicationsList,
    auth: authRoles.board
  },
  {
    path: '/grants/ValidatedApplicationsList_CMHC',
    component: ValidatedApplicationsList,
    auth: authRoles.cmhc
  },
  {
    path: '/grants/ServiceInventory',
    component: ServiceInventory,
    auth: authRoles.admin
  },
  {
    path: '/grants/EditService/:id',
    component: EditService,
    auth: authRoles.admin
  },
  {
    path: '/grants/ReviewApplications_Board/:id',
    component: ReviewApplications,
    auth: authRoles.board
  },
  {
    path: '/grants/ReviewApplications_AC/:id',
    component: ReviewApplications,
    auth: authRoles.allocationCommittee
  },
  {
    path: '/grants/ReviewApplications_SC/:id',
    component: ReviewApplications
    //auth: authRoles.tester
  },
  {
    path: '/grants/ReviewApplications_SC-CHGFB3/:id',
    component: ReviewApplications
    //auth: authRoles.tester
  },
  {
    path: '/grants/ReviewApplications_CMHC/:id',
    component: ReviewApplications,
    auth: authRoles.cmhc
  },
  {
    path: '/grants/Application/:id',
    component: ApplicationEdit,
    auth: authRoles.grantee
  },
  {
    path: '/grants/Form/:id',
    component: Form,
    auth: authRoles.grantee
  },
  {
    path: '/grants/Calendar',
    component: Calendar,
    auth: authRoles.grantee
  },
  {
    path: '/grants/ActionPlan',
    component: ActionPlan,
    auth: authRoles.grantee
  },
  {
    path: '/grants/Reports',
    component: Reports,
    auth: authRoles.grantee
  },
  {
    path: '/grants/AboutMe/:mode?',
    component: AboutMe,
    auth: authRoles.reviewer
  },
  {
    path: '/grants/ApplyToExternalReview',
    component: ApplyToExternalReview,
    auth: authRoles.grantee
  },
  {
    path: '/grants/AssignedOpportunities',
    component: AssignedOpportunities,
    auth: authRoles.reviewer
  },
  {
    path: '/grants/ExternalReviewSurvey/:id/:mode',
    component: ExternalReviewSurvey,
    auth: authRoles.reviewer
  },
  {
    path: '/grants/AcceptReview/:id',
    component: AcceptReviewRequest,
    auth: authRoles.reviewer
  },
  {
    path: '/grants/GranteeReport/:id/:mode?',
    component: GranteeReport,
    auth: authRoles.grantee
  },
  {
    path: '/grants/Application',
    component: ApplicationEdit,
    exact: true,
    auth: authRoles.grantee
  },
  {
    path: '/grants/print/:id',
    component: PrintView
  },
  {
    path: '/grants/Organizations',
    component: Organizations
  },
  {
    path: '/grants/ManageOrganizationMembers/:id?',
    component: ManageOrganizationMembers
  },
  {
    path: '/grants/ApplicantInformation',
    component: ApplicantInformation,
    auth: authRoles.user
  },
  {
    path: '/grants/JoinOrganization',
    component: JoinOrganization,
    auth: authRoles.grantee
  }
]

export default grantsRoutes
