import { Trans, t } from '@lingui/macro'
import { Grid, Icon, IconButton, Link, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import { getDocumentsByEntities } from 'app/services/sfAuth/sfData/sfFiles'
import {
  fetchApplicationConfigurations,
  fetchFormPages
} from 'app/services/sfAuth/sfData/sfForms'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import MUIDataTable from 'mui-datatables'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import ReactToPrint from 'react-to-print'
import { myI18n } from 'translation/I18nConnectedProvider'
import { isDateBeforeNow, muiDateCompare } from 'utils'
import Loading from '../../../egret/components/EgretLoadable/Loading'
import { dateFormat, datetimeFormat, defaultDocTitle } from '../../appSettings'
import {
  CBTI_RECORD_TYPE,
  GREEN_KICKSTARTER_RECORD_TYPE,
  STF_LP_RECORD_TYPE,
  STF_SIP_RECORD_TYPE,
  deleteOpportunity,
  getOpportunities,
  getOpportunityToStepper,
  getRecordTypes,
  oppToStepper,
  opportunitiesStagesInOrder,
  opportunityEditable,
  updateOpportunity
} from '../../services/sfAuth/sfData/sfOpportunity'
import Form, { checkFormValidity } from '../forms/Form'
import { constructFormAddressString } from '../forms/components/Common'
import { matchOpportunityWithConfiguration } from '../internal/ConfigureApplicationTypes'
import {
  OpportunityView,
  defaultConfigurationICCE
} from '../opportunity/OpportunityView'
import { HEADER } from '../opportunity/opportunityViewComponents'
import { muiTextLabels } from '../utilities/muiDataTablesTranslations'
import { ConfigurableEligibilityDialog } from './ConfigurableEligibilityDialog'
import ContactProgramManager from './ContactProgramManager'
import { granteeReportConfiguration } from './GranteeReport'
import { OrganisationToPrint } from './PrintView'

export const printStyles = theme => {
  return {
    root: {
      margin: theme.spacing(2),
      padding: theme.spacing(2)
      // width: 800
    },
    CellCentered: {
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center'
    },
    button: {
      margin: theme.spacing(1)
    },
    gridObjectives: {
      flexGrow: 1
    },
    dataList: {
      padding: 15,
      marginBottom: 15,
      marginLeft: 20,
      marginRight: 20,
      flexGrow: [[1], '!important']
    },
    dataContainer: {
      padding: 15,
      width: '100%'
    },
    paperContainerStretch: {
      width: '100%',
      padding: 15
    },
    gridContainerStretch: {
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 15
    },
    gridContainerStretchNoBreak: {
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 15
    },
    contactGridItem: {
      minHeight: [['90px'], '!important']
    },
    '@media print': {
      fill: {
        width: [['100%'], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191'
      },
      titleCard: {
        padding: '5px',
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        display: [['inline-block'], '!important'],
        justifyContent: [['center'], '!important'],
        marginLeft: [[25], '!important'],
        marginRight: [[15], '!important'],
        height: [['auto'], '!important'],
        width: [['750px'], '!important']
      },
      titleCardStretch: {
        padding: '5px',
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        display: [['inline-block'], '!important'],
        justifyContent: [['center'], '!important'],
        //marginLeft: [[25], '!important'],
        //marginRight: [[15], '!important'],
        height: [['auto'], '!important'],
        width: [['100%'], '!important']
      },
      titleGrid: {
        margin: [[0], '!important'],
        height: [['auto'], '!important'],
        width: [['750px'], '!important']
        // alignItems: [['center'], '!important'],
        // justify: [['flex-start'], '!important'],
        // flexDirection: [['row'], '!important'],
        // display: [['flex'], '!important']
      },
      nameGrid: {
        width: [['300px'], '!important']
      },
      gridObjectives: {
        paddingLeft: 20,
        paddingRight: 20,
        flexGrow: 1
      },
      programManagerCard: {
        padding: [[10], '!important'],
        // marginBottom: [[40], '!important'],
        // paddinTop: [[20], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        display: [['inline-block'], '!important'],
        height: [['150px'], '!important'],
        width: [['300px'], '!important']
      },
      imageLogo: {
        display: [['inline'], '!important']
      },
      gridContainer: {
        display: [['flex'], '!important'],
        width: [['100%'], '!important'],
        justifyContent: [['space-evenly'], '!important'],
        alignItems: [['center'], '!important']
      },
      gridContainerStretch: {
        paddingLeft: 0,
        paddingRight: 0,
        display: [['flex'], '!important'],
        width: [['100%'], '!important'],
        marginBottom: 15,
        justifyContent: [['center'], '!important']
      },
      gridContainerStretchNoBreak: {
        paddingLeft: 0,
        paddingRight: 0,
        display: [['flex'], '!important'],
        width: [['100%'], '!important'],
        marginBottom: 15,
        justifyContent: [['center'], '!important'],
        breakInside: [['auto'], '!important'],
        pageBreakInside: [['auto'], '!important']
      },
      gridChild: {
        display: [['block'], '!important'],
        alignSelf: [['center'], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto'
      },
      otherGrantsGrid: {
        borderRadius: 3,
        margin: 0,
        padding: 0,
        display: [['block'], '!important'],
        alignSelf: [['center'], '!important'],
        border: '2px solid',
        borderColor: '#919191',
        height: [['300px'], '!imortant'],
        width: [['368px'], '!important']
      },
      contactGridItem: {
        minHeight: [['95px'], '!important'],
        //width: [['350px'], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191'
      },
      gridChildHalfWidth: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        //flexGrow: 1,
        //width: '100%'
        width: [['575px'], '!important']
      },
      gridChildTable1: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto',
        minHeight: [['65px'], '!important'],
        width: [['600px'], '!important']
      },
      gridChildTable2: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto',
        minHeight: [['65px'], '!important'],
        width: [['150px'], '!important']
      },
      gridChildTable3: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto',
        minHeight: [['65px'], '!important'],
        width: [['350px'], '!important']
      },
      gridChildTable4: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto',
        minHeight: [['65px'], '!important'],
        width: [['950px'], '!important']
      },
      gridChildTable5: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        minHeight: [['65px'], '!important'],
        width: [['180px'], '!important']
      },
      paperContainer: {
        borderRadius: 3,
        paddingLeft: 15,
        paddingRight: 15,
        border: '2px solid',
        borderColor: '#919191'
      },
      description: {
        padding: 15,
        margin: 0,
        breakInside: [['auto'], '!important'],
        pageBreakInside: [['auto'], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        width: [['1150px'], '!important']
      },
      paperContainerStretch: {
        padding: 15,
        margin: 0,
        // margin: 0,
        //marginLeft: 20,
        //marginRight: 20,
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        width: [['1150px'], '!important']
      },
      dataList: {
        //padding: 15,
        //marginLeft: 20,
        //marginRight: 20,
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        breakInside: [['auto'], '!important'],
        pageBreakInside: [['auto'], '!important'],
        //flexGrow: 1,
        width: [['760px'], '!important']
      },
      dataContainer: {
        padding: 15,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
        borderRadius: 3,
        flexGrow: 1,
        breakInside: [['auto'], '!important'],
        pageBreakInside: [['auto'], '!important'],
        border: [['2px solid'], '!important'],
        borderColor: [['#919191'], '!important']
        //width: [['1150px'], '!important']
      },
      mainTitle: {
        fontSize: [[24], '!important'],
        marginLeft: [[15], '!important'],
        padding: [[10], '!important']
      },
      subtitle: {
        fontSize: 12,
        color: '#919191'
      },
      hideInPrint: {
        display: [['none'], '!important'],
        visibility: [['hidden', '!important']]
      }
    }
  }
}

const useStyles = makeStyles(theme => printStyles(theme))

export const OldFundsPrintViewDialog = ({
  opportunityToPrint,
  handleClose
}) => {
  const printRef = useRef()
  const classes = useStyles()
  const config = sfOauthConfig.isIcce
    ? defaultConfigurationICCE({
        classes,
        data: opportunityToPrint
      })
    : granteeReportConfiguration({
        classes,
        applicationList: true
      })
  config.fields.splice(
    5,
    0,
    ...OrganisationToPrint({
      classes
    }),
    {
      type: HEADER,
      style: { fontSize: 24 },
      text: item => (
        <span className={classes.mainTitle}>
          <Trans>Application Details</Trans>
        </span>
      )
    }
  )
  return (
    <Dialog open={true} maxWidth='md' fullWidth scroll='paper'>
      <DialogTitle>
        <Grid container direction='row' justify='space-between'>
          <ReactToPrint
            onAfterPrint={() => (document.title = defaultDocTitle)}
            onBeforePrint={() =>
              (document.title = opportunityToPrint.info.projectName)
            }
            trigger={() => (
              <Tooltip title={<Trans>Print</Trans>}>
                <span>
                  <IconButton
                    disabled={!opportunityToPrint.readyToPrint}
                    aria-label={<Trans>Print</Trans>}
                    className=''
                  >
                    <Icon>print</Icon>
                  </IconButton>
                </span>
              </Tooltip>
            )}
            content={() => printRef.current}
          />
          <IconButton onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {!opportunityToPrint.readyToPrint && (
          <div style={{ height: 500 }}>
            <Loading />
          </div>
        )}
        {opportunityToPrint.readyToPrint && (
          <div ref={printRef}>
            <OpportunityView
              opportunity={opportunityToPrint}
              committeeType='External Review Accept'
              configuration={config}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export const shouldShowReminderBanner = (data, recordType, cutoffDate) => {
  return (
    data.some(item => {
      return (
        (item.info.stageName === 'In Progress' ||
          item.info.stageName === 'More info required') &&
        item.info.recordType === recordType
      )
    }) &&
    momentTZ
      .tz(moment.utc(), momentTZ.tz.guess())
      .isBefore(momentTZ.tz(cutoffDate, 'Pacific/Pitcairn'))
  )
}

export const ReminderBanner = ({ title, rows }) => {
  return (
    <Alert severity='warning' style={{ marginTop: 10 }}>
      <AlertTitle>
        <Trans id={title} />
      </AlertTitle>
      {rows.map(item => {
        return (
          <div style={item.style}>
            <Trans id={item.id} />
          </div>
        )
      })}
    </Alert>
  )
}

const ApplicationsList = () => {
  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState(null)
  const [applicationToPrint, setApplicationToPrint] = React.useState(null)
  const [recordTypes, setRecordTypes] = React.useState(null)
  const [eligibilityOpen, setEligibilityOpen] = React.useState(false)
  const [appToDelete, setAppToDelete] = React.useState(null)
  const [opportunitiesWithContract, setOpportunitiesWithContract] = useState([])
  const [forms, setForms] = React.useState([])
  const [isOrganizationValid, setIsOrganizationValid] = React.useState(true)
  const [formsConfig, setFormsConfig] = React.useState(null)
  const [nearEndFunds, setNearEndFunds] = React.useState([])
  const history = useHistory()
  const configurations = useSelector(state => state.configuration)
  const applicationTypes = configurations.AVAILABLE_APPLICATIONS || []
  const organization = useSelector(state => state.organization)
  const user = useSelector(state => state.user)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  let userRole = 'No role'
  if (user.organizationMember) {
    userRole = user.organizationMember.TeamMemberRole
  }
  const rolePermissions = {
    manager: userRole === 'Manager',
    contributor: userRole === 'Contributor',
    partner: userRole === 'Operator',
    associate: userRole === 'Associate'
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [organization])

  const fetchData = () => {
    return Promise.all([
      getRecordTypes('Opportunity'),
      organization.id
        ? getOpportunities(
            {
              permissions: {
                TEAM_MEMBERS: true
              },
              searchParams: {
                AccountId: organization.id
              }
            },
            true
          )
        : Promise.resolve([]),
      fetchFormPages(),
      fetchApplicationConfigurations()
    ])
      .then(([recordTypes, list, forms, appConfigurations]) => {
        console.log('getRecordTypes and opportunities', recordTypes, list)
        console.log('got form templates', forms)
        const formsConfig = {}
        const formsMap = {}

        let upcomingFunds = {}
        appConfigurations.forEach(config => {
          const { reminderTime, recordType, endTime } = config
          if (
            reminderTime &&
            isDateBeforeNow(reminderTime) &&
            !upcomingFunds[recordType]
          ) {
            let formattedEndTime = endTime
              ? moment.utc(endTime).format(datetimeFormat)
              : null

            upcomingFunds[recordType] = {
              fundName: null,
              endTime: formattedEndTime,
              opportunities: []
            }
          }
        })

        let organizationForm
        forms.forEach(form => {
          const objects = form.config.objectsConnected || []
          formsConfig[form.id] = [...objects]
          formsMap[form.id] = form
          if (form.id === configurations.FORM_ORGANIZATION_DETAILS) {
            organizationForm = form
          }
        })
        setFormsConfig(formsConfig)
        setForms(forms)

        list = list
          .filter(opp => opp.UserRecordAccess.HasReadAccess)
          .map(item => {
            const stepperItem = oppToStepper(item)
            const fundId = stepperItem.info.recordTypeId
            const fundName = stepperItem.info.fundTypeName
            const oppName = stepperItem.info.name
            const stageName = stepperItem.info.stageName

            if (
              upcomingFunds.hasOwnProperty(fundId) &&
              stageName === 'In Progress'
            ) {
              upcomingFunds[fundId].fundName = fundName
              upcomingFunds[fundId].opportunities.push(oppName)
            }
            return stepperItem
          })
        setNearEndFunds(Object.entries(upcomingFunds))

        setRecordTypes(recordTypes)
        return getDocumentsByEntities(list.map(opp => opp.info.id)).then(
          files => {
            const applicationsWithContract = []
            files.forEach(file => {
              if (
                !applicationsWithContract.includes(file.LinkedEntityId) &&
                file.ContentVersion.TagCsv === 'unsigned_contract'
              ) {
                applicationsWithContract.push(file.LinkedEntityId)
              }
            })
            setOpportunitiesWithContract(applicationsWithContract)
            if (organizationForm && organization.id) {
              let defaultAddressString = []
              organizationForm.config.objectsConnected.forEach(obj => {
                let id
                if (obj.type === 'Account') {
                  id = organization.id
                } else if (obj.type === 'User') {
                  id = user.userId
                }
                defaultAddressString.push(obj.identId + '=' + id)
              })
              defaultAddressString = defaultAddressString.join(';')
              return checkFormValidity({
                formId: organizationForm.id,
                id: defaultAddressString
              }).then(isValid => {
                setIsOrganizationValid(isValid)
                setData(list)
                setLoading(false)
                enqueueSnackbar(<Trans>Loaded</Trans>, {
                  variant: 'info'
                })
              })
            } else {
              setData(list)
              setLoading(false)
              enqueueSnackbar(<Trans>Loaded</Trans>, {
                variant: 'info'
              })
            }
          }
        )
      })
      .catch(reject => console.error(reject))
  }

  const handleClose = () => {
    setAppToDelete(null)
    setEligibilityOpen(false)
  }

  const openDeleteDialog = id => {
    setAppToDelete(id)
  }

  const opportunityToTable = (item, index) => {
    const mail = item.info.pmContact?.split(';')[0]
    const config = matchOpportunityWithConfiguration({
      fundingStreamId: item.info.fundingStream,
      recordTypeId: item.info.fundType,
      configurations: configurations.AVAILABLE_APPLICATIONS
    })
    const formId = config?.form
    const printForm = config?.printForm
    let formConfig = formId ? formsConfig[formId] : null
    return {
      name: item.info.name,
      recordType: item.info.fundTypeName,
      stageName: item.info.stageName,
      createdDate: item.info.createdDate,
      pm: {
        isPm: Boolean(item.info.pmName && mail),
        assignedManager: item.info.assignedManager,
        pmName: item.info.pmName,
        pmMail: mail,
        pmPhone: item.info.pmContact?.split(';')[1]
      },
      actions: {
        formId,
        hasReadAccess: item.info.hasReadAccess,
        editAccess: item.info.hasEditAccess,
        printForm,
        hasContract: opportunitiesWithContract.includes(item.info.id),
        recordType: item.info.fundTypeName,
        formConfig,
        formVersion: item.info.formVersion,
        dataIndex: index
      },
      lastModifiedDate:
        item.info.lastModifiedByUserDate || item.info.lastModifiedDate,
      id: item.info.id
      // block print for new forms
    }
  }

  const onRemoveItem = id => {
    enqueueSnackbar(<Trans>Deleting</Trans>, {
      variant: 'info'
    })
    deleteOpportunity(id)
      .then(() => {
        fetchData().then(r => {
          enqueueSnackbar(<Trans>Deleted</Trans>, {
            variant: 'success'
          })
        })
      })
      .catch(error => {
        console.log(error)
        enqueueSnackbar(<Trans>Error while Deleting</Trans>, {
          variant: 'error'
        })
      })
    setAppToDelete(null)
  }

  if (loading) {
    return <Loading />
  }

  const tableData = data.map((item, index) => opportunityToTable(item, index))

  const config = granteeReportConfiguration({
    classes,
    applicationList: true
  })
  config.fields.splice(
    5,
    0,
    ...OrganisationToPrint({
      classes
    }),
    {
      type: HEADER,
      style: { fontSize: 24 },
      text: item => (
        <span className={classes.mainTitle}>
          <Trans>Application Details</Trans>
        </span>
      )
    }
  )

  const insufficientRole = Boolean(
    !rolePermissions.manager // && !rolePermissions.contributor
  )
  const invalidAccountInfo = Boolean(!isOrganizationValid && organization.id)
  const displayCBTIBanner = shouldShowReminderBanner(
    data,
    CBTI_RECORD_TYPE,
    '2023-01-31 11:59 PM'
  )
  const displayGKFBanner = shouldShowReminderBanner(
    data,
    GREEN_KICKSTARTER_RECORD_TYPE,
    '2023-06-16 11:59 PM'
  )

  return (
    <>
      {applicationToPrint &&
        (applicationToPrint.formPrintId ? (
          <Form
            inDialog={Boolean(
              applicationToPrint && applicationToPrint.formPrintId
            )}
            onDialogClose={e => {
              setApplicationToPrint(null)
            }}
            disableTitle
            formId={applicationToPrint.formPrintId}
            fetchString={constructFormAddressString({
              user,
              organization,
              opportunity: applicationToPrint.opportunityId,
              objectsConnected: formsConfig[applicationToPrint.formPrintId]
            })}
          />
        ) : (
          <OldFundsPrintViewDialog
            opportunityToPrint={applicationToPrint}
            handleClose={e => {
              setApplicationToPrint(null)
            }}
          />
        ))}
      <Paper className={classes.root}>
        <div>
          <div style={{ paddingLeft: 15, paddingTop: 20, paddingRight: 15 }}>
            <Button
              variant='contained'
              color='primary'
              type='button'
              disabled={
                !recordTypes ||
                !organization.id ||
                insufficientRole ||
                invalidAccountInfo
              }
              onClick={() => setEligibilityOpen(true)}
            >
              <Icon color='inherit'>add</Icon>
              <span data-tut='reactour__applicationList__new'>
                <Trans>New Application</Trans>
              </span>
            </Button>
            {insufficientRole && organization.id && (
              <Alert severity='warning' style={{ marginTop: 10 }}>
                <AlertTitle>
                  <Trans>
                    You are not authorized to create new applications for this
                    organization. Please contact your account manager.
                  </Trans>
                </AlertTitle>
                <Trans>You can manage your organizations</Trans>{' '}
                <Link href='/grants/Organizations'>
                  <Trans>here</Trans>
                </Link>
              </Alert>
            )}
            {invalidAccountInfo && !insufficientRole && (
              <Alert severity='warning' style={{ marginTop: 10 }}>
                <Trans>
                  You cannot create new application until you fill out all field
                  marked as required in the{' '}
                  <Link href='/grants/OrganisationDetails'>
                    <Trans>Organisation Details</Trans>
                  </Link>
                </Trans>
              </Alert>
            )}

            {!organization.id && (
              <Alert severity='warning' style={{ marginTop: 10 }}>
                <Trans>
                  Before you will be able to create a new application you have
                  to associate an organisation with your account
                </Trans>{' '}
                <Link href='/grants/Organizations'>
                  <Trans>You can connect to an organization here</Trans>
                </Link>
              </Alert>
            )}

            {!insufficientRole && displayCBTIBanner && (
              <ReminderBanner
                title={t`APPLICATIONS_LIST_CBTI_BANNER_HEADER`}
                rows={[
                  { id: t`APPLICATIONS_LIST_CBTI_BANNER_LINE1` },
                  {
                    id: t`APPLICATIONS_LIST_CBTI_BANNER_LINE2`,
                    style: { marginTop: 5, fontWeight: 'bold' }
                  }
                ]}
              />
            )}
            {!insufficientRole && displayGKFBanner && (
              <ReminderBanner
                title={t`APPLICATIONS_LIST_GKF_BANNER_HEADER`}
                rows={[{ id: t`APPLICATIONS_LIST_GKF_BANNER_LINE1` }]}
              />
            )}

            {!insufficientRole && nearEndFunds.length !== 0 && (
              <>
                {nearEndFunds.map(
                  ([key, obj]) =>
                    obj.opportunities.length > 0 && (
                      <Alert
                        severity='warning'
                        key={key}
                        style={{ marginTop: 10 }}
                      >
                        <AlertTitle>
                          <Trans>
                            APPLICATIONS_LIST_FUNDING_NEAR_END_ALERT{' '}
                            {obj.fundName}
                          </Trans>
                          {'. '}
                          {obj.endTime && (
                            <Trans>
                              APPLICATIONS_LIST_FUNDING_NEAR_END_ALERT_END_TIME{' '}
                              {moment.utc(obj.endTime).format(dateFormat)}
                            </Trans>
                          )}
                          :
                        </AlertTitle>
                        <div style={{ marginTop: 5 }}>
                          {obj.opportunities.map((opp, index) => (
                            <div key={index}>
                              <Trans>{opp}</Trans>
                            </div>
                          ))}
                        </div>
                      </Alert>
                    )
                )}
              </>
            )}
          </div>

          <div className='w-100 overflow-auto' style={{ padding: 20 }}>
            <MUIDataTable
              title={
                <Typography variant='h5'>
                  <b>
                    <Trans>Applications list</Trans>
                  </b>
                </Typography>
              }
              data={tableData}
              options={{
                textLabels: muiTextLabels(myI18n),
                //filter: false,
                selectableRows: 'none',
                print: false,
                download: false,
                viewColumns: false,
                sortOrder: {
                  direction: 'desc',
                  name: 'lastModifiedDate'
                }
              }}
              columns={[
                {
                  name: 'name',
                  label: myI18n._(t`Name`),
                  options: {
                    filter: false,
                    customHeadLabelRender: props => {
                      return (
                        <div style={{ marginLeft: 20 }}>
                          <Trans>Name</Trans>
                        </div>
                      )
                    },
                    customBodyRender: value => {
                      return <div style={{ marginLeft: 20 }}>{value}</div>
                    }
                  }
                },
                {
                  name: 'recordType',
                  label: myI18n._(t`Fund`),
                  options: {
                    filter: true,
                    customHeadLabelRender: props => {
                      return <Trans>Fund</Trans>
                    },
                    customBodyRender: value => {
                      return (
                        <div>
                          <Trans id={value} />
                        </div>
                      )
                    }
                  }
                },
                {
                  name: 'lastModifiedDate',
                  sortCompare: muiDateCompare('lastModifiedDate'),
                  label: myI18n._(t`Modification`),
                  options: {
                    filter: false,
                    customHeadLabelRender: props => {
                      return (
                        <div style={{ textAlign: 'center' }}>
                          <Trans>Modification</Trans>
                        </div>
                      )
                    },
                    customBodyRender: value => {
                      return <div>{moment.utc(value).format(dateFormat)}</div>
                    }
                  }
                },
                {
                  name: 'stageName',
                  label: myI18n._(t`Stage`),
                  options: {
                    filter: true,
                    sortCompare:
                      order =>
                      ({ data: stage1 }, { data: stage2 }) => {
                        const ret =
                          opportunitiesStagesInOrder.indexOf(stage1) -
                          opportunitiesStagesInOrder.indexOf(stage2)
                        return order === 'asc' ? ret : ret * -1
                      },
                    customHeadLabelRender: props => {
                      return <Trans>Stage</Trans>
                    },
                    customBodyRender: value => myI18n._(value)
                  }
                },
                {
                  name: 'createdDate',
                  sortCompare: muiDateCompare('createdDate'),
                  label: myI18n._(t`Created date`),
                  options: {
                    filter: false,
                    customHeadLabelRender: props => {
                      return <Trans>Created date</Trans>
                    },
                    customBodyRender: value => {
                      return <div>{moment.utc(value).format(dateFormat)}</div>
                    }
                  }
                },
                {
                  name: 'pm',
                  label: myI18n._(t`Program Manager`),
                  options: {
                    filter: false,
                    sort: false,
                    customHeadLabelRender: props => {
                      return <Trans>Program Manager</Trans>
                    },
                    customBodyRender: value => {
                      return (
                        <div>
                          <div>{value.pmName}</div>
                          <div>{value.pmMail}</div>
                          <div>{value.pmPhone}</div>
                        </div>
                      )
                    }
                  }
                },
                {
                  name: 'actions',
                  label: myI18n._(t`Actions`),
                  options: {
                    sort: false,
                    filter: false,
                    customHeadLabelRender: props => {
                      return (
                        <div style={{ textAlign: 'center', width: '100%' }}>
                          <Trans>Actions</Trans>
                        </div>
                      )
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      const {
                        formId,
                        printForm,
                        hasContract,
                        formConfig,
                        formVersion,
                        dataIndex,
                        hasReadAccess,
                        editAccess
                      } = value
                      const item = tableData[dataIndex]
                      const { pm, id, recordType } = item
                      const appToPrint = data[dataIndex]

                      let teamMember
                      appToPrint.teamMembers.some(member => {
                        if (member.UserId === user.userId) {
                          teamMember = member
                        }
                        return member.UserId === user.userId
                      })
                      const per = {
                        isTeamMember: Boolean(teamMember),
                        opportunityAccessLevel:
                          teamMember?.OpportunityAccessLevel || 'Edit',
                        teamMember,
                        ...rolePermissions
                      }
                      const addressString = constructFormAddressString({
                        objectsConnected: formConfig,
                        user,
                        organization,
                        opportunity: id
                      })
                      const goToForm = id => {
                        history.push('/elasticform/' + id + '/' + addressString)
                      }
                      const noFormICCE =
                        sfOauthConfig.isIcce && !formId && !formVersion
                      const readOnly = !editAccess
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <ContactProgramManager
                            recordType={recordType}
                            active={pm.isPm}
                            opportunityId={id}
                          />
                          {((sfOauthConfig.isInternal &&
                            pm.assignedManager === user.userId) ||
                            hasContract) && (
                            <Tooltip title={myI18n._(t`Go to contract`)}>
                              <IconButton
                                onClick={e => {
                                  history.push(`/internal/contract/${id}`)
                                }}
                              >
                                <Icon>receipt_long</Icon>
                              </IconButton>
                            </Tooltip>
                          )}
                          {!opportunityEditable(item) || readOnly ? (
                            <Tooltip title={myI18n._(t`View`)}>
                              <IconButton
                                disabled={
                                  Boolean(per.associate && !per.isTeamMember) ||
                                  noFormICCE
                                }
                                type='button'
                                onClick={() => {
                                  if (formVersion || formId) {
                                    goToForm(formVersion || formId)
                                  } else {
                                    history.push(`/grants/Application/${id}`)
                                  }
                                }}
                              >
                                <Icon color='inherit'>description</Icon>
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title={myI18n._(t`Edit`)}>
                              <IconButton
                                disabled={
                                  Boolean(
                                    !per.manager &&
                                      !per.contributor &&
                                      !per.partner &&
                                      !per.isTeamMember
                                  ) || noFormICCE
                                }
                                type='button'
                                onClick={() => {
                                  if (formVersion) {
                                    goToForm(formVersion)
                                  } else if (sfOauthConfig.isIcce) {
                                    goToForm(formId)
                                    updateOpportunity({
                                      Id: id,
                                      Form_Version__c: formId
                                    }).then(
                                      result => {},
                                      reject => {
                                        enqueueSnackbar(
                                          <Trans>
                                            Could not update opportunity form
                                            version!
                                          </Trans>,
                                          {
                                            variant: 'error'
                                          }
                                        )
                                      }
                                    )
                                  } else {
                                    history.push(
                                      `/grants/Application/${item.id}`
                                    )
                                  }
                                }}
                              >
                                <Icon color='inherit'>edit</Icon>
                              </IconButton>
                            </Tooltip>
                          )}
                          {(printForm ||
                            [
                              CBTI_RECORD_TYPE,
                              STF_LP_RECORD_TYPE,
                              STF_SIP_RECORD_TYPE
                            ].includes(recordType)) && (
                            <Tooltip title={<Trans>Print</Trans>}>
                              <span>
                                <IconButton
                                  variant='filled'
                                  onClick={() => {
                                    if (!printForm) {
                                      setApplicationToPrint(appToPrint)
                                      getOpportunityToStepper(item.id).then(
                                        app =>
                                          setApplicationToPrint({
                                            ...app,
                                            readyToPrint: true
                                          })
                                      )
                                    } else {
                                      setApplicationToPrint({
                                        opportunityId: item.id,
                                        formPrintId: printForm
                                      })
                                    }
                                  }}
                                >
                                  <Icon
                                    className='material-icons MuiIcon-root'
                                    variant='filled'
                                  >
                                    print
                                  </Icon>
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                          {(opportunityEditable(item) ||
                            item.stageName === 'InProgress') && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <Tooltip title={<Trans>Delete</Trans>}>
                                <span>
                                  <IconButton
                                    disabled={!per.manager}
                                    variant='filled'
                                    onClick={() => openDeleteDialog(item.id)}
                                  >
                                    <Icon
                                      style={{ color: per.manager && 'red' }}
                                      variant='filled'
                                    >
                                      delete
                                    </Icon>
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      )
                    }
                  }
                }
              ]}
            />
          </div>
        </div>
        <Dialog
          open={Boolean(appToDelete)}
          onClose={handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {'\n' +
              myI18n._(t`Are you sure you want to delete this application?`)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              This process cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='default'>
              {myI18n._(t`Cancel`)}
            </Button>
            <Button onClick={() => onRemoveItem(appToDelete)} color='default'>
              {myI18n._(t`Yes, delete it forever~`)}
            </Button>
          </DialogActions>
        </Dialog>
        <ConfigurableEligibilityDialog
          fetchData={fetchData}
          configurations={applicationTypes.filter(obj => !obj.obsolete)}
          forms={forms}
          open={eligibilityOpen}
          handleClose={() => setEligibilityOpen(false)}
        />
      </Paper>
    </>
  )
}

export default ApplicationsList
