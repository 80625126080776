import SFAuthService, { NO_USER } from '../SFAuthService'

/**
 * Created by Michał Stawski on 23.10.2020.
 */


export const deleteObjective = (id) => {
  let conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Objective__c').delete(id)
}
