import { Trans } from '@lingui/macro'
import { Icon, IconButton, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { defaultDocTitle } from 'app/appSettings'
import {
  FCHI2_RECORD_TYPE,
  getBaseFields,
  getOpportunity,
  oppToStepper,
  otherFields
} from 'app/services/sfAuth/sfData/sfOpportunity'
import Loading from 'egret/components/EgretLoadable/Loading'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { OpportunityView } from '../opportunity/OpportunityView'
import { printStyles } from './ApplicationsList'
import { granteeReportConfiguration } from './GranteeReport'
import {
  HEADER,
  GRID,
  DATA_LIST,
  PAGE_BREAK,
  DATA_ON_BOTTOM
} from '../opportunity/opportunityViewComponents'
import { fieldsFromList } from '../opportunity/OpportunityView'
import { useSelector } from 'react-redux'
import { constructFormAddressString } from '../forms/components/Common'
import { fetchFormPage } from 'app/services/sfAuth/sfData/sfForms'
import Form from '../forms/Form'
import { matchOpportunityWithConfiguration } from '../internal/ConfigureApplicationTypes'
import { CurrencyFormated } from '../common/Formats'

export const OrganisationToPrintPageBreakBefore = ({ classes }) => {
  const toRet = [
    {
      type: PAGE_BREAK
    }
  ]
  toRet.push(...OrganisationToPrint({ classes }))
  toRet.splice(toRet.length - 1, 1)
  return toRet
}

export const OrganisationToPrint = ({ classes }) => [
  {
    type: HEADER,
    style: { fontSize: 24, fonWeight: 'bold' },
    text: (
      <span className={classes.mainTitle}>
        <Trans>Organization details</Trans>
      </span>
    )
  },
  {
    type: GRID,
    justify: 'center',
    style: {
      breakInside: 'auto'
    },
    gridClass: classes.gridContainerStretch,
    paperClass: classes.dataContainer,
    fields: [
      ...fieldsFromList(
        [
          {
            field: 'info.accountName',
            subtitle: <Trans>Organizations Legal Name</Trans>
          },
          {
            field: 'account.geographicAreaOfOperation',
            subtitle: <Trans>Geographic area of operation</Trans>
          },
          {
            field: 'account.totalYearBudget',
            formatter: value => <CurrencyFormated value={value} />,
            subtitle: <Trans>Organization's total yearly budget</Trans>
          }
        ],
        classes.dataContainer,
        { padding: 10 }
      ),
      {
        type: DATA_LIST,
        paperClass: classes.dataList,
        bulletIcon: 'accessibility_new',
        subtitle: <Trans>Organization type</Trans>,
        data: opp =>
          opp.account.accountType ? opp.account.accountType.split(';') : []
      },
      {
        type: DATA_ON_BOTTOM,
        // style: { margin: 20 },
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        data: opp => opp.account.missionStatement,
        subtitle: (
          <span className={classes.subtitle}>
            <Trans>Mission Statement</Trans>
          </span>
        )
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        // style: { margin: 20 },
        paperClass: classes.dataContainer,
        data: opp => opp.account.organizationHistory,
        subtitle: (
          <span className={classes.subtitle}>
            <Trans>Organization History</Trans>
          </span>
        )
      }
    ]
  },
  {
    type: GRID,
    gridClass: classes.gridContainer,
    justify: 'center',
    fields: [
      {
        type: DATA_LIST,
        paperClass: classes.dataList,
        bulletIcon: 'accessibility_new',
        subtitle: <Trans>Indigenous Affiliation</Trans>,
        data: opp =>
          opp.account.indigenousAffiliation
            ? opp.account.indigenousAffiliation.split(';')
            : []
      },
      {
        type: DATA_LIST,
        paperClass: classes.dataList,
        bulletIcon: 'accessibility_new',
        subtitle: <Trans>Primary demographic target (if applicable)</Trans>,
        data: opp =>
          opp.account.focusDemographic
            ? opp.account.focusDemographic.split(';')
            : []
      }
    ]
  },
  {
    type: GRID,
    justify: 'center',
    gridClass: classes.gridContainerStretch,
    paperClass: classes.dataContainer,
    fields: [
      ...fieldsFromList(
        [
          {
            field: 'account.servicesProvided',
            subtitle: <Trans>Services offered</Trans>
          },
          {
            field: 'account.numberOfTenants',
            subtitle: <Trans>Number of tenants</Trans>
          },
          {
            field: 'account.housingUnits',
            subtitle: <Trans>Housing units</Trans>
          },
          {
            field: 'account.unitsSpecialNeeds',
            subtitle: <Trans>Units for people needing support services</Trans>
          },
          {
            field: 'account.numberOfEmployees',
            subtitle: <Trans>Number of employees</Trans>
          }
        ],
        classes.gridChild,
        { paddingLeft: 10 }
      )
    ]
  },
  {
    type: PAGE_BREAK
  }
]

// const OrganisationToPrintFull = ({ classes }) => {
//   return {
//     fields: [
//       {
//         type: HEADER,
//         style: { fontSize: 24, fonWeight: 'bold' },
//         text: app => (
//           <span className={classes.mainTitle}>{app.organisationsName}</span>
//         )
//       },
//       {
//         type: PAPER,
//         justify: 'center',
//         gridClass: classes.gridContainerStretch,
//         paperClass: classes.dataContainer,
//         fields: [
//           {
//             type: SUBHEADER,
//             text: <Trans>Organization Information</Trans>
//           },
//           {
//             type: DATA_ON_TOP,
//             data: app => app.otherName,
//             // disabled: app => !app.otherName,
//             subtitle: <Trans>Other used name</Trans>
//           },
//           {
//             type: DATA_ON_TOP,
//             data: app => app.acronym,
//             // disabled: app => !app.acronym,
//             subtitle: <Trans>Acronym</Trans>
//           },
//           {
//             type: DATA_ON_TOP,
//             data: app => app.phone,
//             // disabled: app => !app.phone,
//             subtitle: <Trans>Phone Number</Trans>
//           },
//           {
//             type: DATA_ON_TOP,
//             data: app => app.email,
//             // disabled: app => !app.email,
//             subtitle: <Trans>Email</Trans>
//           },
//           {
//             type: DATA_ON_TOP,
//             data: app => app.website,
//             // disabled: app => !app.website,
//             subtitle: <Trans>Website</Trans>
//           },
//           {
//             type: DATA_ON_TOP,
//             data: app => app.facebook,
//             // disabled: app => !app.facebook,
//             subtitle: <Trans>Facebook</Trans>
//           },
//           {
//             type: DATA_ON_TOP,
//             data: app => app.linkedIn,
//             // disabled: app => !app.linkedIn,
//             subtitle: <Trans>LinkedIn</Trans>
//           }
//         ]
//       },
//       {
//         type: PAPER,
//         justify: 'center',
//         gridClass: classes.gridContainerStretch,
//         paperClass: classes.dataContainer,
//         fields: [
//           {
//             type: SUBHEADER,
//             text: <Trans>Mailing details</Trans>
//           },
//           {
//             type: DATA_ON_TOP,
//             data: app => app.street,
//             // disabled: app => !app.street,
//             subtitle: <Trans>Street address</Trans>
//           },
//           {
//             type: DATA_ON_TOP,
//             data: app => app.City,
//             // disabled: app => !app.City,
//             subtitle: <Trans>City</Trans>
//           },
//           {
//             type: DATA_ON_TOP,
//             data: app => {
//               let toRet = null
//               provincesToPostal().forEach(ob => {
//                 if (ob.value === app.province) {
//                   toRet = ob.label
//                 }
//               })
//               return toRet
//             },
//             // disabled: app => !app.province,
//             subtitle: <Trans>Province / Territory</Trans>
//           },
//           {
//             type: DATA_ON_TOP,
//             data: app => app.postalCode,
//             // disabled: app => !app.postalCode,
//             subtitle: <Trans>Postal Code</Trans>
//           },
//           {
//             type: DATA_ON_TOP,
//             data: app => app.geographicArea,
//             // disabled: app => !app.geographicArea,
//             subtitle: <Trans>Geographic area of operation</Trans>
//           }
//         ]
//       },
//       {
//         type: DATA_LIST,
//         paperClass: classes.dataList,
//         bulletIcon: 'accessibility_new',
//         subtitle: <Trans>Organization type</Trans>,
//         // disabled: acc => acc.organisationType && acc.organisationType.length > 0,
//         data: acc => (acc.organisationType ? acc.organisationType : [])
//       },
//       {
//         type: DATA_LIST,
//         paperClass: classes.dataList,
//         bulletIcon: 'accessibility_new',
//         subtitle: <Trans>Indigenous Affiliation</Trans>,
//         // disabled: acc => acc.indigenousAffiliation && acc.indigenousAffiliation.length > 0,
//         data: acc =>
//           acc.indigenousAffiliation ? acc.indigenousAffiliation : []
//       },
//       {
//         type: DATA_LIST,
//         paperClass: classes.dataList,
//         bulletIcon: 'accessibility_new',
//         prevData: acc => {
//           return {
//             main: acc.isHousingProvider ? (
//               <Trans>
//                 Is your housing exclusively reserved for a specific group,
//                 demographic, or household type?
//               </Trans>
//             ) : (
//               <Trans>
//                 Does your organization primarily focus on a particular
//                 group/demographic?
//               </Trans>
//             ),
//             sub: <Trans id={acc.hasFocusDemographic} />
//           }
//         },
//         // disabled: acc => acc.focusDemographic && acc.focusDemographic.length > 0,
//         subtitle: <Trans>Focus Demographic</Trans>,
//         data: acc => (acc.focusDemographic ? acc.focusDemographic : [])
//       },
//       {
//         type: DATA_LIST,
//         paperClass: classes.dataList,
//         bulletIcon: 'accessibility_new',
//         prevData: acc => {
//           return {
//             main: (
//               <Trans>
//                 Does your organization have tailored processes, policies, or
//                 practices for certain groups/ demographics?
//               </Trans>
//             ),
//             sub: <Trans id={acc.targetDemographics} />
//           }
//         },
//         subtitle: acc => {
//           return acc.targetDemographics === 'Yes' ? (
//             <Trans>
//               Which groups/ demographics does your organization target through
//               tailored processes, policies, or practices?
//             </Trans>
//           ) : acc.isHousingProvider ? (
//             <Trans>
//               Which characteristics tend to describe the people who live in your
//               housing?
//             </Trans>
//           ) : (
//             <Trans>
//               Does your organization have particular experience working with any
//               groups/ demographics?
//             </Trans>
//           )
//         },
//         data: acc => (acc.typeOfPopulation ? acc.typeOfPopulation : [])
//       },
//       {
//         type: PAPER,
//         justify: 'center',
//         gridClass: classes.gridContainerStretch,
//         paperClass: classes.dataContainer,
//         fields: [
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => app.services,
//             subtitle: <Trans>What kind of services do you provide</Trans>
//           },
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => app.employees,
//             subtitle: <Trans>Number of employees</Trans>
//           },
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => app.numberOfMembers,
//             subtitle: <Trans>Number of members (individuals)</Trans>
//           },
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => app.buildings,
//             subtitle: <Trans>Number of buildings</Trans>
//           },
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => app.numberOfTenants,
//             subtitle: (
//               <Trans>Number of tenants living in your building (s)</Trans>
//             )
//           },
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => app.units,
//             subtitle: <Trans>Housing units managed</Trans>
//           },
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => app.unitsSpecialNeeds,
//             subtitle: (
//               <Trans>Number of units for people needing support services</Trans>
//             )
//           }
//         ]
//       },
//       {
//         type: PAPER,
//         justify: 'center',
//         gridClass: classes.gridContainerStretch,
//         paperClass: classes.dataContainer,
//         fields: [
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => app.missionStatement,
//             subtitle: <Trans>Mission Statement</Trans>
//           },
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => app.organisationHistory,
//             subtitle: <Trans>Organization History</Trans>
//           },
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => app.yearlyBudget,
//             subtitle: <Trans>Organization's total yearly budget</Trans>
//           },
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => app.mainFundingSource,
//             subtitle: <Trans>Main funding sources</Trans>
//           },
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => app.numberOfOrganizations,
//             subtitle: <Trans>Number of member organizations</Trans>
//           },
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             data: app => <Trans id={app.memberOfAsocNetw} />,
//             subtitle: (
//               <Trans>
//                 Is your organization a member of an association/ network?
//               </Trans>
//             )
//           },
//           {
//             type: DATA_ON_BOTTOM_NO_CARD,
//             disabled: app => app.memberOfAsocNetw !== 'Yes',
//             data: app => app.AsocNetwName,
//             subtitle: <Trans>Association/ network name</Trans>
//           }
//         ]
//       }
//     ]
//   }
// }

const styles = theme => printStyles(theme)

const PrintView = ({ match, classes }) => {
  const configurations = useSelector(state => state.configuration)
  const [printApp, setPrintApp] = useState()
  const [formId, setFormId] = useState()
  const [formConfig, setFormConfig] = useState()
  const printRef = useRef()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = match.params

  const config = granteeReportConfiguration({ classes, pmView: true })
  config.fields.splice(5, 0, ...OrganisationToPrint({ classes }), {
    type: HEADER,
    style: { fontSize: 24 },
    text: item => (
      <span className={classes.mainTitle}>
        <Trans>Application Details</Trans>
      </span>
    )
  })

  useEffect(() => {
    getOpportunity(id, {
      fields: getBaseFields().concat(otherFields({ withStar: false }))
    }).then(
      opportunity => {
        const application = oppToStepper(opportunity)
        const config = matchOpportunityWithConfiguration({
          fundingStreamId: opportunity.Funding_Stream__c,
          recordTypeId: opportunity.RecordTypeId,
          configurations: configurations.AVAILABLE_APPLICATIONS
        })
        const formId = config && config.internalPrintPreview

        console.log('got application for print view', application)
        if (formId) {
          setFormId(formId)
          fetchFormPage(formId).then(form => {
            if (form) {
              setFormConfig(form)
            }
            setPrintApp(application)
          })
        } else {
          if (application.info.fundTypeName !== FCHI2_RECORD_TYPE) {
            setPrintApp(application)
          }
        }
      },
      reject => {
        enqueueSnackbar(<Trans>Wrong id. Opportunity not found!</Trans>, {
          variant: 'error'
        })
      }
    )
  }, [])

  if (formConfig && printApp) {
    return (
      <Form
        fetchString={constructFormAddressString({
          objectsConnected: formConfig.objectsConnected,
          opportunity: id,
          organizationId: printApp.info.account
        })}
        formId={formId}
      />
    )
  }

  if (printApp) {
    return (
      <div style={{ padding: 20 }}>
        <Paper>
          <div style={{ padding: 10 }}>
            <ReactToPrint
              onAfterPrint={() => (document.title = defaultDocTitle)}
              onBeforePrint={() =>
                (document.title = printApp.projectDetails.projectName)
              }
              trigger={() => (
                <IconButton aria-label={<Trans>Print</Trans>} className=''>
                  <Icon>print</Icon>
                </IconButton>
              )}
              content={() => printRef.current}
            />
          </div>
          <div ref={printRef}>
            <OpportunityView
              opportunity={printApp}
              committeeType='External Review Accept'
              configuration={config}
            />
          </div>
        </Paper>
      </div>
    )
  }

  return <Loading />
}

export default withStyles(styles)(PrintView)
