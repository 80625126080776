import { Trans } from '@lingui/macro'
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import { Field, useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import {
  getMainConnected,
  getSFObjectFieldValue,
  insertValueToSFObject
} from '../Form'
import { validFieldTypesForTextField } from '../FormWizard'
import { requiredTrans } from '../formTranslations'
import { commitChangeToMultipleFields } from '../multiuser/grpcMultiuserEdit'
import { FormConnectToObject, NumberFormatDefault } from './Common'
import { FormErrorLabel } from './FormErrorLabel'
import MUTextField from './multiuser-input/MUTextField'

// export const FormWeightedPicklistPdf = ({
//   title,
//   id,
//   typeProps,
//   value,
//   values = {},
//   disabled,
//   langFR,
//   connectedFieldDetails,
//   connectedObject,
//   ...props
// }) => {
//   const { picklistType, dynamic, pdfBorderDisplay } = typeProps
//   const { valueToTrans, valueToIndex, valueToSFDetails } =
//     getWeightedPicklistRenderProps({ langFR, connectedFieldDetails, typeProps })

//   let style = { fontFamily: 'Roboto', fontSize: pdfDefaultFontSize }
//   if (pdfBorderDisplay) {
//     style = {
//       ...style,
//       padding: 8,
//       border: '1px solid rgba(0, 0, 0, 0.02)',
//       borderRadius: '5px'
//     }
//   }

//   if (picklistType === 'multiselect' || Array.isArray(value)) {
//     let data = value || []
//     if (connectedFieldDetails) {
//       data = data.sort((a, b) => {
//         const aDetails = valueToSFDetails[a]
//         const bDetails = valueToSFDetails[b]
//         const aIndex = aDetails?.index || 0
//         const bIndex = bDetails?.index || 0
//         return aIndex - bIndex
//       })
//     }
//     return (
//       <View style={style}>
//         <Text style={{ fontWeight: 'bold' }}>{title}</Text>
//         {Boolean(data.length === 0) && (
//           <Text style={{ fontWeight: 'bold' }}>
//             <Trans>This list is empty!</Trans>
//           </Text>
//         )}
//         {data.map((item, index) => {
//           const orgIndex = valueToIndex[item]
//           const translatedOption = dynamic
//             ? valueToSFDetails[item]?.label
//             : valueToTrans[item]
//           const connectedField = valueToSFDetails[item]?.connectedField

//           return (
//             <View
//               key={index}
//               style={{
//                 display: 'flex'
//                 //paddingTop: 3
//                 //paddingBottom: 3,
//                 //paddingLeft: 8
//               }}
//             >
//               <Text>
//                 {translatedOption || <Trans id={item} />}
//                 {/* {values.other[id] && values.other[id][orgIndex] && (
//                   <Text>{' (' + values.other[id][orgIndex] + ')'}</Text>
//                 )} */}
//                 {connectedField && (
//                   <>
//                     <Text>: </Text>
//                     <Text style={{ fontStyle: 'italic' }}>
//                       {connectedObject[connectedField.name]}
//                     </Text>
//                   </>
//                 )}
//               </Text>
//             </View>
//           )
//         })}
//       </View>
//     )
//   } else {
//     return (
//       <View style={style}>
//         <Text style={{ fontWeight: 'bold' }}>{title}</Text>
//         <Text style={{ whiteSpace: 'pre-line' }}>
//           {dynamic
//             ? valueToSFDetails[value]?.label
//             : valueToTrans[value] || <Trans id={value} />}
//           {values.other[id] && <Text>{' (' + values.other[id] + ')'}</Text>}
//         </Text>
//       </View>
//     )
//   }
// }

// export const FormWeightedPicklistPrint = ({
//   title,
//   id,
//   typeProps,
//   value,
//   disabled,
//   langFR,
//   connectedFieldDetails,
//   connectedObject,
//   ...props
// }) => {
//   const { values } = useFormikContext()
//   const { printIcon = 'none', picklistType, options = [], dynamic } = typeProps
//   const { valueToTrans, valueToIndex, valueToSFDetails } =
//     getWeightedPicklistRenderProps({ langFR, connectedFieldDetails, typeProps })

//   if (picklistType === 'multiselect' || Array.isArray(value)) {
//     let data = value || []
//     if (connectedFieldDetails) {
//       data = data.sort((a, b) => {
//         const aDetails = valueToSFDetails[a]
//         const bDetails = valueToSFDetails[b]
//         const aIndex = aDetails?.index || 0
//         const bIndex = bDetails?.index || 0
//         return aIndex - bIndex
//       })
//     }
//     return (
//       <div>
//         <Typography className='form-print-subtitle'>{title}</Typography>
//         <List>
//           {Boolean(data.length === 0) && (
//             <ListItem style={{ display: 'flex', fontWeight: 'bold' }}>
//               <Trans>This list is empty!</Trans>
//             </ListItem>
//           )}
//           {data.map((item, index) => {
//             const translatedOption = dynamic
//               ? valueToSFDetails[item]?.label
//               : valueToTrans[item]
//             const connectedField = valueToSFDetails[item]?.connectedField
//             return (
//               <ListItem
//                 key={index}
//                 style={{
//                   display: 'flex',
//                   paddingTop: 3,
//                   paddingBottom: 3,
//                   paddingLeft: 8
//                 }}
//               >
//                 {printIcon !== 'none' && (
//                   <ListItemIcon style={{ minWidth: 35 }}>
//                     <Icon>{printIcon}</Icon>
//                   </ListItemIcon>
//                 )}
//                 <Typography>
//                   {translatedOption || <Trans id={item} />}
//                   {/* {values.other[id] && values.other[id][orgIndex] && (
//                     <span>{' (' + values.other[id][orgIndex] + ')'}</span>
//                   )} */}
//                   {connectedField && (
//                     <>
//                       {': '}
//                       <i>{connectedObject[connectedField.name]}</i>
//                     </>
//                   )}
//                 </Typography>
//               </ListItem>
//             )
//           })}
//         </List>
//       </div>
//     )
//   } else {
//     return (
//       <div>
//         <Typography className='form-print-subtitle'>{title}</Typography>
//         <Typography style={{ whiteSpace: 'pre-line' }}>
//           {dynamic
//             ? valueToSFDetails[value]?.label
//             : valueToTrans[value] || <Trans id={value} />}
//           {values.other[id] && <span>{' (' + values.other[id] + ')'}</span>}
//         </Typography>
//       </div>
//     )
//   }
// }

// const getWeightedPicklistRenderProps = ({
//   connectedFieldDetails,
//   langFR,
//   typeProps
// }) => {
//   const { options = [] } = typeProps
//   const valueToTrans = {}
//   const valueToIndex = {}
//   const valueToSFDetails = {}
//   options.forEach((opt, index) => {
//     if (opt.apiValue) {
//       valueToTrans[opt.apiValue] = langFR ? opt.titleFR : opt.titleEN
//     }
//     valueToIndex[opt.apiValue] = index
//   })
//   if (connectedFieldDetails) {
//     connectedFieldDetails.picklistValues.forEach((option, index) => {
//       valueToSFDetails[String(option.value).replace(/\s/g, ' ')] = {
//         label: option.label,
//         connectedField: option.connectedFieldDetails,
//         index
//       }
//     })
//   }
//   options.forEach((opt, index) => {
//     if (valueToSFDetails[opt.apiValue]) {
//       valueToSFDetails[opt.apiValue].connectedField = opt.connectedFieldDetails
//     }
//   })
//   return { valueToTrans, valueToIndex, valueToSFDetails }
// }

// export const formWeightedPicklistValueToText = (
//   value = [],
//   question,
//   object = {}
// ) => {
//   const { connectedFieldDetails } = object
//   const { dynamic } = question.typeProps
//   const options =
//     dynamic && connectedFieldDetails
//       ? connectedFieldDetails.picklistValues
//           .filter(item => item.active)
//           .map((item, index) => {
//             return {
//               titleEN: myI18n._(t`${item.label}`),
//               titleFR: myI18n._(t`${item.label}`),
//               apiValue: item.value
//             }
//           })
//       : question.typeProps.options

//   const selectedEn = []
//   const selectedFr = []
//   if (Array.isArray(value)) {
//     value.forEach(v => {
//       options.some((option, index) => {
//         const found = v === 'option' + index || v === option.apiValue
//         if (found) {
//           selectedEn.push(<div key={index}>{option.titleEN}</div>)
//           selectedFr.push(<div key={index}>{option.titleFR}</div>)
//         }
//         return found
//       })
//     })
//     return { en: <div>{selectedEn}</div>, fr: <div>{selectedFr}</div> }
//   } else {
//     let singleOptionEN = ''
//     let singleOptionFR = ''
//     options.some((option, index) => {
//       let found
//       if (option.apiValue) {
//         found = value === option.apiValue
//       } else {
//         found = value === 'option' + index
//       }
//       if (found) {
//         singleOptionEN = option.titleEN
//         singleOptionFR = option.titleFR
//       }
//       return found
//     })
//     return { en: <div>{singleOptionEN}</div>, fr: <div>{singleOptionFR}</div> }
//   }
// }

export const FormWeightedPicklistError = (error, title) => {
  const toRet = {}
  Object.keys(error).forEach(key => {
    toRet[key] = {
      error: error[key],
      label:
        key === 'picklist' ? (
          title
        ) : (
          <Trans>FORM_WEIGHTED_PICKLIST_REASON_FOR_CHANGE</Trans>
        )
    }
  })
  return toRet
}

export const formWeightedPicklistValidation = item => {
  const { required } = item.typeProps
  return Yup.object({
    picklist: required
      ? Yup.array().nullable().compact().min(1, requiredTrans)
      : Yup.array(),
    overrideReason: Yup.string()
      .nullable()
      .when('manualScore', {
        is: v => v || v === 0,
        then: schema => schema.required(requiredTrans),
        otherwise: schema => schema
      })
  })
}

export const FormWeightedPicklist = ({
  id,
  useMultiuser,
  muBag,
  typeProps,
  value,
  disabled,
  langFR,
  connectedFieldDetails,
  ...props
}) => {
  const {
    options = [],
    picklistType,
    required,
    isConnected,
    maxScore
  } = typeProps

  let selectableOptions = options.map(item => {
    return {
      ...item,
      titleEN: String(item.titleEN).replace(/\u00a0/g, ' '),
      titleFR: String(item.titleFR).replace(/\u00a0/g, ' ')
    }
  })

  const renderTwoColumns =
    selectableOptions.length > 10 && picklistType !== 'expandable'
  const optionsMap = {}
  selectableOptions.forEach((option, index) => {
    const defaultOpt = 'option' + index
    const optionValue = isConnected ? option.apiValue || defaultOpt : defaultOpt
    selectableOptions[index].orgIndex = index
    optionsMap[optionValue] = option
  })

  if (renderTwoColumns) {
    const firstHalf = selectableOptions.slice(
      0,
      Math.ceil(selectableOptions.length / 2)
    )
    const secondHalf = selectableOptions.slice(
      Math.ceil(selectableOptions.length / 2)
    )
    const toSet = []
    firstHalf.forEach((item, index) => {
      toSet.push(item)
      if (secondHalf[index]) {
        toSet.push(secondHalf[index])
      }
    })
    selectableOptions = toSet
  }

  let totalWeights = 0
  options.forEach(opt => {
    totalWeights += Number(opt?.score || 1)
  })

  let percentageScore = 0
  options.forEach(opt => {
    if (value?.picklist?.includes(opt.apiValue)) {
      const weigth = Number(opt?.score || 1)
      const value = (weigth / totalWeights) * 100
      percentageScore += value
    }
  })

  return (
    <>
      <Field name={`${id}.picklist`}>
        {({ field, meta, form }) => {
          const value = field.value || []
          const { setFieldTouched, setFieldValue } = form
          const isError = Boolean(meta.touched && meta.error)

          const renderTwoColumns = options.length > 10
          return (
            <FormControl component='fieldset'>
              <FormGroup
                style={
                  renderTwoColumns
                    ? {
                        flexDirection: 'row',
                        marginLeft: 25,
                        justifyContent: 'start'
                      }
                    : {}
                }
              >
                {options.map(item => {
                  const index = item.orgIndex
                  const option = langFR ? item.titleFR : item.titleEN
                  const tooltip = langFR ? item.tooltipFR : item.tooltipEN
                  const defaultOpt = 'option' + index
                  const optionValue = isConnected
                    ? item.apiValue || defaultOpt
                    : defaultOpt
                  const checked = value.includes(optionValue)
                  return (
                    <Grid
                      container
                      direction='column'
                      key={index}
                      style={{ width: renderTwoColumns && '35%' }}
                    >
                      <FormControlLabel
                        style={{
                          marginBottom: 10
                        }}
                        disabled={disabled}
                        key={index}
                        value={optionValue}
                        onChange={e => {
                          const { value } = e.currentTarget
                          if (!meta.touched) {
                            setFieldTouched(`${id}.picklist`)
                          }
                          let newList = [...field.value]
                          if (newList.includes(value)) {
                            newList.splice(newList.indexOf(value), 1)
                          } else {
                            newList.push(value)
                          }
                          setFieldValue(`${id}.picklist`, newList)
                          if (useMultiuser) {
                            commitChangeToMultipleFields({
                              array: [[`${id}.picklist`, newList]],
                              ...muBag
                            })
                          }
                        }}
                        checked={checked}
                        control={
                          <Checkbox
                            style={{
                              paddingLeft: 9,
                              paddingRight: 9,
                              paddingTop: 0,
                              paddingBottom: 0
                            }}
                          />
                        }
                        label={
                          <div>
                            {option}
                            {tooltip && <TooltipLabelIcon tooltip={tooltip} />}
                          </div>
                        }
                      />
                    </Grid>
                  )
                })}
              </FormGroup>
              <FormErrorLabel
                error={isError}
                id={`${id}.picklist`}
                required={required}
              />
            </FormControl>
          )
        }}
      </Field>
      <Field name={`${id}`}>
        {({ field, meta, form }) => {
          const { setFieldValue } = form
          const { overrideReason, picklist, manualScore } = field.value

          return (
            <div>
              {(meta.touched || picklist?.length > 0) && (
                <div>
                  <Typography
                    variant='caption'
                    style={{
                      paddingLeft: 10,
                      marginTop: required ? 16 : 10
                    }}
                  >
                    <Trans>FORM_WEIGHTED_PICKLIST_PROPOSED_SCORE</Trans> :{' '}
                    {Math.round(
                      maxScore
                        ? maxScore * (percentageScore / 100)
                        : percentageScore
                    )}
                  </Typography>

                  <Grid container style={{ paddingTop: 8 }}>
                    <Grid item style={{ maxWidth: 300 }}>
                      <MUTextField
                        label={<Trans>FORM_WEIGHTED_PICKLIST_SCORE</Trans>}
                        useMultiuser={useMultiuser}
                        disabled={disabled}
                        maxInput={maxScore || 100}
                        muBag={muBag}
                        id={`${id}.manualScore`}
                        variant='outlined'
                        type='number'
                        percent={!maxScore}
                        placeholder={Math.round(
                          maxScore
                            ? maxScore * (percentageScore / 100)
                            : percentageScore
                        )}
                        changeCallback={e => {
                          if (!e.target.value && e.target.value !== 0) {
                            setFieldValue(`${id}.overrideReason`, null)
                          }
                        }}
                      />
                    </Grid>
                    {Boolean(manualScore || manualScore === 0) && (
                      <Grid xs style={{ paddingLeft: 16 }}>
                        <MUTextField
                          disabled={disabled}
                          label={
                            <Trans>
                              FORM_WEIGHTED_PICKLIST_REASON_FOR_CHANGE
                            </Trans>
                          }
                          limit={255}
                          useMultiuser={useMultiuser}
                          muBag={muBag}
                          id={`${id}.overrideReason`}
                          variant='outlined'
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>
              )}
            </div>
          )
        }}
      </Field>
    </>
  )
}

export const FormEditorWeightedPicklist = ({
  typeProps = {},
  elementType,
  id,
  french,
  editMode,
  depth,
  showPrintProps,
  showPdfProps,
  injectable,
  ...props
}) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()

  const {
    options = [],
    required,
    isConnected,
    pdfBorderDisplay,
    maxScore,
    connectedTo = []
  } = typeProps

  if (!editMode) {
    return (
      <FormWeightedPicklist
        preview
        typeProps={typeProps}
        id={id}
        {...props}
        langFR={french}
      />
    )
  }
  const { connectedField, connectedObject } = getMainConnected({
    typeProps,
    ...props
  })
  const { objects, objectsConnected } = values
  let fieldsToConnect = []
  const avaliableObjectsMap = {}
  objectsConnected.forEach(obj => {
    avaliableObjectsMap[obj.identId] = obj
  })
  connectedObject &&
    objects.some(obj => {
      if (!avaliableObjectsMap[connectedObject]) {
        return false
      }
      const bool = obj.name === avaliableObjectsMap[connectedObject].type
      if (bool) {
        fieldsToConnect = obj.fields
      }
      return bool
    })

  const fieldsMap = {}
  fieldsToConnect.forEach(field => {
    fieldsMap[field.name] = field
  })
  fieldsToConnect = fieldsToConnect.filter(item =>
    validFieldTypesForTextField.includes(item.type)
  )

  return (
    <Grid container style={{ padding: 10 }} direction='column'>
      <FormControlLabel
        style={{ marginBottom: 5 }}
        control={
          <Checkbox
            checked={Boolean(required)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.required = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Required</Trans>}
      />

      <div style={{ marginTop: 5 }}>
        <Grid container direction='row' alignItems='center'>
          <Typography>
            <Trans>Picklist options</Trans>
          </Typography>
          <IconButton
            onClick={() => {
              options.push({ titleEN: '', titleFR: '', apiValue: '' })
              typeProps.options = options
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...typeProps }
              })
            }}
          >
            <Icon>add</Icon>
          </IconButton>
          <Button
            disabled={!connectedField}
            style={{ marginLeft: 10 }}
            color='primary'
            variant='contained'
            onClick={() => {
              const fieldInfo = fieldsMap[connectedField.name]
              const toSet = fieldInfo?.picklistValues
                .map(sfObj => {
                  if (sfObj.active) {
                    const french = sfObj.labelLang !== 'en_US'
                    const toRet = {
                      titleEN: french ? '' : sfObj.label,
                      titleFR: french ? sfObj.label : '',
                      apiValue: sfObj.value
                    }

                    return toRet
                  } else {
                    return null
                  }
                })
                .filter(item => item)
              typeProps.options = toSet || []
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...typeProps }
              })
            }}
          >
            <Trans>Use values from object definition</Trans>
          </Button>
        </Grid>
        <Grid container direction='column'>
          {options.map((item, index) => {
            return (
              <div key={index}>
                <Grid
                  item
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  wrap='nowrap'
                >
                  {index + 1 + '. '}
                  <Grid item style={{ flex: 1, padding: 10 }}>
                    <Grid container direction='row'>
                      <Grid item xs={!isConnected ? 6 : 4}>
                        <TextField
                          variant='outlined'
                          label={<Trans>Option label - english</Trans>}
                          fullWidth
                          value={item.titleEN}
                          onChange={e => {
                            const toSet = { ...typeProps }
                            toSet.options[index].titleEN = e.currentTarget.value
                            dispatch({
                              type: 'FIELD',
                              injectable,
                              depth: depth.split('.'),
                              fieldName: 'typeProps',
                              fieldValue: toSet
                            })
                          }}
                        />
                      </Grid>
                      <Grid item xs={!isConnected ? 6 : 4}>
                        <TextField
                          variant='outlined'
                          label={<Trans>Option label - french</Trans>}
                          fullWidth
                          value={item.titleFR}
                          onChange={e => {
                            const toSet = { ...typeProps }
                            toSet.options[index].titleFR = e.currentTarget.value
                            dispatch({
                              type: 'FIELD',
                              injectable,
                              depth: depth.split('.'),
                              fieldName: 'typeProps',
                              fieldValue: toSet
                            })
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          variant='outlined'
                          label={<Trans>API value</Trans>}
                          fullWidth
                          value={item.apiValue}
                          onChange={e => {
                            const toSet = { ...typeProps }
                            toSet.options[index].apiValue =
                              e.currentTarget.value
                            dispatch({
                              type: 'FIELD',
                              injectable,
                              depth: depth.split('.'),
                              fieldName: 'typeProps',
                              fieldValue: toSet
                            })
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <TextField
                          variant='outlined'
                          label={<Trans>FORM_WEIGHTED_PICKLIST_WEIGHT</Trans>}
                          fullWidth
                          InputProps={{ inputComponent: NumberFormatDefault }}
                          value={item.score || 1}
                          onChange={e => {
                            const toSet = { ...typeProps }
                            toSet.options[index].score = e.target.value
                            dispatch({
                              type: 'FIELD',
                              injectable,
                              depth: depth.split('.'),
                              fieldName: 'typeProps',
                              fieldValue: toSet
                            })
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <div style={{ width: 50 }}>
                    <IconButton
                      disabled={index === 0}
                      onClick={() => {
                        const toSet = { ...typeProps }
                        const toMove = toSet.options[index]
                        const toReplace = toSet.options[index - 1]
                        toSet.options[index - 1] = toMove
                        toSet.options[index] = toReplace
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    >
                      <Icon>arrow_upward</Icon>
                    </IconButton>
                  </div>

                  <div style={{ width: 50 }}>
                    <IconButton
                      disabled={index === typeProps.options.length - 1}
                      onClick={() => {
                        const toSet = { ...typeProps }
                        const toMove = toSet.options[index]
                        const toReplace = toSet.options[index + 1]
                        toSet.options[index + 1] = toMove
                        toSet.options[index] = toReplace
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    >
                      <Icon>arrow_downward</Icon>
                    </IconButton>
                  </div>

                  <div style={{ width: 50 }}>
                    <IconButton
                      onClick={() => {
                        const toSet = { ...typeProps }
                        toSet.options.splice(index, 1)
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </div>
                </Grid>

                <Divider style={{ marginBottom: 5 }} />
              </div>
            )
          })}
        </Grid>
      </div>

      <TextField
        variant='outlined'
        label={<Trans>Max score (will be used instead of percentage)</Trans>}
        fullWidth
        value={maxScore}
        style={{ marginTop: 10 }}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.maxScore = e.currentTarget.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
      />

      <FormConnectToObject
        depth={depth}
        typeProps={typeProps}
        disableMultiple={true}
        fieldRender={({ fieldsToConnect, fieldsToConnectMap, index }) => {
          const item = connectedTo[index] || {}
          const { picklist, manualOverride, score, overrideReason } = item

          const picklistFields = fieldsToConnect.filter(
            item => item.type === 'multipicklist'
          )
          const percentFields = fieldsToConnect.filter(
            item => item.type === 'percent'
          )
          const boolFields = fieldsToConnect.filter(
            item => item.type === 'boolean'
          )

          const textFields = fieldsToConnect.filter(item =>
            validFieldTypesForTextField.includes(item.type)
          )
          return (
            <div>
              <Autocomplete
                freeSolo={false}
                value={picklist ? picklist.label : null}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].picklist = fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={picklistFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Picklist field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={score ? score.label : null}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].score = fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={percentFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Score field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={manualOverride ? manualOverride.label : null}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].manualOverride =
                    fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={boolFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Manual score field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={overrideReason ? overrideReason.label : null}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].overrideReason =
                    fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={textFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Reason for manual score field</Trans>}
                  />
                )}
              />
            </div>
          )
        }}
      />

      {showPdfProps && (
        <div style={{ marginTop: 10 }}>
          <Typography variant='h6'>
            <Trans>Pdf props</Trans>
          </Typography>
          <div style={{ padding: 5 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(pdfBorderDisplay)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.pdfBorderDisplay = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              }
              label={<Trans>Display with border</Trans>}
            />
          </div>
        </div>
      )}
    </Grid>
  )
}

export const FormWeightedPicklistDefaultValue = (obj, addInfo, item) => {
  const { maxScore } = item.typeProps
  const mainConnected = getMainConnected(item)
  if (!obj) {
    return {
      picklist: '',
      score: ''
    }
  }
  let picklistValue = []
  if (getSFObjectFieldValue(obj, mainConnected?.picklist)) {
    const rawValue = getSFObjectFieldValue(obj, mainConnected?.picklist) || ''
    picklistValue = rawValue.split(';')
  }
  const wasManualyOverriden = getSFObjectFieldValue(
    obj,
    mainConnected?.manualOverride
  )
  let manualScore = null
  const savedScore = getSFObjectFieldValue(obj, mainConnected?.score)
  if (savedScore && wasManualyOverriden) {
    manualScore = maxScore
      ? Math.round((savedScore / 100) * maxScore)
      : savedScore
  }

  return {
    picklist: picklistValue,
    overrideReason: getSFObjectFieldValue(obj, mainConnected?.overrideReason),
    manualScore
  }
}

export const formWeightedPicklistExtractSaveKey = ({
  saveMap,
  sfObject,
  subObjectsMap,
  connectedProps,
  item,
  value,
  connectedObjectId
}) => {
  const { picklist, score, manualOverride, overrideReason } = connectedProps
  const { maxScore } = item.typeProps
  const options = Array.from(item.typeProps.options)

  const totalWeights = options.reduce((acc, item) => {
    return acc + Number(item?.score || 1)
  }, 0)

  let percentageScore = options.reduce((acc, item) => {
    if (value?.picklist?.includes(item.apiValue)) {
      const weigth = Number(item?.score || 1)
      const value = (weigth / totalWeights) * 100
      acc += value
    }
    return acc
  }, 0)

  if (value.manualScore) {
    if (maxScore) {
      percentageScore = Math.round((value.manualScore / maxScore) * 100)
    } else {
      percentageScore = value.manualScore
    }
  }
  insertValueToSFObject({
    saveMap,
    sfObject,
    value: value.overrideReason,
    fieldProps: overrideReason,
    subObjectsMap,
    customConnectedField: true,
    connectedObjectId
  })

  insertValueToSFObject({
    saveMap,
    sfObject,
    value: Math.round(percentageScore),
    fieldProps: score,
    subObjectsMap,
    customConnectedField: true,
    connectedObjectId
  })

  insertValueToSFObject({
    saveMap,
    sfObject,
    value: Math.round(percentageScore),
    fieldProps: score,
    subObjectsMap,
    customConnectedField: true,
    connectedObjectId
  })

  insertValueToSFObject({
    saveMap,
    sfObject,
    value: value.picklist.join(';'),
    fieldProps: picklist,
    subObjectsMap,
    customConnectedField: true,
    connectedObjectId
  })

  insertValueToSFObject({
    saveMap,
    sfObject,
    value: Boolean(value.manualScore),
    fieldProps: manualOverride,
    subObjectsMap,
    customConnectedField: true,
    connectedObjectId
  })
}
