import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'

import { Field } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import NumberFormat from 'react-number-format'
import { questionRequiredTransWithStar } from '../forms/formTranslations'
import moment from 'moment'

Yup.addMethod(Yup.mixed, 'sumArray', function (message, value, rule) {
  return this.test('sumArray', message, function (data) {
    let sum = 0
    const isAnyFieldFilled = data.some(n => !isNaN(n))
    if (!isAnyFieldFilled) {
      return true
    }
    data.forEach(n => {
      sum += n
    })
    if (isNaN(sum)) {
      return false
    }
    return rule(sum, Number(value))
  })
})

Yup.addMethod(Yup.mixed, 'dateRangeRequired', function (message) {
  return this.test('dateRangeRequired', message, function (data) {
    if (!data || !data.start || !data.end) {
      return false
    }
    return moment.utc(data.start).isValid && moment.utc(data.end).isValid
  })
})

const numberValidator = ({ required } = {}) => {
  let arrayElementValidator = Yup.number()
    .transform(currentValue => currentValue || 0)
    .nullable()
  if (required) {
    arrayElementValidator = arrayElementValidator.required(
      questionRequiredTransWithStar
    )
  }
  return arrayElementValidator
}

export const questionValidationRules = {
  eq: {
    text: <Trans>Is equal to</Trans>,
    rule: n =>
      Yup.number()
        .transform(currentValue => currentValue || 0)
        .nullable()
        .required(questionRequiredTransWithStar)
        .oneOf([Number(n)], <Trans>*This number must be equal to {n}</Trans>)
  },
  lt: {
    text: <Trans>Is less than</Trans>,
    rule: n =>
      Yup.number()
        .transform(currentValue => currentValue || 0)
        .nullable()
        .required(questionRequiredTransWithStar)
        .lessThan(Number(n), <Trans>*This number must be less than {n}</Trans>)
  },
  lte: {
    text: <Trans>Is less than or equal to</Trans>,
    rule: n =>
      Yup.number()
        .transform(currentValue => currentValue || 0)
        .nullable()
        .required(questionRequiredTransWithStar)
        .lessThan(
          Number(n) + 1,
        <Trans>*This number must be less than or equal to {n}</Trans>
        )
  },
  gt: {
    text: <Trans>Is greater than</Trans>,
    rule: n =>
      Yup.number()
        .transform(currentValue => currentValue || 0)
        .nullable()
        .required(questionRequiredTransWithStar)
        .moreThan(
          Number(n),
        <Trans>*This number must be greater than {n}</Trans>
        )
  },
  gte: {
    text: <Trans>Is greater than or equal to</Trans>,
    rule: n =>
      Yup.number()
        .transform(currentValue => currentValue || 0)
        .nullable()
        .required(questionRequiredTransWithStar)
        .moreThan(
          Number(n) - 1,
        <Trans>*This number must be greater than or equal to {n}</Trans>
        )
  },
  sum_eq: {
    text: <Trans>Sum of all subquestions is equal to</Trans>,
    applyToAll: true,
    rule: (n, { required } = {}) =>
      Yup.array()
        .transform(v => (!v ? [] : v))
        .of(numberValidator({ required }))
        .sumArray(
          <Trans>*Sum of all fields must be equal to {n}</Trans>,
          n,
          (sum, v) => sum === v
        )
  },
  sum_lt: {
    text: <Trans>Sum of all subquestions is less than</Trans>,
    applyToAll: true,
    rule: (n, { required } = {}) =>
      Yup.array()
        .transform(v => (!v ? [] : v))
        .of(numberValidator({ required }))
        .sumArray(
          <Trans>*Sum of all fields must be less than {n}</Trans>,
          n,
          (sum, v) => sum < v
        )
  },
  sum_lte: {
    text: <Trans>Sum of all subquestions is less or equal than</Trans>,
    applyToAll: true,
    rule: (n, { required, id } = {}) =>
      Yup.array()
        .transform(v => (!v ? [] : v))
        .of(numberValidator({ required }))
        .sumArray(
          <Trans>*Sum of all fields must be less or equal than {n}</Trans>,
          n,
          (sum, v) => sum <= v
        )
  },
  sum_gt: {
    text: <Trans>Sum of all subquestions is greater than</Trans>,
    applyToAll: true,
    rule: (n, { required } = {}) =>
      Yup.array()
        .transform(v => (!v ? [] : v))
        .of(numberValidator({ required }))
        .sumArray(
          <Trans>*Sum of all fields must be greater than {n}</Trans>,
          n,
          (sum, v) => sum > v
        )
  },
  sum_gte: {
    text: <Trans>Sum of all subquestions is greater or equal than</Trans>,
    applyToAll: true,
    rule: (n, { required } = {}) =>
      Yup.array()
        .transform(v => (!v ? [] : v))
        .of(numberValidator({ required }))
        .sumArray(
          <Trans>*Sum of all fields must be greater or equal than {n}</Trans>,
          n,
          (sum, v) => sum >= v
        )
  }
}

function ValidationRulesField ({ setFieldValue, question, index }) {
  return (
    <>
      <div style={{ width: '100%' }}>
        <Button
          variant='contained'
          style={{
            backgroundColor: '#10708A',
            color: 'white',
            margin: 10
          }}
          onClick={e => {
            const toSet = { ...question }
            toSet.validationRules.push({
              type: null,
              parameter: null
            })
            setFieldValue(`created.${index}`, toSet)
          }}
        >
          <Grid container direction='row' alignItems='center'>
            <Trans>Add validation rule</Trans>
            <Icon style={{ marginLeft: 5 }}>add</Icon>
          </Grid>
        </Button>
      </div>
      <div style={{ flexGrow: 1 }}>
        <Grid
          container
          direction='column'
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          {question.validationRules.map((rule, ruleIndex) => {
            return (
              <Grid
                container
                direction='row'
                wrap='nowrap'
                key={ruleIndex}
                style={{ flexGrow: 1 }}
                justify='flex-start'
                alignItems='center'
              >
                <Grid item style={{ flexGrow: 1 }}>
                  <Grid container direction='row'>
                    <Typography>
                      {rule.type ? (
                        questionValidationRules[rule.type].text
                      ) : (
                        <Trans>[Select validation rule!]</Trans>
                      )}
                    </Typography>
                    <Typography style={{ marginLeft: 10 }}>
                      {rule.parameter || <Trans>[Select parameter!]</Trans>}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  style={{ width: '120px' }}
                  direction='row'
                  justify='flex-end'
                  alignItems='center'
                >
                  <EditRuleButton
                    question={question}
                    index={index}
                    ruleIndex={ruleIndex}
                    setFieldValue={setFieldValue}
                  />
                  <IconButton
                    onClick={() => {
                      const toSet = { ...question }
                      toSet.validationRules.splice(ruleIndex, 1)
                      setFieldValue(`created.${index}`, toSet)
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </>
  )
}

const EditRuleButton = ({ index, ruleIndex, setFieldValue, question }) => {
  const [dialogOpened, setDialogOpened] = React.useState(false)
  return (
    <>
      <IconButton
        onClick={() => {
          setDialogOpened(true)
        }}
      >
        <Icon>edit</Icon>
      </IconButton>
      <Dialog fullWidth open={dialogOpened} maxWidth='sm'>
        <DialogTitle>
          <Grid
            container
            direction='row'
            alignItems='center'
            justify='space-between'
          >
            <Typography variant='h6'>
              <Trans>Change validation rule</Trans>
            </Typography>
            <IconButton
              style={{ marginLeft: 15 }}
              onClick={() => {
                setDialogOpened(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction='row' alignItems='center'>
            <Grid item xs={8}>
              <Select
                value={question.validationRules[ruleIndex].type}
                fullWidth
                onChange={e => {
                  setFieldValue(
                    `created.${index}.validationRules.${ruleIndex}.type`,
                    e.target.value
                  )
                }}
              >
                {Object.keys(questionValidationRules).map((key, index) => {
                  if (
                    !question.isGroup &&
                    questionValidationRules[key].applyToAll
                  ) {
                    return null
                  }
                  return (
                    <MenuItem value={key} key={index}>
                      {questionValidationRules[key].text}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <Field
                type='text'
                component={TextField}
                value={question.validationRules[ruleIndex].parameter}
                onChange={e => {
                  setFieldValue(
                    `created.${index}.validationRules.${ruleIndex}.parameter`,
                    e.target.value
                  )
                }}
                style={{ margin: 10 }}
                name={`created.${index}.validationRules.${ruleIndex}.parameter`}
                variant='outlined'
                fullWidth
                InputProps={{ inputComponent: NumberFormatCustom }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
            <Button variant='contained' color='primary'>
              <Typography>
                <Trans>Save</Trans>
              </Typography>
            </Button>
          </DialogActions> */}
      </Dialog>
    </>
  )
}

function NumberFormatCustom (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}

export default React.memo(ValidationRulesField)
