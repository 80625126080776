export const technicalAdvisoriesSchemas = [
  {
    id: 'enhancing_capacity_1-3',
    label: 'Enhancing  Capacity (rounds 1-5) ',
    fields: [
      { name: 'Explicit_Relevance__c', weight: 1 },
      { name: 'In_House_Capacity__c', weight: 1 },
      { name: 'Realistic_Assessment__c', weight: 1 },
      { name: 'Indigenous_Knowledge__c', weight: 1 },
      { name: 'Cooperation_And_Inclusion__c', weight: 1 }
    ]
  },
  {
    id: 'developing_capacity_5',
    label: 'Developing capacity (rounds 1-5)',
    fields: [
      { name: 'Explicit_Relevance__c', weight: 1 },
      { name: 'In_House_Capacity__c', weight: 1 },
      { name: 'Realistic_Assessment__c', weight: 1 },
      { name: 'Indigenous_Knowledge__c', weight: 1 },
      { name: 'Community_Need__c', weight: 1 }
    ]
  },
  {
    id: 'developing_capacity_6',
    label: 'Developing capacity (round 6)',
    totalScore: 100,
    fields: [
      { name: 'Explicit_Relevance_New__c', weight: 1 },
      { name: 'In_House_Capacity_New__c', weight: 1 },
      { name: 'Realistic_Assessment_New__c', weight: 1 },
      { name: 'Community_Need_New__c', weight: 1 }
    ]
  },
  {
    id: 'enhancing_capacity_6',
    label: 'Enhancing Capacity (round 6)',
    totalScore: 100,
    fields: [
      { name: 'Explicit_Relevance_New__c', weight: 2 },
      { name: 'Regional_Relationships_And_Engagement__c', weight: 3 },
      { name: 'Role_Of_Indigenous_Knowledge__c', weight: 1.5 },
      { name: 'Intergenerational_Learning_And_Knowledge__c', weight: 1.5 },
      { name: 'Realistic_Assessment_New__c', weight: 2 }
    ]
  }
]

export const technicalAdvisoriesSchemasMap = Object.fromEntries(
  technicalAdvisoriesSchemas.map(obj => [obj.id, obj])
)

export const calculateFieldValueFromScoring = (value, fieldName, sheet) => {
  if (!value) {
    return undefined
  }
  if (!sheet.totalScore) {
    // Old scoring fields
    return value
  } else {
    let fieldWeight = 1
    let weightSum = 0
    sheet.fields.forEach(({ weight, name }) => {
      weightSum += weight
      if (name === fieldName) {
        fieldWeight = weight
      }
    })
    const maxScore = (fieldWeight / weightSum) * sheet.totalScore
    return maxScore * (value / 100)
  }
}
