import { Trans, t } from '@lingui/macro'
import { I18n, I18nProvider } from '@lingui/react'
import { myI18n } from 'translation/I18nConnectedProvider'
import {
  Button,
  ButtonGroup,
  Collapse,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'
import {
  describe,
  fetchOtherTemplates,
  getSiblings,
  getSubtemplates,
  getSurveyTemplateDetails,
  saveItems,
  surveyItemOptionsFields
} from 'app/services/sfAuth/sfData/sfSurvey'
import * as Yup from 'yup'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Formik } from 'formik'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import { withSnackbar } from 'notistack'
import React, { Component, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { catalogs } from 'translation/I18nConnectedProvider'
import { withFormikIncluded } from '../grants/BaseIncludedForm'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import SurveyCreationField from './SurveyCreationField'
import {
  nonApplicableSuffix,
  questionsToInitial,
  questionToInitial
} from './SurveyTab'
import { TwoSidedScreen } from '../../utils/TwoSidedScreen'
import ExternalQuestionDialog from './ExternalQuestionDialog'
import SurveyCreationFieldMenu from './SurveyCreationFieldMenu'
import { questionRequiredTrans } from '../forms/formTranslations'
import { questionValidationRules } from './ValidationRulesField'
import { countHelperText, NumericFormat } from '../page-layouts/FormElement'
import QuestionTitle from './QuestionTitle'
import { questionRequiredTransWithStar } from '../forms/formTranslations'
import { dateRangeRequiredTrans } from '../forms/formTranslations'
import { getScoringCategoriesConfiguration } from 'app/services/sfAuth/sfData/sfScoreCard'
import { FormikCheckboxField } from 'formik-material-fields'
import FormikCheckboxGroupField from 'formik-material-fields/lib/FormikCheckboxGroupField/FormikCheckboxGroupField'
import { questionTypes, QuestionWidget } from '../surveys/QuestionWidget'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import { TextFieldWithFormik } from '../forms/components/Common'

const styles = {
  textBlock: {
    marginLeft: 30,
    marginTop: 20,
    marginRight: 30,
    textAlign: 'left'
  },
  paper: {
    width: '100%',
    paddingBottom: 10
  },
  sectionHeader: {
    fontSize: '48px',
    textAlign: 'center',
    padding: 20
  },
  question: {
    padding: 10,
    margin: 8
  }
}

const newSurveyElement = {
  type: questionTypes.SELECT_ONE.id,
  id: '',
  questionFR: '',
  questionEN: '',
  requireDetails: false,
  options: [],
  joins: [],
  conditions: [],
  groupQuestions: [],
  conditionsToCheck: [],
  externalConditions: [],
  validationRules: []
}

const initialValues = {
  created: [],
  autosave: 0,
  // deleteIDs: [],
  toDelete: [],
  toUpdate: [],
  surveyTitleEN: '',
  surveyTitleFR: ''
}

class CreateSurvey extends Component {
  constructor (props) {
    super(props)
    const templateId = props.match.params.id
    this.state.templateId = templateId
    this.state.isSubtemplate = props.match.params.templateType === 'sub'
    this.saveSurvey = this.saveSurvey.bind(this)
    this.fetchData(templateId, props)
  }

  state = {
    extendedFR: false,
    extendedEN: false,
    isSubmitting: false,
    autoPreview: false,
    rebuildSchema: 0,
    conditionalObjects: {
      opportunity: {
        display: 'Opportunity',
        fields: {
          objectives: {
            display: 'Objectives',
            subfields: []
          }
        }
      }
    }
  }

  saveSurvey (values) {
    const creatingSnackbar = this.props.enqueueSnackbar(null, {
      variant: 'info',
      persist: true,
      content: key => ProgressSnackbar(<Trans>Saving survey</Trans>)
    })
    this.setState({ isSubmitting: true })
    return saveItems(values, this.state.templateId).then(result => {
      return this.fetchData(
        this.state.templateId,
        this.props,
        creatingSnackbar
      ).then(() => {
        this.setState({ isSubmitting: false })
      })
    })
  }

  isSubquestion (dataObj) {
    return (
      dataObj.itemOptions.parentQuestionIndex ||
      dataObj.itemOptions.parentQuestionIndex === 0 ||
      dataObj.itemOptions.parentQuestionId
    )
  }

  fetchData (templateId, props, snackbarKey = null) {
    const detailsPromises = []
    let otherTemplatesPromise = null
    if (this.state.isSubtemplate) {
      otherTemplatesPromise = getSiblings
    } else {
      otherTemplatesPromise = getSubtemplates
    }
    return Promise.all([
      otherTemplatesPromise(templateId),
      describe('Case'),
      getScoringCategoriesConfiguration(),
      !sfOauthConfig.isIcce && describe('Objective__c')
    ])
      .then(([result, caseDescribe, configuration, objectivesDescribe]) => {
        let caseTypes = []
        if (!configuration) {
          props.enqueueSnackbar(
            <Trans>
              There is no configuration object with scoring categories
            </Trans>,
            { variant: 'error' }
          )
        } else {
          this.setState({
            scoringCategories: JSON.parse(configuration.Value__c) || []
          })
        }
        if (objectivesDescribe) {
          objectivesDescribe.fields.some(field => {
            if (field.name === 'Objective__c') {
              this.setState({
                conditionalObjects: {
                  opportunity: {
                    display: 'Opportunity',
                    fields: {
                      objectives: {
                        display: 'Objectives',
                        subfields: field.picklistValues.map(obj => ({
                          name: obj.label,
                          value: obj.value
                        }))
                      }
                    }
                  }
                }
              })
              return true
            }
            return false
          })
        }
        caseDescribe.fields.some(field => {
          if (field.name === 'Type') {
            caseTypes = field.picklistValues.filter(obj => obj.active)
            return true
          }
          return false
        })
        const otherTemplatesIDs = []
        result.templates.forEach(dataObj => {
          detailsPromises.push(getSurveyTemplateDetails(dataObj.id))
          otherTemplatesIDs.push(dataObj.id)
        })
        const mainTemplatePromise = getSurveyTemplateDetails(templateId)
        console.log('TEMPLATE ID', templateId)
        const getOtherTemplates = fetchOtherTemplates([
          templateId,
          result.parentId,
          ...otherTemplatesIDs
        ])
        return Promise.all([
          mainTemplatePromise,
          getOtherTemplates,
          ...detailsPromises
        ]).then(results => {
          const splitResult = results.splice(0, 2)
          const mainTemplate = splitResult[0]
          console.log('GOT MAIN DETAILS', mainTemplate)
          const setCreated = []
          let newValues = { ...props.values }
          newValues.caseTypes = caseTypes
          const otherTemplates = splitResult[1]
          newValues.otherTemplates = []
          for (const key in otherTemplates) {
            if (Object.hasOwnProperty.call(otherTemplates, key)) {
              const obj = otherTemplates[key]
              const renderENG = props.user.language === 'en_US'
              let name = renderENG ? obj.en : obj.fr
              if (!name) {
                name = obj.template.Name || ''
              }
              newValues.otherTemplates.push({
                name: name,
                id: obj.template.Id
              })
              if (obj.childTemplates.length > 0) {
                obj.childTemplates.forEach(sub => {
                  const subName = renderENG ? sub.en : sub.fr
                  newValues.otherTemplates.push({
                    name: '[' + name + '] ' + subName,
                    id: sub.template.Id
                  })
                })
              }
            }
          }
          newValues.mainTemplate = mainTemplate.info.mainTemplate
          newValues.surveyName = mainTemplate.info.surveyName
          newValues = {
            ...newValues,
            ...mainTemplate.templateOptions,
            skipSection: Array.isArray(mainTemplate.templateOptions.skipSection)
              ? mainTemplate.templateOptions.skipSection
              : []
          }
          const data = mainTemplate.questionsDetails
          if (mainTemplate.titleDetails) {
            newValues.surveyTitleEN = mainTemplate.titleDetails.en
            newValues.surveyTitleEN_ext = mainTemplate.titleDetails.extendedEN
            newValues.surveyTitleEN_tooltip =
              mainTemplate.titleDetails.tooltipEN
            newValues.surveyTitleFR = mainTemplate.titleDetails.fr
            newValues.surveyTitleFR_ext = mainTemplate.titleDetails.extendedFR
            newValues.surveyTitleFR_tooltip =
              mainTemplate.titleDetails.tooltipFR
          }
          newValues.otherSections = []
          results.sort((a, b) => {
            return (
              new Date(a.info.templateCreatedDate) -
              new Date(b.info.templateCreatedDate)
            )
          })
          results.forEach(template => {
            console.log('SUB', template)
            const sectionObj = {}
            sectionObj.id = template.info.templateId
            const groupQuestions = {}
            template.questionsDetails.forEach((dataObj, index) => {
              if (!dataObj.itemOptions) {
                return
              }
              const options = dataObj.itemOptions
              const parentId =
                options.parentQuestionId || options.parentQuestionIndex
              if (this.isSubquestion(dataObj)) {
                let textFR = dataObj.itemTranslation.fr
                let textEn = dataObj.itemTranslation.en
                if (groupQuestions[parentId]) {
                  groupQuestions[parentId].push({
                    fr: textFR,
                    en: textEn,
                    id: options.createdId,
                    index: options.index
                  })
                } else {
                  groupQuestions[parentId] = [
                    {
                      fr: textFR,
                      en: textEn,
                      index: dataObj.itemOptions.index,
                      id: dataObj.itemOptions.createdId
                    }
                  ]
                }
              }
            })
            if (template.titleDetails[0]) {
              template.titleDetails[0].Survey_Texts__r.records.forEach(
                translation => {
                  if (
                    translation.Language__c === 'en-ca' &&
                    props.user.language === 'en_US'
                  ) {
                    sectionObj.name = translation.Text__c
                  } else if (
                    translation.Language__c === 'fr-ca' &&
                    props.user.language === 'fr'
                  ) {
                    sectionObj.name = translation.Text__c
                  }
                }
              )
            } else {
              sectionObj.name = template.info.surveyName
            }
            sectionObj.questions = []
            template.questionsDetails.forEach(questionData => {
              const question = {}
              const crypto = require('crypto')
              const assignId = crypto.randomBytes(20).toString('hex')
              if (!questionData.itemOptions) {
                questionData.itemOptions = {
                  optionsWithRequiredDetails: [],
                  conditions: [],
                  createdId: assignId,
                  conditionsToCheck: [],
                  externalConditions: []
                }
              }
              if (this.isSubquestion(questionData)) {
                return
              }
              if (questionData.itemOptions.createdId) {
                question.id = questionData.itemOptions.createdId
              } else {
                question.id = assignId
              }
              if (questionData.itemOptions.isGroup) {
                question.isGroup = true
                question.groupQuestions = []
                const toMatch = question.id || questionData.order
                const subQuestions = groupQuestions[toMatch]
                if (subQuestions) {
                  subQuestions.sort((a, b) => {
                    return a.index - b.index
                  })
                  subQuestions.forEach(sub => {
                    question.groupQuestions.push({
                      en: sub.en,
                      fr: sub.fr,
                      id: sub.id
                    })
                  })
                }
              } else {
                question.isGroup = false
              }

              questionData.item.Survey_Joins_Template_and_Question__r.records.forEach(
                join => {
                  if (join.Survey_Template__c === templateId) {
                    question.joinId = join.Id
                  }
                }
              )
              question.order = questionData.order
              question.textEn = questionData.itemTranslation.en
              question.textFR = questionData.itemTranslation.fr
              sectionObj.questions.push(question)
            })
            newValues.otherSections.push(sectionObj)
          })
          if (data.length === 0) {
            console.log('No questions!')
            newValues.titleData = mainTemplate.titleDetails
            newValues.templateId = templateId
            newValues.toDelete = []
            newValues.toUpdate = []
            newValues.created = setCreated
            this.loaded = true
            console.log('SET', newValues)
            props.setValues(newValues)
            if (snackbarKey) {
              props.closeSnackbar(snackbarKey)
              props.enqueueSnackbar(<Trans>Survey saved!</Trans>, {
                variant: 'info'
              })
            }
            return
          }
          data.sort((a, b) => {
            return a.order - b.order
          })
          const groupQuestions = {}
          data.forEach((dataObj, index) => {
            if (!dataObj.itemOptions) {
              return
            }
            if (this.isSubquestion(dataObj)) {
              const toPush = {
                fr: dataObj.itemTranslation.fr,
                en: dataObj.itemTranslation.en,
                sfId: dataObj.item.Id,
                itemTranslation: dataObj.itemTranslation,
                joinId: dataObj.joinId,
                joins:
                  dataObj.item.Survey_Joins_Template_and_Question__r.records,
                id: dataObj.itemOptions.createdId,
                index: dataObj.itemOptions.index
              }
              let parentId =
                dataObj.itemOptions.parentQuestionId ||
                dataObj.itemOptions.parentQuestionIndex
              if (groupQuestions[parentId]) {
                groupQuestions[parentId].push(toPush)
              } else {
                {
                  groupQuestions[parentId] = [toPush]
                }
              }
            }
          })
          data.forEach(dataObj => {
            const savedItem = {}
            if (!dataObj.itemOptions) {
              dataObj.itemOptions = {
                optionsWithRequiredDetails: [],
                conditions: [],
                conditionsToCheck: [],
                externalConditions: [],
                isTooltip: false
              }
            }
            if (this.isSubquestion(dataObj)) {
              return
            }
            savedItem.groupQuestions = []
            if (dataObj.itemOptions.isGroup) {
              savedItem.isGroup = true
              const subQuestions =
                groupQuestions[dataObj.itemOptions.createdId] ||
                groupQuestions[dataObj.order]
              if (subQuestions) {
                subQuestions.sort((a, b) => {
                  return a.index - b.index
                })
                subQuestions.forEach(sub => {
                  savedItem.groupQuestions.push({
                    en: sub.en,
                    fr: sub.fr,
                    id: sub.id,
                    sfId: sub.sfId,
                    parentId: dataObj.itemOptions.createdId,
                    itemTranslation: sub.itemTranslation,
                    joinId: sub.joinId,
                    joins: sub.joins
                  })
                })
              }
            } else {
              savedItem.isGroup = false
            }
            savedItem.joins =
              dataObj.item.Survey_Joins_Template_and_Question__r.records
            savedItem.conditions = dataObj.itemOptions.conditions || []
            savedItem.externalConditions =
              dataObj.itemOptions.externalConditions || []
            savedItem.conditionsToCheck =
              dataObj.itemOptions.conditionsToCheck || []
            savedItem.options = dataObj.itemDetails.sort(
              (a, b) => a.order - b.order
            )
            savedItem.itemTranslation = dataObj.itemTranslation
            savedItem.joinId = dataObj.joinId
            savedItem.sfId = dataObj.item.Id
            surveyItemOptionsFields.forEach(key => {
              if (dataObj.itemOptions.hasOwnProperty(key)) {
                savedItem[key] = dataObj.itemOptions[key]
              }
            })
            savedItem.validationRules =
              dataObj.itemOptions.validationRules || []
            const optionsWithRequiredDetails = {}
            dataObj.itemOptions.optionsWithRequiredDetails.forEach(
              detailObj => {
                if (detailObj) {
                  const type = detailObj.type ? detailObj.type : 'text'
                  const index =
                    detailObj.index || detailObj.index === 0
                      ? detailObj.index
                      : detailObj
                  optionsWithRequiredDetails[index] = type
                }
              }
            )
            savedItem.options.forEach(option => {
              option.requireDetails = optionsWithRequiredDetails[option.order]
            })
            savedItem.questionFR = dataObj.itemTranslation.fr
            savedItem.questionEN = dataObj.itemTranslation.en
            savedItem.tooltipFR = dataObj.itemTranslation.tooltipFR
            savedItem.tooltipEN = dataObj.itemTranslation.tooltipEN
            const crypto = require('crypto')
            const assignId = crypto.randomBytes(20).toString('hex')
            if (dataObj.itemOptions.createdId) {
              savedItem.id = dataObj.itemOptions.createdId
            } else {
              savedItem.id = assignId
            }
            savedItem.type = dataObj.item.Type__c
            setCreated.push(savedItem)
          })

          newValues.templateId = templateId
          //newValues.deleteIDs = result.deleteData
          newValues.titleData = mainTemplate.titleDetails
          newValues.created = setCreated
          newValues.toDelete = []
          newValues.toUpdate = []
          this.loaded = true
          props.setValues(newValues)
          // sessionStorage.setItem(templateId, JSON.stringify(newValues))
          if (snackbarKey) {
            props.closeSnackbar(snackbarKey)
            props.enqueueSnackbar(<Trans>Survey saved!</Trans>, {
              variant: 'info'
            })
          }
        })
      })
      .catch(error => {
        console.error('error getting template', error)
        props.enqueueSnackbar(<Trans>Survey template loading error</Trans>, {
          variant: 'error'
        })
      })
  }

  render () {
    const { values, setFieldValue, user } = this.props
    const {
      isSubmitting,
      isSubtemplate,
      autoPreview,
      conditionalObjects,
      scoringCategories = []
    } = this.state

    console.log('values', values)
    return this.loaded ? (
      <TwoSidedScreen
        left={
          <Paper style={styles.paper}>
            <h1 style={{ textAlign: 'center', paddingTop: 20 }}>
              <Trans>Create Survey</Trans>
            </h1>
            <div>
              <Grid
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Typography style={{ fontSize: '16px' }}>
                  <Trans>Add new survey element</Trans>
                </Typography>
                <IconButton
                  onClick={() => {
                    const toSet = [...values.created]
                    let newElement = { ...newSurveyElement }
                    const crypto = require('crypto')
                    const id = crypto.randomBytes(20).toString('hex')
                    newElement.id = id
                    toSet.push(newElement)
                    this.props.setFieldValue('created', toSet)
                  }}
                >
                  <Icon>add_box</Icon>
                </IconButton>
              </Grid>
              <div style={{ padding: 10 }}>
                <Button
                  fullWidth
                  variant='contained'
                  disabled={isSubmitting}
                  onClick={() => {
                    this.saveSurvey(values)
                  }}
                >
                  Save
                </Button>
              </div>
            </div>

            <div style={{ margin: 20 }}>
              <Grid container direction='row' alignItems='center'>
                <Grid style={{ flex: 1 }}>
                  <FormikTextField
                    name='surveyTitleEN'
                    variant='outlined'
                    label={<Trans>Survey Template Title - English</Trans>}
                    fullWidth
                  />
                </Grid>
                <IconButton
                  onClick={() => {
                    // console.log(this.state)
                    this.setState({ extendedEN: !this.state.extendedEN })
                  }}
                >
                  <Icon>
                    {this.state.extendedEN ? 'expand_less' : 'expand_more'}
                  </Icon>
                </IconButton>
              </Grid>
            </div>
            <div style={{ margin: 20 }}>
              <Collapse in={this.state.extendedEN}>
                <FormikTextField
                  name='surveyTitleEN_ext'
                  variant='outlined'
                  multiline
                  rows={4}
                  label={<Trans>Extended description - English</Trans>}
                  fullWidth
                />
                <div style={{ paddingTop: 20 }}>
                  <FormikTextField
                    name='surveyTitleEN_tooltip'
                    variant='outlined'
                    multiline
                    rows={4}
                    label={<Trans>Survey tooltip - English</Trans>}
                    fullWidth
                  />
                </div>
              </Collapse>
            </div>

            <div style={{ margin: 20 }}>
              <Grid container direction='row' alignItems='center'>
                <Grid style={{ flex: 1 }}>
                  <FormikTextField
                    name='surveyTitleFR'
                    variant='outlined'
                    label={<Trans>Survey Template Title - French</Trans>}
                    fullWidth
                  />
                </Grid>

                <IconButton
                  onClick={() => {
                    // console.log(this.state)
                    this.setState({ extendedFR: !this.state.extendedFR })
                  }}
                >
                  <Icon>
                    {this.state.extendedFR ? 'expand_less' : 'expand_more'}
                  </Icon>
                </IconButton>
              </Grid>
            </div>

            <div style={{ margin: 20 }}>
              <Collapse in={this.state.extendedFR}>
                <TextFieldWithFormik
                  name='surveyTitleFR_ext'
                  variant='outlined'
                  multiline
                  rows={4}
                  label={<Trans>Extended description - French</Trans>}
                  fullWidth
                />
                <div style={{ paddingTop: 20 }}>
                  <TextFieldWithFormik
                    name='surveyTitleFR_tooltip'
                    variant='outlined'
                    multiline
                    rows={4}
                    label={<Trans>Survey tooltip - French</Trans>}
                    fullWidth
                  />
                </div>
              </Collapse>
            </div>
            <div style={{ margin: 20 }}>
              <TextFieldWithFormik
                name='surveyName'
                variant='outlined'
                label={<Trans>Survey Name</Trans>}
                inputProps={{
                  maxLength: 80
                }}
                helperText={countHelperText(values.surveyName, 80)}
                fullWidth
              />
            </div>
            {!isSubtemplate && (
              <div style={{ margin: 20 }}>
                <TextFieldWithFormik
                  InputProps={{ inputComponent: NumericFormat }}
                  name='autosave'
                  variant='outlined'
                  label={<Trans>Autosave time (in minutes)</Trans>}
                  fullWidth
                />
              </div>
            )}
            {scoringCategories.length > 0 && (
              <div style={{ margin: 20 }}>
                <FormikCheckboxGroupField
                  label={<Trans>Categories that can be skipped</Trans>}
                  name='skipSection'
                  multiple
                  options={scoringCategories.map((obj, index) => ({
                    value: obj.value,
                    label: user.language === 'en_US' ? obj.en : obj.fr
                  }))}
                />
              </div>
            )}
            <ExternalQuestionDialog
              questionIndex={this.state.externalDialogIndex}
              dialogOpened={
                Boolean(this.state.externalDialogIndex) ||
                this.state.externalDialogIndex === 0
              }
              setDialogOpened={() => {
                this.setState({
                  externalDialogIndex: null,
                  externalDialogClone: null
                })
              }}
              newQuestion={this.state.externalDialogClone}
              values={values}
              saveSurvey={this.saveSurvey}
              userLanguage={this.props.user.language}
              setValues={this.props.setValues}
              setFieldValue={this.props.setFieldValue}
            />
            <SurveyCreationFieldMenu
              index={this.state.menuIndex}
              setFieldValue={this.props.setFieldValue}
              values={values}
              setValues={this.props.setValues}
              anchorEl={this.state.anchorEl}
              setExternalDialogOpened={(index, clone) => {
                this.setState({
                  externalDialogIndex: index,
                  externalDialogClone: clone
                })
              }}
              handleClose={() => {
                this.setState({
                  anchorEl: null,
                  menuIndex: null
                })
              }}
            />
            {values.created?.map((item, key) => {
              return (
                <SurveyCreationField
                  conditionalObjects={conditionalObjects}
                  setValues={this.props.setValues}
                  setAnchorEl={event => {
                    this.setState({
                      anchorEl: event.currentTarget,
                      menuIndex: key
                    })
                  }}
                  shouldSchemaRebuildCallback={() =>
                    this.setState({
                      rebuildSchema: this.state.rebuildSchema + 1
                    })
                  }
                  setFieldValue={this.props.setFieldValue}
                  saveSurvey={this.saveSurvey}
                  user={this.props.user}
                  key={key}
                  index={key}
                  values={values}
                />
              )
            })}
          </Paper>
        }
        right={
          <Paper style={styles.paper} square>
            <Grid
              container
              justify='center'
              alignItems='center'
              style={{ marginTop: 10 }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={autoPreview}
                    onChange={event => {
                      this.setState({ autoPreview: event.target.checked })
                    }}
                    name='autoPreview'
                    color='primary'
                  />
                }
                label={
                  <h5 style={{ margin: 0 }}>
                    <Trans>Auto Preview</Trans>
                  </h5>
                }
              />
            </Grid>

            <div>
              {values.created && autoPreview ? (
                <DisplayedSurveyWithSchema
                  rebuildSchema={this.state.rebuildSchema}
                  values={values}
                  setFieldValue={this.props.setFieldValue}
                />
              ) : (
                <div style={{ padding: 15 }}>
                  <Trans>
                    Auto preview has been disabled, finish Your edits on the
                    left and then turn it on with the switch.
                  </Trans>
                </div>
              )}
            </div>
          </Paper>
        }
      />
    ) : (
      <Loading />
    )
  }
}

const DisplayedSurveyWithSchema = props => {
  const { values, rebuildSchema } = props
  const [validationSchema, setValidationSchema] = React.useState()

  useEffect(() => {
    const yupObj = {}
    values.created.forEach(obj => {
      if (!obj.isRequired) {
        return
      }
      if (obj.type === questionTypes.SELECT_MULTIPLE.id) {
        yupObj[obj.id] = Yup.array()
          .compact()
          .nullable()
          .min(1, questionRequiredTransWithStar)
          .required(questionRequiredTransWithStar)
      } else if (obj.type === questionTypes.INPUT_DATE_RANGE.id) {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array().of(
            Yup.mixed()
              .required(dateRangeRequiredTrans)
              .dateRangeRequired(dateRangeRequiredTrans)
          )
        } else {
          yupObj[obj.id] = Yup.mixed()
            .required(dateRangeRequiredTrans)
            .dateRangeRequired(dateRangeRequiredTrans)
        }
      } else if (
        obj.type === questionTypes.INPUT_TEXT.id ||
        obj.type === questionTypes.INPUT_TEXTAREA.id
      ) {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array().of(
            Yup.string().required(questionRequiredTransWithStar)
          )
        } else {
          yupObj[obj.id] = Yup.string().required(questionRequiredTransWithStar)
        }
      } else if (
        obj.type === questionTypes.INPUT_NUMBER.id ||
        obj.type === questionTypes.SLIDER.id
      ) {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array().of(
            Yup.number()
              .transform(v => v || null)
              .nullable()
              .required(questionRequiredTransWithStar)
          )
        } else {
          yupObj[obj.id] = Yup.number()
            .transform(v => v || null)
            .nullable()
            .required(questionRequiredTransWithStar)
        }
      } else if (obj.type === questionTypes.INPUT_DATE.id) {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array()
            .nullable()
            .of(Yup.mixed().required(questionRequiredTransWithStar))
        } else {
          yupObj[obj.id] = Yup.mixed().required(questionRequiredTransWithStar)
        }
      } else {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array()
            .required(questionRequiredTransWithStar)
            .of(Yup.string().required(questionRequiredTransWithStar))
        } else {
          yupObj[obj.id] = Yup.mixed().required(questionRequiredTransWithStar)
        }
      }
    })
    values.created.forEach(question => {
      if (question.validationRules) {
        question.validationRules.forEach(rule => {
          if (!rule.type || !rule.parameter) {
            return
          }
          if (yupObj[question.id]) {
            if (question.isGroup) {
              if (questionValidationRules[rule.type].applyToAll) {
                yupObj[question.id] = yupObj[question.id].concat(
                  questionValidationRules[rule.type].rule(rule.parameter)
                )
              } else {
                yupObj[question.id] = yupObj[question.id].concat(
                  Yup.array()
                    .transform(v => (!v ? [] : v))
                    .of(questionValidationRules[rule.type].rule(rule.parameter))
                )
              }
            } else {
              yupObj[question.id] = yupObj[question.id].concat(
                questionValidationRules[rule.type].rule(rule.parameter)
              )
            }
          } else {
            if (question.isGroup) {
              if (questionValidationRules[rule.type].applyToAll) {
                yupObj[question.id] = questionValidationRules[rule.type].rule(
                  rule.parameter
                )
              } else {
                yupObj[question.id] = Yup.array()
                  .transform(v => (!v ? [] : v))
                  .of(questionValidationRules[rule.type].rule(rule.parameter))
              }
            } else {
              yupObj[question.id] = questionValidationRules[rule.type].rule(
                rule.parameter
              )
            }
          }
        })
      }
      if (
        question.maxCharacters &&
        [questionTypes.INPUT_TEXT.id, questionTypes.INPUT_TEXTAREA.id].includes(
          question.type
        )
      ) {
        const rule = Yup.string()
          .ensure()
          .max(question.maxCharacters, ({ max }) =>
            myI18n._(t`This fields length cannot exceed ${max} characters!`)
          )
        if (question.isGroup) {
          if (yupObj[question.id]) {
            yupObj[question.id] = yupObj[question.id].concat(
              Yup.array()
                .transform(v => (!v ? [] : v))
                .nullable()
                .of(rule)
            )
          } else {
            yupObj[question.id] = Yup.array()
              .transform(v => (!v ? [] : v))
              .nullable()
              .of(rule)
          }
        } else {
          if (yupObj[question.id]) {
            yupObj[question.id] = yupObj[question.id].concat(rule)
          } else {
            yupObj[question.id] = rule
          }
        }
      }
      if (question.type === questionTypes.INPUT_NUMBER.id) {
        if (question.min) {
          const rule = Yup.number().min(question.min, ({ min }) =>
            myI18n._(t`Provided value cannot be lesser than ${min}!`)
          )
          if (question.isGroup) {
            if (yupObj[question.id]) {
              yupObj[question.id] = yupObj[question.id].concat(
                Yup.array()
                  .transform(v => (!v ? [] : v))
                  .nullable()
                  .of(rule)
              )
            } else {
              yupObj[question.id] = Yup.array()
                .transform(v => (!v ? [] : v))
                .nullable()
                .of(rule)
            }
          } else {
            if (yupObj[question.id]) {
              yupObj[question.id] = yupObj[question.id].concat(rule)
            } else {
              yupObj[question.id] = rule
            }
          }
        }
        if (question.max) {
          const rule = Yup.number().max(question.max, ({ max }) =>
            myI18n._(t`Provided value cannot be greater than ${max}!`)
          )
          if (question.isGroup) {
            if (yupObj[question.id]) {
              yupObj[question.id] = yupObj[question.id].concat(
                Yup.array()
                  .transform(v => (!v ? [] : v))
                  .nullable()
                  .of(rule)
              )
            } else {
              yupObj[question.id] = Yup.array()
                .transform(v => (!v ? [] : v))
                .nullable()
                .of(rule)
            }
          } else {
            if (yupObj[question.id]) {
              yupObj[question.id] = yupObj[question.id].concat(rule)
            } else {
              yupObj[question.id] = rule
            }
          }
        }
      }
    })
    setValidationSchema(Yup.object().shape(yupObj))
  }, [rebuildSchema])

  const initialTouched = {}
  values.created.forEach(q => {
    if (q.isGroup) {
      initialTouched[q.id] = q.groupQuestions.map(sub => true)
    } else {
      initialTouched[q.id] = true
    }
  })

  return (
    <Formik
      initialValues={questionsToInitial(values.created)}
      enableReinitialize
      initialTouched={{
        ...initialTouched
      }}
      touched={initialTouched}
      validationSchema={validationSchema}
    >
      {formikState => (
        <DisplayedSurvey
          formikState={formikState}
          validationSchema={validationSchema}
          {...props}
        />
      )}
    </Formik>
  )
}

const DisplayedSurvey = props => {
  const {
    formikState,
    values,
    setFieldValue,
    validationSchema,
    rebuildSchema
  } = props

  useEffect(() => {
    // const touched = {}
    // values.created.forEach(q => {
    //   if (q.isGroup) {
    //     touched[q.id] = q.groupQuestions.map(sub => true)
    //   } else {
    //     touched[q.id] = true
    //   }
    // })
    // formikState.setTouched(touched)
    formikState.validateForm().then(errors => {
      formikState.setErrors(errors)
    })
  }, [validationSchema])

  // const validateSurveyTab = ({ formikState, disableFields }) => {
  //   const errorsFiltered = {}
  //   try {
  //     validationSchema.validateSync(formikState.values, {
  //       abortEarly: false
  //     })
  //   } catch (err) {
  //     if (err instanceof ValidationError) {
  //       const validationsErrors = err.inner.map(({ path, errors }) => [
  //         path,
  //         errors
  //       ])
  //       console.log('errors', err)
  //       validationsErrors.forEach(([key, value]) => {
  //         if (!disableFields.includes(key)) {
  //           errorsFiltered[key] = value
  //         }
  //       })
  //     }
  //   }
  //   return errorsFiltered
  // }

  const checkQuestionsToDisable = ({ values, formikState }) => {
    const toDisable = []
    const toDisableOptions = {}
    if (values.created) {
      values.created.forEach((item, key) => {
        if (item.conditionsToCheck.length > 0) {
          item.conditionsToCheck.forEach(condition => {
            const addOptionToDisable = (parent, option) => {
              if (!toDisableOptions[parent]) {
                toDisableOptions[parent] = []
              }
              toDisableOptions[parent].push(option)
            }
            if (!condition || condition === {}) {
              return
            } else if (!formikState.values[condition.question]) {
              return
            }
            if (condition.isSelected) {
              if (typeof formikState.values[condition.question] === 'object') {
                if (condition.groupQuestion || condition.groupQuestion === 0) {
                  if (
                    formikState.values[condition.question][
                      condition.groupQuestion
                    ] === condition.option
                  ) {
                    toDisable.push(condition.disableQuestionId)
                  }
                } else if (
                  formikState.values[condition.question][condition.option]
                ) {
                  toDisable.push(condition.disableQuestionId)
                }
              } else {
                if (
                  formikState.values[condition.question] === condition.option
                ) {
                  toDisable.push(condition.disableQuestionId)
                }
              }
            } else {
              if (typeof formikState.values[condition.question] === 'object') {
                if (condition.groupQuestion || condition.groupQuestion === 0) {
                  if (
                    formikState.values[condition.question][
                      condition.groupQuestion
                    ] !== condition.option
                  ) {
                    toDisable.push(condition.disableQuestionId)
                  }
                } else if (
                  !formikState.values[condition.question][condition.option]
                ) {
                  toDisable.push(condition.disableQuestionId)
                }
              } else if (
                formikState.values[condition.question] !== condition.option
              ) {
                toDisable.push(condition.disableQuestionId)
              }
            }
          })
        }
      })
    }
    return { toDisable, toDisableOptions }
  }

  const disableObject = useMemo(
    () => checkQuestionsToDisable({ values, formikState }),
    [formikState.values]
  )

  // const errorsFiltered = useMemo(
  //   () =>
  //     validateSurveyTab({
  //       formikState,
  //       disableFields: disableObject.toDisable
  //     }),
  //   [formikState.values, validationSchema]
  // )

  const toDisable = disableObject.toDisable
  let displayIndex = 0

  return (
    <div align='center'>
      <h1 style={{ textAlign: 'center', paddingTop: 20 }}>
        <Trans>Survey Preview</Trans>
      </h1>
      <div style={{ width: '80%' }}>
        <Typography style={{ margin: 10 }}>
          <b>
            <Trans>Language version</Trans>
          </b>
        </Typography>

        <ButtonGroup variant='text'>
          <Button
            onClick={() => {
              setFieldValue('frenchVersion', true)
            }}
          >
            <img
              src='/assets/images/french_flag.svg'
              className={values.frenchVersion ? null : 'grayscale'}
              style={{ width: '50px', margin: 5 }}
            ></img>
          </Button>

          <Button
            onClick={() => {
              setFieldValue('frenchVersion', false)
            }}
          >
            <img
              src='/assets/images/english_flag.svg'
              className={values.frenchVersion ? 'grayscale' : null}
              style={{ width: '50px', margin: 5 }}
            ></img>
          </Button>
        </ButtonGroup>
      </div>
      <I18nProvider
        catalogs={catalogs}
        language={values.frenchVersion ? 'fr' : 'en'}
      >
        <div style={{ margin: 20 }}>
          <h1>
            {values.frenchVersion ? values.surveyTitleFR : values.surveyTitleEN}
          </h1>
          <Typography>
            {values.frenchVersion
              ? values.surveyTitleFR_tooltip
              : values.surveyTitleEN_tooltip}
          </Typography>
        </div>
        {values.created
          ? values.created.map((item, index) => {
              if (toDisable.includes(item.id)) {
                return null
              }
              if (item.type !== 'HEADER') {
                displayIndex++
              }
              const error = formikState.errors[values.created[index].id]
              const errorIsArray = Boolean(error && Array.isArray(error))
              const nonApplicableValue = values[item.id + nonApplicableSuffix]
              const disabled = Boolean(
                nonApplicableValue && !Array.isArray(nonApplicableValue)
              )
              const showCheckbox =
                item.nonApplicable &&
                !questionTypes[item.type].nonApplicableAsOption
              return (
                <>
                  <QuestionTitle
                    question={values.created[index]}
                    errors={formikState.errors}
                    displayIndex={displayIndex}
                    displayEN={!values.frenchVersion}
                  />
                  <QuestionWidget
                    rebuildSchema={rebuildSchema}
                    disableFields={toDisable}
                    errorIsArray={errorIsArray}
                    displayEN={!values.frenchVersion}
                    questionValue={formikState.values[item.id]}
                    setFieldValue={formikState.setFieldValue}
                    disabled={disabled}
                    nonApplicable={nonApplicableValue}
                    question={item}
                    index={item.id}
                    key={index}
                  />
                  {showCheckbox && (
                    <Grid
                      container
                      direction='row'
                      alignItems='center'
                      style={{ marginLeft: 20 }}
                      key={item.id}
                    >
                      <FormikCheckboxField
                        style={{ marginLeft: 5 }}
                        name={`${item.id}` + nonApplicableSuffix}
                        row
                        controlLabel={<Trans>Is non applicable</Trans>}
                        onChange={e => {
                          setFieldValue(item.id, questionToInitial(item)[1])
                        }}
                      />
                    </Grid>
                  )}
                  <div className='py-12' key={`${index}-spacer`} />
                </>
              )
            })
          : null}
      </I18nProvider>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  withFormikIncluded({
    initialValues,
    validateOnBlur: true,
    validateOnChange: false
  })(withSnackbar(CreateSurvey))
)
