import { TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import { Trans } from '@lingui/macro'
import {
  NumberFormatDefault,
  parseDisplayedText,
  pdfDefaultFontSize
} from './Common'
import Html from 'react-pdf-html'
import { Text } from '@react-pdf/renderer'

export const FormHtmlPdf = ({
  typeProps,
  langFR,
  padding,
  describeMap,
  objectsFieldsMap
}) => {
  const { html, htmlFrench, pdfFontSize } = typeProps

  const parsed = parseDisplayedText({
    text: langFR ? htmlFrench : html,
    french: langFR,
    describeMap,
    objectsFieldsMap,
    returnString: true
  })

  const style = { fontSize: pdfDefaultFontSize }
  if (pdfFontSize) {
    style.fontSize = Number(pdfFontSize)
  }
  return (
    <Text>
      <Html style={style} collapse={false}>
        {parsed}
      </Html>
    </Text>
  )
}

export const FormHtmlInjector = ({
  typeProps,
  langFR,
  editor,
  showPdfProps,
  describeMap,
  objectsFieldsMap
}) => {
  const { html, htmlFrench, pdfFontSize } = typeProps
  const toParse = langFR ? htmlFrench : html

  if (editor && showPdfProps) {
    return (
      <div
        style={{
          fontSize: pdfFontSize && Number(pdfFontSize)
        }}
      >
        {ReactHtmlParser(toParse)}
      </div>
    )
  }

  return ReactHtmlParser(
    editor
      ? toParse
      : parseDisplayedText({
          text: toParse,
          french: langFR,
          objectsFieldsMap,
          returnString: true,
          describeMap
        })
  )
}

export const FormEditorHtmlInjector = ({
  editMode,
  depth,
  typeProps,
  showPdfProps,
  injectable,
  ...props
}) => {
  const dispatch = useDispatch()
  const { html, htmlFrench, pdfFontSize } = typeProps
  if (!editMode) {
    return (
      <FormHtmlInjector editor showPdfProps {...props} typeProps={typeProps} />
    )
  }
  return (
    <div>
      <TextField
        label={<Trans>Html - english</Trans>}
        fullWidth
        value={html}
        multiline
        rows={10}
        variant='outlined'
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.html = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      <TextField
        label={<Trans>Html - french</Trans>}
        fullWidth
        value={htmlFrench}
        multiline
        style={{ marginTop: 10 }}
        rows={10}
        variant='outlined'
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.htmlFrench = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      {showPdfProps && (
        <div style={{ marginTop: 10 }}>
          <Typography variant='h6'>
            <Trans>Pdf props</Trans>
          </Typography>
          <div style={{ padding: 5, marginTop: 10 }}>
            <Typography style={{ marginBottom: 10, fontSize: 16 }}>
              <Trans>Table</Trans>
            </Typography>
            <TextField
              label={<Trans>Font size</Trans>}
              fullWidth
              variant='outlined'
              value={pdfFontSize || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.pdfFontSize = e.target.value
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
              inputProps={{
                maxLength: 2
              }}
              InputProps={{
                inputComponent: NumberFormatDefault
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}
