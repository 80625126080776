import { Trans } from '@lingui/macro'
import { Grid, TextField } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { NumberFormatDefault } from './Common'

export const FormBlankSpace = ({ typeProps }) => {
  const { width = 1, height = 1 } = typeProps
  return (
    <div
      style={{
        width: Number(width),
        height: Number(height)
      }}
    />
  )
}
export const FormEditorBlankSpace = ({
  editMode,
  depth,
  typeProps = {},
  injectable,
  ...props
}) => {
  const { width, height } = typeProps
  const dispatch = useDispatch()

  if (!editMode) {
    return <FormBlankSpace typeProps={typeProps} {...props} />
  }

  return (
    <div>
      <Grid container direction='row'>
        <Grid item xs={6} style={{ padding: 5 }}>
          <TextField
            variant='outlined'
            label={<Trans>Height</Trans>}
            defaultValue={0}
            value={height}
            InputProps={{
              inputComponent: NumberFormatDefault
            }}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.height = e.target.value
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} style={{ padding: 5 }}>
          <TextField
            variant='outlined'
            label={<Trans>Width</Trans>}
            defaultValue={0}
            value={width}
            InputProps={{
              inputComponent: NumberFormatDefault
            }}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.width = e.target.value
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  )
}
