import { NO_USER } from '../SFAuthService'
import SFAuthService from '..//SFAuthService'

export const FIRST_SIGNER = 'FIRST_SIGNER'
export const SECOND_SIGNER = 'SECOND_SIGNER'
export const contactRoles = {
  [FIRST_SIGNER]: 'First Signer',
  [SECOND_SIGNER]: 'Second Signer'
}

export const fetchOpportunityAffiliations = ({
  opportunity,
  opportunityIds
}) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn
      .requestPost(
        '/actions/custom/flow/App_Opportunity_Affiliations_and_Contacts',
        {
          inputs: [{ opportunityId: opportunity, opportunityIds }]
        }
      )
      .then(flowResult => {
        const { contacts, opportunityAffiliations } = flowResult[0].outputValues
        if (!opportunityAffiliations) {
          return []
        }
        const contactsMap = {}
        contacts &&
          contacts.forEach(contact => {
            contactsMap[contact.Id] = contact
          })
        opportunityAffiliations.forEach(aff => {
          aff.Contact__c = contactsMap[aff.Contact__c]
        })
        return opportunityAffiliations || []
      })
  } else {
    return Promise.reject(NO_USER)
  }
}

export const deleteOpportunityContactAffiliations = toDelete => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Delete_Opportunity_Affiliations',
      {
        inputs: [{ toDelete }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}

export const updateOpportunityContactAffiliation = toUpdate => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Update_Opportunity_Affiliations',
      {
        inputs: [{ toUpdate }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}

export const addOpportunityContactAffiliation = toCreate => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Create_Opportunity_Affiliations',
      {
        inputs: [{ toCreate }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}
