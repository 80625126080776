import React from 'react'
import { useDispatch } from 'react-redux'
import { fromLonLat } from 'ol/proj'
import {
  boundingExtent,
  createEmpty,
  extend,
  getHeight,
  getWidth
} from 'ol/extent'
import 'ol/ol.css'
import { RMap, RLayerVector, RLayerCluster, RLayerTile } from 'rlayers'
import GeoJSON from 'ol/format/GeoJSON'
import { useState } from 'react'
import { useCallback } from 'react'
import { Autocomplete } from '@material-ui/lab'
import {
  Button,
  Chip,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  ListSubheader,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { t, Trans } from '@lingui/macro'
import caDivisions from './cenzus_divisions.json'
import caCities from './canada_cities_data.json'
import caProvinces from './canada_provinces.json'
import caCitiesPointers from './ca_cities.json'
import { VariableSizeList } from 'react-window'
import { myI18n } from 'translation/I18nConnectedProvider'
import {
  validFieldTypesForPicklist,
  validFieldTypesForTextField
} from 'app/views/forms/FormWizard'
import { useSelector } from 'react-redux'
import { useField, useFormikContext } from 'formik'
import { useRef } from 'react'
import { RCircle, RStyle, RFill, RStroke, RText } from 'rlayers/style'
import { useSpring, animated } from '@react-spring/web'
import tinycolor from 'tinycolor2'
import { FormConnectToObject } from '../../Common'
import { insertValueToSFObject } from 'app/views/forms/Form'
import { AutocompleteMuncipalities } from './FormCensusDivisionMuncipalityElements'
import { CenzusDivisionSelectProvince } from './FormCensusDivisionRegionalElements'
import cenzusDivisionsById from './census_divisions_by_id.json'
import * as Yup from 'yup'
import { FormErrorLabel } from '../../FormErrorLabel'
import { requiredTrans } from 'app/views/forms/formTranslations'

export const canadaCenter = fromLonLat([-105.3809, 62.227])
const pruidToDivisionIdent = {
  10: 'NL',
  11: 'PE',
  12: 'NS',
  13: 'NB',
  24: 'QC',
  35: 'ON',
  46: 'MB',
  47: 'SK',
  48: 'AB',
  59: 'BC',
  60: 'YT',
  61: 'NT',
  62: 'NU'
}

const pruidToProvince = {
  10: 'Newfoundland and Labrador',
  11: 'Prince Edward Island',
  12: 'Nova Scotia',
  13: 'New Brunswick',
  24: 'Québec',
  35: 'Ontario',
  46: 'Manitoba',
  47: 'Saskatchewan',
  48: 'Alberta',
  59: 'British Columbia',
  60: 'Yukon',
  61: 'Northwest Territories',
  62: 'Nunavut'
}

const provinceToProvinceIdent = {
  'Newfoundland and Labrador': 'NL',
  'Prince Edward Island': 'PE',
  'Nova Scotia': 'NS',
  'New Brunswick': 'NB',
  Québec: 'QC',
  Ontario: 'ON',
  Manitoba: 'MB',
  Alberta: 'AB',
  Saskatchewan: 'SK',
  'British Columbia': 'BC',
  Yukon: 'YT',
  'Northwest Territories': 'NT',
  Nunavut: 'NU'
}

export const provinceAbbrevationToProvince = Object.fromEntries(
  Object.entries(provinceToProvinceIdent).map(a => a.reverse())
)

const getCenzusId = (obj, withSuffix = true) => {
  let id = obj.CDNAME || obj.name
  const add = obj.province_code || pruidToDivisionIdent[obj.PRUID]
  if (add) {
    id += ' (' + add + ')'
  }
  if (withSuffix) {
    id += ' [' + myI18n._(t`Cenzus divison`) + ']'
  }
  return id
}

export const formCenzusDivisionDefaultValue = (obj, info, item) => {
  const { typeProps } = item
  if (!typeProps.connectedTo || !typeProps.connectedTo[0] || !obj) {
    return {
      selectedScope: [],
      impactsProvincialNorth: false,
      scopeType: 'Neighbourhood',
      pilotPhase: null,
      pilotPhaseScopeType: null
    }
  }
  const {
    cenzusDivision,
    impactsProvincialNorth,
    pilotPhase,
    regionalScope,
    cities,
    provinces,
    pilotPhaseScopeType
  } = typeProps.connectedTo[0]
  let selectedScope = []

  if (cenzusDivision && obj[cenzusDivision.name]) {
    const sfValue = obj[cenzusDivision.name]
    sfValue.split(', ').forEach(id => {
      selectedScope.push(id + '_cenzus')
    })
  }
  if (cities && obj[cities.name]) {
    const sfValue = obj[cities.name]
    sfValue.split(', ').forEach(id => {
      //const parsedId = id.replace(/ *\[[^)]*\] */g, '')
      selectedScope.push(id.trim() + '_city')
    })
  }
  if (provinces && obj[provinces.name]) {
    const sfValue = obj[provinces.name]
    sfValue.split(', ').forEach(id => {
      if (id === 'Canada') {
        selectedScope.push('Canada')
      } else {
        const parsedId = id.replace(/ *\[[^)]*\] */g, '')
        selectedScope.push(parsedId.trim() + '_province')
      }
    })
  }
  let scopeTypeValue = regionalScope ? obj[regionalScope.name] : 'Neighbourhood'
  let pilotPhaseValue = pilotPhase && obj[pilotPhase.name]
  let pilotPhaseScopeTypeValue =
    pilotPhaseScopeType && obj[pilotPhaseScopeType.name]
  if (scopeTypeValue !== 'National') {
    pilotPhaseValue = null
    pilotPhaseScopeTypeValue = null
  }
  if (['Neighbourhood', 'Municipal'].includes(scopeTypeValue)) {
    selectedScope = selectedScope.filter(id => id.includes('_city'))[0]
  } else if (pilotPhase && obj[pilotPhase.name] === 'No') {
    selectedScope = ['Canada']
  }

  return {
    selectedScope,
    impactsProvincialNorth:
      impactsProvincialNorth && obj[impactsProvincialNorth.name],
    scopeType: scopeTypeValue,
    pilotPhase: pilotPhaseValue,
    pilotPhaseScopeType: pilotPhaseScopeTypeValue
  }
}

function parseValueToSF (value = []) {
  const toRet = []
  if (!Array.isArray(value)) {
    value = [value]
  }
  value.forEach(idString => {
    if (idString === 'Canada') {
      toRet.push('Canada')
    } else {
      if (geoData[idString]) {
        const { type, province, id } = geoData[idString]
        toRet.push(id)
        // if (type === 'city') {
        //   toRet.push(id + ' [' + province + ']') //[City] ')
        // } else {
        //   toRet.push(id)
        // }
        // else if (type === 'province') {
        //   toRet.push(id + ' [Province]')
        // } else if (type === 'cenzus') {
        //   toRet.push(id + ' [Cenzus Division]')
        // }
      }
    }
  })
  return toRet.join(', ')
}

export const formCenzusDivisionExtractKey = ({
  saveMap,
  sfObject,
  value,
  connectedProps,
  subObjectsMap,
  connectedObjectId
}) => {
  const {
    cenzusDivision,
    impactsProvincialNorth,
    pilotPhase,
    regionalScope,
    pilotPhaseScopeType,
    cities,
    provinces
  } = connectedProps
  const { scopeType } = value

  let selected = value.selectedScope || []
  if (!Array.isArray(selected)) {
    selected = [selected]
  }
  if (impactsProvincialNorth) {
    let toSet = 'None'
    const cenzusDivisionsSelected = selected.filter(id =>
      id.includes('_cenzus')
    )
    let cenzusDivisionNorthCount = 0
    cenzusDivisionsSelected.forEach(id => {
      const data = geoData[id]
      if (['YT', 'NT', 'NU'].includes(data.province)) {
        cenzusDivisionNorthCount += 1
      }
    })
    if (
      cenzusDivisionNorthCount > 0 &&
      cenzusDivisionNorthCount > cenzusDivisionsSelected.length / 2
    ) {
      toSet = 'Focus'
    } else if (cenzusDivisionNorthCount > 0) {
      toSet = 'Impacts'
    }
    if (
      scopeType === 'Provincial or territorial' ||
      (scopeType === 'National' && value.pilotPhase === 'Yes')
    ) {
      selected
        .filter(id => id.includes('_province'))
        .some(id => {
          if (
            id.includes('Yukon') ||
            id.includes('Nunavut') ||
            id.includes('Northwest Territories')
          ) {
            toSet = 'Impacts'
            return true
          }
          return false
        })
    } else if (scopeType === 'National' && value.pilotPhase === 'No') {
      toSet = 'Impacts'
    }

    insertValueToSFObject({
      saveMap,
      sfObject,
      value: toSet,
      fieldProps: impactsProvincialNorth,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }

  if (['Neighbourhood', 'Municipal'].includes(scopeType)) {
    const municipality = selected[0]
    const provinceAbr = geoData[municipality]?.province
    if (cenzusDivision) {
      const censusId = provinceAbr
        ? geoData[municipality].cenzusDivision + ' (' + provinceAbr + ')'
        : null
      insertValueToSFObject({
        saveMap,
        sfObject,
        value: censusId,
        fieldProps: cenzusDivision,
        subObjectsMap,
        connectedObjectId,
        customConnectedField: true
      })
    }
    if (provinces) {
      insertValueToSFObject({
        saveMap,
        sfObject,
        value: provinceAbr ? provinceAbbrevationToProvince[provinceAbr] : null,
        fieldProps: provinces,
        subObjectsMap,
        connectedObjectId,
        customConnectedField: true
      })
    }
  } else {
    if (cenzusDivision) {
      insertValueToSFObject({
        saveMap,
        sfObject,
        value: parseValueToSF(selected.filter(id => id.includes('_cenzus'))),
        fieldProps: cenzusDivision,
        subObjectsMap,
        connectedObjectId,
        customConnectedField: true
      })
    }
    if (provinces) {
      insertValueToSFObject({
        saveMap,
        sfObject,
        value: parseValueToSF(
          selected.filter(id => id.includes('_province') || id === 'Canada')
        ),
        fieldProps: provinces,
        subObjectsMap,
        connectedObjectId,
        customConnectedField: true
      })
    }
  }

  if (cities) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: parseValueToSF(selected.filter(id => id.includes('_city'))),
      fieldProps: cities,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }
  if (pilotPhase) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: value.pilotPhase,
      fieldProps: pilotPhase,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }
  if (regionalScope) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: value.scopeType,
      fieldProps: regionalScope,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }
  if (pilotPhaseScopeType) {
    insertValueToSFObject({
      saveMap,
      sfObject,
      value: value.pilotPhaseScopeType,
      fieldProps: pilotPhaseScopeType,
      subObjectsMap,
      connectedObjectId,
      customConnectedField: true
    })
  }
}

export const geoData = {
  Canada: {
    label: myI18n._(t`Canada`),
    type: 'canada'
  },
  ...Object.fromEntries(
    caDivisions.features.map(obj => {
      return [
        getCenzusId(obj.properties, false) + '_cenzus',
        {
          id: getCenzusId(obj.properties, false),
          province: pruidToDivisionIdent[obj.properties.PRUID],
          type: 'cenzus',
          label:
            getCenzusId(obj.properties, false) +
            ' [' +
            cenzusDivisionsById[obj.properties.CDUID]?.census_division_type +
            ']'
        }
      ]
    })
  ),
  ...Object.fromEntries(
    caCities.map(obj => {
      return [
        obj.name + ' [' + obj.province_code + ']_city',
        {
          province: obj.province_code,
          cenzusDivision: obj.census_division,
          id: obj.name + ' [' + obj.province_code + ']',
          type: 'city',
          muncipalLabel: obj.name + ' (' + obj.province_code + ')',
          label:
            obj.name +
            ' (' +
            obj.province_code +
            ') [' +
            myI18n._(t`City`) +
            ']'
        }
      ]
    })
  ),
  ...Object.fromEntries(
    caProvinces.features.map(obj => {
      return [
        obj.properties.name + '_province',
        {
          type: 'province',
          location: obj.center,
          id: obj.properties.name,
          label: (
            <>
              <Trans id={obj.properties.name} />
              {' [' + myI18n._(t`Province`) + ']'}
            </>
          )
        }
      ]
    })
  )
}

const AutocompleteWithGeographicalOptions = ({
  onlyProvinces,
  onlyCenzusDivision,
  avaliableProvinces,
  scopeType,
  disabled,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Autocomplete
      multiple
      fullWidth
      disableListWrap
      disableCloseOnSelect
      disableClearable
      disabled={disabled}
      options={Object.keys(geoData)
        .filter(item => {
          if (scopeType === 'Regional' && item.includes('_cenzus')) {
            const provinceId =
              provinceAbbrevationToProvince[geoData[item].province] +
              '_province'
            if (!props.value.includes(provinceId)) {
              return false
            }
          }
          if (onlyCenzusDivision && !item.includes('_cenzus')) {
            return false
          }
          if (onlyProvinces) {
            return item.includes('_province')
          } else if (avaliableProvinces && avaliableProvinces.length > 0) {
            const provinceAbbrevation = geoData[item].province
            return avaliableProvinces.includes(provinceAbbrevation)
          }
          return true
        })
        .sort((a, b) => a.localeCompare(b))}
      filterSelectedOptions
      classes={classes}
      ListboxComponent={ListboxComponent}
      renderGroup={renderGeohraphicalOptionsGroup}
      renderInput={params => (
        <TextField {...params} variant='outlined' label={props.label} />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant='outlined'
            key={option}
            label={geoData[option]?.label}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={option => (
        <Typography noWrap>{geoData[option]?.label}</Typography>
      )}
      {...props}
    />
  )
}

export const formCenzusDivisionError = error => {
  if (error.selectedScope) {
    return error.selectedScope
  }
  return error
}

export const formCensusDivisionValidation = item =>
  Yup.object({
    selectedScope: Yup.mixed()
      .checkIfMuniciplaityIsRequired(requiredTrans)
      .nullable()
  }).checkCensusDivisionValidity(item)

Yup.addMethod(Yup.mixed, 'checkIfMuniciplaityIsRequired', function (trans) {
  return this.test('checkIfMuniciplaityIsRequired', null, function (value) {
    const { path, createError, parent } = this
    const {
      scopeType = 'Neighbourhood',
      pilotPhaseScopeType,
      pilotPhase
    } = parent
    const isPilotPhase = pilotPhase === 'Yes'
    const scopeToCheck = isPilotPhase ? pilotPhaseScopeType : scopeType
    if (['Neighbourhood', 'Municipal'].includes(scopeToCheck) && !value) {
      return createError({
        path,
        message: trans
      })
    }
    return true
  })
})

const atLeastOneCenzusPerProvinceTrans = (
  <Trans>
    CENZUS_DIVISION_ELEMENT_VALIDATION_ERROR_AT_LEAST_ONE_VALUE_PER_REGION
  </Trans>
)
const cenzusDivisionRequiredTrans = (
  <Trans>CENZUS_DIVISION_ELEMENT_SELECT_CENZUS_REQUIRED_LABEL</Trans>
)

Yup.addMethod(Yup.object, 'checkCensusDivisionValidity', function (item) {
  return this.test('checkCensusDivisionValidity', null, function (object) {
    const { path, createError, parent } = this
    const {
      selectedScope = [],
      pilotPhase,
      pilotPhaseScopeType = 'Neighbourhood',
      scopeType = 'Neighbourhood'
    } = object
    const { avaliableProvinces = [] } = item.typeProps
    const onlyOneProvinceAvaliable = avaliableProvinces.length === 1
    let notEnoughSelectedRegional = false
    const scopeToCheck = pilotPhase === 'Yes' ? pilotPhaseScopeType : scopeType
    if (scopeToCheck === 'Regional' && Array.isArray(selectedScope)) {
      const selectedPerProvince = Object.fromEntries(
        selectedScope
          .filter(id => id.includes('_province'))
          .map(id => [provinceToProvinceIdent[id.split('_')[0]], []])
      )
      selectedScope
        .filter(id => !id.includes('_province'))
        .forEach(id => {
          const data = geoData[id]
          if (data.province) {
            selectedPerProvince[data.province].push(id)
          }
        })
      notEnoughSelectedRegional = Object.values(selectedPerProvince).some(
        array => array.length === 0
      )
    }
    if (notEnoughSelectedRegional) {
      return createError({
        path,
        message: onlyOneProvinceAvaliable
          ? cenzusDivisionRequiredTrans
          : atLeastOneCenzusPerProvinceTrans
      })
    }
    return true
  })
})

export function FormCensusDivision ({
  id,
  useMultiuser,
  formId,
  typeProps,
  disabled,
  formikRef,
  ...props
}) {
  const [prevCord, setPrevCord] = useState({ x: 0, y: 0 })
  const [current, setCurrent] = useState(null)
  const ref = useRef()
  const xDiff = window.innerWidth - prevCord.x
  const elementWidth = ref?.current?.offsetWidth || 30
  const switchSides = xDiff < elementWidth + 30
  const animStyles = useSpring({
    position: 'fixed',
    left: switchSides ? prevCord.x - 30 - elementWidth : prevCord.x + 30,
    top: prevCord.y,
    zIndex: 100,
    pointerEvents: 'none'
  })

  return (
    <>
      {current && (
        <animated.div style={animStyles}>
          <Paper style={{ padding: 5 }} ref={ref}>
            {getCenzusId(current.values_, false)}
          </Paper>
        </animated.div>
      )}
      <FormCensusDivisionMap
        setPrevCord={setPrevCord}
        current={current}
        setCurrent={setCurrent}
        typeProps={typeProps}
        id={id}
        useMultiuser={useMultiuser}
        formId={formId}
        disabled={disabled}
        formikRef={formikRef}
      />
    </>
  )
}

const scopeOptions = [
  {
    value: 'Neighbourhood',
    label: <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_NEIGHBOURHOOD_OPTION</Trans>
  },
  {
    value: 'Municipal',
    label: <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_MUNCIPAL_OPTION</Trans>
  },
  {
    value: 'Regional',
    label: <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_REGIONAL_OPTION</Trans>
  },
  {
    value: 'Provincial or territorial',
    label: (
      <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_PROVINCE_OR_TERRITORY_OPTION</Trans>
    )
  },
  {
    value: 'National',
    label: <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_National_OPTION</Trans>
  }
]

const initialMapView = { center: canadaCenter, zoom: 3.3 }
const FormCensusDivisionMap = ({
  setPrevCord,
  current,
  setCurrent,
  typeProps,
  id,
  formikRef,
  disabled
}) => {
  const [field, meta, helpers] = useField(id)
  const { value = {} } = field
  const { setFieldValue } = useFormikContext()
  const {
    selectedScope = [],
    pilotPhase,
    pilotPhaseScopeType = 'Neighbourhood',
    scopeType = 'Neighbourhood'
  } = value
  const [view, setView] = React.useState(initialMapView)
  const [movingMap, setMovingMap] = React.useState(false)
  const chipContainer = useRef()
  const { predefinedGroups = [], avaliableProvinces = [] } = typeProps
  const onlyOneProvinceAvaliable = avaliableProvinces.length === 1
  const user = useSelector(state => state.user)
  const isPilotPhase = pilotPhase === 'Yes'
  const scopeToCheck = isPilotPhase ? pilotPhaseScopeType : scopeType
  const municipalDisplay = ['Neighbourhood', 'Municipal'].includes(scopeToCheck)
  const regionalDisplay = ['Regional', 'Provincial or territorial'].includes(
    scopeToCheck
  )
  const nationalDisplay = scopeToCheck === 'National'
  const provinceSelected =
    regionalDisplay && selectedScope.some(id => id.includes('_province'))
  const hideMap =
    municipalDisplay ||
    (!provinceSelected && !nationalDisplay) ||
    (isPilotPhase && !scopeToCheck)
  const elementsSpacing = 15

  const getAvaliableCenzusDivisions = useCallback(() => {
    let toRet = []
    if (avaliableProvinces && avaliableProvinces.length > 0) {
      const provinces = avaliableProvinces.map(
        id => provinceToProvinceIdent[id.split('_')[0]]
      )
      toRet = [...divisionsFeatures].filter(feature => {
        const provinceAbbreviation = pruidToDivisionIdent[feature.values_.PRUID]
        return provinces.includes(provinceAbbreviation)
      })
    } else {
      toRet = divisionsFeatures
    }
    return toRet
  }, [avaliableProvinces])

  const setMapMovingCallback = useCallback(e => {
    setMovingMap(true)
  }, [])
  const setMapNotMovingCallback = useCallback(e => {
    setMovingMap(false)
  }, [])

  const addValueCallback = useCallback(
    (newId, removable) => {
      if (formikRef) {
        const { setFieldValue, values } = formikRef.current
        const toSet = [...values[id].selectedScope]
        if (removable && toSet.includes(newId)) {
          if (toSet.includes(newId)) {
            toSet.splice(toSet.indexOf(newId), 1)
            setFieldValue(`${id}.selectedScope`, toSet)
          }
        } else {
          if (!toSet.includes(newId)) {
            toSet.push(newId)
            setFieldValue(`${id}.selectedScope`, toSet)
          }
        }
      }
    },
    [formikRef, id]
  )

  const PilotPhaseQuestionComponent = (
    <div>
      <FormLabel>
        <Trans>CENZUS_DIVISION_ELEMENT_PILOT_PHASE_QUESTION</Trans>
      </FormLabel>

      <RadioGroup
        value={pilotPhase}
        onChange={e => {
          const toSet = { ...field.value }
          toSet.pilotPhase = e.target.value
          if (e.target.value === 'No') {
            toSet.selectedScope = 'Canada'
          } else if (toSet.selectedScope === 'Canada') {
            toSet.selectedScope = []
          }
          setFieldValue(id, toSet)
        }}
      >
        <FormControlLabel
          disabled={disabled}
          value={'Yes'}
          control={<Radio />}
          label={<Trans>Yes</Trans>}
        />
        <FormControlLabel
          disabled={disabled}
          value={'No'}
          control={<Radio />}
          label={<Trans>No</Trans>}
        />
      </RadioGroup>
    </div>
  )

  return (
    <div>
      <Grid container>
        <Grid item xs={hideMap ? 12 : 6} style={{ padding: 10 }}>
          {predefinedGroups.length > 0 && !municipalDisplay && (
            <Grid container style={{ marginBottom: 10 }}>
              {predefinedGroups.map((obj, index) => {
                const { options = [], labelEN, labelFR } = obj
                return (
                  <Grid
                    item
                    key={index}
                    style={{ paddingRight: 5, paddingTop: 5 }}
                  >
                    <Button
                      color='primary'
                      variant='contained'
                      disabled={disabled}
                      onClick={e => {
                        const toSet = { ...field.value }
                        options.forEach(opt => {
                          if (!toSet.selectedScope.includes(opt)) {
                            toSet.selectedScope.push(opt)
                          }
                        })
                        helpers.setValue(toSet)
                      }}
                    >
                      <Icon style={{ marginRight: 5 }}>add</Icon>
                      {user.language === 'en_US' ? labelEN : labelFR}
                    </Button>
                  </Grid>
                )
              })}
            </Grid>
          )}

          <div style={{ marginTop: elementsSpacing }}>
            <FormLabel>
              <Trans>CENZUS_DIVISION_ELEMENT_SCOPE</Trans>
            </FormLabel>
            <RadioGroup
              value={scopeType}
              onChange={(event, value) => {
                const toSet = {
                  ...field.value,
                  pilotPhase: null,
                  pilotPhaseScopeType: null,
                  scopeType: value
                }
                if (value === 'Neighbourhood' || value === 'Municipal') {
                  if (Array.isArray(toSet.selectedScope)) {
                    toSet.selectedScope = ''
                  }
                } else {
                  if (!Array.isArray(toSet.selectedScope)) {
                    toSet.selectedScope = []
                  } else if (value === 'Provincial or territorial') {
                    toSet.selectedScope = [...selectedScope].filter(id =>
                      id.includes('_province')
                    )
                  } else if (value === 'National') {
                    toSet.selectedScope = []
                    setView(initialMapView)
                  }
                  if (onlyOneProvinceAvaliable) {
                    if (value === 'Regional') {
                      toSet.selectedScope.push(avaliableProvinces[0])
                    } else if (value === 'Provincial or territorial') {
                      toSet.selectedScope = [avaliableProvinces[0]]
                    }
                  }
                }
                setFieldValue(id, toSet)
              }}
            >
              {scopeOptions
                .filter(obj =>
                  onlyOneProvinceAvaliable ? obj.value !== 'National' : true
                )
                .map(obj => {
                  return (
                    <FormControlLabel
                      disabled={disabled}
                      value={obj.value}
                      control={<Radio />}
                      label={obj.label}
                    />
                  )
                })}
            </RadioGroup>
          </div>

          {scopeType === 'National' && isPilotPhase && (
            <div style={{ marginTop: elementsSpacing }}>
              {PilotPhaseQuestionComponent}
              <div style={{ marginTop: elementsSpacing }}>
                <FormLabel>
                  <Trans>CENZUS_DIVISION_ELEMENT_SCOPE_PILOT_PHASE</Trans>
                </FormLabel>
              </div>
              <RadioGroup
                value={pilotPhaseScopeType}
                onChange={(event, value) => {
                  const toSet = {
                    ...field.value,
                    pilotPhaseScopeType: value
                  }
                  if (value === 'Neighbourhood' || value === 'Municipal') {
                    if (Array.isArray(toSet.selectedScope)) {
                      toSet.selectedScope = ''
                    }
                  } else {
                    if (!Array.isArray(toSet.selectedScope)) {
                      toSet.selectedScope = []
                    } else if (value === 'Provincial or territorial') {
                      toSet.selectedScope = [...selectedScope].filter(id =>
                        id.includes('_province')
                      )
                    }
                  }
                  setFieldValue(id, toSet)
                }}
              >
                {scopeOptions
                  .filter(obj => obj.value !== 'National')
                  .map(obj => {
                    return (
                      <FormControlLabel
                        disabled={disabled}
                        value={obj.value}
                        control={<Radio />}
                        label={obj.label}
                      />
                    )
                  })}
              </RadioGroup>
            </div>
          )}

          <div style={{ paddingTop: elementsSpacing }}>
            {municipalDisplay ? (
              <div>
                <FormLabel>
                  {isPilotPhase ? (
                    <Trans>
                      CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY_PILOT_PHASE
                    </Trans>
                  ) : (
                    <Trans>CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY</Trans>
                  )}
                </FormLabel>
                <AutocompleteMuncipalities
                  id={id}
                  value={selectedScope || ''}
                  disabled={disabled}
                  avaliableProvinces={avaliableProvinces.map(
                    id => provinceToProvinceIdent[id.split('_')[0]]
                  )}
                  scopeType={scopeToCheck}
                  onChange={(e, value) => {
                    setFieldValue(`${id}.selectedScope`, value)
                  }}
                  label={<div />}
                  renderOption={option => (
                    <Typography noWrap>
                      {geoData[option]?.muncipalLabel}
                    </Typography>
                  )}
                />
              </div>
            ) : (
              <>
                {nationalDisplay &&
                  !pilotPhaseScopeType &&
                  PilotPhaseQuestionComponent}

                {(regionalDisplay ||
                  (nationalDisplay && pilotPhase === 'Yes')) && (
                  <CenzusDivisionSelectProvince
                    disabled={disabled}
                    id={id}
                    selected={selectedScope}
                    avaliableProvinces={avaliableProvinces}
                    scopeType={scopeToCheck}
                    setView={setView}
                    isPilotPhase={isPilotPhase}
                  />
                )}

                {Boolean(
                  (scopeToCheck === 'Regional' ||
                    (nationalDisplay && isPilotPhase)) &&
                    selectedScope.some(id => id.includes('_province'))
                ) && (
                  <>
                    <div
                      style={{ paddingBottom: 10, marginTop: elementsSpacing }}
                    >
                      <FormLabel>
                        {scopeToCheck === 'Regional' && (
                          <>
                            {isPilotPhase ? (
                              <Trans>
                                CENZUS_DIVISION_ELEMENT_SEARCH_FOR_CENZUS_DIVISIONS_PILOT_PHASE
                              </Trans>
                            ) : (
                              <Trans>
                                CENZUS_DIVISION_ELEMENT_SEARCH_FOR_CENZUS_DIVISIONS
                              </Trans>
                            )}
                          </>
                        )}
                        {scopeToCheck === 'National' && (
                          <Trans>
                            CENZUS_DIVISION_ELEMENT_SELECT_PILOT_PHASE_SCOPE
                          </Trans>
                        )}
                      </FormLabel>
                    </div>
                    <AutocompleteWithGeographicalOptions
                      value={selectedScope || []}
                      disabled={disabled}
                      scopeType={scopeToCheck}
                      onlyCenzusDivision={scopeToCheck === 'Regional'}
                      avaliableProvinces={avaliableProvinces.map(
                        id => provinceToProvinceIdent[id.split('_')[0]]
                      )}
                      onChange={(e, value) => {
                        setFieldValue(`${id}.selectedScope`, value)
                      }}
                      renderTags={(tagValue, getTagProps) => null}
                    />

                    <FormErrorLabel id={id} />

                    {Array.isArray(selectedScope) && selectedScope.length > 0 && (
                      <div style={{ marginTop: 15 }}>
                        <FormLabel>
                          <Trans>CENZUS_DIVISION_ELEMENT_SELECTED_LABEL</Trans>
                        </FormLabel>
                        <Grid
                          ref={chipContainer}
                          item
                          xs
                          container
                          alignItems='flex-start'
                        >
                          {selectedScope
                            .filter(id => !id.includes('_province'))
                            .map(id => (
                              <Chip
                                label={geoData[id]?.label}
                                style={{ marginRight: 5, marginBottom: 5 }}
                                disabled={disabled}
                                onDelete={e => {
                                  const toSet = { ...field.value }
                                  toSet.selectedScope.splice(
                                    toSet.selectedScope.indexOf(id),
                                    1
                                  )
                                  helpers.setValue(toSet)
                                }}
                              />
                            ))}
                        </Grid>
                        <div style={{ paddingTop: 10 }}>
                          <Button
                            variant='contained'
                            color='primary'
                            disabled={selectedScope.length === 0}
                            onClick={e => {
                              const toSet = { ...field.value }
                              toSet.selectedScope = toSet.selectedScope.filter(
                                id => id.includes('_province')
                              )
                              helpers.setValue(toSet)
                            }}
                          >
                            <Trans>
                              CENZUS_DIVISION_ELEMENT_CLEAR_ALL_BUTTON
                            </Trans>
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Grid>

        {!hideMap && (
          <Grid
            item
            xs={6}
            style={{
              padding: 10
            }}
          >
            <div
              onMouseLeave={e => {
                setCurrent(null)
              }}
              onMouseMove={e => {
                if (current && !movingMap) {
                  setPrevCord({
                    x: e.nativeEvent.pageX,
                    y: e.nativeEvent.pageY
                  })
                }
              }}
              onWheel={e => {
                setMovingMap(true)
              }}
            >
              <RMap
                width={'100%'}
                height={'700px'}
                view={[view, setView]}
                initial={initialMapView}
                extent={boundingExtent([
                  fromLonLat([-143, 90]),
                  fromLonLat([-50, 40])
                ])}
                onPointerDrag={setMapMovingCallback}
                onMoveEnd={setMapNotMovingCallback}
              >
                <RLayerTile
                  projection='EPSG:3857'
                  url='https://stamen-tiles.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png'
                />

                {scopeToCheck === 'Provincial or territorial' ||
                (scopeType === 'National' && pilotPhase !== 'Yes') ? (
                  <NonInteractiveCenzusDivisonsLayer
                    value={selectedScope}
                    pilotPhase={pilotPhase}
                    getAvaliableCenzusDivisions={getAvaliableCenzusDivisions}
                    scopeType={scopeToCheck}
                  />
                ) : (
                  <CenzusDivisionsLayer
                    valueChanged={selectedScope.length}
                    value={selectedScope}
                    current={current}
                    addValue={addValueCallback}
                    setCurrent={setCurrent}
                    getAvaliableCenzusDivisions={getAvaliableCenzusDivisions}
                    disabled={disabled || movingMap}
                  />
                )}
              </RMap>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

const extentFeatures = (features, resolution) => {
  const extent = createEmpty()
  for (const f of features) extend(extent, f.getGeometry().getExtent())
  return Math.round(
    Math.round(0.25 * (getWidth(extent) + getHeight(extent))) / resolution
  )
}

const provincesFeatures = new GeoJSON({
  featureProjection: 'EPSG:3857'
  //featureProjection: 'EPSG:4617'
}).readFeatures(caProvinces)

const divisionsFeatures = new GeoJSON({
  featureProjection: 'EPSG:3857'
  //featureProjection: 'EPSG:4617'
}).readFeatures(caDivisions)

const NonInteractiveCenzusDivisonsLayer = React.memo(
  ({ value, getAvaliableCenzusDivisions, pilotPhase, scopeType }) => {
    const theme = useTheme()
    const { main, dark } = theme.palette.primary
    const tcMain = tinycolor(main)
    tcMain.setAlpha(0.4)
    const provincial = scopeType === 'Provincial or territorial'
    const national = scopeType === 'National'

    return (
      <>
        <RLayerVector zIndex={5} features={getAvaliableCenzusDivisions()}>
          <RStyle
            render={useCallback(
              (feature, resolution) => {
                const provinceId =
                  pruidToProvince[feature.values_.PRUID] + '_province'
                const isSelected =
                  value.includes(
                    getCenzusId(feature.values_, false) + '_cenzus'
                  ) ||
                  (provincial && value.includes(provinceId)) ||
                  (national && pilotPhase === 'No')
                let borderColor = main
                let fillColor = 'transparent'
                if (isSelected) {
                  borderColor = dark
                  fillColor = tcMain.toRgbString()
                }
                return (
                  <>
                    <RStroke color={borderColor} width={1} />
                    <RFill color={fillColor} />
                  </>
                )
              },
              [value, provincial, national, pilotPhase]
            )}
          />
        </RLayerVector>

        <RLayerVector zIndex={5} features={provincesFeatures}>
          <RStyle
            render={useCallback(
              feature => {
                const isSelected = value.includes(
                  feature.values_.name + '_province'
                )
                return (
                  <RStroke
                    color={isSelected ? dark : 'transparent'}
                    width={2}
                  />
                )
              },
              [value]
            )}
          />
        </RLayerVector>
      </>
    )
  }
)

const CenzusDivisionsLayer = React.memo(
  ({
    current,
    disabled,
    setCurrent,
    value,
    valueChanged,
    addValue,
    getAvaliableCenzusDivisions
  }) => {
    const theme = useTheme()
    const { main, dark } = theme.palette.primary
    const tcMain = tinycolor(main)
    tcMain.setAlpha(0.4)

    return (
      <>
        <RLayerVector
          zIndex={5}
          features={getAvaliableCenzusDivisions()}
          onPointerEnter={useCallback(
            e => {
              if (!disabled) {
                setCurrent(e.target)
              }
            },
            [disabled]
          )}
          onPointerMove={useCallback(
            e => {
              if (!disabled && e.target !== current) {
                setCurrent(e.target)
              }
            },
            [disabled, current]
          )}
          onPointerLeave={useCallback(
            e => {
              if (current === e.target && !disabled) {
                setCurrent(null)
              }
            },
            [current, disabled]
          )}
          onClick={useCallback(
            e => {
              if (!disabled) {
                let id = getCenzusId(e.target.values_, false) + '_cenzus'
                addValue(id, true)
                e.stopPropagation()
              }
            },
            [disabled]
          )}
        >
          <RStyle
            render={useCallback(
              (feature, resolution) => {
                const provinceId =
                  pruidToProvince[feature.values_.PRUID] + '_province'
                if (!value.includes(provinceId)) {
                  return null
                }
                const isSelected = value.includes(
                  getCenzusId(feature.values_, false) + '_cenzus'
                )
                const isHovered = feature === current
                let borderColor = main
                let fillColor = 'transparent'
                if (isSelected) {
                  borderColor = dark
                  fillColor = tcMain.toRgbString()
                }
                if (isHovered) {
                  //fillColor = selectedHoverColor
                  borderColor = dark
                }

                return (
                  <>
                    <RStroke color={borderColor} width={isHovered ? 5 : 1} />
                    <RFill color={fillColor} />
                  </>
                )
              },
              [current, value, valueChanged]
            )}
          />
        </RLayerVector>

        <RLayerVector zIndex={5} features={provincesFeatures}>
          <RStyle
            render={useCallback(
              feature => {
                const isSelected = value.includes(
                  feature.values_.name + '_province'
                )
                return (
                  <RStroke
                    color={isSelected ? dark : 'transparent'}
                    width={4}
                  />
                )
              },
              [value, valueChanged]
            )}
          />
        </RLayerVector>
      </>
    )
  }
)

const citiesFeatures = new GeoJSON({
  featureProjection: 'EPSG:3857'
}).readFeatures(caCitiesPointers)
const cityDisabledColor = 'rgba(124, 124, 124, 0.7)'

const CitiesClusterLayer = React.memo(
  ({
    current,
    value,
    addValue,
    setCurrent,
    disableCitySelect,
    getAvaliableCities,
    setPrevCord
  }) => {
    const theme = useTheme()
    const cacheSize = 1024 * 5
    const { main, dark } = theme.palette.primary
    const tcMain = tinycolor(main)

    return (
      <>
        <RLayerCluster
          zIndex={10}
          distance={40}
          features={getAvaliableCities()}
          onPointerLeave={useCallback(e => setCurrent(null), [])}
          onPointerMove={useCallback(e => {
            const features = e.target.values_.features
            if (features.length === 1) {
              //console.log(e.target.values_.features[0].values_.name)
              setCurrent(e.target.values_.features[0])
              e.stopPropagation()
            }
            setPrevCord({
              x: e.originalEvent.screenX,
              y: e.originalEvent.screenY
            })
          }, [])}
          onClick={useCallback(
            e => {
              const features = e.target.values_.features
              if (features.length === 1) {
                const target = features[0].values_
                if (
                  (!disableCitySelect ||
                    value.join(';').includes(target.name)) &&
                  current?.values_?.name === target.name
                ) {
                  const id = target.name + '_city'
                  addValue(id, true)
                  e.stopPropagation()
                }
                if (value.join(';').includes(target.name)) {
                  e.stopPropagation()
                }
              }
            },
            [disableCitySelect, current]
          )}
        >
          <RStyle
            cacheSize={cacheSize}
            cacheId={useCallback(
              (feature, resolution) =>
                feature.get('features').length > 1
                  ? '#' + extentFeatures(feature.get('features'), resolution)
                  : '$' + feature.get('features')[0].values_.name,
              []
            )}
            render={useCallback(
              (feature, resolution) => {
                const size = feature.get('features').length
                if (size > 1) {
                  const radius = extentFeatures(
                    feature.get('features'),
                    resolution
                  )
                  return (
                    <React.Fragment>
                      <RCircle radius={radius}>
                        <RFill
                          color={tcMain
                            .setAlpha(
                              Math.min(0.8, 0.4 + Math.log(size / 10) / 20)
                            )
                            .toRgbString()}
                        />
                      </RCircle>
                      <RText text={size.toString()}>
                        <RFill color='#fff' />
                        <RStroke color='rgba(0, 0, 0, 0.6)' width={3} />
                      </RText>
                    </React.Fragment>
                  )
                }
                const unclusteredFeature = feature.get('features')[0]
                const name = unclusteredFeature.values_.name
                const isSelected = value.join(';').includes(name)
                const isHovered = name === current?.values_?.name
                let color = main
                if (isSelected) {
                  color = dark
                } else if (disableCitySelect) {
                  color = cityDisabledColor
                }
                return (
                  <React.Fragment>
                    <RCircle
                      radius={
                        isSelected || (isHovered && !disableCitySelect) ? 14 : 8
                      }
                    >
                      <RFill color={color} />
                    </RCircle>
                    {/* <RText text={isSelected ? name + '\n[SELECTED]' : name}>
                      <RFill color='#fff' />
                      <RStroke color='rgba(0, 0, 0, 0.6)' width={3} />
                    </RText> */}
                  </React.Fragment>
                )
              },
              [value, disableCitySelect, current]
            )}
          />
        </RLayerCluster>
        {/* //Rendering Text separatly is necessary because rendering Text with Shape changes onClick hitbox in a really werid way */}
        <RLayerCluster zIndex={10} distance={40} features={citiesFeatures}>
          <RStyle
            cacheSize={cacheSize}
            cacheId={useCallback((feature, resolution) => {
              const size = feature.get('features').length
              if (size > 1) {
                return 'NULL'
              }
              return 'NAME_' + feature.get('features')[0].values_.name
            }, [])}
            render={useCallback((feature, resolution) => {
              const size = feature.get('features').length
              if (size > 1) {
                return null
              }
              const unclusteredFeature = feature.get('features')[0]
              return (
                <RText text={unclusteredFeature.values_.name}>
                  <RFill color='#fff' />
                  <RStroke color='rgba(0, 0, 0, 0.6)' width={3} />
                </RText>
              )
            }, [])}
          />
        </RLayerCluster>
      </>
    )
  }
)

const LISTBOX_PADDING = 8 // px
const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
})

function renderRow (props) {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING
    }
  })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache (data) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

export const renderGeohraphicalOptionsGroup = params => [
  <ListSubheader key={params.key} component='div'>
    {params.group}
  </ListSubheader>,
  params.children
]

export const ListboxComponent = React.forwardRef(function ListboxComponent (
  props,
  ref
) {
  const { children, ...other } = props
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

export function FormEditorCensusDivision ({
  editMode,
  typeProps = {},
  depth,
  disabled,
  injectable,
  injectableId,
  ...props
}) {
  const dispatch = useDispatch()
  const {
    avaliableProvinces = [],
    connectedTo = [],
    predefinedGroups = []
  } = typeProps

  if (!editMode) {
    return <FormCensusDivision {...props} typeProps={typeProps} />
  }

  return (
    <div>
      <Grid
        container
        style={{ marginTop: 10, marginBottom: 10 }}
        alignItems='center'
      >
        <Grid item style={{ marginRight: 10 }}>
          <b>
            <Trans>Predifined groups</Trans>
          </b>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            disabled={disabled}
            onClick={e => {
              const toSet = { ...typeProps }
              toSet.predefinedGroups = [...predefinedGroups, {}]
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          >
            <Trans>Add new group</Trans>
            <Icon style={{ marginLeft: 5 }}>add</Icon>
          </Button>
        </Grid>
      </Grid>
      {predefinedGroups.map((obj, index) => {
        const { options = [], labelFR, labelEN } = obj
        return (
          <Grid container alignItems='center' style={{ marginBottom: 15 }}>
            <Grid item style={{ paddingRight: 10 }}>
              {index + 1}
            </Grid>
            <Grid item xs>
              <Grid container>
                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs style={{ paddingRight: 5 }}>
                    <TextField
                      label={<Trans>Label - english</Trans>}
                      fullWidth
                      variant='outlined'
                      value={labelEN || ''}
                      disabled={disabled}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.predefinedGroups[index].labelEN =
                          e.currentTarget.value
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      label={<Trans>Label - french</Trans>}
                      fullWidth
                      variant='outlined'
                      value={labelFR || ''}
                      disabled={disabled}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.predefinedGroups[index].labelFR =
                          e.currentTarget.value
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    />
                  </Grid>
                </Grid>
                <AutocompleteWithGeographicalOptions
                  value={options}
                  label={<Trans>Geographical scope</Trans>}
                  disabled={disabled}
                  onChange={(e, value) => {
                    const toSet = { ...typeProps }
                    toSet.predefinedGroups[index].options = value
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <IconButton
                disabled={disabled}
                onClick={e => {
                  const toSet = { ...typeProps }
                  toSet.predefinedGroups.splice(index, 1)
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Grid>
          </Grid>
        )
      })}

      <AutocompleteWithGeographicalOptions
        style={{ marginTop: 10 }}
        label={<Trans>Avaliable Provinces</Trans>}
        onlyProvinces
        value={avaliableProvinces}
        disabled={disabled}
        onChange={(e, value) => {
          const toSet = { ...typeProps }
          toSet.avaliableProvinces = value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
      />

      <FormConnectToObject
        depth={depth}
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        disableMultiple
        fieldRender={({ fieldsToConnect, fieldsToConnectMap, index }) => {
          const item = connectedTo[index] || {}
          const {
            cenzusDivision,
            pilotPhaseScopeType,
            regionalScope,
            impactsProvincialNorth,
            pilotPhase,
            cities,
            provinces
          } = item

          const stringFields = [...fieldsToConnect].filter(item =>
            validFieldTypesForTextField.includes(item.type)
          )
          const picklistFields = [...fieldsToConnect].filter(item =>
            validFieldTypesForPicklist.includes(item.type)
          )

          return (
            <div>
              <Autocomplete
                freeSolo={false}
                value={cenzusDivision ? cenzusDivision.label : null}
                disabled={disabled}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].cenzusDivision =
                    fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={stringFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Cenzus division field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={provinces ? provinces.label : null}
                disabled={disabled}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].provinces = fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={picklistFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Provinces field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={cities?.label || null}
                disabled={disabled}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].cities = fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={stringFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Cities field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={regionalScope ? regionalScope.label : null}
                disabled={disabled}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].regionalScope =
                    fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={picklistFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Geographical scope field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={
                  impactsProvincialNorth ? impactsProvincialNorth.label : null
                }
                disabled={disabled}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].impactsProvincialNorth =
                    fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={picklistFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Impact Provincial North field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={pilotPhaseScopeType ? pilotPhaseScopeType.label : null}
                disabled={disabled}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].pilotPhaseScopeType =
                    fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={picklistFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Pilot Phase Scope field</Trans>}
                  />
                )}
              />

              <Autocomplete
                freeSolo={false}
                value={pilotPhase ? pilotPhase.label : null}
                disabled={disabled}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].pilotPhase =
                    fieldsToConnectMap[value]
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                options={picklistFields.map(item => item.label)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Pilot Phase In Smaller Location field</Trans>}
                  />
                )}
              />
            </div>
          )
        }}
      />
    </div>
  )
}
