import { NO_USER } from '../SFAuthService'
import SFAuthService from '..//SFAuthService'
import { mapSFToForm, reverseMap } from '../sfDataService'

const mapFields = {
  direction: 'in',
  Id: 'id',
  Hide_On_Live__c: 'hideOnLive',
  Location__c: 'location',
  Text_English__c: 'textEN',
  Text_French__c: 'textFR',
  Order__c: 'order',
  CSS_Selector__c: 'selector',
  Data_Tut__c: 'dataTut',
  Content__c: 'content'
}

const reverseMapFields = reverseMap(mapFields)

export const deleteTutorialStep = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Tutorial_Step__c').delete(id)
}

export const fetchTutorialSteps = props => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Tutorial_Step__c')
    .find({ ...props })
    .then(steps => steps.map(obj => mapSFToForm(mapFields, obj)))
}

export const saveTutorialSteps = array => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const toUpdate = []
  const toCreate = []
  array.forEach((obj, index) => {
    const sfObj = mapSFToForm(reverseMapFields, obj)
    sfObj.Order__c = index
    delete sfObj.direction
    if (obj.id) {
      toUpdate.push(sfObj)
    } else {
      delete sfObj.Id
      toCreate.push(sfObj)
    }
  })
  return Promise.all([
    conn.sobject('Tutorial_Step__c').create(toCreate),
    conn.sobject('Tutorial_Step__c').update(toUpdate)
  ])
}
