import '../fake-db'
import '../styles/_app.scss'
import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import EgretTheme from './EgretLayout/EgretTheme/EgretTheme'
import AppContext from './appContext'
import history from 'history.js'
import { TourProvider } from '@reactour/tour'
import routes from './RootRoutes'
import { Store } from './redux/Store'
import Auth from './auth/Auth'
import EgretLayout from './EgretLayout/EgretLayout'
import AuthGuard from './auth/AuthGuard'
import { SnackbarProvider } from 'notistack'
import I18nConnectedProvider from '../translation/I18nConnectedProvider'
import { GlobalValues } from './utils/GlobalValues'
import InactivityDetector from './InactivityDetector'
import moment from 'moment'
import sfOauthConfig from './services/sfAuth/sfAuthConfig'
import { makeStyles } from '@material-ui/styles'
import MissingUserInformationPopUp from './MissingUserInformationPopUp'

// lock site for maintenance
const maintenance = false
moment.updateLocale('fr', {
  months: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ],
  monthsShort: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ],
  weekdaysShort: [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi'
  ]
})
moment.locale('en', { week: { dow: 1 } })
moment.updateLocale('en', {
  monthsShort: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  weekdaysShort: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
})

export const snackbarClasses = sfOauthConfig.isIcce
  ? {
    variantSuccess: { backgroundColor: '#569159' },
    variantError: { backgroundColor: '#C75656' },
    variantWarning: { backgroundColor: '#DC8935' },
    variantInfo: { backgroundColor: '#338EC0' }
  }
  : {
    variantSuccess: {
      backgroundColor: '#43a047',
      color: '#fff'
    },
    variantError: {
      backgroundColor: '#d32f2f',
      color: '#fff'
    },
    variantInfo: {
      backgroundColor: '#2196f3',
      color: '#fff'
    },
    variantWarning: {
      backgroundColor: '#ff9800',
      color: '#fff'
    }
  }

const useStyles = makeStyles(snackbarClasses)

const App = () => {
  const classes = useStyles()
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <I18nConnectedProvider>
          <SnackbarProvider maxSnack={3} classes={classes}>
            <EgretTheme>
              <TourProvider
                steps={[]}
                onClickClose={({ setIsOpen, setCurrentStep }) => {
                  setIsOpen(false)
                  setCurrentStep(0)
                }}
                styles={{
                  popover: base => ({
                    ...base,
                    '--reactour-accent': '#5cb7b7'
                  })
                }}
              >
                <Router history={history}>
                  <Auth maintenance={maintenance}>
                    <GlobalValues>
                      <AuthGuard>
                        <EgretLayout />
                        <InactivityDetector />
                        <MissingUserInformationPopUp />
                      </AuthGuard>
                    </GlobalValues>
                  </Auth>
                </Router>
              </TourProvider>
            </EgretTheme>
          </SnackbarProvider>
        </I18nConnectedProvider>
      </Provider>
    </AppContext.Provider>
  )
}

export default App
