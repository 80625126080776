import { Trans, t } from '@lingui/macro'
import {
  Divider,
  Grid,
  Icon,
  IconButton,
  Paper,
  TableCell,
  TextField,
  Typography
} from '@material-ui/core'
import { muiTextLabels } from 'app/views/utilities/muiDataTablesTranslations'
import React, { useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { useSnackbar } from 'notistack'
import { myI18n } from 'translation/I18nConnectedProvider'
import FindAccountButton from 'app/views/grants/FindAccountButton'
import {
  createAccountJoinOpportunity,
  deleteAccountJoinOpportunity,
  fetchAccountJoinOpportunityList
} from 'app/services/sfAuth/sfData/sfAccountAndOpportunityJoin'
import { useDispatch, useSelector } from 'react-redux'
import { FormConnectToObject, getMuState, MuEditedByLabel } from '../Common'
import {
  endEditingField,
  startEditingField
} from '../../multiuser/grpcMultiuserEdit'

import { useFormikContext } from 'formik'

export const formAccountJoinListDefaultValue = (obj, additionalInfo, item) => {
  if (!obj || !additionalInfo.accountJoinOpportunityList) {
    return []
  } else {
    let toMap = additionalInfo.accountJoinOpportunityList || []
    const tag = item.typeProps.tag
    if (tag) {
      toMap = toMap.filter(item => item.Tag__c === tag)
    }
    return toMap.map(item => ({
      id: item.Id,
      name: item.Account_Name__c,
      email: item.Account_Email__c
    }))
  }
}

export const FormAccountJoinListPrint = ({
  typeProps = {},
  title,
  value = [],
  connectedObject,
  id,
  editMode,
  ...props
}) => {
  return (
    <div style={{ flex: 1 }}>
      <b>
        <div
          style={{
            fontSize: 21,
            width: '100%',
            color: '#757575',
            textAlign: 'center'
          }}
        >
          {title}
        </div>
      </b>
      <Paper elevation={6} style={{ marginTop: 10, padding: 15 }}>
        {Boolean(value.length === 0) ? (
          <Typography style={{ fontWeight: 'bold' }}>
            <Trans>This list is empty!</Trans>
          </Typography>
        ) : (
          <>
            <Grid container>
              <Grid item xs style={{ padding: 4 }}>
                <Typography className='form-print-subtitle'>
                  <Trans>Name</Trans>
                </Typography>
              </Grid>
              <Grid item xs style={{ padding: 4 }}>
                <Typography className='form-print-subtitle'>
                  <Trans>Email</Trans>
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            {value.map(obj => {
              const { name, email } = obj
              return (
                <Grid container>
                  <Grid item xs style={{ padding: 4 }}>
                    <Typography style={{ whiteSpace: 'pre-line' }}>
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item xs style={{ padding: 4 }}>
                    <Typography style={{ whiteSpace: 'pre-line' }}>
                      {email}
                    </Typography>
                  </Grid>
                </Grid>
              )
            })}
          </>
        )}
      </Paper>
    </div>
  )
}

export const FormAccountJoinList = ({
  typeProps = {},
  title,
  value,
  disabled,
  saveDisabled = false,
  connectedObject,
  id,
  muBag,
  useMultiuser,
  editMode,
  ...props
}) => {
  const user = useSelector(state => state.user)
  const { tag, connectedTo } = typeProps
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [joining, setJoining] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const { values, setFieldValue } = useFormikContext()
  const { isEdited, userColor, userName } = getMuState({ values, id })

  const reloadData = () => {
    return fetchAccountJoinOpportunityList({
      opportunity: connectedObject.Id,
      tag
    }).then(result => {
      const list = result[0].outputValues.result || []
      const newValue = list.map(item => ({
        id: item.Id,
        name: item.Account_Name__c,
        email: item.Account_Email__c
      }))
      setFieldValue(id, newValue)
      if (useMultiuser) {
        endEditingField({
          ...muBag,
          fieldId: id,
          fieldValue: newValue,
          lockId: values.muInfo.lockId
        })
      }
    })
  }

  const addNewJoin = org => {
    if (editMode || saveDisabled) {
      return
    }
    const creatingKey = enqueueSnackbar(
      <Trans>Joing opportunity with selected organization</Trans>,
      {
        persist: true,
        variant: 'info'
      }
    )
    setJoining(true)
    return createAccountJoinOpportunity({
      accountId: org.Id,
      opportunityId: connectedObject.Id,
      tag
    }).then(
      result => {
        const error = result[0].outputValues.error
        if (error) {
          if (error === 'ALREADY_EXISTS') {
            enqueueSnackbar(
              <Trans>This organization is already connected!</Trans>,
              { variant: 'error' }
            )
            closeSnackbar(creatingKey)
            setJoining(false)
            return
          }
        }
        return reloadData().then(r => {
          closeSnackbar(creatingKey)
          setJoining(false)
          enqueueSnackbar(
            <Trans>Opportunity connected with selected organization!</Trans>,
            { variant: 'success' }
          )
        })
      },
      reject => {
        console.log(reject)
        closeSnackbar(creatingKey)
        setJoining(false)
        enqueueSnackbar(
          <Trans>
            Colud not join this opportunity with selected organization!
          </Trans>,
          { variant: 'error' }
        )
      }
    )
  }

  const deleteJoin = id => {
    enqueueSnackbar(<Trans>Deleting connection</Trans>, {
      variant: 'info'
    })
    setDeleting(true)
    deleteAccountJoinOpportunity({
      id
    }).then(
      result => {
        reloadData().then(r => {
          setDeleting(false)
          enqueueSnackbar(<Trans>Connection deleted!</Trans>, {
            variant: 'success'
          })
        })
      },
      reject => {
        setDeleting(false)
        enqueueSnackbar(<Trans>Could not delete this connection!</Trans>, {
          variant: 'error'
        })
      }
    )
  }

  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== 'Opportunity'
  )

  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>
          There is no object connected in editor or connected object is not of
          "Opportunity" type!
        </Trans>
      </div>
    )
  }

  return (
    <div style={{ marginTop: 15 }}>
      <MUIDataTable
        data={value}
        title={
          <Grid container wrap='nowrap'>
            <Typography variant='h6' style={{ marginRight: 20 }}>
              {title}
            </Typography>
            <FindAccountButton
              hidePhoneSearch
              hideWebsiteSearch
              label={<Trans>Search for organization to connect to</Trans>}
              disabled={
                disabled ||
                joining ||
                saveDisabled ||
                Boolean(isEdited && useMultiuser)
              }
              onSelect={addNewJoin}
              afterCreate={addNewJoin}
              allowCreation
              disableCreation={editMode || saveDisabled}
              onDialogClose={e => {
                if (useMultiuser) {
                  endEditingField({
                    ...muBag,
                    fieldId: id,
                    fieldValue: values[id],
                    lockId: values.muInfo.lockId
                  })
                }
              }}
              onDialogOpen={e => {
                if (useMultiuser) {
                  startEditingField({
                    ...muBag,
                    fieldId: id
                  })
                }
              }}
            />
          </Grid>
        }
        columns={[
          {
            name: 'name',
            label: myI18n._(t`Organization name`),
            options: {
              customHeadLabelRender: props => {
                return (
                  <div style={{ marginLeft: 20 }}>
                    <Trans>Organization name</Trans>
                  </div>
                )
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div style={{ flex: 1, marginLeft: 20 }}>{value}</div>
              }
            }
          },
          {
            name: 'email',
            label: myI18n._(t`Organization e-mail`),
            options: {
              customHeadLabelRender: props => {
                return (
                  <div>
                    <Trans>Organization e-mail</Trans>
                  </div>
                )
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{value}</div>
              }
            }
          },
          {
            name: 'id',
            options: {
              sort: false,
              customHeadRender: props => {
                return <TableCell style={{ width: 100 }} />
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>
                    <IconButton
                      disabled={disabled || deleting || saveDisabled}
                      onClick={() => deleteJoin(value)}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </div>
                )
              }
            }
          }
        ]}
        options={{
          textLabels: muiTextLabels(myI18n),
          search: false,
          filter: false,
          selectableRows: 'none',
          print: false,
          download: false,
          viewColumns: false
        }}
      />
      {isEdited && (
        <div style={{ marginTop: 5 }}>
          <MuEditedByLabel color={userColor} userName={userName} />
        </div>
      )}
    </div>
  )
}

export const FormEditorAccountJoinList = ({
  editMode,
  depth,
  showPrintProps,
  typeProps = {},
  disabled,
  injectable,
  injectableId,
  ...props
}) => {
  const dispatch = useDispatch()
  const { tag } = typeProps
  if (!editMode) {
    if (showPrintProps) {
      return (
        <FormAccountJoinListPrint
          editorPreview
          typeProps={typeProps}
          {...props}
        />
      )
    }
    return <FormAccountJoinList editMode typeProps={typeProps} {...props} />
  }
  return (
    <div>
      {!showPrintProps && (
        <TextField
          label={<Trans>Tag</Trans>}
          fullWidth
          value={tag || ''}
          variant='outlined'
          disabled={disabled}
          onChange={e => {
            const toSet = { ...typeProps }
            toSet.tag = e.target.value
            dispatch({
              type: 'FIELD',
              injectable,
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: { ...toSet }
            })
          }}
        />
      )}
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
    </div>
  )
}
