import { NO_USER } from '../SFAuthService'
import SFAuthService from '..//SFAuthService'
import { mapSFToForm, reverseMap } from '../sfDataService'
import { chunkArray } from '../../../../utils'
import {
  parseExtensionPhoneFromSF,
  parseExtensionPhoneToSF
} from 'app/views/common/Formats'

/**
 * Created by Michał Stawski on 13.10.2020.
 */

const mapFields = {
  direction: 'in',
  // Address: {
  //   name: 'address',
  //   type: 'Address'
  // },
  Id: 'id',
  OwnerId: 'owner',
  // Phone: 'phoneNumber',
  Title: 'jobTitle',
  Email: 'email',
  Name: 'name',
  FirstName: 'firstName',
  LastName: 'lastName',
  Associated_Organizations__c: 'associatedOrganizations',
  Role__c: 'role',
  Relationship__c: 'relationship',
  Provided_Organization_Name__c: 'providedOrganizationName',
  Phone: {
    key: 'phoneNumber',
    in: (contact, toRet) => {
      const { phoneNumber, countryCode } = parseExtensionPhoneFromSF(
        contact.Phone
      )
      toRet.phoneNumberCountryCode = countryCode
      return phoneNumber
    },
    out: res => {
      return parseExtensionPhoneToSF(
        res.phoneNumber,
        res.phoneNumberCountryCode
      )
    }
  },
  MobilePhone: {
    key: 'mobilePhone',
    in: (contact, toRet) => {
      const { phoneNumber, countryCode } = parseExtensionPhoneFromSF(
        contact.MobilePhone
      )
      toRet.mobilePhoneCountryCode = countryCode
      return phoneNumber
    },
    out: res => {
      return parseExtensionPhoneToSF(
        res.mobilePhone,
        res.mobilePhoneCountryCode
      )
    }
  },
  npe01__WorkPhone__c: {
    key: 'workPhone',
    in: (contact, toRet) => {
      const { phoneNumber, countryCode } = parseExtensionPhoneFromSF(
        contact.npe01__WorkPhone__c
      )
      toRet.workPhoneCountryCode = countryCode
      return phoneNumber
    },
    out: res => {
      return parseExtensionPhoneToSF(res.workPhone, res.workPhoneCountryCode)
    }
  }

  //  slautatation,
}
const reverseMapFields = reverseMap(mapFields)

export const saveContact = contact => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Update_Contact', {
      inputs: [
        {
          contact
        }
      ]
    })
    .then(flowResult => flowResult[0])
}

export const parseContacts = contact => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost(
      '/actions/custom/flow/Launched_Set_Contacts_Phone_to_New_Format',
      {
        inputs: [{}]
      }
    )
    .then(flowResult => {
      const { toModify } = flowResult[0].outputValues
      const toSend = []
      toModify.forEach(contact => {
        const toPush = {
          Id: contact.Id
        }
        let modified = false
        if (contact.Phone) {
          let newPhone = contact.Phone.replace(/\D/g, '')
          newPhone = parseExtensionPhoneToSF(newPhone)
          toPush.Phone = newPhone
          if (newPhone) {
            modified = true
          }
          if (newPhone.length > 23 || !newPhone) {
            modified = false
            console.log('invalid phone', contact)
          }
        }
        if (contact.MobilePhone) {
          let newMobilePhone = contact.MobilePhone.replace(/\D/g, '')
          newMobilePhone = parseExtensionPhoneToSF(newMobilePhone)
          toPush.MobilePhone = newMobilePhone
          if (newMobilePhone) {
            modified = true
          }
          if (newMobilePhone.length > 23 || !newMobilePhone) {
            modified = false
            console.log('invalid phone', contact)
          }
        }
        if (contact.npe01__WorkPhone__c) {
          let newWorkPhone = contact.npe01__WorkPhone__c.replace(/\D/g, '')
          newWorkPhone = parseExtensionPhoneToSF(newWorkPhone)
          toPush.npe01__WorkPhone__c = newWorkPhone
          if (newWorkPhone) {
            modified = true
          }
          if (newWorkPhone.length > 23 || !newWorkPhone) {
            modified = false
            console.log('invalid phone', contact)
          }
        }
        if (modified) {
          toSend.push(toPush)
        }
      })
      console.log('contacts with old phone number: ', toSend, toModify)
      const queriesSplit = new Array(Math.ceil(toSend.length / 500))
        .fill()
        .map(_ => toSend.splice(0, 500))
      return Promise.all(queriesSplit.map(contacts => updateContacts(contacts)))
    })
}

export const updateContacts = contacts => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Update_Contacts', {
    inputs: [{ contacts }]
  })
}

export const mapContact = (contact, onlyActive = false) => {
  return mapSFToForm(mapFields, contact, onlyActive)
}

export const saveContactParsed = value => {
  return saveContact(mapSFToForm(reverseMapFields, value))
}

export const fetchContact = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Contact').find({
    Id: id
  })
}

export const fetchContacts = ids => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const chunks = chunkArray(ids, 50)

  return conn
    .requestPost('/actions/custom/flow/App_Find_contacts', {
      inputs: chunks.map(item => ({
        ids: item
      }))
    })
    .then(results => [
      results.reduce(
        (prev, current) => {
          console.log('reduce', prev, current)
          if (current.isSuccess) {
            prev.isSuccess = prev.isSuccess && current.isSuccess
            prev.outputValues.contactsFound = prev.outputValues.contactsFound.concat(
              current.outputValues.contactsFound
            )
          } else {
            prev.isSuccess = false
            if (!prev.errors) {
              prev.errors = []
            }
            prev.errors = prev.errors.concat(current.errors)
          }
          return prev
        },
        {
          actionName: 'App_Find_contacts',
          errors: null,
          isSuccess: true,
          outputValues: {
            contactsFound: [],
            Flow__InterviewStatus: 'Finished'
          }
        }
      )
    ])
}

export const getContactsMap = ids => {
  const conn = SFAuthService.getConnection()
  if (ids.length === 0) {
    return Promise.resolve([])
  }
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Find_contacts', {
      inputs: [
        {
          ids
        }
      ]
    })
    .then(contactsFlow => {
      const contacts = contactsFlow[0].outputValues.contactsFound.filter(v => v)
      const toRet = {}
      contacts.forEach(contact => {
        toRet[contact.Id] = { ...contact }
      })
      return toRet
    })
}

export const createContact = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Create_New_Contact', {
      inputs: [
        {
          ...values
        }
      ]
    })
    .then(flowResult => flowResult[0].outputValues)
}

export const saveContactFromLookupField = ({
  objectName,
  objectId,
  fieldName,
  fieldValue
}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost(
    '/actions/custom/flow/App_Save_Contact_Lookup_Field',
    {
      inputs: [
        {
          objectName,
          objectId,
          fieldName,
          fieldValue
        }
      ]
    }
  )
}

/*
jak jest email to szuka po emailu
jak nie znajdzie to sprawdza czy podane byly first AND last name
jak byly to szuka po nich
jak nie byly to NOT_FOUND
jak znajdzie 1 kontakt to go podaje
jak znajdzie 2 lub wiecej to MULTIPLE_MATCHES

 */

export const checkContact = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Find_Contact', {
    inputs: [
      {
        ...values
      }
    ]
  })
}
/*
w odpowiedzi dostajesz maximum 3 obiekty i info ile ich bylo w sumie
[01:20] Nembrot: jak jest wiecej niz 1 to error jest na MORE_MATCHES ale odpowiedz dostaniesz
[01:20] Nembrot: organizacje nie sa tak krytyczne jak contacty do ludzi
[01:21] Nembrot: w odpowiedzi wysyalm ci [{id, name}]
 */
export const checkAccount = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Find_Account', {
    inputs: [
      {
        name: 'déjà'

        // email: 'daisy+4@magnaro.com',
        // email: null,
        // website: ''
      }
    ]
  })
}

export const setAccountOwnership = (userId, contactId) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Set_Contact_Ownership', {
    inputs: [
      {
        contactId: contactId,
        userId: userId
      }
    ]
  })
}

export const setReviewerStatus = userId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost(
    '/actions/custom/flow/App_Add_to_External_Reviewers_Group',
    {
      inputs: [
        {
          userId: userId
        }
      ]
    }
  )
}
