import { TextField } from '@material-ui/core'
import { FastField } from 'formik'
import React from 'react'

export function FormikFastTextField ({ name, ...props }) {
  return (
    <FastField name={name}>
      {({ field }) => {
        return (
          <TextField
            {...field}
            value={field.value || ''}
            variant='outlined'
            fullWidth
            {...props}
          />
        )
      }}
    </FastField>
  )
}
