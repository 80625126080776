import React, { useCallback, useEffect, useState } from 'react'
import {
  defaultConfigurationICCE,
  fieldsFromList,
  OpportunityView,
  usePrintStyles
} from '../opportunity/OpportunityView'
import SurveyTab from '../surveys/SurveyTab'
import Loading from '../../../egret/components/EgretLoadable/Loading'
import { useSnackbar } from 'notistack'
import {
  DATA_LIST,
  DATA_ON_BOTTOM,
  DATA_ON_TOP,
  FUND_IMAGE,
  GRID,
  HEADER,
  LIST_TO_OBJECTS,
  PAGE_BREAK,
  PAPER
} from '../opportunity/opportunityViewComponents'
import { t, Trans } from '@lingui/macro'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  getReportParsed,
  getReportsForOpportunity,
  reportIsEditable,
  sanitizeReportType,
  saveReport,
  saveReportFromFlow
} from '../../services/sfAuth/sfData/sfReports'
import {
  getOpportunity,
  MILESTONE_TRANSLATIONS,
  oppToStepper,
  STF_LP_RECORD_TYPE,
  STF_SIP_RECORD_TYPE
} from '../../services/sfAuth/sfData/sfOpportunity'
import {
  addSurvey,
  deleteSurvey,
  getSurveys,
  getSurveyTemplateDetails,
  surveyMapping
} from '../../services/sfAuth/sfData/sfSurvey'
import { connect, useSelector } from 'react-redux'
import { globalValues } from '../../utils/GlobalValues'
import { TwoSidedScreen } from '../../utils/TwoSidedScreen'
import moment from 'moment'
import { CurrencyFormated } from '../common/Formats'
import { fetchFormPage } from 'app/services/sfAuth/sfData/sfForms'
import Form from '../forms/Form'
import sfOauthConfig from '../../services/sfAuth/sfAuthConfig'
import {
  getDocumentsByEntity,
  parseDocument
} from '../../services/sfAuth/sfData/sfFiles'
import { constructFormAddressString } from '../forms/components/Common'
import { dateFormat } from 'app/appSettings'
import { matchOpportunityWithConfiguration } from '../internal/ConfigureApplicationTypes'
import { printStyles } from './ApplicationsList'

export const granteeReportConfiguration = ({
  files,
  classes,
  pmView,
  applicationList,
  granteeReview
}) => {
  return {
    fields: [
      {
        type: HEADER,
        text: (
          <span className={classes.mainTitle}>
            <Trans>Application Details</Trans>
          </span>
        )
      },
      {
        type: GRID,
        fields: [
          {
            type: PAPER,
            style: {
              width: '100%',
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 20,
              padding: 15,
              flexWrap: 'nowrap'
            },
            paperClass: classes.titleCard,
            fields: [
              {
                type: GRID,
                // flexWrap: '',
                justify: 'center',
                gridClass: classes.titleGrid,
                fields: [
                  {
                    type: FUND_IMAGE,
                    imageClass: classes.imageLogo,
                    fundTypeName: opp => opp.info.fundTypeName
                  },
                  {
                    type: GRID,
                    direction: 'column',
                    style: {
                      width: '300px',
                      marginLeft: '20px',
                      height: 'auto'
                    },
                    fields: [
                      {
                        type: DATA_ON_TOP,
                        gridClass: classes.nameGrid,
                        data: opp => opp.projectDetails.projectName,
                        subtitle: opp => (
                          <span className={classes.subtitle}>
                            <Trans id={opp.info.fundTypeName} />
                          </span>
                        )
                      },
                      {
                        type: GRID,
                        alignItems: 'center',
                        fields: [
                          {
                            type: DATA_ON_TOP,
                            style: { width: 150 },
                            disabled: opp => !pmView,
                            data: opp => (
                              <CurrencyFormated
                                value={opp.projectDetails.amountRequested}
                              />
                            ),
                            subtitle: (
                              <span className={classes.subtitle}>
                                <Trans>Amount Requested</Trans>
                              </span>
                            )
                          },
                          {
                            type: DATA_ON_TOP,
                            style: { width: 150 },
                            data: opp => (
                              <CurrencyFormated
                                value={opp.projectDetails.grantAwarded}
                              />
                            ),
                            subtitle: (
                              <span className={classes.subtitle}>
                                <Trans>Amount awarded</Trans>
                              </span>
                            )
                          },
                          {
                            type: DATA_ON_TOP,
                            style: { width: 150 },
                            data: opp => (
                              <CurrencyFormated value={opp.info.budgetTotal} />
                            ),
                            subtitle: (
                              <span className={classes.subtitle}>
                                <Trans>Overall Project Budget</Trans>
                              </span>
                            )
                          },
                          {
                            type: DATA_ON_TOP,
                            style: { width: 150 },
                            data: opp => (
                              <CurrencyFormated
                                value={opp.projectDetails.amountRecieved}
                              />
                            ),
                            subtitle: (
                              <span className={classes.subtitle}>
                                <Trans>Amount received so far</Trans>
                              </span>
                            )
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: PAPER,
            style: {
              width: '100%',
              marginLeft: 20,
              marginRight: 20,
              padding: 15
            },
            paperClass: classes.programManagerCard,
            fields: [
              {
                type: GRID,
                // gridClass: classes.gridContainerStretch,
                alignItems: 'center',
                justify: 'flex-start',
                fields: [
                  {
                    type: DATA_ON_TOP,
                    lg: 6,
                    data: opp => opp.info.pmName,
                    subtitle: <Trans>Program Manager Name</Trans>
                  },
                  {
                    type: DATA_ON_TOP,
                    lg: 6,
                    data: opp =>
                      opp.info.pmContact
                        ? opp.info.pmContact.split(';')[0]
                        : '',
                    subtitle: <Trans>Program Manager Email</Trans>
                  }
                  // {
                  //   type: DATA_ON_TOP,
                  //   lg: 4,
                  //   data: opp =>
                  //     opp.info.pmContact ? opp.info.pmContact.split(';')[1] : '',
                  //   subtitle: <Trans>Program Manager Phone</Trans>
                  // }
                ]
              }
            ]
          }
        ]
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        data: opp => opp.projectDetails.description,
        subtitle: <Trans>Description</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretchNoBreak,
        paperClass: classes.dataContainer,
        data: opp => (
          <div
            dangerouslySetInnerHTML={{
              __html: opp.projectDetails.detailedDescription
            }}
          />
        ),
        subtitle: <Trans>Detailed Description</Trans>
      },
      {
        type: PAGE_BREAK
      },
      {
        type: HEADER,
        style: { fontSize: 14, fonWeight: 'bold' },
        disabled: opp => opp.projectDetails.grants.length === 0,
        text: (
          <span className={classes.mainTitle}>
            <Trans>Other Grants</Trans>
          </span>
        )
      },
      {
        type: LIST_TO_OBJECTS,
        list: opp => opp.projectDetails.grants,
        conf: {
          fields: [
            {
              type: GRID,
              style: { marginBottom: 20, paddingLeft: 20, paddingRight: 20 },
              gridClass: classes.gridContainer,
              justify: 'center',
              fields: [
                {
                  type: DATA_ON_BOTTOM,
                  paperClass: classes.otherGrantsGrid,
                  paperStyle: { minHeight: 90 },
                  lg: 4,
                  data: item => (item ? item.name : ''),
                  subtitle: <Trans>Name</Trans>
                },
                {
                  type: DATA_ON_BOTTOM,
                  paperClass: classes.otherGrantsGrid,
                  paperStyle: { minHeight: 90 },
                  lg: 4,
                  data: item =>
                    item ? <CurrencyFormated value={item.value} /> : '',
                  subtitle: <Trans>Value</Trans>
                },
                {
                  type: DATA_ON_BOTTOM,
                  paperClass: classes.otherGrantsGrid,
                  paperStyle: { minHeight: 90 },
                  lg: 4,
                  data: item => (item ? <Trans id={item.state} /> : ''),
                  subtitle: <Trans>Grants: State</Trans>
                }
              ]
            }
          ]
        }
      },
      // ...fieldsFromList(
      //   [
      //     {
      //       field: 'projectDetails.grantEssential',
      //       paperClassName: classes.dataContainer,
      //       subtitle: <Trans>How will the Centre's grant be essential</Trans>
      //     },
      //     {
      //       field: 'projectDetails.partners',
      //       subtitle: <Trans>Partners</Trans>
      //     },
      //     {
      //       field: 'projectDetails.challenges',
      //       subtitle: <Trans>Challenges</Trans>
      //     }
      //   ],
      //   classes.dataContainer
      // ),
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        // style: { padding: 20 },
        data: opp => opp.projectDetails.grantEssential,
        subtitle: <Trans>How will the Centre's grant be essential</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        // style: { padding: 20 },
        data: opp => opp.projectDetails.partners,
        subtitle: <Trans>Partners</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        // style: { padding: 20 },
        data: opp => opp.projectDetails.challenges,
        subtitle: <Trans>Challenges</Trans>
      },
      {
        type: GRID,
        gridClass: classes.gridContainer,
        justify: 'center',
        fields: [
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'accessibility_new',
            subtitle: <Trans>Centre's priority</Trans>,
            data: opp => opp.projectDetails.priorityAreas
          },
          {
            type: DATA_ON_BOTTOM,
            gridClass: classes.gridContainerStretch,
            paperClass: classes.dataContainer,
            // style: { padding: 20 },
            data: opp => opp.projectDetails.priorityAreasDescription,
            subtitle: <Trans>Priority Areas Description</Trans>
          },
          {
            type: DATA_LIST,
            bulletIcon: 'public',
            paperClass: classes.dataList,
            subtitle: <Trans>Scope</Trans>,
            data: opp => opp.projectDetails.geographicalScopeType
          },
          {
            type: DATA_ON_BOTTOM,
            gridClass: classes.gridContainerStretch,
            paperClass: classes.dataContainer,
            // style: { padding: 20 },
            data: opp => opp.projectDetails.geographicalScopeDetails,
            subtitle: <Trans>Geographical Location</Trans>
          },
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            // style: { padding: '15' },
            bulletIcon: 'accessibility_new',
            subtitle: <Trans>Housing providers type beneficiaries</Trans>,
            data: opp => opp.projectDetails.housingProviders
          },
          // old demographic if filled
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            disabled: opp =>
              !opp.projectDetails.demographic ||
              opp.projectDetails.demographic.length === 0,
            bulletIcon: 'supervised_user_circle',
            subtitle: <Trans>Demographic</Trans>,
            data: opp => opp.projectDetails.demographic
          }
        ]
      },
      // new demographic if old is empty
      {
        type: GRID,
        gridClass: classes.gridContainer,
        disabled: opp =>
          !(
            !opp.projectDetails.demographic ||
            opp.projectDetails.demographic.length === 0
          ),
        justify: 'center',
        fields: [
          // check hasTargetDemographic
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'supervised_user_circle',
            style: { width: '100%' },
            subtitle: <Trans>Target demographic (application)</Trans>,
            data: opp =>
              pmView || applicationList
                ? opp.projectDetails.targetDemographic
                : opp.projectDetails.targetDemographicPM
          },
          {
            type: DATA_ON_BOTTOM,
            paperClass: classes.dataContainer,
            gridClass: classes.gridContainerStretch,
            // style: { padding: 20 },
            dataClass: 'largeText',
            disabled: opp =>
              Boolean(!applicationList && !pmView) ||
              !opp.projectDetails.otherTargetDemographic,
            data: opp => opp.projectDetails.otherTargetDemographic,
            subtitle: <Trans>Other - target demographic</Trans>
          },
          {
            type: HEADER,
            style: { fontSize: 18, fonWeight: 'bold', width: '100%' },
            text: item => <Trans>Units identified for each group:</Trans>
          },
          {
            type: GRID,
            disabled: opp => granteeReview,
            gridClass: classes.gridContainerStretch,
            justify: 'center',
            fields: [
              ...fieldsFromList(
                [
                  {
                    field: 'projectDetails.unitsWomen',
                    subtitle: <Trans>Units - women and children</Trans>
                  },
                  {
                    field: 'projectDetails.unitsFleeingDomesticViolence',
                    subtitle: <Trans>Units - fleeing domestic violence</Trans>
                  },
                  {
                    field: 'projectDetails.unitsSingleParent',
                    subtitle: <Trans>Units - single parent</Trans>
                  },
                  {
                    field: 'projectDetails.unitsIndigenous',
                    subtitle: <Trans>Units - Indigenous</Trans>
                  },
                  {
                    field: 'projectDetails.unitsRacializedPersons',
                    subtitle: <Trans>Units - racialized persons</Trans>
                  }
                ],
                classes.gridChild,
                { paddingLeft: 10 }
              )
            ]
          },

          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'supervised_user_circle',
            subtitle: <Trans>Supported demographics</Trans>,
            style: { width: '100%' },
            data: opp => opp.projectDetails.supportedDemographic
          },
          {
            // check hasSupportedDemographic
            type: DATA_ON_BOTTOM,
            paperClass: classes.dataContainer,
            gridClass: classes.gridContainerStretch,
            dataClass: 'largeText',
            data: opp => opp.projectDetails.otherSupportedDemographic,
            disabled: opp => !opp.projectDetails.otherSupportedDemographic,
            subtitle: <Trans>Other - supported demographic</Trans>
          }
        ]
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        disabled: opp =>
          opp.info.fundTypeName === STF_SIP_RECORD_TYPE ||
          opp.info.fundTypeName === STF_LP_RECORD_TYPE,
        data: opp => opp.projectDetails.tenantsDescription,
        subtitle: <Trans>Tenant Questions</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        disabled: opp =>
          opp.info.fundTypeName === STF_SIP_RECORD_TYPE ||
          opp.info.fundTypeName === STF_LP_RECORD_TYPE,
        data: opp => opp.projectDetails.tenantsInvolvement,
        subtitle: <Trans>Social Inclusion</Trans>
      },
      {
        type: GRID,
        gridClass: classes.gridContainerStretch,
        justify: 'center',
        fields: [
          ...fieldsFromList(
            [
              {
                field: 'projectDetails.impactOrganization',
                subtitle: <Trans>Numbers of organization affected</Trans>
              },
              {
                field: 'projectDetails.impactBuildings',
                subtitle: <Trans>Number of buildings affected</Trans>
              },
              {
                field: 'projectDetails.impactUnits',
                subtitle: <Trans>Number of units affected</Trans>
              },
              {
                field: 'projectDetails.impactStaff',
                subtitle: <Trans>Number of staff affected</Trans>
              },
              {
                field: 'projectDetails.tenantsAffected',
                subtitle: <Trans>Number of Tenants affected</Trans>
              }
            ],
            classes.gridChild,
            { padding: 10 }
          )
        ]
      },
      ...fieldsFromList(
        [
          {
            field: 'projectDetails.impactSustainable',
            gridClassName: classes.gridContainerStretch,
            subtitle: <Trans>Transformational impact description</Trans>
          },
          {
            field: 'projectDetails.sharingDescription',
            gridClassName: classes.gridContainerStretch,
            subtitle: <Trans>Knowledge transfer</Trans>
          }
        ],
        classes.dataContainer
      ),
      {
        type: HEADER,
        pageBreakBefore: true,
        disabled: opp => opp.objectives.objectives.length === 0,
        style: { fontSize: 24, fonWeight: 'bold' },
        text: (
          <span className={classes.mainTitle}>
            <Trans>Objectives</Trans>
          </span>
        )
      },
      {
        type: LIST_TO_OBJECTS,
        list: opp => opp.objectives.objectives,
        conf: {
          type: PAPER,
          fields: [
            {
              type: GRID,
              gridClass: classes.gridContainerStretch,
              paperClass: classes.dataContainer,
              justify: 'center',
              // style: { padding: 20 },
              fields: [
                {
                  type: DATA_ON_BOTTOM,
                  gridClass: classes.gridObjectives,
                  paperClass: classes.paperContainerStretch,
                  data: item => (item ? <Trans id={item.objective} /> : ''),
                  subtitle: <Trans>Objective</Trans>
                },
                {
                  type: DATA_ON_BOTTOM,
                  gridClass: classes.gridObjectives,
                  paperClass: classes.paperContainerStretch,
                  data: item => (item ? item.needs : ''),
                  subtitle: <Trans>Needs</Trans>
                },
                {
                  type: DATA_LIST,
                  style: { margin: 0 },
                  paperClass: classes.paperContainerStretch,
                  bulletIcon: 'fiber_manual_record',
                  data: item => (item ? item.outcomes : []),
                  subtitle: <Trans>Outcomes</Trans>
                }
              ]
            }
          ]
        }
      },
      {
        type: PAGE_BREAK
      },
      {
        type: HEADER,
        style: { fontSize: 24 },
        text: item => (
          <span className={classes.mainTitle}>
            <Trans>Benchmarks</Trans>
          </span>
        )
      },
      {
        type: LIST_TO_OBJECTS,
        list: opp => opp.milestones.milestones,
        conf: {
          type: PAPER,
          fields: [
            {
              type: GRID,
              // style: { width: 'auto', margin: 0 },
              direction: 'column',
              gridClass: classes.gridContainerStretch,
              paperClass: classes.dataContainer,
              style: { marginBottom: 0 },
              // style: { width: 'auto' },
              fields: [
                {
                  type: HEADER,
                  style: { fontSize: 18, fonWeight: 'bold' },
                  // text: <Trans>Budget Revenues</Trans>
                  text: item => (
                    <Trans id={MILESTONE_TRANSLATIONS[item.stage]} />
                  )
                },
                {
                  type: GRID,
                  gridClass: classes.gridContainerStretch,
                  paperClass: classes.dataContainer,
                  style: { margin: 0, padding: 0 },
                  fields: [
                    {
                      type: DATA_ON_BOTTOM,
                      lg: 6,
                      dataClass: 'largeText',
                      paperClass: classes.gridChildHalfWidth,
                      data: opp => moment(opp.startDate).format('DD/MM/YYYY'),
                      subtitle: <Trans>Start Date</Trans>
                    },
                    {
                      type: DATA_ON_BOTTOM,
                      lg: 6,
                      dataClass: 'largeText',
                      paperClass: classes.gridChildHalfWidth,
                      data: opp => moment(opp.endDate).format('DD/MM/YYYY'),
                      subtitle: <Trans>End Date</Trans>
                    }
                  ]
                }
              ]
            },
            {
              type: GRID,
              style: { width: 'auto', margin: 0 },
              gridClass: classes.gridContainerStretch,
              paperClass: classes.dataContainer,
              fields: [
                {
                  type: DATA_ON_BOTTOM,
                  paperClass: classes.paperContainerStretch,
                  data: item => item.primaryActivities,
                  subtitle: <Trans>Primary Activities</Trans>
                },
                {
                  type: DATA_ON_BOTTOM,
                  paperClass: classes.paperContainerStretch,
                  data: item => item.comments,
                  subtitle: <Trans>Comments</Trans>
                }
              ]
            }
          ]
        }
      },
      {
        type: HEADER,
        pageBreakBefore: true,
        disabled: opp => Object.entries(opp.budget.revenues).length === 0,
        style: { fontSize: 24, fonWeight: 'bold' },
        text: (
          <span className={classes.mainTitle}>
            <Trans>Budget Revenues</Trans>
          </span>
        )
      },
      {
        type: LIST_TO_OBJECTS,
        list: opp =>
          Object.entries(opp.budget.revenues).filter(revenue => {
            const milestones = opp.milestones.milestones
            const endMilestone = milestones[milestones.length - 1]
            if (!endMilestone) {
              return false
            }
            const year = revenue[0]
            const endYear = moment(endMilestone.endDate).year()
            return year <= endYear
          }),
        conf: {
          fields: [
            {
              type: PAPER,
              style: { width: 'auto', margin: 0, padding: 10 },
              fields: [
                {
                  type: DATA_ON_BOTTOM,
                  dataClass: 'largeText',
                  paperClass: classes.paperContainerStretch,
                  data: ([year, budget]) => year,
                  subtitle: <Trans>Year</Trans>
                },
                {
                  type: LIST_TO_OBJECTS,
                  list: ([year, budget]) => Object.entries(budget).reverse(),
                  conf: {
                    fields: [
                      {
                        type: GRID,
                        gridClass: classes.gridContainer,
                        fields: [
                          {
                            type: DATA_ON_TOP,
                            gridClass: classes.gridChildTable4,
                            lg: 4,
                            data: ([name, item]) => <Trans id={name} />,
                            subtitle: <Trans>Budget Item</Trans>
                          },
                          {
                            type: DATA_ON_TOP,
                            gridClass: classes.gridChildTable2,
                            lg: 8,
                            data: ([name, item]) => (
                              <CurrencyFormated value={item.value} />
                            ),
                            subtitle: <Trans>Revenue</Trans>
                          }
                          // {
                          //   type: DATA_ON_TOP,
                          //   lg: 4,
                          //   data: ([name, item]) => item.comment,
                          //   subtitle: <Trans>Comment</Trans>
                          // },
                        ]
                      }
                    ]
                  }
                }
              ]
            }
          ]
        }
      },
      {
        type: HEADER,
        pageBreakBefore: true,
        disabled: opp => Object.entries(opp.budget.expenses).length === 0,
        text: (
          <span className={classes.mainTitle}>
            <Trans>Budget Expenses</Trans>
          </span>
        ),
        style: { fontSize: 24, fonWeight: 'bold' }
      },
      {
        type: LIST_TO_OBJECTS,
        list: opp => {
          return Object.entries(opp.budget.expenses).filter(expense => {
            const milestones = opp.milestones.milestones
            const endMilestone = milestones[milestones.length - 1]
            if (!endMilestone) {
              return false
            }
            const year = expense[0]
            const endYear = moment(endMilestone.endDate).year()
            return year <= endYear
          })
        },
        conf: {
          fields: [
            {
              type: PAPER,
              // paperClass: classes.paperContainerStretch,
              style: { width: 'auto', margin: 0, padding: 10 },
              fields: [
                {
                  type: DATA_ON_BOTTOM,
                  paperClass: classes.paperContainerStretch,
                  dataClass: 'largeText',
                  data: ([year, budget]) => year,
                  subtitle: <Trans>Year</Trans>
                },
                {
                  type: LIST_TO_OBJECTS,
                  list: ([year, budget]) => Object.entries(budget).reverse(),
                  conf: {
                    fields: [
                      {
                        type: GRID,
                        gridClass: classes.gridContainer,
                        fields: [
                          {
                            type: DATA_ON_TOP,
                            gridClass: classes.gridChildTable1,
                            lg: 4,
                            data: ([name, item]) => <Trans id={name} />,
                            subtitle: <Trans>Budget Item</Trans>
                          },
                          {
                            type: DATA_ON_TOP,
                            gridClass: classes.gridChildTable2,
                            lg: 4,
                            data: ([name, item]) => (
                              <CurrencyFormated value={item.value} />
                            ),
                            subtitle: <Trans>Expense</Trans>
                          },
                          {
                            type: DATA_ON_TOP,
                            gridClass: classes.gridChildTable3,
                            lg: 4,
                            data: ([name, item]) => item.comment,
                            subtitle: <Trans>Comment</Trans>
                          }
                        ]
                      }
                    ]
                  }
                }
              ]
            }
          ]
        }
      }
    ]
  }
}

// TODO move some to theme

export const opportunityToReportTemplateConfigurationVar = recordTypeId => {
  for (const key in globalValues.reportRecordTypes) {
    if (globalValues.reportRecordTypes[key].recordTypeId === recordTypeId) {
      return globalValues.reportRecordTypes[key]
    }
  }
}

const styles = theme => printStyles(theme)
const useStyles = makeStyles(styles)

const GranteeReport = ({ ...props }) => {
  const [application, setApplication] = useState(null)
  const [applicationToSurveysConditional, setApplicationToSurveysConditional] =
    useState(null)
  const [report, setReport] = useState(null)
  const [survey, setSurvey] = useState(null)
  const [isInvalid, setIsInvalid] = useState(false)
  const [form, setForm] = useState(null)
  // const [isOwner, setIsOwner] = useState(true)
  // const [warning, setWarning] = useState(false)
  const configuration = useSelector(state => state.configuration)
  // const [survey, setSurvey] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const reportId = props.match.params.id
  const { mode } = props.match.params
  const classes = useStyles()
  const userId = props.user.userId
  const frenchLang = props.user.language !== 'en_US'
  const printClasses = usePrintStyles()

  useEffect(() => {
    console.log('report', reportId, globalValues.reportRecordTypes)
    if (reportId) {
      enqueueSnackbar(<Trans>Loading Report</Trans>, {
        variant: 'info'
      })
      getReportParsed(reportId)
        .then(reportObj => {
          console.log('got report', reportObj, globalValues.reportRecordTypes)
          const templateName = reportObj.type
          console.log('template Name', templateName)
          if (!templateName || templateName === 'Narrative and Financial') {
            console.error('Invalid record type')
            setIsInvalid(true)
            return Promise.resolve()
          }

          return getOpportunity(reportObj.opportunity).then(opportunity => {
            let templateId =
              configuration[surveyMapping[templateName].configuration.key]
            let formId
            const applicationTypes = configuration.AVAILABLE_APPLICATIONS || []
            const config = matchOpportunityWithConfiguration({
              fundingStreamId: opportunity.Funding_Stream__c,
              recordTypeId: opportunity.RecordTypeId,
              configurations: applicationTypes
            })
            if (config) {
              formId = config.granteeReportForm
              if (
                reportObj.recordType.name === 'Final Report' &&
                config.granteeFinalReportSurveyTemplate
              ) {
                templateId = config.granteeFinalReportSurveyTemplate
              } else if (
                reportObj.recordType.name === 'Midterm Report' &&
                config.granteeProgressReportSurveyTemplate
              ) {
                templateId = config.granteeProgressReportSurveyTemplate
              }
            }
            return Promise.all([
              getDocumentsByEntity(reportObj.opportunity),
              getSurveys({
                searchParams: reportObj.surveyId
                  ? {
                      Id: reportObj.surveyId
                    }
                  : {
                      Template__c: templateId,
                      Report__c: reportObj.id
                    },
                searchByFlow: mode === 'view'
              }),
              getReportsForOpportunity(reportObj.opportunity)
            ]).then(([files, surveys, reportsForOpportunity]) => {
              console.log(
                'got reports for opportunity',
                reportsForOpportunity,
                opportunity
              )
              if (!opportunity.uploadFiles) {
                opportunity.uploadFiles = {}
              }
              opportunity.uploadFiles.list = files.map(item =>
                parseDocument(item)
              )
              const sortedReports = reportsForOpportunity
                .filter(
                  report =>
                    report.FGM_Base__Due_Date__c &&
                    report.RecordTypeId === reportObj.recordTypeId
                )
                .sort((a, b) => {
                  const val1Date = moment.utc(a.FGM_Base__Due_Date__c)
                  const val2Date = moment.utc(b.FGM_Base__Due_Date__c)
                  return val1Date.diff(val2Date, 'minutes')
                })
              const opportunityToSurveyFields = oppToStepper(opportunity, true)
              reportsForOpportunity = reportsForOpportunity.sort((a, b) => {
                const val1Date = moment.utc(a.FGM_Base__Due_Date__c)
                const val2Date = moment.utc(b.FGM_Base__Due_Date__c)
                return val1Date.diff(val2Date, 'minutes')
              })
              opportunity = oppToStepper(opportunity)
              const milestones = opportunity.milestones.milestones
              reportObj.firstReportDate =
                reportsForOpportunity[0].Id === reportId
                  ? milestones
                    ? milestones[0].startDate
                    : null
                  : null
              let reportIndex
              sortedReports.some((report, index) => {
                if (sortedReports.length <= 1) {
                  return true
                }
                if (report.Id === reportId) {
                  reportIndex = index + 1
                }
                return report.Id === reportId
              })
              reportObj.reportIndex = reportIndex
              console.log('loaded report', reportObj)
              console.log('loaded opportunity', opportunity)
              console.log('loaded surveys', surveys)

              const addNewSurvey = toDelete => {
                addSurvey(
                  {
                    reportId: reportObj.id,
                    userId: userId,
                    opportunityId: opportunity.info.id,
                    templateId,
                    name: sanitizeReportType(reportObj.type)
                  },
                  configuration
                ).then(surveyCreated => {
                  console.log('createdSurvey', surveyCreated)
                  const createdSurvey = surveyCreated[0].outputValues.Survey
                  getSurveyTemplateDetails(createdSurvey.Template__c).then(
                    result => {
                      setSurvey({
                        nameEN: result.titleDetails.en,
                        nameFR: result.titleDetails.fr,
                        lastModifiedDate: moment.utc(),
                        surveyId: createdSurvey.Id,
                        templateId: createdSurvey.Template__c,
                        disabled: false
                      })
                      saveReportFromFlow({
                        Id: reportObj.id,
                        Survey__c: createdSurvey.Id
                      })
                      if (toDelete) {
                        deleteSurvey(toDelete)
                      }
                      enqueueSnackbar(<Trans>Created Report Survey</Trans>, {
                        variant: 'info'
                      })
                    }
                  )
                })
              }

              if (surveys.length === 0) {
                if (reportObj.surveyId) {
                  enqueueSnackbar(
                    <Trans>
                      You don't have access to this survey! Contact your
                      administrator
                    </Trans>,
                    {
                      variant: 'error'
                    }
                  )
                } else {
                  enqueueSnackbar(<Trans>Creating Report Survey</Trans>, {
                    variant: 'info'
                  })
                  addNewSurvey()
                }
              } else if (surveys.length === 1) {
                const { survey } = surveys[0]
                const surveyTemplateId = survey.Template__c
                if (
                  !survey.Last_Modified_By_User_Date__c &&
                  surveyTemplateId !== templateId
                ) {
                  addNewSurvey(survey.Id)
                } else {
                  getSurveyTemplateDetails(surveyTemplateId).then(result => {
                    setSurvey({
                      nameEN: result.titleDetails.en,
                      nameFR: result.titleDetails.fr,
                      lastModifiedDate:
                        survey.Last_Modified_By_User_Date__c ||
                        survey.LastModifiedDate,
                      surveyId: survey.Id,
                      templateId: survey.Template__c,
                      disabled: !reportIsEditable(reportObj.status)
                    })
                    saveReport({
                      Survey__c: survey.Id
                    })
                  })
                }
              } else {
                enqueueSnackbar(<Trans>Too Many Surveys</Trans>, {
                  variant: 'error'
                })
              }
              if (formId) {
                return fetchFormPage(formId).then(form => {
                  setForm(form)
                  setApplication(opportunity)
                  setReport(reportObj)
                  setApplicationToSurveysConditional({
                    ...opportunityToSurveyFields,
                    reportId: reportId,
                    id: opportunity.info.id
                  })
                })
              } else {
                setApplication(opportunity)
                setReport(reportObj)
                setApplicationToSurveysConditional({
                  ...opportunityToSurveyFields,
                  reportId: reportId,
                  id: opportunity.info.id
                })
              }
            })
          })
        })
        .catch(error => {
          console.error('errorloading report', error)
          enqueueSnackbar(<Trans>Error loading Report</Trans>, {
            variant: 'error'
          })
        })
    }
  }, [reportId])

  const saveCallback = useCallback(
    values => {
      return saveReportFromFlow({
        Id: report.id,
        Survey__c: survey.surveyId,
        Last_Modified_By_User_Date__c: moment.utc()
      }).then(result => {
        if (result) {
          if (result[0] && !result[0].isSuccess) {
            enqueueSnackbar(<Trans>Error saving grantee report</Trans>, {
              variant: 'error'
            })
            return Promise.reject(new Error('Error saving report'))
          }
        }
      })
    },
    [report, survey]
  )

  const submitCallback = useCallback(
    values => {
      const newData = {
        id: report.id,
        status: 'Submitted'
      }
      return saveReportFromFlow({
        Id: report.id,
        Survey__c: survey.surveyId,
        FGM_Base__Status__c: 'Submitted'
      })
        .then(result => {
          if (result) {
            if (result[0] && !result[0].isSuccess) {
              return Promise.reject(new Error('Error saving report'))
            }
          }
          enqueueSnackbar(<Trans>Submitted Report</Trans>, {
            variant: 'success'
          })
          setReport({ ...report, ...newData })
          setSurvey({ ...survey, disabled: true })
        })
        .catch(error => {
          console.log('error Saving report', error, newData)
          enqueueSnackbar(<Trans>Error Submitting Report</Trans>, {
            variant: 'error'
          })
        })
    },
    [report, survey]
  )

  console.log('survey data', survey)

  if (isInvalid) {
    return (
      <div style={{ padding: 20, textAlign: 'center' }}>
        <h3 style={{ color: 'red' }}>
          <Trans>This report's record type in invalid!</Trans>
        </h3>
      </div>
    )
  }

  if (!report || !application || !survey || !applicationToSurveysConditional) {
    return <Loading />
  }

  let reportPeriodStart = null
  if (report.firstReportDate) {
    reportPeriodStart = moment.utc(report.firstReportDate)
  } else {
    reportPeriodStart = moment.utc(
      application.info.contractSigned || report.submissionDate
    )
  }

  let printTitle = frenchLang ? survey.nameFR : survey.nameEN
  printTitle +=
    '_' +
    application.info.name +
    '_' +
    moment.utc(survey.lastModifiedDate).format(dateFormat)

  return (
    <TwoSidedScreen
      left={
        form ? (
          <Form
            formId={form.id}
            fetchString={constructFormAddressString({
              user: props.user,
              reportId: report.id,
              organizationId: application.info.account,
              opportunity: application.info.id,
              objectsConnected: form.objectsConnected
            })}
            fixedDisplay
            displayView
            disablePDF
          />
        ) : (
          <OpportunityView
            displayPrint
            opportunity={application}
            committeeType='External Review Accept'
            configuration={
              sfOauthConfig.isIcce
                ? defaultConfigurationICCE({
                    classes: printClasses,
                    data: application
                  })
                : granteeReportConfiguration({
                    classes,
                    granteeReview: true
                  })
            }
          />
        )
      }
      right={
        <>
          {report.status === 'Scheduled' && (
            <div
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 28,
                marginTop: 20
              }}
            >
              <Trans>
                This report is scheduled. You cannot fill the survey yet!
              </Trans>
            </div>
          )}
          <SurveyTab
            granteeReportPeriodStart={reportPeriodStart}
            opportunity={applicationToSurveysConditional}
            embedded
            granteeReport={report}
            printTitle={printTitle}
            viewMode={mode}
            // submitDisabled={!isOwner}
            disabled={survey.disabled || report.status === 'Scheduled'}
            templateId={survey.templateId}
            surveyId={survey.surveyId}
            opportunityId={application.id}
            saveCallback={saveCallback}
            submitCallback={submitCallback}
          />
        </>
      }
    />
  )
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(GranteeReport)
