/**
 * Created by Michał Stawski on 24.04.2020.
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Form, Formik } from 'formik'
import {
  QuestionWidget,
  fixedQuestionAnswers,
  questionTypes
} from './QuestionWidget'
import Button from '@material-ui/core/Button'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
  withStyles
} from '@material-ui/core'
import {
  addSurvey,
  changeSurveyLock,
  findCompletedSurveys,
  getSubtemplates,
  getSurveyByFlow,
  getSurveyTemplateDetails,
  isConfiguredTemplate,
  SATsIdsList,
  saveSurveyData,
  surveyConfigurationVariables,
  surveyMapping,
  templateIdToMapping,
  surveyItemOptionsFields
} from 'app/services/sfAuth/sfData/sfSurvey'
import { connect, useSelector } from 'react-redux'
import Loading from 'egret/components/EgretLoadable/Loading'
import { useSnackbar } from 'notistack'
import { t, Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import PopUpDialogue from '../grants/PopUpDialogue'
import * as Yup from 'yup'
import { SurveyTabSatTopCard } from './sat/SurveyTabSatTopCard'
import {
  dateRangeRequiredTrans,
  questionRequiredTransWithStar
} from '../forms/formTranslations'
import FormikCheckboxField from 'formik-material-fields/lib/FormikCheckboxField/FormikCheckboxField'
import { I18n } from '@lingui/react'
import { HEADER } from '../opportunity/opportunityViewComponents'
import { questionValidationRules } from './ValidationRulesField'
import moment from 'moment'
import { getNetwork } from 'app/services/sfAuth/sfData/sfUser'
import { getDocumentsByEntity } from 'app/services/sfAuth/sfData/sfFiles'
import ReactToPrint from 'react-to-print'
import {
  surveyToPrint,
  questionToMultilanguageText
} from 'app/utils/survey/surveyToRTF'
import { fundLogos } from '../grants/ReviewApplications'
import QuestionTitle from './QuestionTitle'
import {
  myI18n,
  userToFormat
} from '../../../translation/I18nConnectedProvider'
import RedirectWarning from '../page-layouts/RedirectWarning'
import sfAuthService from 'app/services/sfAuth/SFAuthService'
import SaveWillOverrideWarningDialog from '../common/SaveWillOverrideWarningDialog'
import {
  createCase,
  createCaseByFlow,
  createCases
} from 'app/services/sfAuth/sfData/sfCase'
import { SurveyTabESatTopCard } from './ESATSurveys'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import { checkIfScoreCardExistForTemplate } from 'app/services/sfAuth/sfData/sfScoreCard'
import { dateFormat } from 'app/appSettings'
import { Alert, AlertTitle } from '@material-ui/lab'

export const questionToInitial = item => {
  if (item.isGroup && item.groupQuestions) {
    return [item.id.toString(), Array(item.groupQuestions.length).fill('')]
  }
  switch (item.type) {
    case questionTypes.INPUT_TEXT.id:
      return [item.id.toString(), '']
    case questionTypes.INPUT_TEXTAREA.id:
      return [item.id.toString(), '']
    case questionTypes.SELECT_ONE.id:
      return [item.id.toString(), null]
    case questionTypes.SELECT_MULTIPLE.id:
      return [item.id.toString(), Array(item.options.length).fill(false)]
    default:
      return [item.id.toString(), '']
  }
}

export const questionsToInitial = questionList => {
  const ret = {
    submitButtonType: 'Saved'
  }
  for (const i of questionList.map((item, index) => {
    return questionToInitial(item)
  })) {
    ret[i[0]] = i[1]
  }
  return ret
}

export const nonApplicableSuffix = '_nonApplicable'
const answerToInit = survey => {
  try {
    if (survey) {
      const ret = JSON.parse(survey.answer)
      return ret
    }
  } catch (e) {
    console.warn('could not load answer data', e)
  }
  return {}
}

const styles = theme => ({
  formik: {
    margin: '0 auto',
    width: '100%',
    maxWidth: 1350,
    textAlign: 'left'
    // width: 800
  },
  question: {
    padding: 12,
    margin: 8,
    textAlign: 'left'
  },
  submitButton: {
    margin: 10
  }
})

export const saveFile = (filename, data, { type = 'text/csv' }) => {
  const blob = new Blob([data], { type })
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const elem = window.document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = filename
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
}

const exportDataFunctions = (toSet, templateId, configuration) => {
  console.log('export toSet', toSet)
  const surveyScoreCard = templateIdToMapping(templateId, configuration)
  const exportData = toSet
    .map(item => {
      return item.questions
        .map(question => {
          if (question.isGroup) {
            return question.groupQuestions.map(subquestion => ({
              id: `${question.id}.${subquestion.index}`,
              text: subquestion.en,
              parentText: question.textEn,
              templateName: question.templateName,
              date: question.createdDate,
              ...Object.fromEntries(
                question.options.map((item, index) => [
                  `option_${index + 1}`,
                  item.text
                ])
              )
            }))
          }
          return {
            id: question.id,
            text: question.textEn,
            date: question.createdDate,
            templateName: question.templateName,
            children: question.groupQuestions,
            parentText: '',
            ...Object.fromEntries(
              question.options.map((item, index) => [
                `option_${index + 1}`,
                item.text
              ])
            )
          }
        })
        .flat()
    })
    .flat()
  const fixDoubles = {}
  const filteredDoubles = []

  exportData.forEach(item => {
    if (!fixDoubles[item.id]) {
      filteredDoubles.push(item)
      fixDoubles[item.id] = true
    }
  })

  const csv = filteredDoubles.map(item => {
    let scoringGroup = ''
    let multiple = ''
    let modifier = ''
    if (surveyScoreCard[item.id]) {
      scoringGroup = surveyScoreCard[item.id]['Scoring group']
      multiple = surveyScoreCard[item.id].multiple
      modifier = surveyScoreCard[item.id].modifier
    }

    return (
      `"${item.id}","${item.parentText}", "${item.text}", "${
        item.date && item.date.split('.')[0]
      }", "${item.templateName}", "${scoringGroup}",` +
      `"${modifier}", "${multiple}",` +
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        .map(optionIndex => {
          const scoreCardOption =
            surveyScoreCard[item.id] &&
            surveyScoreCard[item.id][`Option ${optionIndex}`]
          console.log(
            'option data',
            scoreCardOption,
            `"${item[`option_${optionIndex}`]}"`,
            item[`option_${optionIndex}`],
            surveyScoreCard[item.id]
          )
          return item[`option_${optionIndex}`]
            ? scoreCardOption && scoreCardOption !== 'undefined'
              ? `"${scoreCardOption}"`
              : `"${item[`option_${optionIndex}`]}"`
            : ''
        })
        .join(',')
    )
  })
  csv.unshift(
    [
      'QuestionID',
      'Parent Text',
      'Text EN',
      'Created date',
      'Section',
      'Scoring group',
      'modifier',
      'multiple',
      'Option 1',
      'Option 2',
      'Option 3',
      'Option 4',
      'Option 5',
      'Option 6',
      'Option 7',
      'Option 8',
      'Option 9',
      'Option 10',
      'Option 11',
      'Option 12'
    ]
      .map(i => `"${i}"`)
      .join(',')
  )

  saveFile('fileCSV.csv', csv.join('\r\n'))

  console.log('export', exportData)
}

const isSubquestion = dataObj => {
  return (
    dataObj.itemOptions.parentQuestionIndex ||
    dataObj.itemOptions.parentQuestionIndex === 0 ||
    dataObj.itemOptions.parentQuestionId
  )
}

export const sfDataToRenderData = (result, templateId, props) => {
  const { user = {} } = props
  const subtemplatesIds = result.templates
    .sort((a, b) => a.order - b.order)
    .map(item => item.id)
  const detailsPromises = []
  detailsPromises.push(getSurveyTemplateDetails(templateId))
  subtemplatesIds.forEach(id => {
    detailsPromises.push(getSurveyTemplateDetails(id))
  })
  return Promise.all(detailsPromises).then(result => {
    const toSet = []
    result.forEach((template, index) => {
      const section = { ...template.templateOptions }
      section.questions = []
      section.templateId = template.info.templateId
      section.header = template.info.surveyName
      if (template.titleDetails) {
        if (user.language === 'en_US') {
          section.header = template.titleDetails.en
          section.tooltip = template.titleDetails.tooltipEN
        } else {
          section.header = template.titleDetails.fr
          section.tooltip = template.titleDetails.tooltipFR
        }
        section.tooltipEn = template.titleDetails.tooltipEN
        section.tooltipFr = template.titleDetails.tooltipFR
        section.headerEN = template.titleDetails.en
        section.headerFR = template.titleDetails.fr
      }
      if (template.questionsDetails.length === 0) {
        toSet.push(section)
        return
      }
      template.questionsDetails.sort((a, b) => {
        return a.order - b.order
      })
      const groupQuestions = {}
      template.questionsDetails.forEach((dataObj, index) => {
        if (!dataObj.itemOptions) {
          return
        }
        if (isSubquestion(dataObj)) {
          const toPush = {
            fr: dataObj.itemTranslation.fr,
            en: dataObj.itemTranslation.en,
            index: dataObj.itemOptions.index,
            id: dataObj.itemOptions.createdId
          }
          const parentId =
            dataObj.itemOptions.parentQuestionId ||
            dataObj.itemOptions.parentQuestionIndex
          if (groupQuestions[parentId]) {
            groupQuestions[parentId].push(toPush)
          } else {
            groupQuestions[parentId] = [toPush]
          }
        }
      })
      template.questionsDetails.forEach(question => {
        let isGroup = false
        const childQuestions = []
        const inTemplates = []
        const otherTemplatesIDs = []
        question.item.Survey_Joins_Template_and_Question__r.records.forEach(
          join => {
            const options = JSON.parse(join.Item_Options__c)
            if (join.Id !== question.joinId) {
              inTemplates.push(join.Survey_Template__c)
              if (options.mainTemplate) {
                inTemplates.push(options.mainTemplate)
              }
              otherTemplatesIDs.push(options.createdId)
            }
          }
        )
        const crypto = require('crypto')
        const assignId = crypto.randomBytes(20).toString('hex')
        if (!question.itemOptions) {
          question.itemOptions = {
            optionsWithRequiredDetails: [],
            conditions: [],
            conditionsToCheck: [],
            externalConditions: [],
            createdId: assignId
          }
        } else if (!question.itemOptions.createdId) {
          question.itemOptions.createdId = assignId
        }
        if (isSubquestion(question)) {
          return
        }
        const questionOptions = []
        if (question.itemOptions.isGroup === true) {
          isGroup = true
          const subQuestions =
            groupQuestions[question.itemOptions.createdId] ||
            groupQuestions[question.order]
          if (subQuestions) {
            subQuestions.sort((a, b) => {
              return a.index - b.index
            })
            subQuestions.forEach(sub => {
              childQuestions.push({
                en: sub.en,
                fr: sub.fr,
                index: sub.index,
                id: sub.id
              })
            })
          }
        }

        const optionsWithRequiredDetails = {}
        question.itemOptions.optionsWithRequiredDetails.forEach(detailObj => {
          if (!detailObj) {
            return
          }
          const type = detailObj.type ? detailObj.type : 'text'
          const index =
            detailObj.index || detailObj.index === 0
              ? detailObj.index
              : detailObj
          optionsWithRequiredDetails[index] = type
        })
        question.itemDetails.sort((a, b) => a.order - b.order)
        question.itemDetails.forEach(detail => {
          const requireDetails = optionsWithRequiredDetails[detail.order]
          questionOptions.push({
            text: user.language === 'en_US' ? detail.en : detail.fr,
            textEn: detail.en,
            textFr: detail.fr,
            apiValue: detail.apiValue,
            requireDetails: requireDetails || null,
            clearOnSelect: Boolean(detail.clearOnSelect)
          })
        })
        if (fixedQuestionAnswers[question.item.Type__c]) {
          fixedQuestionAnswers[question.item.Type__c].forEach(option => {
            questionOptions.push({
              text: option,
              requireDetails: null
            })
          })
        }
        const questionObj = {
          templateName: template.info.surveyName,
          type: question.item.Type__c,
          conditionsToCheck: question.itemOptions.conditionsToCheck || [],
          conditions: question.itemOptions.conditions || [],
          externalConditions: question.itemOptions.externalConditions || [],
          validationRules: question.itemOptions.validationRules || [],
          createdDate: question.item.CreatedDate,
          isGroup,
          otherTemplatesIDs,
          inTemplates,
          sfId: question.item.Id,
          groupQuestions: childQuestions,
          options: questionOptions,
          renderFrench: user.language === 'fr',
          id: question.itemOptions.createdId,
          text:
            user.language === 'en_US'
              ? question.itemTranslation.en
              : question.itemTranslation.fr,
          tooltip:
            user.language === 'en_US'
              ? question.itemTranslation.tooltipEN
              : question.itemTranslation.tooltipFR,
          tooltipEn: question.itemTranslation.tooltipEN,
          tooltipFr: question.itemTranslation.tooltipFR,
          textEn: question.itemTranslation.en,
          textFr: question.itemTranslation.fr
        }
        surveyItemOptionsFields.forEach(field => {
          questionObj[field] = question.itemOptions[field]
        })
        section.questions.push(questionObj)
      })
      toSet.push(section)
    })
    return toSet
  })
}

export const suveyTempalteDataToQuestions = templates => {
  const ret = {}
  for (const subtemplate of templates) {
    for (const question of subtemplate.questions) {
      ret[question.id] = question
    }
  }
  return ret
}

const surveyScoringAvailable = (templateId, configuration) => {
  const configurationKeys = Object.values(surveyMapping).map(item => ({
    key: item.configuration.key,
    hasScore: item.hasScore
  }))
  for (const { key, hasScore } of configurationKeys) {
    if (configuration[key] === templateId && hasScore) {
      return true
    }
  }
  return false
}

const SurveyTab = ({
  disabled = null,
  viewMode = 'editable',
  opportunity,
  granteeReport,
  submitDisabled = false,
  embedded,
  noSubmit = false,
  noSave = false,
  showApply = false,
  submitCallback,
  saveCallback,
  requiredQuestions = [],
  linkedQuestions = [],
  templatesToCheck = [],
  linkedObjects,
  isExternalReview = false,
  isAboutMe = false,
  isConfirm = false,
  ...props
}) => {
  const [data, setData] = useState(null)
  const formRef = useRef()
  const [network, setNetwork] = useState(null)
  const [survey, setSurvey] = useState(null)
  const [noPermission, setNoPermission] = useState(false)
  const [entityFiles, setEntityFiles] = useState([])
  const [linkedQuestionsToCheck, setLinkedQuestionsToCheck] =
    useState(linkedQuestions)
  const [initialValues, setInitialValues] = useState({})
  const [requiredIDs, setRequiredIDs] = useState(requiredQuestions)
  const [completedSurveys, setCompletedSurveys] = useState([])
  const [scoreCardExists, setScoreCardExists] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const configuration = useSelector(state => state.configuration)
  const organization = useSelector(state => state.organization)
  const history = useHistory()
  const userLanguage = useSelector(state => state.user.language)
  const user = useSelector(state => state.user)
  let isSAT = false
  let esat = false
  // try prop first
  const templateId = props.templateId
    ? props.templateId
    : props.match
    ? props.match.params.id
    : null
  const surveyId = props.surveyId
    ? props.surveyId
    : props.match
    ? props.match.params.surveyId
    : null

  isSAT = isConfiguredTemplate(templateId, configuration, [
    surveyConfigurationVariables.E_SAT_SURVEY.key,
    surveyConfigurationVariables.SAT_COOPS_SURVEY.key,
    surveyConfigurationVariables.SAT_OSBL_SURVEY.key
  ])
  esat = isConfiguredTemplate(templateId, configuration, [
    surveyConfigurationVariables.E_SAT_SURVEY.key
  ])

  console.log('surveyTab params', {
    disabled,
    survey,
    data,
    noSubmit,
    match: props.match,
    linkedQuestionsToCheck,
    opportunity
  })
  const isGranteeReport =
    history.location.pathname.split('/')[2] === 'GranteeReport'
  const classes = props.classes
  const questionMap = data ? suveyTempalteDataToQuestions(data) : {}

  useEffect(() => {
    if (data === null && !props.renderData) {
      fetchData()
    } else if (props.renderData && data === null) {
      setData(props.renderData)
      let title = ''
      props.renderData.some(template => {
        if (template.templateId === templateId) {
          title = template.header
          return true
        }
      })
      if (surveyId) {
        getSurveyByFlow(surveyId).then(survey => {
          setSurvey({ ...survey, title: title })
        })
      } else {
        setSurvey({
          ...survey,
          title: title
        })
      }
    }
  }, [])

  const fetchData = () => {
    setData(null)
    Promise.all([
      getSurveyByFlow(surveyId),
      getSubtemplates(templateId).then(result => {
        return sfDataToRenderData(result, templateId, {
          configuration,
          ...props
        })
      }),
      findCompletedSurveys(
        templateId,
        isGranteeReport && { Opportunity__c: opportunity.id }
      ),
      getNetwork(),
      getDocumentsByEntity(opportunity && opportunity.reportId),
      checkIfScoreCardExistForTemplate(templateId)
    ])
      .then(
        ([
          survey,
          templates,
          completedSurveys,
          network,
          files,
          scoreCardExists
        ]) => {
          console.log(
            'gote surveys and template',
            survey,
            templates,
            completedSurveys
          )
          console.log(
            'got files',
            opportunity && opportunity.reportId,
            files,
            network
          )
          if (!survey) {
            setNoPermission(true)
            return
          }
          if (
            survey.locked &&
            survey.owner !== user.userId &&
            survey.surveyState !== 'Submitted'
          ) {
            if (viewMode !== 'view') {
              history.goBack()
              enqueueSnackbar(
                <Trans>This survey is locked by another user!</Trans>,
                { variant: 'error' }
              )
            }
          }
          const valuesParsed = JSON.parse(survey.answer) || {}
          const toSetInitial = {
            skippedSections: { ...valuesParsed?.skippedSections }
          }
          Object.keys(valuesParsed).forEach(key => {
            if (key.indexOf(nonApplicableSuffix) !== -1 && valuesParsed[key]) {
              toSetInitial[key] = valuesParsed[key]
            }
          })
          setInitialValues(toSetInitial)
          setEntityFiles(
            files.map((item, index) => ({
              name: item.ContentDocument.Title,
              description: item.ContentDocument.Description,
              uploadId: index,
              tags: item.ContentVersion.TagCsv,
              id: item.ContentDocumentId
            }))
          )
          const extraTemplates = templatesToCheck || []
          const requiredQuestions = []
          const linkedQuestions = []
          templates.forEach(template => {
            if (template.templateId === templateId) {
              survey.title = template.header
              if (SATsIdsList.includes(template.templateId)) {
                survey.title = survey.name
                survey.noDateInTitle = true
              }
              survey.autosave = template.autosave
            }
            template.questions.forEach(question => {
              if (question.inTemplates.length > 1) {
                question.inTemplates.forEach(id => {
                  if (!extraTemplates.includes(id) && id !== templateId) {
                    extraTemplates.push(id)
                  }
                })
              }
              if (question.linkedField && question.linkedObject) {
                linkedQuestions.push({
                  linkedField: question.linkedField,
                  linkedObject: question.linkedObject,
                  id: question.id,
                  sfID: question.sfID
                })
              }
              if (
                question.isRequired &&
                question.type !== questionTypes.HEADER.id
              ) {
                requiredQuestions.push({
                  id: question.id,
                  type: question.type,
                  isGroup: question.isGroup
                })
              }
            })
          })
          if (extraTemplates.length > 0) {
            findCompletedSurveys([...extraTemplates]).then(result => {
              const toCheck = [...completedSurveys, ...result]
              toCheck.sort(
                (a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate)
              )
              setCompletedSurveys([...toCheck.map(item => answerToInit(item))])
            })
          } else {
            setCompletedSurveys([
              ...completedSurveys.map(item => answerToInit(item))
            ])
          }
          setScoreCardExists(scoreCardExists)
          setNetwork(network)
          setData(templates)
          setRequiredIDs(requiredQuestions)
          setLinkedQuestionsToCheck(linkedQuestions)
          setSurvey(survey)
        }
      )
      .catch(error => {
        console.error('getting templates', error, surveyId, templateId)
      })
  }

  useEffect(() => {
    if (data) {
      const newData = [...data]
      const en = userLanguage === 'en_US'
      newData.forEach(obj => {
        obj.header = en ? obj.headerEN : obj.headerFR
        obj.tooltip = en ? obj.tooltipEn : obj.tooltipFr
        obj.questions.forEach(question => {
          question.text = en ? question.textEn : question.textFr
          question.tooltip = en ? question.tooltipEn : question.tooltipFr
          if (question.options) {
            question.options.forEach(option => {
              const isTrans = option.text && option.text.id
              if (!isTrans) {
                option.text = en ? option.textEn : option.textFr
              }
            })
          }
        })
      })
      setData(newData)
    }
  }, [userLanguage])

  const submitSurvey = useCallback(
    (values, { setSubmitting, resetForm, unlock }) => {
      console.log('submitSurvey', values)
      enqueueSnackbar(<Trans>Saving Survey</Trans>, {
        variant: 'info'
      })
      const submitButtonType = values.submitButtonType || 'Saved'
      const disableIds = values.disableFields
      delete values.disableFields
      delete values.submitButtonType
      setSubmitting(true)
      const casesToCreate = []
      if (submitButtonType === 'Submitted') {
        Object.values(questionMap).forEach(question => {
          if (question.createCase && question.caseType && values[question.id]) {
            casesToCreate.push({
              Type: question.caseType,
              AccountId: organization.id,
              Description: values[question.id],
              Language__c: userLanguage,
              SuppliedName: user.displayName,
              ContactId: user.userObject.contactId,
              Subject: survey.title
            })
          }
        })
      }
      if (survey) {
        Promise.all([
          saveSurveyData({
            name: survey.title,
            id: survey.id,
            opportunityId: opportunity?.id,
            accountId: organization?.id,
            userId: user.userId,
            values,
            submitButtonType,
            template: data,
            disableIds
          }),
          casesToCreate.length > 0
            ? createCases(casesToCreate)
            : Promise.resolve([]),
          unlock &&
            changeSurveyLock({
              surveyId: survey.id,
              lockState: false,
              userId: user.userId
            })
        ])
          .then(
            ([saveResult, casesResult, unlockResult]) => {
              console.log('cases from survey', casesResult)
              console.log('saveSurveyData', saveResult)
              if (unlock && unlockResult.result !== 'RESULT_UNLOCKED') {
                enqueueSnackbar(
                  <Trans>
                    Error ocurred while trying to unlock this survey
                  </Trans>,
                  {
                    variant: 'error'
                  }
                )
              }
              if (casesResult.some(res => !res.success)) {
                enqueueSnackbar(
                  <Trans>
                    There was a problem with submitting some of your comments
                  </Trans>,
                  {
                    variant: 'error'
                  }
                )
              }
              if (!saveCallback) {
                resetForm({ values })
                enqueueSnackbar(<Trans>Saved Survey</Trans>, {
                  variant: 'success'
                })
                if (unlock) {
                  history.push('/surveys/SelfAssessmentSurveys')
                }
                getSurveyByFlow(surveyId).then(survey => {
                  setSurvey(survey)
                  setSubmitting(false)
                })
              }
              if (
                submitButtonType === 'Submitted' ||
                submitButtonType === 'Applied'
              ) {
                if (submitCallback) {
                  submitCallback(values, {
                    linkedQuestionsToCheck,
                    disableFields: disableIds,
                    templateData: data,
                    surveyId: saveResult.id
                  })
                  resetForm({ values })
                  getSurveyByFlow(surveyId).then(survey => {
                    setSurvey(survey)
                  })
                } else {
                  if (surveyScoringAvailable(templateId, configuration)) {
                    history.push(
                      `/surveys/SurveyScore/${surveyId}/${templateId}`
                    )
                  } else {
                    // TODO handle coming to the survey better
                    history.goBack()
                  }
                }
              } else if (submitButtonType === 'Saved' && saveCallback) {
                saveCallback(values, {
                  linkedQuestionsToCheck,
                  disableFields: disableIds,
                  surveyId: saveResult.id,
                  templateData: data
                }).then(
                  result => {
                    resetForm({ values })
                    enqueueSnackbar(<Trans>Saved Survey</Trans>, {
                      variant: 'success'
                    })
                    if (unlock) {
                      history.push('/surveys/SelfAssessmentSurveys')
                    }
                    getSurveyByFlow(surveyId).then(survey => {
                      setSurvey(survey)
                      setSubmitting(false)
                    })
                  },
                  reject => {
                    console.error('error saving survey', reject)
                    enqueueSnackbar(<Trans>Error Saving Survey</Trans>, {
                      variant: 'error'
                    })
                  }
                )
              } else {
                getSurveyByFlow(surveyId).then(survey => {
                  setSurvey(survey)
                  setSubmitting(false)
                })
              }
            },
            reject => {
              console.log('error', reject)
              createCaseByFlow({
                title: 'Saving failed',
                type: 'Saving Failed',
                description: JSON.stringify({ values, reject }),
                contact: user.userObject.contactId,
                opportunityId: opportunity?.id,
                organization: organization.id,
                language: userLanguage,
                userName: user.displayName,
                skipAssigment: false,
                owner: '005Am000000Kas0IAC'
              })
              getSurveyByFlow(surveyId).then(survey => {
                setSurvey(survey)
                setSubmitting(false)
              })
              enqueueSnackbar(<Trans>Error Saving Survey</Trans>, {
                variant: 'error'
              })
            }
          )
          .catch(error => {
            console.error('error saving survey', error)
            getSurveyByFlow(surveyId).then(survey => {
              setSurvey(survey)
              setSubmitting(false)
            })
            enqueueSnackbar(<Trans>Error Saving Survey</Trans>, {
              variant: 'error'
            })
          })
      } else {
        // TODO this does not submit after creation check where it can be used
        Promise.all([
          addSurvey(
            {
              surveyTemplate: props.createSurveyType,
              accountId: props.organization.id,
              opportunityId: opportunity?.id,
              userId: user.userId
            },
            configuration
          ),
          casesToCreate.length > 0
            ? createCases(casesToCreate)
            : Promise.resolve([])
        ]).then(
          ([result]) => {
            resetForm({ values })
            enqueueSnackbar(<Trans>Saved Survey</Trans>, {
              variant: 'success'
            })
            if (submitButtonType === 'Submitted') {
              history.push(`/surveys/SurveyScore/${surveyId}/${templateId}`)
            }
            setSubmitting(false)
          },
          reject => {
            console.error('error saving survey', reject)
            setSubmitting(false)
            resetForm({ values: values })
            enqueueSnackbar(<Trans>Error Saving Survey</Trans>, {
              variant: 'error'
            })
          }
        )
      }
    },
    [survey, data, opportunity, user, organization]
  )

  const [validationSchema, setValidationSchema] = useState()

  useEffect(() => {
    const yupObj = {}
    const requiredIDsValues = requiredIDs.map(i => i.id)
    requiredIDs.forEach(obj => {
      if (obj.type === questionTypes.SELECT_MULTIPLE.id) {
        yupObj[obj.id] = Yup.array()
          .nullable()
          .compact()
          .min(1, questionRequiredTransWithStar)
          .required(questionRequiredTransWithStar)
      } else if (obj.type === questionTypes.INPUT_DATE_RANGE.id) {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array().of(
            Yup.mixed()
              .required(dateRangeRequiredTrans)
              .dateRangeRequired(dateRangeRequiredTrans)
          )
        } else {
          yupObj[obj.id] = Yup.mixed()
            .required(dateRangeRequiredTrans)
            .dateRangeRequired(dateRangeRequiredTrans)
        }
      } else if (
        obj.type === questionTypes.INPUT_TEXT.id ||
        obj.type === questionTypes.INPUT_TEXTAREA.id
      ) {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array()
            .nullable()
            .of(Yup.string().required(questionRequiredTransWithStar))
        } else {
          yupObj[obj.id] = Yup.string().required(questionRequiredTransWithStar)
        }
      } else if (
        obj.type === questionTypes.INPUT_NUMBER.id ||
        obj.type === questionTypes.SLIDER.id
      ) {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array()
            .nullable()
            .of(
              Yup.number()
                .transform(v => v || null)
                .nullable()
                .required(questionRequiredTransWithStar)
            )
        } else {
          yupObj[obj.id] = Yup.number()
            .transform(v => v || null)
            .nullable()
            .required(questionRequiredTransWithStar)
        }
      } else if (obj.type === questionTypes.INPUT_DATE.id) {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array()
            .nullable()
            .of(Yup.mixed().required(questionRequiredTransWithStar))
        } else {
          yupObj[obj.id] = Yup.mixed().required(questionRequiredTransWithStar)
        }
      } else {
        if (obj.isGroup) {
          yupObj[obj.id] = Yup.array()
            .nullable()
            .of(Yup.string().required(questionRequiredTransWithStar))
        } else {
          yupObj[obj.id] = Yup.mixed().required(questionRequiredTransWithStar)
        }
      }
    })
    if (data) {
      data.forEach(template => {
        template.questions.forEach(question => {
          const questionParams = {
            required: requiredIDsValues.includes(question.id)
          }
          if (question.validationRules) {
            question.validationRules.forEach(rule => {
              if (!rule.type || !rule.parameter) {
                return
              }
              if (yupObj[question.id]) {
                if (question.isGroup) {
                  if (questionValidationRules[rule.type].applyToAll) {
                    yupObj[question.id] = yupObj[question.id].concat(
                      questionValidationRules[rule.type].rule(
                        rule.parameter,
                        questionParams
                      )
                    )
                  } else {
                    yupObj[question.id] = yupObj[question.id].concat(
                      Yup.array()
                        .transform(v => (!v ? [] : v))
                        .nullable()
                        .of(
                          questionValidationRules[rule.type].rule(
                            rule.parameter,
                            questionParams
                          )
                        )
                    )
                  }
                } else {
                  yupObj[question.id] = yupObj[question.id].concat(
                    questionValidationRules[rule.type].rule(
                      rule.parameter,
                      questionParams
                    )
                  )
                }
              } else {
                if (question.isGroup) {
                  if (questionValidationRules[rule.type].applyToAll) {
                    yupObj[question.id] = questionValidationRules[
                      rule.type
                    ].rule(rule.parameter, questionParams)
                  } else {
                    yupObj[question.id] = Yup.array()
                      .transform(v => (!v ? [] : v))
                      .nullable()
                      .of(
                        questionValidationRules[rule.type].rule(
                          rule.parameter,
                          questionParams
                        )
                      )
                  }
                } else {
                  yupObj[question.id] = questionValidationRules[rule.type].rule(
                    rule.parameter,
                    questionParams
                  )
                }
              }
            })
          }
          if (
            question.maxCharacters &&
            [
              questionTypes.INPUT_TEXT.id,
              questionTypes.INPUT_TEXTAREA.id
            ].includes(question.type)
          ) {
            const rule = Yup.string()
              .ensure()
              .max(question.maxCharacters, ({ max }) =>
                myI18n._(t`This fields length cannot exceed ${max} characters!`)
              )
            if (question.isGroup) {
              if (yupObj[question.id]) {
                yupObj[question.id] = yupObj[question.id].concat(
                  Yup.array()
                    .transform(v => (!v ? [] : v))
                    .nullable()
                    .of(rule)
                )
              } else {
                yupObj[question.id] = Yup.array()
                  .transform(v => (!v ? [] : v))
                  .nullable()
                  .of(rule)
              }
            } else {
              if (yupObj[question.id]) {
                yupObj[question.id] = yupObj[question.id].concat(rule)
              } else {
                yupObj[question.id] = rule
              }
            }
          }
          if (question.type === questionTypes.INPUT_NUMBER.id) {
            if (question.min) {
              const rule = Yup.number().min(question.min, ({ min }) =>
                myI18n._(t`Provided value cannot be lesser than ${min}!`)
              )
              if (question.isGroup) {
                if (yupObj[question.id]) {
                  yupObj[question.id] = yupObj[question.id].concat(
                    Yup.array()
                      .transform(v => (!v ? [] : v))
                      .nullable()
                      .of(rule)
                  )
                } else {
                  yupObj[question.id] = Yup.array()
                    .transform(v => (!v ? [] : v))
                    .nullable()
                    .of(rule)
                }
              } else {
                if (yupObj[question.id]) {
                  yupObj[question.id] = yupObj[question.id].concat(rule)
                } else {
                  yupObj[question.id] = rule
                }
              }
            }
            if (question.max) {
              const rule = Yup.number().max(question.max, ({ max }) =>
                myI18n._(t`Provided value cannot be greater than ${max}!`)
              )
              if (question.isGroup) {
                if (yupObj[question.id]) {
                  yupObj[question.id] = yupObj[question.id].concat(
                    Yup.array()
                      .transform(v => (!v ? [] : v))
                      .nullable()
                      .of(rule)
                  )
                } else {
                  yupObj[question.id] = Yup.array()
                    .transform(v => (!v ? [] : v))
                    .nullable()
                    .of(rule)
                }
              } else {
                if (yupObj[question.id]) {
                  yupObj[question.id] = yupObj[question.id].concat(rule)
                } else {
                  yupObj[question.id] = rule
                }
              }
            }
          }
        })
      })
    }
    setValidationSchema(Yup.object().shape(yupObj))
  }, [requiredIDs])

  if (!data || !survey || !surveyId || !templateId) {
    return (
      <>
        <Loading isNotFixed={isGranteeReport} />
        {noPermission && (
          <Dialog fullWidth maxWidth='sm' open={noPermission}>
            <DialogTitle>
              <Trans>Insufficient access!</Trans>
            </DialogTitle>
            <DialogContent>
              <Trans>
                You don't have sufficient access to view this survey!
              </Trans>
              <div
                style={{
                  padding: 15,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Button
                  color='primary'
                  variant='contained'
                  onClick={e => {
                    history.push('/surveys/SelfAssessmentSurveys')
                  }}
                >
                  <Trans>Go back to surveys list</Trans>
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </>
    )
  }

  const answers = answerToInit(survey) || {}
  if (linkedObjects && data) {
    console.log('loaded answers', linkedObjects, answers, questionMap, survey)
    for (const linkedQuestion of linkedQuestionsToCheck) {
      if (linkedObjects[linkedQuestion.linkedObject]) {
        const answer =
          linkedObjects[linkedQuestion.linkedObject][linkedQuestion.linkedField]
        const question = questionMap[linkedQuestion.id]
        if (question.type === questionTypes.SELECT_MULTIPLE.id) {
          const answerOptions = []
          const savedOptions = answer ? answer.split(';') : []
          for (const option of question.options) {
            if (
              (option.apiValue &&
                savedOptions.indexOf(option.apiValue) !== -1) ||
              savedOptions.indexOf(option.textEn) !== -1
            ) {
              answerOptions.push(true)
            } else {
              answerOptions.push(false)
            }
          }
          answers[question.id] = answerOptions
        } else if (question.type === questionTypes.SELECT_ONE.id) {
          let i = 0
          for (const option of question.options) {
            if (
              (option.apiValue && option.apiValue === answer) ||
              option.textEn === answer
            ) {
              answers[question.id] = `option_${i}`
              break
            }
            i++
          }
        } else {
          answers[question.id] = answer
        }
      }
    }
  }

  let state = survey.surveyState
  const disableStates = ['Submitted', 'Approved', 'Obsolete', 'Scheduled']
  if (granteeReport) {
    state = granteeReport.status
  }

  const toInit = []
  const initialTouched = {}
  data.forEach(template => {
    template.questions.forEach(q => {
      toInit.push(q)
      if (q.isGroup) {
        initialTouched[q.id] = q.groupQuestions.map(sub => true)
      } else {
        initialTouched[q.id] = true
      }
    })
  })

  const isLocked = Boolean(survey.locked && survey.owner !== user.userId)
  return (
    <div className={classes.formik}>
      {isSAT && (esat ? <SurveyTabESatTopCard /> : <SurveyTabSatTopCard />)}
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          ...questionsToInitial(toInit),
          ...answers,
          ...initialValues
        }}
        initialTouched={initialTouched}
        validationSchema={validationSchema}
        onSubmit={submitSurvey}
      >
        {formikState => (
          <DisplayedSurvey
            {...props}
            isSAT={isSAT}
            scoreCardExists={scoreCardExists}
            user={user}
            formRef={formRef}
            network={network}
            entityFiles={entityFiles}
            data={data}
            isLocked={isLocked}
            isDisabled={disabled || disableStates.includes(state) || isLocked}
            completedSurveys={completedSurveys}
            classes={classes}
            isAboutMe={isAboutMe}
            noSubmit={noSubmit}
            embedded={embedded}
            submitDisabled={submitDisabled}
            noSave={noSave}
            showApply={showApply}
            history={history}
            opportunity={opportunity}
            validationSchema={validationSchema}
            survey={survey}
            granteeReport={granteeReport}
            formikState={formikState}
            submitSurvey={submitSurvey}
            isGranteeReport={isGranteeReport}
            isConfirm={isConfirm}
            questionMap={questionMap}
          />
        )}
      </Formik>
    </div>
  )
}

export const checkSurveyQuestionsToDisable = ({
  data,
  values,
  opportunity
}) => {
  const disableFields = []
  data.forEach(template => {
    template.questions.forEach(question => {
      if (
        question.conditionForObjectField &&
        question.conditionalObject &&
        question.conditionalObjectField &&
        question.conditionalObjectName &&
        opportunity
      ) {
        let disable = checkConditionalsBasedOnObject({
          question,
          opportunity
        })
        if (question.conditionForObjectField === 'enable') {
          disable = !disable
        }
        if (disable) {
          disableFields.push(question.id)
        }
      }
      if (question.externalConditions) {
        if (question.externalConditions.length > 0) {
          question.externalConditions.forEach(external => {
            checkExternalConditions({
              values,
              question,
              disableFields,
              external
            })
          })
        }
      }
      if (question.conditionsToCheck) {
        question.conditionsToCheck.forEach(condition => {
          checkConditions({
            condition,
            values,
            disableFields,
            template
          })
        })
      }
    })
  })
  // disable questions from disabled templates
  for (const templateObject of data) {
    if (disableFields.includes(templateObject.templateId)) {
      for (const q of templateObject.questions) {
        disableFields.push(q.id)
      }
    }
  }
  return disableFields
}

const DisplayedSurvey = props => {
  const {
    formikState,
    printTitle,
    scoreCardExists,
    network,
    formRef,
    entityFiles,
    data,
    user,
    isLocked,
    isDisabled,
    completedSurveys,
    classes,
    isSAT,
    isAboutMe,
    noSubmit,
    embedded,
    submitDisabled,
    noSave,
    showApply,
    history,
    opportunity,
    validationSchema,
    survey,
    isConfirm,
    isGranteeReport,
    submitSurvey,
    questionMap,
    granteeReport
  } = props

  const [currentTemplate, setCurrentTemplate] = React.useState(null)
  const [confirmationDialogOpened, confirmDialog] = React.useState(false)
  const [currentStep, setCurrentStep] = React.useState(0)
  const [overrideWarningData, setOverrideWarningData] = React.useState(null)
  const stepperRef = useRef()
  const printRef = useRef()
  const {
    values,
    errors,
    isValidating,
    setFieldValue,
    initialValues,
    setValues,
    validateForm,
    setErrors,
    isSubmitting,
    setSubmitting,
    resetForm,
    dirty
  } = formikState

  console.log('values', values)

  useEffect(() => {
    console.log('validate', values)
    validateForm().then(res => {
      setErrors(res)
    })
  }, [validationSchema, initialValues])

  let currentIndex = 0

  const disableFields = useMemo(
    () => checkSurveyQuestionsToDisable({ data, values, opportunity }),
    [values, opportunity]
  )

  const missingQuestion = {}
  if (Object.keys(errors).length !== 0) {
    for (const error in errors) {
      const errorId = error.split('[')[0]
      data.forEach((temp, tempIndex) => {
        let displayIndex = 1
        let qData
        temp.questions.forEach((q, qIndex) => {
          if (q.id === errorId) {
            qData = {
              ...q,
              templateIndex: tempIndex,
              questionIndex: displayIndex,
              templateName: temp.header
            }
          }
          if (!disableFields.includes(q.id) && q.type !== HEADER) {
            displayIndex++
          }
        })
        if (
          qData &&
          !isSectionDisabled(temp) &&
          !disableFields.includes(qData.id) &&
          values.skippedSections &&
          !values.skippedSections[temp.templateId] &&
          !values[qData.id + nonApplicableSuffix]
        ) {
          missingQuestion[errorId] = {
            error: errors[errorId],
            value: values[errorId],
            template: temp.header,
            question: qData,
            aData: qData ? qData.text : null
          }
        }
      })
    }
    console.log('errors values', missingQuestion)
    console.log('errors questions', data)
  } else {
    console.log('errors no valid errors', errors, isValidating)
  }

  const errorsPresent = Object.keys(missingQuestion).length !== 0

  function isSectionDisabled (template) {
    const notAllDisabled = template.questions.some(question => {
      return !disableFields.includes(question.id) && question.type !== HEADER
    })
    return (
      template.questions.length === 0 ||
      disableFields.includes(template.templateId) ||
      !notAllDisabled
    )
  }

  useEffect(() => {
    let templateToSet = null
    let stepIndex = 0
    data.some((template, index) => {
      const notAllDisabled = template.questions.some(question => {
        return !disableFields.includes(question.id) && question.type !== HEADER
      })
      const unavaliable =
        template.questions.length === 0 ||
        disableFields.includes(template.templateId) ||
        !notAllDisabled

      if (!unavaliable) {
        if (stepIndex === currentStep) {
          templateToSet = template
          return true
        }
        stepIndex++
      }
    })
    setCurrentTemplate(templateToSet)
    if (stepperRef.current) {
      ReactDOM.findDOMNode(stepperRef.current).scrollIntoView()
    }
  }, [currentStep])

  const saveSurvey = (props = {}) => {
    const { unlock } = props
    var ref = formRef.current
    const newValues = { ...ref.values }
    newValues.submitButtonType = 'Saved'
    newValues.disableFields = disableFields
    setValues(newValues)
    submitSurvey(newValues, {
      setSubmitting,
      unlock,
      resetForm
    })
  }

  const checkSurveySave = (props = {}) => {
    const { unlock } = props
    var ref = formRef.current
    if (!ref.dirty || !sfAuthService.user || Boolean(overrideWarningData)) {
      return
    }
    console.log('survey to save', survey)
    setSubmitting(true)
    const currentValues = { ...ref.values }

    getSurveyByFlow(survey.id).then(alreadySaved => {
      const wasSavedInMeantime = moment(alreadySaved.modifiedDate).isAfter(
        moment(survey.modifiedDate)
      )
      if (wasSavedInMeantime) {
        const savedAnswer = JSON.parse(alreadySaved.answer)
        const dataToPass = {
          type: 'survey'
        }
        Object.keys(savedAnswer).forEach(key => {
          const question = questionMap[key]
          if (question) {
            dataToPass[key] = {
              fr: question.textFr,
              en: question.textEn
            }
            if (question.isGroup) {
              for (const groupQuestion of question.groupQuestions) {
                const saved = savedAnswer[key][groupQuestion.index]
                const current = currentValues[key][groupQuestion.index]
                let savedOther
                if (savedAnswer[key + '_other']) {
                  savedOther = savedAnswer[key + '_other'][groupQuestion.index]
                }
                let currentOther
                if (currentValues[key + '_other']) {
                  currentOther =
                    currentValues[key + '_other'][groupQuestion.index]
                }
                savedAnswer[key + '.' + groupQuestion.index] = {
                  ...questionToMultilanguageText(question, saved, savedOther),
                  value: saved,
                  other: savedOther
                }
                currentValues[key + '.' + groupQuestion.index] = {
                  ...questionToMultilanguageText(
                    questionMap[key],
                    current,
                    currentOther
                  ),
                  value: current,
                  other: currentOther
                }
                dataToPass[key + '.' + groupQuestion.index] = {
                  en: '[' + question.textEn + '] ' + groupQuestion.en,
                  fr: '[' + question.textFr + '] ' + groupQuestion.fr
                }
              }
            } else {
              savedAnswer[key] = {
                ...questionToMultilanguageText(
                  questionMap[key],
                  savedAnswer[key],
                  savedAnswer[key + '_other']
                ),
                value: savedAnswer[key],
                other: savedAnswer[key + '_other']
              }
              currentValues[key] = {
                ...questionToMultilanguageText(
                  questionMap[key],
                  currentValues[key],
                  currentValues[key + '_other']
                ),
                value: currentValues[key],
                other: currentValues[key + '_other']
              }
            }
          } else {
            delete savedAnswer[key]
          }
        })
        setOverrideWarningData({
          current: currentValues,
          saved: savedAnswer,
          formData: dataToPass
        })
      } else {
        saveSurvey({ unlock })
      }
    })
  }

  useEffect(() => {
    if (
      survey &&
      survey.autosave &&
      !(isAboutMe && errorsPresent) &&
      !isDisabled
    ) {
      const minutes = Number(survey.autosave)
      const handle = setInterval(checkSurveySave, 60000 * minutes)
      return () => {
        clearInterval(handle)
      }
    }
  }, [currentStep, survey, overrideWarningData])

  const presentHeaders = []
  let stepIndex = 0
  const isVertical = data.length > 4
  const organization = useSelector(state => state.organization)
  let roleNoPermission = false
  if (user.organizationMember) {
    roleNoPermission = user.organizationMember.TeamMemberRole === 'Associate'
  }
  if (!organization.id) {
    roleNoPermission = true
  }

  const printSurvey = Boolean(
    survey.surveyState === 'Submitted' || granteeReport
  )
  const noSteps = data.length < 2

  const periodStart = moment
    .utc(props.granteeReportPeriodStart)
    .format(userToFormat(user))
  const periodEnd = moment.utc().format(userToFormat(user))
  const sectionSkipped =
    currentTemplate &&
    values.skippedSections &&
    values.skippedSections[currentTemplate.templateId]
  const saveDisabled = isSubmitting || (isAboutMe && errorsPresent) || !dirty

  return (
    currentTemplate && (
      <Form className='m-sm-30'>
        <RedirectWarning open={dirty} handleSave={checkSurveySave} />
        <SaveWillOverrideWarningDialog
          handleSave={saveSurvey}
          handleClose={() => {
            setOverrideWarningData(null)
            setSubmitting(false)
          }}
          open={Boolean(overrideWarningData)}
          data={overrideWarningData}
        />

        {Boolean(
          survey.surveyState === 'Submitted' && (scoreCardExists || isSAT)
        ) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Button
              color='primary'
              variant='contained'
              onClick={e => {
                history.push(
                  `/surveys/SurveyScore/${survey.id}/${survey.templateId}`
                )
              }}
            >
              <Grid container justifyContent='center' alignItems='center'>
                <Trans>Go to score card</Trans>
                <Icon style={{ marginLeft: 5 }}>bar_chart</Icon>
              </Grid>
            </Button>
          </div>
        )}
        {printSurvey && (
          <>
            <Grid container direction='row-reverse' style={{ flexGrow: 1 }}>
              <ReactToPrint
                onBeforePrint={() =>
                  (document.title =
                    printTitle ||
                    survey.title +
                      ', ' +
                      moment.utc(survey.modifiedDate).format(dateFormat))
                }
                trigger={() => (
                  <Button
                    variant='contained'
                    color='primary'
                    style={{ marginBottom: 15 }}
                  >
                    <Trans>Print</Trans>
                    <Icon style={{ marginLeft: 5 }}>print</Icon>
                  </Button>
                )}
                content={() => printRef.current}
              />
            </Grid>
            <div ref={printRef} className='hideInPrint'>
              <Grid
                wrap='nowrap'
                className='titleContainer'
                container
                direction='row'
                justify='center'
                alignItems='center'
              >
                {opportunity && (
                  <img
                    alt=''
                    title={opportunity.info.fundTypeName}
                    src={fundLogos[opportunity.info.fundTypeName]}
                    style={{
                      width: '100px',
                      height: '100px',
                      margin: 10
                    }}
                  />
                )}
                <Grid
                  style={{
                    marginLeft: 15,
                    maxWidth: 800
                  }}
                  container
                  direction='column'
                >
                  <div>
                    <b>
                      <Trans>Organization name:</Trans>
                    </b>{' '}
                    {organization.organisationsName}
                  </div>
                  <div>
                    <b>
                      <Trans>ASSESMENT_NAME</Trans>
                    </b>
                    {': '}
                    {survey.title}
                  </div>

                  <div>
                    <b>
                      <Trans>Submission date:</Trans>
                    </b>{' '}
                    {moment.utc(survey.modifiedDate).format('YYYY-MM-DD')}
                  </div>
                </Grid>
              </Grid>
              {surveyToPrint(values, data, disableFields, user)}
            </div>
          </>
        )}
        {isLocked && (
          <Alert severity='warning' style={{ marginBottom: 10 }}>
            <Trans>
              This survey is currently locked by another user. You can access it
              read only form
            </Trans>
          </Alert>
        )}
        {data.length > 1 && (
          <Stepper
            alternativeLabel={!isVertical}
            nonLinear
            activeStep={currentStep}
            ref={stepperRef}
            orientation={isVertical ? 'vertical' : 'horizontal'}
          >
            {data.map((template, index) => {
              const notAllDisabled = template.questions.some(question => {
                return (
                  !disableFields.includes(question.id) &&
                  question.type !== HEADER
                )
              })
              if (
                template.questions.length === 0 ||
                disableFields.includes(template.templateId) ||
                !notAllDisabled
              ) {
                return null
              }
              const toSet = stepIndex
              stepIndex++
              presentHeaders.push(template.header)
              return (
                <Step
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCurrentStep(toSet)
                  }}
                >
                  <StepLabel>{template.header}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        )}
        {isGranteeReport && (
          <div style={{ padding: 10 }}>
            <Typography style={{ textAlign: 'center' }} variant='h5'>
              {survey.title} {granteeReport && granteeReport.reportIndex}
            </Typography>
            <Typography style={{ textAlign: 'center' }} variant='h6'>
              <Trans>
                Report covers time period from <b>{periodStart}</b> to{' '}
                <b>{periodEnd}</b>
              </Trans>
            </Typography>
          </div>
        )}
        <div style={{ textAlign: 'center', margin: 10 }}>
          <h1>{currentTemplate.header}</h1>
          {currentTemplate.tooltip && (
            <Typography>{currentTemplate.tooltip}</Typography>
          )}
          {currentTemplate.skipSection &&
            currentTemplate.skipSection.length > 0 && (
              <FormControlLabel
                checked={Boolean(sectionSkipped)}
                onChange={e => {
                  const toSet = { ...values.skippedSections }
                  const add = e.target.checked
                  const categories = currentTemplate.skipSection
                  const id = currentTemplate.templateId
                  if (!add) {
                    delete toSet[id]
                  } else {
                    toSet[id] = categories
                  }
                  setFieldValue('skippedSections', toSet)
                }}
                control={<Checkbox />}
                labelPlacement='end'
                label={<Trans>Skip section?</Trans>}
              />
            )}
          {currentTemplate.questions.map((item, qIndex) => {
            item.groupQuestionsToDisable = disableFields
            const error = errors[item.id]
            const errorIsArray = Boolean(error && Array.isArray(error))
            const nonApplicableValue = values[item.id + nonApplicableSuffix]
            const disabled =
              isDisabled ||
              sectionSkipped ||
              Boolean(nonApplicableValue && !Array.isArray(nonApplicableValue))
            const nonApplicableDisabled = isDisabled || sectionSkipped
            const showCheckbox =
              item.nonApplicable &&
              !questionTypes[item.type].nonApplicableAsOption
            let toRet = [
              <QuestionTitle
                key={item.id + 'title'}
                nonApplicable={nonApplicableValue}
                question={item}
                errors={!disabled && errors}
                displayIndex={currentIndex + 1}
                displayEN={props.user.language === 'en_US'}
              />,
              <QuestionWidget
                surveyId={survey.id}
                network={network}
                errorIsArray={errorIsArray}
                entityFiles={entityFiles}
                opportunity={opportunity}
                hideError={sectionSkipped}
                completedSurveys={completedSurveys}
                displayEN={props.user.language === 'en_US'}
                questionValue={values[item.id]}
                setFieldValue={setFieldValue}
                disabled={disabled}
                disableFields={disableFields}
                nonApplicable={nonApplicableValue}
                nonApplicableDisabled={nonApplicableDisabled}
                question={item}
                index={item.id}
                key={item.id + 'question'}
                className={classes.question}
              />,
              showCheckbox && (
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  style={{ marginLeft: 20 }}
                  key={item.id}
                >
                  <FormikCheckboxField
                    disabled={survey.surveyState === 'Submitted'}
                    style={{ marginLeft: 5 }}
                    name={`${item.id}` + nonApplicableSuffix}
                    row
                    controlLabel={<Trans>Is non applicable</Trans>}
                    onChange={e => {
                      setFieldValue(item.id, questionToInitial(item)[1])
                    }}
                  />
                </Grid>
              ),
              <div className='py-12' key={`${qIndex}-spacer`} />
            ]
            if (disableFields.includes(item.id)) {
              toRet = null
            }
            if (item.type !== 'HEADER') {
              currentIndex++
            }
            return toRet
          })}
        </div>
        {!isDisabled && isAboutMe && (
          <PopUpDialogue
            open={isSubmitting}
            dialogTitle={<Trans>Survey submitted</Trans>}
            okText={<Trans>Return to homepage</Trans>}
            closeFunction={() => {
              history.push('/')
            }}
            dialogMessege={
              <Trans>
                Thank you for updating your external reviewer profile. The
                information has been updated within our directory, and we will
                follow up with you when a project becomes available.
              </Trans>
            }
          />
        )}

        {data.length > 1 && (
          <Grid
            container
            direction='row'
            style={{
              margin: 10
            }}
          >
            <Grid item xs={6} style={{ paddingRight: 5 }}>
              <Button
                fullWidth
                className={classes.saveButton}
                variant='contained'
                color='primary'
                disabled={isSubmitting || currentStep === 0}
                onClick={() => {
                  setCurrentStep(currentStep - 1)
                }}
              >
                <Icon style={{ marginRight: 5 }}>arrow_back</Icon>
                <Trans>Back</Trans>
              </Button>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 5 }}>
              <Button
                fullWidth
                className={classes.saveButton}
                variant='contained'
                color='primary'
                disabled={isSubmitting || currentStep === stepIndex - 1}
                onClick={() => {
                  setCurrentStep(currentStep + 1)
                }}
              >
                <Trans>Next</Trans>
                <Icon style={{ marginLeft: 5 }}>arrow_forward</Icon>
              </Button>
            </Grid>
          </Grid>
        )}

        {!isDisabled && (
          <>
            {!noSave &&
              SaveButton({
                formikState,
                isGranteeReport,
                classes,
                saveSurvey: checkSurveySave,
                disabled: saveDisabled,
                isConfirm
              })}

            {Boolean(!noSave && survey.locked) &&
              SaveButton({
                formikState,
                unlock: true,
                isGranteeReport,
                classes,
                saveSurvey: () => {
                  checkSurveySave({ unlock: true })
                },
                disabled: saveDisabled,
                isConfirm
              })}

            {showApply && (
              <Button
                fullWidth
                className={classes.submitButton}
                variant='contained'
                color='secondary'
                disabled={isSubmitting || errorsPresent}
                onClick={() => {
                  const newValues = { ...formikState.values }
                  newValues.submitButtonType = 'Applied'
                  newValues.disableFields = disableFields
                  formikState.setValues(newValues)
                  submitSurvey(newValues, {
                    setSubmitting: formikState.setSubmitting,
                    resetForm: formikState.resetForm
                  })
                  // formikState.handleSubmit()
                }}
              >
                <Trans>Apply</Trans>
              </Button>
            )}
            <ConfirmationDialog
              user={user}
              opportunity={opportunity}
              values={formikState.values}
              open={confirmationDialogOpened && isGranteeReport}
              handleCancel={() => {
                confirmDialog(false)
                const toSet = { ...formikState.values }
                toSet.checkbox1 = false
                toSet.checkbox2 = false
                formikState.setValues(toSet)
              }}
              submitCallback={() => {
                const newValues = { ...formikState.values }
                newValues.submitButtonType = 'Submitted'
                newValues.disableFields = disableFields
                formikState.setValues(newValues)
                submitSurvey(newValues, {
                  setSubmitting: formikState.setSubmitting,
                  resetForm: formikState.resetForm
                })
                // formikState.handleSubmit()
              }}
            />

            {(currentStep === stepIndex - 1 || noSteps) && !noSubmit && (
              <Button
                fullWidth
                className={classes.submitButton}
                variant='contained'
                color='primary'
                disabled={
                  isSubmitting ||
                  submitDisabled ||
                  errorsPresent ||
                  (isGranteeReport && roleNoPermission)
                }
                onClick={() => {
                  if (isGranteeReport) {
                    confirmDialog(true)
                  } else {
                    const newValues = { ...formikState.values }
                    newValues.submitButtonType = 'Submitted'
                    newValues.disableFields = disableFields
                    formikState.setValues(newValues)
                    submitSurvey(newValues, {
                      setSubmitting: formikState.setSubmitting,
                      resetForm: formikState.resetForm
                    })
                    // formikState.handleSubmit()
                  }
                }}
              >
                <Trans>Submit</Trans>
              </Button>
            )}
            {currentStep === stepIndex - 1 &&
              errorsPresent &&
              (!noSubmit || showApply || isAboutMe) && (
                <Typography style={{ color: 'red', textAlign: 'center' }}>
                  <b>
                    <Trans>
                      You cannot submit this survey - you have to answer all
                      questions marked as required!
                    </Trans>
                  </b>
                  <div style={{ margin: 10 }}>
                    {Object.keys(missingQuestion).map((key, index) => {
                      const q = missingQuestion[key].question
                      if (
                        disableFields.includes(key) ||
                        values[key + nonApplicableSuffix]
                      ) {
                        return null
                      }
                      return (
                        <div key={index}>
                          <span>
                            {q.templateName +
                              ': ' +
                              q.questionIndex +
                              '. ' +
                              q.text}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                </Typography>
              )}
            {currentStep === stepIndex - 1 &&
              isGranteeReport &&
              roleNoPermission && (
                <Typography style={{ color: 'red', textAlign: 'center' }}>
                  <b>
                    <Trans>
                      You current organization access is not eligible for
                      submitting Grantee Report!
                    </Trans>
                  </b>
                </Typography>
              )}
          </>
        )}
        {isDisabled && !embedded && (
          <Button
            fullWidth
            className={classes.submitButton}
            variant='contained'
            color='primary'
            onClick={() => {
              history.goBack()
            }}
          >
            <Icon
              style={{
                fontSize: '27px',
                marginLeft: 8,
                verticalAlign: 'middle'
              }}
            >
              keyboard_arrow_left
            </Icon>
            <Trans>Back</Trans>
          </Button>
        )}
      </Form>
    )
  )
}

const ConfirmationDialog = ({
  open,
  submitCallback,
  opportunity,
  values,
  user,
  handleCancel
}) => {
  const canSubmit = values.checkbox1 && values.checkbox2

  if (!opportunity || !user) {
    return null
  }

  return (
    <I18n>
      {({ i18n }) => (
        <Dialog open={open}>
          <DialogTitle>
            <Typography variant='h6'>
              <Trans>Confirm submission</Trans>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <FormikCheckboxField
              name='checkbox1'
              style={{ marginLeft: 10 }}
              FormControlLabelProps={{ labelPlacement: 'end' }}
              controlLabel={
                i18n._(
                  t`I solemnly declare that the information contained in this report as an honest and complete representation of the project progress for`
                ) +
                ' ' +
                opportunity?.info.projectName
              }
            />
            <FormikCheckboxField
              name='checkbox2'
              style={{ marginLeft: 10, marginTop: 20, marginBottom: 20 }}
              FormControlLabelProps={{ labelPlacement: 'end' }}
              controlLabel={
                i18n._(
                  t`I affirm that I have the authority to sign this document on behalf of`
                ) +
                ' ' +
                opportunity?.info.accountName
              }
            />
            <div style={{ marginLeft: 20 }}>
              <Typography>
                <b>{user.displayName}</b>
              </Typography>
              <Typography>
                {user.userObject && user.userObject.jobTitle}
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!canSubmit}
              onClick={() => {
                handleCancel()
                submitCallback()
              }}
              variant='contained'
              color='primary'
            >
              <Trans>Submit</Trans>
            </Button>
            <Button onClick={handleCancel} variant='contained' color='primary'>
              <Trans>Cancel</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </I18n>
  )
}

const SaveButton = ({
  disabled,
  classes,
  saveSurvey,
  formikState,
  unlock,
  isConfirm = false
}) => {
  let label = <Trans>Save</Trans>
  if (isConfirm) {
    label = <Trans>Confirm</Trans>
  } else if (unlock) {
    label = myI18n._(t`Save & close`)
  }

  return (
    <Button
      fullWidth
      className={classes.submitButton}
      variant='contained'
      color='secondary'
      disabled={disabled || formikState.isSubmitting}
      onClick={saveSurvey}
    >
      {label}
    </Button>
  )
}

const checkConditionalsBasedOnObject = ({ question, opportunity }) => {
  if (question.conditionalObjectName === 'opportunity') {
    if (question.conditionalObjectSubfield) {
      const toCheck = question.conditionalObjectSubfield
      const oppField = opportunity[question.conditionalObjectField]
      if (typeof oppField === 'object') {
        return oppField.includes(toCheck)
      } else {
        return oppField === toCheck
      }
    } else {
      return Boolean(opportunity[question.conditionalObjectField])
      // const toCheck = question.conditionalObjectField
      // const oppFieldValue = opportunity[question.conditionalObjectField]
      // if (typeof oppFieldValue === 'object') {
      //   return oppFieldValue.includes(toCheck)
      // } else {
      //   return Boolean(oppFieldValue)
      // }
    }
  }
  return false
}

const checkExternalConditions = ({
  question,
  disableFields,
  external,
  values
}) => {
  const toAdd = external
    ? external.disableWholeTemplate
      ? external.template
      : external.disableQuestion
    : false
  if (!external || !toAdd) {
    return
  }
  const fieldValue = values[question.id]
  if (typeof fieldValue === 'object' && fieldValue) {
    console.log(external, fieldValue)
    fieldValue.forEach((option, index) => {
      if (index === external.option) {
        if (external.isSelected && option) {
          disableFields.push(toAdd)
        } else if (!external.isSelected && !option) {
          disableFields.push(toAdd)
        }
      } else if (external.option === fieldValue[index]) {
        if (external.isSelected && index === external.groupQuestion) {
          if (
            !external.disableWholeTemplate &&
            (external.disableQuestionInGroup ||
              external.disableQuestionInGroup === 0)
          ) {
            disableFields.push(external.disableQuestionInGroup)
          } else {
            disableFields.push(toAdd)
          }
        } else if (
          !external.isSelected &&
          !(external.option === fieldValue[index]) &&
          index === external.groupQuestion
        ) {
          if (!external.disableWholeTemplate) {
            disableFields.push(external.disableQuestionInGroup)
          } else {
            disableFields.push(toAdd)
          }
        }
      } else if (
        !external.isSelected &&
        !(external.option === fieldValue[index]) &&
        index === external.groupQuestion
      ) {
        if (
          !external.disableWholeTemplate &&
          (external.disableQuestionInGroup ||
            external.disableQuestionInGroup === 0)
        ) {
          disableFields.push(external.disableQuestionInGroup)
        } else {
          disableFields.push(toAdd)
        }
      }
    })
  } else if (external.isSelected && fieldValue === external.option) {
    if (
      !external.disableWholeTemplate &&
      (external.disableQuestionInGroup || external.disableQuestionInGroup === 0)
    ) {
      disableFields.push(external.disableQuestionInGroup)
    } else {
      disableFields.push(toAdd)
    }
  } else if (!external.isSelected && fieldValue !== external.option) {
    if (
      !external.disableWholeTemplate &&
      (external.disableQuestionInGroup || external.disableQuestionInGroup === 0)
    ) {
      disableFields.push(external.disableQuestionInGroup)
    } else {
      disableFields.push(toAdd)
    }
  }
}

const checkConditions = ({ condition, values, disableFields, template }) => {
  if (!condition || condition === {}) {
    return
  }
  // if (!disableFields[template.templateId]) {
  //   disableFields[template.templateId] = []
  // }
  if (condition.isSelected) {
    if (
      typeof values[condition.question] === 'object' &&
      values[condition.question] !== null
    ) {
      if (condition.groupQuestion || condition.groupQuestion === 0) {
        if (
          values[condition.question][condition.groupQuestion] ===
          condition.option
        ) {
          disableFields.push(condition.disableQuestionId)
        }
      } else if (values[condition.question][condition.option]) {
        disableFields.push(condition.disableQuestionId)
      }
    } else {
      if (values[condition.question] === condition.option) {
        disableFields.push(condition.disableQuestionId)
      }
    }
  } else {
    if (
      typeof values[condition.question] === 'object' &&
      values[condition.question] !== null
    ) {
      if (condition.groupQuestion || condition.groupQuestion === 0) {
        if (
          values[condition.question][condition.groupQuestion] !==
          condition.option
        ) {
          disableFields.push(condition.disableQuestionId)
        }
      } else if (!values[condition.question][condition.option]) {
        disableFields.push(condition.disableQuestionId)
      }
    } else if (values[condition.question] !== condition.option) {
      disableFields.push(condition.disableQuestionId)
    }
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organization: state.organization
})

export default withStyles(styles)(
  connect(mapStateToProps, null, null, { forwardRef: true })(SurveyTab)
)
