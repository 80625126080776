import { grpc } from '@improbable-eng/grpc-web'
import { host } from 'app/views/forms/multiuser/grpcMultiuserEdit'
import { UserSessionRequest } from 'app/views/forms/multiuser/proto/generated/Multiuser_pb'
import { Multiuser } from 'app/views/forms/multiuser/proto/generated/Multiuser_pb_service'

export function grpcGlobalLogin ({
  username,
  token,
  userId,
  userInfo,
  onFail,
  onSuccess
}) {
  const request = new UserSessionRequest()
  request.setRealmpath('+')
  request.setUserdata(userInfo)
  request.setUsername(username)
  request.setToken(token)
  request.setUserid(userId)
  request.setMode('Global')

  grpc.unary(Multiuser.LogIn, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
          if (response.trailers.has('Exception')) {
            console.log('exception caught', response.trailers.get('Exception'))
          }
        }
      } else {
        if (onSuccess) {
          onSuccess({
            sessionToken: response.message.array[0]
          })
        }
      }
    }
  })
}
