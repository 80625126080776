import { configurationTypes } from 'app/views/internal/ConfigureApplicationTypes'
import { datetimeFormat } from 'app/appSettings'
import moment from 'moment'
import { getTitleTranslation, isValidJSON } from 'utils'
import SFAuthService, { NO_USER } from '..//SFAuthService'
import { textFields } from './sfSurvey'
import { getUsersByFlow } from './sfUser'

export const FORM_VERSION_ERROR = 'VERSION_ALREADY_EXISTS'
export const FORM_PREVIEW_CONFIG = 'FORM_PREVIEW_CONFIG'

export const savePreviewConfig = config => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Configuration__c')
    .find({ Name: FORM_PREVIEW_CONFIG })
    .then(result => {
      if (result.length > 0) {
        const id = result[0].Id
        return conn.sobject('Configuration__c').update({
          Id: id,
          Value__c: JSON.stringify(config)
        })
      } else {
        return conn.sobject('Configuration__c').create({
          Name: FORM_PREVIEW_CONFIG,
          Value__c: JSON.stringify(config)
        })
      }
    })
}

export const fetchFormPage = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  if (!id) {
    return null
  }
  return conn
    .sobject('Form_Page__c')
    .find({ Id: id })
    .then(result => {
      const form = result[0]
      if (!form) {
        return null
      }
      return conn
        .sobject('SurveyTranslation__c')
        .find({
          Id: form.Title_Translation__c
        })
        .select('Id')
        .include('Survey_Texts__r')
        .select(textFields.join(', '))
        .end()
        .then(translations => {
          const trans = translations[0]
          let en
          let fr
          let enId
          let frId
          trans?.Survey_Texts__r.records.forEach(text => {
            if (text.Language__c === 'en-ca') {
              en = text.Text__c
              enId = text.Id
            } else if (text.Language__c === 'fr-ca') {
              fr = text.Text__c
              frId = text.Id
            }
          })
          let toPass
          if (isValidJSON(form.Data__c)) {
            toPass = JSON.parse(form.Data__c)
          } else {
            const pako = require('pako')
            const gzip = Buffer.from(form.Data__c, 'base64')
            toPass = pako.ungzip(gzip, { to: 'string' })
            toPass = JSON.parse(toPass)
          }
          return {
            ...toPass,
            holdsReusableElements: form.Holds_Reusable_Elements__c,
            comments: form.Comments__c,
            origin: form.Origin__c,
            titleEN: en,
            titleFR: fr,
            id: form.Id,
            textEN_Id: enId,
            textFR_Id: frId
          }
        })
    })
}

export const removeOriginFromFormPage = ({ id, origin }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Form_Page__c')
    .find({
      Origin__c: origin
    })
    .then(forms => {
      if (forms.length === 2) {
        return conn
          .sobject('Form_Page__c')
          .update(forms.map(form => ({ Id: form.Id, Origin__c: null })))
      } else {
        return conn.sobject('Form_Page__c').update({
          Id: id,
          Origin__c: null
        })
      }
    })
}

export const updateFormPage = toUpdate => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Form_Page__c').update({
    ...toUpdate
  })
}

export const compressFormData = data => {
  const toSave = {
    sections: data.sections,
    injectableComponents: data.injectableComponents,
    autosave: data.autosave,
    version: data.version,
    enableMultiuser: data.enableMultiuser,
    pdfProps: data.pdfProps,
    style: data.style,
    formType: data.formType,
    showPrintButton: data.showPrintButton,
    objectsConnected: data.objectsConnected,
    // showPdfDownload: data.showPdfDownload,
    // readOnly: data.readOnly,
    displaySaveFailedDialog: data.displaySaveFailedDialog,
    displayUnsavedWarning: data.displayUnsavedWarning,
    displayesSavedInMeantimeWarning: data.displayesSavedInMeantimeWarning,
    restrictAccessForRoles: data.restrictAccessForRoles,
    restrictAccessType: data.restrictAccessType
  }
  const json = JSON.stringify(toSave)
  const pako = require('pako')
  const gzip = pako.gzip(json)
  var b64 = Buffer.from(gzip).toString('base64')
  return b64
}

export const uncompressFormData = data => {
  const pako = require('pako')
  const gzip = Buffer.from(data, 'base64')
  const uncompressed = pako.ungzip(gzip, { to: 'string' })
  return JSON.parse(uncompressed)
}

export const saveFormPage = data => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const updatePromise = () => {
    const saveTitleEN = conn.sobject('Survey_Text__c').update({
      Id: data.textEN_Id,
      Text__c: data.titleEN
    })
    const saveTitleFR = conn.sobject('Survey_Text__c').update({
      Id: data.textFR_Id,
      Text__c: data.titleFR
    })
    const saveForm = conn.sobject('Form_Page__c').update({
      Id: data.id,
      Comments__c: data.comments,
      Data__c: compressFormData(data)
    })
    return Promise.all([saveTitleEN, saveTitleFR, saveForm])
  }

  if (data.origin) {
    return checkIfFormVersionExists({
      origin: data.origin,
      id: data.id,
      version: data.version
    }).then(result => {
      return updatePromise()
    })
  } else {
    return updatePromise()
  }
}

export const checkNextAvaliableFormVersion = ({ origin, id, version }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Form_Page__c')
    .find({
      Origin__c: origin
    })
    .then(forms => {
      const versions = forms.map(form => {
        let savedData
        if (isValidJSON(form.Data__c)) {
          savedData = JSON.parse(form.Data__c)
        } else {
          const pako = require('pako')
          const gzip = Buffer.from(form.Data__c, 'base64')
          savedData = pako.ungzip(gzip, { to: 'string' })
          savedData = JSON.parse(savedData)
        }
        return +savedData.version
      })
      let toRet
      let loopIndex = 0
      while (!toRet) {
        loopIndex++
        if (!versions.includes(loopIndex)) {
          toRet = loopIndex
        }
      }
      return toRet
    })
}

export const checkIfFormVersionExists = ({ origin, id, version }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Form_Page__c')
    .find({
      Origin__c: origin
    })
    .then(forms => {
      const versionAlready = forms.some(form => {
        let savedData
        if (isValidJSON(form.Data__c)) {
          savedData = JSON.parse(form.Data__c)
        } else {
          const pako = require('pako')
          const gzip = Buffer.from(form.Data__c, 'base64')
          savedData = pako.ungzip(gzip, { to: 'string' })
          savedData = JSON.parse(savedData)
        }
        if (id) {
          return +savedData.version === +version && form.Id !== id
        } else {
          return +savedData.version === +version
        }
      })
      if (versionAlready) {
        return Promise.reject(FORM_VERSION_ERROR)
      } else {
        return Promise.resolve()
      }
    })
}

export const fetchReusableFormPage = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Form_Page__c')
    .find({
      Holds_Reusable_Elements__c: true
    })
    .select('Id, Data__c')
    .then(result => {
      const form = result[0]
      let toPass
      if (!form) {
        return null
      }
      if (form.Data__c && isValidJSON(form.Data__c)) {
        toPass = JSON.parse(form.Data__c)
      } else {
        const pako = require('pako')
        const gzip = Buffer.from(form.Data__c, 'base64')
        toPass = pako.ungzip(gzip, { to: 'string' })
        toPass = JSON.parse(toPass)
      }
      return {
        id: form.Id,
        config: toPass || {}
      }
    })
}

export const fetchFormPages = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Form_Page__c')
    .find({
      Holds_Reusable_Elements__c: false
    })
    .select(
      'Id, Title_Translation__c, Origin__c, Data__c, Comments__c, LastModifiedDate, LastModifiedById'
    )
    .then(result => {
      const matchObj = {}
      const userMatchObj = {}
      const translationIds = []
      const lastModifiedUsers = []
      // console.log('got form forms', result)
      result.forEach(obj => {
        translationIds.push(obj.Title_Translation__c)
        matchObj[obj.Title_Translation__c] = obj
        if (!lastModifiedUsers.includes(obj.LastModifiedById)) {
          lastModifiedUsers.push(obj.LastModifiedById)
        }
      })
      return Promise.all([
        conn
          .sobject('SurveyTranslation__c')
          .find({
            Id: { $in: translationIds }
          })
          .select('Id')
          .include('Survey_Texts__r')
          .select(textFields.join(', '))
          .end(),
        getUsersByFlow(lastModifiedUsers)
      ]).then(([translations, users = []]) => {
        // console.log('got form translations', translations)
        const translationsMatchObject = {}
        translations.forEach(trans => {
          translationsMatchObject[trans.Id] = trans
        })
        users.forEach(user => {
          userMatchObj[user.Id] = user.Name
        })
        return result
          .map(form => {
            const translation =
              translationsMatchObject[form.Title_Translation__c]
            let toPass
            if (isValidJSON(form.Data__c)) {
              toPass = JSON.parse(form.Data__c)
            } else {
              const pako = require('pako')
              const gzip = Buffer.from(form.Data__c, 'base64')
              toPass = pako.ungzip(gzip, { to: 'string' })
              toPass = JSON.parse(toPass)
            }
            return {
              en: getTitleTranslation('en_US', translation),
              fr: getTitleTranslation('fr', translation),
              lastModifiedBy: userMatchObj[form.LastModifiedById],
              lastModifiedDate: form.LastModifiedDate,
              comments: form.Comments__c,
              origin: form.Origin__c,
              id: form.Id,
              config: toPass || {}
            }
          })
          .filter(item => item)
      })
    })
}

export const createFormPage = ({ titleEN, titleFR, origin, data = {} }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const json = JSON.stringify(data)
  const pako = require('pako')
  const gzip = pako.gzip(json)
  var b64 = Buffer.from(gzip).toString('base64')
  return conn
    .sobject('SurveyTranslation__c')
    .create({})
    .then(translation => {
      const transTextFR = conn.sobject('Survey_Text__c').create({
        Survey_Translation__c: translation.id,
        Text__c: titleFR,
        Language__c: 'fr-ca'
      })
      const transTextEN = conn.sobject('Survey_Text__c').create({
        Survey_Translation__c: translation.id,
        Text__c: titleEN,
        Language__c: 'en-ca'
      })
      return Promise.all([
        transTextEN,
        transTextFR,
        conn.sobject('Form_Page__c').create({
          Title_Translation__c: translation.id,
          Origin__c: origin,
          Data__c: b64
        })
      ])
    })
}

export const deleteFormPage = (id, origin) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Form_Page__c')
    .delete(id)
    .then(result => {
      if (!origin) {
        return result
      } else {
        return conn
          .sobject('Form_Page__c')
          .find({
            Origin__c: origin
          })
          .then(forms => {
            if (forms.length === 1) {
              return conn.sobject('Form_Page__c').update({
                Id: forms[0].Id,
                Origin__c: null
              })
            } else {
              return result
            }
          })
      }
    })
}

export const fetchConfigurations = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Configuration__c').find({})
}

export const fetchConfiguration = (searchProps = {}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Configuration__c')
    .find({ ...searchProps })
    .then(result => result[0])
}

export const fetchApplicationConfigurations = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Configuration__c')
    .find({})
    .then(result => {
      return result
        .filter(item => item.Name === 'AVAILABLE_APPLICATIONS')
        .map(item => ({ ...JSON.parse(item.Value__c), id: item.Id }))
    })
}

export const deleteApplicationConfig = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Configuration__c').delete(id)
}

export const saveApplicationConfig = data => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const toUpdate = []
  const toCreate = []
  console.log('save', data.configList)
  data.configList.forEach((dataToSave, index) => {
    const item = { ...dataToSave }
    delete item.expanded
    item.order = index
    if (item.startTime) {
      const date = moment.utc(item.startTime)
      const local = date.local(true)
      item.startTime = moment.utc(local)
    }
    if (item.endTime) {
      const date = moment.utc(item.endTime)
      const local = date.local(true)
      item.endTime = moment.utc(local)
    }
    if (item.reminderTime) {
      const date = moment.utc(item.reminderTime)
      const local = date.local(true)
      item.reminderTime = moment.utc(local)
    }
    const objName = item.type && configurationTypes[item.type].name
    if (!item.id) {
      toCreate.push({
        Value__c: JSON.stringify(item),
        Name: objName
      })
    } else {
      const sfId = item.id
      delete item.id
      toUpdate.push({
        Id: sfId,
        Value__c: JSON.stringify(item),
        Name: objName
      })
    }
  })
  return Promise.all([
    conn.sobject('Configuration__c').create(toCreate),
    conn.requestPost('/actions/custom/flow/App_Update_Configurations', {
      inputs: [
        {
          toUpdate
        }
      ]
    })
  ])
}
