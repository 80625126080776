import SFAuthService, { NO_USER } from '../SFAuthService'

/**
 * Created by Michał Stawski on 25.11.2020.
 */
/*
0: {attributes: {…}, Id: "a1K3j000006F6X0EAK", Name: "Expenses", FGM_Portal__Parent_Category__c: null}
1: {attributes: {…}, Id: "a1K3j000006F6X5EAK", Name: "Personnel - Internal Wages and Benefits", FGM_Portal__Parent_Category__c: "a1K3j000006F6X0EAK"}
2: {attributes: {…}, Id: "a1K3j000006F6XFEA0", Name: "Personnel - External Professional/consultant", FGM_Portal__Parent_Category__c: "a1K3j000006F6X0EAK"}
3: {attributes: {…}, Id: "a1K3j000006F6XKEA0", Name: "Revenue", FGM_Portal__Parent_Category__c: null}
4: {attributes: {…}, Id: "a1K3j000006F6XPEA0", Name: "Transformation Centre Grant", FGM_Portal__Parent_Category__c: "a1K3j000006F6XKEA0"}
5: {attributes: {…}, Id: "a1K3j000006F6XUEA0", Name: "Other Grants", FGM_Portal__Parent_Category__c: "a1K3j000006F6XKEA0"}
6: {attributes: {…}, Id: "a1K3j000006F6XZEA0", Name: "Fundraising/Donations", FGM_Portal__Parent_Category__c: "a1K3j000006F6XKEA0"}
7: {attributes: {…}, Id: "a1K3j000006F6XeEAK", Name: "Internal Revenue", FGM_Portal__Parent_Category__c: "a1K3j000006F6XKEA0"}
8: {attributes: {…}, Id: "a1K5X0000004C9tUAE", Name: "Other", FGM_Portal__Parent_Category__c: "a1K3j000006F6XKEA0"}
9: {attributes: {…}, Id: "a1K5X0000004CADUA2", Name: "Direct costs (travel, materials, equipment, supplies, licensing, training costs)", FGM_Portal__Parent_Category__c: "a1K3j000006F6X0EAK"}
10: {attributes: {…}, Id: "a1K5X0000004CAXUA2", Name: "Indirect costs (Rental space, Insurance, Maintenance, etc...)", FGM_Portal__Parent_Category__c: "a1K3j000006F6X0EAK"}
11: {attributes: {…}, Id: "a1K5X0000004CAcUAM", Name: "Other", FGM_Portal__Parent_Category__c: "a1K3j000006F6X0EAK"}
12: {attributes: {…}, Id: "a1K5X0000004CAmUAM", Name: "Dépenses", FGM_Portal__Parent_Category__c: null}
13: {attributes: {…}, Id: "a1K5X0000004CArUAM", Name: "Personnel - Interne (Salaires, avantages sociaux…)", FGM_Portal__Parent_Category__c: "a1K5X0000004CAmUAM"}
14: {attributes: {…}, Id: "a1K5X0000004CAwUAM", Name: "Personnel – Externe (Honoraires des professionnels et consultants)", FGM_Portal__Parent_Category__c: "a1K5X0000004CAmUAM"}
15: {attributes: {…}, Id: "a1K5X0000004CB1UAM", Name: "Frais directs (déplacements, matériel et équipement, licences, etc)", FGM_Portal__Parent_Category__c: "a1K5X0000004CAmUAM"}
16: {attributes: {…}, Id: "a1K5X0000004CB6UAM", Name: "Frais indirects (loyer, assurances, maintenance…)", FGM_Portal__Parent_Category__c: "a1K5X0000004CAmUAM"}
17: {attributes: {…}, Id: "a1K5X0000004CBBUA2", Name: "Autre", FGM_Portal__Parent_Category__c: "a1K5X0000004CAmUAM"}
18: {attributes: {…}, Id: "a1K5X0000004CBGUA2", Name: "Revenu", FGM_Portal__Parent_Category__c: null}
19: {attributes: {…}, Id: "a1K5X0000004CBLUA2", Name: "Subvention du Centre de transformation du logement communautaire", FGM_Portal__Parent_Category__c: "a1K5X0000004CBGUA2"}
20: {attributes: {…}, Id: "a1K5X0000004CBQUA2", Name: "Autres subventions", FGM_Portal__Parent_Category__c: "a1K5X0000004CBGUA2"}
21: {attributes: {…}, Id: "a1K5X0000004CBVUA2", Name: "Campagnes de financement et dons", FGM_Portal__Parent_Category__c: "a1K5X0000004CBGUA2"}
22: {attributes: {…}, Id: "a1K5X0000004CBaUAM", Name: "Revenu interne", FGM_Portal__Parent_Category__c: "a1K5X0000004CBGUA2"}
23: {attributes: {…}, Id: "a1K5X0000004CBfUAM", Name: "Autre", FGM_Portal__Parent_Category__c: "a1K5X0000004CBGUA2"}
*/

const mapping = {
  Expenses: { lang: 'en_CA', category: 'expenses' },
  Revenue: { lang: 'en_CA', category: 'revenues' },
  Dépenses: { lang: 'fr_CA', category: 'expenses' },
  Revenu: { lang: 'fr_CA', category: 'revenues' },
}

export const getBudgetCategories = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('FGM_Portal__Grantee_Budget_Category__c')
    .select('Id, Name, FGM_Portal__Parent_Category__c')
    .autoFetch(true)
    .then(results => {
      let topLevel = {}
      let budgetCategoryMapping = {
        en_CA: {
          revenues: {},
          expenses: {},
        },
        fr_CA: {
          revenues: {},
          expenses: {},
        },
      }
      for (let i = 0; i < results.length; i++) {
        if (results[i].FGM_Portal__Parent_Category__c === null) {
          topLevel[results[i].Id] = results[i]
          topLevel[results[i].Id].category = mapping[results[i].Name]
          topLevel[results[i].Id].children = []

        }
      }
      for (let i = 0; i < results.length; i++) {
        if (results[i].FGM_Portal__Parent_Category__c !== null) {
          let parent = topLevel[results[i].FGM_Portal__Parent_Category__c]
          budgetCategoryMapping[parent.category.lang][parent.category.category][results[i].Name] =
            results[i].Id
        }
      }
      return budgetCategoryMapping
    })
}
