import { Trans, t } from '@lingui/macro'
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  Icon,
  Grid
} from '@material-ui/core'
import {
  Document,
  Font,
  PDFViewer,
  Page,
  Text,
  View,
  Image
} from '@react-pdf/renderer'
import {
  getSubtemplates,
  getSurveyByFlow
} from 'app/services/sfAuth/sfData/sfSurvey'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { sfDataToRenderData } from './SurveyTab'
import { useSelector } from 'react-redux'
import Loading from 'egret/components/EgretLoadable/Loading'
import { questionTypes } from './QuestionWidget'
import { myI18n } from 'translation/I18nConnectedProvider'
import { PdfCheckbox } from './pdfSurveyComponents/SurveyChoiceQuestionPdf'

const pdfSurveySectionFontSize = 15
export const pdfSurveyHeaderFontSize = 14
export const pdfSurveyQuestionMainFontSize = 12
const pdfSurveyTooltipFontSize = 10
export const pdfSurveyQuestionSubFontSize = 11

export default function PrintableReadyToFillSurvey ({ data }) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [templateData, setTemplateData] = useState(null)
  const [loading, setLoading] = useState(false)

  const configuration = useSelector(state => state.configuration)
  const user = useSelector(state => state.user)
  const langFR = user.language !== 'en_US'

  useEffect(() => {
    // This fixes issues with bold font not being used in first render of pdf
    Font.load({ fontFamily: 'Roboto' })
    Font.load({ fontFamily: 'Roboto', fontStyle: 'italic' })
    Font.load({ fontFamily: 'Roboto', fontWeight: 700 })
    Font.load({ fontFamily: 'Roboto', fontWeight: 700, fontStyle: 'italic' })
  }, [])

  const fetchData = () => {
    getSubtemplates(data.id)
      .then(result => {
        return sfDataToRenderData(result, data.id, {
          configuration,
          user
        })
      })
      .then(result => {
        console.log('loaded survey template data', result)
        setTemplateData(result)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (dialogOpen) {
      setLoading(true)
      fetchData()
    }
  }, [dialogOpen])

  let footnoteRendered = false
  return (
    <>
      <Dialog maxWidth='lg' fullWidth open={dialogOpen}>
        <DialogTitle>
          <Grid container justifyContent='space-between'>
            {data.name}
            <IconButton
              onClick={e => {
                setDialogOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ minHeight: '70vh', display: 'flex' }}>
          {loading && <Loading />}
          {!loading && templateData && (
            <PDFViewer showToolbar={true} style={{ flexGrow: 1 }}>
              <Document style={{ fontFamily: 'Roboto' }} title={data.name}>
                {templateData
                  .filter(template => template.questions.length > 0)
                  .map((template, index) => {
                    const { header, questions = [] } = template
                    let trueIndex = 0

                    const questionIdToQuestion = {}
                    const questionIdToConditionsTargeting = {}
                    let displayIndex = 0

                    questions
                      .filter(
                        question => questionTypes[question.type].pdfComponent
                      )
                      .forEach((question, index) => {
                        const { type, conditionsToCheck = [] } = question
                        if (type !== 'HEADER') {
                          displayIndex++
                          questionIdToQuestion[question.id] = {
                            ...question,
                            displayIndex
                          }
                        }
                        if (conditionsToCheck) {
                          conditionsToCheck.forEach(condition => {
                            if (
                              questionIdToConditionsTargeting[
                                condition.disableQuestionId
                              ]
                            ) {
                              questionIdToConditionsTargeting[
                                condition.disableQuestionId
                              ].push(condition)
                            } else {
                              questionIdToConditionsTargeting[
                                condition.disableQuestionId
                              ] = [condition]
                            }
                          })
                        }
                      })

                    let headerComponent = null
                    return (
                      <Page
                        key={index}
                        style={{
                          padding: '1.27cm',
                          fontFamily: 'Roboto'
                        }}
                      >
                        {index === 0 && (
                          <>
                            <View
                              style={{
                                width: 167,
                                height: 44,
                                position: 'absolute',
                                top: 25,
                                right: 25
                              }}
                            >
                              <Image src={'/assets/images/chtc_logo_2.png'} />
                            </View>
                            <Text
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: 'bold',
                                fontSize: 18,
                                paddingTop: 70,
                                textAlign: 'center',
                                paddingBottom: 30
                              }}
                            >
                              {data.name}
                            </Text>
                          </>
                        )}
                        <Text
                          style={{
                            textAlign: 'center',
                            paddingBottom: 10,
                            fontSize: pdfSurveySectionFontSize,
                            fontFamily: 'Roboto'
                          }}
                        >
                          {header}
                        </Text>
                        <View
                          fixed
                          style={{
                            textAlign: 'right',
                            right: '1.27cm',
                            bottom: '1.27cm',
                            position: 'absolute'
                          }}
                          render={({ pageNumber, totalPages }) => (
                            <Text
                              style={{
                                fontFamily: 'Roboto',
                                fontSize: pdfSurveyQuestionSubFontSize
                              }}
                            >
                              {pageNumber + '/' + totalPages}
                            </Text>
                          )}
                        />
                        {questions
                          .filter(
                            question =>
                              questionTypes[question.type].pdfComponent
                          )
                          .map((question, index) => {
                            const { type, nonApplicable, isRequired, isGroup } =
                              question
                            const Class =
                              questionTypes[question.type].pdfComponent
                            if (type !== 'HEADER') {
                              trueIndex++
                            } else {
                              headerComponent = (
                                <Class langFR={langFR} {...question} />
                              )
                              return null
                            }
                            const toRet = (
                              <View
                                style={{ paddingTop: 16 }}
                                wrap={false}
                                key={trueIndex}
                              >
                                {headerComponent}
                                {type !== 'HEADER' && (
                                  <Text
                                    style={{
                                      fontFamily: 'Roboto',
                                      fontSize: pdfSurveyQuestionMainFontSize,
                                      paddingBottom: 8,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    {trueIndex + '. ' + question.text}
                                    {isRequired && (
                                      <Text style={{ color: 'red' }}>*</Text>
                                    )}
                                  </Text>
                                )}
                                {getTooltipText({
                                  question,
                                  questionIdToQuestion,
                                  questionIdToConditionsTargeting,
                                  langFR
                                }) && (
                                  <Text
                                    style={{
                                      fontWeight: 'thin',
                                      fontFamily: 'Roboto',
                                      paddingLeft: 10,
                                      fontSize: pdfSurveyTooltipFontSize
                                    }}
                                  >
                                    {getTooltipText({
                                      question,
                                      questionIdToQuestion,
                                      questionIdToConditionsTargeting,
                                      langFR
                                    })}
                                  </Text>
                                )}
                                <View
                                  style={{ padding: 5, fontFamily: 'Roboto' }}
                                >
                                  <Class langFR={langFR} {...question} />
                                </View>

                                {nonApplicable && !isGroup && (
                                  <SurveyPdfNonApplicableCheckbox />
                                )}
                              </View>
                            )
                            headerComponent = null

                            if (isRequired && !footnoteRendered) {
                            }
                            const footnoteComponent = isRequired &&
                              !footnoteRendered && (
                                <View
                                  fixed
                                  style={{
                                    left: '1.27cm',
                                    bottom: '1.27cm',
                                    position: 'absolute'
                                  }}
                                  render={({ subPageNumber }) => {
                                    if (subPageNumber === 1) {
                                      return (
                                        <Text>
                                          <Text
                                            style={{
                                              color: 'red',
                                              fontFamily: 'Roboto',
                                              fontSize:
                                                pdfSurveyQuestionSubFontSize
                                            }}
                                          >
                                            *
                                          </Text>
                                          <Text
                                            style={{
                                              fontFamily: 'Roboto',
                                              fontSize:
                                                pdfSurveyQuestionSubFontSize
                                            }}
                                          >
                                            {myI18n._(
                                              t`SURVEY_PDF_REQUIRED_FOOTNOTE`
                                            )}
                                          </Text>
                                        </Text>
                                      )
                                    } else {
                                      return null
                                    }
                                  }}
                                />
                              )

                            if (isRequired) {
                              footnoteRendered = true
                            }
                            return (
                              <>
                                {toRet}
                                {footnoteComponent}
                              </>
                            )
                          })}
                      </Page>
                    )
                  })}
              </Document>
            </PDFViewer>
          )}
        </DialogContent>
      </Dialog>
      <Tooltip title={<Trans>Generate fillable pdf file</Trans>}>
        <IconButton
          onClick={e => {
            setDialogOpen(true)
          }}
        >
          <Icon>history_edu</Icon>
        </IconButton>
      </Tooltip>
    </>
  )
}

export const SurveyPdfNonApplicableCheckbox = () => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 8,
        paddingTop: 4
      }}
    >
      <PdfCheckbox />
      <Text
        style={{
          fontSize: pdfSurveyQuestionSubFontSize
        }}
      >
        {myI18n._(t`Is non applicable`)}
      </Text>
    </View>
  )
}

const getTooltipText = ({
  question,
  questionIdToQuestion,
  questionIdToConditionsTargeting,
  langFR
}) => {
  const {
    id,
    isRequired,
    type,
    tooltip,
    conditionForObjectField,
    conditionalObjectField,
    conditionalObjectName,
    conditionalObjectSubfield,
    max,
    min
  } = question
  const toRet = []
  if (questionIdToConditionsTargeting[id]) {
    let base = myI18n._(t`Answer to this question only if you`)
    const conditionsString = []
    questionIdToConditionsTargeting[id].forEach(conditionObj => {
      const { isSelected, question, option } = conditionObj
      let toAdd = ' '
      if (isSelected) {
        toAdd += myI18n._(t`selected`)
      } else {
        toAdd += myI18n._(t`didn't select`)
      }
      const targetQuestion = questionIdToQuestion[question]
      const optionIndex = option.split('_')[1]
      const optionObj = targetQuestion?.options[optionIndex]
      if (optionObj) {
        const optionText = optionObj.text?.id
          ? myI18n._(t`${optionObj.text.id}`)
          : optionObj.text
        const conditionString =
          ' ' +
          optionText +
          ' ' +
          myI18n._(t`in question ${targetQuestion.displayIndex}`)
        toAdd += conditionString
        conditionsString.push(toAdd)
      }
      base += conditionsString.join(' ' + myI18n._(t`or`))
    })
    toRet.push(base)
  }
  if (conditionalObjectName) {
    if (
      conditionalObjectField === 'objectives' &&
      conditionalObjectName === 'opportunity' &&
      conditionForObjectField === 'enable'
    ) {
      toRet.push(
        myI18n._(
          t`SURVEY_PDF_ANSWER_IF_OBJECTIVE_IS_MET ${conditionalObjectSubfield}`
        )
      )
    } else {
      console.warn('Unhandled condition: ', question)
    }
  }
  if (tooltip) {
    toRet.push(tooltip)
  }
  // if (isRequired) {
  //   toRet.push(myI18n._(t`Answer to this question is required`))
  // }
  if (type === 'SELECT') {
    toRet.push(myI18n._(t`SURVEY_PDF_SELECT_ONLY_ONE_ANSWER`))
  }
  if (min) {
    toRet.push(myI18n._(t`Min`) + ': ' + min)
  }
  if (max) {
    toRet.push(myI18n._(t`Max`) + ': ' + max)
  }
  if (toRet.length === 0) {
    return null
  } else {
    return toRet.join('. ').replaceAll('..', '.')
  }
}
