import React, { useEffect, useRef, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useSnackbar } from 'notistack'
import CardActions from '@material-ui/core/CardActions'
import { Field, Formik } from 'formik'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import {
  Grid,
  Icon,
  IconButton,
  Paper,
  Step,
  StepLabel,
  Stepper
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormPage,
  getInitialValues,
  handleFormSave,
  mapFormElements
} from '../forms/Form'
import moment from 'moment'
import { constructValidationSchema } from '../forms/FormHelpersValidation'
import { Alert, AlertTitle } from '@material-ui/lab'
import { connectedObjectQuery } from '../forms/FormsHelpersQueries'
import { constructFormAddressString } from '../forms/components/Common'
import Loading from 'egret/components/EgretLoadable/Loading'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 10,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: props =>
      props.disabled
        ? theme.palette.action.disabledBackground
        : props.highlight
        ? theme.palette.secondary.light
        : 'inherit',
    filter: props => (props.disabled ? 'grayscale(1)' : '')
  },
  selectedCard: {
    maxWidth: 450,
    padding: 10,
    height: '100%',
    display: 'flex'
  },
  actions: {
    flexDirection: 'column'
  },
  button: {
    margin: 8
  },
  media: {
    backgroundSize: 'contain',
    height: 140
  }
}))

const EligibilityCard = ({
  handleNext,
  selectApplicationType,
  rebuildInitialValues,
  resetForm,
  disabled,
  disabledMessage,
  id,
  image,
  logo,
  nameEN,
  nameFR,
  recordType,
  subtitleEN,
  subtitleFR,
  descriptionEN,
  descriptionFR,
  moreInfoUrlEN,
  moreInfoUrlFR,
  quiz,
  ...props
}) => {
  const classes = useStyles({ highlight: false })
  const classesHighlight = useStyles({ highlight: true })
  const langEN = useSelector(state => state.user.language === 'en_US')
  const mainTitle = langEN ? nameEN : nameFR
  const subtitle = langEN ? subtitleEN : subtitleFR
  const link = langEN ? moreInfoUrlEN : moreInfoUrlFR
  const description = langEN ? descriptionEN : descriptionFR
  return (
    <Field name='applicationType'>
      {({ form: { setFieldValue, setValues, values }, field }) => {
        const checked = id === field.value
        return (
          <Card className={classes.root}>
            <CardActionArea
              disabled={disabled}
              onClick={() => {
                selectApplicationType({
                  formId: id,
                  quizId: quiz,
                  recordTypeId: recordType
                })
                handleNext(quiz)
              }}
            >
              <CardMedia className={classes.media} image={logo} title='logo' />
              <CardContent>
                <Typography gutterBottom variant='h5' component='h2'>
                  {mainTitle}
                </Typography>
                {subtitle && (
                  <Typography gutterBottom variant='h6' component='h6'>
                    {subtitle}
                  </Typography>
                )}
                <Typography variant='body2' color='textSecondary' component='p'>
                  <Trans id={description} />
                  {checked}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.actions}>
              <Button
                disabled={disabled}
                color='primary'
                fullWidth
                variant='contained'
                className={classes.button}
                onClick={() => {
                  selectApplicationType({
                    formId: id,
                    quizId: quiz,
                    recordTypeId: recordType
                  })
                  handleNext(quiz)
                }}
              >
                <Trans>Select</Trans>
              </Button>
              {link && (
                <Button
                  disabled={disabled}
                  color='secondary'
                  variant='outlined'
                  className={classes.button}
                  fullWidth
                  href={link}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  {/* <a href={link} target='_blank' rel='noreferrer noopener'> */}
                  <Trans>Learn More</Trans>
                  {/* </a> */}
                </Button>
              )}
              {disabledMessage && (
                <div style={{ color: '#f5543b' }}>{disabledMessage}</div>
              )}
            </CardActions>
          </Card>
        )
      }}
    </Field>
  )
}

export const ConfigurableEligibilityDialog = ({
  open = false,
  configurations,
  forms,
  fetchData,
  handleClose
}) => {
  const currentOrganization = useSelector(state => state.organization)
  const user = useSelector(state => state.user)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [activeStep, setActiveStep] = useState(0)
  const [validationSchema, setValidationSchema] = useState(null)
  const [formData, setFormData] = useState(null)
  const [connectedMap, setConnectedMap] = useState({})
  const [loadingForm, setLoadingForm] = useState(false)
  const langEN = useSelector(state => state.user.language === 'en_US')
  const history = useHistory()
  const formRef = useRef()
  const avaliableOrganizations = useSelector(
    state => state.avaliableOrganizations
  )
  const dispatch = useDispatch()
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  useEffect(() => {
    setActiveStep(0)
  }, [open])

  const returnInitialTouched = formQuizId => {
    let quizForm
    const toRet = {}
    forms.some(form => {
      if (form.id === formQuizId) {
        quizForm = form
      }
      return form.id === formQuizId
    })
    if (!quizForm) {
      return toRet
    }
    quizForm.config?.sections?.forEach(section => {
      section.elements.forEach(element => {
        toRet[element.id] = true
      })
    })
    return toRet
  }

  const returnInitialValues = (formQuizId, connectedMap) => {
    let quizForm
    forms.some(form => {
      if (form.id === formQuizId) {
        quizForm = form
      }
      return form.id === formQuizId
    })
    if (!quizForm) {
      return {}
    }
    return getInitialValues({
      data: quizForm.config,
      connectedMap
    })
  }

  const returnValidationSchema = formQuizId => {
    let quizForm
    forms.some(form => {
      if (form.id === formQuizId) {
        quizForm = form
      }
      return form.id === formQuizId
    })
    if (!quizForm) {
      return null
    }
    return constructValidationSchema({ data: quizForm.config })
  }

  const submitQuiz = (values, formikHelpers) => {
    const { setSubmitting, resetForm } = formikHelpers
    const toSubmit = { ...values }
    const { configUsed } = values
    let quizForm,
      connectedObjects,
      connectedObjectsGoToForm,
      goToForm,
      goTo,
      fundingStream
    const recordTypeId = values.recordTypeId
    configurations.some(config => {
      if (config.id === configUsed) {
        goTo = config.form
        fundingStream = config.fundingStream
        return true
      }
      return false
    })
    forms.forEach(form => {
      if (form.id === values.formQuizId) {
        quizForm = form
        connectedObjects = form.config.objectsConnected
      }
      if (form.id === goTo) {
        goToForm = form.config
        connectedObjectsGoToForm = form.config.objectsConnected
      }
    })
    delete toSubmit.applicationType
    delete toSubmit.formQuizId
    delete toSubmit.recordTypeId
    delete toSubmit.configUsed
    setSubmitting(true)
    const creatingSnackbar = enqueueSnackbar(null, {
      persist: true,
      content: key =>
        ProgressSnackbar(<Trans>Creating new application...</Trans>)
    })
    return handleFormSave({
      values: toSubmit,
      elementsMap: mapFormElements(quizForm.config, !langEN),
      extraInfo: {
        typeFormElements: goToForm && Object.values(mapFormElements(goToForm))
      },
      reduxBag: {
        dispatch,
        user,
        organization: currentOrganization,
        avaliableOrganizations
      },
      connectedMap: {
        [connectedObjects[0].identId]: {
          sfObject: {
            attributes: {
              type: 'Opportunity'
            }
          }
        },
        ...connectedMap
      },
      baseToSave: {
        [connectedObjects[0].identId]: {
          AccountId: currentOrganization.id,
          RecordTypeId: recordTypeId,
          Form_Version__c: goTo,
          Funding_Stream__c: fundingStream
        }
      }
    }).then(
      result => {
        const created = result[0].results[0] && !result[0].hasErrors
        if (goTo && created) {
          closeSnackbar(creatingSnackbar)
          enqueueSnackbar(<Trans>Application created!</Trans>, {
            variant: 'success'
          })
          let addressString = []
          connectedObjectsGoToForm.forEach(obj => {
            let defaultId = ''
            if (obj.type === 'Opportunity') {
              defaultId = result[0].results[0].id
            } else if (obj.type === 'User') {
              defaultId = user.userId
            } else if (obj.type === 'Account') {
              defaultId = currentOrganization.id
            }
            addressString.push(obj.identId + '=' + defaultId)
          })
          addressString = addressString.join(';')
          history.push('/elasticform/' + goTo + '/' + addressString)
        } else {
          return fetchData().then(() => {
            closeSnackbar(creatingSnackbar)
            setSubmitting(false)
            handleClose()
            resetForm()
            enqueueSnackbar(<Trans>Application created!</Trans>, {
              variant: 'success'
            })
            if (created) {
              const id = result[0].results[0].id
              history.push(`/grants/Application/${id}`)
            }
          })
        }
      },
      reject => {
        closeSnackbar(creatingSnackbar)
        setSubmitting(false)
        enqueueSnackbar(
          <Trans>Error ocurred while creating application!</Trans>,
          {
            variant: 'error'
          }
        )
        console.log(reject)
      }
    )
  }

  const returnFormData = id => {
    let quizForm
    forms.some(form => {
      if (form.id === id) {
        quizForm = form
      }
      return form.id === id
    })
    if (!quizForm) {
      return null
    }
    return quizForm
  }

  useEffect(() => {
    formRef.current.validateForm()
  }, [validationSchema])

  const isDisabled = item => {
    return (
      Array.isArray(item.disableInProvinces) &&
      item.disableInProvinces.includes(currentOrganization.province) &&
      !item.inTesting
    )
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={{}}
      initialTouched={{}}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={submitQuiz}
    >
      {({
        values,
        setValues,
        resetForm,
        errors,
        handleSubmit,
        isSubmitting,
        setTouched,
        validateForm
      }) => {
        const { formQuizId } = values
        let quizForm, connectedObjects
        if (formQuizId) {
          forms.some(form => {
            if (form.id === formQuizId) {
              quizForm = form
            }
            return form.id === formQuizId
          })
        }
        const steps = [{ label: <Trans>Select application type</Trans> }]
        let quizElements
        if (quizForm) {
          connectedObjects = quizForm.config.objectsConnected
          steps.push(
            ...quizForm.config.sections.map(section => ({
              label: langEN ? section.titleEN : section.titleFR
            }))
          )
          if (activeStep > 0) {
            quizElements = quizForm.config.sections[activeStep - 1].elements
          }
        }

        const activeConfigs = configurations.filter(config => {
          if (config.startTime) {
            if (!moment.utc().isAfter(moment(config.startTime).utc())) {
              return false
            }
          }
          if (config.endTime) {
            if (moment.utc().isAfter(moment(config.endTime).utc())) {
              return false
            }
          }
          if (!config.quiz) {
            return false
          }
          if (config.inTesting) {
            return user.role.includes('TESTER')
          }
          return true
        })

        return (
          <Dialog
            open={open}
            maxWidth='md'
            fullWidth
            aria-labelledby='alert-dialog-slide-title'
            aria-describedby='alert-dialog-slide-description'
          >
            <DialogTitle id='alert-dialog-slide-title'>
              <Grid
                container
                direction='row'
                alignItems='center'
                justify='space-between'
              >
                <Trans>Create new application</Trans>
                <IconButton
                  onClick={() => {
                    handleClose()
                    resetForm({})
                  }}
                  color='default'
                  disabled={isSubmitting}
                >
                  <Icon>close</Icon>
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ minHeight: 500 }}>
              {activeConfigs.length > 1 ? (
                <Stepper alternativeLabel activeStep={activeStep}>
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              ) : (
                <Alert severity='info'>
                  <AlertTitle>
                    <Trans>
                      Currently there are no avaliable grants to apply to
                    </Trans>
                  </AlertTitle>
                </Alert>
              )}
              {activeStep === 0 && (
                <Grid
                  container
                  direction='row'
                  alignItems='stretch'
                  justify='center'
                >
                  {activeConfigs
                    .map((item, index) => {
                      let order = item.order
                      if (!order && order !== 0) {
                        order = index
                      }
                      return { ...item, order }
                    })
                    .sort((a, b) => {
                      if (isDisabled(a) && !isDisabled(b)) {
                        return 1
                      } else if (isDisabled(b) && !isDisabled(a)) {
                        return -1
                      } else {
                        return a.order - b.order
                      }
                    })
                    .map((item, index) => {
                      return (
                        <Grid item xs={6} key={index}>
                          <EligibilityCard
                            disabled={isDisabled(item)}
                            disabledMessage={
                              isDisabled(item) && (
                                <Trans>
                                  Your organization home province is invalid for
                                  this application type
                                </Trans>
                              )
                            }
                            selectApplicationType={({
                              formId,
                              quizId,
                              recordTypeId
                            }) => {
                              const data = returnFormData(quizId)
                              setLoadingForm(true)
                              connectedObjectQuery(data.config, {
                                langFR: !langEN,
                                id: constructFormAddressString({
                                  objectsConnected:
                                    data.config.objectsConnected,
                                  ids: {
                                    Account: currentOrganization.id
                                  }
                                }),
                                enqueueSnackbar
                              }).then(({ connectedMap }) => {
                                setConnectedMap(connectedMap)
                                setLoadingForm(false)
                                const toSet = { ...values }
                                toSet.configUsed = item.id
                                toSet.applicationType = formId
                                toSet.formQuizId = quizId
                                toSet.recordTypeId = recordTypeId
                                const initValue = returnInitialValues(
                                  quizId,
                                  connectedMap
                                )
                                const touched = returnInitialTouched(quizId)
                                const newValidationSchema =
                                  returnValidationSchema(quizId)
                                setValidationSchema(newValidationSchema)
                                setTouched(touched)
                                setValues({ ...toSet, ...initValue })
                                validateForm()
                              })
                            }}
                            handleNext={quizId => {
                              if (quizId) {
                                handleNext()
                              }
                            }}
                            {...item}
                          />
                        </Grid>
                      )
                    })}
                </Grid>
              )}
              {loadingForm && <Loading />}
              {quizElements && !loadingForm && (
                <FormPage
                  sections={quizForm.config.sections}
                  elements={quizElements}
                  values={values}
                  errors={errors}
                  langFR={!langEN}
                  connectedMap={connectedMap}
                />
              )}
            </DialogContent>
            <DialogActions>
              {activeStep > 0 && (
                <Button
                  onClick={() => {
                    handleBack()
                  }}
                  color='primary'
                  variant='contained'
                  disabled={isSubmitting}
                >
                  <Trans>Back</Trans>
                </Button>
              )}
              {activeStep < steps.length - 1 && (
                <Button
                  onClick={() => {
                    handleNext()
                  }}
                  color='primary'
                  variant='contained'
                  disabled={isSubmitting}
                >
                  <Trans>Next</Trans>
                </Button>
              )}
              {activeStep === steps.length - 1 && connectedObjects && (
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleSubmit}
                  disabled={
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    connectedObjects.length === 0
                  }
                >
                  <Trans>Create new application</Trans>
                </Button>
              )}
            </DialogActions>
          </Dialog>
        )
      }}
    </Formik>
  )
}
