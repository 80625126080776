import React from 'react'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  FormControlLabel,
  Link,
  Typography,
  Card
} from '@material-ui/core'
import { Field } from 'formik'
import { Checkbox } from 'formik-material-ui'
import { I18n } from '@lingui/react'
import { useSelector } from 'react-redux'
import moment from 'moment'
// import { SUBMIT_GRANT_APPLICATION } from './FormSubmitButton'

const questions = [
  t`I declare that I have been duly authorized by responsible bodies (eg board of directors, executive committee, general management) of the applicant organisation (hereinafter "the Organisation") to submit a grant request on its behalf to the Community Housing Transformation Centre (hereinafter "the Centre").`,
  t`I certify being able to demonstrate that the responsible bodies of the Organisation are informed and agree with the terms of the contract that would bond the Centre to the Organisation if the grant was to be allocated in whole or in part by the Centre`,
  t`I solemnly affirm to have read the standard contract template, available on the Centre’s website, which will govern the relationship between the Organisation and the Centre if the grant is awarded, and that I have shared the said contract with the bodies responsible for the Organisation.`,
  t`I solemnly declare that I have made sure that the signatories listed in this application are authorized, by the responsible bodies of the Organisation, to make the commitments associated with the project and the contract.`,
  t`I understand that submitting a funding request on behalf of the Organisation without the prior authorization of the responsible bodies constitutes a false declaration and a fraudulent action and exposes me, on a personal basis, to legal proceedings on the part of the Centre.`
]

export const FormSubmitOpportunity = ({
  id,
  errors,
  saving,
  langFR,
  configuration,
  handleSubmit,
  disabled,
  saveDisabled
}) => {
  const errorsPresent = Object.keys(errors).length > 0
  const errorsToSections = {}
  errors.forEach(error => {
    if (errorsToSections[error.sectionName]) {
      errorsToSections[error.sectionName].push(error)
    } else {
      errorsToSections[error.sectionName] = [error]
    }
  })

  const user = useSelector(state => state.user)
  let userRole = 'No role'
  if (user.organizationMember) {
    userRole = user.organizationMember.TeamMemberRole
  }
  const insufficientAccess = Boolean(
    userRole !== 'Manager' && userRole !== 'Contributor'
  )

  let disableButton =
    saving || errorsPresent || insufficientAccess || disabled || saveDisabled
  if (configuration && configuration.endTime) {
    if (
      moment.utc().isAfter(moment(configuration.endTime).utc()) &&
      !configuration.inTesting
    ) {
      disableButton = true
    }
  }

  const templateLink = !langFR
    ? 'https://centre.support/wp-content/uploads/2020/07/Funding_Agreement_Template-EN-2.pdf'
    : 'https://centre.support/wp-content/uploads/2020/07/Convention-de-financement_mod%C3%A8le-FR.pdf'
  return (
    <I18n>
      {({ i18n }) => (
        <Field name={id}>
          {({
            field,
            form: { setFieldValue, setFieldTouched, values },
            meta
          }) => {
            const allSelected = field.value && !field.value.some(v => !v)
            return (
              <div style={{ padding: 20 }}>
                {errorsPresent && (
                  <Card className='p-24 mb-44 bg-light-error box-shadow-none'>
                    <h5 className='m-0 mb-8 font-weight-500 text-error'>
                      <Trans>Errors found!</Trans>
                    </h5>

                    <p className='m-0 text-muted' style={{ maxWidth: '771px' }}>
                      <Trans>
                        You need to fix these error before You can submit
                      </Trans>
                      :
                    </p>
                    {Object.keys(errorsToSections).map((key, index) => {
                      const sectionErrors = errorsToSections[key]
                      return (
                        <div key={index} style={{ marginTop: 10 }}>
                          <h6 className='m-0 mb-8 font-weight-500 text-error'>
                            {key}
                          </h6>
                          <ul>
                            {sectionErrors.map((errorObj, index) => {
                              const { error, title } = errorObj
                              if (error && error.toMap) {
                                return (
                                  <>
                                    <b>{title}</b>
                                    {error.toMap.map((item, index) => (
                                      <li key={index}>{item}</li>
                                    ))}
                                  </>
                                )
                              } else if (
                                error &&
                                typeof error === 'object' &&
                                !error.props
                              ) {
                                return (
                                  <>
                                    <b>{title}</b>
                                    <ul>
                                      {Object.keys(error).map((key, index) => (
                                        <li key={index}>
                                          <u>{error[key].label}</u>:{' '}
                                          {error[key].error}
                                        </li>
                                      ))}
                                    </ul>
                                  </>
                                )
                              } else {
                                return (
                                  <li key={index}>
                                    {title ? (
                                      <>
                                        <b>{title}:</b> {error}
                                      </>
                                    ) : (
                                      error
                                    )}
                                  </li>
                                )
                              }
                            })}
                          </ul>
                        </div>
                      )
                    })}
                  </Card>
                )}
                <Typography variant='h6'>
                  <Trans>
                    Please read carefully the following statements and make sure
                    to agree with those before being able to submit your
                    application.
                  </Trans>
                </Typography>
                {questions.map((item, index) => (
                  <div style={{ marginTop: 10 }} key={index}>
                    <FormControlLabel
                      // disabled={disabled}
                      key={index}
                      control={
                        <Field
                          type='checkbox'
                          component={Checkbox}
                          name={`${id}.${index}`}
                        />
                      }
                      label={i18n._(item)}
                    />
                    {index === 0 && (
                      <div className='m-10'>
                        <Link
                          href={templateLink}
                          //target='_blank'
                          rel='noopener'
                        >
                          <Trans>Contract Template</Trans>
                        </Link>
                      </div>
                    )}
                  </div>
                ))}

                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  fullWidth
                  style={{ margin: 10 }}
                  onClick={() => {
                    handleSubmit({
                      values
                      // submitType: SUBMIT_GRANT_APPLICATION
                    })
                  }}
                  disabled={disableButton || !allSelected}
                >
                  <Trans>Submit</Trans>
                </Button>
              </div>
            )
          }}
        </Field>
      )}
    </I18n>
  )
}

export const FormEditorSubmitOpportunity = ({ editMode, id, langFR }) => {
  const templateLink = !langFR
    ? 'https://centre.support/wp-content/uploads/2020/07/Funding_Agreement_Template-EN-2.pdf'
    : 'https://centre.support/wp-content/uploads/2020/07/Convention-de-financement_mod%C3%A8le-FR.pdf'
  if (!editMode) {
    return (
      <I18n>
        {({ i18n }) => {
          return (
            <div>
              <Typography variant='h6'>
                <Trans>
                  Please read carefully the following statements and make sure
                  to agree with those before being able to submit your
                  application.
                </Trans>
              </Typography>
              {questions.map((item, index) => (
                <div style={{ marginTop: 10 }} key={index}>
                  <FormControlLabel
                    // disabled={disabled}
                    key={index}
                    control={
                      <Field
                        type='checkbox'
                        component={Checkbox}
                        name={`${id}.${index}`}
                      />
                    }
                    label={i18n._(item)}
                  />
                  {index === 0 && (
                    <div className='m-10'>
                      <Link href={templateLink} rel='noopener'>
                        {/* target='_blank' > */}
                        <Trans>Contract Template</Trans>
                      </Link>
                    </div>
                  )}
                </div>
              ))}
              <Button
                variant='contained'
                color='primary'
                size='large'
                fullWidth
                style={{ margin: 10 }}
              >
                <Trans>Submit</Trans>
              </Button>
            </div>
          )
        }}
      </I18n>
    )
  }
  return <div />
}
