import { t, Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Typography,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  bilingualNumberFormat,
  CurrencyFormated,
  currencyFormatedString,
  PhoneCountryCodeFormat,
  PhoneFormat,
  PhoneFormatWithExtension
} from 'app/views/common/Formats'
import { useFormikContext } from 'formik'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import _ from 'lodash'
import React, { useCallback } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch } from 'react-redux'
import { getMainConnected, getSFObjectFieldValue } from '../Form'
import { isConditionMet } from '../FormHelpersConditions'
import { validFieldTypesForTextFieldNumeric } from '../FormWizard'
import { FormConnectToObject } from './Common'
import { FormTextInputPrint } from './FormTextField'
import MUTextField from './multiuser-input/MUTextField'

export const formTextFieldNumericDefaultValue = (obj, additional, item) => {
  const { initObj } = additional
  const { isPhone } = item.typeProps
  const { connectedField, connectedObject } = getMainConnected(item)
  if (connectedObject && connectedField && obj) {
    let sfValue = getSFObjectFieldValue(obj, connectedField)
    const stringV = String(sfValue)
    if (sfValue && stringV.indexOf('+(') !== -1) {
      const countryCode = stringV.substring(2, stringV.indexOf(')'))
      _.set(initObj, 'other.' + item.id, countryCode)
      sfValue = stringV.substring(stringV.indexOf(')'))
    }
    const parsed = String(sfValue).replace(/[^0-9]/g, '')
    if (!parsed) {
      return ''
    }
    return isPhone ? parsed : Number(parsed)
  } else {
    return ''
  }
}

export const formTextFieldNumericValueToText = value =>
  value
    ? {
        en: bilingualNumberFormat(value),
        fr: bilingualNumberFormat(value, true)
      }
    : { en: '', fr: '' }

export const formTextFieldNumericConditionsStates = {
  numMin: {
    label: <Trans>Set min input to</Trans>,
    component: ({ onChange, value }) => (
      <Grid xs item>
        <TextField
          variant='outlined'
          fullWidth
          label={<Trans>Min input</Trans>}
          value={value || ''}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
      </Grid>
    )
  },
  maxMin: {
    label: <Trans>Set max input to</Trans>,
    component: ({ onChange, value }) => (
      <Grid xs item>
        <TextField
          variant='outlined'
          fullWidth
          label={<Trans>Max input</Trans>}
          value={value || ''}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
      </Grid>
    )
  }
}

export function FormTextFieldNumeric ({
  id,
  useMultiuser,
  muBag,
  value,
  title,
  preview,
  disabled,
  typeProps,
  conditions = [],
  connectedMap,
  elementsMap,
  langFR,
  ...props
}) {
  const {
    required,
    currency,
    readOnly,
    isPhone,
    showFieldLabel,
    max,
    min,
    allowPhoneExtension
  } = typeProps
  const { values } = useFormikContext()
  let maxInput = max
  let minInput = min

  conditions
    .filter(condition => ['maxMin', 'numMin'].includes(condition.state))
    .forEach(condition => {
      const { conditionMet, state } = isConditionMet({
        condition,
        connectedMap,
        values,
        elementsMap
      })
      if (conditionMet) {
        if (state === 'numMin' && condition.numMin) {
          minInput = Number(condition.numMin)
        } else if (state === 'maxMin' && condition.maxMin) {
          maxInput = Number(condition.maxMin)
        }
      }
    })

  if (readOnly) {
    return (
      <Typography>
        {currency ? <CurrencyFormated value={value} /> : value}
      </Typography>
    )
  }

  if (isPhone) {
    return (
      <Grid container wrap='nowrap'>
        <Grid item>
          <MUTextField
            required={required}
            defaultValue='1'
            useMultiuser={useMultiuser}
            muBag={muBag}
            disabled={disabled}
            isCountryCode={true}
            id={'other.' + id}
            type='number'
            variant='outlined'
          />
        </Grid>
        <Grid item xs>
          <MUTextField
            useMultiuser={useMultiuser}
            muBag={muBag}
            disabled={disabled}
            label={showFieldLabel && title}
            id={id}
            fullWidth
            variant='outlined'
            isPhone={isPhone}
            type='number'
            allowPhoneExtension={allowPhoneExtension}
            required={required}
            //helperText={helper}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <MUTextField
      required={required}
      id={id}
      useMultiuser={useMultiuser}
      muBag={muBag}
      disabled={disabled}
      label={showFieldLabel && title}
      fullWidth
      variant='outlined'
      maxInput={maxInput}
      minInput={minInput}
      isPhone={isPhone}
      langFR={langFR}
      currency={currency}
      type='number'
    />
  )
}

export function FormEditorTextFieldNumeric ({
  typeProps = {},
  injectable,
  french,
  showPrintProps,
  showPdfProps,
  depth,
  editMode,
  title,
  ...props
}) {
  const dispatch = useDispatch()
  const {
    required,
    allowPhoneExtension,
    isPhone,
    forceRequired,
    currency,
    showFieldLabel,
    max,
    min
  } = typeProps

  if (!editMode) {
    if (showPrintProps) {
      return (
        <FormTextInputPrint
          typeProps={typeProps}
          title={title}
          {...props}
          editorPreview
        />
      )
    }
    return (
      <FormTextFieldNumeric typeProps={typeProps} title={title} {...props} />
    )
  }

  const { connectedField } = getMainConnected({ typeProps, ...props })
  const forceReadOnly =
    (connectedField && connectedField.readOnly) || showPrintProps
  const readOnly = typeProps.readOnly || forceReadOnly

  return (
    <Grid container direction='column' style={{ padding: 10 }}>
      {!showPdfProps && !showPrintProps && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(readOnly && connectedField) || showPrintProps}
              disabled={!connectedField || showPrintProps || forceReadOnly}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.readOnly = e.target.checked
                delete toSet.required
                delete toSet.min
                delete toSet.max
                delete toSet.allowPhoneExtension
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Read only</Trans>}
        />
      )}

      {forceReadOnly && !showPdfProps ? (
        <Alert severity='info' variant='outlined'>
          <Trans>
            You can't change this element to an input. Either connected field or
            whole form is marked as read only
          </Trans>
        </Alert>
      ) : (
        !readOnly &&
        !showPdfProps && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(showFieldLabel)}
                  disabled={readOnly}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.showFieldLabel = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Show label in field?</Trans>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={readOnly || forceRequired}
                  checked={Boolean(required || forceRequired)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.required = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Required</Trans>}
            />
          </>
        )
      )}

      {!forceReadOnly && !showPdfProps && (
        <h6 style={{ marginBottom: 5, marginTop: 10 }}>Input restrictions</h6>
      )}
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(isPhone)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.isPhone = e.target.checked
                delete toSet.allowPhoneExtension
                if (e.target.checked) {
                  delete toSet.currency
                  delete toSet.min
                  delete toSet.max
                }
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Is phone number?</Trans>}
        />

        {isPhone && !readOnly && !showPdfProps && !showPrintProps && (
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(allowPhoneExtension)}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.allowPhoneExtension = e.target.checked
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              />
            }
            label={<Trans>Allow phone number extension?</Trans>}
          />
        )}
      </div>

      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(currency)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.currency = e.target.checked
              if (e.target.checked) {
                delete toSet.isPhone
                delete toSet.allowPhoneExtension
              }
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Is currency?</Trans>}
      />

      {!readOnly && !showPdfProps && !showPrintProps && (
        <Grid container>
          <Grid item xs style={{ padding: 5 }}>
            <TextField
              label={<Trans>Max input</Trans>}
              disabled={Boolean(isPhone)}
              value={max || ''}
              InputProps={{ inputComponent: NumberFormatCustom }}
              variant='outlined'
              fullWidth
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.max = e.target.value
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          </Grid>
          <Grid item xs style={{ padding: 5 }}>
            <TextField
              label={<Trans>Min input</Trans>}
              disabled={Boolean(isPhone)}
              value={min || ''}
              InputProps={{ inputComponent: NumberFormatCustom }}
              variant='outlined'
              fullWidth
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.min = e.target.value
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          </Grid>
        </Grid>
      )}

      <FormConnectToObject
        injectable={injectable}
        typeProps={typeProps}
        depth={depth}
        validTypes={validFieldTypesForTextFieldNumeric}
        allowReadOnlyFields
        clearOnReadOnlyField={['required', 'min', 'max', 'allowPhoneExtension']}
      />
    </Grid>
  )
}

function NumberFormatCustom (props) {
  const { inputRef, onChange, id, value = '', ...other } = props
  let formattedValue = ''
  // noinspection JSIncompatibleTypesComparison
  if (value || value === 0) {
    const num = String(value).replaceAll(',', '')
    formattedValue = parseFloat(Number(num).toFixed(2))
  }
  return (
    <NumberFormat
      {...other}
      value={typeof props.value === 'object' ? '' : formattedValue}
      defaultValue=''
      thousandSeparator=','
      isNumericString
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}
