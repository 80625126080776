import { I18n } from '@lingui/react'
import { t, Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  Icon,
  Link,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  LinearProgress,
  Tooltip,
  IconButton,
  Grid,
  Typography,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  deleteDocument,
  parseDocument,
  uploadFile
} from 'app/services/sfAuth/sfData/sfFiles'
import { useSnackbar } from 'notistack'
import React from 'react'
import Dropzone from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { FormErrorLabel } from './FormErrorLabel'
import { Field, useField, useFormikContext } from 'formik'
import { FormConnectToObject, getMuState } from './Common'
import { isError } from 'lodash'
import { DefaultNumericFormat } from 'app/views/common/Formats'
import { myI18n } from 'translation/I18nConnectedProvider'
import { endEditingField } from '../multiuser/grpcMultiuserEdit'

const crypto = require('crypto')
const styles = theme => ({
  dropbox: {
    margin: '20px 0',
    border: '2px dashed #0085B8',
    padding: 35,
    backgroundColor: 'rgba(0, 133, 184, 0.05)',
    '&:hover': {
      backgroundColor: 'rgba(0, 133, 184, 0.15)',
      border: '3px dashed #0085B8',
      textWeight: 'bold'
    }
  },
  disabled: {
    margin: '20px 0',
    border: '2px dashed rgba(158, 158, 158)',
    padding: 35,
    backgroundColor: 'rgba(245, 245, 245, 0.05)'
  }
})
export const formUploadComponentStyles = makeStyles(styles)

export const formUploadFilesConditionsStates = {
  minFiles: {
    label: <Trans>Set minimum number of files to upload</Trans>,
    component: ({ onChange, value }) => (
      <Grid xs item>
        <TextField
          variant='outlined'
          fullWidth
          InputProps={{ inputComponent: DefaultNumericFormat }}
          label={<Trans>Number of files</Trans>}
          value={value || ''}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
      </Grid>
    )
  }
}

export const FormUploadFilesPrint = ({
  value = [],
  title,
  editorPreview,
  ...props
}) => {
  return (
    <div>
      <Typography className='form-print-subtitle' style={{ marginBottom: 5 }}>
        {title}
      </Typography>
      {editorPreview && <Trans>FILE LIST PLACEHOLDER</Trans>}
      {value.map((file, index) => {
        return (
          <Grid
            container
            direction='row'
            alignItems='center'
            key={index}
            style={{ paddingLeft: 5 }}
          >
            <IconButton variant='link' href={file.url}>
              <Icon>download</Icon>
            </IconButton>
            <Typography style={{ marginLeft: 5 }}>
              <b>{file.name}</b>
              <div>{file.description}</div>
            </Typography>
          </Grid>
        )
      })}
    </div>
  )
}

export const FormUploadFiles = ({
  id,
  langFR,
  muBag,
  value,
  disabled,
  useMultiuser,
  connectedObject,
  network = {},
  typeProps = {},
  entityFiles = [],
  formikRef,
  ...props
}) => {
  const preview = false
  const { required, tags = [], uploadPrefixEN, uploadPrefixFR } = typeProps
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const user = useSelector(state => state.user)
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(id)
  const classes = formUploadComponentStyles()
  const invalid = Boolean(!connectedObject || !connectedObject.Id) && !preview
  const uploadPrefix = langFR ? uploadPrefixFR : uploadPrefixEN

  const handleOnDrop = ({ files, id, fieldValue }) => {
    console.log('handleOnDrop', files, network)
    const listCount = files.length
    const uploadId = listCount + 1
    const file = files[0]
    let uploadedName = file.name
    if (uploadPrefix) {
      uploadedName = uploadPrefix + ' ' + uploadedName
    }
    const newValue = [...formikRef.current.values[id]]
    const fakeId = crypto.randomBytes(8).toString('hex')
    newValue.push({
      uploadId,
      name: uploadedName,
      progress: !preview,
      actionId: fakeId
    })
    setFieldValue(id, newValue)
    if (useMultiuser) {
      endEditingField({
        ...muBag,
        fieldId: id,
        fieldValue: newValue
      })
    }
    if (preview) {
      enqueueSnackbar(<Trans>Uploaded File</Trans>, {
        variant: 'success'
      })
      return
    }
    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = e => {
      console.log('file reading has failed', e)
      enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
        variant: 'error'
      })
      const newValue = [...formikRef.current.values[id]]
      newValue.some((file, index) => {
        if (file.actionId === fakeId) {
          newValue.splice(index, 1)
          return true
        }
        return false
      })
      setFieldValue(id, newValue)
      if (useMultiuser) {
        endEditingField({
          ...muBag,
          fieldId: id,
          fieldValue: newValue
        })
      }
    }
    reader.onload = () => {
      const binaryStr = reader.result
      console.log('binary', binaryStr, reader)
      const uploadingKey = enqueueSnackbar(<Trans>Uploading File</Trans>, {
        variant: 'info'
      })
      const handleError = () => {
        const newValue = [...formikRef.current.values[id]]
        newValue.some((file, index) => {
          if (file.actionId === fakeId) {
            newValue.splice(index, 1)
            return true
          }
          return false
        })
        setFieldValue(id, newValue)
        closeSnackbar(uploadingKey)
        if (useMultiuser) {
          endEditingField({
            ...muBag,
            fieldId: id,
            fieldValue: newValue
          })
        }
      }
      if (binaryStr.byteLength > 52428800) {
        enqueueSnackbar(<Trans>Maximum file size is 52 MB</Trans>, {
          variant: 'error'
        })
        handleError()
      }
      let tagsString = id
      if (tags.length > 0) {
        tags.filter(tag => tag).forEach(tag => (tagsString += '&tag&' + tag))
      }
      uploadFile(
        {
          name: uploadedName,
          tags: tagsString,
          uploadId: connectedObject.Id
        },
        binaryStr,
        network.Id,
        true
      )
        .then(uploadedFile => {
          enqueueSnackbar(<Trans>Uploaded File</Trans>, {
            variant: 'success'
          })
          const newValue = [...formikRef.current.values[id]]
          newValue.some((file, index) => {
            if (file.actionId === fakeId) {
              newValue[index] = {
                id: uploadedFile.Id,
                tags: tagsString,
                uploadId,
                name: uploadedName
              }
              return true
            }
            return false
          })
          // files
          //   .map(item => parseDocument(item))
          //   .filter(file => file.tags && file.tags.includes(id))
          setFieldValue(id, newValue)
          if (useMultiuser) {
            endEditingField({
              ...muBag,
              fieldId: id,
              fieldValue: newValue
            })
          }
        })
        .catch(error => {
          console.warn('cant upload file', error)
          enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
            variant: 'error'
          })
          handleError()
        })
    }
    reader.readAsArrayBuffer(file)
  }

  const onRemoveItem = ({ id, files, fieldId, index }) => {
    if (preview) {
      const newValue = [...files.filter(item => item.id !== id)]
      setFieldValue(fieldId, newValue)
      if (useMultiuser) {
        endEditingField({
          ...muBag,
          fieldId,
          fieldValue: newValue
        })
      }
      enqueueSnackbar(<Trans>Deleted File</Trans>, {
        variant: 'success'
      })
      return
    }
    enqueueSnackbar(<Trans>Deleting File</Trans>, {
      variant: 'info'
    })

    const fakeId = crypto.randomBytes(8).toString('hex')
    const newValue = [...formikRef.current.values[fieldId]]
    newValue[index].deleting = true
    newValue[index].actionId = fakeId
    setFieldValue(fieldId, newValue)

    deleteDocument(id).then(
      result => {
        enqueueSnackbar(<Trans>Deleted File</Trans>, {
          variant: 'success'
        })
        const newValue = [...formikRef.current.values[field.name]]
        newValue.some((file, index) => {
          if (file.actionId === fakeId) {
            newValue.splice(index, 1)
            return true
          }
          return false
        })
        setFieldValue(fieldId, newValue)
        if (useMultiuser) {
          endEditingField({
            ...muBag,
            fieldId,
            fieldValue: newValue
          })
        }
      },
      reject => {
        enqueueSnackbar(<Trans>Error ocurred while deleting file!</Trans>, {
          variant: 'error'
        })
        const newFiles = [...formikRef.current.values[field.name]]
        newFiles.some((file, index) => {
          if (file.actionId === fakeId) {
            delete newFiles[index].actionId
            delete newFiles[index].deleting
            return true
          }
          return false
        })
        // delete toSet[index].deleting
        setFieldValue('files', newFiles)
        if (useMultiuser) {
          endEditingField({
            ...muBag,
            fieldId,
            fieldValue: newValue
          })
        }
      }
    )
  }

  const createTable = ({ files = [], fieldId }) => {
    const columns = [t`Name`, t`Remove`]
    return (
      <TableContainer>
        <Table style={{ whiteSpace: 'pre' }}>
          <colgroup>
            <col width='85%' />
            <col width='15%' />
          </colgroup>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f5f5f5' }}>
              {columns.map((item, i) => (
                <TableCell key={i}>
                  <p
                    style={{
                      textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      paddingRight: i === 1 && 20
                    }}
                  >
                    {myI18n._(item)}
                  </p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file, index) => {
              const iconDisabled = file.progress || file.deleting
              return [
                <TableRow key={index}>
                  <TableCell>
                    <div style={{ paddingLeft: 35 }}>
                      <Link color='primary' href={file.url}>
                        {file.name}
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingRight: 20
                      }}
                    >
                      <Tooltip title={myI18n._(t`Remove file`)}>
                        <IconButton
                          variant='filled'
                          disabled={iconDisabled}
                          onClick={() => {
                            onRemoveItem({
                              id: file.id,
                              fieldId,
                              files,
                              index
                            })
                          }}
                        >
                          <Icon
                            className={
                              iconDisabled
                                ? ''
                                : 'material-icons MuiIcon-root MuiIcon-colorError'
                            }
                            variant='filled'
                          >
                            delete
                          </Icon>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>,
                file.progress && (
                  <TableRow key={files.length + 1}>
                    <TableCell colSpan={3}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )
              ]
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const isError = Boolean(meta.error)
  return (
    <div>
      {createTable({ files: field.value, fieldId: id })}
      <Dropzone
        disabled={disabled || invalid}
        multiple={false}
        maxFiles={1}
        onDrop={files => {
          handleOnDrop({
            files,
            id,
            fieldValue: field.value
          })
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={disabled || invalid ? classes.disabled : classes.dropbox}
          >
            <section>
              <div>
                <input {...getInputProps()} />
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='center'
                  >
                    <Icon style={{ marginRight: 10 }}>upload</Icon>
                    <Trans>
                      Drag 'n' drop file here, or click to select file
                    </Trans>
                  </Grid>
                </div>
              </div>
              <div />
            </section>
          </div>
        )}
      </Dropzone>
      {invalid && (
        <div style={{ padding: 10, color: 'red' }}>
          <Trans>There is no object connected in editor!</Trans>
        </div>
      )}
      <FormErrorLabel
        error={isError}
        id={id}
        required={required}
        label={<Trans>Maximum file size is 52 MB</Trans>}
      />
    </div>
  )
}

export const FormEditorUploadFiles = ({
  typeProps = {},
  showPrintProps,
  editMode,
  depth,
  injectable,
  ...props
}) => {
  const {
    required,
    minFiles,
    tags = [],
    uploadPrefixEN,
    uploadPrefixFR,
    displayAllFiles
  } = typeProps
  const dispatch = useDispatch()

  if (!editMode) {
    if (showPrintProps) {
      return (
        <FormUploadFilesPrint typeProps={typeProps} editorPreview {...props} />
      )
    }

    return <FormUploadFiles typeProps={typeProps} preview {...props} />
  }

  return (
    <div>
      {!showPrintProps && (
        <>
          <Grid container style={{ padding: 10 }}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={required}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      toSet.required = e.target.checked
                      delete toSet.minFiles
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: { ...toSet }
                      })
                    }}
                  />
                }
                label={<Trans>Required</Trans>}
              />
            </Grid>
            {required && (
              <Grid item>
                <TextField
                  variant='outlined'
                  label={<Trans>Minimum number of files</Trans>}
                  fullWidth
                  value={minFiles}
                  InputProps={{ inputComponent: DefaultNumericFormat }}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.minFiles = e.target.value
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              </Grid>
            )}
          </Grid>

          <Grid container direction='row'>
            <Grid item xs style={{ padding: 5 }}>
              <TextField
                variant='outlined'
                label={<Trans>Uploaded file prefix - English</Trans>}
                fullWidth
                value={uploadPrefixEN}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.uploadPrefixEN = e.currentTarget.value
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            </Grid>
            <Grid item xs style={{ padding: 5 }}>
              <TextField
                variant='outlined'
                label={<Trans>Uploaded file prefix - French</Trans>}
                fullWidth
                value={uploadPrefixFR}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.uploadPrefixFR = e.currentTarget.value
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction='row' alignItems='center'>
            <Typography>
              <Trans>Tags</Trans>
            </Typography>
            <IconButton
              onClick={() => {
                tags.push('')
                typeProps.tags = tags
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...typeProps }
                })
              }}
            >
              <Icon>add</Icon>
            </IconButton>
          </Grid>
          {tags.map((tag, index) => (
            <>
              <Grid
                item
                container
                direction='row'
                key={index}
                justifyContent='space-between'
                alignItems='center'
                wrap='nowrap'
              >
                {index + 1 + '. '}
                <Grid item style={{ flex: 1, padding: 10 }}>
                  <TextField
                    variant='outlined'
                    label={<Trans>Tag</Trans>}
                    fullWidth
                    value={tag}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      toSet.tags[index] = e.currentTarget.value
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                      // setOptionEN(e.currentTarget.value)
                    }}
                  />
                </Grid>
                <div style={{ width: 50 }}>
                  <IconButton
                    onClick={() => {
                      const toSet = { ...typeProps }
                      toSet.tags.splice(index, 1)
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              </Grid>
            </>
          ))}
        </>
      )}
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={displayAllFiles}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.displayAllFiles = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={
            <Trans>Display all files uploaded to object in this element</Trans>
          }
        />
      </div>
      <FormConnectToObject
        injectable={injectable}
        depth={depth}
        typeProps={typeProps}
        noField
      />
    </div>
  )
}
