import { I18n } from '@lingui/react'
import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Paper,
  Typography
} from '@material-ui/core'
import {
  getOpportunity,
  getOpportunityStage,
  GREEN_KICKSTARTER_RECORD_TYPE,
  oppToStepper
} from 'app/services/sfAuth/sfData/sfOpportunity'
import Loading from 'egret/components/EgretLoadable/Loading'
import FormikRadioGroupField from 'formik-material-fields/lib/FormikRadioGroupField'
import React, { Component } from 'react'
import * as Yup from 'yup'
import AllocationComitteQuestionsCard from './AllocationComitteQuestionsCard'
import { withFormikIncluded } from './BaseIncludedForm'
import { connect } from 'react-redux'
import { getUser } from '../../services/sfAuth/sfData/sfUser'
import {
  addComment,
  committeeCaseMapping,
  createCase,
  createCaseByFlow,
  createVote,
  entityTypes,
  getCases,
  getVote,
  updateVote
} from 'app/services/sfAuth/sfData/sfCase'
import { withSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router'
import { OpportunityView } from '../opportunity/OpportunityView'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import { fetchFormPage } from 'app/services/sfAuth/sfData/sfForms'
import Form from '../forms/Form'
import { constructFormAddressString } from '../forms/components/Common'
import { useState } from 'react'
import { Alert } from '@material-ui/lab'
import { getExternalReviews } from 'app/services/sfAuth/sfData/sfReviews'
import { basePortalConfig } from '../internal/ConfigurePortalConfigurations'
import { matchOpportunityWithConfiguration } from '../internal/ConfigureApplicationTypes'

const styles = {
  textBlock: {
    marginLeft: 30,
    marginTop: 20,
    marginRight: 30,
    //fontSize: 18,
    textAlign: 'left'
  },
  subtitle: {
    fontSize: 12,
    color: '#919191'
  },
  switch: {
    color: 'green',
    '&$checked': {
      color: 'red'
    },
    '&$checked + $track': {
      backgroundColor: 'red'
    },
    checked: {},
    track: {}
  },
  paperGrid: {
    padding: 20
  },
  paper: {
    width: '100%',
    paddingBottom: 10
  },
  sectionHeader: {
    fontSize: '48px',
    textAlign: 'center',
    padding: 20
  },
  fieldLabel: {}
}

export const fundLogos = {
  'Community-Based Tenant Initiative Fund (CBTIF)':
    '/assets/images/funds-icons-cbti.png',
  'Sector Transformation Fund – Sectoral Impact':
    '/assets/images/funds-icons-stf-sip.png',
  'Sector Transformation Fund – Local Project':
    '/assets/images/funds-icons-stf-lp.png',
  // TODO replace FCHI image with correct one
  'FCHI-2': '/assets/images/funds-icons-stf-lp.png',
  'ICCE Grant 2021': '/assets/images/icce-logo-circle.svg',
  'ICCE - Community Funding Program - Round 3':
    '/assets/images/icce-logo-circle.svg',
  'ICCE - Community Funding Program': '/assets/images/icce-logo-circle.svg',
  'Green Kickstarter Fund': '/assets/images/funds-icons-greenfund.png'
}

const validationSchema = Yup.object().shape({})

const initialValues = {
  questions: null,
  vote: ''
}

function ReviewApplicationsWithHooks () {
  return function WrappedComponent (props) {
    const { id } = useParams()
    const history = useHistory()
    return <ReviewApplications {...props} history={history} id={id} />
  }
}

class ReviewApplications extends Component {
  state = {
    loadingCompleted: false,
    toggleAll: true,
    vote: 'None',
    forceUpdate: 1,
    application: null,
    committeeType: null
  }

  constructor (props) {
    super(props)
    const { configuration } = props
    this.getComments = this.getCases.bind(this)
    this.submitVote = this.submitVote.bind(this)
    this.addNewComment = this.addNewComment.bind(this)
    this.handleSaveComment = this.handleSaveComment.bind(this)
    this.handleSaveQuestion = this.handleSaveQuestion.bind(this)
    this.reloadStage = this.reloadStage.bind(this)
    this.state.committeeType = props.match.url.split('_')[1].split('/')[0]
    const applicationId = props.id
    console.log('got committee type', this.state.committeeType, configuration)

    Promise.all([
      getUser(this.props.user.userId),
      getVote(
        applicationId,
        this.props.user.userId,
        entityTypes[this.state.committeeType]
      ).then(result => {
        return result[0]
      }),
      this.getCases(applicationId),
      getOpportunity(applicationId, {
        permissions: { CMHC: true },
        fields: [
          'Id',
          'LastModifiedDate',
          'RecordTypeId',
          'Assigned_Program_Manager__c',
          'StageName',
          'FGM_Base__Recommended_Amount__c',
          'AccountId',
          'RecordType.*',
          'FGM_Base__Amount_Requested__c',
          'Funding_Stream__c',
          'FGM_Portal__Total_Project_Budget__c',
          'Other_grant_requests_related_to_project__c',
          'Submit_Date__c',
          'Summary__c',
          'Account.Name',
          'Description',
          'Priority_areas__c',
          'Geographical_scope__c',
          'Geographical_Location__c',
          'Demographic__c',
          'Target_demographics__c',
          'Other_target_demographic__c',
          'Units_women_and_children__c',
          'Units_single_parent__c',
          'Units_racialized_persons__c',
          'Units_Indigenous__c',
          'Units_fleeing_domestic_violence__c',
          'Supported_demographics__c',
          'Other_supported_demographic__c',
          'Impact_summary__c',
          'Partners_summary__c',
          'PM_Name__c',
          'PM_Recommendation__c',
          'PM_Recommendation_TXT__c'
        ]
      })
    ])
      .then(([user, vote, cases, application]) => {
        const applicationTypes = configuration.AVAILABLE_APPLICATIONS || []
        const form = matchOpportunityWithConfiguration({
          fundingStreamId: application.Funding_Stream__c,
          recordTypeId: application.RecordTypeId,
          configurations: applicationTypes
        })
        const formId = form && form[this.state.committeeType]

        let voteValue = 'None'
        if (vote && vote.Result__c) {
          voteValue = vote.Result__c
        }
        console.log('loaded questions', cases)
        console.log('loaded vote', vote)
        console.log('loaded application', application)
        props.setValues({
          ...this.props.values,
          questions: cases,
          vote: voteValue
        })

        if (formId) {
          Promise.all([
            fetchFormPage(formId),
            getExternalReviews({
              FGM_Base__Request__c: applicationId
            })
          ]).then(([form, externalReviews]) => {
            let firstReview, inDepthReview
            externalReviews.forEach(review => {
              const recordType = String(review.RecordType.Name).toLowerCase()
              if (recordType.includes('first')) {
                firstReview = review.Id
              } else if (recordType.includes('depth')) {
                inDepthReview = review.Id
              }
            })

            console.log('found form for opportunity view', form)
            this.setState({
              loadingCompleted: true,
              vote: voteValue,
              voteObjectId: vote ? vote.Id : null,
              user,
              form,
              applicationId,
              firstReview,
              inDepthReview,
              application: {
                ...oppToStepper(application),
                lastModifiedDate: application.LastModifiedDate
              }
            })
          })
        } else {
          this.setState({
            loadingCompleted: true,
            conflictOfInterest: Boolean(
              vote && vote.Conflict_of_interest__c === 'declared_conflict'
            ),
            vote: voteValue,
            voteObjectId: vote ? vote.Id : null,
            user,
            applicationId,
            application: {
              ...oppToStepper(application),
              lastModifiedDate: application.LastModifiedDate
            }
          })
        }
      })
      .catch(err => {
        console.error('failed Loading data', err)
      })
  }

  getCases (applicationId) {
    return getCases(applicationId, {
      searchParams: {
        Type: committeeCaseMapping[this.state.committeeType]
      }
    })
      .then(cases => {
        console.log('found cases', cases)
        return cases.map(item => {
          const toSetComments = []
          if (item.CaseComments) {
            item.CaseComments.records.forEach(record => {
              toSetComments.push({
                user: record.CreatedBy.Name,
                comment: record.CommentBody,
                createdDate: record.CreatedDate,
                editable: false
              })
            })
          }
          return {
            question: item.Description,
            caseId: item.Id,
            user: item.SuppliedName,
            type: item.Type,
            createdDate: item.CreatedDate,
            editable: false,
            comments: toSetComments
          }
        })
      })
      .catch(e => {
        console.log('error loading cases', e)
        this.props.enqueueSnackbar(
          <Trans>Error loading Questions/Comments</Trans>,
          {
            variant: 'error'
          }
        )
      })
  }

  addNewComment (id) {
    const { values, setFieldValue } = this.props
    let newQuestions = [...this.props.values.questions]
    newQuestions[id].comments.push({
      user: this.props.user.displayName,
      userFunc: this.state.user.FGM_Portal__UserProfile__c,
      comment: '',
      editable: true
    })
    this.props.setFieldValue('questions', newQuestions)
  }

  handleSaveComment (questionId, commentId) {
    let newQuestions = [...this.props.values.questions]
    return addComment(newQuestions[questionId].caseId, {
      comment: newQuestions[questionId].comments[commentId].comment
    }).then(
      result => {
        this.props.enqueueSnackbar(<Trans>Comment saved</Trans>, {
          variant: 'info'
        })
        newQuestions[questionId].comments[commentId].editable = false
        const questionsToSet = []
        newQuestions.forEach(question => {
          const copyQuestion = { ...question }
          copyQuestion.comments = []
          question.comments.forEach(comment => {
            if (!comment.editable) {
              copyQuestion.comments.push(comment)
            }
          })
          if (!copyQuestion.editable) {
            questionsToSet.push(copyQuestion)
          }
        })
        this.props.setFieldValue('questions', newQuestions)
      },
      reject => {
        this.props.enqueueSnackbar(<Trans>Error while saving comment</Trans>, {
          variant: 'error'
        })
      }
    )
  }

  handleSaveQuestion (index) {
    const { values, user, enqueueSnackbar, setFieldValue } = this.props
    const { committeeType, application, applicationId } = this.state
    let newQuestions = [...values.questions]

    return createCaseByFlow({
      type: committeeCaseMapping[committeeType],
      description: newQuestions[index].question,
      opportunityId: applicationId,
      assignedManager: application.info.assignedManager,
      userName: user.displayName
    })
      .then(result => {
        console.log('case created', newQuestions, index, result)
        newQuestions[index].editable = false
        newQuestions[index].caseId = result[0].outputValues.createdId
        setFieldValue('questions', newQuestions)
        enqueueSnackbar(<Trans>Question saved</Trans>, {
          variant: 'info'
        })
      })
      .catch(reject => {
        enqueueSnackbar(<Trans>Error while saving question</Trans>, {
          variant: 'error'
        })
      })
  }

  reloadStage (vote) {
    return getOpportunityStage(this.state.application.info.id).then(stage => {
      const app = { ...this.state.application }
      app.info.stageName = stage
      this.setState({
        application: app,
        vote: vote,
        submittingVote: false
      })
    })
  }

  submitVote (voteValue) {
    console.log('submitting vote', voteValue, voteObjectId)
    const { committeeType, application, voteObjectId } = this.state
    const { enqueueSnackbar, values, user, closeSnackbar } = this.props
    this.setState({ submittingVote: true })
    const infoSnackbar = enqueueSnackbar(<Trans>Submitting vote</Trans>, {
      variant: 'info',
      persist: true
    })
    if (voteObjectId) {
      updateVote({
        vote: voteValue,
        updateId: voteObjectId,
        conflictOfInterest:
          voteValue === 'conflict_of_interest'
            ? 'declared_conflict'
            : 'declared_no_conflict	'
      }).then(
        result => {
          this.reloadStage(voteValue).then(result => {
            closeSnackbar(infoSnackbar)
            enqueueSnackbar(<Trans>Vote submitted</Trans>, {
              variant: 'success'
            })
          })
        },
        reject => {
          console.log('error updating vote', reject)
          this.setState({ submittingVote: false })
          closeSnackbar(infoSnackbar)
          enqueueSnackbar(<Trans>Error while submitting vote</Trans>, {
            variant: 'error'
          })
        }
      )
    } else {
      createVote({
        voteValue,
        id: application.info.id,
        userId: user.userId,
        entityType: entityTypes[committeeType]
      }).then(
        result => {
          this.setState({ voteObjectId: result.id })
          this.reloadStage(values.vote).then(result => {
            closeSnackbar(infoSnackbar)
            enqueueSnackbar(<Trans>Vote submitted</Trans>, {
              variant: 'info'
            })
          })
        },
        reject => {
          console.log('error creating vote', reject)
          this.setState({ submittingVote: false })
          closeSnackbar(infoSnackbar)
          enqueueSnackbar(<Trans>Error while submitting vote</Trans>, {
            variant: 'error'
          })
        }
      )
    }
  }

  render () {
    const {
      application,
      conflictOfInterest,
      applicationId,
      form,
      loadingCompleted,
      committeeType,
      vote,
      submittingVote,
      forceUpdate,
      toggleAll,
      firstReview,
      inDepthReview
    } = this.state
    const {
      values,
      history,
      setValues,
      setFieldValue,
      enqueueSnackbar,
      closeSnackbar,
      user,
      configuration,
      organization
    } = this.props
    // let validationStatus = validationStatusMapping[this.state.committeeType]

    let noNeedToReview = false
    let canBeReviewed = false
    const isConflictOfInterest =
      vote === 'conflict_of_interest' ||
      conflictOfInterest === 'declared_conflict'
    if (application) {
      noNeedToReview =
        application.recommendation.recommendedAmount <= 50000 &&
        !committeeType.includes('SC')
      canBeReviewed = committeeType === application.info.stageName
    }

    if (!loadingCompleted) {
      return <Loading />
    }

    const reviewIds =
      configuration.EXECUTIVE_SUMMARY_ORDER ||
      basePortalConfig.EXECUTIVE_SUMMARY_ORDER
    reviewIds[reviewIds.indexOf('firstReview')] = firstReview
    reviewIds[reviewIds.indexOf('inDepth')] = inDepthReview

    if (isConflictOfInterest) {
      return (
        <>
          <Alert severity='info' style={{ margin: 10 }}>
            <Trans>
              You declared a conflict of interest. You cannot vote on this
              application
            </Trans>
          </Alert>
          <div style={{ padding: 10 }}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              onClick={e => {
                history.push(`/grants/ExecutiveSummaries/${committeeType}`)
              }}
            >
              <Trans>Go back to executive summaries</Trans>
            </Button>
          </div>
        </>
      )
    }

    return (
      <Grid
        container
        spacing={1}
        justify='space-between'
        style={{ height: '100%' }}
      >
        <Grid item xs={6}>
          {form ? (
            <Form
              formId={form.id}
              fetchString={constructFormAddressString({
                user,
                organizationId: application.info.account,
                opportunity: applicationId,
                objectsConnected: form.objectsConnected,
                externalReviewId: reviewIds
              })}
              fixedDisplay
              displayView
            />
          ) : (
            <OpportunityView
              displayPrint
              opportunity={application}
              committeeType={committeeType}
              defaultConfigurationOptions={{ ac: true }}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <Paper style={styles.paper} square={true}>
            {noNeedToReview ? (
              <div style={{ paddingTop: 30 }} align='center'>
                <Grid
                  style={{
                    width: '100%',
                    paddingTop: 30,
                    paddingBottom: 30
                  }}
                  container
                  direction='row'
                  alignItems='center'
                  justify='center'
                >
                  <Typography style={styles.sectionHeader}>
                    <Trans>Reviewed</Trans>
                  </Typography>
                  <Icon color='secondary' style={{ fontSize: 48, margin: 10 }}>
                    check_box
                  </Icon>
                </Grid>
              </div>
            ) : (
              <div style={{ paddingTop: 30 }} align='center'>
                <Typography style={{ fontSize: 18 }}>
                  <b>
                    <Trans>Vote</Trans>
                  </b>
                </Typography>
                <Typography>
                  <Trans>Vote currently saved:</Trans>{' '}
                  <b>
                    <Trans id={vote} />
                  </b>
                </Typography>
                {canBeReviewed && (
                  <>
                    <div>
                      <FormikRadioGroupField
                        name='vote'
                        margin='normal'
                        disabled={submittingVote}
                        options={[
                          { label: <Trans>For</Trans>, value: 'For' },
                          {
                            label: <Trans>Against</Trans>,
                            value: 'Against'
                          },
                          committeeType !== 'CMHC' && {
                            label: <Trans>Request Live Discussion</Trans>,
                            value: 'Request Live Discussion'
                          }
                        ]}
                        onChange={e => {
                          setFieldValue('vote', e)
                        }}
                        row='all'
                      />
                      <Button
                        variant='contained'
                        color='secondary'
                        disabled={submittingVote}
                        style={{ padding: 10, margin: 15 }}
                        onClick={() => {
                          this.submitVote(values.vote)
                        }}
                      >
                        <Trans>Vote</Trans>!
                      </Button>
                    </div>

                    {committeeType === 'SC' && (
                      <div>
                        <ConflictOfInterestButton
                          disabled={submittingVote}
                          handleSubmit={e => {
                            this.submitVote('conflict_of_interest')
                          }}
                        />
                      </div>
                    )}
                  </>
                )}

                <div style={{ width: '80%' }}>
                  <Grid container alignItems='center'>
                    <Typography align='center' style={{ fontSize: 18 }}>
                      {committeeType !== 'CMHC' ? (
                        <Trans>Questions {`&`} Answers</Trans>
                      ) : (
                        <Trans>Comments</Trans>
                      )}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        let newValues = { ...values }
                        newValues.questions.push({
                          question: '',
                          user: user.displayName,
                          userFunc: user.FGM_Portal__UserProfile__c,
                          editable: true,
                          comments: []
                        })
                        setValues(newValues)
                      }}
                    >
                      <Icon style={{ color: '#5DBD94', fontSize: 36 }}>
                        add_circle
                      </Icon>
                    </IconButton>
                  </Grid>
                </div>
                <Button
                  variant='contained'
                  style={{
                    margin: 5,
                    marginBottom: 10,
                    color: 'white',
                    backgroundColor: '#5DBD94'
                  }}
                  onClick={() => {
                    this.setState({
                      toggleAll: true,
                      forceUpdate: forceUpdate + 1
                    })
                  }}
                >
                  <Typography>
                    <Trans>Show all</Trans>
                  </Typography>
                </Button>
                <Button
                  variant='contained'
                  style={{
                    margin: 5,
                    marginBottom: 10,
                    color: 'white',
                    backgroundColor: '#5DBD94'
                  }}
                  onClick={() => {
                    this.setState({
                      toggleAll: false,
                      forceUpdate: forceUpdate + 1
                    })
                  }}
                >
                  <Typography>
                    <Trans>Hide all</Trans>
                  </Typography>
                </Button>
                <Button
                  variant='contained'
                  style={{
                    margin: 5,
                    marginBottom: 10,
                    color: 'white',
                    backgroundColor: '#5DBD94'
                  }}
                  onClick={() => {
                    const refreshingKey = enqueueSnackbar(null, {
                      variant: 'info',
                      persist: true,
                      content: key =>
                        ProgressSnackbar(<Trans>Refreshing comments</Trans>)
                    })
                    this.getCases(application.info.id).then(result => {
                      setFieldValue('questions', result)
                      closeSnackbar(refreshingKey)
                      enqueueSnackbar(<Trans>Comments refreshed</Trans>, {
                        variant: 'success'
                      })
                    })
                  }}
                >
                  <Typography>
                    <Trans>Refresh comments</Trans>
                  </Typography>
                </Button>
                {values.questions &&
                  values.questions.map((question, index) => {
                    return (
                      <AllocationComitteQuestionsCard
                        toggleAll={toggleAll}
                        forceUpdate={forceUpdate}
                        index={index}
                        handleSaveQuestion={this.handleSaveQuestion}
                        handleSaveComment={this.handleSaveComment}
                        handleComment={() => {
                          this.addNewComment(index)
                        }}
                        {...question}
                      />
                    )
                  })}
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const ConflictOfInterestButton = ({ disabled, handleSubmit }) => {
  const [warning, showWarning] = useState(false)

  return (
    <>
      <Button
        disabled={disabled}
        variant='contained'
        color='secondary'
        onClick={e => {
          showWarning(true)
        }}
      >
        <Trans>Conflict of interest</Trans>
      </Button>
      <Dialog open={warning}>
        <DialogTitle id='alert-dialog-title'>
          <Trans>Are you sure you want to declare conflic of interest?</Trans>
        </DialogTitle>
        <DialogContent>
          <Alert severity='warning'>
            <Trans>You cannot undo this decision</Trans>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={e => {
              showWarning(false)
              handleSubmit()
            }}
          >
            <Trans>Yes</Trans>
          </Button>
          <Button onClick={e => showWarning(false)}>
            <Trans>No</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = function (state) {
  return {
    user: state.user,
    organization: state.organization,
    configuration: state.configuration
  }
}

export default withFormikIncluded({
  initialValues,
  validationSchema,
  persist: true
})(
  connect(mapStateToProps, null, null, { forwardRef: true })(
    withSnackbar(ReviewApplicationsWithHooks())
  )
)
