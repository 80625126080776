import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Typography
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { VersionsDifferences } from '../form-page/FormVersionDifferences'

export default function UnsavedDataDetectedDialog ({ data, elementsMap }) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { values, setValues } = useFormikContext()

  useEffect(() => {
    if (data) {
      setDialogOpen(true)
    }
  }, [data])

  return (
    <Dialog open={dialogOpen} fullWidth maxWidth='md'>
      <DialogTitle>
        <Typography variant='h6' style={{ marginBottom: 15 }}>
          <Trans>Unsaved data from last session detected</Trans>
        </Typography>
        <Grid container direction='row'>
          <Grid
            item
            xs
            style={{
              textAlign: 'center',
              fontWeight: 400,
              fontSize: 17
            }}
          >
            <Trans>Current version</Trans>
          </Grid>
          <Grid
            item
            xs
            style={{
              textAlign: 'center',
              fontWeight: 400,
              fontSize: 17
            }}
          >
            <Trans>Backup</Trans>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <VersionsDifferences
          cache={data}
          current={values}
          elementsMap={elementsMap}
        />
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent='space-evenly'
          style={{ paddingBottom: 8 }}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={e => {
              setDialogOpen(false)
              setValues({ ...values, ...data })
            }}
          >
            <Trans>Restore</Trans>
            <Icon style={{ marginLeft: 5 }}>done</Icon>
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={e => {
              setDialogOpen(false)
            }}
          >
            <Trans>Ignore</Trans>
            <Icon style={{ marginLeft: 5 }}>close</Icon>
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
