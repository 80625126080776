import { checkIfOrganizationsAreStale } from 'app/services/sfAuth/sfData/sfAccount'
import {
  SET_USER_DATA,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
  UPDATE_ORG_INFO
} from '../actions/UserActions'

const initialState = {}

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.data
      }
    }
    case UPDATE_ORG_INFO: {
      if (!action.data || !action.data.members) {
        return state
      }
      return {
        ...state,
        organizationMember: action.data.members.find(
          member => member.UserId === state.userId
        )
      }
    }
    case REMOVE_USER_DATA: {
      return {
        ...state
      }
    }
    case USER_LOGGED_OUT: {
      return {}
    }
    default: {
      return state
    }
  }
}

export default userReducer
