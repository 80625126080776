import { Trans } from '@lingui/macro'
import {
  Button,
  Card,
  Grid,
  Icon,
  IconButton,
  TextField
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

const searchForOccurrence = ({ data, searchString, objectIdToName }) => {
  const found = {}

  const checkTextForSearchString = (string, item, section) => {
    if (
      string &&
      String(string).toLowerCase().includes(String(searchString).toLowerCase())
    ) {
      found[item.id] = {
        id: item.id,
        section
      }
    }
  }

  const checkConditionForReferences = (condition, item, section) => {
    const { sfField } = condition
    if (sfField) {
      checkTextForSearchString(sfField, item, section)
    }
  }

  const checkElementForReferences = (item, sectionIndex) => {
    const textKeys = [
      'titleEN',
      'titleFR',
      'helpTextEN',
      'helpTextFR',
      'tooltipEN',
      'tooltipFR'
    ]
    const typePropsKeys = ['html', 'htmlFrench', 'imgLink', 'tag', 'url']

    textKeys.forEach(key => {
      if (item[key]) {
        checkTextForSearchString(item[key], item, sectionIndex)
      }
    })
    if (item.typeProps) {
      typePropsKeys.forEach(key => {
        if (item.typeProps[key]) {
          checkTextForSearchString(item.typeProps[key], item, sectionIndex)
        }
      })
      if (item.typeProps.connectedTo) {
        item.typeProps.connectedTo.forEach(connObj => {
          const fieldName = connObj.connectedField?.label
          const objId = connObj.connectedObject
          if (objId) {
            checkTextForSearchString(objectIdToName[objId], item, sectionIndex)
          }
          checkTextForSearchString(fieldName, item, sectionIndex)
        })
      }
    }

    if (item.conditions) {
      item.conditions.forEach(condition => {
        if (condition.conditions) {
          condition.conditions.forEach(subcondition => {
            checkConditionForReferences(subcondition, item, sectionIndex)
          })
        } else {
          checkConditionForReferences(condition, item, sectionIndex)
        }
      })
    }

    const typeProps = item.typeProps || {}
    const { content = [], options = [] } = typeProps
    switch (item.elementType) {
      case 'textWithReferences':
        content.forEach(item => {
          const { type, textEN, textFR } = item
          if (type === 'text') {
            checkTextForSearchString(textEN, item, sectionIndex)
            checkTextForSearchString(textFR, item, sectionIndex)
          }
        })
        break
      case 'picklist':
      case 'weightedPicklist':
        options.forEach(option => {
          checkTextForSearchString(option.titleEN, item, sectionIndex)
          checkTextForSearchString(option.titleFR, item, sectionIndex)
          checkTextForSearchString(option.apiValue, item, sectionIndex)
          const { connectedField } = option
          if (connectedField) {
            checkTextForSearchString(connectedField.name, item, sectionIndex)
          }
        })
        break
      case 'objectives':
        checkTextForSearchString(item.needsHelpTextFR, item, sectionIndex)
        checkTextForSearchString(item.needsHelpTextEN, item, sectionIndex)
        checkTextForSearchString(item.outcomesHelpTextFR, item, sectionIndex)
        checkTextForSearchString(item.outcomesHelpTextEN, item, sectionIndex)
        options.forEach(option => {
          checkTextForSearchString(option.value, item, sectionIndex)
        })
        break
      case 'milestones':
        const { phases = [] } = item.typeProps
        phases.forEach(phase => {
          checkTextForSearchString(phase.endDateTooltipEN, item, sectionIndex)
          checkTextForSearchString(phase.endDateTooltipFR, item, sectionIndex)
          checkTextForSearchString(phase.labelEN, item, sectionIndex)
          checkTextForSearchString(phase.labelFR, item, sectionIndex)
          checkTextForSearchString(phase.startDateTooltipEN, item, sectionIndex)
          checkTextForSearchString(phase.startDateTooltipFR, item, sectionIndex)
        })
        break
      case 'table':
        const { columns = [] } = item.typeProps
        columns.forEach(column => {
          checkTextForSearchString(column.field, item, sectionIndex)
          checkTextForSearchString(column.labelFR, item, sectionIndex)
          checkTextForSearchString(column.labelEN, item, sectionIndex)
        })
        break
      case 'associatedContactsList':
        const { roles = [] } = item.typeProps
        roles.forEach(role => {
          checkTextForSearchString(role.apiValue, item, sectionIndex)
          checkTextForSearchString(role.labelFR, item, sectionIndex)
          checkTextForSearchString(role.labelEN, item, sectionIndex)
        })
        break
      case 'budget':
        const { expensesLines = [], revenuesLines = [] } = item.typeProps
        checkTextForSearchString(
          item.typeProps.expensesHelpTextEn,
          item,
          sectionIndex
        )
        checkTextForSearchString(
          item.typeProps.expensesHelpTextFr,
          item,
          sectionIndex
        )
        checkTextForSearchString(
          item.typeProps.revenuesHelpTextEn,
          item,
          sectionIndex
        )
        checkTextForSearchString(
          item.typeProps.revenuesHelpTextFr,
          item,
          sectionIndex
        )
        expensesLines.forEach(line =>
          checkTextForSearchString(line, item, sectionIndex)
        )
        revenuesLines.forEach(line =>
          checkTextForSearchString(line, item, sectionIndex)
        )
        break

      case 'icce_budget':
        const { baseCategories = [] } = item.typeProps
        baseCategories.forEach(category => {
          checkTextForSearchString(category.titleEN, item, sectionIndex)
          checkTextForSearchString(category.titleFR, item, sectionIndex)
        })
        break
      default:
        break
    }
  }

  //   checkTextForSearchString(data.titleEN)
  //   checkTextForSearchString(data.titleFR)

  //   if (data.pdfProps) {
  //     checkTextForSearchString(data.pdfProps?.header.textEN)
  //     checkTextForSearchString(data.pdfProps?.header.textFR)
  //     checkTextForSearchString(data.pdfProps?.footer.textEN)
  //     checkTextForSearchString(data.pdfProps?.footer.textFR)
  //   }

  data.sections.forEach((section, sectionIndex) => {
    checkTextForSearchString(
      section.titleEN,
      {
        id: 'section' + sectionIndex
      },
      sectionIndex
    )
    checkTextForSearchString(
      section.titleFR,
      {
        id: 'section' + sectionIndex
      },
      sectionIndex
    )
    section.elements.forEach(element => {
      const loopElement = element => {
        if (element.elements) {
          checkElementForReferences(element, sectionIndex)
          element.elements.forEach(child => {
            loopElement(child)
          })
        } else {
          checkElementForReferences(element, sectionIndex)
        }
      }
      loopElement(element)
    })
  })
  return found
}

export default function SearchInFormEditor ({ scrollRef, data, goToSection }) {
  const [searchString, setSearchString] = useState('')
  const [result, setResult] = useState(null)
  const [resultIndex, setResultIndex] = useState(0)
  const [showBox, setShowBox] = useState(false)
  const { values } = useFormikContext()
  const { objectsConnected = [] } = values
  const objectIdToName = Object.fromEntries(
    objectsConnected.map(obj => [obj.identId, obj.name])
  )

  useEffect(() => {
    if (result && result[resultIndex]) {
      const { id, section } = result[resultIndex]
      goToSection(section)
      setTimeout(() => {
        const domElement = document.getElementById(id)
        scrollRef.current.scrollTop(domElement.offsetTop)
      }, 300)
    }
  }, [result, resultIndex])

  const keyDownCheck = e => {
    if (e.key === 'f' && e.ctrlKey) {
      e.preventDefault()
      setShowBox(!showBox)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keyDownCheck)
    setResult(null)
    setSearchString('')
    return () => {
      document.removeEventListener('keydown', keyDownCheck)
    }
  }, [showBox])

  if (!showBox) {
    return null
  }

  return (
    <Card
      elevation={6}
      style={{
        position: 'fixed',
        top: 80,
        right: 30,
        zIndex: 100,
        padding: 8
      }}
    >
      <Grid container justifyContent='center' alignItems='center'>
        {result && (
          <>
            {result.length > 0 ? (
              <>
                <Grid item style={{ paddingLeft: 4, paddingRight: 4 }}>
                  {Number(resultIndex + 1) + ' '}
                  <Trans>of</Trans>
                  {' ' + result.length}
                </Grid>
                <Grid item>
                  <IconButton
                    size='small'
                    disabled={resultIndex === 0}
                    onClick={e => {
                      setResultIndex(resultIndex - 1)
                    }}
                  >
                    <Icon>arrow_upward</Icon>
                  </IconButton>
                </Grid>
                <Grid item style={{ paddingRight: 4 }}>
                  <IconButton
                    size='small'
                    disabled={resultIndex + 1 === result.length}
                    onClick={e => {
                      setResultIndex(resultIndex + 1)
                    }}
                  >
                    <Icon>arrow_downward</Icon>
                  </IconButton>
                </Grid>
              </>
            ) : (
              <span style={{ paddingRight: 8 }}>
                <Trans>Nothing found!</Trans>
              </span>
            )}
          </>
        )}

        <Grid item xs>
          <TextField
            label={<Trans>Search form elements</Trans>}
            value={searchString}
            onChange={e => {
              setSearchString(e.target.value)
            }}
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item style={{ paddingLeft: 5 }}>
          <Button
            color='primary'
            variant='contained'
            disabled={!searchString}
            onClick={e => {
              const found = searchForOccurrence({
                data,
                searchString,
                objectIdToName
              })
              setResult(Object.values(found))
              setResultIndex(0)
            }}
          >
            <Trans>Search</Trans>
          </Button>
        </Grid>
        <Grid item style={{ paddingLeft: 4 }}>
          <IconButton
            size='small'
            onClick={e => {
              setShowBox(false)
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  )
}
