/**
 * Created by Michał Stawski on 10.03.2021.
 */

export const satScoreCardOSBL = [
  {
    'QuestionID': '453a632042d17d40509ce6f3fd08fa6878323263.0',
    'Parent Text': 'To what degree do you agree with the following statements?',
    'Text EN': 'To function well, an NPO must have competent, democratic and scrupulous governance and management.',
    'Created date': 'undefined',
    'Section': 'SECTION A : GENERAL INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Strongly agree',
    'Option 2': 'Moderately agree',
    'Option 3': 'Neither agree nor disagree',
    'Option 4': 'Moderately disagree',
    'Option 5': 'Strongly disagree',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '453a632042d17d40509ce6f3fd08fa6878323263.1',
    'Parent Text': 'To what degree do you agree with the following statements?',
    'Text EN': 'To provide long-term quality housing, it is important to plan for maintenance and renovations.',
    'Created date': 'undefined',
    'Section': 'SECTION A : GENERAL INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Strongly agree',
    'Option 2': 'Moderately agree',
    'Option 3': 'Neither agree nor disagree',
    'Option 4': 'Moderately disagree',
    'Option 5': 'Strongly disagree',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '453a632042d17d40509ce6f3fd08fa6878323263.2',
    'Parent Text': 'To what degree do you agree with the following statements?',
    'Text EN': 'Transparent financial management that anticipates future expenses, ensures appropriate rent collection, and clear and up-to-date accounting is necessary to maintain the best possible rent structure and to avoid sudden rent increases.',
    'Created date': 'undefined',
    'Section': 'SECTION A : GENERAL INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Strongly agree',
    'Option 2': 'Moderately agree',
    'Option 3': 'Neither agree nor disagree',
    'Option 4': 'Moderately disagree',
    'Option 5': 'Strongly disagree',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '453a632042d17d40509ce6f3fd08fa6878323263.3',
    'Parent Text': 'To what degree do you agree with the following statements?',
    'Text EN': 'The NPO model requires active participation of tenants in decision-making and NPO operations.',
    'Created date': 'undefined',
    'Section': 'SECTION A : GENERAL INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Strongly agree',
    'Option 2': 'Moderately agree',
    'Option 3': 'Neither agree nor disagree',
    'Option 4': 'Moderately disagree',
    'Option 5': 'Strongly disagree',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '453a632042d17d40509ce6f3fd08fa6878323263.4',
    'Parent Text': 'To what degree do you agree with the following statements?',
    'Text EN': 'The NPO model values social inclusion and engagement in the community.',
    'Created date': 'undefined',
    'Section': 'SECTION A : GENERAL INFORMATION',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Strongly agree',
    'Option 2': 'Moderately agree',
    'Option 3': 'Neither agree nor disagree',
    'Option 4': 'Moderately disagree',
    'Option 5': 'Strongly disagree',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'dd10d26b22416b8ba96265e0c372168db3ee8a9a',
    'Parent Text': '',
    'Text EN': 'Governance and general administration',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '870b4b244756761e40d1a05028b2a19859127dcd',
    'Parent Text': '',
    'Text EN': 'How important is governance for your NPO?',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '53dfa998cc766774c2fc66f5d874fd83cda7a67e.0',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'The board meets regularly (e.g., monthly or quarterly).',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '53dfa998cc766774c2fc66f5d874fd83cda7a67e.1',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'Board meeting minutes are kept, filed and accessible.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '53dfa998cc766774c2fc66f5d874fd83cda7a67e.2',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'The board has written policies and procedures.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '53dfa998cc766774c2fc66f5d874fd83cda7a67e.3',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'The board reviews its policies and procedures regularly.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '53dfa998cc766774c2fc66f5d874fd83cda7a67e.4',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'Board members have fixed-term mandates (e.g., 3 years).',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '53dfa998cc766774c2fc66f5d874fd83cda7a67e.5',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'The board ensures membership renewal (it has a succession plan).',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '53dfa998cc766774c2fc66f5d874fd83cda7a67e.6',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'The training or integration of new board members is ensured.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '53dfa998cc766774c2fc66f5d874fd83cda7a67e.7',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'The board is in part made up of tenants from different properties owned by the NPO.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '53dfa998cc766774c2fc66f5d874fd83cda7a67e.8',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'Board members represent the diversity of the community.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '53dfa998cc766774c2fc66f5d874fd83cda7a67e.9',
    'Parent Text': 'How well do each of the following statements describe your board of directors?',
    'Text EN': 'Board members have complementary skills (management, accounting, law, social work, etc.).',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5a48f94719bf681cbf89dc3af8031f9a2b06b592.0',
    'Parent Text': 'To what extent do each of the following statements describe the action and functioning of your board?',
    'Text EN': 'The board reviews the monthly/ quarterly financial statements, meets with the auditor to audit the annual financial statements, and reviews other corporate reports.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': 'NOT SCORED (N/A)',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5a48f94719bf681cbf89dc3af8031f9a2b06b592.1',
    'Parent Text': 'To what extent do each of the following statements describe the action and functioning of your board?',
    'Text EN': 'The board ensures the NPO meets all its legal and compliance obligations.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': 'NOT SCORED (N/A)',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5a48f94719bf681cbf89dc3af8031f9a2b06b592.2',
    'Parent Text': 'To what extent do each of the following statements describe the action and functioning of your board?',
    'Text EN': 'The board has a strategic plan with clear objectives and assesses progress toward meeting them. It weighs its decisions carefully to ensure they are consistent with the plan.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': 'NOT SCORED (N/A)',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5a48f94719bf681cbf89dc3af8031f9a2b06b592.3',
    'Parent Text': 'To what extent do each of the following statements describe the action and functioning of your board?',
    'Text EN': 'Board members represent the NPO in the surrounding community, in associations or consultations.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': 'NOT SCORED (N/A)',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '57e896a577cb3d2ac6dbd3d437af525034d8859d.0',
    'Parent Text': 'Is your NPO a member of a federation/ alliance/ association of non-profit housing organizations? Does it participate in federation activities and use its services?',
    'Text EN': 'Our NPO is a member of a federation of non-profit housing organizations.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '57e896a577cb3d2ac6dbd3d437af525034d8859d.1',
    'Parent Text': 'Is your NPO a member of a federation/ alliance/ association of non-profit housing organizations? Does it participate in federation activities and use its services?',
    'Text EN': 'Board members and management attend meetings or participate in federation events.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '57e896a577cb3d2ac6dbd3d437af525034d8859d.2',
    'Parent Text': 'Is your NPO a member of a federation/ alliance/ association of non-profit housing organizations? Does it participate in federation activities and use its services?',
    'Text EN': 'Our NPO uses the training and support services provided by the federation.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'ac5c537c793c1e92da94af541a63595c1fa41f51',
    'Parent Text': '',
    'Text EN': 'Does your NPO have any paid staff?',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Yes',
    'Option 2': 'No',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '02ff2aefb08e58538fcb41c4052d0452b4a16544',
    'Parent Text': '',
    'Text EN': 'Do you contract out work to a property management firm?',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Yes',
    'Option 2': 'No',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0e6ead04437745a4aa8c96b830f761d3ad167803.0',
    'Parent Text': 'To what extent do each of the following statements describe the management of your NPO and its employees?',
    'Text EN': 'The manager(s), our employees or contracted staff have the training and experience to properly manage our property(ies), operations and planning.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0e6ead04437745a4aa8c96b830f761d3ad167803.1',
    'Parent Text': 'To what extent do each of the following statements describe the management of your NPO and its employees?',
    'Text EN': 'Our NPO has implemented controls and processes tailored to its needs to eliminate the risk of error and/ or fraud.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0e6ead04437745a4aa8c96b830f761d3ad167803.2',
    'Parent Text': 'To what extent do each of the following statements describe the management of your NPO and its employees?',
    'Text EN': 'Our NPO has updated its computer hardware, software and security processes and ensures staff are properly trained.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0e6ead04437745a4aa8c96b830f761d3ad167803.3',
    'Parent Text': 'To what extent do each of the following statements describe the management of your NPO and its employees?',
    'Text EN': 'Human resources policies have been put in place to recruit and manage employees, and ensure they receive all the required training.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0e6ead04437745a4aa8c96b830f761d3ad167803.4',
    'Parent Text': 'To what extent do each of the following statements describe the management of your NPO and its employees?',
    'Text EN': 'Our management prepares regular follow-up reports to the board and ensures that all reports are prepared respecting both best practice standards and established deadlines.',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '9d5164642de4f443a04073dcec60d8d1e79dcbf9',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the day-to-day management of your NPO by the management staff?',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': 'false',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '020bc464eaff2c993d7de65f2c905bbde214f144',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the day-to-day management of your NPO by the board?',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '4f200fce5aa8e49cb88f3b4a3a472d389ed58aab',
    'Parent Text': '',
    'Text EN': 'When was your business continuity plan last updated?',
    'Created date': 'undefined',
    'Section': 'SECTION B: GOVERNANCE',
    'Scoring group': 'Governance',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0,9',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'a789e68cf6628b5b8b69ce91f666582987c8d952',
    'Parent Text': '',
    'Text EN': 'General information',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '1a6adf63a16a65990087af6853f729ddc40df4ce',
    'Parent Text': '',
    'Text EN': 'Some NPOs have several buildings. Often, these assets have been acquired through different funding programs or phases of programs over time. The management of these assets is subject to different agreements and contracts with funders. Has your NPOâ€™s development been carried out in different projects or in different phases?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Yes',
    'Option 2': 'No, we only have one property.',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '781de307e9196074578772dd69b398398faa6b68',
    'Parent Text': '',
    'Text EN': 'If you have multiple properties in various locations, please select one that, to the extent possible, is representative of your other properties in terms of size, age and condition, and profile of tenants to answer the questions in this section.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '248fc49fd1061f0a3b574861b61a44344c90661f.0',
    'Parent Text': 'What is the property address?',
    'Text EN': 'Street address:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '248fc49fd1061f0a3b574861b61a44344c90661f.1',
    'Parent Text': 'What is the property address?',
    'Text EN': 'Municipality:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '248fc49fd1061f0a3b574861b61a44344c90661f.2',
    'Parent Text': 'What is the property address?',
    'Text EN': 'Province/territory:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '248fc49fd1061f0a3b574861b61a44344c90661f.3',
    'Parent Text': 'What is the property address?',
    'Text EN': 'Postal code:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5f15c50fe0626844584adbd2badd8a7dcb6355e2',
    'Parent Text': '',
    'Text EN': 'Please indicate the number of units on this property:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'd8b6bac31e920b218ccde03ed5068c2f901a1fed.0',
    'Parent Text': 'Breakdown (in percentage) of the rents listed below in this property:',
    'Text EN': 'Rent-geared-to-income (RGI)*  (%):',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'd8b6bac31e920b218ccde03ed5068c2f901a1fed.1',
    'Parent Text': 'Breakdown (in percentage) of the rents listed below in this property:',
    'Text EN': 'Rents below market (%):',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'd8b6bac31e920b218ccde03ed5068c2f901a1fed.2',
    'Parent Text': 'Breakdown (in percentage) of the rents listed below in this property:',
    'Text EN': 'Market rents (%):',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '2db61ae9f60efa711acdecb24bd1d593b1053329',
    'Parent Text': '',
    'Text EN': 'Does your NPO have an operating agreement* or subsidy agreement** for this property with CMHC, the province, territory or municipality?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Yes',
    'Option 2': 'No',
    'Option 3': 'Donâ€™t know',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '1f0d9f69335a1840aac3e8921b95fff1f34f8bb6',
    'Parent Text': '',
    'Text EN': 'How much time is left before the end of this agreement?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Less than 2 years',
    'Option 2': 'Between 2 and 5 years',
    'Option 3': 'Between 5 and 10 years',
    'Option 4': 'More than 10 years',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '159cfc168335cb59f5f9b51d6c600bb71e0d2033',
    'Parent Text': '',
    'Text EN': 'Does your NPO have an agreement for rent subsidies* with a government entity to reduce rents for certain units on this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Yes',
    'Option 2': 'No',
    'Option 3': 'Donâ€™t know',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '789c662b68fcc7a783117c986b957e3a301fe738',
    'Parent Text': '',
    'Text EN': 'How much time is left before the end of this agreement?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Less than 2 years',
    'Option 2': 'Between 2 and 5 years',
    'Option 3': 'Between 5 and 10 years',
    'Option 4': 'More than 10 years',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '2f270aa2c9ebd4ba538d9cfc3ad2cb47c551bfcc',
    'Parent Text': '',
    'Text EN': 'Does your NPO have a service-funding agreement with the province or municipality for this propertyâ€™s tenants who need support services*?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Yes',
    'Option 2': 'No',
    'Option 3': 'Donâ€™t know',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '2ee4064ced29306f491dce16fcdd312e4904aff5',
    'Parent Text': '',
    'Text EN': 'How much time is left before the end of this agreement?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Less than 2 years',
    'Option 2': 'Between 2 and 5 years',
    'Option 3': 'Between 5 and 10 years',
    'Option 4': 'More than 10 years',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6ab5d3f28865d0fd3d4baaf0b20e61131e98fa27',
    'Parent Text': '',
    'Text EN': 'How is the overall condition of the building(s) on this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'dcf44db723f13ff80fa40c176ebf3ce8b57dcbb8',
    'Parent Text': '',
    'Text EN': 'Property Management',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '2bf3d3c5a23b28d10cb60b37e5ad02097a6ad4a6',
    'Parent Text': '',
    'Text EN': 'How important is property management for your NPO?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Property',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '583c22a6b5df51f35b50d91e86ec7d50a869360f',
    'Parent Text': '',
    'Text EN': 'Overall, the property management of this property is:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '58aac582c0f3ac1bb441db682b9709a9001036c9',
    'Parent Text': '',
    'Text EN': 'When was this propertyâ€™s last full building condition assessment (BCA)?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c671868ced030e677e7f97e04d41b79e97ac4a24',
    'Parent Text': '',
    'Text EN': 'Have you completed the work recommended in the most recent assessment?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '6b45a827ee3b1357f5aae21f74d4dcb6d01c9225',
    'Parent Text': '',
    'Text EN': 'What is your approach to preventative maintenance and proactive repairs on this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b40161d3bc2638038fb8ae5f1b5dea6f7b6b4928',
    'Parent Text': '',
    'Text EN': 'What is your approach to tenant feedback?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0229b4ee868ac664976afe5783751797bc00aa72',
    'Parent Text': '',
    'Text EN': 'What is your approach to tenant requests?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'a63f8ddb6b974f4a326fc6a27f5f7092eac7a127',
    'Parent Text': '',
    'Text EN': 'Financial management',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '0ff0eb19aceb0cb62d2cfdaae2782fc3a0005113',
    'Parent Text': '',
    'Text EN': 'How important is financial management for your NPO?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Financial',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '8d6fe3c5df2dc02702b25071aa55a93948873cd6',
    'Parent Text': '',
    'Text EN': 'Overall, the financial health of this property is:',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'b65c54ab17bc9b259afe2912d486f18be4ab8617',
    'Parent Text': '',
    'Text EN': 'In the past five years, have you made annual contributions to a Capital Replacement Reserve Fund for this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '57f38b1458a1726e308d9f407fa9247210ad95ec.0',
    'Parent Text': 'How well do the following statements describe this property?',
    'Text EN': 'The property is financially viable (e.g., revenues cover all expenses, including routine repairs and contributions to the capital reserve).',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '57f38b1458a1726e308d9f407fa9247210ad95ec.1',
    'Parent Text': 'How well do the following statements describe this property?',
    'Text EN': 'Vacancy losses are minimal and/ or have declined over the past five years.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '57f38b1458a1726e308d9f407fa9247210ad95ec.2',
    'Parent Text': 'How well do the following statements describe this property?',
    'Text EN': 'Rent arrears are close to zero or have been declining over the past five years.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '57f38b1458a1726e308d9f407fa9247210ad95ec.3',
    'Parent Text': 'How well do the following statements describe this property?',
    'Text EN': 'The capital reserve is enough to complete the planned renovations.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5e2a2b78e4a8e2e0ba086bfcfa09f8394e66a58d',
    'Parent Text': '',
    'Text EN': 'Do you have an Eviction Prevention Policy? (Including, for example, repayment schedules, access to a rent bank, landlord mediation, help to apply for income support, etc.)',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '0,6',
    'Option 3': '1,3',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '38eb5bace6edd3a54b87e4d2794cd59d7548e7cc.0',
    'Parent Text': 'To what extent is the current rent structure financially sustainable in the immediate and longer term?',
    'Text EN': 'The current mix of rent-geared-to-income (RGI)* and non-subsidized rent is sustainable to the extent that we have subsidy agreements in place.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '38eb5bace6edd3a54b87e4d2794cd59d7548e7cc.1',
    'Parent Text': 'To what extent is the current rent structure financially sustainable in the immediate and longer term?',
    'Text EN': 'When units become available, we set rents for new tenants to ensure long-term financial sustainability even if subsidies end.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '38eb5bace6edd3a54b87e4d2794cd59d7548e7cc.2',
    'Parent Text': 'To what extent is the current rent structure financially sustainable in the immediate and longer term?',
    'Text EN': 'We plan to assess the rent structure at the end of the operating agreements**.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '38eb5bace6edd3a54b87e4d2794cd59d7548e7cc.3',
    'Parent Text': 'To what extent is the current rent structure financially sustainable in the immediate and longer term?',
    'Text EN': 'Our rent structure allows us to capitalize for further development and/or subsidize lower-income tenants.',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'To a large extent',
    'Option 2': 'To some extent',
    'Option 3': 'Not at all',
    'Option 4': 'Donâ€™t know',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '24f93e3dec6fd8ce7f3e13c9e9d42095e142105f',
    'Parent Text': '',
    'Text EN': 'Has your NPO developed a plan to ensure that this property remains financially viable once the operating agreement has expired?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Financial',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'f8ea4819314209638f5fce3165acef37f6f528ca',
    'Parent Text': '',
    'Text EN': 'Tenant involvement and community relations',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '1e9fd06b3397075b096c9bf2a63abb664e5e268b',
    'Parent Text': '',
    'Text EN': 'How important is it for you that tenants be involved in the NPO?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': '1',
    'Option 2': '0,5',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'd30658b92138605fd36e09aa3a8b89b35d3c54c3',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the involvement of tenants in this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c702058ec21935053e1f0f24f35707766e5def79.0',
    'Parent Text': 'What is the level of involvement of this propertyâ€™s tenants in the following NPO activities?',
    'Text EN': 'Participation in the board and/ or committees',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c702058ec21935053e1f0f24f35707766e5def79.1',
    'Parent Text': 'What is the level of involvement of this propertyâ€™s tenants in the following NPO activities?',
    'Text EN': 'Organization of regular events/ activities',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c702058ec21935053e1f0f24f35707766e5def79.2',
    'Parent Text': 'What is the level of involvement of this propertyâ€™s tenants in the following NPO activities?',
    'Text EN': 'Preparation of newsletters, print, digital or internet publications',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c702058ec21935053e1f0f24f35707766e5def79.3',
    'Parent Text': 'What is the level of involvement of this propertyâ€™s tenants in the following NPO activities?',
    'Text EN': 'Regular use of common space for events and activities',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c702058ec21935053e1f0f24f35707766e5def79.4',
    'Parent Text': 'What is the level of involvement of this propertyâ€™s tenants in the following NPO activities?',
    'Text EN': 'Participation in events and social activities',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '9dbda6fffcf240edca61fe403d67bdafde9123b5',
    'Parent Text': '',
    'Text EN': 'What is the level of importance assigned by your non-profit for tenants, board members and staff to get involved in the surrounding community?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': 'WEIGHTING',
    'multiple': '',
    'Option 1': '1',
    'Option 2': '0,5',
    'Option 3': '0',
    'Option 4': '0',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'ba0b4f7e9112a253739eb09729b1a6bb3c7cf11e',
    'Parent Text': '',
    'Text EN': 'Overall, how would you rate the involvement of tenants, board members and staff in the surrounding community?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '0',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e5b896c75a87326bd6a133f9fc845fd5a6d2f2b4.0',
    'Parent Text': 'What is the level of tenant, board member and staff involvement in the surrounding community for this property?',
    'Text EN': 'Participation in community events',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e5b896c75a87326bd6a133f9fc845fd5a6d2f2b4.1',
    'Parent Text': 'What is the level of tenant, board member and staff involvement in the surrounding community for this property?',
    'Text EN': 'Use of common space for activities with community groups',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e5b896c75a87326bd6a133f9fc845fd5a6d2f2b4.2',
    'Parent Text': 'What is the level of tenant, board member and staff involvement in the surrounding community for this property?',
    'Text EN': 'Shared common services with other community groups',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e5b896c75a87326bd6a133f9fc845fd5a6d2f2b4.3',
    'Parent Text': 'What is the level of tenant, board member and staff involvement in the surrounding community for this property?',
    'Text EN': 'Membership in other community groups',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'e5b896c75a87326bd6a133f9fc845fd5a6d2f2b4.4',
    'Parent Text': 'What is the level of tenant, board member and staff involvement in the surrounding community for this property?',
    'Text EN': 'Participation in community initiatives to improve the surrounding community',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7189dc2bc872ed3bec95d0a4751ca356fcd99a9b.0',
    'Parent Text': 'How would you describe diversity among the tenants who live in this property?',
    'Text EN': 'Economically',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7189dc2bc872ed3bec95d0a4751ca356fcd99a9b.1',
    'Parent Text': 'How would you describe diversity among the tenants who live in this property?',
    'Text EN': 'Linguistically',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7189dc2bc872ed3bec95d0a4751ca356fcd99a9b.2',
    'Parent Text': 'How would you describe diversity among the tenants who live in this property?',
    'Text EN': 'Culturally',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7189dc2bc872ed3bec95d0a4751ca356fcd99a9b.3',
    'Parent Text': 'How would you describe diversity among the tenants who live in this property?',
    'Text EN': 'Racially',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7189dc2bc872ed3bec95d0a4751ca356fcd99a9b.4',
    'Parent Text': 'How would you describe diversity among the tenants who live in this property?',
    'Text EN': 'At the age level',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7189dc2bc872ed3bec95d0a4751ca356fcd99a9b.5',
    'Parent Text': 'How would you describe diversity among the tenants who live in this property?',
    'Text EN': 'At the household type level',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1',
    'Option 3': '0',
    'Option 4': 'false',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '4196b9ca7bd75501d841aed785cb7256c9b98175',
    'Parent Text': '',
    'Text EN': 'Which of the following best describes accessibility in this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'This property is fully accessible to people with physical disabilities.',
    'Option 2': 'This property requires modifications to be fully accessible.',
    'Option 3': 'This property is not accessible.',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '4e6972358b709f51ac14e7ec28ab99723119fb80',
    'Parent Text': '',
    'Text EN': 'Has your NPO ever assessed the feasibility of improving or expanding the accessibility of this property?',
    'Created date': 'undefined',
    'Section': 'SECTION C: Version for NPOs with more than one property',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '2',
    'Option 3': '0',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '561856af632acf023321ec6cb9640dcf149542f0.0',
    'Parent Text': 'Considering all your properties, how satisfied are you with each of the following aspects of your NPO?',
    'Text EN': 'Governance (board and employee)',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': 'Governance',
    'modifier': 'SATISFACTION',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '561856af632acf023321ec6cb9640dcf149542f0.1',
    'Parent Text': 'Considering all your properties, how satisfied are you with each of the following aspects of your NPO?',
    'Text EN': 'Property management',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': 'Property',
    'modifier': 'SATISFACTION',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '561856af632acf023321ec6cb9640dcf149542f0.2',
    'Parent Text': 'Considering all your properties, how satisfied are you with each of the following aspects of your NPO?',
    'Text EN': 'Financial management',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': 'Financial',
    'modifier': 'SATISFACTION',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '561856af632acf023321ec6cb9640dcf149542f0.3',
    'Parent Text': 'Considering all your properties, how satisfied are you with each of the following aspects of your NPO?',
    'Text EN': 'Tenant involvement',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': 'Social',
    'modifier': 'SATISFACTION',
    'multiple': '',
    'Option 1': '1',
    'Option 2': '0,75',
    'Option 3': '0,5',
    'Option 4': '0,25',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '561856af632acf023321ec6cb9640dcf149542f0.4',
    'Parent Text': 'Considering all your properties, how satisfied are you with each of the following aspects of your NPO?',
    'Text EN': 'Community relations',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': 'Social',
    'modifier': 'SATISFACTION',
    'multiple': '',
    'Option 1': '1',
    'Option 2': '0,75',
    'Option 3': '0,5',
    'Option 4': '0,25',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '749b9c5275f17afcac9a6c93c80a0b1d4baae69a.0',
    'Parent Text': 'Overall, how accurately do these statements describe your NPO?',
    'Text EN': 'Our NPO is able to maintain its operations at the same level for a long time to come without additional external support or significant rent increases (i.e., we have sufficient reserves for annual maintenance and renovations)',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': 'VIABILITY',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '749b9c5275f17afcac9a6c93c80a0b1d4baae69a.1',
    'Parent Text': 'Overall, how accurately do these statements describe your NPO?',
    'Text EN': 'Our NPO has the flexibility to adapt and meet more or less predictable challenges (e.g., end of subsidies, disaster, resignation by a manager, sudden increase in energy prices)',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': 'RESILIENCE',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '749b9c5275f17afcac9a6c93c80a0b1d4baae69a.2',
    'Parent Text': 'Overall, how accurately do these statements describe your NPO?',
    'Text EN': 'Our NPO is strong enough to consider growing, improving its services or supporting the community (e.g., purchasing or building a new phase, accepting tenants facing serious health issues, installing public equipment in the yard)',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': 'vitality',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,5',
    'Option 3': '1',
    'Option 4': '0,5',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '95d40f295a5c41dddfd3823e15c962fe4c98c512.0',
    'Parent Text': 'To what extent does your NPO have programs or policies in place for the following?',
    'Text EN': 'Minimize operating costs for heating, A/C, lighting, and water.',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '95d40f295a5c41dddfd3823e15c962fe4c98c512.1',
    'Parent Text': 'To what extent does your NPO have programs or policies in place for the following?',
    'Text EN': 'Minimize the impacts of climate change on our tenants (e.g. heat waves, harsher winters, etc.).',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': 'Property',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '95d40f295a5c41dddfd3823e15c962fe4c98c512.2',
    'Parent Text': 'To what extent does your NPO have programs or policies in place for the following?',
    'Text EN': 'Create more socially accessible and inclusive housing.',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': 'Social',
    'modifier': '',
    'multiple': '',
    'Option 1': '2',
    'Option 2': '1,3',
    'Option 3': '0,6',
    'Option 4': '0',
    'Option 5': '0',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5d205a310a8087a1d4c1b60cbc43c2e37343069b.0',
    'Parent Text': 'How easy is it for your NPO to obtain the following services?',
    'Text EN': 'Financial services',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very easy',
    'Option 2': 'Somewhat easy',
    'Option 3': 'Neither easy nor difficult',
    'Option 4': 'Somewhat difficult',
    'Option 5': 'Very difficult',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5d205a310a8087a1d4c1b60cbc43c2e37343069b.1',
    'Parent Text': 'How easy is it for your NPO to obtain the following services?',
    'Text EN': 'Aid for development proposals',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very easy',
    'Option 2': 'Somewhat easy',
    'Option 3': 'Neither easy nor difficult',
    'Option 4': 'Somewhat difficult',
    'Option 5': 'Very difficult',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5d205a310a8087a1d4c1b60cbc43c2e37343069b.2',
    'Parent Text': 'How easy is it for your NPO to obtain the following services?',
    'Text EN': 'Aid for renovation proposals',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very easy',
    'Option 2': 'Somewhat easy',
    'Option 3': 'Neither easy nor difficult',
    'Option 4': 'Somewhat difficult',
    'Option 5': 'Very difficult',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5d205a310a8087a1d4c1b60cbc43c2e37343069b.3',
    'Parent Text': 'How easy is it for your NPO to obtain the following services?',
    'Text EN': 'Architectural and technical expertise',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very easy',
    'Option 2': 'Somewhat easy',
    'Option 3': 'Neither easy nor difficult',
    'Option 4': 'Somewhat difficult',
    'Option 5': 'Very difficult',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '5d205a310a8087a1d4c1b60cbc43c2e37343069b.4',
    'Parent Text': 'How easy is it for your NPO to obtain the following services?',
    'Text EN': 'Services for tenants needing support services',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': 'Very easy',
    'Option 2': 'Somewhat easy',
    'Option 3': 'Neither easy nor difficult',
    'Option 4': 'Somewhat difficult',
    'Option 5': 'Very difficult',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': 'c4db50c5e641af6402bdb2cbbbb8663e2d57531f',
    'Parent Text': '',
    'Text EN': 'How could the Centre help you?',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  },
  {
    'QuestionID': '7e866c50e8df78b03def41b4790e9277c86c99ba',
    'Parent Text': '',
    'Text EN': 'Do you have any other comments about your NPO and its housing?',
    'Created date': 'undefined',
    'Section': 'SECTION E: OVERALL ASSESSMENT OF YOUR NPO AND OTHER QUESTIONS',
    'Scoring group': '',
    'modifier': '',
    'multiple': '',
    'Option 1': '',
    'Option 2': '',
    'Option 3': '',
    'Option 4': '',
    'Option 5': '',
    'Option 6': '',
    'Option 7': '',
    'Option 8': '',
    'Option 9': '',
    'Option 10': '',
    'Option 11': '',
    'Option 12': ''
  }
]
